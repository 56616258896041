import { SVGProps } from 'react';

export const TimerStop = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M2.5 3.5C2.5 3.23478 2.60536 2.98043 2.79289 2.79289C2.98043 2.60536 3.23478 2.5 3.5 2.5H8.5C8.76522 2.5 9.01957 2.60536 9.20711 2.79289C9.39464 2.98043 9.5 3.23478 9.5 3.5V8.5C9.5 8.76522 9.39464 9.01957 9.20711 9.20711C9.01957 9.39464 8.76522 9.5 8.5 9.5H3.5C3.23478 9.5 2.98043 9.39464 2.79289 9.20711C2.60536 9.01957 2.5 8.76522 2.5 8.5V3.5Z"
        fill="#2D6ADF"
        stroke="#2D6ADF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
