import styled from '@emotion/styled';
import { IconButton, Tooltip } from '@mui/material';
import { useKeyboardEvent } from '@react-hookz/web';
import { focusModeTaskboxAtom, hideFocusModeTaskboxAtom } from 'atoms/focus';
import { userStateAtom } from 'atoms/userState';
import { Icons } from 'components';
import dayjs from 'lib/dayjs';
import { useAtom } from 'jotai';
import { updateStateStorageV1StateStoragePatch } from 'queries';
import { OutTaskboxDetail, OutTaskboxDetailResponse } from 'queries/model';
import { COLORS } from 'styles/constants';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.gray900};
  padding: 0px 30px;
`;

const UniversalIconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UniversalFocusModeTaskboxWrapper = styled.div<{ taskbox?: OutTaskboxDetailResponse }>`
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(270deg, #4760de -0.16%, #2a92f3 99.84%);
  border-radius: 8px;
  color: ${COLORS.white};
  cursor: pointer;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 700;
  ${(props) =>
    props.taskbox?.isRecurrence
      ? `background: linear-gradient(63deg, #02CB8F 17.47%, #0CB0BB 83.26%);`
      : props.taskbox?.project
      ? `background: linear-gradient(63deg, #CD6CFC 17.47%, #9C17DB 83.26%);`
      : props.taskbox?.focus
      ? `background: linear-gradient(243deg, #C471ED 16.99%, #F9787F 82.79%);`
      : `background: linear-gradient(270deg, #4760de -0.16%, #2a92f3 99.84%);`}
`;

const UniversalFocusModeTaskboxTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UniversalFocusModeTaskboxTitle = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UniversalTaskViewControlWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

export const Universal = () => {
  const [focusModeTaskbox, setFocusModeTaskbox] = useAtom(focusModeTaskboxAtom);
  const [hideFocusModeTaskbox, setHideFocusModeTaskbox] = useAtom(hideFocusModeTaskboxAtom);
  const [userState, setUserStat] = useAtom(userStateAtom);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement;
      if (ev.shiftKey && ev.code === 'KeyF') {
        if (!element) return;
        if (
          element.tagName === 'TEXTAREA' ||
          (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
          (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        ) {
          return;
        }
        if (hideFocusModeTaskbox) handleClickShowFocusModeTaskbox();
      }
    },
    [],
    { event: 'keydown', eventOptions: { passive: false } },
  );

  const handleClickListView = async () => {
    const success = await updateStateStorageV1StateStoragePatch({
      data: { ...userState.data, taskViewMode: userState.data.taskViewMode === 'MERGED' ? 'SPLIT' : 'MERGED' },
    });

    if (success) {
      setUserStat({
        ...userState,
        data: {
          ...userState.data,
          taskViewMode: userState.data.taskViewMode === 'MERGED' ? 'SPLIT' : 'MERGED',
        },
      });
    }
  };

  const handleClickShowSubtask = async () => {
    const success = await updateStateStorageV1StateStoragePatch({
      data: { ...userState.data, isShowingSubtask: !userState.data.isShowingSubtask },
    });

    if (success) {
      setUserStat({
        ...userState,
        data: {
          ...userState.data,
          isShowingSubtask: !userState.data.isShowingSubtask,
        },
      });
    }
  };

  const handleClickShowFocusModeTaskbox = () => {
    setHideFocusModeTaskbox(false);
  };

  const handleCloseFocusModeTaskbox = () => {
    setHideFocusModeTaskbox(false);
    setFocusModeTaskbox(null);
  };

  return (
    <Container>
      <UniversalIconsWrapper>
        <Icons.Slash style={{ marginRight: 4 }} />
        <Icons.Beta />
      </UniversalIconsWrapper>
      {hideFocusModeTaskbox && (
        <Tooltip
          title={
            <div style={{ margin: '2px 4px' }}>
              <span>포커스 된 태스크박스 보기</span>
              <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                Shift
              </KeyboardButtonRect>
              + <KeyboardButtonRect small>F</KeyboardButtonRect>
            </div>
          }
          disableInteractive
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              },
            ],
          }}
        >
          <UniversalFocusModeTaskboxWrapper taskbox={focusModeTaskbox ? focusModeTaskbox : undefined} onClick={handleClickShowFocusModeTaskbox}>
            <UniversalFocusModeTaskboxTitleWrapper>
              <Icons.Flame style={{ marginRight: 8 }} />
              <UniversalFocusModeTaskboxTitle style={{ marginRight: 8 }}>{focusModeTaskbox?.title}</UniversalFocusModeTaskboxTitle>
              {!focusModeTaskbox?.allDay && (
                <div style={{ marginRight: 8 }}>{`${dayjs(focusModeTaskbox?.start?.datetime).format('a h:mm')}-${dayjs(focusModeTaskbox?.end?.datetime).format(
                  'a h:mm',
                )}`}</div>
              )}
            </UniversalFocusModeTaskboxTitleWrapper>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleCloseFocusModeTaskbox();
              }}
              sx={{
                'padding': '0px',
                ':hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Icons.Close width={14} height={14} fill={COLORS.white} />
            </IconButton>
          </UniversalFocusModeTaskboxWrapper>
        </Tooltip>
      )}
      <UniversalTaskViewControlWrapper>
        {/* <IconButton
          onClick={handleClickListView}
          sx={{
            borderRadius: '6px',
            backgroundColor: userState.data.taskViewMode === 'MERGED' ? COLORS.sub2 : 'transparent',
            padding: '4px',
            marginRight: '10px',
          }}
        >
          <Icons.ListView />
        </IconButton> */}
        <IconButton
          onClick={handleClickShowSubtask}
          sx={{
            'borderRadius': '6px',
            'backgroundColor': userState.data.isShowingSubtask ? 'transparent' : COLORS.sub2,
            'padding': '4px',
            ':hover': {
              backgroundColor: userState.data.isShowingSubtask ? COLORS.gray800 : '#003DB2',
            },
          }}
        >
          <Icons.SubTaskCollapse fill={COLORS.white} />
        </IconButton>
      </UniversalTaskViewControlWrapper>
    </Container>
  );
};
