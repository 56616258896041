import styled from '@emotion/styled';
import { IconButton, Popover, Tooltip } from '@mui/material';
import { categoryAtom } from 'atoms/category';
import { selectedProjectAtom } from 'atoms/projects';
import { Icons } from 'components';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useAtom } from 'jotai';
import dayjs, { Dayjs } from 'lib/dayjs';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import {
  createCategoryV1CategoryPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  updateProjectV1ProjectProjectIdPatch,
} from 'queries';
import { OutCategory, OutProject, OutRoutine, OutTaskboxDetailProjectOnly, OutTaskboxDetailRoutineOnly } from 'queries/model';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { css } from '@emotion/react';
import { selectedRoutineAtom } from 'atoms/routine';

export type RoutineStatus = 'DONE' | 'HOLD' | 'CANCEL' | 'UNLOCK';

interface RoutineViewProps {
  routine?: OutRoutine;
  onKeydownRoutine?: (e: React.KeyboardEvent<HTMLDivElement>, routineId: string) => void;
  onClickRoutineCheck?: (projectId: string, status: RoutineStatus) => void;
  onSelectRoutine?: (routine: OutRoutine) => void;
  onFetch?: () => void;
  onDrop?: (routineId: string) => void;
}

const RoutineView = ({
  routine,
  onKeydownRoutine = () => {},
  onClickRoutineCheck = () => {},
  onSelectRoutine = () => {},
  onFetch = () => {},
  onDrop = () => {},
}: RoutineViewProps) => {
  const [routineStatusPopover, setRoutineStatusPopover] = useState<HTMLElement | null>();
  const [routineSubtasks, setRoutineSubtasks] = useState<OutTaskboxDetailRoutineOnly[]>(routine?.tasks || []);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedRoutine] = useAtom(selectedRoutineAtom);

  useEffect(() => {
    setRoutineSubtasks(routine!.tasks!);
  }, [routine]);

  const handleClickRoutineCheck = async (status: RoutineStatus) => {
    onClickRoutineCheck(routine!.id, status);
    setRoutineStatusPopover(null);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!routine) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            await updateProjectV1ProjectProjectIdPatch(routine.id!, { categoryIds: category ? [category.id] : [] });
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 선택할 수 없습니다.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error('카테고리를 생성할 수 없습니다.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 삭제할 수 없습니다.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 수정할 수 없습니다.');
          }
        }
        break;
    }
  };

  const handleDropToRoutine = async (projectId: string) => {
    onDrop(projectId);
    setIsDragOver(false);
  };

  const isInstance = routine && routine.tasks!.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK').length > 0;

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragOver(false);
      }}
      style={{ width: '100%' }}
    >
      <div hidden={isDragOver} className="routine-card">
        <RoutineContainer
          key={routine!.id}
          isFocus={selectedRoutine?.id === routine?.id}
          // done={routine?.status === 'DONE'}
          onClick={(e) => {
            e.stopPropagation();
            onSelectRoutine(routine!);
          }}
        >
          <RoutineLine />
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0px 4px', marginLeft: '8px' }}>
            <div>
              <RoutineHeader>
                {routine && routine.category && (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(routine.category.color)}
                    bgColor={getCategoryBgColor(routine.category.color)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryAnchorEl(e.currentTarget);
                    }}
                  >
                    {`# ${routine.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(routine.category!.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} fill={getCategoryTextColor(routine.category!.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                )}
                {/* {routine?.due && (
                  <DeadlineShowingWrapper
                    date={routine.due}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeadlineAnchorEl(e.currentTarget);
                    }}
                  >
                    <Icons.Flag
                      fill={dayjs(routine.due).isToday() ? COLORS.brand1 : dayjs(routine.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                    />
                    <span style={{ marginLeft: '2px' }}>
                      {dayjs(routine.due).isToday()
                        ? '오늘'
                        : dayjs(routine.due).isYesterday()
                        ? '어제'
                        : dayjs(routine.due).isTomorrow()
                        ? '내일'
                        : dayjs(routine.due).format('M월 D일 (dd)')}
                    </span>
                    <IconButton
                      className="deadline-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDeadline(null);
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(
                            dayjs(routine!.due).isToday() ? COLORS.brand1 : dayjs(routine!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
                            0.3,
                          ),
                        },
                      }}
                      style={deadlineAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close
                        width={8}
                        height={8}
                        fill={dayjs(routine!.due).isToday() ? COLORS.brand1 : dayjs(routine!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                      />
                    </IconButton>
                  </DeadlineShowingWrapper>
                )} */}
              </RoutineHeader>
              <RoutineContent>
                {/* {routine?.place === 'DESK' && (
                  <PriorityIssueCheckbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setRoutineStatusPopover(e.currentTarget);
                    }}
                    checked={routine.done}
                    bordercolor={COLORS.issue2}
                    width={20}
                    height={20}
                  />
                )} */}
                {/* {(routine?.place === 'DRAWER_CREATED' || routine?.place === 'DRAWER_MOVED') && (
                  <Tooltip title={exceed ? '데스크가 가득 찼습니다' : '홀드 해제'} disableInteractive>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        exceed ? toast.error('데스크가 가득 찼습니다.') : onClickProjectCheck(routine!.id, 'UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueHold width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {routine?.place === 'ARCHIVE' && routine?.done && (
                  <Tooltip title={'완료 해제'} disableInteractive>
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickProjectCheck(routine!.id, 'UNLOCK');
                      }}
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                    >
                      <Icons.DeskIssueCheck />
                    </IconButton>
                  </Tooltip>
                )}
                {routine?.place === 'ARCHIVE' && !routine?.done && (
                  <Tooltip title={'취소 해제'} disableInteractive>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickProjectCheck(routine!.id, 'UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueCancel width={12} height={12} />
                    </IconButton>
                  </Tooltip>
                )} */}
                <ProjectContentTitle contentEditable={false} suppressContentEditableWarning={true} onKeyDown={(e) => onKeydownRoutine(e, routine!.id)}>
                  {routine!.title}
                </ProjectContentTitle>
              </RoutineContent>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {routine && routine.memo && (
                <div>
                  <Icons.TaskboxMemo width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {routine && routine.links && routine.links.length > 0 && (
                <div style={{ marginLeft: 4 }}>
                  <Icons.Link2 width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {routineSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length > 0 && (
                <RoutineSubtask>
                  <Icons.Subtask width={18} height={18} stroke={COLORS.gray500} />
                  <div style={{ color: COLORS.gray500, marginLeft: '4px', marginTop: 2 }}>
                    {routineSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length}
                  </div>
                </RoutineSubtask>
              )}
            </div>
          </div>
          {routineStatusPopover && (
            <Popover
              open={Boolean(routineStatusPopover)}
              anchorEl={routineStatusPopover}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ marginTop: 0.5 }}
              onClose={() => setRoutineStatusPopover(null)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RoutineStatue>
                <RoutineStatueDetail
                  onClick={() => {
                    // handleClickRoutineCheck('DONE');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCheck />
                  </IconButton>
                  완료
                </RoutineStatueDetail>
                <RoutineStatueDetail
                  onClick={() => {
                    handleClickRoutineCheck('HOLD');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueHold />
                  </IconButton>
                  홀드
                </RoutineStatueDetail>
                <RoutineStatueDetail
                  onClick={() => {
                    // handleClickRoutineCheck('CANCEL');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCancel />
                  </IconButton>
                  취소
                </RoutineStatueDetail>
              </RoutineStatue>
            </Popover>
          )}
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setCategoryAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
          {/* <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setDeadlineAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={routine && routine.due ? dayjs(routine!.due).toDate() : dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
          </Popover> */}
        </RoutineContainer>
      </div>
      <RoutineDropContainer hidden={!isDragOver} onDrop={() => handleDropToRoutine(routine!.id)} />
    </div>
  );
};

const RoutineDropContainer = styled.div`
  width: 582px;
  height: 102px;
  background-color: ${COLORS.sub5};
  border-radius: 8px;
  border: 1px dashed ${COLORS.sub4};
`;

const RoutineContainer = styled.div<{ done?: boolean; isFocus?: boolean }>`
  width: 582px;
  height: 102px;
  display: flex;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid ${COLORS.gray200};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 20px 1px ${COLORS.gray200};

    .routine-detail {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isFocus &&
    css`
      border: 1px solid ${COLORS.sub4};
    `}

  ${(props) =>
    props.done &&
    css`
      opacity: 0.6;
    `}
`;

const RoutineLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.sub4};
  border-radius: 100px;
`;

const RoutineHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RoutineContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;
`;

const ProjectContentTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-left: 8px;
`;

const RoutineSubtask = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4px;
`;

const CabinetContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CabinetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const RoutineStatue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
`;

const RoutineStatueDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 16px;
  font-size: 12px;
  width: 80px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

export default RoutineView;
