import styled from '@emotion/styled';
import { Icons } from 'components';
import { OutTaskboxDetailResponse } from 'queries/model';
import TaskBlockInputList, { TaskBlock, TaskBlockHandle } from './TaskBlockInputList';
import { Button, Divider, TextField, Tooltip } from '@mui/material';
import { COLORS } from 'styles/constants';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { useClickOutside } from '@react-hookz/web';

interface CreateListViewTaskboxProps {
  date: Date;
  taskbox: OutTaskboxDetailResponse;
  onChange?: (taskbox: OutTaskboxDetailResponse) => void;
  onCreate?: (isClick?: boolean) => void;
  onDelete?: () => void;
}

export interface ListViewTaskboxHandle {
  focus: () => void;
}

const CreateListViewTaskbox = ({ date, taskbox, onChange, onCreate, onDelete }: CreateListViewTaskboxProps, ref: any) => {
  const refTitle = useRef<HTMLInputElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const refTask = useRef<TaskBlockHandle>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      refTitle.current?.focus();
    },
  }));

  useClickOutside(refContainer, (e) => {
    const targetElement = e.target;

    if (targetElement instanceof HTMLElement && targetElement.classList.contains('MuiAutocomplete-option')) {
      return;
    }

    if (taskbox.title === '') {
      onDelete && onDelete();
    } else {
      onCreate && onCreate();
    }
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      handleCreate();
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      refTask.current?.add();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      onDelete && onDelete();
    }
  };

  const handleChangeTitle = (title: string) => {
    onChange && onChange({ ...taskbox, title });
  };

  const handleChangeTasks = (tasks: TaskBlock[]) => {
    onChange && onChange({ ...taskbox, tasks });
  };

  const handleChangeTime = async (startTime: string, endTime: string, timeDiff?: number, eventId?: string, isAllDay?: boolean) => {
    const updateTaskbox: OutTaskboxDetailResponse = isAllDay
      ? {
          ...taskbox,
          start: { date: dayjs(taskbox.start?.date || taskbox.start?.datetime).format(DATE_FORMAT_4) },
          end: {
            date: dayjs(taskbox.start?.date || taskbox.start?.datetime)
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          durationMin: timeDiff,
          allDay: isAllDay,
        }
      : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
      ? {
          ...taskbox,
          start: { date: dayjs(startTime).format(DATE_FORMAT_4) },
          end: { date: dayjs(endTime).add(1, 'day').format(DATE_FORMAT_4) },
          allDay: isAllDay,
        }
      : {
          ...taskbox,
          start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
          end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
          allDay: isAllDay,
        };
    onChange && onChange(updateTaskbox);
  };

  const handleDelete = () => {
    onDelete && onDelete();
  };

  const handleCreate = (isClick?: boolean) => {
    onCreate && onCreate(isClick);
  };

  const handleFocusOnTitle = () => {
    refTitle.current?.focus();
  };

  return (
    <Container ref={refContainer}>
      <TaskboxLine />
      <TaskboxContentWrapper>
        <TaskboxTitleWrapper>
          <Icons.PriorityIssueUncheck width={20} height={20} style={{ marginTop: 2, marginRight: 8 }} />
          <TaskboxInputWrapper isProject={taskbox.project?.title === taskbox.title}>
            <TextField
              inputRef={refTitle}
              value={taskbox.title}
              data-id={
                taskbox.type === 'TERM_TASK' ? `${taskbox.id}-${taskbox.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}` : `${taskbox.id}`
              }
              fullWidth
              autoComplete="off"
              variant="standard"
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleChangeTitle(e.target.value)}
              placeholder={'할 일을 입력해주세요'}
              InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray900, padding: '0px' } }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              sx={{
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
              }}
            />
          </TaskboxInputWrapper>
        </TaskboxTitleWrapper>
        <TaskBlockInputWrapper onClick={(e) => e.stopPropagation()}>
          <TaskBlockInputList
            ref={refTask}
            tasks={taskbox.tasks as TaskBlock[]}
            onChange={handleChangeTasks}
            onFocusOnTitle={handleFocusOnTitle}
            onCreateListViewTaskbox={handleCreate}
          />
        </TaskBlockInputWrapper>
        <Divider sx={{ margin: '12px 0px' }} />
        <Footer onClick={(e) => e.stopPropagation()}>
          <SimpleTimeSelectionModal event={taskbox} isNotPopover onChangeTime={handleChangeTime} />
          <ButtonWrapper>
            <Button
              variant="contained"
              onClick={handleDelete}
              sx={{
                'backgroundColor': COLORS.white,
                'border': `1px solid ${COLORS.gray200}`,
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': COLORS.gray900,
                'fontSize': '12px',
                'fontWeight': 'bold',
                'marginRight': '8px',
                'padding': '6px 12px',
                ':hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              취소
            </Button>
            <Tooltip
              title={
                <div style={{ padding: '4px 0px' }}>
                  <span style={{ marginRight: 8 }}>할 일 추가</span>
                  <KeyboardButtonRect>Shift</KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect>Enter</KeyboardButtonRect>
                </div>
              }
            >
              <Button
                variant="contained"
                onClick={() => handleCreate(true)}
                sx={{ borderRadius: '8px', boxShadow: 'none', fontSize: '12px', fontWeight: 'bold', padding: '6px 12px' }}
              >
                할 일 추가
              </Button>
            </Tooltip>
          </ButtonWrapper>
        </Footer>
      </TaskboxContentWrapper>
    </Container>
  );
};

export default forwardRef<ListViewTaskboxHandle, CreateListViewTaskboxProps>(CreateListViewTaskbox);

const Container = styled.div`
  display: flex;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 8px 12px;
  gap: 1rem;
`;

const TaskboxLine = styled.div`
  width: 4px;
  background-color: ${COLORS.brand1};
  border-radius: 4px;
`;

const TaskboxContentWrapper = styled.div`
  width: 100%;
`;

const TaskboxTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 2px;
`;

const TaskboxInputWrapper = styled.div<{ isProject?: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  word-break: break-all;

  :hover {
    ${(props) => props.isProject && `color: #68387F;`}
  }
`;

const TaskboxInput = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-right: 8px;

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }
`;

const TaskBlockInputWrapper = styled.div`
  margin-top: 8px;
  margin-left: -25px;
`;

const ButtonWrapper = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 4px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  color: ${COLORS.gray500};
  font-size: 12px;
  font-weight: 700;
  margin: 0px 4px;
`;
