import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { surveyAtom } from 'atoms/survey';
import { updateUserAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import { getSignupSurveyV1SurveysSignupGet, getUserMeV1UsersMeGet, recordUserSurveyV1SurveysSurveyIdPost, updateMyProfileV1UsersMePatch } from 'queries';
import { OutUserDetail, WelcomeSurveyOut } from 'queries/model';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 72px 0px;
  background-color: ${COLORS.gray200};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const SurveyWrapper = styled.div`
  .MuiTextField-root {
    height: 59px;
  }

  .MuiSelect-select {
    margin-top: 4px;
  }

  .MuiFormControlLabel-root {
    font-size: 13px;
    margin-right: 0px;
    padding-left: 16px;
  }

  .MuiInputBase-root {
    width: 260px;
    height: 36px;
    font-size: 13px;
    background-color: ${COLORS.white};
  }

  .MuiInputBase-multiline {
    width: 100%;
    height: auto;
  }

  .select {
    height: 44px;
  }

  .multiline {
    height: auto;
  }
`;

const Header = styled.div``;
const HeaderWelcome = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px 12px 0px;
  .MuiInputBase-root {
    width: 160px;
    height: 48px;
    font-size: 13px;
    background-color: ${COLORS.white};
  }
`;

const HeaderWelComeText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const SurveyQuestionWrapper = styled.div`
  width: 630px;
  padding: 24px;
  border: 1px solid ${COLORS.white};
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12), 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  margin-top: 32px;
  > div:not(:first-of-type) {
    margin-top: 24px;
  }
`;

const SurveyQuestion = styled.div``;
const SurveyQuestionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 16px;
`;

const OccupationWrapper = styled.div`
  width: 50%;
`;

const SignUpSurvey = () => {
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<WelcomeSurveyOut>();
  const [, fetchUser] = useAtom(updateUserAtom);
  const [user, setUser] = useState<OutUserDetail>();
  const [userName, setUserName] = useState('');
  const [funnelOptions, setFunnelOptions] = useState([
    {
      name: 'acquaintance',
      status: false,
      label: '지인 추천',
    },
    { name: 'slash', status: false, label: 'SLASH 팀원의 추천' },
    { name: 'instagram/facebook', status: false, label: '인스타그램/페이스북' },
    { name: 'google', status: false, label: '구글 검색' },
    { name: 'community', status: false, label: '커뮤니티 (오픈채팅방 등)' },
    { name: 'naver', status: false, label: '네이버 검색' },
    { name: 'etc', status: false, label: '기타' },
  ]);

  const [toolOptions, setToolOptions] = useState([
    { name: 'notion', status: false, label: 'Notion' },
    { name: 'jira', status: false, label: 'Jira' },
    { name: 'slack', status: false, label: 'Slack' },
    { name: 'ms', status: false, label: 'MS Teams' },
    { name: 'google', status: false, label: 'Google Workspace' },
    { name: 'asana', status: false, label: 'Asana' },
    { name: 'naver', status: false, label: '네이버웍스/라인웍스' },
    { name: 'flow', status: false, label: '플로우' },
    { name: 'dooray', status: false, label: '두레이' },
    { name: 'sunsama', status: false, label: 'Sunsama' },
    { name: 'todoist', status: false, label: 'Todoist' },
    { name: 'akiflow', status: false, label: 'Akiflow' },
    { name: 'trello', status: false, label: 'Trello' },
    { name: 'obsidian', status: false, label: 'Obsidian' },
    { name: 'ticktick', status: false, label: 'TickTick' },
    { name: 'etc', status: false, label: '기타' },
  ]);

  const [requiredError, setRequiredError] = useState({
    funnel: false,
    tool: false,
    occupation: false,
    position: false,
  });
  const [funnelEtcInput, setFunnelEtcInput] = useState('');
  const [funnelEtcInputError, setFunnelEtcInputError] = useState(false);
  const [toolEtcInput, setToolEtcInput] = useState('');
  const [toolEtcInputError, setToolEtcInputError] = useState(false);
  const [occupationEtcInput, setOccupationEtcInput] = useState('');
  const [occupationEtcInputError, setOccupationEtcInputError] = useState(false);
  const [occupation, setOccupation] = useState('');
  const [position, setPosition] = useState('');
  const [userOpinion, setUserOpinion] = useState('');

  const occupationList = [
    '마케팅',
    '제품기획(소프트웨어)',
    '상품기획(제조업/하드웨어)',
    '소프트웨어 엔지니어',
    '디자이너',
    '영업/고객 성공',
    'HR',
    '재무/전략',
    '금융',
    '전문직',
    '연구',
    '대학생',
    '기타',
  ];

  const positionList = ['관리자(대표, 임원 등)', '중간관리자(팀장 등)', '실무자', '1인 사업가 또는 프리랜서'];

  useEffect(() => {
    (async () => {
      const survey = await getSignupSurveyV1SurveysSignupGet();
      const user = await getUserMeV1UsersMeGet();
      if (!survey.isTarget) {
        if (!user?.isCalendarPicked) navigate('/calendar');
        else if (!user?.tutorialStatus && !localStorage.getItem('onboarding')) navigate('/welcome');
        else navigate('/task');
      } else {
        setSurvey(survey);
      }
      setUser(user);
      setUserName(user.name!);
    })();
  }, []);

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user) setUserName(event.target.value);
  };

  /** 유입경로  */
  const handleChangeFunnel = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = funnelOptions[idx];
    checkedOption.status = event.target.checked;
    setFunnelOptions([...funnelOptions]);
    if (requiredError.funnel) setRequiredError({ ...requiredError, funnel: false });
    if (event.target.name === 'etc') {
      setFunnelEtcInputError(false);
    }
  };

  const handleChangeFunnelEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunnelEtcInput(event.target.value);
    setFunnelEtcInputError(false);
  };

  const handleFunnelEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (funnelEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 생산성 툴  */
  const handleChangeTool = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = toolOptions[idx];
    checkedOption.status = event.target.checked;
    setToolOptions([...toolOptions]);

    if (requiredError.tool) setRequiredError({ ...requiredError, tool: false });
    if (event.target.name === 'etc') {
      setToolEtcInputError(false);
    }
  };

  const handleChangeToolEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToolEtcInput(event.target.value);
    setToolEtcInputError(false);
  };

  const handleToolEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (toolEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직군 로직 */
  const handleChangeOccupation = (event: SelectChangeEvent) => {
    setTimeout(() => {
      setOccupation(event.target.value);
      if (requiredError.occupation) setRequiredError({ ...requiredError, occupation: false });
      if (occupationEtcInputError) setOccupationEtcInputError(false);
    }, 200);
  };
  /** 직군 기타창 */
  const handleChangeOccupationEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOccupationEtcInput(event.target.value);
    setOccupationEtcInputError(false);
  };

  const handleOccupationEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (occupationEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직책 로직 */
  const handleChangePosition = (event: SelectChangeEvent) => {
    setPosition(event.target.value);
    if (requiredError.position) setRequiredError({ ...requiredError, position: false });
  };

  /** 자유 의견 로직 */
  const handleUserOpinion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserOpinion(event.target.value);
  };

  const handleSurveySubmit = async () => {
    const funnelList: any[] = funnelOptions.filter((v) => v.status).map((v) => v.name);
    const funnelEtcIndex = funnelList.findIndex((v) => v === 'etc');
    if (funnelEtcIndex !== -1) funnelList[funnelEtcIndex] = { etc: funnelEtcInput };
    const toolList: any[] = toolOptions.filter((v) => v.status).map((v) => v.name);
    const toolEtcIndex = toolList.findIndex((v) => v === 'etc');
    if (toolEtcIndex !== -1) toolList[toolEtcIndex] = { etc: toolEtcInput };

    setRequiredError({
      ...requiredError,
      funnel: funnelList.length === 0 ? true : false,
      tool: toolList.length === 0 ? true : false,
      occupation: occupation === '' ? true : false,
      position: position === '' ? true : false,
    });

    if (funnelEtcIndex !== -1 && !funnelEtcInput) setFunnelEtcInputError(true);
    if (toolEtcIndex !== -1 && !toolEtcInput) setToolEtcInputError(true);
    if (occupation === '기타' && !occupationEtcInput) setOccupationEtcInputError(true);

    if (
      funnelList.length === 0 ||
      toolList.length === 0 ||
      occupation === '' ||
      position === '' ||
      (funnelEtcIndex !== -1 && !funnelEtcInput) ||
      (toolEtcIndex !== -1 && !toolEtcInput) ||
      (occupation === '기타' && !occupationEtcInput)
    )
      return;

    const userSurveyResponse = {
      funnel: funnelList,
      tool: toolList,
      occupation: occupation === '기타' ? { etc: occupationEtcInput } : occupation,
      position: position,
      userOpinion: userOpinion,
    };

    const success =
      user!.name !== userName
        ? await Promise.all([
            updateMyProfileV1UsersMePatch({ ...user!, name: userName }),
            recordUserSurveyV1SurveysSurveyIdPost(survey!.survey!.id, userSurveyResponse),
          ])
        : await recordUserSurveyV1SurveysSurveyIdPost(survey!.survey!.id, userSurveyResponse);

    if (success) {
      if (user!.name !== userName) fetchUser();
      // '/calendar'로 변경

      if (!user?.isCalendarPicked) navigate('/calendar');
      else if (!user?.tutorialStatus && !localStorage.getItem('onboarding')) navigate('/welcome');
      else navigate('/task');
    }
  };

  return (
    <Container>
      {survey && (
        <SurveyWrapper>
          <Header>
            <Icons.SlashBeta />
            <HeaderWelcome>
              <TextField autoComplete="off" value={userName} onChange={handleChangeUserName} sx={{ marginRight: '8px' }} />
              <HeaderWelComeText>님, 반가워요!</HeaderWelComeText>
            </HeaderWelcome>
            <div>맞춤 기능을 개발할 수 있도록 아래의 4가지 질문에 답해주세요.</div>
          </Header>
          <SurveyQuestionWrapper>
            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    1. SLASH를 <span style={{ color: COLORS.sub2 }}>어떻게</span> 알게 되었나요? <span style={{ fontWeight: 400 }}>(복수선택)</span>
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {requiredError.funnel && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>필수 질문에 응답하지 않았어요.</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <FormControl component="fieldset" variant="standard" sx={{ marginTop: '8px' }}>
                <FormGroup sx={{ width: '580px', display: 'flex', flexDirection: 'row' }}>
                  {funnelOptions.map((option, idx) => (
                    <div key={option.name} style={{ width: '290px' }}>
                      <FormControlLabel
                        control={<Checkbox checked={option.status} onChange={(e) => handleChangeFunnel(e, idx)} name={option.name} />}
                        label={option.label}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              {funnelOptions.find((v) => v.name === 'etc')?.status && (
                <TextField
                  error={funnelEtcInputError}
                  autoComplete="off"
                  autoFocus
                  placeholder="직접 입력해주세요."
                  helperText={!funnelEtcInput ? '기타 항목에 내용을 입력해주세요.' : ''}
                  onChange={handleChangeFunnelEtcInput}
                  onKeyDown={handleFunnelEtcInputKeyDown}
                  sx={{ marginTop: '4px', marginLeft: '14px' }}
                />
              )}
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    2. 주로 사용하고 계신 <span style={{ color: COLORS.sub2 }}>협업툴/생산성</span> 툴을 알려주세요.{' '}
                    <span style={{ fontWeight: 400 }}>(복수선택)</span>
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {requiredError.tool && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>필수 질문에 응답하지 않았어요.</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <FormControl component="fieldset" variant="standard" sx={{ marginTop: '8px' }}>
                <FormGroup sx={{ width: '580px', display: 'flex', flexDirection: 'row' }}>
                  {toolOptions.map((option, idx) => (
                    <div key={option.name} style={{ width: '290px' }}>
                      <FormControlLabel
                        control={<Checkbox checked={option.status} onChange={(e) => handleChangeTool(e, idx)} name={option.name} />}
                        label={option.label}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              {toolOptions.find((v) => v.name === 'etc')?.status && (
                <TextField
                  error={toolEtcInputError}
                  autoComplete="off"
                  autoFocus
                  placeholder="직접 입력해주세요."
                  helperText={!toolEtcInput ? '기타 항목에 내용을 입력해주세요.' : ''}
                  onChange={handleChangeToolEtcInput}
                  onKeyDown={handleToolEtcInputKeyDown}
                  sx={{ marginTop: '4px', marginLeft: '304px' }}
                />
              )}
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontWeight: 700, marginRight: '4px' }}>
                    3. 자신의 <span style={{ color: COLORS.sub2 }}>직군과 직책</span>을 가장 잘 설명하는 것을 선택해주세요.
                  </div>
                  <div style={{ width: '16px', height: '16px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}>*</div>
                </div>
                {(requiredError.occupation || requiredError.position) && (
                  <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                    <div
                      style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: COLORS.negative1,
                        color: COLORS.white,
                        textAlign: 'center',
                        fontWeight: 700,
                        marginRight: '4px',
                      }}
                    >
                      !
                    </div>
                    <div>필수 질문에 응답하지 않았어요.</div>
                  </div>
                )}
              </SurveyQuestionTitle>
              <SelectWrapper>
                <OccupationWrapper>
                  <FormControl error={requiredError.occupation}>
                    {!occupation && <InputLabel sx={{ fontSize: '13px', top: '-3px' }}>직군을 선택해주세요.</InputLabel>}
                    <Select className="select" value={occupation} label={occupation ? '' : '직군을 선택해주세요.'} onChange={handleChangeOccupation}>
                      {occupationList.map((occupation, idx) => (
                        <MenuItem value={occupation} key={idx}>
                          {occupation}
                        </MenuItem>
                      ))}
                    </Select>
                    {requiredError.occupation && <FormHelperText>항목을 선택해주세요.</FormHelperText>}
                  </FormControl>

                  {occupation === '기타' && (
                    <TextField
                      error={occupationEtcInputError}
                      autoComplete="off"
                      autoFocus
                      placeholder="직접 입력해주세요."
                      helperText={!occupationEtcInput ? '기타 항목에 내용을 입력해주세요.' : ''}
                      onChange={handleChangeOccupationEtcInput}
                      // onBlur={handleBlurOccupationEtcInput}
                      onKeyDown={handleOccupationEtcInputKeyDown}
                      sx={{ marginTop: '4px' }}
                    />
                  )}
                </OccupationWrapper>

                <FormControl error={requiredError.position}>
                  {!position && <InputLabel sx={{ fontSize: '13px', top: '-3px' }}>직책을 선택해주세요.</InputLabel>}
                  <Select className="select" value={position} label={position ? '' : '직책을 선택해주세요.'} onChange={handleChangePosition}>
                    {positionList.map((position, idx) => (
                      <MenuItem value={position} key={idx}>
                        {position}
                      </MenuItem>
                    ))}
                  </Select>
                  {requiredError.position && <FormHelperText>항목을 선택해주세요.</FormHelperText>}
                </FormControl>
              </SelectWrapper>
            </SurveyQuestion>

            <SurveyQuestion>
              <SurveyQuestionTitle>
                <div style={{ fontWeight: 700, marginRight: '4px' }}>
                  4. 업무관리에 있어서 어떤 <span style={{ color: COLORS.sub2 }}>어려움</span>을 겪고 있으신가요?
                </div>
              </SurveyQuestionTitle>
              <TextField
                className="multiline"
                multiline
                minRows={2}
                onChange={handleUserOpinion}
                sx={{ width: '100%', fontSize: '13px', marginTop: '12px', padding: '0px 16px' }}
                placeholder="불편사항, 아이디어 등을 가감없이 제안해주시면 서비스에 적극 반영하겠습니다 :)"
               />
            </SurveyQuestion>
          </SurveyQuestionWrapper>
          <Button variant="contained" onClick={handleSurveySubmit} sx={{ width: '100%', height: '48px', marginTop: '32px', borderRadius: '8px' }}>
            제출하고 시작하기
          </Button>
        </SurveyWrapper>
      )}
    </Container>
  );
};

export default SignUpSurvey;
