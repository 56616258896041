import React, { FC, useCallback } from 'react';
import { HorizontalRuleExtension } from '@remirror/extension-horizontal-rule';
import { useCommands, useCurrentSelection } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';
import { BlockquoteExtension, TrailingNodeExtension } from 'remirror/extensions';
import { on } from 'events';
import { useActive } from '@remirror/react';

export interface TrailingNodeButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const InsertTrailingNodeButton: FC<TrailingNodeButtonProps> = (props) => {
  const { toggleBlockquote } = useCommands<TrailingNodeExtension>();
  // Force component update on selection change
  const onClose = props.onClose;
  useCurrentSelection();

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (toggleBlockquote.enabled()) {
      toggleBlockquote();
    }
  }, [toggleBlockquote]);

  const active = useActive<TrailingNodeExtension>().blockquote();
  const enabled = toggleBlockquote.enabled();

  return <CommandButton {...props} commandName="인용" active={active} enabled={enabled} onSelect={handleSelect} />;
};
