import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { has, isArray, isObject, merge, set } from 'lodash';
import { getToken, getWorkspaceToken, removeTokens } from 'utils/token';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT } from 'constants/dateFormat';

export const instance = Axios.create({ baseURL: `${process.env.REACT_APP_SERVER_URL}`.replace('/v1', '') });

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = instance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data) as any;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

instance.interceptors.request.use(
  async (config) => {
    const token = `${config.url}`.match(
      /v1\/users|v1\/notes|v1\/invitation|v1\/meeting-notes|v1\/events|v1\/timeboxes|v1\/daily-memos|v1\/tasks|v1\/slack|v1\/taskboxes|v1\/works|v1\/plans|v1\/frequentwork|v1\/autocomplete|v2\/workboxes|v1\/workboxes|v1\/memos|v2\/works|v1\/votes|v1\/reflections|v1\/surveys|v1\/record|v1\/memo|v1\/feature-storage|v1\/note|v1\/link|v1\/category|v1\/project|v1\/calendar|v1\/stat|v1\/state-storage|v1\/routine/g,
    )
      ? getWorkspaceToken()
      : getToken();

    merge(config.headers, { Authorization: `Bearer ${token}` });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function handleResponseDates(response: AxiosResponse): AxiosResponse {
  if (response.config.method === 'get' && response.data) {
    const formatDateTime = (value: string | undefined, format: string): string | undefined => {
      return value ? dayjs(value, { utc: true }).format(format) : undefined;
    };

    const formatDates = (item: any): any => {
      if (item?.start) {
        if (has(item.start, 'datetime')) {
          item.start.datetime = formatDateTime(item.start.datetime, DATE_FORMAT.TZ);
        }
      }
      if (item?.end) {
        if (has(item.end, 'datetime')) {
          item.end.datetime = formatDateTime(item.end.datetime, DATE_FORMAT.TZ);
        }
      }
      return item;
    };

    if (isArray(response.data)) {
      response.data = response.data.map(formatDates);
    } else if (isObject(response.data)) {
      response.data = formatDates(response.data);
    }
  }
  return response;
}

instance.interceptors.response.use(handleResponseDates, async (error) => {
  // 응답 에러 처리
  if (error.response && (error.response.status === 401 || error.response.status === 502 || error.response.status === 503)) {
    window.location.replace(`${window.location.origin}/signIn`);
    removeTokens();
  }
  return Promise.reject(error);
});
