import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from 'styles/constants';
import { Icons } from 'components';
import { forwardRef, useLayoutEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { createPortal } from 'react-dom';

interface TutorialProps {
  onClickTutorialButton?: (id: string) => void;
  onClickTutorialReplay?: () => void;
  onCloseTutorial?: () => void;
  replay?: boolean;
  tutorialStatus?: boolean | null;
  tutorialEl?: HTMLElement | null;
}

export const Tutorial = forwardRef(
  ({ onClickTutorialButton, onClickTutorialReplay, onCloseTutorial, replay = false, tutorialStatus = false, tutorialEl = null }: TutorialProps, _ref) => {
    const steps = [
      {
        id: 'tutorial-drag-drop',
        title: '드래그 앤 드롭',
        icon: <Icons.ArrowBack />,
        maindescription: '할 일을 캘린더에 드래그 앤 드롭하여 일정을 계획할 수 있습니다.',
        subdescription: '할 일을 캘린더에 추가할 때는 왼쪽으로 해당 아이콘을 드래그 하세요.',
        image: require('assets/images/TutorialDragDrop.png'),
      },
      {
        id: 'tutorial-calendar',
        title: '구글 캘린더에 표시하기',
        icon: <Icons.LockIn />,
        maindescription: '슬래시에서 추가한 일을 구글 캘린더에서도 확인할 수 있습니다.',
        subdescription: '구글 캘린더에서 보이게 하려면 태스크 박스에 마우스를 올려 [구글 캘린더에 표시하기]를 클릭하세요.',
        image: require('assets/images/TutorialCalendar.png'),
      },
      {
        id: 'tutorial-fold-subtask',
        title: '하위업무 접기',
        icon: <Icons.SubTaskCollapse fill={COLORS.white} />,
        maindescription: '하위업무를 접고 펼칠 수 있습니다.',
        subdescription: '펼쳐진 하위업무를 접고 싶을 때는 접기 아이콘 클릭해보세요.',
        image: require('assets/images/TutorialFoldSubtask.png'),
      },
      {
        id: 'tutorial-replay',
        title: '튜토리얼 다시보기',
        icon: <Icons.TutorialReplay />,
        maindescription: '튜토리얼을 다시 확인할 수 있습니다.',
        subdescription: '언제든지 튜토리얼 다시보기가 가능합니다.',
        image: require('assets/images/TutorialReplay.png'),
      },
    ];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [position, setPosition] = useState<DOMRect | undefined>(tutorialEl?.getBoundingClientRect());

    useLayoutEffect(() => {
      if (tutorialEl?.getBoundingClientRect()) {
        setPosition(tutorialEl.getBoundingClientRect());
      }

      const handleResize = () => {
        if (tutorialEl?.getBoundingClientRect()) {
          setPosition(tutorialEl.getBoundingClientRect());
        }
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [tutorialEl]);

    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };

    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      onClickTutorialButton && onClickTutorialButton(steps[activeStep + 1].id);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleBack = () => {
      onClickTutorialButton && onClickTutorialButton(steps[activeStep - 1].id);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useLayoutEffect(() => {
      handleNewUser();
    }, []);

    const handleNewUser = async () => {
      if (!tutorialStatus) {
        setActiveStep(3);
        onClickTutorialButton && onClickTutorialButton(steps[3].id);
      }

      if (replay) {
        setActiveStep(0);
      }
    };

    return (
      <>
        {!tutorialStatus && !replay && (
          <>
            {createPortal(<TutorialBackdrop activeStep={activeStep} position={position} />, document.body)}
            {activeStep === 1 &&
              createPortal(
                <TutorialCalendarPortal position={position}>
                  <Icons.SelectTime />
                  <Icons.Postpone />
                  <Icons.LockIn />
                  <Icons.More width={16} height={16} fill={COLORS.gray300} />
                </TutorialCalendarPortal>,
                document.body,
              )}
          </>
        )}

        {replay && (
          <TutorialReplayWrapper>
            <TutorialReplayHeader>
              <Icons.TutorialReplay width={24} height={24} stroke={COLORS.brand1} />
              <div style={{ marginLeft: '8px' }}>튜토리얼</div>
            </TutorialReplayHeader>
            <TutorialWrapper style={{ margin: '24px 0px' }}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                  return (
                    <TutorialContent key={index} hidden={index !== activeStep}>
                      <TutorialContentTitle>{step.title}</TutorialContentTitle>
                      <TutorialContentMainDescription>
                        <TutorialContentIcon index={index}>{step.icon}</TutorialContentIcon>
                        {step.maindescription}
                      </TutorialContentMainDescription>
                      <TutorialContentSubDescription>{step.subdescription}</TutorialContentSubDescription>
                      <TutorialContentImage
                        style={
                          index === 3
                            ? {
                                width: '297px',
                                height: '128px',
                              }
                            : {}
                        }
                      >
                        <img src={step.image} />
                      </TutorialContentImage>
                    </TutorialContent>
                  );
                })}
              </Stepper>
            </TutorialWrapper>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton disabled={activeStep === 0} onClick={handleBack} style={activeStep == 0 ? { opacity: 0, padding: 0 } : { padding: 0 }}>
                <Icons.ArrowLeftBig fill={COLORS.brand1} />
              </IconButton>
              <div style={{ color: COLORS.gray400, margin: '0px 4px', fontSize: '12px' }}>{`${activeStep + 1}/${steps.length - 1}`}</div>
              <IconButton disabled={activeStep === 2} onClick={handleNext} style={activeStep == 2 ? { opacity: 0, padding: 0 } : { padding: 0 }}>
                <Icons.ArrowRightBig fill={COLORS.brand1} />
              </IconButton>
            </Box>
          </TutorialReplayWrapper>
        )}

        {!tutorialStatus && !replay && (
          <TutorialWrapper activeStep={3}>
            <Stepper activeStep={3}>
              <TutorialContent>
                <TutorialContentTitle>
                  <div style={{ display: 'flex', alignItems: 'center' }}>{'튜토리얼 보기'}</div>
                </TutorialContentTitle>
                <TutorialContentMainDescription>
                  <TutorialContentIcon>{steps[steps.length - 1].icon}</TutorialContentIcon>
                  {'여기서 튜토리얼을 확인할 수 있어요.'}
                </TutorialContentMainDescription>
                <TutorialContentSubDescription>튜토리얼을 확인해 슬래시를 100% 활용해보세요.</TutorialContentSubDescription>

                <TutorialContentImage
                  style={{
                    width: '297px',
                    height: '128px',
                  }}
                >
                  <img src={steps[steps.length - 1].image} />
                </TutorialContentImage>
              </TutorialContent>
            </Stepper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  backgroundColor: COLORS.brand1,
                  color: COLORS.white,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  minWidth: '0px',
                  padding: '8px 16px',
                }}
                onClick={() => {
                  onCloseTutorial && onCloseTutorial();
                }}
              >
                알겠어요!
              </Button>

              {/* <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  onCloseTutorial && onCloseTutorial();
                  onClickTutorialReplay && onClickTutorialReplay();
                }}
                sx={{
                  backgroundColor: COLORS.brand1,
                  color: COLORS.white,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  minWidth: '0px',
                  padding: '8px 16px',
                }}
              >
                지금 확인하기
              </Button> */}
            </Box>
          </TutorialWrapper>
        )}
      </>
    );
  },
);

const TutorialCalendarPortal = styled.div<{ position?: DOMRect }>`
  width: 108px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 7px;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
  position: absolute;
  top: ${(props) => props.position!.top - 2}px;
  left: ${(props) => props.position!.left - 50}px;
  z-index: 10;
  background-color: ${COLORS.white};
`;

const TutorialWrapper = styled.div<{ activeStep?: number }>`
  padding: 24px;
  background-color: ${COLORS.white};
  border-radius: 8px;

  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0px 12px 10px 12px;
    border-color: ${COLORS.white} transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: 2px;
    left: 50%;
    @media screen and (max-width: 650px) {
      left: 8px;
    }

    ${(props) =>
      props.activeStep === 3 &&
      css`
        left: 3px;
        top: 308px;
        rotate: -90deg;
      `}
  }
`;

const TutorialBackdrop = styled.div<{ activeStep?: number; position?: DOMRect }>`
  position: absolute;
  top: 10px;
  border: 10000px solid rgba(69, 69, 69, 0.4);
  margin-left: -10000px;
  margin-top: -10000px;
  z-index: 1000;

  ${(props) =>
    props.activeStep === 0 &&
    css`
      padding: 57px 298px;
      top: ${props.position!.top - 8}px;
      left: ${props.position!.left - 7}px;
      border-radius: 10008px;
    `}

  ${(props) =>
    props.activeStep === 1 &&
    css`
      padding: 20px 60px;
      top: ${props.position!.top - 7}px;
      left: ${props.position!.left - 56}px;
      border-radius: 10008px;
    `}

  ${(props) =>
    props.activeStep === 2 &&
    css`
      padding: 18px 18px;
      top: ${props.position!.top - 5}px;
      left: ${props.position!.left - 7}px;
      border-radius: 10008px;
    `}
    
  ${(props) =>
    props.activeStep === 3 &&
    css`
      padding: 30px 30px;
      top: ${props.position!.top - 6}px;
      left: ${props.position!.left - 6}px;
      border-radius: 10008px;
    `}
`;

const TutorialContent = styled.div``;

const TutorialContentTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TutorialContentMainDescription = styled.div`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const TutorialContentIcon = styled.div<{ index?: number }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${COLORS.sub3};
  margin-right: 8px;
  padding: 4px;
  ${(props) =>
    props.index === 2 &&
    css`
      background-color: ${COLORS.sub2};
    `}

  ${(props) =>
    props.index === 3 &&
    css`
      background-color: ${COLORS.sub3};
    `}
`;

const TutorialContentSubDescription = styled.div`
  font-size: 12px;
  color: ${COLORS.gray700};
  margin-bottom: 16px;
`;

const TutorialContentImage = styled.div`
  width: 468px;
  height: 148px;
`;

const TutorialReplayWrapper = styled.div`
  width: 564px;
  padding: 24px;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TutorialReplayHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.brand1};
  font-size: 16px;
  font-weight: bold;
`;
