import styled from '@emotion/styled';
import { Button, Dialog, IconButton, TextField, Tooltip } from '@mui/material';
import { useClickOutside, useKeyboardEvent } from '@react-hookz/web';
import { Icons } from 'components';
import { ReflectionTemplate } from 'components/ReflectionTemplate/ReflectionTemplate';
import { InCreateRecord, InUpdateRecord, OutRecord, OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import {
  createRecordV1RecordPost,
  getAllRecordV1RecordGet,
  updateRecordV1RecordRecordIdPatch,
  getAllStateStorageV1StateStorageGet,
  updateStateStorageV1StateStoragePatch,
  deleteRecordV1RecordRecordIdDelete,
} from 'queries';
import { ReflectionCalendarReadOnly } from 'components/ReflectionCalendarReadOnly/ReflectionCalendarReadOnly';
import dayjs from 'lib/dayjs';
import { hideScroll } from 'styles/utils';
import toast from 'react-hot-toast';

interface ReflectionViewProps {
  taskboxes?: OutTaskboxDetailResponse[];
  record: OutRecord;
  onChangeContinueTaskbox?: () => void;
  onSaveReflection?: (record: OutRecord) => void;
}

const ReflectionView = ({ taskboxes = [], record, onChangeContinueTaskbox, onSaveReflection }: ReflectionViewProps) => {
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [highlight, setHighlight] = useState<boolean>(false);
  const [records, setRecords] = useState<OutRecord[]>([]);
  const [recordData, setRecordData] = useState<OutRecord>(record);
  const [tempRecord, setTempRecord] = useState<OutRecord>(record);
  const [completeReflection, setCompleteReflection] = useState(false);
  const [memoFocus, setMemoFocus] = useState(false);
  const memoRef = useRef<HTMLDivElement>(null);
  const [mount, setMount] = useState(false);
  const [isFirstRitual, setIsFirstRitual] = useState(false);
  const [isReflectionDialogOpen, setIsReflectionDialogOpen] = useState(false);
  const [randomReflectionCard, setRandomReflectionCard] = useState<{ content: string; type: string; record: OutRecord | null }>();
  const [isRandomReflectionCardOpen, setIsRandomReflectionCardOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const selectRandomReflectionCard = (records: OutRecord[], previousRandomCard?: OutRecord) => {
    const n = records.length;
    const types = ['keep', 'problem', 'try'];
    let maxRepeat = 30;

    while (n > 0 && maxRepeat > 0) {
      maxRepeat -= 1;
      const randomIndex = Math.floor(Math.random() * n);
      const randomTypeIndex = Math.floor(Math.random() * 3);

      const previousIndex = records.findIndex((record) => record.date === previousRandomCard?.date);
      const type = types[randomTypeIndex];

      if ((randomIndex !== previousIndex || records.length === 1) && records[randomIndex].content[type] && records[randomIndex].content[type] !== '') {
        return { content: records[randomIndex].content[type], type: type, record: records[randomIndex] };
      }
    }

    return { content: '', type: '', record: null };
  };

  useEffect(() => {
    const fetchRecords = async () => {
      const firstRitual = await getAllStateStorageV1StateStorageGet();
      setIsFirstRitual(!firstRitual.data.hasSeenReflection ?? true);

      const records = await getAllRecordV1RecordGet({
        startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      });

      setRecords(records);
      setRandomReflectionCard(selectRandomReflectionCard(records));
      setRender(true);
    };

    fetchRecords();
  }, []);

  useEffect(() => {
    setRecordData(record);
    if (record.createdAt !== '') {
      setCompleteReflection(true);
    } else {
      setCompleteReflection(false);
    }
  }, [record]);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement as HTMLElement;

      if (!element) return;

      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
      ) {
        return;
      }

      if (!ev.ctrlKey && !ev.metaKey && ev.code === 'KeyR' && !isFirstRitual && !completeReflection) {
        setTimeout(() => {
          setIsReflectionDialogOpen(true);
        }, 100);
      }
    },
    [],
    { event: 'keydown', eventOptions: { passive: true } },
  );

  useLayoutEffect(() => {
    if (mount) {
      if (taskboxes.find((v) => v.focus)) setHighlight(true);
      else setHighlight(false);
    }
    setMount(true);
  }, [taskboxes]);

  useClickOutside(memoRef, () => {
    setMemoFocus(false);
  });

  const handleCloseGuide = async () => {
    await updateStateStorageV1StateStoragePatch({ data: { hasSeenReflection: true } });
    setIsFirstRitual(false);
  };

  const handleCompleteEdit = async () => {
    if (!recordData) return;
    const inUpdateRecord: InUpdateRecord = {
      content: recordData.content,
      date: recordData.date,
      status: 'ACTIVE',
    };
    await updateRecordV1RecordRecordIdPatch(recordData.id, inUpdateRecord);
    setRecordData(recordData);
    setIsReflectionDialogOpen(false);
  };

  const handleCreateRecord = async () => {
    if (!recordData) return;
    const inCreateRecord: InCreateRecord = {
      content: recordData.content,
      date: recordData.date,
    };

    try {
      const response = await createRecordV1RecordPost(inCreateRecord);
      setRecordData(response);
      setRecords([...records, response]);
    } catch (e) {
      toast.error('회고 작성에 실패했습니다.');
    } finally {
      setCompleteReflection(true);
      setIsReflectionDialogOpen(false);
    }
  };

  const handleDeleteRecord = async (record: OutRecord) => {
    try {
      await deleteRecordV1RecordRecordIdDelete(record.id);
      toast.success('회고가 삭제되었습니다.');
      const newRecords = records.filter((v) => v.id !== record.id);
      setRecords(newRecords);
      setRecordData({
        date: dayjs().format('YYYY-MM-DD'),
        id: '',
        status: 'ACTIVE',
        createdAt: '',
        updatedAt: '',
        comboHit: 0,
        content: {},
      });
    } catch (e) {
      toast.error('삭제에 실패했습니다.');
    } finally {
      setIsReflectionDialogOpen(false);
      setCompleteReflection(false);
    }
  };

  return (
    <Container>
      <HighlightWrapper>
        <HighlightHeader>
          <Icons.FocusTime />
          <div style={{ marginLeft: '4px' }}>오늘의 하이라이트</div>
        </HighlightHeader>
        <HighlightContent highlight={Boolean(taskboxes.find((v) => v.focus)) && taskboxes.find((v) => v.focus)!.done}>
          {!taskboxes.find((v) => v.focus) && (
            <>
              <HighLightContentIcon highlight={false}>!</HighLightContentIcon>
              <div>오늘 가장 중요한 일을 &apos;하이라이트&apos; 해보세요!</div>
            </>
          )}
          {taskboxes.find((v) => v.focus) && !taskboxes.find((v) => v.focus)!.done && highlight && (
            <>
              <HighLightContentIcon highlight={false}>!</HighLightContentIcon>
              <div>좋아요! &apos;하이라이트한&apos; 일만 해내도 오늘은 성공이에요!</div>
            </>
          )}
          {taskboxes.find((v) => v.focus) && !taskboxes.find((v) => v.focus)!.done && !highlight && (
            <>
              <HighLightContentIcon highlight={false}>!</HighLightContentIcon>
              <div>오늘 가장 중요한 일을 아직 마치지 못했어요.</div>
            </>
          )}
          {taskboxes.find((v) => v.focus) && taskboxes.find((v) => v.focus)!.done && (
            <>
              <HighLightContentIcon highlight={true}>
                <Icons.Check fill={COLORS.white} />
              </HighLightContentIcon>
              <div>가장 중요한 일을 완료했어요! 완벽해요 🙌</div>
            </>
          )}
        </HighlightContent>
        {render &&
          (isFirstRitual ? (
            <ContentWrapper style={{ backgroundColor: COLORS.gray100 }}>
              <Tooltip title="가이드 닫기" disableInteractive>
                <IconButton
                  sx={{
                    'position': 'absolute',
                    'top': '10px',
                    'right': '7px',
                    'padding': '3px',
                    'borderRadius': '6px',
                    ':hover': {
                      backgroundColor: COLORS.gray200,
                    },
                  }}
                  onClick={handleCloseGuide}
                >
                  <Icons.Close width={18} height={18} fill={COLORS.gray400} />
                </IconButton>
              </Tooltip>
              <img src={require('assets/images/FirstRitual.png')} style={{ width: '136px', height: '136px' }} />
              <ContentTitle>미완료 업무는 내일로 미뤄봐요.</ContentTitle>
              <ContentSubTitle>
                <Button
                  variant="contained"
                  sx={{ padding: '4px 8px', borderRadius: '4px', fontWeight: 700, pointerEvents: 'none', fontSize: '8px', marginRight: '4px' }}
                >
                  <Icons.DoubleCheck style={{ marginRight: '2px', width: '10px', height: '10px' }} />
                  오늘 마무리하기
                </Button>
                하면 한 번에 내일로 옮겨져요.
              </ContentSubTitle>
            </ContentWrapper>
          ) : completeReflection ? (
            <>
              <div style={{ display: 'flex', fontSize: '13px', fontWeight: 700, marginTop: '32px' }}>
                <img src={require('assets/images/reflectionImg.png')} style={{ width: '20px', height: '20px', marginRight: '4px' }} />
                나의 회고
              </div>
              <ContentWrapper style={{ justifyContent: 'start', backgroundColor: COLORS.gray100, padding: '24px', flex: 0, marginTop: '8px' }}>
                <ContentTitle style={{ marginTop: '0px' }}>회고록 작성 완료</ContentTitle>
                <ContentSubTitle style={{ marginBottom: '24px' }}>수고하셨어요! 한 걸음 더 성장했어요.</ContentSubTitle>
                <ReflectionCalendarReadOnly
                  records={records}
                  monthFontSize={11}
                  monthMarginBottom={17}
                  dateFontSize={9}
                  dateMarginBottom={8}
                  dateWidth={26}
                  dayLabelFontSize={7}
                  dayLabelHeight={13}
                  dayLabelMarginBottom={3}
                />
                <Button
                  style={{
                    fontSize: '12px',
                    fontWeight: 600,
                    marginTop: '24px',
                  }}
                  sx={{
                    ':focus': { display: 'none' },
                  }}
                  onClick={() => {
                    setTempRecord(recordData);
                    setIsReflectionDialogOpen(true);
                  }}
                >
                  <Icons.Edit width={16} height={16} stroke={COLORS.sub2} style={{ margin: '0px 4px 2px 0px' }} />
                  수정하기
                </Button>
              </ContentWrapper>
            </>
          ) : (
            <>
              {records.length > 0 && randomReflectionCard && randomReflectionCard.record && (
                <>
                  <div style={{ display: 'flex', fontSize: '13px', fontWeight: 700, marginTop: '32px' }}>
                    <img src={require('assets/images/reflectionImg.png')} style={{ width: '20px', height: '20px', marginRight: '4px' }} />
                    나의 회고
                  </div>
                  <RandomReflectionCard
                    onClick={() => {
                      setIsRandomReflectionCardOpen(true);
                    }}
                  >
                    {randomReflectionCard?.type === 'keep' ? (
                      <RandomReflectionCardType style={{ backgroundColor: COLORS.positive2 }}>😀&nbsp;&nbsp;Keep</RandomReflectionCardType>
                    ) : randomReflectionCard?.type === 'problem' ? (
                      <RandomReflectionCardType style={{ backgroundColor: COLORS.negative2 }}>😡&nbsp;&nbsp;Problem</RandomReflectionCardType>
                    ) : (
                      <RandomReflectionCardType style={{ backgroundColor: COLORS.sub3 }}>👉&nbsp;&nbsp;Try</RandomReflectionCardType>
                    )}

                    <RandomReflectionCardContent length={randomReflectionCard.content.length}>{randomReflectionCard?.content}</RandomReflectionCardContent>
                    <RandomReflectionCardFooter>
                      <RandomReflectionCardDate>{dayjs(randomReflectionCard.record.date).format('YYYY년 M월 D일')}</RandomReflectionCardDate>
                      <RandomReflectionCardIconBox>
                        <Tooltip title="다른 회고 보기" disableInteractive>
                          <IconButton
                            sx={{
                              'padding': '4px',
                              ':hover': {
                                backgroundColor: COLORS.gray100,
                              },
                            }}
                            onClick={(event) => {
                              setRandomReflectionCard(selectRandomReflectionCard(records, randomReflectionCard.record!));
                              event?.stopPropagation();
                            }}
                          >
                            <Icons.Reload width={16} height={16} fill={COLORS.gray400} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="최근 작성한 회고를 랜덤으로 표시해요" disableInteractive>
                          <IconButton
                            sx={{
                              'padding': '4px',
                              ':hover': {
                                backgroundColor: COLORS.gray100,
                              },
                            }}
                          >
                            <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray400} />
                          </IconButton>
                        </Tooltip>
                      </RandomReflectionCardIconBox>
                    </RandomReflectionCardFooter>
                  </RandomReflectionCard>
                </>
              )}
              <ContentWrapper>
                <img src={require('assets/images/reflectionImg2.png')} style={{ width: '150px', height: '150px' }} />
                <div style={{ width: '100px', height: '100px', position: 'absolute', filter: 'blur(20px)' }}>asd</div>
                <ContentTitle>어떤 하루를 보냈나요?</ContentTitle>
                <ContentSubTitle>회고로 하루 1% 성장을 완성해봐요.</ContentSubTitle>
                <ReflectionBtn
                  onClick={() => {
                    setTempRecord(recordData);
                    setIsReflectionDialogOpen(true);
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: COLORS.brand1,
                      color: COLORS.white,
                    },
                  }}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <Icons.Edit width={16} height={16} stroke={isHover ? COLORS.white : COLORS.brand1} style={{ marginRight: '6px' }} />
                  회고 작성하기
                  <KeyboardButtonRect isHover={isHover}>R</KeyboardButtonRect>
                </ReflectionBtn>
              </ContentWrapper>
            </>
          ))}
      </HighlightWrapper>
      <Dialog
        open={isReflectionDialogOpen}
        onClose={() => {
          if (recordData.content && (recordData.content.memo || recordData.content.keep || recordData.content.problem || recordData.content.try)) {
            if (completeReflection) {
              handleCompleteEdit();
            } else {
              handleCreateRecord();
            }
          } else {
            setRecordData(tempRecord);
            setIsReflectionDialogOpen(false);
          }
        }}
        maxWidth="md"
      >
        <div style={{ height: '726px', width: '790px' }}>
          <ReflectionTemplate
            readOrcreateOrUpdate={completeReflection ? 'update' : 'create'}
            record={recordData!}
            padding="0px 80px 32px 80px"
            closeDialog={() => {
              setRecordData(tempRecord);
              setIsReflectionDialogOpen(false);
            }}
            onDelete={handleDeleteRecord}
            createOrUpdateRecord={completeReflection ? handleCompleteEdit : handleCreateRecord}
            onChange={setRecordData}
          />
        </div>
      </Dialog>
      <Dialog
        open={isRandomReflectionCardOpen}
        onClose={() => {
          setIsRandomReflectionCardOpen(false);
        }}
        maxWidth="md"
      >
        <div style={{ minHeight: '336px', width: '790px' }}>
          <ReflectionTemplate
            readOrcreateOrUpdate={'read'}
            record={
              randomReflectionCard?.record ?? {
                date: dayjs().format('YYYY-MM-DD'),
                id: '',
                status: 'ACTIVE',
                createdAt: '',
                updatedAt: '',
                comboHit: 0,
                content: {},
              }
            }
            padding="0px 80px 32px 80px"
            closeDialog={() => {
              setIsRandomReflectionCardOpen(false);
            }}
          />
        </div>
      </Dialog>
    </Container>
  );
};

export default ReflectionView;

const Container = styled.div`
  width: 22%;
  min-width: 344px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-left: 1px solid ${COLORS.gray300};
  background-color: ${COLORS.white};
  overflow-y: scroll;
  ${hideScroll()};
  .codex-editor__redactor {
    padding-bottom: 10px !important;
  }
`;

const HighlightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; ;
`;

const HighlightHeader = styled.div`
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const HighlightContent = styled.div<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 12px;
  ${({ highlight }) => (highlight ? `background-color: ${COLORS.positive2};` : `background-color: ${COLORS.alert2};`)}
`;

const HighLightContentIcon = styled.div<{ highlight?: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  font-weight: 700;
  color: ${COLORS.white};
  ${({ highlight }) => (highlight ? `background-color: ${COLORS.positive1};` : `background-color: ${COLORS.alert1};`)}
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0px;
  border-radius: 8px;
`;

const ContentTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 8px;
`;

const ContentSubTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
`;

const ReflectionBtn = styled(Button)`
  margin-top: 20px;
  font-size: 13px;
  font-weight: 700;
  color: ${COLORS.brand1};
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid ${COLORS.brand1};
  line-height: 20px;
`;

const KeyboardButtonRect = styled.div<{ isHover?: boolean }>`
  margin-left: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.gray500};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 0px 0px 0px;
  ${({ isHover }) => isHover && `background-color: ${COLORS.white};`}
`;

const RandomReflectionCard = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  margin-top: 8px;
  :hover {
    box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  }
`;

const RandomReflectionCardType = styled.div`
  width: fit-content;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
`;

const RandomReflectionCardContent = styled.div<{ length: number }>`
  color: ${COLORS.gray900};
  font-size: ${(props) => (props.length < 30 ? '24px' : props.length < 100 ? '20px' : '13px')};
  font-weight: 400;
  margin-bottom: 11px;
  width: 100%;
  overflow-y: scroll;
  word-wrap: break-word;
  max-height: 120px;
  ${hideScroll()}
`;

const RandomReflectionCardFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const RandomReflectionCardDate = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray600};
  padding-top: 4px;
`;

const RandomReflectionCardIconBox = styled.div`
  display: flex;
`;
