import styled from '@emotion/styled';
import { Button, Checkbox, Dialog, TextField } from '@mui/material';
import { PriorityIssueCheck } from 'components/Icons';
import { useAuthAction } from 'hooks/useAuthAction';
import { useState } from 'react';
import { COLORS } from 'styles/constants';

interface DeleteProps {
  open?: boolean;
  isDelete?: boolean;
  onClose?: () => void;
  onClick?: (reason: string) => void;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const DeleteEmoji = styled.div`
  font-size: 13px;
  font-weight: 700;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: ${COLORS.sub3};
`;

const DeleteText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0px;
`;

const DeleteCheck = styled.div`
  width: 100%;
`;

const DeleteCheckTextField = styled.div`
  font-size: 13px;
  margin-bottom: 12px;
`;

const DeleteCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 24px;
`;

const DeleteButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DeleteAccountDialog = ({ open = false, isDelete = false, onClose, onClick }: DeleteProps) => {
  const [reason, setReason] = useState<string | null>(null);
  const [clickReason, setClickReason] = useState<boolean>(false);
  const [checkText, setCheckText] = useState<string | null>(null);
  const [clickCheckText, setClickCheckText] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const { deleteAccount } = useAuthAction();

  const handleChangeDeleteReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleClickDeleteReason = () => {
    setClickReason(true);
  };

  const handleChangeCheckText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckText(e.target.value);
  };

  const handleClickCheckText = () => {
    setClickCheckText(true);
  };

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleClose = () => {
    onClose && onClose();
    setReason(null);
    setClickReason(false);
    setCheckText(null);
    setClickCheckText(false);
    setChecked(false);
  };

  const handleClickDeleteAccount = () => {
    if (!reason) return;
    onClick && onClick(reason);
  };

  const handleClcikConfirm = () => {
    deleteAccount();
  };

  return (
    <Dialog
      open={open}
      onClose={!isDelete ? handleClose : undefined}
      slotProps={{
        backdrop: {
          sx: { backdropFilter: 'blur(8px)' },
        },
      }}
    >
      {isDelete ? (
        <Container style={{ width: '456px' }}>
          <PriorityIssueCheck width={60} height={60} fill={COLORS.sub2} stroke={COLORS.sub2} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '40px 0px 24px 0px' }}>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '8px' }}>계정 삭제 완료</div>
            <div>그동안 SLASH를 이용해 주셔서 감사합니다.</div>
          </div>

          <Button variant="contained" onClick={handleClcikConfirm} sx={{ width: '198px', padding: '14px 16px', borderRadius: '8px', fontSize: '13px' }}>
            확인
          </Button>
        </Container>
      ) : (
        <Container>
          <DeleteEmoji>
            <span>😭</span>
            <span style={{ marginLeft: '8px' }}>계정삭제</span>
          </DeleteEmoji>
          <DeleteText>
            <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '8px' }}>계정을 삭제하시겠어요?</div>
            <div style={{ fontSize: '13px' }}>아래 지시사항에 따라 진행해주세요.</div>
          </DeleteText>
          <DeleteCheck>
            <DeleteCheckTextField>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ marginRight: '4px' }}>삭제 이유가 무엇인가요?</div>
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    backgroundColor: COLORS.gray100,
                    textAlign: 'center',
                    fontSize: '14px',
                  }}
                >
                  *
                </div>
              </div>

              <TextField
                fullWidth
                required
                autoComplete="off"
                error={!reason && clickReason}
                helperText={!reason && clickReason ? '삭제 이유를 입력해주세요.' : ''}
                placeholder="내용을 입력해주세요."
                onChange={handleChangeDeleteReason}
                onBlur={handleClickDeleteReason}
                inputProps={{ style: { fontSize: '13px' } }}
              />
            </DeleteCheckTextField>
            <DeleteCheckTextField style={{ marginBottom: '24px' }}>
              <div style={{ marginBottom: '8px' }}>{`계속 하려면 "계정 삭제하기"를 입력해주세요.`} </div>
              <TextField
                fullWidth
                required
                autoComplete="off"
                error={checkText !== '계정 삭제하기' && clickCheckText}
                helperText={checkText !== '계정 삭제하기' && clickCheckText ? '입력한 텍스트가 일치하지 않습니다.' : ''}
                placeholder={`"계정 삭제하기" 입력하기`}
                onChange={handleChangeCheckText}
                onBlur={handleClickCheckText}
                inputProps={{ style: { fontSize: '13px' } }}
              />
            </DeleteCheckTextField>
            <DeleteCheckbox>
              <Checkbox checked={checked} onChange={handleChangeCheck} sx={{ padding: '0px', marginRight: '8px' }} />
              <div>개인정보가 모두 삭제됨을 이해했으며 진행을 원합니다.</div>
            </DeleteCheckbox>
          </DeleteCheck>
          <DeleteButton>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                'width': '192px',
                'padding': '14px 16px',
                'borderRadius': '8px',
                'fontSize': '13px',
                'marginRight': '24px',
                'backgroundColor': COLORS.gray100,
                'color': COLORS.gray900,
                'boxShadow': 'none',
                '&:hover': {
                  backgroundColor: COLORS.gray200,
                },
              }}
            >
              취소하기
            </Button>
            <Button
              variant="contained"
              color="error"
              disabled={reason && checkText === '계정 삭제하기' && checked ? false : true}
              onClick={handleClickDeleteAccount}
              sx={{ width: '192px', padding: '14px 16px', borderRadius: '8px', fontSize: '13px', backgroundColor: COLORS.negative1, boxShadow: 'none' }}
            >
              계정 삭제하기
            </Button>
          </DeleteButton>
        </Container>
      )}
    </Dialog>
  );
};

export default DeleteAccountDialog;
