import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'styles/index.css';
import theme from './styles/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider as JotaiProvider } from 'jotai';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn:
    process.env.REACT_APP_MODE === 'development'
      ? 'https://a1e7b9858dd1ad0492aecfc398bc7d2c@o4507339290509312.ingest.us.sentry.io/4507540872953856'
      : 'https://9b9fce875d0af8859621206333796c37@o4507339290509312.ingest.us.sentry.io/4507540881211392',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // 성능 모니터링
  tracesSampleRate: process.env.REACT_APP_MODE === 'development' ? 1 : 0.01,
  // tracePropagationTargets를 설정하여 분산 트레이싱을 활성화할 URL을 제어
  tracePropagationTargets:
    process.env.REACT_APP_MODE === 'development' ? ['localhost', /^https:\/\/staging.doslash\.io\/api/] : [/^https:\/\/api.doslash\.io\/api/],
  // 세션 리플레이
  replaysSessionSampleRate: process.env.REACT_APP_MODE === 'development' ? 1.0 : 0.001, // 세션 샘플 비율을 10%로 설정. 개발 중에는 100%로 설정하고 프로덕션에서는 낮은 비율로 샘플링.
  replaysOnErrorSampleRate: 1.0, // 오류가 발생한 세션을 샘플링할 때 샘플 비율을 100%로 변경.
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <JotaiProvider>
          <CssBaseline />
          <App />
        </JotaiProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </>,
);
