import { SVGProps } from 'react';

export const TimerStart = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M4.82574 1.61573C4.02612 1.12707 3 1.70255 3 2.63967V9.36033C3 10.2974 4.02612 10.8729 4.82574 10.3843L10.3245 7.02394C11.0901 6.55602 11.0901 5.44398 10.3245 4.97606L4.82574 1.61573Z"
        fill="#2D6ADF"
      />
    </svg>
  );
};
