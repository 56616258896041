import { SVGProps } from 'react';

export const Plus = ({ width = 24, height = 24, fill = '#1F2023', strokeWidth = 1.5, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12 4V20" stroke={fill} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12L4 12" stroke={fill} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
