import { COLORS } from '../../styles/constants';
import { Button } from '@mui/material';
import { OutOAuthUser, OutUserDetail } from '../../queries/model';
import Fonts from '../../components/Fonts';
import styled from '@emotion/styled';
import { useAuthAction } from 'hooks/useAuthAction';
import { useNavigate } from 'react-router-dom';

interface Props {
  hidden?: boolean;
  user?: OutUserDetail;
}

const Container = styled.div`
  padding: 20px 20px 0px 20px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
`;

const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;

const ProfileContext = styled.div``;

const EmptyProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${COLORS.gray600};
`;

export const Profile = ({ hidden, user }: Props) => {
  const { logout } = useAuthAction();
  const navigate = useNavigate();
  return (
    <Container hidden={hidden}>
      <ProfileWrapper>
        <ProfileImage>{user?.name ? <img style={{ width: 60, height: 60 }} src={user?.profileImage} /> : <EmptyProfileImage />}</ProfileImage>
        <ProfileContext>
          <Fonts.H3>{user?.name} </Fonts.H3>
          <Fonts.Blockquote style={{ color: COLORS.gray500 }}>{user?.email}</Fonts.Blockquote>
        </ProfileContext>
      </ProfileWrapper>
      <Button
        disableRipple={true}
        disableElevation={true}
        onClick={() => logout('/signIn')}
        variant="outlined"
        sx={{
          'color': COLORS.gray900,
          'width': '100%',
          'height': 54,
          'border': `1px solid ${COLORS.gray200}`,
          'marginTop': '20px',
          'borderRadius': '8px',
          '&:hover': {
            borderColor: 'transparent',
          },
        }}
      >
        로그아웃
      </Button>
      <Button
        disableRipple={true}
        disableElevation={true}
        onClick={() => navigate('/task')}
        variant="outlined"
        sx={{
          'color': COLORS.gray900,
          'width': '100%',
          'height': 54,
          'border': `1px solid ${COLORS.gray200}`,
          'marginTop': '20px',
          'borderRadius': '8px',
          '&:hover': {
            borderColor: 'transparent',
          },
        }}
      >
        PC 버전으로 보기
      </Button>
    </Container>
  );
};

export default Profile;
