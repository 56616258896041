import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, Divider, Drawer, IconButton, Popover, Popper, Skeleton, TextField, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { CreateTaskboxTask, InCreateLink, OutCategory, OutLink, OutTaskboxDetailResponse, OutTaskboxDetailResponseForSubtask } from 'queries/model';
import dayjs, { Dayjs } from 'lib/dayjs';
import {
  createCategoryV1CategoryPost,
  createLinkV1LinkPost,
  createTaskboxV1TaskboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  updateLinkV1LinkLinkIdPatch,
  updateTaskboxV1TaskboxesTaskboxIdPut,
} from 'queries';
import toast from 'react-hot-toast';
import { useEventListener, useUpdateEffect } from '@react-hookz/web';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { useAtom } from 'jotai';
import { categoryAtom } from 'atoms/category';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { v4 as uuidv4 } from 'uuid';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType } from 'remirror';
import { isMouseInsideMemoContainer } from 'components/Remirror/utils';
import { hideScroll } from 'styles/utils';
import ConfirmDialog, { DialogElement } from 'components/ConfirmDialog';
import { sidePanelWidthWithCalendarAtom } from 'atoms/foldView';
import { TaskboxCheckbox } from './TaskboxCreationView';
import TaskboxCalendar, { TaskboxCalendarMenuItem } from './TaskboxCalendar';
import { DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { syncTaskboxDetailViewAtom } from 'atoms/taskboxDetail';
import { useDebouncedSync } from 'utils/debounce';

const ProjectContainer = styled.div<{ left?: number; isAnimation?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  padding: 32px;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(${(props) => props.left! + 5000}px);
  transition: transform 0.4s ease-in-out;
  z-index: 103;

  ${(props) =>
    props.isAnimation &&
    `
      transform: translateX(0);
  `}
`;

const ContentHeaderWrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  min-height: 392px;
  overflow-y: scroll;
  ${hideScroll};
`;

const MemoLinkWrapper = styled.div``;

const ContentHeader = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

const TaskboxDetailTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CategoryDeadlineWrapper = styled.div`
  margin-top: 20px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const MemoWrapper = styled.div``;

const LinkWrapper = styled.div`
  padding: 16px 0px;
`;

const LinkContentWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  position: relative;

  .link-icon {
    opacity: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :hover {
    background-color: ${COLORS.gray100};
    .link-icon {
      opacity: 1;
    }
  }
`;

const CreateLinkButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const LinkPopoverContainer = styled.div`
  width: 365px;
`;

const LinkPopoverWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
`;

const LinkURLWrapper = styled.div``;

const LinkTitleWrapper = styled.div`
  margin-top: 12px;
`;

const LinkDeleteWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const TaskboxCompletionWrapper = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const TaskboxCompletionButton = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

export type ProjectFilter = 'ALL' | 'SUBTASK';

export interface TaskboxDetailViewProps {
  isSide?: boolean;
  taskboxDetail?: OutTaskboxDetailResponse;
  onClose?: (taskbox?: OutTaskboxDetailResponse) => void;
  onDelete?: (id: string) => void;
  onFetch?: () => void;
}

const TaskboxDetailView = ({ taskboxDetail, isSide = false, onClose, onDelete, onFetch }: TaskboxDetailViewProps) => {
  const [taskbox, setTaskbox] = useState<OutTaskboxDetailResponse | null>(taskboxDetail!);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedLink, setSelectedLink] = useState<OutLink | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState<HTMLElement | null>(null);
  const [taskboxMemoEle, setTaskboxMemoEle] = useState<Element | null>(null);
  const [isAnimation, setIsAnimation] = useState<boolean>(!!taskbox);
  const [taskViewSidePanel] = useAtom(sidePanelWidthWithCalendarAtom);
  const refProjectDetail = useRef<HTMLDivElement>(null);
  const refCreateLinkInput = useRef<HTMLInputElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const refConfirm = useRef<DialogElement>(null);
  const [taskboxMemo, setTaskboxMemo] = useState(taskbox?.memo);
  const [isMemoReady, setIsMemoReady] = useState(false);
  const [taskboxCompletionPopover, setTaskboxCompletionPopover] = useState<HTMLElement | null>(null);
  const [taskboxCalendarPopover, setTaskboxCalendarPopover] = useState<HTMLElement | null>(null);
  const [syncTaskboxDetailView, setSyncTaskboxDetailView] = useAtom(syncTaskboxDetailViewAtom);

  useLayoutEffect(() => {
    setIsAnimation(!!taskboxDetail);
    removeMemoHandle();
    if (taskboxDetail) {
      updateTaskboxDetails();
      resetTaskboxMemo();
    } else {
      if (isSide) handleSaveTaskbox();
      clearProjectMemo();
    }
  }, [taskboxDetail]);

  useUpdateEffect(() => {
    if (syncTaskboxDetailView && syncTaskboxDetailView.syncType === 'taskbox') {
      setTaskbox(syncTaskboxDetailView);
    }
  }, [syncTaskboxDetailView]);

  useEventListener(
    taskboxMemoEle,
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, taskboxMemoEle as Element);
      if (!inside) {
        hideMemoHandle();
      }
    },
    { passive: true },
  );

  const hideMemoHandle = () => {
    const element = document.querySelector('.empty-taskbox-detail-memo') || document.querySelector('.taskbox-detail-memo');
    if (element) {
      element.querySelector('.memo-drag-handle')?.classList.add('hidden');
      element.querySelector('.memo-menu-handle')?.classList.add('hidden');
    }
  };

  const removeMemoHandle = () => {
    const element = document.querySelector('.empty-taskbox-detail-memo') || document.querySelector('.taskbox-detail-memo');
    if (element) {
      element.querySelector('.memo-drag-handle')?.remove();
      element.querySelector('.hidden')?.remove();
      element.querySelector('.memo-menu-handle')?.remove();
      element.querySelector('.hidden')?.remove();
    }
  };

  const updateTaskboxDetails = () => {
    if (!taskboxDetail) return;
    setTaskbox(taskboxDetail);
    const taskboxMemo = document.querySelector('.taskbox-detail-memo');
    const remirror = taskboxMemo?.firstChild as HTMLDivElement;
    setTaskboxMemoEle(taskboxMemo);
    if (remirror) remirror.classList.add('taskbox-detail-memo');
  };

  const resetTaskboxMemo = () => {
    setIsMemoReady(false);
    setTaskboxMemo(undefined);

    setTimeout(() => {
      setTaskboxMemo(taskboxDetail?.memo);
      setIsMemoReady(true);
    }, 500);
  };

  const clearProjectMemo = () => {
    setTimeout(() => {
      setTaskboxMemo(undefined);
      setIsMemoReady(false);
    }, 500);
  };

  const handleOpenDeleteDialog = () => {
    if (!taskbox) return;
    refConfirm.current?.open();
  };

  const handleSaveTaskbox = async () => {
    if (!taskbox) return;
    onClose && onClose(taskbox);
    setIsAnimation(false);
    // setProject(null);
  };

  const handleUpdateTaskboxTitle = async () => {
    if (!taskbox) return;
    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { title: taskbox.title });
    } catch (e) {
      toast.error('프로젝트 이름을 수정할 수 없습니다.');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      const active = document.activeElement as HTMLElement;
      active.blur();
      e.preventDefault();
      handleUpdateTaskboxTitle();
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current!);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current!);
      }, 100);
    }
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!taskbox) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            if (taskbox.title?.includes('#')) {
              setTaskbox({ ...taskbox, title: taskbox.title.replace(/#/g, ''), category: category ? category : undefined });
              setSyncTaskboxDetailView({ ...taskbox, title: taskbox.title.replace(/#/g, ''), category: category ? category : undefined, syncType: 'detail' });
              await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
                title: taskbox.title.includes('#') ? taskbox.title.replace(/#/g, '') : taskbox.title,
                categoryId: category ? [category.id] : [],
              });
            } else {
              setTaskbox({ ...taskbox, category: category ? category : undefined });
              setSyncTaskboxDetailView({ ...taskbox, category: category ? category : undefined, syncType: 'detail' });
              await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
                categoryId: category ? [category.id] : [],
              });
            }
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 선택할 수 없습니다.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost({ id: uuidv4(), name: category?.name, color: category?.color });
            fetchCategoryList();
          } catch (e) {
            toast.error('카테고리를 생성할 수 없습니다.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 삭제할 수 없습니다.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 수정할 수 없습니다.');
          }
        }
        break;
    }
  };

  const handleChangeDeadline = async (date: Dayjs | null) => {
    if (!taskbox) return;
    setDeadlineAnchorEl(null);

    try {
      if (taskbox.title?.includes('$')) {
        setTaskbox({ ...taskbox, title: taskbox.title.replace(/\$/g, ''), deadline: date ? date.format('YYYY-MM-DD') : null });
        setSyncTaskboxDetailView({
          ...taskbox,
          title: taskbox.title.replace(/\$/g, ''),
          deadline: date ? date.format('YYYY-MM-DD') : null,
          syncType: 'detail',
        });
        await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
          title: taskbox.title.includes('$') ? taskbox.title.replace(/\$/g, '') : taskbox.title,
          deadline: date ? date.format('YYYY-MM-DD') : null,
        });
      } else {
        setTaskbox({ ...taskbox, deadline: date ? date.format('YYYY-MM-DD') : null });
        setSyncTaskboxDetailView({ ...taskbox, deadline: date ? date.format('YYYY-MM-DD') : null, syncType: 'detail' });
        await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { deadline: date ? date.format('YYYY-MM-DD') : null });
      }
      onFetch && onFetch();
    } catch (e) {
      toast.error('기한을 설정할 수 없습니다.');
    }
  };

  const saveMemo = async (data: any) => {
    if (!data) return;
    await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, { ...data, memo: data });
  };

  const handleChangeMemo = (data: any) => {
    if (!data) return;
    if (!taskbox) return;
    setTaskbox({
      ...taskbox,
      memo: data,
    });
    setSyncTaskboxDetailView({ ...taskbox, memo: data, syncType: 'detail' });
    saveMemo(data);
  };

  const handleCreateLink = () => {
    if (!taskbox) return;
    const links = taskbox?.links || [];
    if (links[links.length - 1]?.title === '' && links[links.length - 1]?.url === '') {
      setSelectedLink(links[links.length - 1]);
      setTimeout(() => {
        if (refCreateLinkInput.current) refCreateLinkInput.current?.focus();
      }, 100);
    } else {
      const newLink = {
        title: '',
        url: '',
        id: uuidv4(),
      };
      setSelectedLink(newLink as OutLink);
      setTaskbox({ ...taskbox, links: [...links, newLink] as OutLink[] });
      setSyncTaskboxDetailView({ ...taskbox, links: [...links, newLink] as OutLink[], syncType: 'detail' });
    }
  };

  const handleSaveLink = async () => {
    if (!taskbox) return;
    if (!selectedLink || selectedLink?.url === '') {
      clearLinkState();
      return;
    }
    const links = taskbox?.links || [];
    const targetLink = links.find((v) => v.id === selectedLink.id);
    if (targetLink) {
      targetLink.url = selectedLink.url;
      targetLink.title = selectedLink.title;
      if (targetLink?.createdAt) {
        try {
          await updateLinkV1LinkLinkIdPatch(targetLink.id, { ...targetLink });
          onFetch && onFetch();
        } catch (e) {
          toast.error('링크를 수정할 수 없습니다.');
        }
      } else {
        try {
          const success = await createLinkV1LinkPost(targetLink as InCreateLink);
          if (success) {
            targetLink.createdAt = success.createdAt;
            await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, { linkIds: links.map((link) => link.id) });
            onFetch && onFetch();
          }
        } catch (e) {
          toast.error('링크를 생성할 수 없습니다.');
        }
      }
    }
    clearLinkState();
  };

  const clearLinkState = () => {
    setSelectedLink(null);
    setLinkAnchorEl(null);
  };

  const handleClickLinkUpdate = (link: OutLink) => {
    setSelectedLink(link);
    setTimeout(() => {
      const el = document.querySelector(`[data-link-id="${link.id}"]`) as HTMLDivElement;
      setLinkAnchorEl(el);
    }, 100);
  };

  const handleCopyClipBoard = async (text: string) => {
    if (text === '') return toast.error('링크가 없습니다.');

    try {
      await navigator.clipboard.writeText(text);
      toast.success('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      toast.error('복사에 실패하였습니다');
    }
  };

  const handleDeleteLink = async () => {
    if (!taskbox || !selectedLink) return;
    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, {
        linkIds: taskbox?.links?.filter((link) => link.id !== selectedLink?.id).map((link) => link.id),
      });
      clearLinkState();
      setTaskbox({ ...taskbox, links: taskbox?.links?.filter((link) => link.id !== selectedLink?.id) });
      setSyncTaskboxDetailView({ ...taskbox, links: taskbox?.links?.filter((link) => link.id !== selectedLink?.id), syncType: 'detail' });
    } catch (e) {
      toast.error('링크를 삭제할 수 없습니다.');
    }
  };

  const handleDelete = (data: any) => {
    onDelete && onDelete(data.id);
  };

  const handleChangeDone = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!taskbox) return;
    const done = e.currentTarget.checked;
    setTaskbox({
      ...taskbox,
      title: taskbox!.title!,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: done })),
      done: done,
    });
    setSyncTaskboxDetailView({
      ...taskbox,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: done })),
      done: done,
      syncType: 'detail',
    });

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        ...taskbox,
        title: taskbox.title!,
        tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: done })),
        done: done,
      });
    } catch (e) {
      toast.error('완료 처리를 할 수 없습니다.');
    }
  };

  const handleCloseTaskboxCompletionPopover = () => {
    setTaskboxCompletionPopover(null);
  };

  const handleClickTaskboxCompletion = async () => {
    if (!taskbox) return;
    setTaskboxCompletionPopover(null);
    setTaskbox({
      ...taskbox,
      title: taskbox.title!,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: true })),
      done: true,
    });
    setSyncTaskboxDetailView({
      ...taskbox,
      tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: true })),
      done: true,
      syncType: 'detail',
    });

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        ...taskbox,
        title: taskbox.title!,
        tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: true })),
        done: true,
      });
    } catch (e) {
      toast.error('완료 처리를 할 수 없습니다.');
    }
  };

  const handleCloseTaskboxCalendarPopover = () => {
    setTaskboxCalendarPopover(null);
  };

  const handleClickContinueOnAnotherDay = async (date: Date) => {
    if (!taskbox) return;
    setTaskboxCompletionPopover(null);
    setTaskboxCalendarPopover(null);

    const subTasks = taskbox?.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }
    const continuationTasks = subTasks.filter((v: any) => !v.done);
    const isTodayTimeBlockedTask = !taskbox.allDay && dayjs(date).diff(taskbox?.start?.datetime, 'date') === 0;

    await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
      tasks: subTasks.filter((item: any) => item.done),
      done: true,
    } as OutTaskboxDetailResponseForSubtask);
    await createTaskboxV1TaskboxesPost({
      ...taskbox,
      id: uuidv4(),
      title: taskbox.title!,
      tasks: continuationTasks as CreateTaskboxTask[],
      start: isTodayTimeBlockedTask ? { datetime: taskbox.start?.datetime } : { date: dayjs(date).format(DATE_FORMAT_4) },
      end: isTodayTimeBlockedTask ? { datetime: taskbox.end?.datetime } : { date: dayjs(date).format(DATE_FORMAT_4) },
    });

    setTaskbox({
      ...taskbox,
      done: true,
    });

    setSyncTaskboxDetailView({ ...taskbox, done: true, syncType: 'detail' });

    toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다');
  };

  const isInprogress = taskbox?.tasks && taskbox.tasks?.filter((v: any) => v.done).length > 0 && !taskbox?.done;
  const checkboxColor = taskbox?.project ? COLORS.issue2 : taskbox?.isRecurrence ? COLORS.sub4 : COLORS.brand1;
  const debouncedSyncTaskbox = useDebouncedSync(setSyncTaskboxDetailView, 100);

  return isSide ? (
    <ProjectContainer
      className="taskbox-detail-view"
      ref={refProjectDetail}
      left={taskViewSidePanel}
      isAnimation={isAnimation}
      onClick={(e) => e.stopPropagation()}
    >
      <ContentHeaderWrapper>
        <ContentHeader>
          <div>
            <IconButton onClick={handleSaveTaskbox} sx={{ borderRadius: '8px', padding: '4px' }}>
              <Icons.FoldDeskIssueDetail width={16} height={16} />
            </IconButton>
            <IconButton onClick={handleOpenDeleteDialog} sx={{ borderRadius: '8px', padding: '4px' }}>
              <Icons.Delete width={16} height={16} fill={COLORS.gray500} />
            </IconButton>
          </div>
        </ContentHeader>
        <TaskboxDetailTitleWrapper>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isInprogress ? (
                <Checkbox
                  checked={false}
                  icon={taskbox?.focus ? <Icons.FocusInprogressCheck /> : <Icons.InprogressCheck fill={checkboxColor} />}
                  sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                  onClick={(e) => setTaskboxCompletionPopover(e.currentTarget)}
                />
              ) : (
                <TaskboxCheckbox
                  icon={
                    taskbox?.focus ? (
                      <Icons.FocusUncheck />
                    ) : (
                      <Icons.TaskboxUncheck circle={checkboxColor} marker={taskbox?.project ? '#F3DAFE' : taskbox?.isRecurrence ? '#E9F9FA' : '#E2ECFF'} />
                    )
                  }
                  checkedIcon={taskbox?.focus ? <Icons.FocusCheck opacity={0.5} /> : <Icons.TaskboxCheck fill={checkboxColor} opacity={0.5} />}
                  checked={!!taskbox?.done}
                  onChange={handleChangeDone}
                  style={{ marginRight: 8 }}
                />
              )}
            </div>
            {taskbox?.lockedIn && <span style={{ marginRight: 4 }}>{taskbox.done ? '✅' : '🌟'}</span>}
            <TextField
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder="Title"
              ref={refInput}
              value={taskbox?.title || ''}
              onChange={taskbox ? (e) => setTaskbox({ ...taskbox, title: e.currentTarget.value }) : undefined}
              onInput={(e) => {
                const target = e.target as HTMLInputElement;
                debouncedSyncTaskbox({ ...taskbox, title: target.value, syncType: 'detail' });
              }}
              onBlur={handleUpdateTaskboxTitle}
              onKeyDown={(e) => handleKeyDown(e)}
              InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
            />
          </div>
          {/* <div>
              <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={(e) => setContextMenuPopover(e.currentTarget)}>
                <Icons.ColorMore width={24} height={24} />
              </IconButton>
            </div> */}
        </TaskboxDetailTitleWrapper>
        <CategoryDeadlineWrapper>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>카테고리</div>
            {taskbox?.category ? (
              <CategoryShowingWrapper
                textColor={getCategoryTextColor(taskbox.category!.color)}
                bgColor={getCategoryBgColor(taskbox.category!.color)}
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
              >
                {`# ${taskbox.category.name}`}
                <IconButton
                  className="category-detach-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickCategoryActions(null, 'SELECT');
                  }}
                  sx={{
                    'width': '12px',
                    'height': '12px',
                    'borderRadius': '4px',
                    'marginLeft': '4px',
                    'marginTop': '1px',
                    'padding': '0px',
                    ':hover': {
                      backgroundColor: hexToRGBA(getCategoryTextColor(taskbox.category!.color)!, 0.3),
                    },
                  }}
                  style={categoryAnchorEl ? { display: 'flex' } : {}}
                >
                  <Icons.Close width={8} height={8} fill={getCategoryTextColor(taskbox.category!.color)} />
                </IconButton>
              </CategoryShowingWrapper>
            ) : (
              <Button
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.gray500, fontSize: '12px', padding: '2px 6px' }}
              >
                <Icons.Category width={16} height={16} fill={COLORS.gray500} />
                <span style={{ margin: '1px 0px 0px 8px' }}>카테고리 추가하기</span>
              </Button>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
            <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>기한</div>
            {taskbox?.deadline ? (
              <DeadlineShowingWrapper date={taskbox.deadline} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
                <Icons.Flag
                  fill={dayjs(taskbox.deadline).isToday() ? COLORS.brand1 : dayjs(taskbox.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                />
                <span style={{ marginLeft: '2px' }}>
                  {dayjs(taskbox.deadline).isToday()
                    ? '오늘'
                    : dayjs(taskbox.deadline).isYesterday()
                    ? '어제'
                    : dayjs(taskbox.deadline).isTomorrow()
                    ? '내일'
                    : dayjs(taskbox.deadline).format('M월 D일 (dd)')}
                </span>
                <IconButton
                  className="deadline-detach-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeDeadline(null);
                  }}
                  sx={{
                    'width': '12px',
                    'height': '12px',
                    'borderRadius': '4px',
                    'marginLeft': '4px',
                    'marginTop': '1px',
                    'padding': '0px',
                    ':hover': {
                      backgroundColor: hexToRGBA(
                        dayjs(taskbox!.deadline).isToday() ? COLORS.brand1 : dayjs(taskbox!.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
                        0.3,
                      ),
                    },
                  }}
                  style={deadlineAnchorEl ? { display: 'flex' } : {}}
                >
                  <Icons.Close
                    width={8}
                    height={8}
                    fill={dayjs(taskbox!.deadline).isToday() ? COLORS.brand1 : dayjs(taskbox!.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                  />
                </IconButton>
              </DeadlineShowingWrapper>
            ) : (
              <Button
                onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
                sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.gray500, fontSize: '12px', padding: '2px 6px' }}
              >
                <Icons.Flag width={16} height={16} fill={COLORS.gray500} />
                <span style={{ margin: '1px 0px 0px 8px' }}>기한 추가하기</span>
              </Button>
            )}
          </div>
        </CategoryDeadlineWrapper>
      </ContentHeaderWrapper>
      <ContentWrapper>
        <div style={{ width: '100%' }}>
          <MemoLinkWrapper>
            <Divider sx={{ width: '100%', margin: '16px 0px' }} />
            <MemoWrapper className={taskbox ? 'taskbox-detail-memo' : 'empty-taskbox-detail-memo'}>
              {isMemoReady ? (
                <RemirrorEditor data={taskboxMemo as RemirrorContentType} onChangeData={handleChangeMemo} />
              ) : (
                <div style={{ height: '128px' }}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </div>
              )}
            </MemoWrapper>
            <Divider sx={{ width: '100%', margin: '16px 0px' }} />
            <LinkWrapper>
              {taskbox?.links?.map((link, idx) => (
                <LinkContentWrapper data-link-id={link.id} key={link.id}>
                  <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                    <Icons.Link2 />
                    {link.url === '' ? (
                      <TextField
                        data-link-input-id={link.id}
                        inputRef={refCreateLinkInput}
                        fullWidth
                        autoFocus
                        placeholder="URL 주소(https://…)를 붙여넣고 Enter로 입력하기"
                        onChange={(e) => {
                          setSelectedLink({ ...link, url: e.target.value, title: e.target.value });
                        }}
                        onBlur={handleSaveLink}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (e.nativeEvent.isComposing) return;
                            e.preventDefault();
                            handleSaveLink();
                          }
                        }}
                        inputProps={{
                          style: { padding: '0px', fontSize: '13px', border: 'none' },
                        }}
                        sx={{ border: 'none', fontSize: '13px', marginLeft: '8px' }}
                      />
                    ) : (
                      <Tooltip title={link.url} disableInteractive>
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            width: '100%',
                            fontSize: '13px',
                            marginLeft: '8px',
                            textDecoration: 'underline',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {link.title || link.url}
                        </a>
                      </Tooltip>
                    )}
                  </div>
                  <div
                    className="link-icon"
                    style={{
                      backgroundColor: COLORS.white,
                      border: `1px solid ${COLORS.gray200}`,
                      borderRadius: '8px',
                      padding: '2px',
                      position: 'absolute',
                      right: '8px',
                    }}
                  >
                    <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                      <Icons.Duplicate stroke={COLORS.gray400} />
                    </IconButton>
                    <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleClickLinkUpdate(link)}>
                      <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
                    </IconButton>
                  </div>
                </LinkContentWrapper>
              ))}
              <CreateLinkButton onClick={handleCreateLink}>
                <Icons.AddLink />
                <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>링크 추가하기</div>
              </CreateLinkButton>
            </LinkWrapper>
            <Divider sx={{ width: '100%', margin: '16px 0px' }} />
          </MemoLinkWrapper>
        </div>
      </ContentWrapper>
      {taskboxCompletionPopover && (
        <Popover
          className="taskbox-detail-complete"
          open={Boolean(taskboxCompletionPopover)}
          anchorEl={taskboxCompletionPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskboxCompletionPopover}
        >
          <TaskboxCompletionWrapper>
            <TaskboxCompletionButton onClick={handleClickTaskboxCompletion}>
              <Icons.TaskboxCheck width={16} height={16} fill={checkboxColor} />
              <span style={{ marginLeft: '8px' }}>모두 완료하기</span>
            </TaskboxCompletionButton>
            <TaskboxCompletionButton onClick={(e) => setTaskboxCalendarPopover(e.currentTarget)}>
              <Icons.ContinueTaskbox fill={checkboxColor} />
              <span style={{ marginLeft: '8px' }}>일부 완료하고 이어서하기</span>
            </TaskboxCompletionButton>
          </TaskboxCompletionWrapper>
        </Popover>
      )}
      {taskboxCalendarPopover && (
        <Popover
          className="taskbox-detail-continue"
          open={Boolean(taskboxCalendarPopover)}
          anchorEl={taskboxCalendarPopover}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskboxCalendarPopover}
        >
          <TaskboxCalendar
            currentDate={dayjs(taskbox?.start?.date || taskbox?.start?.datetime, { utc: true }).toDate()}
            onChange={(date) => handleClickContinueOnAnotherDay(dayjs(date).toDate())}
          >
            <TaskboxCalendarMenuItem
              icon={<Icons.Today width={16} height={16} />}
              label="오늘 중"
              onClick={() => handleClickContinueOnAnotherDay(dayjs(taskbox?.start?.date || taskbox?.start?.datetime, { utc: true }).toDate())}
            />
            <TaskboxCalendarMenuItem
              icon={<Icons.Tomorrow />}
              label="다음 날"
              onClick={() =>
                handleClickContinueOnAnotherDay(
                  dayjs(taskbox?.start?.date || taskbox?.start?.datetime, { utc: true })
                    .add(1, 'day')
                    .toDate(),
                )
              }
            />
            <TaskboxCalendarMenuItem
              icon={<Icons.NextMonday />}
              label="다음주 월요일"
              onClick={() =>
                handleClickContinueOnAnotherDay(
                  dayjs(taskbox?.start?.date || taskbox?.start?.datetime, { utc: true })
                    .day(8)
                    .toDate(),
                )
              }
            />
          </TaskboxCalendar>
        </Popover>
      )}
      {linkAnchorEl && (
        <Popover
          open={Boolean(linkAnchorEl)}
          anchorEl={linkAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleSaveLink}
          sx={{ marginLeft: '30px' }}
        >
          <LinkPopoverContainer className="taskbox-detail-link">
            <LinkPopoverWrapper>
              <LinkURLWrapper>
                <div>링크 URL</div>
                <TextField
                  autoFocus
                  fullWidth
                  value={selectedLink?.url}
                  placeholder="링크 URL 주소(https://…)를 입력해주세요."
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, url: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkURLWrapper>
              <LinkTitleWrapper>
                <div>링크 제목</div>
                <TextField
                  fullWidth
                  value={selectedLink?.title}
                  placeholder="링크 제목을 입력해주세요."
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, title: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkTitleWrapper>
            </LinkPopoverWrapper>
            <Divider />
            <LinkDeleteWrapper>
              <Button
                onClick={handleDeleteLink}
                sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
              >
                <Icons.Delete fill={COLORS.negative1} />
                <span style={{ marginLeft: '8px' }}>링크 삭제</span>
              </Button>
            </LinkDeleteWrapper>
          </LinkPopoverContainer>
        </Popover>
      )}
      <Popover
        open={Boolean(categoryAnchorEl)}
        anchorEl={categoryAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setCategoryAnchorEl(null);
        }}
        sx={{ marginTop: '4px' }}
      >
        <div className="taskbox-detail-category">
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
        </div>
      </Popover>
      <Popover
        open={Boolean(deadlineAnchorEl)}
        anchorEl={deadlineAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setDeadlineAnchorEl(null);
        }}
        sx={{ marginTop: '4px' }}
      >
        <div className="taskbox-detail-deadline">
          <DeadlinePopover date={taskbox && taskbox.deadline ? dayjs(taskbox.deadline).toDate() : dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
        </div>
      </Popover>
      <ConfirmDialog className="taskbox-detail-delete" ref={refConfirm} onConfirm={handleDelete} data={taskbox} type="taskbox" title="태스크박스 삭제">
        <div style={{ color: `${COLORS.negative1}`, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>태스크박스를 삭제하시겠어요?</div>
        <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>관련 데이터를 모두 삭제합니다.</div>
      </ConfirmDialog>
    </ProjectContainer>
  ) : (
    <></>
    // <Drawer
    //   className="project-drawer"
    //   anchor="right"
    //   open={!!project}
    //   onClose={handleSaveTaskbox}
    //   SlideProps={{
    //     style: { visibility: 'visible' },
    //   }}
    // >
    //   <Container ref={refProjectDetail}>
    //     <ContentHeaderWrapper>
    //       <ContentHeader>
    //         <div style={{ display: 'flex', alignItems: 'center' }}>
    //           <Icons.Issue width={20} height={20} />
    //           {unallocate.filter((task) => !task.done).length > 0 && (
    //             <>
    //               <Button
    //                 onClick={() => setProjectFilter('ALL')}
    //                 sx={{
    //                   backgroundColor: projectFilter === 'ALL' ? COLORS.gray200 : COLORS.white,
    //                   border: `1px solid ${COLORS.gray200}`,
    //                   borderRadius: '6px',
    //                   fontSize: '12px',
    //                   fontWeight: projectFilter === 'ALL' ? 'bold' : 'normal',
    //                   color: projectFilter === 'ALL' ? COLORS.gray900 : COLORS.gray500,
    //                   padding: '2px 8px',
    //                   marginLeft: '8px',
    //                 }}
    //               >
    //                 모두 보기
    //               </Button>
    //               <Button
    //                 onClick={() => setProjectFilter('SUBTASK')}
    //                 sx={{
    //                   backgroundColor: projectFilter === 'SUBTASK' ? COLORS.gray200 : COLORS.white,
    //                   border: `1px solid ${COLORS.gray200}`,
    //                   borderRadius: '6px',
    //                   fontSize: '12px',
    //                   fontWeight: projectFilter === 'SUBTASK' ? 'bold' : 'normal',
    //                   color: projectFilter === 'SUBTASK' ? COLORS.gray900 : COLORS.gray500,
    //                   padding: '2px 8px',
    //                   marginLeft: '8px',
    //                 }}
    //               >{`타임블록할 하위 업무 ${unallocate.filter((task) => !task.done).length}`}</Button>
    //             </>
    //           )}
    //         </div>
    //         <div>
    //           <IconButton onClick={handleSaveTaskbox} sx={{ borderRadius: '8px', padding: '4px' }}>
    //             <Icons.FoldDeskIssueDetail width={16} height={16} />
    //           </IconButton>
    //           <IconButton onClick={handleOpenDeleteDialog} sx={{ borderRadius: '8px', padding: '4px' }}>
    //             <Icons.Delete width={16} height={16} fill={COLORS.gray500} />
    //           </IconButton>
    //         </div>
    //       </ContentHeader>
    //       <TaskboxDetailTitleWrapper>
    //         <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    //           {project?.place === 'DESK' && (
    //             <PriorityIssueCheckbox
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 setProjectStatusPopover(e.currentTarget);
    //               }}
    //               checked={project.done}
    //               bordercolor={COLORS.issue2}
    //               width={20}
    //               height={20}
    //             />
    //           )}
    //           {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
    //             <Tooltip title={deskProjects.length >= 7 ? '데스크가 가득 찼습니다' : '홀드 해제'} disableInteractive>
    //               <IconButton
    //                 onClick={(e) => {
    //                   e.stopPropagation();
    //                   deskProjects.length >= 7 ? toast.error('데스크가 가득 찼습니다.') : handleClickProjectCheck(project!.id, 'UNLOCK');
    //                 }}
    //                 disableRipple
    //                 sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
    //               >
    //                 <Icons.DeskIssueHold width={20} height={20} />
    //               </IconButton>
    //             </Tooltip>
    //           )}
    //           {project?.place === 'ARCHIVE' && project?.done && (
    //             <Tooltip title={'완료 해제'} disableInteractive>
    //               <IconButton
    //                 disableRipple
    //                 onClick={(e) => {
    //                   e.stopPropagation();
    //                   handleClickProjectCheck(project!.id, 'UNLOCK');
    //                 }}
    //                 sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
    //               >
    //                 <Icons.DeskIssueCheck />
    //               </IconButton>
    //             </Tooltip>
    //           )}
    //           {project?.place === 'ARCHIVE' && !project?.done && (
    //             <Tooltip title={'취소 해제'} disableInteractive>
    //               <IconButton
    //                 onClick={(e) => {
    //                   e.stopPropagation();
    //                   handleClickProjectCheck(project!.id, 'UNLOCK');
    //                 }}
    //                 disableRipple
    //                 sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
    //               >
    //                 <Icons.DeskIssueCancel width={12} height={12} />
    //               </IconButton>
    //             </Tooltip>
    //           )}
    //           <TextField
    //             autoComplete="off"
    //             fullWidth
    //             variant="standard"
    //             placeholder="프로젝트 이름을 입력해주세요."
    //             ref={refInput}
    //             value={project?.title || ''}
    //             onChange={project ? (e) => setProject({ ...project, title: e.currentTarget.value }) : undefined}
    //             onBlur={handleUpdateTaskboxTitle}
    //             onKeyDown={(e) => handleKeyDown(e)}
    //             InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
    //             style={{ marginLeft: 8 }}
    //           />
    //         </div>
    //         {/* <div>
    //           <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={(e) => setContextMenuPopover(e.currentTarget)}>
    //             <Icons.ColorMore width={24} height={24} />
    //           </IconButton>
    //         </div> */}
    //       </TaskboxDetailTitleWrapper>
    //       {projectFilter === 'ALL' && (
    //         <CategoryDeadlineWrapper>
    //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
    //             <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>카테고리</div>
    //             {project?.category ? (
    //               <CategoryShowingWrapper
    //                 textColor={getCategoryTextColor(project.category!.color)}
    //                 bgColor={getCategoryBgColor(project.category!.color)}
    //                 onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
    //               >
    //                 {`# ${project.category.name}`}
    //                 <IconButton
    //                   className="category-detach-button"
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     handleClickCategoryActions(null, 'SELECT');
    //                   }}
    //                   sx={{
    //                     'width': '12px',
    //                     'height': '12px',
    //                     'borderRadius': '4px',
    //                     'marginLeft': '4px',
    //                     'marginTop': '1px',
    //                     'padding': '0px',
    //                     ':hover': {
    //                       backgroundColor: hexToRGBA(getCategoryTextColor(project.category!.color)!, 0.3),
    //                     },
    //                   }}
    //                   style={categoryAnchorEl ? { display: 'flex' } : {}}
    //                 >
    //                   <Icons.Close width={8} height={8} fill={getCategoryTextColor(project.category!.color)} />
    //                 </IconButton>
    //               </CategoryShowingWrapper>
    //             ) : (
    //               <Button
    //                 onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
    //                 sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.gray500, fontSize: '12px', padding: '2px 6px' }}
    //               >
    //                 <Icons.Category width={16} height={16} fill={COLORS.gray500} />
    //                 <span style={{ margin: '1px 0px 0px 8px' }}>카테고리 추가하기</span>
    //               </Button>
    //             )}
    //           </div>
    //           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
    //             <div style={{ fontSize: 12, fontWeight: 'bold', width: '60px' }}>기한</div>
    //             {project?.due ? (
    //               <DeadlineShowingWrapper date={project.due} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
    //                 <Icons.Flag
    //                   fill={dayjs(project.due).isToday() ? COLORS.brand1 : dayjs(project.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
    //                 />
    //                 <span style={{ marginLeft: '2px' }}>
    //                   {dayjs(project.due).isToday()
    //                     ? '오늘'
    //                     : dayjs(project.due).isYesterday()
    //                     ? '어제'
    //                     : dayjs(project.due).isTomorrow()
    //                     ? '내일'
    //                     : dayjs(project.due).format('M월 D일 (dd)')}
    //                 </span>
    //                 <IconButton
    //                   className="deadline-detach-button"
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     handleChangeDeadline(null);
    //                   }}
    //                   sx={{
    //                     'width': '12px',
    //                     'height': '12px',
    //                     'borderRadius': '4px',
    //                     'marginLeft': '4px',
    //                     'marginTop': '1px',
    //                     'padding': '0px',
    //                     ':hover': {
    //                       backgroundColor: hexToRGBA(
    //                         dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
    //                         0.3,
    //                       ),
    //                     },
    //                   }}
    //                   style={deadlineAnchorEl ? { display: 'flex' } : {}}
    //                 >
    //                   <Icons.Close
    //                     width={8}
    //                     height={8}
    //                     fill={dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
    //                   />
    //                 </IconButton>
    //               </DeadlineShowingWrapper>
    //             ) : (
    //               <Button
    //                 onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
    //                 sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.gray500, fontSize: '12px', padding: '2px 6px' }}
    //               >
    //                 <Icons.Flag width={16} height={16} fill={COLORS.gray500} />
    //                 <span style={{ margin: '1px 0px 0px 8px' }}>기한 추가하기</span>
    //               </Button>
    //             )}
    //           </div>
    //         </CategoryDeadlineWrapper>
    //       )}
    //       {projectFilter === 'SUBTASK' && (
    //         <div style={{ marginTop: 12 }}>
    //           {unallocate
    //             .filter((task) => !task.done)
    //             .map((task) => (
    //               <UnallocateTaskWrapper
    //                 draggable
    //                 key={task.id}
    //                 multiSelect={multiSelect.map((v) => v.id).includes(task!.id)}
    //                 onDragStart={(e) => {
    //                   handleDragStart(e, task);
    //                 }}
    //                 onDragEnd={handleDragEnd}
    //                 onClick={(e) => {
    //                   if (e.ctrlKey || e.metaKey) {
    //                     setMultiSelectAnchorEl(e.currentTarget);
    //                     handleMultiSelectIds(task);
    //                   }
    //                 }}
    //               >
    //                 <Icons.TaskCheckbox fill={'#D18DF2'} />
    //                 <div style={{ marginLeft: 8 }}>{task.title}</div>
    //               </UnallocateTaskWrapper>
    //             ))}
    //         </div>
    //         // <TaskBlockInputList
    //         //   tasks={(unallocate as TaskBlock[]) || []}
    //         //   projectId={project?.id}
    //         //   categoryList={categoryList}
    //         //   isProjectUnallocateSubtask
    //         //   isTimeblock
    //         //   draggable={false}
    //         //   onChange={handleChangeUnallocateState}
    //         //   onChangeUnallocateSubtask={handleChangeUnallocateSubtask}
    //         //   onClickCategory={handleClickCategoryActions}
    //         //   suppressTemplate
    //         // />
    //       )}
    //     </ContentHeaderWrapper>
    //     <ContentWrapper>
    //       {projectFilter === 'ALL' && (
    //         <div style={{ width: '100%' }}>
    //           <MemoLinkWrapper>
    //             <Divider sx={{ width: '100%', margin: '16px 0px' }} />
    //             <MemoWrapper className="project-memo">
    //               <RemirrorEditor data={project && project.memo ? (project.memo as RemirrorContentType) : undefined} onChangeData={handleChangeMemo} />
    //             </MemoWrapper>
    //             <Divider sx={{ width: '100%', margin: '16px 0px' }} />
    //             <LinkWrapper>
    //               {project?.links?.map((link, idx) => (
    //                 <LinkContentWrapper data-link-id={link.id} key={link.id}>
    //                   <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
    //                     <Icons.Link2 />
    //                     {link.url === '' ? (
    //                       <TextField
    //                         data-link-input-id={link.id}
    //                         inputRef={refCreateLinkInput}
    //                         fullWidth
    //                         autoFocus
    //                         placeholder="URL 주소(https://…)를 붙여넣고 Enter로 입력하기"
    //                         onChange={(e) => {
    //                           setSelectedLink({ ...link, url: e.target.value, title: e.target.value });
    //                         }}
    //                         onBlur={handleSaveLink}
    //                         onKeyDown={(e) => {
    //                           if (e.key === 'Enter') {
    //                             if (e.nativeEvent.isComposing) return;
    //                             e.preventDefault();
    //                             handleSaveLink();
    //                           }
    //                         }}
    //                         inputProps={{
    //                           style: { padding: '0px', fontSize: '13px', border: 'none' },
    //                         }}
    //                         sx={{ border: 'none', fontSize: '13px', marginLeft: '8px' }}
    //                       />
    //                     ) : (
    //                       <Tooltip title={link.url} disableInteractive>
    //                         <a
    //                           href={link.url}
    //                           target="_blank"
    //                           rel="noreferrer"
    //                           style={{
    //                             width: '100%',
    //                             fontSize: '13px',
    //                             marginLeft: '8px',
    //                             textDecoration: 'underline',
    //                             whiteSpace: 'nowrap',
    //                             overflow: 'hidden',
    //                             textOverflow: 'ellipsis',
    //                           }}
    //                         >
    //                           {link.title || link.url}
    //                         </a>
    //                       </Tooltip>
    //                     )}
    //                   </div>
    //                   <div
    //                     className="link-icon"
    //                     style={{
    //                       backgroundColor: COLORS.white,
    //                       border: `1px solid ${COLORS.gray200}`,
    //                       borderRadius: '8px',
    //                       padding: '2px',
    //                       position: 'absolute',
    //                       right: '8px',
    //                     }}
    //                   >
    //                     <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
    //                       <Icons.Duplicate stroke={COLORS.gray400} />
    //                     </IconButton>
    //                     <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleClickLinkUpdate(link)}>
    //                       <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
    //                     </IconButton>
    //                   </div>
    //                 </LinkContentWrapper>
    //               ))}
    //               <CreateLinkButton onClick={handleCreateLink}>
    //                 <Icons.AddLink />
    //                 <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>링크 추가하기</div>
    //               </CreateLinkButton>
    //             </LinkWrapper>
    //             <Divider sx={{ width: '100%', margin: '16px 0px' }} />
    //           </MemoLinkWrapper>
    //           <TaskListWrapper>
    //             <TaskListClassifyTitle>하위 업무</TaskListClassifyTitle>
    //             {instances.map((instance, idx) => (
    //               <div id={instance.id} key={instance.id} style={{ marginBottom: 8 }}>
    //                 <TaskListHeader>
    //                   {instance.start?.datetime
    //                     ? dayjs(instance.start?.datetime, { utc: true }).format('M월 D일 dddd A h:mm')
    //                     : dayjs(instance.start?.date, { utc: true }).format('M월 D일 dddd')}
    //                 </TaskListHeader>
    //                 <div style={{ marginLeft: -16 }}>
    //                   <TaskBlockInputList
    //                     tasks={instance.tasks as TaskBlock[]}
    //                     instance={instance}
    //                     project={project!}
    //                     categoryList={categoryList}
    //                     suppressTemplate
    //                     suppressVisibleTag
    //                     suppressLoadSubtask
    //                     draggable={false}
    //                     onChangeProjectDetailInstanceSubtask={handleChangeInstanceSubtask}
    //                     onClickCategory={handleClickCategoryActions}
    //                   />
    //                 </div>
    //               </div>
    //             ))}
    //             {(unallocate.length > 0 || instances.length > 0) && <TaskListClassifyTitle style={{ margin: '10px 0px' }}>미할당</TaskListClassifyTitle>}
    //             {unallocate.filter((task) => !task.done).length > 0 && <TaskListHeader style={{ color: COLORS.gray400 }}>미완료</TaskListHeader>}
    //             <div style={{ marginLeft: -16 }}>
    //               <TaskBlockInputList
    //                 tasks={(unallocate as TaskBlock[]) || []}
    //                 project={project!}
    //                 categoryList={categoryList}
    //                 isProjectUnallocateSubtask
    //                 draggable={false}
    //                 onChange={handleChangeUnallocateState}
    //                 onChangeUnallocateSubtask={handleChangeUnallocateSubtask}
    //                 onClickCategory={handleClickCategoryActions}
    //                 suppressTemplate
    //                 suppressLoadSubtask
    //               />
    //             </div>
    //           </TaskListWrapper>
    //         </div>
    //       )}
    //       {projectFilter === 'SUBTASK' &&
    //         unallocateSubtasksBeforeToday.length > 0 &&
    //         !dayjs(instance?.start?.date || instance?.start?.datetime).isBefore(dayjs(), 'date') && (
    //           <NoticeUndoneSubtaskWrapper>
    //             <NoticeUndoneSubtaskHeader>
    //               <div style={{ display: 'flex', alignItems: 'center' }}>
    //                 <Icons.Alert width={16} height={16} fill={COLORS.negative1} />
    //                 <span
    //                   style={{ marginLeft: 4, fontWeight: 700, fontSize: 13 }}
    //                 >{`오늘 이전 일자에 ${unallocateSubtasksBeforeToday.length}개의 미완료 하위 업무가 있어요.`}</span>
    //               </div>
    //               <IconButton onClick={() => setIsFoldUndoneSubtask(!isFoldUndoneSubtask)} sx={{ padding: '4px' }}>
    //                 {isFoldUndoneSubtask ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
    //               </IconButton>
    //             </NoticeUndoneSubtaskHeader>
    //             <Divider sx={{ margin: '8px 0px' }} />
    //             {!isFoldUndoneSubtask &&
    //               unallocateSubtasksBeforeToday.map((task) => (
    //                 <UnallocateTaskWrapper
    //                   key={task!.id}
    //                   draggable
    //                   multiSelect={multiSelect.map((v) => v.id).includes(task!.id)}
    //                   onDragStart={(e) => {
    //                     handleDragStart(e, task! as OutTaskboxDetailProjectOnly);
    //                   }}
    //                   onDragEnd={handleDragEnd}
    //                   onClick={(e) => {
    //                     if (e.ctrlKey || e.metaKey) {
    //                       setMultiSelectAnchorEl(e.currentTarget);
    //                       handleMultiSelectIds(task! as OutTaskboxDetailProjectOnly);
    //                     }
    //                   }}
    //                   style={{ flexDirection: 'column', alignItems: 'flex-start' }}
    //                 >
    //                   <TaskListHeader style={{ marginBottom: 8 }}>
    //                     <span>
    //                       {task!.start?.datetime
    //                         ? dayjs(task!.start?.datetime, { utc: true }).format('M월 D일 dddd A h:mm')
    //                         : dayjs(task!.start?.date, { utc: true }).format('M월 D일 dddd')}
    //                     </span>
    //                     <Tooltip title="일시 지정 해제하기">
    //                       <IconButton
    //                         className="close-btn"
    //                         onClick={() => handleMoveToUnallocate(task!)}
    //                         sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px' }}
    //                       >
    //                         <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
    //                       </IconButton>
    //                     </Tooltip>
    //                   </TaskListHeader>
    //                   <div style={{ display: 'flex', alignItems: 'center' }}>
    //                     <Icons.TaskCheckbox fill={'#D18DF2'} />
    //                     <div style={{ marginLeft: 8 }}>{task!.content}</div>
    //                   </div>
    //                 </UnallocateTaskWrapper>
    //               ))}
    //             {/* {instances
    //             .filter((instance) => instance.tasks && instance.tasks.filter((task) => !task.done).length > 0)
    //             .map((instance) => (
    //               <UnallocateTaskWrapper key={instance.id} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
    //                 <TaskListHeader style={{ marginBottom: 8 }}>
    //                   <span>
    //                     {instance.start?.datetime
    //                       ? dayjs(instance.start?.datetime).format('M월 D일 dddd A h:mm')
    //                       : dayjs(instance.start?.date).format('M월 D일 dddd')}
    //                   </span>
    //                   <Tooltip title="일시 지정 해제하기">
    //                     <IconButton
    //                       className="close-btn"
    //                       onClick={() => handleMoveToUnallocate(instance.tasks!.filter((v) => !v.done))}
    //                       sx={{ width: '20px', height: '20px', borderRadius: '6px', marginLeft: '4px', padding: '2px' }}
    //                     >
    //                       <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
    //                     </IconButton>
    //                   </Tooltip>
    //                 </TaskListHeader>
    //                 {instance.tasks &&
    //                   instance.tasks
    //                     .filter((v) => !v.done)
    //                     .map((task) => (
    //                       <div style={{ display: 'flex', alignItems: 'center' }}>
    //                         <Icons.TaskCheckbox fill={'#D18DF2'} />
    //                         <div style={{ marginLeft: 8 }}>{task.content}</div>
    //                       </div>
    //                     ))}
    //               </UnallocateTaskWrapper>
    //             ))} */}
    //           </NoticeUndoneSubtaskWrapper>
    //         )}
    //     </ContentWrapper>
    //     <ProjectDragImage id="drag-image">
    //       <ProjectDragImageCount>{multiSelect.length || 1}</ProjectDragImageCount>
    //       <span style={{ fontSize: 13, marginLeft: 8 }}>선택됨</span>
    //     </ProjectDragImage>
    //     {projectStatusPopover && (
    //       <Popover
    //         open={Boolean(projectStatusPopover)}
    //         anchorEl={projectStatusPopover}
    //         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    //         transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    //         sx={{ marginTop: 0.5 }}
    //         onClose={() => setProjectStatusPopover(null)}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //         }}
    //       >
    //         <ProjectStatusWrapper>
    //           <ProjectStatusDetail
    //             onClick={() => {
    //               handleClickProjectCheck(project!.id, 'DONE');
    //             }}
    //           >
    //             <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
    //               <Icons.DeskIssueCheck />
    //             </IconButton>
    //             완료
    //           </ProjectStatusDetail>
    //           <ProjectStatusDetail
    //             onClick={() => {
    //               handleClickProjectCheck(project!.id, 'HOLD');
    //             }}
    //           >
    //             <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
    //               <Icons.DeskIssueHold />
    //             </IconButton>
    //             홀드
    //           </ProjectStatusDetail>
    //           <ProjectStatusDetail
    //             onClick={() => {
    //               handleClickProjectCheck(project!.id, 'CANCEL');
    //             }}
    //           >
    //             <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
    //               <Icons.DeskIssueCancel />
    //             </IconButton>
    //             취소
    //           </ProjectStatusDetail>
    //         </ProjectStatusWrapper>
    //       </Popover>
    //     )}
    //     {linkAnchorEl && (
    //       <Popover
    //         open={Boolean(linkAnchorEl)}
    //         anchorEl={linkAnchorEl}
    //         anchorOrigin={{
    //           vertical: 'bottom',
    //           horizontal: 'left',
    //         }}
    //         transformOrigin={{
    //           vertical: 'top',
    //           horizontal: 'left',
    //         }}
    //         onClose={handleSaveLink}
    //         sx={{ marginLeft: '30px' }}
    //       >
    //         <LinkPopoverContainer>
    //           <LinkPopoverWrapper>
    //             <LinkURLWrapper>
    //               <div>링크 URL</div>
    //               <TextField
    //                 autoFocus
    //                 fullWidth
    //                 value={selectedLink?.url}
    //                 placeholder="링크 URL 주소(https://…)를 입력해주세요."
    //                 onChange={(e) => {
    //                   if (!selectedLink) return;
    //                   setSelectedLink({ ...selectedLink, url: e.target.value });
    //                 }}
    //                 sx={{ marginTop: '4px' }}
    //                 inputProps={{
    //                   style: { padding: '8px 12px', fontSize: '12px' },
    //                 }}
    //               />
    //             </LinkURLWrapper>
    //             <LinkTitleWrapper>
    //               <div>링크 제목</div>
    //               <TextField
    //                 fullWidth
    //                 value={selectedLink?.title}
    //                 placeholder="링크 제목을 입력해주세요."
    //                 onChange={(e) => {
    //                   if (!selectedLink) return;
    //                   setSelectedLink({ ...selectedLink, title: e.target.value });
    //                 }}
    //                 sx={{ marginTop: '4px' }}
    //                 inputProps={{
    //                   style: { padding: '8px 12px', fontSize: '12px' },
    //                 }}
    //               />
    //             </LinkTitleWrapper>
    //           </LinkPopoverWrapper>
    //           <Divider />
    //           <LinkDeleteWrapper>
    //             <Button
    //               onClick={handleDeleteLink}
    //               sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
    //             >
    //               <Icons.Delete fill={COLORS.negative1} />
    //               <span style={{ marginLeft: '8px' }}>링크 삭제</span>
    //             </Button>
    //           </LinkDeleteWrapper>
    //         </LinkPopoverContainer>
    //       </Popover>
    //     )}
    //     <Popover
    //       open={Boolean(categoryAnchorEl)}
    //       anchorEl={categoryAnchorEl}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'left',
    //       }}
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'left',
    //       }}
    //       onClose={() => {
    //         setCategoryAnchorEl(null);
    //       }}
    //       sx={{ marginTop: '4px' }}
    //     >
    //       <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
    //     </Popover>
    //     <Popover
    //       open={Boolean(deadlineAnchorEl)}
    //       anchorEl={deadlineAnchorEl}
    //       anchorOrigin={{
    //         vertical: 'bottom',
    //         horizontal: 'left',
    //       }}
    //       transformOrigin={{
    //         vertical: 'top',
    //         horizontal: 'left',
    //       }}
    //       onClose={() => {
    //         setDeadlineAnchorEl(null);
    //       }}
    //       sx={{ marginTop: '4px' }}
    //     >
    //       <DeadlinePopover date={project && project.due ? dayjs(project!.due).toDate() : dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
    //     </Popover>
    //     {multiSelect.length > 0 && (
    //       <Popper
    //         open={Boolean(multiSelectAnchorEl)}
    //         anchorEl={multiSelectAnchorEl}
    //         placement={'left-start'}
    //         ref={refPopperMenu}
    //         sx={{ zIndex: 1201, right: '4px' }}
    //       >
    //         <SubtaskMultiSelectPopoverWrapper>
    //           <div style={{ padding: '16px 16px 0px 16px', fontSize: 12, fontWeight: 'bold' }}>{multiSelect.length}개의 하위 업무가 선택되었습니다.</div>
    //           <InboxContextMenu
    //             id=""
    //             type="project"
    //             menus={instance?.id ? ['INSTANCE', 'DELETE'] : ['MOVE_TO_TODAY', 'DELETE']}
    //             data={multiSelect}
    //             onClickMenu={handleClickInboxContextMenu}
    //           />
    //         </SubtaskMultiSelectPopoverWrapper>
    //       </Popper>
    //     )}
    //     <ConfirmDialog ref={refConfirm} onConfirm={handleDelete} data={project}>
    //       <div style={{ color: `${COLORS.negative1}`, fontWeight: 500, fontSize: '1.25rem', textAlign: 'center' }}>{`프로젝트를 삭제하시겠어요?`}</div>
    //       <div style={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center', fontSize: '1rem', marginTop: 16 }}>
    //         {instances.length > 0 ? `프로젝트에 연결된 ${instances.length}개의 인스턴스도 함께 삭제됩니다.` : '관련 데이터를 모두 삭제합니다.'}
    //       </div>
    //     </ConfirmDialog>
    //   </Container>
    // </Drawer>
  );
};

export default TaskboxDetailView;
