import styled from '@emotion/styled';
import { Button, CircularProgress, Dialog, Divider, IconButton, Tooltip } from '@mui/material';
import { userAtom } from 'atoms/user';
import { Icons } from 'components';
import dayjs from 'lib/dayjs';
import { useAtom } from 'jotai';
import { getWeeklyCategoryStatV1StatCategoryWeekGet, getWeeklyProjectStatV1StatProjectWeekGet, readTaskboxesV1TaskboxesGet } from 'queries';
import { OutCategoryStatList, OutProjectStat, OutProjectStatList, OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { getCategoryBgColor, getCategoryStatColor, getCategoryTextColor } from 'utils/category';
import { hideScroll } from 'styles/utils';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { projects } from 'atoms/projects';
import { DATE_FORMAT_4 } from 'utils/datetimeFormat';

export interface ControlTime {
  lastWeek: {
    totalTimeboxing: number;
    doneTimeboxing: number;
  };
  thisWeek: {
    totalTimeboxing: number;
    doneTimeboxing: number;
  };
}

export interface PaceData {
  date: string;
  timeblocks: number;
  cumulativeTimeblocks: number;
  doneTimeblocks: number;
  cumulativeDoneTimeblocks: number;
}

export interface FocusTimeData {
  lastWeek: number;
  thisWeek: number;
}

const Home = () => {
  const [me] = useAtom(userAtom);
  const [render, setRender] = useState(false);
  const [weekDate, setWeekDate] = useState<{ start: Date; end: Date }>({
    start: dayjs().startOf('week').toDate(),
    end: dayjs().endOf('week').toDate(),
  });
  const [controlTimeData, setControlTimeData] = useState<ControlTime>();
  const [paceData, setPaceData] = useState<PaceData[]>([]);
  const [focusTimeData, setFocusTimeData] = useState<FocusTimeData>({
    lastWeek: 0,
    thisWeek: 0,
  });
  const [investedTaskboxes, setInvestedTaskboxes] = useState<OutTaskboxDetailResponse[]>([{}, {}, {}]);
  const [categoryStatList, setCategoryStatList] = useState<OutCategoryStatList | null>(null);
  const [priorityProject, setPriorityProject] = useState<OutProjectStat[]>([
    {
      place: 'DESK',
      title: '',
      thisWeekMin: 0,
    },
    { place: 'DESK', title: '', thisWeekMin: 0 },
    { place: 'DESK', title: '', thisWeekMin: 0 },
  ]);
  const [projectStatList, setProjectStatList] = useState<OutProjectStatList | null>(null);
  const refCategoryStat = useRef<HTMLDivElement>(null);
  const [categoryStatHeight, setCategoryStatHeight] = useState<number>(0);
  const [isProjectDetail, setIsProjectDetail] = useState<boolean>(false);
  const [isFold, setIsFold] = useState<boolean>(true);

  const lineChartOptions: ApexOptions = {
    chart: {
      width: '100%',
      type: 'area',
      offsetX: -28,
      offsetY: -10,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series:
      controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
        ? [
            {
              name: '타임박싱한 태스크박스 수',
              data: [8, 12, 15, 18, 20, 27, 30],
            },
            {
              name: '그 중 완료한 태스크박스 수',
              data: [2, 7, 9, 12, 15, 20, 27],
            },
          ]
        : [
            {
              name: '타임박싱한 태스크박스 수',
              data: paceData.map((v) => v.cumulativeTimeblocks),
            },
            {
              name: '그 중 완료한 태스크박스 수',
              data: paceData.map((v) => v.cumulativeDoneTimeblocks),
            },
          ],
    xaxis: {
      categories: paceData.map((v) => (dayjs(v.date).date() === 1 ? dayjs(v.date).format('M월 D일 (ddd)') : dayjs(v.date).format('D일 (ddd)'))),
      labels: {
        style: {
          fontSize: '10px',
          colors: paceData.map((v) => (dayjs(v.date).isSame(dayjs(), 'date') ? COLORS.brand1 : COLORS.gray900)),
        },
      },
    },
    yaxis: {
      title: {
        text: '누적',
        rotate: 360,
        offsetX: 24,
        offsetY: -78,
      },
      labels: {
        formatter: (value) => {
          return parseFloat(value.toFixed(1)) + '개';
        },
        style: {
          fontSize: '10px',
        },
      },
    },
    stroke: {
      curve: 'straight',
      width: 2,
      colors:
        controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
          ? [COLORS.gray200, COLORS.gray200]
          : [COLORS.sub4, COLORS.sub2],
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops:
          controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
            ? [
                [
                  {
                    offset: 10,
                    color: COLORS.gray100,
                    opacity: 1,
                  },
                  {
                    offset: 90,
                    color: COLORS.gray100,
                    opacity: 0.1,
                  },
                ],
                [
                  {
                    offset: 10,
                    color: COLORS.gray100,
                    opacity: 1,
                  },
                  {
                    offset: 90,
                    color: COLORS.gray100,
                    opacity: 0.1,
                  },
                ],
              ]
            : [
                [
                  {
                    offset: 10,
                    color: '#E9F9FA',
                    opacity: 1,
                  },
                  {
                    offset: 90,
                    color: '#E9F9FA',
                    opacity: 0.1,
                  },
                ],
                [
                  {
                    offset: 10,
                    color: '#E4EDFF',
                    opacity: 1,
                  },
                  {
                    offset: 90,
                    color: '#E4EDFF',
                    opacity: 0.1,
                  },
                ],
              ],
      },
    },
    legend: {
      offsetY: 8,
      markers: {
        fillColors:
          controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
            ? [COLORS.gray200, COLORS.gray200]
            : [COLORS.sub4, COLORS.sub2],
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0 ? false : true,
    },
    states:
      controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
        ? {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          }
        : {},
  };

  const BarChartOptions: ApexOptions = {
    chart: {
      width: '100%',
      type: 'bar',
      offsetX: 30,
      toolbar: {
        show: false,
      },
    },
    series:
      controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
        ? [
            {
              data: [16, 8],
            },
          ]
        : [
            {
              name: '몰입한 시간',
              data: [focusTimeData!.lastWeek!, focusTimeData!.thisWeek!],
            },
          ],
    xaxis: {
      categories: ['지난주', '이번주'],
      labels: {
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        formatter: (value) => {
          return parseFloat(value.toFixed(1)) + '시간';
        },
        style: {
          fontSize: '10px',
        },
      },
      opposite: true,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0 ? false : true,
    },
    states:
      controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
        ? {
            hover: {
              filter: {
                type: 'none',
              },
            },
            active: {
              filter: {
                type: 'none',
              },
            },
          }
        : {},
    plotOptions: {
      bar: {
        borderRadius: 8,
        borderRadiusApplication: 'end',
        horizontal: false,
        columnWidth: '40px',
        colors: {
          ranges:
            controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
              ? [
                  { from: 0, to: 100, color: COLORS.gray100 },
                  { from: 0, to: 100, color: COLORS.gray100 },
                ]
              : [
                  { from: 0, to: 100, color: COLORS.sub2 },
                  { from: 0, to: 100, color: COLORS.sub2 },
                ],
        },
      },
    },
  };

  const DonutChartOptions: ApexOptions = {
    series: projectStatList?.data.length === 0 ? [1] : projectStatList?.data.map((v) => v.thisWeekMin).slice(0, 7) || [0],
    colors: projectStatList?.data.length === 0 ? ['#F4EBFF'] : ['#7F56D9', '#9E77ED', '#B692F6', '#D6BBFB', '#E6D2FE', '#EFE3FE', '#F4EBFF'],
    labels: projectStatList?.data.map((v) => v.title) || ['No Project'],
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: projectStatList?.data.length !== 0,
      y: {
        formatter: function (value) {
          return `${parseFloat((value / 60).toFixed(1))}시간`;
        },
      },
    },
    states:
      projectStatList?.data.length === 0
        ? {
            hover: {
              filter: {
                type: 'none', // isEmpty일 때 호버 시 색상 변화를 막음
              },
            },
          }
        : {},
    responsive: [
      {
        breakpoint: 0,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const DetailDonutChartOptions: ApexOptions = {
    series: projectStatList?.data.length === 0 ? [1] : projectStatList?.data.map((v) => v.thisWeekMin) || [0],
    colors:
      projectStatList?.data.length === 0
        ? ['#F4EBFF']
        : ['#7F56D9', '#9E77ED', '#B692F6', '#D6BBFB', '#E6D2FE', '#EFE3FE', '#F4EBFF', ...[...Array(projectStatList?.data.length)].map(() => '#F4EBFF')],
    labels: projectStatList?.data.map((v) => v.title) || ['No Project'],
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: projectStatList?.data.length !== 0,
      y: {
        formatter: function (value) {
          return `${parseFloat((value / 60).toFixed(1))}시간`;
        },
      },
    },
    states:
      projectStatList?.data.length === 0
        ? {
            hover: {
              filter: {
                type: 'none', // isEmpty일 때 호버 시 색상 변화를 막음
              },
            },
          }
        : {},
    responsive: [
      {
        breakpoint: 0,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  useEffect(() => {
    handleRefresh();
  }, [weekDate]);

  useEffect(() => {
    if (refCategoryStat && refCategoryStat.current && render) setCategoryStatHeight(refCategoryStat.current?.getBoundingClientRect().height);
  }, [refCategoryStat]);

  const handleRefresh = async () => {
    const thisWeekTaskboxes = await readTaskboxesV1TaskboxesGet({
      start_date: dayjs(weekDate.start).startOf('week').format('YYYY-MM-DD'),
      end_date: dayjs(weekDate.end).endOf('week').format('YYYY-MM-DD'),
    });

    const lastWeekTaskboxes = await readTaskboxesV1TaskboxesGet({
      start_date: dayjs(weekDate.start).subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
      end_date: dayjs(weekDate.end).subtract(1, 'week').endOf('week').format('YYYY-MM-DD'),
    });

    const categoryStatList = await getWeeklyCategoryStatV1StatCategoryWeekGet({
      startDate: dayjs(weekDate.start).startOf('week').format('YYYY-MM-DD'),
      endDate: dayjs(weekDate.end).endOf('week').format('YYYY-MM-DD'),
    });

    const projectStatList = await getWeeklyProjectStatV1StatProjectWeekGet({
      startDate: dayjs(weekDate.start).startOf('week').format('YYYY-MM-DD'),
      endDate: dayjs(weekDate.end).endOf('week').format('YYYY-MM-DD'),
    });

    handleTimeboxingTime(lastWeekTaskboxes, thisWeekTaskboxes);
    handleLineChartData(thisWeekTaskboxes);
    handleBarChartData(lastWeekTaskboxes, thisWeekTaskboxes);
    handleInvestedTaskboxes(thisWeekTaskboxes);
    if (projectStatList.data.length < 4) {
      handlePriorityProject(projectStatList.data);
    }
    setCategoryStatList(categoryStatList);
    setProjectStatList(projectStatList);
    setRender(true);
  };

  const handleTimeboxingTime = (lastWeekTaskboxes: OutTaskboxDetailResponse[], thisWeekTaskboxes: OutTaskboxDetailResponse[]) => {
    let thisWeekTotal = 0;
    let thisWeekDone = 0;
    let lastWeekTotal = 0;
    let lastWeekDone = 0;
    thisWeekTaskboxes.forEach((taskbox) => {
      thisWeekTotal += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      if (taskbox.done) {
        thisWeekDone += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      }
    });
    lastWeekTaskboxes.forEach((taskbox) => {
      lastWeekTotal += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      if (taskbox.done) {
        lastWeekDone += dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute');
      }
    });

    const control: ControlTime = {
      lastWeek: {
        totalTimeboxing: lastWeekTotal / 60,
        doneTimeboxing: lastWeekDone / 60,
      },
      thisWeek: {
        totalTimeboxing: thisWeekTotal / 60,
        doneTimeboxing: thisWeekDone / 60,
      },
    };
    setControlTimeData(control);
  };

  const handleLineChartData = (taskboxes: OutTaskboxDetailResponse[]) => {
    const data: PaceData[] = [];
    let cumulativeTimeblocks = 0;
    let cumulativeDoneTimeblocks = 0;

    for (let i = 0; i < 7; i++) {
      if (dayjs(weekDate.start).add(i, 'day').isAfter(dayjs())) continue;
      const date = dayjs(weekDate.start).add(i, 'day').format(DATE_FORMAT_4);

      const timeblocks = taskboxes.filter(
        (taskbox) => !taskbox.allDay && dayjs(taskbox.start?.datetime).isSame(dayjs(weekDate.start).add(i, 'day'), 'date'),
      ).length;
      const doneTimeblocks = taskboxes.filter(
        (taskbox) => !taskbox.allDay && taskbox.done && dayjs(taskbox.start?.datetime).isSame(dayjs(weekDate.start).add(i, 'day'), 'date'),
      ).length;
      cumulativeTimeblocks += timeblocks;
      cumulativeDoneTimeblocks += doneTimeblocks;
      data.push({
        date,
        timeblocks,
        cumulativeTimeblocks,
        doneTimeblocks,
        cumulativeDoneTimeblocks,
      });
    }
    setPaceData(data);
  };

  const handleBarChartData = (lastWeekTaskboxes: OutTaskboxDetailResponse[], thisWeekTaskboxes: OutTaskboxDetailResponse[]) => {
    const focusLastWeekTaskboxes = lastWeekTaskboxes.filter(
      (taskbox) => taskbox.done && !taskbox.allDay && dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') >= 90,
    );
    const focusThisWeekTaskboxes = thisWeekTaskboxes.filter(
      (taskbox) => taskbox.done && !taskbox.allDay && dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') >= 90,
    );

    const lastWeekFocusTime = focusLastWeekTaskboxes.reduce((acc, cur) => {
      return acc + dayjs(cur.end?.datetime).diff(dayjs(cur.start?.datetime), 'minute');
    }, 0);
    const thisWeekFocusTime = focusThisWeekTaskboxes.reduce((acc, cur) => {
      return acc + dayjs(cur.end?.datetime).diff(dayjs(cur.start?.datetime), 'minute');
    }, 0);

    setFocusTimeData({
      lastWeek: lastWeekFocusTime / 60,
      thisWeek: thisWeekFocusTime / 60,
    });
  };

  const handleInvestedTaskboxes = (thisWeekTaskboxes: OutTaskboxDetailResponse[]) => {
    const investedTaskboxes = [{}, {}, {}];
    const unduplicatedTaskboxes: OutTaskboxDetailResponse[] = [];
    thisWeekTaskboxes
      .filter((taskbox) => !taskbox.allDay && (taskbox.done || (taskbox.tasks && taskbox.tasks.filter((task) => task.done).length > 0)))
      .forEach((v) => {
        const isDuplicate = unduplicatedTaskboxes.some(
          (t) =>
            t.isRecurrence &&
            t.title === v.title &&
            dayjs(t.start?.datetime).format('HH:mm') === dayjs(v.start?.datetime).format('HH:mm') &&
            dayjs(t.end?.datetime).format('HH:mm') === dayjs(v.end?.datetime).format('HH:mm'),
        );

        if (!isDuplicate) {
          unduplicatedTaskboxes.push(v);
        }
      });
    const priorityTaskboxes = unduplicatedTaskboxes
      .sort((a, b) => dayjs(b.end?.datetime).diff(dayjs(b.start?.datetime), 'minute') - dayjs(a.end?.datetime).diff(dayjs(a.start?.datetime), 'minute'))
      .slice(0, 3);
    investedTaskboxes.splice(0, priorityTaskboxes.length, ...priorityTaskboxes);
    setInvestedTaskboxes(investedTaskboxes);
  };

  const handlePriorityProject = (projects: OutProjectStat[]) => {
    const priorityProjects: OutProjectStat[] = [
      {
        place: 'DESK',
        title: '',
        thisWeekMin: 0,
      },
      { place: 'DESK', title: '', thisWeekMin: 0 },
      { place: 'DESK', title: '', thisWeekMin: 0 },
    ];

    priorityProjects.splice(0, projects.length, ...projects);
    setPriorityProject(priorityProjects);
  };

  const handleClickThisWeek = () => {
    setWeekDate({
      start: dayjs().startOf('week').toDate(),
      end: dayjs().endOf('week').toDate(),
    });
  };

  const handleClickPrev = () => {
    setWeekDate({
      start: dayjs(weekDate.start).subtract(1, 'week').startOf('week').toDate(),
      end: dayjs(weekDate.end).subtract(1, 'week').endOf('week').toDate(),
    });
  };

  const handleClickNext = () => {
    setWeekDate({
      start: dayjs(weekDate.start).add(1, 'week').startOf('week').toDate(),
      end: dayjs(weekDate.end).add(1, 'week').endOf('week').toDate(),
    });
  };

  const convertTime = (time: number) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    if (hours > 0 && minutes > 0) {
      return `${hours}시간 ${minutes}분`;
    } else if (hours > 0) {
      return `${hours}시간`;
    } else if (minutes > 0) {
      return `${minutes}분`;
    } else {
      return '0분';
    }
  };

  const isThisWeek = (date: Date) => dayjs(date).isSame(dayjs(), 'week');
  const isLastWeek = (date: Date) => dayjs(date).isSame(dayjs().subtract(1, 'week'), 'week');
  const label = isThisWeek(weekDate.start) ? '이번주' : isLastWeek(weekDate.start) ? '지난주' : null;

  return (
    <Container>
      {render ? (
        <TimeGraphWrapper>
          <div>
            <DateWrapper>
              <DateContentWrapper>
                {label && (
                  <div
                    style={{
                      backgroundColor: label === '이번주' ? COLORS.brand1 : COLORS.white,
                      border: `1px solid ${label === '이번주' ? 'transparent' : COLORS.gray200}`,
                      borderRadius: '40px',
                      color: label === '이번주' ? COLORS.white : COLORS.gray900,
                      fontSize: '13px',
                      fontWeight: 700,
                      marginRight: 8,
                      padding: '2px 8px',
                    }}
                  >
                    {dayjs(weekDate.start).isSame(dayjs(), 'week') ? '이번주' : '지난주'}
                  </div>
                )}
                <DateContent>{`${dayjs(weekDate?.start).format('M월 D일 (dd)')} - ${dayjs(weekDate?.end).format('M월 D일 (dd)')}`}</DateContent>
              </DateContentWrapper>
              <DateButtonWrapper>
                <IconButton
                  disabled={!dayjs(weekDate.start).isAfter(dayjs(me?.createdAt))}
                  onClick={handleClickPrev}
                  sx={{
                    borderRadius: '8px',
                    border: `1px solid ${COLORS.gray200}`,
                    backgroundColor: COLORS.white,
                    padding: '6px',
                  }}
                >
                  <Icons.ArrowLeftBig width={16} height={16} fill={dayjs(weekDate.start).isAfter(dayjs()) ? COLORS.gray400 : COLORS.gray900} />
                </IconButton>
                <Button
                  onClick={handleClickThisWeek}
                  sx={{
                    height: '32px',
                    borderRadius: '8px',
                    border: `1px solid ${COLORS.gray200}`,
                    backgroundColor: COLORS.white,
                    color: COLORS.gray800,
                    margin: '0px 4px',
                    padding: '8px 12px',
                    fontSize: '13px',
                    fontWeight: 700,
                  }}
                >
                  이번주
                </Button>
                <IconButton
                  disabled={!dayjs(weekDate.end).isBefore(dayjs())}
                  onClick={handleClickNext}
                  sx={{
                    borderRadius: '8px',
                    border: `1px solid ${COLORS.gray200}`,
                    backgroundColor: COLORS.white,
                    padding: '6px',
                  }}
                >
                  <Icons.ArrowRightBig width={16} height={16} fill={!dayjs(weekDate.end).isBefore(dayjs()) ? COLORS.gray400 : COLORS.gray900} />
                </IconButton>
              </DateButtonWrapper>
            </DateWrapper>
            <BoardHeaderWrapper>{me!.name}님, 안녕하세요!</BoardHeaderWrapper>
            <BoardBodyWrapper>
              <BoardGraphWrapper>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <GraphTimeWrapper>
                    <GraphTimeHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '4px' }}>내가 통제한 시간</div>
                        <div className="control-wrapper" style={{ cursor: 'pointer', position: 'relative' }}>
                          <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray500} />
                          <TooltipWrapper className="control-tooltip" style={{ left: '-122px', top: '22px' }}>
                            <div style={{ marginBottom: '8px', fontWeight: 700 }}>내가 통제한 시간</div>
                            <div>
                              <div>무엇인가요?</div>
                              <div>&nbsp;&nbsp;• 타임박싱하고 완료한 시간이에요.</div>
                            </div>
                            <div>
                              <div>왜 필요한가요?</div>
                              <div>
                                &nbsp;&nbsp;• 통제한 시간을 기록함으로써 시간을 &apos;생각대로&apos; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사용하는 능력을 키울 수
                                있어요.
                              </div>
                            </div>
                          </TooltipWrapper>
                        </div>
                      </div>
                      <Divider style={{ marginTop: '12px' }} />
                    </GraphTimeHeader>
                    <GraphTimeContent style={{ width: '370px', padding: '0px 24px' }}>
                      <TimeboxingTimeWrapper>
                        <TimeboxingTimeHeader>이번주 타임박싱한 시간</TimeboxingTimeHeader>
                        <TimeboxingTimeContent>
                          {parseFloat(controlTimeData!.thisWeek.totalTimeboxing.toFixed(1)!) === 0
                            ? '-'
                            : parseFloat(controlTimeData!.thisWeek.totalTimeboxing.toFixed(1)!)}
                          시간
                        </TimeboxingTimeContent>
                      </TimeboxingTimeWrapper>
                      <TimeboxingTimeWrapper>
                        <TimeboxingTimeHeader>그 중 완료한 시간</TimeboxingTimeHeader>
                        <TimeboxingTimeContent>
                          {parseFloat(controlTimeData!.thisWeek.doneTimeboxing.toFixed(1)!) === 0
                            ? '-'
                            : parseFloat(controlTimeData!.thisWeek.doneTimeboxing.toFixed(1)!)}
                          시간
                        </TimeboxingTimeContent>
                      </TimeboxingTimeWrapper>
                    </GraphTimeContent>
                    <GraphTimeFooter
                      style={{
                        color:
                          controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0 ? COLORS.gray400 : COLORS.gray900,
                      }}
                    >
                      {paceData.map((v) => v.date).length < 7 ? (
                        <>
                          {controlTimeData?.thisWeek.totalTimeboxing === 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            '🌱 할 일을 타임박싱하고 완료하면 확인할 수 있어요!'}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            `👊 이번주 통제한 시간, ${parseFloat(controlTimeData.thisWeek.totalTimeboxing.toFixed(1))}시간 도전중!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData!.thisWeek.doneTimeboxing! > 0 &&
                            `👊 이번주 통제한 시간, ${parseFloat(controlTimeData!.thisWeek.doneTimeboxing.toFixed(1))}시간 달성중!`}
                        </>
                      ) : (
                        <>
                          {controlTimeData?.thisWeek.totalTimeboxing === 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            '🌱 할 일을 타임박싱하고 완료하면 확인할 수 있어요!'}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            controlTimeData.lastWeek.totalTimeboxing > controlTimeData.thisWeek.totalTimeboxing &&
                            `😭 지난주보다 타임박싱한 시간이 ${parseFloat(
                              (controlTimeData.lastWeek.totalTimeboxing - controlTimeData.thisWeek.totalTimeboxing).toFixed(1),
                            )}시간 줄었어요!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            controlTimeData!.lastWeek.totalTimeboxing > 0 &&
                            controlTimeData.lastWeek.totalTimeboxing < controlTimeData.thisWeek.totalTimeboxing &&
                            `👍 지난주보다 타임박싱한 시간이 ${parseFloat(
                              (controlTimeData.thisWeek.totalTimeboxing - controlTimeData.lastWeek.totalTimeboxing).toFixed(1),
                            )}시간 늘었어요!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData?.thisWeek.doneTimeboxing === 0 &&
                            controlTimeData.lastWeek.totalTimeboxing === 0 &&
                            `👊 이번주 통제한 시간, ${parseFloat(controlTimeData.thisWeek.totalTimeboxing.toFixed(1))}시간 도전중!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData!.thisWeek.doneTimeboxing! > 0 &&
                            controlTimeData!.lastWeek.doneTimeboxing > controlTimeData!.thisWeek.doneTimeboxing &&
                            `😭 지난주보다 통제한 시간이 ${parseFloat(
                              (controlTimeData!.lastWeek.doneTimeboxing - controlTimeData!.thisWeek.doneTimeboxing).toFixed(1),
                            )}시간 줄었어요!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData!.thisWeek.doneTimeboxing! > 0 &&
                            controlTimeData!.lastWeek.totalTimeboxing > 0 &&
                            controlTimeData!.lastWeek.doneTimeboxing < controlTimeData!.thisWeek.doneTimeboxing &&
                            `👍 지난주보다 통제한 시간이 ${parseFloat(
                              (controlTimeData!.thisWeek.doneTimeboxing - controlTimeData!.lastWeek.doneTimeboxing).toFixed(1),
                            )}시간 늘었어요!`}
                          {controlTimeData!.thisWeek.totalTimeboxing! > 0 &&
                            controlTimeData!.thisWeek.doneTimeboxing! > 0 &&
                            controlTimeData!.lastWeek.totalTimeboxing === 0 &&
                            `👊 이번주 통제한 시간, ${parseFloat(controlTimeData!.thisWeek.doneTimeboxing.toFixed(1))}시간 달성중!`}
                        </>
                      )}
                    </GraphTimeFooter>
                  </GraphTimeWrapper>
                  <GraphTimeWrapper style={{ width: '638px' }}>
                    <GraphTimeHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '4px' }}>나의 페이스</div>
                        <div className="pace-wrapper" style={{ cursor: 'pointer', position: 'relative' }}>
                          <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray500} />
                          <TooltipWrapper className="pace-tooltip" style={{ width: '321px', left: '-130px', top: '22px' }}>
                            <div style={{ marginBottom: '8px', fontWeight: 700 }}>나의 페이스</div>
                            <div>
                              <div>무엇인가요?</div>
                              <div>&nbsp;&nbsp;• 계획한 시간과 업무를 완료한 시간의 추이를 확인할 수 있어요.</div>
                            </div>
                            <div>
                              <div>왜 필요한가요?</div>
                              <div>&nbsp;&nbsp;• 남은 업무량을 예측할 수 있어요.</div>
                            </div>
                          </TooltipWrapper>
                        </div>
                      </div>
                      <Divider sx={{ marginTop: '12px', marginBottom: '24px' }} />
                    </GraphTimeHeader>
                    <GraphTimeContent>
                      <ReactApexChart options={lineChartOptions} series={lineChartOptions.series} type={'area'} width={'622px'} height={'200px'} />
                    </GraphTimeContent>
                  </GraphTimeWrapper>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                  <GraphTimeWrapper style={{ justifyContent: 'unset', height: '100%', minHeight: '296px' }}>
                    <GraphTimeHeader>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '4px' }}>내가 투자한 카테고리</div>
                      </div>
                      <Divider sx={{ marginTop: '12px', marginBottom: '20px' }} />
                    </GraphTimeHeader>
                    <CategoryStatWrapper ref={refCategoryStat}>
                      <CategoryChartWrapper style={{ width: '100%' }}>
                        {categoryStatList?.data.length === 0 ? (
                          <>
                            <CategoryChartContent width={70} color={COLORS.gray200} />
                            <CategoryChartContent width={30} color={COLORS.gray200} />
                          </>
                        ) : (
                          <>
                            {categoryStatList?.data.map((category, idx) => (
                              <CategoryChartContent
                                key={category.id}
                                width={(category.thisWeekMin / categoryStatList.totalMin) * 100}
                                color={category.id ? getCategoryStatColor(category.color) : '#E5E5E6'}
                              />
                            ))}
                          </>
                        )}
                      </CategoryChartWrapper>
                      <CategoryStatList>
                        {categoryStatList && categoryStatList.data.length > 0 ? (
                          <>
                            {categoryStatList?.data.map((category, idx) => {
                              const thisWeekHours = (category.thisWeekMin / 60).toFixed(1);
                              const prevWeekMin = category.prevWeekMin || 0;
                              const increase = category.thisWeekMin - prevWeekMin;
                              const percentageChange = ((increase / prevWeekMin) * 100).toFixed(0);
                              const isIncrease = increase > 0;
                              const isDecrease = increase < 0;
                              const changeColor = isIncrease ? COLORS.negative1 : isDecrease ? COLORS.sub2 : COLORS.gray900;
                              return (
                                <CategoryStatContent key={idx}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CategoryIcon
                                      bgColor={!category.id ? COLORS.gray100 : getCategoryBgColor(category.color)}
                                      textColor={!category.id ? COLORS.gray600 : getCategoryTextColor(category.color)}
                                    >
                                      #
                                    </CategoryIcon>
                                    <CategoryStatName>{!category.id ? '미지정' : category.name}</CategoryStatName>
                                  </div>
                                  <div style={{ width: '110px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CategoryStatTime>{parseFloat(thisWeekHours)}시간</CategoryStatTime>
                                    {category.prevWeekMin && (
                                      <Tooltip
                                        disableInteractive
                                        placement="right"
                                        title={
                                          isIncrease
                                            ? `지난주보다 ${convertTime(increase)} 증가했어요`
                                            : isDecrease
                                            ? `지난주보다 ${convertTime(Math.abs(increase))} 감소했어요`
                                            : ``
                                        }
                                      >
                                        <CategoryIncreaseDecrease>
                                          {isIncrease ? (
                                            <Icons.ArrowUpLong stroke={COLORS.negative1} />
                                          ) : isDecrease ? (
                                            <Icons.ArrowDownLong stroke={COLORS.sub2} />
                                          ) : (
                                            <div />
                                          )}
                                          <div style={{ marginLeft: 2, color: changeColor }}>{percentageChange}%</div>
                                        </CategoryIncreaseDecrease>
                                      </Tooltip>
                                    )}
                                    {/* <div
                                  style={{
                                    width: '33px',
                                    height: '19px',
                                    textAlign: 'center',
                                    backgroundColor: '#D7E2FF',
                                    borderRadius: '30px',
                                    color: COLORS.sub2,
                                    fontSize: '10px',
                                    padding: '2px 4px',
                                  }}
                                >
                                  NEW
                                </div> */}
                                  </div>
                                </CategoryStatContent>
                              );
                            })}{' '}
                          </>
                        ) : (
                          <>
                            <CategoryStatContent>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CategoryIcon bgColor={COLORS.gray100} textColor={COLORS.gray900}>
                                  #
                                </CategoryIcon>
                                <CategoryStatName style={{ color: COLORS.gray400 }}>카테고리명</CategoryStatName>
                              </div>
                              <div style={{ width: '110px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <CategoryStatTime>00시간 00분</CategoryStatTime>
                                <CategoryIncreaseDecrease>
                                  <Icons.ArrowUpLong stroke={COLORS.gray500} />
                                  <div style={{ marginLeft: 2, color: COLORS.gray500 }}>20%</div>
                                </CategoryIncreaseDecrease>
                              </div>
                            </CategoryStatContent>
                            <CategoryStatContent>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CategoryIcon bgColor={COLORS.gray100} textColor={COLORS.gray900}>
                                  #
                                </CategoryIcon>
                                <CategoryStatName style={{ color: COLORS.gray400 }}>카테고리명</CategoryStatName>
                              </div>
                              <div style={{ width: '110px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <CategoryStatTime>00시간 00분</CategoryStatTime>
                                <CategoryIncreaseDecrease>
                                  <Icons.ArrowDownLong stroke={COLORS.gray500} />
                                  <div style={{ marginLeft: 2, color: COLORS.gray500 }}>20%</div>
                                </CategoryIncreaseDecrease>
                              </div>
                            </CategoryStatContent>
                          </>
                        )}
                      </CategoryStatList>
                      {categoryStatList && categoryStatList.data.length > 0 && (
                        <>
                          <Divider sx={{ marginBottom: '4px' }} />
                          {categoryStatList?.totalMin && (
                            <CategoryTotalTimeWrapper>
                              <div style={{ fontSize: 12, fontWeight: 700, paddingLeft: 28 }}>합계</div>
                              <div style={{ width: 110, color: COLORS.gray600, fontSize: 12, fontWeight: 700 }}>{convertTime(categoryStatList.totalMin)}</div>
                            </CategoryTotalTimeWrapper>
                          )}
                        </>
                      )}
                    </CategoryStatWrapper>
                  </GraphTimeWrapper>
                  <GraphTimeWrapper style={{ width: '638px', height: '100%', minHeight: '296px' }}>
                    <GraphTimeHeader>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: '4px' }}>내가 투자한 프로젝트</div>
                        <IconButton onClick={() => setIsProjectDetail(true)}>
                          <Icons.FocusMode stroke={COLORS.gray900} />
                        </IconButton>
                      </div>
                      <Divider sx={{ marginTop: '12px', marginBottom: '20px' }} />
                    </GraphTimeHeader>
                    <ProjectStatWrapper>
                      <GraphTimeContent style={{ position: 'relative' }}>
                        <ReactApexChart options={DonutChartOptions} series={DonutChartOptions.series} type={'donut'} width={'180px'} height={'180px'} />
                        {projectStatList && (
                          <div
                            style={{
                              width: '100px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'absolute',
                              left: '24%',
                              fontSize: projectStatList?.totalMin === 0 ? 12 : 20,
                              fontWeight: 700,
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ color: projectStatList && projectStatList?.totalMin === 0 ? COLORS.gray700 : COLORS.gray900 }}>
                                {parseFloat((projectStatList!.totalMin / 60).toFixed(1)) === 0 ? '-' : parseFloat((projectStatList!.totalMin / 60).toFixed(1))}
                              </span>
                              <span style={{ fontSize: 12, marginLeft: 2, color: projectStatList?.totalMin === 0 ? COLORS.gray700 : COLORS.gray900 }}>
                                시간
                              </span>
                            </div>
                          </div>
                        )}
                      </GraphTimeContent>
                      <ProjectStatContentWrapper>
                        {projectStatList!.data.length! < 4 &&
                          priorityProject?.map((project, idx) => (
                            <div key={idx} style={{ marginBottom: 8 }}>
                              <ProjectStatContent>
                                <ProjectStatPriority
                                  style={
                                    !project.id
                                      ? {
                                          color: '#7F56D9',
                                          backgroundColor: '#F4EBFF',
                                        }
                                      : {}
                                  }
                                  color={idx === 0 ? COLORS.project1 : idx === 1 ? COLORS.project2 : COLORS.project3}
                                >{`Top ${idx + 1}`}</ProjectStatPriority>
                                {project.id ? (
                                  <InvestedTaskboxContent>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {project?.place === 'DESK' && <Icons.PriorityIssueUncheck width={16} height={16} stroke={COLORS.issue2} />}
                                      {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
                                        <div
                                          style={{
                                            width: 16,
                                            height: 16,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                            backgroundColor: COLORS.issue2,
                                          }}
                                        >
                                          <Icons.DeskIssueHold />
                                        </div>
                                      )}
                                      {project?.place === 'ARCHIVE' && (
                                        <div
                                          style={{
                                            width: 16,
                                            height: 16,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '50%',
                                            backgroundColor: COLORS.issue2,
                                          }}
                                        >
                                          <Icons.PriorityIssueCheck width={16} height={16} stroke={COLORS.issue2} fill={COLORS.issue2} />
                                        </div>
                                      )}
                                      <div style={{ marginLeft: '8px', fontSize: '12px', fontWeight: 700 }}>{project.title}</div>
                                    </div>
                                    <div style={{ fontSize: '10px', color: COLORS.gray700 }}>{convertTime(project.thisWeekMin)}</div>
                                  </InvestedTaskboxContent>
                                ) : (
                                  <InvestedTaskboxContent style={{ color: COLORS.gray400, fontSize: 12 }}>
                                    프로젝트를 타임블록하고 완료하면 확인할 수 있어요!
                                  </InvestedTaskboxContent>
                                )}
                              </ProjectStatContent>
                            </div>
                          ))}
                        {projectStatList!.data.length >= 4 &&
                          projectStatList?.data.map(
                            (project, idx) =>
                              idx < 7 && (
                                <div key={project.id} style={{ marginBottom: 8 }}>
                                  <ProjectStatContent>
                                    {idx < 3 ? (
                                      <ProjectStatPriority color={idx === 0 ? COLORS.project1 : idx === 1 ? COLORS.project2 : COLORS.project3}>{`Top ${
                                        idx + 1
                                      }`}</ProjectStatPriority>
                                    ) : (
                                      <div style={{ width: 48 }} />
                                    )}
                                    <InvestedTaskboxContent style={{ height: categoryStatHeight / projectStatList.data.length, maxHeight: '38px' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icons.PriorityIssueCheck width={16} height={16} stroke={COLORS.issue2} fill={COLORS.issue2} />
                                        <div style={{ marginLeft: '8px', fontSize: '12px', fontWeight: 700 }}>{project.title}</div>
                                      </div>
                                      <div style={{ fontSize: '10px', color: COLORS.gray700 }}>{convertTime(project.thisWeekMin)}</div>
                                    </InvestedTaskboxContent>
                                  </ProjectStatContent>
                                </div>
                              ),
                          )}
                      </ProjectStatContentWrapper>
                    </ProjectStatWrapper>
                  </GraphTimeWrapper>
                </div>
                {!isFold && (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <GraphTimeWrapper>
                      <GraphTimeHeader>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>내가 몰입한 시간</div>
                          <div className="focus-wrapper" style={{ cursor: 'pointer', position: 'relative' }}>
                            <Icons.ExclamationInfo width={16} height={16} stroke={COLORS.gray500} />
                            <TooltipWrapper className="focus-tooltip" style={{ width: '284px', left: '-130px', top: '22px' }}>
                              <div style={{ marginBottom: '8px', fontWeight: 700 }}>내가 몰입한 시간</div>
                              <div>
                                <div>무엇인가요?</div>
                                <div>&nbsp;&nbsp;• 통제한 시간 중 90분 이상 길이의 업무들이에요.</div>
                              </div>
                              <div>
                                <div>왜 필요한가요?</div>
                                <div>&nbsp;&nbsp;• 연속된 긴 시간을 사용할 때, 쪼개서 시간을 쓸 때보다 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;더 집중할 수 있어요.</div>
                              </div>
                            </TooltipWrapper>
                          </div>
                        </div>
                        <Divider sx={{ marginTop: '12px', marginBottom: '6px' }} />
                      </GraphTimeHeader>
                      <GraphTimeContent style={{ padding: '0px 24px' }}>
                        <TimeboxingTimeWrapper>
                          <TimeboxingTimeHeader>이번주 몰입한 시간</TimeboxingTimeHeader>
                          <TimeboxingTimeContent>
                            {parseFloat(focusTimeData.thisWeek.toFixed(1)) === 0 ? '-' : parseFloat(focusTimeData.thisWeek.toFixed(1))}시간
                          </TimeboxingTimeContent>
                        </TimeboxingTimeWrapper>
                        <ReactApexChart options={BarChartOptions} series={BarChartOptions.series} type={'bar'} width={'200px'} height={'140px'} />
                      </GraphTimeContent>
                      <GraphTimeFooter
                        style={{
                          color:
                            controlTimeData?.thisWeek.totalTimeboxing === 0 && controlTimeData?.lastWeek.totalTimeboxing === 0
                              ? COLORS.gray400
                              : COLORS.gray900,
                        }}
                      >
                        {paceData.map((v) => v.date).length < 7 ? (
                          <>
                            {focusTimeData.lastWeek === 0 && focusTimeData.thisWeek === 0 && `🌱 할 일을 타임박싱하고 완료하면 확인할 수 있어요!`}
                            {focusTimeData.lastWeek > 0 && focusTimeData.thisWeek === 0 && `💪 지난주처럼 몰입한 시간을 확보해보세요!`}
                            {focusTimeData.thisWeek > 0 && `👊 이번주 몰입한 시간, ${parseFloat(focusTimeData.thisWeek.toFixed(1))}시간 도전중!`}
                          </>
                        ) : (
                          <>
                            {focusTimeData.lastWeek === 0 && focusTimeData.thisWeek === 0 && `🌱 할 일을 타임박싱하고 완료하면 확인할 수 있어요!`}
                            {focusTimeData.lastWeek > 0 && focusTimeData.thisWeek === 0 && `💪 지난주처럼 몰입한 시간을 확보해보세요!`}
                            {focusTimeData.lastWeek === 0 &&
                              focusTimeData.thisWeek > 0 &&
                              `👊 이번주 몰입한 시간, ${parseFloat(focusTimeData.thisWeek.toFixed(1))}시간 달성중!`}
                            {focusTimeData.lastWeek > 0 &&
                              focusTimeData.thisWeek > 0 &&
                              focusTimeData.lastWeek > focusTimeData.thisWeek &&
                              `😭 지난주보다 몰입한 시간이 ${parseFloat((focusTimeData.lastWeek - focusTimeData.thisWeek).toFixed(1))}시간 줄었어요!`}
                            {focusTimeData.lastWeek > 0 &&
                              focusTimeData.thisWeek > 0 &&
                              focusTimeData.lastWeek < focusTimeData.thisWeek &&
                              `👍 지난주보다 몰입한 시간이 ${parseFloat((focusTimeData.thisWeek - focusTimeData.lastWeek).toFixed(1))}시간 늘었어요!`}
                          </>
                        )}
                      </GraphTimeFooter>
                    </GraphTimeWrapper>
                    <GraphTimeWrapper style={{ width: '638px' }}>
                      <GraphTimeHeader>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>내가 투자한 업무</div>
                        </div>
                        <Divider sx={{ marginTop: '12px', marginBottom: '32px' }} />
                      </GraphTimeHeader>
                      {investedTaskboxes.slice(0, 3).map((taskbox, idx) => (
                        <InvestedTaskboxWrapper key={idx}>
                          <InvestedTaskboxPrioity>{`Top ${idx + 1}`}</InvestedTaskboxPrioity>
                          {taskbox.id ? (
                            <InvestedTaskboxContent>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {taskbox.done ? (
                                  <Icons.PriorityIssueCheck
                                    width={20}
                                    height={20}
                                    stroke={taskbox.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                    fill={taskbox.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                  />
                                ) : (
                                  <Icons.InprogressCheck width={20} height={20} fill={taskbox.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                                )}
                                <div style={{ marginLeft: '8px', fontSize: '16px', fontWeight: 700 }}>{taskbox.title}</div>
                              </div>
                              <div style={{ fontSize: '12px', color: COLORS.gray700 }}>
                                {dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') % 70 === 0
                                  ? `${Math.floor(dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') / 60)}시간 `
                                  : `${Math.floor(dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') / 60)}시간 ${
                                      dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime), 'minute') % 60
                                    }분`}
                              </div>
                            </InvestedTaskboxContent>
                          ) : (
                            <InvestedTaskboxContent style={{ color: COLORS.gray400 }}>할 일을 타임박싱하고 완료하면 확인할 수 있어요!</InvestedTaskboxContent>
                          )}
                        </InvestedTaskboxWrapper>
                      ))}
                    </GraphTimeWrapper>
                  </div>
                )}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                  <Button
                    onClick={() => setIsFold(!isFold)}
                    sx={{
                      'borderRadius': '8px',
                      'fontSize': 13,
                      'fontWeight': 700,
                      'color': COLORS.gray500,
                      'padding': '6px 8px',
                      ':hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    {isFold ? '펼치기' : '접기'}
                    {isFold ? <Icons.ArrowDownSmall stroke={COLORS.gray500} /> : <Icons.ArrowUpSmall stroke={COLORS.gray500} />}
                  </Button>
                </div>
              </BoardGraphWrapper>
            </BoardBodyWrapper>
          </div>
        </TimeGraphWrapper>
      ) : (
        <div style={{ position: 'absolute', top: `calc(100vh - 50%)`, left: `calc(100vw - 50%)` }}>
          <CircularProgress size={50} sx={{ color: COLORS.brand1 }} />
        </div>
      )}
      <Dialog open={isProjectDetail} onClose={() => setIsProjectDetail(false)}>
        <ProjectDetailWrapper>
          <GraphTimeHeader>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ marginRight: '4px' }}>내가 투자한 프로젝트</div>
              <IconButton onClick={() => setIsProjectDetail(false)}>
                <Icons.Close width={16} height={16} stroke={COLORS.gray900} />
              </IconButton>
            </div>
            <Divider sx={{ marginTop: '12px', marginBottom: '20px' }} />
          </GraphTimeHeader>
          <ProjectDetailStatWrapper>
            <GraphTimeContent style={{ position: 'relative' }}>
              <ReactApexChart options={DetailDonutChartOptions} series={DetailDonutChartOptions.series} type={'donut'} width={'180px'} height={'180px'} />
              {projectStatList && (
                <div
                  style={{
                    width: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: '24%',
                    fontSize: projectStatList?.totalMin === 0 ? 12 : 20,
                    fontWeight: 700,
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: projectStatList && projectStatList?.totalMin === 0 ? COLORS.gray700 : COLORS.gray900 }}>
                      {parseFloat((projectStatList!.totalMin / 60).toFixed(1)) === 0 ? '-' : parseFloat((projectStatList!.totalMin / 60).toFixed(1))}
                    </span>
                    <span style={{ fontSize: 12, marginLeft: 2, color: projectStatList?.totalMin === 0 ? COLORS.gray700 : COLORS.gray900 }}>시간</span>
                  </div>
                </div>
              )}
            </GraphTimeContent>
            <ProjectDetailScrollWrapper>
              <ProjectDetailStatContentWrapper>
                {projectStatList?.data.map((project, idx) => {
                  return (
                    <div key={project.id} style={{ marginBottom: 8 }}>
                      <ProjectStatContent>
                        {idx < 3 ? (
                          <ProjectStatPriority color={idx === 0 ? COLORS.project1 : idx === 1 ? COLORS.project2 : COLORS.project3}>{`Top ${
                            idx + 1
                          }`}</ProjectStatPriority>
                        ) : (
                          <div style={{ width: 48 }} />
                        )}
                        <InvestedTaskboxContent>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {project?.place === 'DESK' && <Icons.PriorityIssueUncheck width={16} height={16} stroke={COLORS.issue2} />}
                            {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
                              <div
                                style={{
                                  width: 16,
                                  height: 16,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%',
                                  backgroundColor: COLORS.issue2,
                                }}
                              >
                                <Icons.DeskIssueHold />
                              </div>
                            )}
                            {project?.place === 'ARCHIVE' && (
                              <div
                                style={{
                                  width: 16,
                                  height: 16,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%',
                                  backgroundColor: COLORS.issue2,
                                }}
                              >
                                <Icons.PriorityIssueCheck width={16} height={16} stroke={COLORS.issue2} fill={COLORS.issue2} />
                              </div>
                            )}
                            <div style={{ marginLeft: '8px', fontSize: '12px', fontWeight: 700 }}>{project.title}</div>
                          </div>
                          <div style={{ fontSize: '10px', color: COLORS.gray700 }}>{convertTime(project.thisWeekMin)}</div>
                        </InvestedTaskboxContent>
                      </ProjectStatContent>
                    </div>
                  );
                })}
              </ProjectDetailStatContentWrapper>{' '}
            </ProjectDetailScrollWrapper>
          </ProjectDetailStatWrapper>
        </ProjectDetailWrapper>
      </Dialog>
    </Container>
  );
};
export default Home;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeGraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0px;
  overflow-y: scroll;
  ${hideScroll}
`;

const DateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DateContent = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const DateButtonWrapper = styled.div``;

const BoardHeaderWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${COLORS.sub3};
  color: ${COLORS.sub2};
  margin: 24px 0px;
  padding: 24px;
  font-size: 24px;
  font-weight: 700;
`;

const BoardBodyWrapper = styled.div`
  width: 100%;
  height: calc(100% - 166px);
`;

const BoardGraphWrapper = styled.div`
  width: 1076px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  /* overflow-y: scroll;
  ${hideScroll} */
`;

const GraphTimeWrapper = styled.div`
  width: 418px;
  height: 296px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 24px;
`;

const GraphTimeHeader = styled.div`
  font-size: 16px;
  font-weight: 700;

  .control-wrapper {
    &:hover {
      .control-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .pace-wrapper {
    &:hover {
      .pace-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .focus-wrapper {
    &:hover {
      .focus-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .invest-wrapper {
    &:hover {
      .invest-tooltip {
        opacity: 1;
        z-index: 10;
      }
    }
  }
`;
const GraphTimeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GraphTimeFooter = styled.div`
  border-radius: 8px;
  background-color: ${COLORS.gray100};
  padding: 12px 16px;
`;

const TimeboxingTimeWrapper = styled.div`
  width: 142px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimeboxingTimeHeader = styled.div`
  color: ${COLORS.gray500};
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;
const TimeboxingTimeContent = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const InvestedTaskboxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const InvestedTaskboxPrioity = styled.div`
  width: 48px;
  border-radius: 8px;
  background-color: ${COLORS.alert2};
  color: ${COLORS.alert1};
  padding: 4px 0px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

const InvestedTaskboxContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  margin-left: 12px;
  padding: 12px 16px;
  font-size: 16px;
`;

const TooltipWrapper = styled.div`
  width: 265px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${COLORS.gray900};
  color: ${COLORS.white};
  position: absolute;
  z-index: -1;
  opacity: 0;
  font-size: 12px;
  font-weight: 400;
`;

const CategoryStatWrapper = styled.div`
  width: 100%;
`;

const CategoryChartWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CategoryChartContent = styled.div<{ width?: number; color?: string }>`
  width: ${(props) => props.width}%;
  height: 24px;
  background-color: ${(props) => props.color};
  :first-of-type {
    border-radius: 8px 0px 0px 8px;
  }

  :last-child {
    border-radius: 0px 8px 8px 0px;
  }

  :not(:last-child) {
    margin-right: 2px;
  }
`;

const CategoryStatList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CategoryStatContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CategoryIcon = styled.div<{ bgColor?: string; textColor?: string }>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : COLORS.gray100)};
  color: ${(props) => (props.textColor ? props.textColor : COLORS.gray600)};
  margin-right: 8px;
`;

const CategoryStatName = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const CategoryStatTime = styled.div`
  font-size: 12px;
  color: ${COLORS.gray700};
`;

const CategoryIncreaseDecrease = styled.div`
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
`;

const CategoryTotalTimeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProjectStatWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ProjectStatContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProjectStatContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ProjectStatPriority = styled.div<{ color: string }>`
  width: 48px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  color: ${COLORS.white};
  padding: 4px 0px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

const ProjectDetailWrapper = styled.div`
  width: 600px;
  max-height: 726px;
  padding: 24px;
`;

const ProjectDetailStatWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ProjectDetailScrollWrapper = styled.div`
  width: 100%;
  max-height: 622px;
  overflow-y: scroll;
  ${hideScroll}
`;

const ProjectDetailStatContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
