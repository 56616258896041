/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Custom title
 * 커스텀 OpenAPI 스키마
 * OpenAPI spec version: 3.0.2
 */
import type {
  CreateGoogleUserByAppV1AuthGoogleGetParams,
  Token,
  CreateUser,
  OutWorkspaceWithToken,
  OutWorkspace,
  CreateWorkspace,
  OutOAuthUser,
  BasePaginationOutReadEvent,
  GetEventsV1EventsGetParams,
  OutCreateEventSimple,
  CreateEventWithParam,
  UpdateEventWithParam,
  OutBaseResponse,
  RemoveEventV1EventsCalendarIdEventIdDeleteParams,
  OutCalendar,
  InUpdateCalendar,
  InUpdateCalendarShown,
  AppApplicationTasksSchemasOutTask,
  KernelBaseSchemaOutRemovedEntity,
  UpdateTask,
  CreateTask,
  AppApiMemosSchemasOutMemo,
  UpdateMemo,
  PaginationSlackReceiver,
  ListReceiversV1SlackReceiversGetParams,
  OutSlackState,
  InSlackState,
  IntegrateV1SlackAuthGetParams,
  OutDailyMemo,
  ReadDailyMemoV1DailyMemosGetParams,
  CreateDailyMemo,
  UpdateDailyMemo,
  OutTaskboxDetailResponse,
  ReadTaskboxesV1TaskboxesGetParams,
  OutTaskboxDetail,
  CreateTaskbox,
  RemoveTaskboxesV1TaskboxesDeleteParams,
  UpdateSubtaskBulk,
  UpdateBulkTaskbox,
  InCreateTaskBoxInRecurrence,
  UpdateTaskBoxRecurrence,
  UpdateTaskbox,
  AppTasksModelOutRemovedEntity,
  RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
  AssignTask,
  AbsorbingTask,
  InRecurrenceAction,
  OutTaskboxDetailResponseForSubtask,
  CreateTaskboxTask,
  UpdateTaskboxTask,
  OutWorks,
  ListWorksV1WorksGetParams,
  OutWork,
  CreateWork,
  UpdateWork,
  SubTask,
  OutIssue,
  UpdateIssueDetail,
  MoveWorkToTaskbox,
  OutPlanSection,
  CreatePlanSection,
  UpdatePlanSection,
  OutPlanWeekly,
  ListPlanWeeklyV1PlansWeeklyGetParams,
  CreatePlanWeekly,
  UpdatePlanWeekly,
  DetachWorkInWorkbox,
  OutFrequentWork,
  CreateFrequentWork,
  UpdateFrequentWork,
  CreateFrequentWorkTask,
  OutAutoComplete,
  ReadAutocompleteListV1AutocompleteGetParams,
  OutVote,
  CreateVote,
  WelcomeSurveyOut,
  SurveyResponseOut,
  RecordUserSurveyV1SurveysSurveyIdPostBody,
  RecordUserRequestSurveyV1SurveysRequestPostBody,
  OutCategory,
  GetAllCategoryV1CategoryGetParams,
  InCreateCategory,
  InUpdateCategory,
  AppMemoModelOutMemo,
  GetAllMemoV1MemoGetParams,
  InCreateMemo,
  InUpdateMemo,
  OutLink,
  GetAllLinkV1LinkGetParams,
  InCreateLink,
  InUpdateLink,
  OutProject,
  GetAllProjectV1ProjectGetParams,
  InCreateProject,
  InUpdateProject,
  InUpdateProjectOrder,
  InUpdateTasks,
  OutRecord,
  GetAllRecordV1RecordGetParams,
  InCreateRecord,
  InUpdateRecord,
  OutUserDetail,
  UpdateUserMe,
  AppUserModelOutUser,
  UpdateUserPermission,
  InDeleteAccount,
  UserSettingModel,
  UpdateUser,
  OutUserHistory,
  ReadUserHistoryV1UsersHistoryGetParams,
  OutStateStorage,
  InUpdateStateStorage,
  OutFeatureStorage,
  GetAllFeatureStorageV1FeatureStorageGetParams,
  InCreateFeatureStorage,
  InUpdateFeatureStorage,
  OutNote,
  InUpdateNote,
  OutCategoryStatList,
  GetWeeklyCategoryStatV1StatCategoryWeekGetParams,
  OutProjectStatList,
  GetWeeklyProjectStatV1StatProjectWeekGetParams,
  OutRoutine,
  InCreateRoutine,
  InUpdateBaseRoutine,
  OutTaskboxDetailRoutineOnly,
  ListRoutineInstanceByDateAndCountV1RoutineRoutineIdInstanceGetParams,
  InUpdateRoutineOrder,
  InUpdateRoutineRule,
  InUpdateRoutineTaskCollection,
  InUpdateRoutineRuleOptionalTime,
  InCreateRoutineExceptionTask,
  OutMeetingNote,
  ReadMeetingNoteByEventIdV1MeetingNotesGetParams,
  SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem,
  Pagination,
  ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetParams,
  OutAttachmentFile,
  CreateAttachmentFile,
  OutAttachment,
  AppApplicationAttachmentsSchemasUpdateAttachment,
  CreateAttachmentLink,
  OutInvitation,
  UpdateInvitation,
  CreateInvitationShare,
  OutReminder,
  UpdateReminder,
  OutReflection,
  ListReflectionsV1ReflectionsGetParams,
  OutReflectionSchedule,
  InReflectionSchedule,
  OutEvent,
  AppApplicationReflectionsSchemasOutMemo,
  InMemo,
  InReflection,
  OutAchievement,
  MoveWorkbox,
  UpdateWorkV2,
  OutCreatedEntity,
  MergeWorkOrWorkBoxes
} from './model'
import { customInstance } from '../lib/axios';



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * @summary Health Check
 */
export const healthCheckGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/`, method: 'get'
    },
      options);
    }
  
/**
 * @summary google로 로그인합니다. access_token이 query string으로 붙어 redirect 됩니다.
 */
export const createGoogleUserByAppV1AuthGoogleGet = (
    params?: CreateGoogleUserByAppV1AuthGoogleGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/auth/google`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @deprecated
 * @summary [FOR TEST] email 로그인
 */
export const createUserV1AuthEmailPost = (
    createUser: CreateUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/v1/auth/email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createUser
    },
      options);
    }
  
/**
 * @summary 사용자의 워크스페이스 목록을 조회합니다.
 */
export const listWorkspacesV1AuthWorkspacesGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkspaceWithToken[]>(
      {url: `/v1/auth/workspaces`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 워크스페이스를 생성합니다.
 */
export const createWorkspaceV1AuthWorkspacesPost = (
    createWorkspace: CreateWorkspace,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkspace>(
      {url: `/v1/auth/workspaces`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWorkspace
    },
      options);
    }
  
/**
 * 해당 워크스페이스 리소스에 접근 가능한 access token을 제공합니다.
 * @summary 워크스페이스에 참가합니다.
 */
export const joinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPost = (
    workspaceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/auth/workspaces/${workspaceId}/join`, method: 'post'
    },
      options);
    }
  
/**
 * @summary 내 정보
 */
export const userMeV1AuthMeGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutOAuthUser>(
      {url: `/v1/auth/me`, method: 'get'
    },
      options);
    }
  
/**
 * 구글 토큰 상태를 확인 합니다.
 * @summary Check Google Oauth Token Status
 */
export const checkGoogleOauthTokenStatusV1AuthGoogleTokenStatusGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/auth/google/token/status`, method: 'get'
    },
      options);
    }
  
/**
 * @summary access token을 갱신합니다.
 */
export const refreshV1TokenRefreshPost = (
    token: Token,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/v1/token/refresh`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: token
    },
      options);
    }
  
/**
 * -- Deprecated -- 
    요청에 시간이 오래 걸려 더이상 사용하지 않습니다.
    사용자의 캘린더 목록을 동기화하고, 이벤트를 동기화합니다.
    로그인, 사입업 하거나, 캘린더 목록 새로 갱신용으로만 사용해주세요.
 * @deprecated
 * @summary 캘린더 목록 동기화 & 이벤트 동기화
 */
export const initiateGoogleCalendarAndEventV1EventsSyncingGoogleCalendarGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/syncing-google-calendar`, method: 'get'
    },
      options);
    }
  
/**
 * 사용자의 캘린더 목록을 동기화합니다.
 * @summary 캘린더 목록 동기화
 */
export const syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/syncing-google-calendar-list`, method: 'get'
    },
      options);
    }
  
/**
 * 사용자의 캘린더 목록중 shown=true 캘린더를 동기화합니다.
 * @summary 캘린더별 일정 동기화 by shown ids
 */
export const syncGoogleCalendarEventOnlyShownV1EventsSyncingGoogleCalendarEventOnlyShownGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/syncing-google-calendar-event-only-shown`, method: 'get'
    },
      options);
    }
  
/**
 * 사용자의 캘린더 목록중 요청한 캘린더 목록을 동기화합니다.
 * @summary 캘린더별 일정 동기화 by calendar-ids
 */
export const syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost = (
    syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPostBody: string[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/syncing-google-calendar-event-by-ids`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPostBody
    },
      options);
    }
  
/**
 * @summary 사용자 일정을 조회합니다.
 */
export const getEventsV1EventsGet = (
    params?: GetEventsV1EventsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<BasePaginationOutReadEvent>(
      {url: `/v1/events`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 사용자 일정을 생성합니다.
 */
export const createEventV1EventsPost = (
    createEventWithParam: CreateEventWithParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCreateEventSimple>(
      {url: `/v1/events`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createEventWithParam
    },
      options);
    }
  
/**
 * 이 요청은 이벤트 전체를 보내야 합니다. 변경 사항 하나만 보내면 동작이 안됩니다.
    
    일정 참여를 위해서는 attendees[].responseStatus 를 업데이트 해야 합니다.
    self: True 인 대상의 responseStatus 변경만 반영 됩니다. 나머지는 변경 적용 불가능
    
    canModify False 인경우,
    attendees[].responseStatus, visibility, transparency만 수정이 반영됩니다. 
    나머지는 요청을 받아도 무시합니다.
 * @summary 사용자 일정을 수정합니다.
 */
export const updateEventsV1EventsCalendarIdEventIdPut = (
    calendarId: string,
    eventId: string,
    updateEventWithParam: UpdateEventWithParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/events/${calendarId}/${eventId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateEventWithParam
    },
      options);
    }
  
/**
 * @summary 사용자 일정 삭제
 */
export const removeEventV1EventsCalendarIdEventIdDelete = (
    calendarId: string,
    eventId: string,
    params?: RemoveEventV1EventsCalendarIdEventIdDeleteParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/events/${calendarId}/${eventId}`, method: 'delete',
        params
    },
      options);
    }
  
/**
 * @summary Google calendar Push
 */
export const handleEventNotificationV1EventsNotificationsPost = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/notifications`, method: 'post'
    },
      options);
    }
  
/**
 * 업데이트 또는 갱신이 필요할 경우
    /event/syncing-google-calendar 엔드포인트 사용
 * @summary 유저 캘린더 목록 조회
 */
export const getAllCalendarV1CalendarGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCalendar[]>(
      {url: `/v1/calendar`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 캘린더 조회
 */
export const getACalendarV1CalendarCalendarIdGet = (
    calendarId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCalendar>(
      {url: `/v1/calendar/${calendarId}`, method: 'get'
    },
      options);
    }
  
/**
 * - shown: 캘린더 표시 여부는 True, False로 설정 가능
    - 캘린더 권한이 owner인 경우에만 기본 캘린더 설정 가능
    - default: 캘린더 기본 여부는 True로 설정 가능 but, 기본 캘린더는 1개만 존재해야하므로
        - 기본 캘린더가 아닌 다른 캘린더를 기본 캘린더로 설정하면, 기존 기본 캘린더는 False로 변경
 * @summary 캘린더 수정하기
 */
export const updateCalendarV1CalendarCalendarIdPatch = (
    calendarId: string,
    inUpdateCalendar: InUpdateCalendar,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCalendar>(
      {url: `/v1/calendar/${calendarId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateCalendar
    },
      options);
    }
  
/**
 * - shown: 캘린더 표시 여부는 True, False로 설정 가능
 * @summary 캘린더 수정하기 벌크
 */
export const updateCalendarsV1CalendarBulkPatch = (
    inUpdateCalendarShown: InUpdateCalendarShown,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCalendar[]>(
      {url: `/v1/calendar/bulk`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateCalendarShown
    },
      options);
    }
  
/**
 * @summary 업무 조회
 */
export const readTaskV1TasksTaskIdGet = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTasksSchemasOutTask>(
      {url: `/v1/tasks/${taskId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 업무 삭제
 */
export const removeTaskV1TasksTaskIdDelete = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<KernelBaseSchemaOutRemovedEntity>(
      {url: `/v1/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 하위 업무 수정
    *** 삭제될 예정 ****
    /taskboxes/{taskbox_id}/tasks/{task_id} 를 이용해 주세요.
    
 */
export const updateSubtaskV1TasksTaskIdPatch = (
    taskId: string,
    updateTask: UpdateTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTasksSchemasOutTask>(
      {url: `/v1/tasks/${taskId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateTask
    },
      options);
    }
  
/**
 * @summary 내 업무 생성
 */
export const createTaskV1TasksPost = (
    createTask: CreateTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTask
    },
      options);
    }
  
/**
 * @summary 메모 조회
 */
export const getMemoV1MemosGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApiMemosSchemasOutMemo>(
      {url: `/v1/memos`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 메모 수정
 */
export const updateMemoV1MemosPut = (
    updateMemo: UpdateMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApiMemosSchemasOutMemo>(
      {url: `/v1/memos`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateMemo
    },
      options);
    }
  
/**
 * @summary Slack workspace 채널 및 사용자 조회
 */
export const listReceiversV1SlackReceiversGet = (
    params?: ListReceiversV1SlackReceiversGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationSlackReceiver>(
      {url: `/v1/slack/receivers`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary Slack 연동 정보 생성
 */
export const createStateV1SlackStatePost = (
    inSlackState: InSlackState,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutSlackState>(
      {url: `/v1/slack/state`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inSlackState
    },
      options);
    }
  
/**
 * @summary Slack 연동
 */
export const integrateV1SlackAuthGet = (
    params: IntegrateV1SlackAuthGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/slack/auth`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary Revoke
 */
export const revokeV1SlackRevokeDelete = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/slack/revoke`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 조회
 */
export const readDailyMemoV1DailyMemosGet = (
    params: ReadDailyMemoV1DailyMemosGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 생성
 */
export const createDailyMemoV1DailyMemosPost = (
    createDailyMemo: CreateDailyMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createDailyMemo
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 수정
 */
export const updateDailyMemoV1DailyMemosMemoIdPut = (
    memoId: string,
    updateDailyMemo: UpdateDailyMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos/${memoId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateDailyMemo
    },
      options);
    }
  
/**
 * [start_date, end_date) 범위의 태스크박스를 조회합니다. <br />start_date, end_date를 null로 설정하거나 입력하지 않은 경우 나중에 태스크박스를 조회합니다.start_date == end_date 일 경우, start_date 가 겹치는 태스크박스를 조회합니다.
 * @summary 태스크박스 조회
 */
export const readTaskboxesV1TaskboxesGet = (
    params?: ReadTaskboxesV1TaskboxesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse[]>(
      {url: `/v1/taskboxes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 태스크박스를 생성합니다. <br />- 시간이 지정되지 않은 워크스테이션 내 태스크박스인 경우 start, end를 비워두거나 null을 설정합니다. <br />- 종일인 경우 [start.date, end.date]를 설정합니다. <br />- 시간이 지정된 경우 [start.datetime, end.datetime]를 설정합니다.
 * @summary 태스크박스 생성
 */
export const createTaskboxV1TaskboxesPost = (
    createTaskbox: CreateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/taskboxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskbox
    },
      options);
    }
  
/**
 * @summary 여러 개의 태스크박스 제거
 */
export const removeTaskboxesV1TaskboxesDelete = (
    params: RemoveTaskboxesV1TaskboxesDeleteParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes`, method: 'delete',
        params
    },
      options);
    }
  
/**
 * 하위 업무를 종일 업무로 변경합니다.
- start, end가 있을 경우 -> 종일
- start, end가 없을 경우 -> 나중에
- 프로젝트 관련 태스크의 하위 업무일 경우, 프로젝트 인스턴스를 만들고 그 하위 업무에 내용이 생깁니다.
(프로젝트 인스턴스는 제목이 없기 때문에)
 * @summary 서브태스크들 -> 태스크박스 변경
 */
export const subtaskToTaskV1TaskboxesSubtasksTaskTypePatch = (
    updateSubtaskBulk: UpdateSubtaskBulk,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail[]>(
      {url: `/v1/taskboxes/subtasks/task-type`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateSubtaskBulk
    },
      options);
    }
  
/**
 * # 지원 기능
    - 태스크박스 -> 다른 날짜 보내기
    - 태스크박스 -> 나중에 보내기
 * @summary 태스크박스 벌크 수정
 */
export const updateBulkTaskboxesV1TaskboxesBulkPut = (
    updateBulkTaskbox: UpdateBulkTaskbox[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/bulk`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateBulkTaskbox
    },
      options);
    }
  
/**
 * @summary 태스크박스 반복일정 생성
 */
export const createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost = (
    taskboxId: string,
    inCreateTaskBoxInRecurrence: InCreateTaskBoxInRecurrence,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/${taskboxId}/recurrences`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateTaskBoxInRecurrence
    },
      options);
    }
  
/**
 * - 반복 주기, 반복 시간 변경
## action type 별 변경 가능 내역
| action type     | reccurence rule | time |
| --------------- | --------------- | ---- |
| THIS            | x               | O    |
| THIS AND Follow | O               | O    |
| ALL             | O               | O    |

- body에 is_cancelled: true로 설정하면 반복업무 취소됩니다.
    - 주의사항
    - ActionTarget 무시됩니다.
 * @summary 태스크박스 반복일정 수정
 */
export const updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch = (
    taskboxId: string,
    updateTaskBoxRecurrence: UpdateTaskBoxRecurrence,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/${taskboxId}/recurrences`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskBoxRecurrence
    },
      options);
    }
  
/**
 * 단일 태스크박스의 상세 정보를 조회합니다.
 * @summary (작업중) 태스크박스 상세 조회
 */
export const readTaskboxV1TaskboxesTaskboxIdGet = (
    taskboxId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse>(
      {url: `/v1/taskboxes/${taskboxId}`, method: 'get'
    },
      options);
    }
  
/**
 * 업무를 업데이트 합니다.
    *** 부분적으로 태스크 수정하는 동작에서 한번에 보내는 prop 갯수가 3개 이상인 경우 발생시 *** 
    백엔드에 필히 공유해야합니다.
    프론트에서 변경사항 있을 경우 공유 받아야함
 * @summary 태스크박스 수정
 */
export const updateTaskboxV1TaskboxesTaskboxIdPut = (
    taskboxId: string,
    updateTaskbox: UpdateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse>(
      {url: `/v1/taskboxes/${taskboxId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskbox
    },
      options);
    }
  
/**
 * @summary 태스크박스 제거
 */
export const removeTaskboxV1TaskboxesTaskboxIdDelete = (
    taskboxId: string,
    params?: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/taskboxes/${taskboxId}`, method: 'delete',
        params
    },
      options);
    }
  
/**
 * 태스크 및 태스크그룹을 태스크박스의 하위 업무로 등록합니다.
 * @summary 태스크 할당
 */
export const assignTasksV1TaskboxesTaskboxIdAssignPost = (
    taskboxId: string,
    assignTask: AssignTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/taskboxes/${taskboxId}/assign`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: assignTask
    },
      options);
    }
  
/**
 * - 프로젝트 인스턴스 태스크 박스에
    - 동일 프로젝트의 미할당 하위업무 or 할당된 하위업무 (인스턴스에 배정된 태스크)를 할당합니다.
    - 할당된 하위업무일 경우, 기존 인스턴스와의 관계가 끊어집니다.
 * @summary 태스크 할당
 */
export const absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost = (
    taskboxId: string,
    absorbingTask: AbsorbingTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/taskboxes/${taskboxId}/subtask/from-project`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: absorbingTask
    },
      options);
    }
  
/**
 * 반복업무의 하위일정을 동기화합니다.
 * @summary 반복 업무 하위 할일 동기화
 */
export const syncRecurringTaskboxesV1TaskboxesTaskboxIdSyncPut = (
    taskboxId: string,
    inRecurrenceAction: InRecurrenceAction,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/${taskboxId}/sync`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: inRecurrenceAction
    },
      options);
    }
  
/**
 * 태스크박스 내에서만 존재하는 업무로, 내 업무 목록에는 노출되지 않습니다. <br>
 * @summary 태스크박스 내 업무 생성
 */
export const createTaskboxTaskV1TaskboxesTaskboxIdTasksPost = (
    taskboxId: string,
    createTaskboxTask: CreateTaskboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponseForSubtask>(
      {url: `/v1/taskboxes/${taskboxId}/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskboxTask
    },
      options);
    }
  
/**
 * @summary 태스크박스 내 태스크 변경
 */
export const updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut = (
    taskboxId: string,
    taskId: string,
    updateTaskboxTask: UpdateTaskboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponseForSubtask>(
      {url: `/v1/taskboxes/${taskboxId}/tasks/${taskId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskboxTask
    },
      options);
    }
  
/**
 * @summary 태스크박스 내 업무 제거
 */
export const detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete = (
    taskboxId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/${taskboxId}/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary Reorder Taskbox Time Split
 */
export const reorderTaskboxTimeSplitV1TaskboxesOrderingTimeSplitStylePut = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/taskboxes/ordering/time-split-style`, method: 'put'
    },
      options);
    }
  
/**
 * TODO: 이전에 inbox를 가져오기 위해 사용되던 API로 보임
inbox는 현재 존재하지 않으며, 나중에(someday)에 대한 테스크만 존재하며, 이는 GET: v2/workboxes로 대체됨.
 * @deprecated
 * @summary 태스크, 이슈 목록 조회
 */
export const listWorksV1WorksGet = (
    params: ListWorksV1WorksGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorks>(
      {url: `/v1/works`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 태스크, 이슈 생성
 */
export const createTaskV1WorksPost = (
    createWork: CreateWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWork
    },
      options);
    }
  
/**
 * @summary 태스크, 이슈 수정
 */
export const updateTaskV1WorksWorkIdPut = (
    workId: string,
    updateWork: UpdateWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works/${workId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateWork
    },
      options);
    }
  
/**
 * 태스크, 이슈, 그리고 이슈 내 태스크 제거에 사용됩니다.
 * @summary 태스크, 이슈 삭제
 */
export const removeTaskV1WorksWorkIdDelete = (
    workId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/works/${workId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 이슈 내 업무 할당
 */
export const assignTaskV1WorksIssuesIssueIdTasksPut = (
    issueId: string,
    subTask: SubTask[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works/issues/${issueId}/tasks`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: subTask
    },
      options);
    }
  
/**
 * @summary 이슈 상세내역 조회
 */
export const readIssueV1WorksIssuesIssueIdGet = (
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutIssue>(
      {url: `/v1/works/issues/${issueId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 이슈 상세내역 수정
 */
export const updateIssueV1WorksIssuesIssueIdPut = (
    issueId: string,
    updateIssueDetail: UpdateIssueDetail,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/works/issues/${issueId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateIssueDetail
    },
      options);
    }
  
/**
 * 태스크를 태스크박스로 이동합니다. 태스크박스에 서브태스크는 request body의 id 순서대로 생성됩니다.
 * @deprecated
 * @summary 태스크를 태스크박스로 이동
 */
export const moveWorkToTaskboxV1WorksMoveTaskboxIdPost = (
    taskboxId: string,
    moveWorkToTaskbox: MoveWorkToTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/works/move/${taskboxId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: moveWorkToTaskbox
    },
      options);
    }
  
/**
 * @summary 유저의 모든 플랜 섹션 조회
 */
export const listPlanSectionsV1PlansSectionsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection[]>(
      {url: `/v1/plans/sections`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 생성
 */
export const createPlanSectionV1PlansSectionsPost = (
    createPlanSection: CreatePlanSection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection>(
      {url: `/v1/plans/sections`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPlanSection
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 변경
 */
export const updatePlanSectionV1PlansSectionsPlanSectionIdPut = (
    planSectionId: string,
    updatePlanSection: UpdatePlanSection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection>(
      {url: `/v1/plans/sections/${planSectionId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePlanSection
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 삭제
 */
export const deletePlanSectionV1PlansSectionsPlanSectionIdDelete = (
    planSectionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<KernelBaseSchemaOutRemovedEntity>(
      {url: `/v1/plans/sections/${planSectionId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 유저의 주간 플랜 조회
 */
export const listPlanWeeklyV1PlansWeeklyGet = (
    params: ListPlanWeeklyV1PlansWeeklyGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly[]>(
      {url: `/v1/plans/weekly`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 주간 플랜 생성
 */
export const createPlanWeeklyV1PlansWeeklyPost = (
    createPlanWeekly: CreatePlanWeekly,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly>(
      {url: `/v1/plans/weekly`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPlanWeekly
    },
      options);
    }
  
/**
 * @summary 주간 플랜 변경
 */
export const updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut = (
    planWeeklyId: string,
    updatePlanWeekly: UpdatePlanWeekly,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly>(
      {url: `/v1/plans/weekly/${planWeeklyId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePlanWeekly
    },
      options);
    }
  
/**
 * @summary 주간 플랜 삭제
 */
export const deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete = (
    planWeeklyId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<KernelBaseSchemaOutRemovedEntity>(
      {url: `/v1/plans/weekly/${planWeeklyId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary workbox에서 work를 분리합니다.
 */
export const detachWorksInWorkboxV1WorkboxesWorkboxIdDetachPut = (
    workboxId: string,
    detachWorkInWorkbox: DetachWorkInWorkbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/workboxes/${workboxId}/detach`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: detachWorkInWorkbox
    },
      options);
    }
  
/**
 * @summary workbox내 work 생성
 */
export const createWorkInWorkboxV1WorkboxesWorkboxIdWorksPost = (
    workboxId: string,
    createTaskboxTask: CreateTaskboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponseForSubtask>(
      {url: `/v1/workboxes/${workboxId}/works`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskboxTask
    },
      options);
    }
  
/**
 * @summary workbox를 분리합니다.
 */
export const splitWorkboxV1WorkboxesWorkboxIdSplitPost = (
    workboxId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/workboxes/${workboxId}/split`, method: 'post'
    },
      options);
    }
  
/**
 * @summary workbox 제거
 */
export const removeWorkboxV1WorkboxesWorkboxIdDelete = (
    workboxId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/workboxes/${workboxId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary workbox 내 work 수정
 */
export const updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut = (
    workboxId: string,
    workId: string,
    updateTaskboxTask: UpdateTaskboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponseForSubtask>(
      {url: `/v1/workboxes/${workboxId}/works/${workId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskboxTask
    },
      options);
    }
  
/**
 * @summary workbox 내 work 제거
 */
export const removeWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDelete = (
    workboxId: string,
    workId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/workboxes/${workboxId}/works/${workId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 자주 하는 업무를 조회합니다.
 * @summary 자주 하는 업무 조회
 */
export const readFrequentWorksV1FrequentworksGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFrequentWork[]>(
      {url: `/v1/frequentworks`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 자주 하는 업무 생성
 */
export const createFrequentWorkV1FrequentworksPost = (
    createFrequentWork: CreateFrequentWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateFrequentWork>(
      {url: `/v1/frequentworks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createFrequentWork
    },
      options);
    }
  
/**
 * 자주 하는 업무를 수정합니다.
 * @summary 자주 하는 업무 수정
 */
export const updateFrequentWorkV1FrequentworksFrequentWorkIdPut = (
    frequentWorkId: string,
    updateFrequentWork: UpdateFrequentWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFrequentWork>(
      {url: `/v1/frequentworks/${frequentWorkId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateFrequentWork
    },
      options);
    }
  
/**
 * 자주 하는 업무를 삭제합니다.
 * @summary 자주 하는 업무 삭제
 */
export const deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete = (
    frequentWorkId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/frequentworks/${frequentWorkId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 자주 하는 업무를 태스크박스로 생성합니다.
 * @deprecated
 * @summary 자주 하는 업무를 태스크박스로 생성
 */
export const createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost = (
    frequentWorkId: string,
    createTaskbox: CreateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/frequentworks/${frequentWorkId}/taskbox`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskbox
    },
      options);
    }
  
/**
 * TODO: not used in frontend
 * @deprecated
 * @summary 자주 하는 업무 내 하위 업무 생성
 */
export const createTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPost = (
    freuqentWorkId: string,
    createFrequentWorkTask: CreateFrequentWorkTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateFrequentWorkTask>(
      {url: `/v1/frequentworks/${freuqentWorkId}/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createFrequentWorkTask
    },
      options);
    }
  
/**
 * TODO: not used in frontend
 * @deprecated
 * @summary 자주 하는 업무 내 하위 업무 제거
 */
export const deleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDelete = (
    frequentWorkId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppTasksModelOutRemovedEntity>(
      {url: `/v1/frequentworks/${frequentWorkId}/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * title을 null로 설정하거나 입력하지 않으면 미완료 태스크박스 업무를 생성시간 역순으로 조회합니다.
    default 50, 최대 100개까지 업무조회로 제한.
    추후 pagination 추가 예정.
 * @summary 미완료 업무 단어로 조회, ilike 조회
 */
export const readAutocompleteListV1AutocompleteGet = (
    params?: ReadAutocompleteListV1AutocompleteGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAutoComplete[]>(
      {url: `/v1/autocomplete`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 유저의 GNB 투표 정보 조회
 */
export const getListVotesV1VotesGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutVote[]>(
      {url: `/v1/votes`, method: 'get'
    },
      options);
    }
  
/**
 * @summary GNB 투표 생성
 */
export const createVoteV1VotesPost = (
    createVote: CreateVote,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutVote>(
      {url: `/v1/votes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createVote
    },
      options);
    }
  
/**
 * - 사인업 설문조사 조회
  - 사인업 설문조사 대상 조회 -> 200
  - 사인업 설문조사 세팅이 안되어 있을경우 -> 400
 * @summary 사인업 설문조사 대상 여부 조회
 */
export const getSignupSurveyV1SurveysSignupGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<WelcomeSurveyOut>(
      {url: `/v1/surveys/signup`, method: 'get'
    },
      options);
    }
  
/**
 * 유저 관련 설문조사 리스트 조회
    - 1개 이상이 있을 수 있음
    - priority 높은 순으로 조회
    - IN_PROGRESS 상태인 설문조사만 조회 
    - 유저가 응답한 설문조사는 조회 x
    - 유저가 응답하지 않은 설문조사 중에 suspension_count가 최대치 이하인 설문조사는 조회 x
 * @summary 유저에게 할당된 설문조사 있는지 조회, priority 높은 순 정렬
 */
export const getUserSurveyListV1SurveysGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SurveyResponseOut[]>(
      {url: `/v1/surveys`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 설문에 대한 응답
 */
export const recordUserSurveyV1SurveysSurveyIdPost = (
    surveyId: string,
    recordUserSurveyV1SurveysSurveyIdPostBody: RecordUserSurveyV1SurveysSurveyIdPostBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/surveys/${surveyId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recordUserSurveyV1SurveysSurveyIdPostBody
    },
      options);
    }
  
/**
 * 설문 응답을 연기합니다. 연기한 설문은 나중에 다시 응답할 수 있습니다.
    (연기 횟수는 설정을 따릅니다.)
 * @summary 설문 응답 연기
 */
export const suspenseSurveyV1SurveysSurveyIdPatch = (
    surveyId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/surveys/${surveyId}`, method: 'patch'
    },
      options);
    }
  
/**
 * {
        "content": [
            {"request": "제안"},
            {"satisfaction": "만족기능"},
            {"email": "연락받을 이메일"},
            {"image": 
                [
                    {"url": "이미지 URL", "alt": "이미지 설명"}
                ]
            }
        ]
    }
 * @summary 유저 제안하기
 */
export const recordUserRequestSurveyV1SurveysRequestPost = (
    recordUserRequestSurveyV1SurveysRequestPostBody: RecordUserRequestSurveyV1SurveysRequestPostBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/surveys/request`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recordUserRequestSurveyV1SurveysRequestPostBody
    },
      options);
    }
  
/**
 * ...
 * @summary 유저 전체 카테고리 조회
 */
export const getAllCategoryV1CategoryGet = (
    params?: GetAllCategoryV1CategoryGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCategory[]>(
      {url: `/v1/category`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 카테고리를 생성합니다.
    - 카테고리 생성은 갯수 제한이 존재합니다.
 * @summary 카테고리 생성
 */
export const createCategoryV1CategoryPost = (
    inCreateCategory: InCreateCategory,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCategory>(
      {url: `/v1/category`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateCategory
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 카테고리 조회
 */
export const getACategoryV1CategoryCategoryIdGet = (
    categoryId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCategory>(
      {url: `/v1/category/${categoryId}`, method: 'get'
    },
      options);
    }
  
/**
 * 카테고리 삭제는 영향력이 큰 거라 삭제후 복구가 안됩니다.
!!! Hard Delete !!!
복구 불가 확인이 필요합니다.
 * @summary 카테고리 삭제 (되돌리기 불가능)
 */
export const deleteCategoryV1CategoryCategoryIdDelete = (
    categoryId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/category/${categoryId}`, method: 'delete'
    },
      options);
    }
  
/**
 * ...
 * @summary 카테고리 수정하기
 */
export const updateCategoryV1CategoryCategoryIdPatch = (
    categoryId: string,
    inUpdateCategory: InUpdateCategory,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCategory>(
      {url: `/v1/category/${categoryId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateCategory
    },
      options);
    }
  
/**
 * ...
 * @summary 유저 전체 메모 조회
 */
export const getAllMemoV1MemoGet = (
    params?: GetAllMemoV1MemoGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppMemoModelOutMemo[]>(
      {url: `/v1/memo`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 메모를 생성합니다.
 * @summary 메모 생성
 */
export const createMemoV1MemoPost = (
    inCreateMemo: InCreateMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppMemoModelOutMemo>(
      {url: `/v1/memo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateMemo
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 메모 조회
 */
export const getAMemoV1MemoMemoIdGet = (
    memoId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppMemoModelOutMemo>(
      {url: `/v1/memo/${memoId}`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary  삭제, soft delete
 */
export const deleteMemoV1MemoMemoIdDelete = (
    memoId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/memo/${memoId}`, method: 'delete'
    },
      options);
    }
  
/**
 * ...
 * @summary 메모 수정하기
 */
export const updateMemoV1MemoMemoIdPatch = (
    memoId: string,
    inUpdateMemo: InUpdateMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppMemoModelOutMemo>(
      {url: `/v1/memo/${memoId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateMemo
    },
      options);
    }
  
/**
 * ...
 * @summary 유저 전체 링크 조회
 */
export const getAllLinkV1LinkGet = (
    params?: GetAllLinkV1LinkGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutLink[]>(
      {url: `/v1/link`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 링크를 생성합니다.
 * @summary 링크 생성
 */
export const createLinkV1LinkPost = (
    inCreateLink: InCreateLink,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutLink>(
      {url: `/v1/link`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateLink
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 링크 조회
 */
export const getALinkV1LinkLinkIdGet = (
    linkId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutLink>(
      {url: `/v1/link/${linkId}`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary  삭제, soft delete
 */
export const deleteLinkV1LinkLinkIdDelete = (
    linkId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/link/${linkId}`, method: 'delete'
    },
      options);
    }
  
/**
 * ...
 * @summary 링크 수정하기
 */
export const updateLinkV1LinkLinkIdPatch = (
    linkId: string,
    inUpdateLink: InUpdateLink,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutLink>(
      {url: `/v1/link/${linkId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateLink
    },
      options);
    }
  
/**
 * @summary 유저 전체 프로젝트 조회
 */
export const getAllProjectV1ProjectGet = (
    params?: GetAllProjectV1ProjectGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProject[]>(
      {url: `/v1/project`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 프로젝트를 생성합니다.
 * @summary 프로젝트 생성
 */
export const createProjectV1ProjectPost = (
    inCreateProject: InCreateProject,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProject>(
      {url: `/v1/project`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateProject
    },
      options);
    }
  
/**
 * @summary 단일 프로젝트 조회
 */
export const getAProjectV1ProjectProjectIdGet = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProject>(
      {url: `/v1/project/${projectId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 프로젝트 삭제, soft delete, status -> DELETED
 */
export const deleteProjectV1ProjectProjectIdDelete = (
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/project/${projectId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 프로젝트 수정하기
 */
export const updateProjectV1ProjectProjectIdPatch = (
    projectId: string,
    inUpdateProject: InUpdateProject,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProject>(
      {url: `/v1/project/${projectId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateProject
    },
      options);
    }
  
/**
 * - 프로젝트 순서를 변경합니다.
 * @summary 프로젝트 순서 변경
 */
export const orderProjectV1ProjectProjectIdOrderPatch = (
    projectId: string,
    inUpdateProjectOrder: InUpdateProjectOrder,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProject>(
      {url: `/v1/project/${projectId}/order`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateProjectOrder
    },
      options);
    }
  
/**
 * - 태스크를 프로젝트에 연결합니다.
    - 태스크 링크, 메모는 프로젝트로 이전 됩니다.
    - 카테고리, 기한은 프로젝트에 맞게 변경됩니다.
    - 제약 조건
        - DAY_TASK, TIME_TASK만 가능
        - 서브태스크 불가능
 * @summary 프로젝트에 기존 태스크 연결하기
 */
export const linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost = (
    projectId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/project/${projectId}/linking-task/${taskId}`, method: 'post'
    },
      options);
    }
  
/**
 * - 작업 대상
        - 시간업무, 종일업무, 나중에
    - 태스크를 바탕으로 프로젝트를 생성하고,
    - 시간 업무, 종일업무에 프로젝트가 추가됩니다.
    - 나중에는 프로젝트 생성후,
        - 하위 업무가 없을 경우 삭제
        - 하위 업무가 있을 경우, 하위업무들을 프로젝트 하위업무로 등록합니다.
 * @summary 태스크에서 프로젝트를 생성합니다.
 */
export const convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/project/converting-from-task/${taskId}`, method: 'post'
    },
      options);
    }
  
/**
 * - 태스크를 프로젝트에 하위업무로 등록합니다.
    - 태스크 링크, 메모는 프로젝트로 이전 됩니다.
    - 제약 조건
        - DAY_TASK, TIME_TASK, SOMEDAY_TASK, SUBTASK 가능
 * @summary 프로젝트에 태스크 박스, 태스크, 나중에 하위업무로 등록
 */
export const assignTasksToProjectV1ProjectProjectIdAssigningTaskPost = (
    projectId: string,
    inUpdateTasks: InUpdateTasks,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/project/${projectId}/assigning-task`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateTasks
    },
      options);
    }
  
/**
 * - start_date, end_date가 없을 경우 30일 전부터 오늘까지 조회합니다.
 * @summary 유저 전체 레코드 조회
 */
export const getAllRecordV1RecordGet = (
    params?: GetAllRecordV1RecordGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord[]>(
      {url: `/v1/record`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 레코드를 생성합니다.
    - 한 날짜에는 하나의 레코드만 생성이 가능합니다.
    - 기존 기록 있을시 에러 리스폰스
 * @summary 레코드 생성
 */
export const createRecordV1RecordPost = (
    inCreateRecord: InCreateRecord,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord>(
      {url: `/v1/record`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateRecord
    },
      options);
    }
  
/**
 * @summary 랜덤 날짜 레코드를 조회
 */
export const getARandomRecordV1RecordRandomGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord>(
      {url: `/v1/record/random`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 레코드 조회
 */
export const getARecordV1RecordRecordIdGet = (
    recordId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord>(
      {url: `/v1/record/${recordId}`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary  삭제, soft delete
 */
export const deleteRecordV1RecordRecordIdDelete = (
    recordId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/record/${recordId}`, method: 'delete'
    },
      options);
    }
  
/**
 * ...
 * @summary 레코드 수정하기
 */
export const updateRecordV1RecordRecordIdPatch = (
    recordId: string,
    inUpdateRecord: InUpdateRecord,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord>(
      {url: `/v1/record/${recordId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateRecord
    },
      options);
    }
  
/**
 * @summary 단일 날짜 레코드를 조회
 */
export const getADateRecordV1RecordDateDateGet = (
    date: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRecord>(
      {url: `/v1/record/date/${date}`, method: 'get'
    },
      options);
    }
  
/**
 * 워크스페이스 내에서 내 정보를 조회합니다.
 * @summary 내 정보
 */
export const getUserMeV1UsersMeGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/me`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 워크스페이스 탈퇴
 */
export const removeV1UsersMeDelete = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/users/me`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 내 정보 수정
 */
export const updateMyProfileV1UsersMePatch = (
    updateUserMe: UpdateUserMe,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/me`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUserMe
    },
      options);
    }
  
/**
 * 워크스페이스 내의 팀원 정보룰 불러옵니다.
 * @summary 팀원 목록을 조회합니다.
 */
export const getMembersV1UsersMembersGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppUserModelOutUser[]>(
      {url: `/v1/users/members`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 팀원 권한을 변경합니다.
 */
export const grantPermissionV1UsersMembersPermissionPost = (
    updateUserPermission: UpdateUserPermission,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppUserModelOutUser>(
      {url: `/v1/users/members/permission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateUserPermission
    },
      options);
    }
  
/**
 * 이메일로 사용자를 초대합니다.
 * @summary 사용자 초대
 */
export const inviteMembersV1UsersInvitePost = (
    inviteMembersV1UsersInvitePostBody: string[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/users/invite`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inviteMembersV1UsersInvitePostBody
    },
      options);
    }
  
/**
 * @summary 모든 유저 정보 삭제
 */
export const removeUserDataV1UsersDeleteAccountPost = (
    inDeleteAccount: InDeleteAccount,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/users/delete-account`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inDeleteAccount
    },
      options);
    }
  
/**
 * @summary Retrieve User Setting
 */
export const retrieveUserSettingV1UsersSettingGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserSettingModel>(
      {url: `/v1/users/setting`, method: 'get'
    },
      options);
    }
  
/**
 * @summary Partial Update User Setting
 */
export const partialUpdateUserSettingV1UsersSettingPatch = (
    userSettingModel: UserSettingModel,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserSettingModel>(
      {url: `/v1/users/setting`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: userSettingModel
    },
      options);
    }
  
/**
 * 사용자 이름, 계정 활성화 상태를 변경합니다.
 * @summary 사용자 정보 수정
 */
export const updateUserProfileV1UsersUserIdPatch = (
    userId: string,
    updateUser: UpdateUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/${userId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUser
    },
      options);
    }
  
/**
 * 워크스페이스 내 소속 팀 목록을 조회합니다.
 * @summary 소속 팀 목록 조회
 */
export const readDepartmentsV1UsersDepartmentsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string[]>(
      {url: `/v1/users/departments`, method: 'get'
    },
      options);
    }
  
/**
 * 사용자의 히스토리를 조회합니다.
 * @summary 사용자 히스토리 조회
 */
export const readUserHistoryV1UsersHistoryGet = (
    params: ReadUserHistoryV1UsersHistoryGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserHistory[]>(
      {url: `/v1/users/history`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 저장된 값을 조회합니다. 저장된 값이 없을 경우 default 값을 만들어서 리턴합니다.
 * @summary user State 조회
 */
export const getAllStateStorageV1StateStorageGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutStateStorage>(
      {url: `/v1/state-storage`, method: 'get'
    },
      options);
    }
  
/**
 * 저장된 값을 수정합니다. 없으면 default 값으로 생성해서 업데이트 합니다.
 * @summary user State 수정하기
 */
export const updateStateStorageV1StateStoragePatch = (
    inUpdateStateStorage: InUpdateStateStorage,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutStateStorage>(
      {url: `/v1/state-storage`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateStateStorage
    },
      options);
    }
  
/**
 * ...
 * @summary 유저 전체 피쳐데이터 조회
 */
export const getAllFeatureStorageV1FeatureStorageGet = (
    params?: GetAllFeatureStorageV1FeatureStorageGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFeatureStorage[]>(
      {url: `/v1/feature-storage`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 피쳐데이터를 생성합니다.
    - 피쳐 네임 존재할 경우, 해당 피쳐데이터를 업데이트합니다.
 * @summary 피쳐데이터 생성
 */
export const createFeatureStorageV1FeatureStoragePost = (
    inCreateFeatureStorage: InCreateFeatureStorage,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFeatureStorage>(
      {url: `/v1/feature-storage`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateFeatureStorage
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 피쳐데이터 이름으로 조회
 */
export const getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet = (
    featureStorageName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFeatureStorage>(
      {url: `/v1/feature-storage/name/${featureStorageName}`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 피쳐데이터 조회
 */
export const getAFeatureStorageV1FeatureStorageFeatureStorageIdGet = (
    featureStorageId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFeatureStorage>(
      {url: `/v1/feature-storage/${featureStorageId}`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary  삭제, soft delete
 */
export const deleteFeatureStorageV1FeatureStorageFeatureStorageIdDelete = (
    featureStorageId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/feature-storage/${featureStorageId}`, method: 'delete'
    },
      options);
    }
  
/**
 * ...
 * @summary 피쳐데이터 수정하기
 */
export const updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch = (
    featureStorageId: string,
    inUpdateFeatureStorage: InUpdateFeatureStorage,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFeatureStorage>(
      {url: `/v1/feature-storage/${featureStorageId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateFeatureStorage
    },
      options);
    }
  
/**
 * 유저당 1개의 로우만 생기기 때문에, 단일 조회만 지원함.
 * @summary 단일 메모 조회
 */
export const getANoteV1NoteGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutNote>(
      {url: `/v1/note`, method: 'get'
    },
      options);
    }
  
/**
 * ...
 * @summary 메모 수정하기
 */
export const updateNoteV1NotePatch = (
    inUpdateNote: InUpdateNote,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutNote>(
      {url: `/v1/note`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateNote
    },
      options);
    }
  
/**
 * - 주간 카테고리 통계를 조회 합니다.
    - DESC this_week_min 으로 데이터 정렬
    - category_id가 Null 일경우 category id가 없습니다.
    - 지난주 비교 데이터가 없을 경우, prev_week_min = null 입니다.
 * @summary weekly category stat
 */
export const getWeeklyCategoryStatV1StatCategoryWeekGet = (
    params: GetWeeklyCategoryStatV1StatCategoryWeekGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCategoryStatList>(
      {url: `/v1/stat/category/week`, method: 'get',
        params
    },
      options);
    }
  
/**
 * - 주간 프로젝트 통계를 조회 합니다.
    - DESC this_week_min 으로 데이터 정렬
 * @summary weekly category stat
 */
export const getWeeklyProjectStatV1StatProjectWeekGet = (
    params: GetWeeklyProjectStatV1StatProjectWeekGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutProjectStatList>(
      {url: `/v1/stat/project/week`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 유저 전체 routine 조회
 */
export const listRoutineV1RoutineGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRoutine[]>(
      {url: `/v1/routine`, method: 'get'
    },
      options);
    }
  
/**
 * - routine 생성합니다.
 * @summary routine 생성
 */
export const createRoutineV1RoutinePost = (
    inCreateRoutine: InCreateRoutine,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRoutine>(
      {url: `/v1/routine`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateRoutine
    },
      options);
    }
  
/**
 * ...
 * @summary 단일 routine 조회
 */
export const getARoutineV1RoutineRoutineIdGet = (
    routineId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRoutine>(
      {url: `/v1/routine/${routineId}`, method: 'get'
    },
      options);
    }
  
/**
 * - routine 삭제
    - routine 삭제시, 관련된 모든 태스크들 일괄 삭제합니다.
 * @summary  삭제
 */
export const deleteRoutineV1RoutineRoutineIdDelete = (
    routineId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/v1/routine/${routineId}`, method: 'delete'
    },
      options);
    }
  
/**
 * - 변경 가능
        - title, description, category, link, memo
    - 변경 불가능: 아래 항목은 변경시 사이드이펙트가 크기 때문에 각각 개별작업으로 분리합니다.
        - order, rrule, status, tasks
 * @summary routine 기본정보 수정하기
 */
export const updateRoutineV1RoutineRoutineIdPatch = (
    routineId: string,
    inUpdateBaseRoutine: InUpdateBaseRoutine,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutRoutine>(
      {url: `/v1/routine/${routineId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateBaseRoutine
    },
      options);
    }
  
/**
 * - routine_id에 해당하는 반복 업무를 조회합니다.
    - start_date를 기준으로 before_count, after_count 만큼 조회합니다.
    - default start_date는 UTC+00 기준 Today 입니다. (Not KST!)
 * @summary 단일 routine 반복 인스턴스 조회
 */
export const listRoutineInstanceByDateAndCountV1RoutineRoutineIdInstanceGet = (
    routineId: string,
    params: ListRoutineInstanceByDateAndCountV1RoutineRoutineIdInstanceGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailRoutineOnly[]>(
      {url: `/v1/routine/${routineId}/instance`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary routine간 순서 수정하기
 */
export const updateRoutineOrderV1RoutineRoutineIdOrderPatch = (
    routineId: string,
    inUpdateRoutineOrder: InUpdateRoutineOrder,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/${routineId}/order`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateRoutineOrder
    },
      options);
    }
  
/**
 * @summary routine 규칙 수정하기
 */
export const updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch = (
    routineId: string,
    inUpdateRoutineRule: InUpdateRoutineRule,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/${routineId}/recurrence`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateRoutineRule
    },
      options);
    }
  
/**
 * - routine task들을 수정합니다.
    - 들어온 순서대로 정렬 됩니다. (루틴 인스턴스의 하위 업무들도 순서가 변경 될 수 있으나, 기존 순서와 겹칠 경우 달라질 수 있습니다.)
    - 제목이 변경된 경우, 해당 task와 연결된 반복 업무 하위 task들의 제목도 변경됩니다.(기존 제목에서 편집 안된 경우 에만)
    - task가 삭제된 경우, 해당 task와 연결된 반복 업무 하위 task들도 삭제됩니다.(기존 제목에서 편집안된 경우 에만)
 * @summary routine task 벌크 수정하기
 */
export const updateRoutineTaskV1RoutineRoutineIdTaskPost = (
    routineId: string,
    inUpdateRoutineTaskCollection: InUpdateRoutineTaskCollection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/${routineId}/task`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateRoutineTaskCollection
    },
      options);
    }
  
/**
 * 일반 업무를 루틴으로 변경합니다.
    - 가능한 업무
        - 종일 업무
        - 시간 업무
    - 그 외의 종류는 미지원
 * @summary 일반 업무 -> 루틴으로 변환
 */
export const createRoutineFromTaskV1RoutineFromTaskTaskIdPost = (
    taskId: string,
    inUpdateRoutineRuleOptionalTime: InUpdateRoutineRuleOptionalTime,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/from-task/${taskId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inUpdateRoutineRuleOptionalTime
    },
      options);
    }
  
/**
 * - 상실 정보: 메모, 링크
    - 유지 정보: 제목, 카테고리, 시간정보
 * @summary 루틴 인스턴스 -> 태스크박스
 */
export const unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/task/${taskId}/unlink`, method: 'patch'
    },
      options);
    }
  
/**
 * - 반복 룰을 따르지 않는 예외 루틴을 추가합니다.
    - 예외 루틴 인스턴스는, 룰변경, 룰 하위업무 변경시 영향을 받지 않습니다.
    - 시간 정보, 제목만 입력 가능합니다.
 * @summary 루틴 예외일정 추가
 */
export const createExceptionRoutineInstanceV1RoutineRoutineIdExceptionPost = (
    routineId: string,
    inCreateRoutineExceptionTask: InCreateRoutineExceptionTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/routine/${routineId}/exception`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inCreateRoutineExceptionTask
    },
      options);
    }
  
/**
 * @summary event id를 이용한 회의록 조회
 */
export const readMeetingNoteByEventIdV1MeetingNotesGet = (
    params: ReadMeetingNoteByEventIdV1MeetingNotesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutMeetingNote>(
      {url: `/v1/meeting-notes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 회의록 읽기
 */
export const readBlocksV1MeetingNotesMeetingNoteIdGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutMeetingNote>(
      {url: `/v1/meeting-notes/${meetingNoteId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 회의록 저장
 */
export const saveTransactionV1MeetingNotesMeetingNoteIdSavePost = (
    meetingNoteId: string,
    saveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem: SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/meeting-notes/${meetingNoteId}/save`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem
    },
      options);
    }
  
/**
 * @summary 반복 회의 목록 조회
 */
export const listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet = (
    meetingNoteId: string,
    params?: ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Pagination>(
      {url: `/v1/meeting-notes/${meetingNoteId}/recurring`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 회의록을 삭제합니다.
 * @deprecated
 * @summary 회의록 제거
 */
export const removeMeetingNoteV1NotesMeetingNoteIdDelete = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<KernelBaseSchemaOutRemovedEntity>(
      {url: `/v1/notes/${meetingNoteId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 업로드를 위해 미리 서명된 s3 링크를 제공합니다.<br>유효 기간은 500초 입니다. PUT method를 이용합니다.
 * @deprecated
 * @summary 첨부 파일 업로드
 */
export const retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost = (
    meetingNoteId: string,
    createAttachmentFile: CreateAttachmentFile,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachmentFile>(
      {url: `/v1/notes/${meetingNoteId}/attachments/file`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAttachmentFile
    },
      options);
    }
  
/**
 * 다운로드 받기 위한 s3 링크를 제공합니다. <br>유효 기간은 500초 입니다.file 타입만 사용 가능합니다.
 * @deprecated
 * @summary 첨부 파일 다운로드
 */
export const retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet = (
    meetingNoteId: string,
    attachmentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachmentFile>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}/file`, method: 'get'
    },
      options);
    }
  
/**
 * 첨부 링크 또는 파일의 이름을 수정합니다.
 * @deprecated
 * @summary 첨부항목 이름 변경
 */
export const updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut = (
    meetingNoteId: string,
    attachmentId: string,
    appApplicationAttachmentsSchemasUpdateAttachment: AppApplicationAttachmentsSchemasUpdateAttachment,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachment>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: appApplicationAttachmentsSchemasUpdateAttachment
    },
      options);
    }
  
/**
 * 첨부항목을 삭제합니다.
 * @deprecated
 * @summary 첨부항목 삭제
 */
export const deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete = (
    meetingNoteId: string,
    attachmentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<KernelBaseSchemaOutRemovedEntity>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 링크를 첨부합니다.
 * @deprecated
 * @summary 링크 첨부
 */
export const retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost = (
    meetingNoteId: string,
    createAttachmentLink: CreateAttachmentLink,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachment>(
      {url: `/v1/notes/${meetingNoteId}/attachments/links`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAttachmentLink
    },
      options);
    }
  
/**
 * 인비테이션을 조회합니다.
 * @deprecated
 * @summary 인비테이션 조회
 */
export const readInvitationV1NotesMeetingNoteIdInvitationGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutInvitation>(
      {url: `/v1/notes/${meetingNoteId}/invitation`, method: 'get'
    },
      options);
    }
  
/**
 * 인비테이션을 수정합니다.
 * @deprecated
 * @summary 인비테이션 수정
 */
export const updateInvitationV1NotesMeetingNoteIdInvitationPut = (
    meetingNoteId: string,
    updateInvitation: UpdateInvitation,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutInvitation>(
      {url: `/v1/notes/${meetingNoteId}/invitation`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateInvitation
    },
      options);
    }
  
/**
 * 인비테이션을 전송합니다.
 * @deprecated
 * @summary 인비테이션 전송
 */
export const sendInvitationV1NotesMeetingNoteIdInvitationSendPost = (
    meetingNoteId: string,
    createInvitationShare: CreateInvitationShare,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notes/${meetingNoteId}/invitation/send`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createInvitationShare
    },
      options);
    }
  
/**
 * 리마인더를 조회합니다.
 * @deprecated
 * @summary 리마인더 조회
 */
export const readReminderV1NotesMeetingNoteIdInvitationReminderGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReminder>(
      {url: `/v1/notes/${meetingNoteId}/invitation/reminder`, method: 'get'
    },
      options);
    }
  
/**
 * 리마인더를 설정합니다.
 * @deprecated
 * @summary 리마인더 설정
 */
export const setReminderV1NotesMeetingNoteIdInvitationReminderPost = (
    meetingNoteId: string,
    updateReminder: UpdateReminder,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReminder>(
      {url: `/v1/notes/${meetingNoteId}/invitation/reminder`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateReminder
    },
      options);
    }
  
/**
 * [start, end] 기간의 회고록을 조회합니다. end값이 없을 경우 이번 달의 말일로 설정됩니다.
 * @summary 작성된 회고 조회
 */
export const listReflectionsV1ReflectionsGet = (
    params?: ListReflectionsV1ReflectionsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReflection[]>(
      {url: `/v1/reflections`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 회고 스케줄 조회
 */
export const getReflectionScheduleV1ReflectionsScheduleGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReflectionSchedule>(
      {url: `/v1/reflections/schedule`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 회고 스케줄 설정
 */
export const scheduleReflectionV1ReflectionsSchedulePost = (
    inReflectionSchedule: InReflectionSchedule,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReflectionSchedule>(
      {url: `/v1/reflections/schedule`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inReflectionSchedule
    },
      options);
    }
  
/**
 * '이번 회고 서베이'에 노출될 날짜를 반환합니다.
 * @summary 최신 회고 일정 조회
 */
export const retrieveUpcomingReflectionScheduleV1ReflectionsUpcomingEventGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutEvent>(
      {url: `/v1/reflections/upcoming-event`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 월간 메모 작성
 */
export const createMonthlyMemoV1ReflectionsMemosYearMonthPost = (
    yearMonth: string,
    inMemo: InMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationReflectionsSchemasOutMemo>(
      {url: `/v1/reflections/memos/${yearMonth}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inMemo
    },
      options);
    }
  
/**
 * @summary 월간 메모 조회
 */
export const getMonthlyMemoV1ReflectionsMemosYearMonthGet = (
    yearMonth: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationReflectionsSchemasOutMemo>(
      {url: `/v1/reflections/memos/${yearMonth}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 회고록 상세내용 조회
 */
export const getReflectionDetailV1ReflectionsReflectionIdGet = (
    reflectionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReflection>(
      {url: `/v1/reflections/${reflectionId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 회고 작성
 */
export const writeReflectionV1ReflectionsReflectionIdPut = (
    reflectionId: string,
    inReflection: InReflection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReflection>(
      {url: `/v1/reflections/${reflectionId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: inReflection
    },
      options);
    }
  
/**
 * @summary 월간 달성률 조회
 */
export const getMonthlyAchievementV1ReflectionsAchievementsYearMonthGet = (
    yearMonth: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAchievement>(
      {url: `/v1/reflections/achievements/${yearMonth}`, method: 'get'
    },
      options);
    }
  
/**
 * work(task) 및 workbox(taskbox) 목록을 조회합니다.
 * @summary 태스크 목록 조회
 */
export const listWorksV2V2WorkboxesGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse[]>(
      {url: `/v2/workboxes`, method: 'get'
    },
      options);
    }
  
/**
 * 인박스/나중에 에서 사용할 박스를 생성합니다.
 * @summary workbox 생성
 */
export const createWorkboxV2WorkboxesPost = (
    createTaskbox: CreateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse>(
      {url: `/v2/workboxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskbox
    },
      options);
    }
  
/**
 * 나중에 에서 태스크(그룹)의 순서를 변경합니다.
 * @summary workbox 순서 변경
 */
export const reorderWorkboxV2WorkboxesWorkboxIdReorderPut = (
    workboxId: string,
    moveWorkbox: MoveWorkbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v2/workboxes/${workboxId}/reorder`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: moveWorkbox
    },
      options);
    }
  
/**
 * @summary workbox 수정
 */
export const updateWorkboxesV2V2WorkboxesWorkboxIdPut = (
    workboxId: string,
    updateTaskbox: UpdateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse>(
      {url: `/v2/workboxes/${workboxId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskbox
    },
      options);
    }
  
/**
 * @summary work 수정
 */
export const updateWorkV2V2WorksWorkIdPut = (
    workId: string,
    updateWorkV2: UpdateWorkV2,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v2/works/${workId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateWorkV2
    },
      options);
    }
  
/**
 * - 병합 규칙
1. 첫 태스크가 기준이 됩니다.
2. 프로젝트 태스크가 있다면, 순서에 상관 없이 첫 프로젝트 태스크가 우선순위를 가집니다.
3. 2개 이상의 서로다른 프로젝트가 존재한다면 병합되지 않습니다.
4. 기간 업무(하루초과 종일업무)가 병합대상 에 포함되어 있다면 병합되지 않습니다. 병합 주체로서는 가능합니다.
5. 하이라이트는 초기화 됩니다.
6. 개별 태스크의 카테고리, 기한은 유지 됩니다.
7. 개별 태스크의 메모, 링크는 상위 태스크로 이전 됩니다. (메모는 내용 합쳐짐, 링크는 포인터 변경)

- 병합 제약사항
1. 메인 태스크에 하위 업무 있을시,
    메인 태스크 제목 그대로 유지됨
2. 메인 태스크에 하위 업무 없을시,
    메인 태스크 제목 + ", etc"
 * @summary task 혹은 taskbox를 병합합니다.
 */
export const mergeWorkOrWorkBoxesV2V2WorksMergePost = (
    mergeWorkOrWorkBoxes: MergeWorkOrWorkBoxes,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCreatedEntity>(
      {url: `/v2/works/merge`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mergeWorkOrWorkBoxes
    },
      options);
    }
  
export type HealthCheckGetResult = NonNullable<Awaited<ReturnType<typeof healthCheckGet>>>
export type CreateGoogleUserByAppV1AuthGoogleGetResult = NonNullable<Awaited<ReturnType<typeof createGoogleUserByAppV1AuthGoogleGet>>>
export type CreateUserV1AuthEmailPostResult = NonNullable<Awaited<ReturnType<typeof createUserV1AuthEmailPost>>>
export type ListWorkspacesV1AuthWorkspacesGetResult = NonNullable<Awaited<ReturnType<typeof listWorkspacesV1AuthWorkspacesGet>>>
export type CreateWorkspaceV1AuthWorkspacesPostResult = NonNullable<Awaited<ReturnType<typeof createWorkspaceV1AuthWorkspacesPost>>>
export type JoinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPostResult = NonNullable<Awaited<ReturnType<typeof joinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPost>>>
export type UserMeV1AuthMeGetResult = NonNullable<Awaited<ReturnType<typeof userMeV1AuthMeGet>>>
export type CheckGoogleOauthTokenStatusV1AuthGoogleTokenStatusGetResult = NonNullable<Awaited<ReturnType<typeof checkGoogleOauthTokenStatusV1AuthGoogleTokenStatusGet>>>
export type RefreshV1TokenRefreshPostResult = NonNullable<Awaited<ReturnType<typeof refreshV1TokenRefreshPost>>>
export type InitiateGoogleCalendarAndEventV1EventsSyncingGoogleCalendarGetResult = NonNullable<Awaited<ReturnType<typeof initiateGoogleCalendarAndEventV1EventsSyncingGoogleCalendarGet>>>
export type SyncGoogleCalendarListV1EventsSyncingGoogleCalendarListGetResult = NonNullable<Awaited<ReturnType<typeof syncGoogleCalendarListV1EventsSyncingGoogleCalendarListGet>>>
export type SyncGoogleCalendarEventOnlyShownV1EventsSyncingGoogleCalendarEventOnlyShownGetResult = NonNullable<Awaited<ReturnType<typeof syncGoogleCalendarEventOnlyShownV1EventsSyncingGoogleCalendarEventOnlyShownGet>>>
export type SyncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPostResult = NonNullable<Awaited<ReturnType<typeof syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost>>>
export type GetEventsV1EventsGetResult = NonNullable<Awaited<ReturnType<typeof getEventsV1EventsGet>>>
export type CreateEventV1EventsPostResult = NonNullable<Awaited<ReturnType<typeof createEventV1EventsPost>>>
export type UpdateEventsV1EventsCalendarIdEventIdPutResult = NonNullable<Awaited<ReturnType<typeof updateEventsV1EventsCalendarIdEventIdPut>>>
export type RemoveEventV1EventsCalendarIdEventIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeEventV1EventsCalendarIdEventIdDelete>>>
export type HandleEventNotificationV1EventsNotificationsPostResult = NonNullable<Awaited<ReturnType<typeof handleEventNotificationV1EventsNotificationsPost>>>
export type GetAllCalendarV1CalendarGetResult = NonNullable<Awaited<ReturnType<typeof getAllCalendarV1CalendarGet>>>
export type GetACalendarV1CalendarCalendarIdGetResult = NonNullable<Awaited<ReturnType<typeof getACalendarV1CalendarCalendarIdGet>>>
export type UpdateCalendarV1CalendarCalendarIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateCalendarV1CalendarCalendarIdPatch>>>
export type UpdateCalendarsV1CalendarBulkPatchResult = NonNullable<Awaited<ReturnType<typeof updateCalendarsV1CalendarBulkPatch>>>
export type ReadTaskV1TasksTaskIdGetResult = NonNullable<Awaited<ReturnType<typeof readTaskV1TasksTaskIdGet>>>
export type RemoveTaskV1TasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskV1TasksTaskIdDelete>>>
export type UpdateSubtaskV1TasksTaskIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateSubtaskV1TasksTaskIdPatch>>>
export type CreateTaskV1TasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskV1TasksPost>>>
export type GetMemoV1MemosGetResult = NonNullable<Awaited<ReturnType<typeof getMemoV1MemosGet>>>
export type UpdateMemoV1MemosPutResult = NonNullable<Awaited<ReturnType<typeof updateMemoV1MemosPut>>>
export type ListReceiversV1SlackReceiversGetResult = NonNullable<Awaited<ReturnType<typeof listReceiversV1SlackReceiversGet>>>
export type CreateStateV1SlackStatePostResult = NonNullable<Awaited<ReturnType<typeof createStateV1SlackStatePost>>>
export type IntegrateV1SlackAuthGetResult = NonNullable<Awaited<ReturnType<typeof integrateV1SlackAuthGet>>>
export type RevokeV1SlackRevokeDeleteResult = NonNullable<Awaited<ReturnType<typeof revokeV1SlackRevokeDelete>>>
export type ReadDailyMemoV1DailyMemosGetResult = NonNullable<Awaited<ReturnType<typeof readDailyMemoV1DailyMemosGet>>>
export type CreateDailyMemoV1DailyMemosPostResult = NonNullable<Awaited<ReturnType<typeof createDailyMemoV1DailyMemosPost>>>
export type UpdateDailyMemoV1DailyMemosMemoIdPutResult = NonNullable<Awaited<ReturnType<typeof updateDailyMemoV1DailyMemosMemoIdPut>>>
export type ReadTaskboxesV1TaskboxesGetResult = NonNullable<Awaited<ReturnType<typeof readTaskboxesV1TaskboxesGet>>>
export type CreateTaskboxV1TaskboxesPostResult = NonNullable<Awaited<ReturnType<typeof createTaskboxV1TaskboxesPost>>>
export type RemoveTaskboxesV1TaskboxesDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskboxesV1TaskboxesDelete>>>
export type SubtaskToTaskV1TaskboxesSubtasksTaskTypePatchResult = NonNullable<Awaited<ReturnType<typeof subtaskToTaskV1TaskboxesSubtasksTaskTypePatch>>>
export type UpdateBulkTaskboxesV1TaskboxesBulkPutResult = NonNullable<Awaited<ReturnType<typeof updateBulkTaskboxesV1TaskboxesBulkPut>>>
export type CreateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPostResult = NonNullable<Awaited<ReturnType<typeof createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost>>>
export type UpdateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatchResult = NonNullable<Awaited<ReturnType<typeof updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch>>>
export type ReadTaskboxV1TaskboxesTaskboxIdGetResult = NonNullable<Awaited<ReturnType<typeof readTaskboxV1TaskboxesTaskboxIdGet>>>
export type UpdateTaskboxV1TaskboxesTaskboxIdPutResult = NonNullable<Awaited<ReturnType<typeof updateTaskboxV1TaskboxesTaskboxIdPut>>>
export type RemoveTaskboxV1TaskboxesTaskboxIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskboxV1TaskboxesTaskboxIdDelete>>>
export type AssignTasksV1TaskboxesTaskboxIdAssignPostResult = NonNullable<Awaited<ReturnType<typeof assignTasksV1TaskboxesTaskboxIdAssignPost>>>
export type AbsorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPostResult = NonNullable<Awaited<ReturnType<typeof absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost>>>
export type SyncRecurringTaskboxesV1TaskboxesTaskboxIdSyncPutResult = NonNullable<Awaited<ReturnType<typeof syncRecurringTaskboxesV1TaskboxesTaskboxIdSyncPut>>>
export type CreateTaskboxTaskV1TaskboxesTaskboxIdTasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskboxTaskV1TaskboxesTaskboxIdTasksPost>>>
export type UpdateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPutResult = NonNullable<Awaited<ReturnType<typeof updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut>>>
export type DetachTaskV1TaskboxesTaskboxIdTasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete>>>
export type ReorderTaskboxTimeSplitV1TaskboxesOrderingTimeSplitStylePutResult = NonNullable<Awaited<ReturnType<typeof reorderTaskboxTimeSplitV1TaskboxesOrderingTimeSplitStylePut>>>
export type ListWorksV1WorksGetResult = NonNullable<Awaited<ReturnType<typeof listWorksV1WorksGet>>>
export type CreateTaskV1WorksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskV1WorksPost>>>
export type UpdateTaskV1WorksWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateTaskV1WorksWorkIdPut>>>
export type RemoveTaskV1WorksWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskV1WorksWorkIdDelete>>>
export type AssignTaskV1WorksIssuesIssueIdTasksPutResult = NonNullable<Awaited<ReturnType<typeof assignTaskV1WorksIssuesIssueIdTasksPut>>>
export type ReadIssueV1WorksIssuesIssueIdGetResult = NonNullable<Awaited<ReturnType<typeof readIssueV1WorksIssuesIssueIdGet>>>
export type UpdateIssueV1WorksIssuesIssueIdPutResult = NonNullable<Awaited<ReturnType<typeof updateIssueV1WorksIssuesIssueIdPut>>>
export type MoveWorkToTaskboxV1WorksMoveTaskboxIdPostResult = NonNullable<Awaited<ReturnType<typeof moveWorkToTaskboxV1WorksMoveTaskboxIdPost>>>
export type ListPlanSectionsV1PlansSectionsGetResult = NonNullable<Awaited<ReturnType<typeof listPlanSectionsV1PlansSectionsGet>>>
export type CreatePlanSectionV1PlansSectionsPostResult = NonNullable<Awaited<ReturnType<typeof createPlanSectionV1PlansSectionsPost>>>
export type UpdatePlanSectionV1PlansSectionsPlanSectionIdPutResult = NonNullable<Awaited<ReturnType<typeof updatePlanSectionV1PlansSectionsPlanSectionIdPut>>>
export type DeletePlanSectionV1PlansSectionsPlanSectionIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deletePlanSectionV1PlansSectionsPlanSectionIdDelete>>>
export type ListPlanWeeklyV1PlansWeeklyGetResult = NonNullable<Awaited<ReturnType<typeof listPlanWeeklyV1PlansWeeklyGet>>>
export type CreatePlanWeeklyV1PlansWeeklyPostResult = NonNullable<Awaited<ReturnType<typeof createPlanWeeklyV1PlansWeeklyPost>>>
export type UpdatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPutResult = NonNullable<Awaited<ReturnType<typeof updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut>>>
export type DeletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete>>>
export type DetachWorksInWorkboxV1WorkboxesWorkboxIdDetachPutResult = NonNullable<Awaited<ReturnType<typeof detachWorksInWorkboxV1WorkboxesWorkboxIdDetachPut>>>
export type CreateWorkInWorkboxV1WorkboxesWorkboxIdWorksPostResult = NonNullable<Awaited<ReturnType<typeof createWorkInWorkboxV1WorkboxesWorkboxIdWorksPost>>>
export type SplitWorkboxV1WorkboxesWorkboxIdSplitPostResult = NonNullable<Awaited<ReturnType<typeof splitWorkboxV1WorkboxesWorkboxIdSplitPost>>>
export type RemoveWorkboxV1WorkboxesWorkboxIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeWorkboxV1WorkboxesWorkboxIdDelete>>>
export type UpdateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut>>>
export type RemoveWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDelete>>>
export type ReadFrequentWorksV1FrequentworksGetResult = NonNullable<Awaited<ReturnType<typeof readFrequentWorksV1FrequentworksGet>>>
export type CreateFrequentWorkV1FrequentworksPostResult = NonNullable<Awaited<ReturnType<typeof createFrequentWorkV1FrequentworksPost>>>
export type UpdateFrequentWorkV1FrequentworksFrequentWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateFrequentWorkV1FrequentworksFrequentWorkIdPut>>>
export type DeleteFrequentWorkV1FrequentworksFrequentWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete>>>
export type CreateFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPostResult = NonNullable<Awaited<ReturnType<typeof createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost>>>
export type CreateTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPost>>>
export type DeleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDelete>>>
export type ReadAutocompleteListV1AutocompleteGetResult = NonNullable<Awaited<ReturnType<typeof readAutocompleteListV1AutocompleteGet>>>
export type GetListVotesV1VotesGetResult = NonNullable<Awaited<ReturnType<typeof getListVotesV1VotesGet>>>
export type CreateVoteV1VotesPostResult = NonNullable<Awaited<ReturnType<typeof createVoteV1VotesPost>>>
export type GetSignupSurveyV1SurveysSignupGetResult = NonNullable<Awaited<ReturnType<typeof getSignupSurveyV1SurveysSignupGet>>>
export type GetUserSurveyListV1SurveysGetResult = NonNullable<Awaited<ReturnType<typeof getUserSurveyListV1SurveysGet>>>
export type RecordUserSurveyV1SurveysSurveyIdPostResult = NonNullable<Awaited<ReturnType<typeof recordUserSurveyV1SurveysSurveyIdPost>>>
export type SuspenseSurveyV1SurveysSurveyIdPatchResult = NonNullable<Awaited<ReturnType<typeof suspenseSurveyV1SurveysSurveyIdPatch>>>
export type RecordUserRequestSurveyV1SurveysRequestPostResult = NonNullable<Awaited<ReturnType<typeof recordUserRequestSurveyV1SurveysRequestPost>>>
export type GetAllCategoryV1CategoryGetResult = NonNullable<Awaited<ReturnType<typeof getAllCategoryV1CategoryGet>>>
export type CreateCategoryV1CategoryPostResult = NonNullable<Awaited<ReturnType<typeof createCategoryV1CategoryPost>>>
export type GetACategoryV1CategoryCategoryIdGetResult = NonNullable<Awaited<ReturnType<typeof getACategoryV1CategoryCategoryIdGet>>>
export type DeleteCategoryV1CategoryCategoryIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteCategoryV1CategoryCategoryIdDelete>>>
export type UpdateCategoryV1CategoryCategoryIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateCategoryV1CategoryCategoryIdPatch>>>
export type GetAllMemoV1MemoGetResult = NonNullable<Awaited<ReturnType<typeof getAllMemoV1MemoGet>>>
export type CreateMemoV1MemoPostResult = NonNullable<Awaited<ReturnType<typeof createMemoV1MemoPost>>>
export type GetAMemoV1MemoMemoIdGetResult = NonNullable<Awaited<ReturnType<typeof getAMemoV1MemoMemoIdGet>>>
export type DeleteMemoV1MemoMemoIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteMemoV1MemoMemoIdDelete>>>
export type UpdateMemoV1MemoMemoIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateMemoV1MemoMemoIdPatch>>>
export type GetAllLinkV1LinkGetResult = NonNullable<Awaited<ReturnType<typeof getAllLinkV1LinkGet>>>
export type CreateLinkV1LinkPostResult = NonNullable<Awaited<ReturnType<typeof createLinkV1LinkPost>>>
export type GetALinkV1LinkLinkIdGetResult = NonNullable<Awaited<ReturnType<typeof getALinkV1LinkLinkIdGet>>>
export type DeleteLinkV1LinkLinkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteLinkV1LinkLinkIdDelete>>>
export type UpdateLinkV1LinkLinkIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateLinkV1LinkLinkIdPatch>>>
export type GetAllProjectV1ProjectGetResult = NonNullable<Awaited<ReturnType<typeof getAllProjectV1ProjectGet>>>
export type CreateProjectV1ProjectPostResult = NonNullable<Awaited<ReturnType<typeof createProjectV1ProjectPost>>>
export type GetAProjectV1ProjectProjectIdGetResult = NonNullable<Awaited<ReturnType<typeof getAProjectV1ProjectProjectIdGet>>>
export type DeleteProjectV1ProjectProjectIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteProjectV1ProjectProjectIdDelete>>>
export type UpdateProjectV1ProjectProjectIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateProjectV1ProjectProjectIdPatch>>>
export type OrderProjectV1ProjectProjectIdOrderPatchResult = NonNullable<Awaited<ReturnType<typeof orderProjectV1ProjectProjectIdOrderPatch>>>
export type LinkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPostResult = NonNullable<Awaited<ReturnType<typeof linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost>>>
export type ConvertTaskToProjectV1ProjectConvertingFromTaskTaskIdPostResult = NonNullable<Awaited<ReturnType<typeof convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost>>>
export type AssignTasksToProjectV1ProjectProjectIdAssigningTaskPostResult = NonNullable<Awaited<ReturnType<typeof assignTasksToProjectV1ProjectProjectIdAssigningTaskPost>>>
export type GetAllRecordV1RecordGetResult = NonNullable<Awaited<ReturnType<typeof getAllRecordV1RecordGet>>>
export type CreateRecordV1RecordPostResult = NonNullable<Awaited<ReturnType<typeof createRecordV1RecordPost>>>
export type GetARandomRecordV1RecordRandomGetResult = NonNullable<Awaited<ReturnType<typeof getARandomRecordV1RecordRandomGet>>>
export type GetARecordV1RecordRecordIdGetResult = NonNullable<Awaited<ReturnType<typeof getARecordV1RecordRecordIdGet>>>
export type DeleteRecordV1RecordRecordIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteRecordV1RecordRecordIdDelete>>>
export type UpdateRecordV1RecordRecordIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateRecordV1RecordRecordIdPatch>>>
export type GetADateRecordV1RecordDateDateGetResult = NonNullable<Awaited<ReturnType<typeof getADateRecordV1RecordDateDateGet>>>
export type GetUserMeV1UsersMeGetResult = NonNullable<Awaited<ReturnType<typeof getUserMeV1UsersMeGet>>>
export type RemoveV1UsersMeDeleteResult = NonNullable<Awaited<ReturnType<typeof removeV1UsersMeDelete>>>
export type UpdateMyProfileV1UsersMePatchResult = NonNullable<Awaited<ReturnType<typeof updateMyProfileV1UsersMePatch>>>
export type GetMembersV1UsersMembersGetResult = NonNullable<Awaited<ReturnType<typeof getMembersV1UsersMembersGet>>>
export type GrantPermissionV1UsersMembersPermissionPostResult = NonNullable<Awaited<ReturnType<typeof grantPermissionV1UsersMembersPermissionPost>>>
export type InviteMembersV1UsersInvitePostResult = NonNullable<Awaited<ReturnType<typeof inviteMembersV1UsersInvitePost>>>
export type RemoveUserDataV1UsersDeleteAccountPostResult = NonNullable<Awaited<ReturnType<typeof removeUserDataV1UsersDeleteAccountPost>>>
export type RetrieveUserSettingV1UsersSettingGetResult = NonNullable<Awaited<ReturnType<typeof retrieveUserSettingV1UsersSettingGet>>>
export type PartialUpdateUserSettingV1UsersSettingPatchResult = NonNullable<Awaited<ReturnType<typeof partialUpdateUserSettingV1UsersSettingPatch>>>
export type UpdateUserProfileV1UsersUserIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdPatch>>>
export type ReadDepartmentsV1UsersDepartmentsGetResult = NonNullable<Awaited<ReturnType<typeof readDepartmentsV1UsersDepartmentsGet>>>
export type ReadUserHistoryV1UsersHistoryGetResult = NonNullable<Awaited<ReturnType<typeof readUserHistoryV1UsersHistoryGet>>>
export type GetAllStateStorageV1StateStorageGetResult = NonNullable<Awaited<ReturnType<typeof getAllStateStorageV1StateStorageGet>>>
export type UpdateStateStorageV1StateStoragePatchResult = NonNullable<Awaited<ReturnType<typeof updateStateStorageV1StateStoragePatch>>>
export type GetAllFeatureStorageV1FeatureStorageGetResult = NonNullable<Awaited<ReturnType<typeof getAllFeatureStorageV1FeatureStorageGet>>>
export type CreateFeatureStorageV1FeatureStoragePostResult = NonNullable<Awaited<ReturnType<typeof createFeatureStorageV1FeatureStoragePost>>>
export type GetAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGetResult = NonNullable<Awaited<ReturnType<typeof getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet>>>
export type GetAFeatureStorageV1FeatureStorageFeatureStorageIdGetResult = NonNullable<Awaited<ReturnType<typeof getAFeatureStorageV1FeatureStorageFeatureStorageIdGet>>>
export type DeleteFeatureStorageV1FeatureStorageFeatureStorageIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteFeatureStorageV1FeatureStorageFeatureStorageIdDelete>>>
export type UpdateFeatureStorageV1FeatureStorageFeatureStorageIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch>>>
export type GetANoteV1NoteGetResult = NonNullable<Awaited<ReturnType<typeof getANoteV1NoteGet>>>
export type UpdateNoteV1NotePatchResult = NonNullable<Awaited<ReturnType<typeof updateNoteV1NotePatch>>>
export type GetWeeklyCategoryStatV1StatCategoryWeekGetResult = NonNullable<Awaited<ReturnType<typeof getWeeklyCategoryStatV1StatCategoryWeekGet>>>
export type GetWeeklyProjectStatV1StatProjectWeekGetResult = NonNullable<Awaited<ReturnType<typeof getWeeklyProjectStatV1StatProjectWeekGet>>>
export type ListRoutineV1RoutineGetResult = NonNullable<Awaited<ReturnType<typeof listRoutineV1RoutineGet>>>
export type CreateRoutineV1RoutinePostResult = NonNullable<Awaited<ReturnType<typeof createRoutineV1RoutinePost>>>
export type GetARoutineV1RoutineRoutineIdGetResult = NonNullable<Awaited<ReturnType<typeof getARoutineV1RoutineRoutineIdGet>>>
export type DeleteRoutineV1RoutineRoutineIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteRoutineV1RoutineRoutineIdDelete>>>
export type UpdateRoutineV1RoutineRoutineIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateRoutineV1RoutineRoutineIdPatch>>>
export type ListRoutineInstanceByDateAndCountV1RoutineRoutineIdInstanceGetResult = NonNullable<Awaited<ReturnType<typeof listRoutineInstanceByDateAndCountV1RoutineRoutineIdInstanceGet>>>
export type UpdateRoutineOrderV1RoutineRoutineIdOrderPatchResult = NonNullable<Awaited<ReturnType<typeof updateRoutineOrderV1RoutineRoutineIdOrderPatch>>>
export type UpdateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatchResult = NonNullable<Awaited<ReturnType<typeof updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch>>>
export type UpdateRoutineTaskV1RoutineRoutineIdTaskPostResult = NonNullable<Awaited<ReturnType<typeof updateRoutineTaskV1RoutineRoutineIdTaskPost>>>
export type CreateRoutineFromTaskV1RoutineFromTaskTaskIdPostResult = NonNullable<Awaited<ReturnType<typeof createRoutineFromTaskV1RoutineFromTaskTaskIdPost>>>
export type UnlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatchResult = NonNullable<Awaited<ReturnType<typeof unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch>>>
export type CreateExceptionRoutineInstanceV1RoutineRoutineIdExceptionPostResult = NonNullable<Awaited<ReturnType<typeof createExceptionRoutineInstanceV1RoutineRoutineIdExceptionPost>>>
export type ReadMeetingNoteByEventIdV1MeetingNotesGetResult = NonNullable<Awaited<ReturnType<typeof readMeetingNoteByEventIdV1MeetingNotesGet>>>
export type ReadBlocksV1MeetingNotesMeetingNoteIdGetResult = NonNullable<Awaited<ReturnType<typeof readBlocksV1MeetingNotesMeetingNoteIdGet>>>
export type SaveTransactionV1MeetingNotesMeetingNoteIdSavePostResult = NonNullable<Awaited<ReturnType<typeof saveTransactionV1MeetingNotesMeetingNoteIdSavePost>>>
export type ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetResult = NonNullable<Awaited<ReturnType<typeof listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet>>>
export type RemoveMeetingNoteV1NotesMeetingNoteIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeMeetingNoteV1NotesMeetingNoteIdDelete>>>
export type RetrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePostResult = NonNullable<Awaited<ReturnType<typeof retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost>>>
export type RetrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGetResult = NonNullable<Awaited<ReturnType<typeof retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet>>>
export type UpdateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPutResult = NonNullable<Awaited<ReturnType<typeof updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut>>>
export type DeleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete>>>
export type RetrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPostResult = NonNullable<Awaited<ReturnType<typeof retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost>>>
export type ReadInvitationV1NotesMeetingNoteIdInvitationGetResult = NonNullable<Awaited<ReturnType<typeof readInvitationV1NotesMeetingNoteIdInvitationGet>>>
export type UpdateInvitationV1NotesMeetingNoteIdInvitationPutResult = NonNullable<Awaited<ReturnType<typeof updateInvitationV1NotesMeetingNoteIdInvitationPut>>>
export type SendInvitationV1NotesMeetingNoteIdInvitationSendPostResult = NonNullable<Awaited<ReturnType<typeof sendInvitationV1NotesMeetingNoteIdInvitationSendPost>>>
export type ReadReminderV1NotesMeetingNoteIdInvitationReminderGetResult = NonNullable<Awaited<ReturnType<typeof readReminderV1NotesMeetingNoteIdInvitationReminderGet>>>
export type SetReminderV1NotesMeetingNoteIdInvitationReminderPostResult = NonNullable<Awaited<ReturnType<typeof setReminderV1NotesMeetingNoteIdInvitationReminderPost>>>
export type ListReflectionsV1ReflectionsGetResult = NonNullable<Awaited<ReturnType<typeof listReflectionsV1ReflectionsGet>>>
export type GetReflectionScheduleV1ReflectionsScheduleGetResult = NonNullable<Awaited<ReturnType<typeof getReflectionScheduleV1ReflectionsScheduleGet>>>
export type ScheduleReflectionV1ReflectionsSchedulePostResult = NonNullable<Awaited<ReturnType<typeof scheduleReflectionV1ReflectionsSchedulePost>>>
export type RetrieveUpcomingReflectionScheduleV1ReflectionsUpcomingEventGetResult = NonNullable<Awaited<ReturnType<typeof retrieveUpcomingReflectionScheduleV1ReflectionsUpcomingEventGet>>>
export type CreateMonthlyMemoV1ReflectionsMemosYearMonthPostResult = NonNullable<Awaited<ReturnType<typeof createMonthlyMemoV1ReflectionsMemosYearMonthPost>>>
export type GetMonthlyMemoV1ReflectionsMemosYearMonthGetResult = NonNullable<Awaited<ReturnType<typeof getMonthlyMemoV1ReflectionsMemosYearMonthGet>>>
export type GetReflectionDetailV1ReflectionsReflectionIdGetResult = NonNullable<Awaited<ReturnType<typeof getReflectionDetailV1ReflectionsReflectionIdGet>>>
export type WriteReflectionV1ReflectionsReflectionIdPutResult = NonNullable<Awaited<ReturnType<typeof writeReflectionV1ReflectionsReflectionIdPut>>>
export type GetMonthlyAchievementV1ReflectionsAchievementsYearMonthGetResult = NonNullable<Awaited<ReturnType<typeof getMonthlyAchievementV1ReflectionsAchievementsYearMonthGet>>>
export type ListWorksV2V2WorkboxesGetResult = NonNullable<Awaited<ReturnType<typeof listWorksV2V2WorkboxesGet>>>
export type CreateWorkboxV2WorkboxesPostResult = NonNullable<Awaited<ReturnType<typeof createWorkboxV2WorkboxesPost>>>
export type ReorderWorkboxV2WorkboxesWorkboxIdReorderPutResult = NonNullable<Awaited<ReturnType<typeof reorderWorkboxV2WorkboxesWorkboxIdReorderPut>>>
export type UpdateWorkboxesV2V2WorkboxesWorkboxIdPutResult = NonNullable<Awaited<ReturnType<typeof updateWorkboxesV2V2WorkboxesWorkboxIdPut>>>
export type UpdateWorkV2V2WorksWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateWorkV2V2WorksWorkIdPut>>>
export type MergeWorkOrWorkBoxesV2V2WorksMergePostResult = NonNullable<Awaited<ReturnType<typeof mergeWorkOrWorkBoxesV2V2WorksMergePost>>>
