import styled from '@emotion/styled';
import { Button, Checkbox, CheckboxProps, Fade, IconButton, Popper, TextField, Tooltip, Typography, keyframes } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Icons } from 'components';
import { v4 as uuidv4 } from 'uuid';
import { useClickOutside } from '@react-hookz/web';
import CalendarView, { CustomEvent } from './CalendarView';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { dragContextAtom } from 'atoms/works';
import { SVGProps } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { createTaskboxV1TaskboxesPost, getEventsV1EventsGet, updateTaskboxV1TaskboxesTaskboxIdPut } from 'queries';
import { OutReadEvent } from 'queries/model';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.gray100};
  min-width: 1200px;
  position: relative;
`;

const fadeInOutAnimation = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const TaskListDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 166px;
  height: 40px;
  padding: 4px 8px 4px 12px;
  background-color: #fff;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  left: -10000px;
  right: -10000px;
  z-index: 1000;
`;

const VerticalBar = styled.div<{ done?: boolean }>`
  border: 2px solid ${COLORS.brand1};
  outline: 1px solid #e7eaf4;
  border-radius: 4px;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const VerticalBarFocus = styled.div<{ done?: boolean }>`
  border: 2px solid transparent;
  outline: 1px solid #e7eaf4;
  border-radius: 4px;
  -webkit-border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image-slice: 1;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const DragTooltipPopoverWrapper = styled.div`
  width: 560px;
  height: 268px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px ${COLORS.shadow100};
`;

const SuccessTooltipPopoverWrapper = styled.div`
  width: 480px;
  height: 116px;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px ${COLORS.shadow100};
`;

const ArrowLeftIconWrapper = styled.span`
  display: inline-flex;
  width: 20px;
  height: 24px;
  padding: 4px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #e7eaf4;
  cursor: grab;
`;

const AnimationWrapper = styled.div<{ opacity?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeInOutAnimation} ease-in-out 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const HeaderStepperWrapper = styled.div`
  width: 100%;
  min-height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.gray300};
  padding: 0px 32px;
`;

const TaskboxInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 582px;
  padding: 8px 8px 8px 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  position: relative;

  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }

  .drag-handle {
    position: absolute;
    left: -24px;
    top: 14px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
`;

const ArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.6673 8H3.33398M3.33398 8L7.33398 12M3.33398 8L7.33398 4" stroke="#ABB0BF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const TaskboxCheckbox = (props: CheckboxProps) => {
  return <Checkbox {...props} sx={{ padding: 0, margin: 0, ...props.sx }} style={{ width: 20, height: 20, ...props.style }} />;
};

const TaskboxInput = ({
  id,
  title,
  focus,
  start,
  end,
  draggable,
  readonly,
  verticalBar,
  autoFocus,
  onChange,
  onKeydown,
}: {
  id: string;
  title?: string;
  focus?: boolean;
  draggable?: boolean;
  start?: { date?: string | null; datetime?: string | null };
  end?: { date?: string | null; datetime?: string | null };
  readonly?: boolean;
  verticalBar?: boolean;
  autoFocus?: boolean;
  onChange?: (id: string, title?: string) => void;
  onKeydown?: (e: React.KeyboardEvent<HTMLInputElement>, id: string) => void;
}) => {
  const refTaskboxInput = useRef<HTMLInputElement>(null);
  const refInput = useRef<HTMLDivElement>(null);
  const [, setDragContext] = useState({ dragging: false, draggingId: '' });
  const [, setOutsideDragContext] = useAtom(dragContextAtom);

  useEffect(() => {
    if (autoFocus) {
      refTaskboxInput?.current?.focus();
    }
  }, [autoFocus]);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(id, e.target.value || '');
  };

  const handleTaskboxKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeydown?.(e, id);
  };

  const handleDragStart = (e: React.DragEvent<HTMLButtonElement> | React.DragEvent<HTMLDivElement>) => {
    const el = refInput?.current?.querySelector('#onboarding-taskbox-drag-image');
    e.dataTransfer.setDragImage(el!, 16, 8);

    setDragContext({ dragging: true, draggingId: id });
    setOutsideDragContext({ id, view: 'onboarding', title: title, type: 'taskbox' });
  };

  const handleDragEnd = () => {
    setDragContext({ dragging: false, draggingId: '' });
    setOutsideDragContext(null);
  };

  const handleDrag = (e: React.DragEvent<HTMLButtonElement> | React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDragContext({ dragging: true, draggingId: id });
  };

  const handleDragOver = (e: React.DragEvent<HTMLButtonElement> | React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <TaskboxInputWrapper ref={refInput} draggable onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragOver={handleDragOver} onDrag={handleDrag}>
      {draggable && !(start && end) && (
        <div className="drag-handle">
          <Tooltip title={'왼쪽으로 드래그해서 캘린더로 옮깁니다.'} disableInteractive>
            <ArrowLeftIconWrapper onClick={(e) => e.preventDefault}>
              <ArrowLeft />
            </ArrowLeftIconWrapper>
          </Tooltip>
        </div>
      )}
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        {verticalBar ? focus ? <VerticalBarFocus style={{ height: '100%', width: '3px' }} /> : <VerticalBar style={{ height: '100%', width: '3px' }} /> : <></>}
        <div style={{ width: '100%' }}>
          {start?.datetime && end?.datetime && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8, marginTop: 4 }}>
              <Icons.Time width={16} height={16} stroke={COLORS.gray500} />
              <div style={{ marginLeft: 6, fontSize: 12, color: COLORS.gray500 }}>
                {`${dayjs(start?.datetime, { utc: true }).format(TIME_FORMAT_2)} - ${dayjs(end?.datetime, { utc: true }).format(TIME_FORMAT_2)}`}
              </div>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
            <TaskboxCheckbox
              tabIndex={-1}
              icon={focus ? <Icons.FocusUncheck /> : <Icons.TaskboxUncheck circle={COLORS.brand1} marker={'#E2ECFF'} />}
              checked={false}
              style={{ marginRight: 8, marginLeft: 8, marginBottom: 2 }}
            />
            <TextField
              inputRef={refTaskboxInput}
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder=""
              value={title || ''}
              onChange={handleChangeTitle}
              onKeyDown={handleTaskboxKeydown}
              InputProps={{
                disableUnderline: true,
                readOnly: readonly,
                style: { fontWeight: 'bold', color: COLORS.gray900 },
              }}
            />
          </div>
        </div>
      </div>
      <TaskListDragImage id="onboarding-taskbox-drag-image">
        {verticalBar ? focus ? <VerticalBarFocus style={{ height: '100%', width: '3px' }} /> : <VerticalBar style={{ height: '100%', width: '3px' }} /> : <></>}
        <TaskboxCheckbox
          tabIndex={-1}
          icon={<Icons.TaskboxUncheck circle={COLORS.brand1} marker={'#E2ECFF'} />}
          checkedIcon={focus ? <Icons.FocusCheck opacity={0.5} /> : <Icons.TaskboxCheck fill={COLORS.brand1} opacity={0.5} />}
          checked={false}
          style={{ marginLeft: 8, marginRight: 8 }}
        />
        <Typography variant="body2" fontSize={16} fontWeight={'bold'} color={COLORS.gray900} overflow={'hidden'} textOverflow={'ellipsis'}>
          {title}
        </Typography>
      </TaskListDragImage>
    </TaskboxInputWrapper>
  );
};

interface Taskbox {
  id: string;
  title?: string;
  start?: { date?: string | null; datetime?: string | null };
  end?: { date?: string | null; datetime?: string | null };
  allDay?: boolean;
  done?: boolean;
  focus?: boolean;
}

const WelcomeAnimation = () => (
  <AnimationWrapper style={{ marginTop: 160 }}>
    <img src={require('assets/images/illust.png')} width={276} height={320} style={{ width: 276, height: 320 }} />
    <div style={{ display: 'flex', marginLeft: 12, marginTop: 16 }}>
      <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
        “
      </Typography>
      <Typography variant="subtitle2" color={COLORS.gray700} fontSize={40}>
        오늘 하루를&nbsp;
      </Typography>
      <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'}>
        성공적으로&nbsp;
      </Typography>
      <Typography variant="subtitle2" color={COLORS.gray700} fontSize={40}>
        보내봐요
      </Typography>
      <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
        ”
      </Typography>
    </div>
  </AnimationWrapper>
);

const Onboarding = () => {
  const navigate = useNavigate();
  const [animation, setAnimation] = useState(true);
  const [meetings, setMeetings] = useState<OutReadEvent[]>([]);
  const [taskboxes, setTaskboxes] = useState<Taskbox[]>([
    { id: uuidv4(), title: '', focus: false },
    { id: uuidv4(), title: '', focus: false },
    { id: uuidv4(), title: '', focus: false },
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [isSkipped, setIsSkipped] = useState(false);
  const refCalendarView = useRef<HTMLDivElement>(null);
  const [, setSelectedEventId] = useState<string | undefined>();
  const [newTaskbox, setNewTaskbox] = useState<Taskbox>();
  const [taskViewDragContext] = useAtom(dragContextAtom);
  const [draggedTaskboxEvents, setDraggedTaskboxEvents] = useState<Taskbox[]>([]);
  const [dragTooltipPopover, setDragTooltipPopover] = useState(false);
  const [todoTooltipPopover, setTodoTooltipPopover] = useState(false);
  const [successTooltipPopover, setSuccessTooltipPopover] = useState(false);
  const refTooltip = useRef<HTMLDivElement>(null);
  const refSuccessTooltip = useRef<HTMLDivElement>(null);
  const [autoFocusIndex, setAutoFocusIndex] = useState(0);
  const steps = ['할 일 입력하기', '하이라이트하기'];

  useEffect(() => {
    init();
    setTimeout(() => setAnimation(false), 4000);
  }, []);

  useClickOutside(refCalendarView, () => {
    setSelectedEventId(undefined);
  });

  const events = useMemo(() => {
    const meetingEvents: CustomEvent[] = meetings.map((item: OutReadEvent) => ({
      id: item.id || '',
      title: item.summary || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime, { utc: true }).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime, { utc: true }).toDate(),
      data: item,
      type: 'meeting',
      allDay: item.allDay,
    }));

    const taskboxEvents: CustomEvent[] = draggedTaskboxEvents.map((item: Taskbox) => ({
      id: item.id || '',
      title: item.title || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime, { utc: true }).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime, { utc: true }).toDate(),
      type: 'task',
      data: {},
      done: item.done,
      allDay: item.allDay,
      focus: item.focus,
    }));

    const newTaskboxEvent: any[] =
      newTaskbox && newTaskbox.id
        ? [
            {
              id: newTaskbox.id,
              title: newTaskbox.title || '',
              start: newTaskbox.start?.date ? dayjs(newTaskbox.start?.date).toDate() : dayjs(newTaskbox.start?.datetime, { utc: true }).toDate(),
              end: newTaskbox.end?.date ? dayjs(newTaskbox.end?.date).toDate() : dayjs(newTaskbox.end?.datetime, { utc: true }).toDate(),
              type: 'task',
              focus: true,
              allDay: Boolean(newTaskbox.allDay),
            },
          ]
        : [];

    return [...meetingEvents, ...taskboxEvents, ...newTaskboxEvent];
  }, [meetings, draggedTaskboxEvents, newTaskbox]);

  const init = async () => {
    const meetings = await fetchMeetings(new Date());
    setMeetings(meetings);
  };

  const fetchMeetings = async (date: Date, refresh?: boolean) => {
    const meetings = await getEventsV1EventsGet({
      startTime: dayjs(date).startOf('day').format(DATE_FORMAT_1),
      endTime: dayjs(date).endOf('day').format(DATE_FORMAT_1),
      refresh,
    });
    return meetings.data || [];
  };

  const handleSkip = () => {
    setIsSkipped(true);
    setActiveStep(steps.length - 1);
    setTodoTooltipPopover(true);
    setTaskboxes([]);
    setDraggedTaskboxEvents([]);
  };

  const handleStart = async () => {
    localStorage.setItem('onboarding', 'true');

    for (const taskbox of draggedTaskboxEvents) {
      if (!taskbox.title) continue;

      const start = taskbox.allDay
        ? { date: dayjs(taskbox.start?.date).format(DATE_FORMAT_4) }
        : { datetime: dayjs(taskbox.start?.datetime, { utc: true }).format(DATE_FORMAT_1) };
      const end = taskbox.allDay
        ? { date: dayjs(taskbox.end?.date).add(1, 'day').format(DATE_FORMAT_4) }
        : { datetime: dayjs(taskbox.end?.datetime, { utc: true }).format(DATE_FORMAT_1) };

      await createTaskboxV1TaskboxesPost({ id: taskbox.id || uuidv4(), title: taskbox.title, start, end });

      if (taskbox.focus) {
        await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id, { focus: true });
      }
    }

    navigate('/task/today');
  };

  const handleBack = () => {
    const step = Math.max(0, activeStep - 1);
    setActiveStep(step);
    setDragTooltipPopover(false);
    setTodoTooltipPopover(false);
    setSuccessTooltipPopover(false);
    setTaskboxes(Array.from({ length: 3 }, () => ({ id: uuidv4(), title: '', start: undefined, end: undefined, allDay: false, done: false, focus: false })));
    if (step === 0) {
      setDraggedTaskboxEvents([]);
    }
  };

  const handleNext = () => {
    const step = Math.min(steps.length - 1, activeStep + 1);
    setActiveStep(step);
    setDragTooltipPopover(true);
    if (step === 1) {
      setDraggedTaskboxEvents([
        ...taskboxes.map((item) => ({ ...item, allDay: true, start: { date: dayjs().format(DATE_FORMAT_4) }, end: { date: dayjs().format(DATE_FORMAT_4) } })),
      ]);
    }
  };

  const handleSelectEvent = async (eventId: string) => {
    const event = events.find((item) => item.id === eventId);
    setSelectedEventId(event?.id || undefined);
  };

  const handleChangeTaskbox = (id: string, title?: string) => {
    setTaskboxes(taskboxes.map((item) => (item.id === id ? { ...item, title } : item)));
  };

  const handleTaskboxInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, id: string) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const index = taskboxes.map((item) => item.id).indexOf(id);
      if (index === taskboxes.length - 1 && e.key === 'Enter') {
        if (taskboxes.filter((v) => (v.title || '')?.length > 0).length > 0) {
          handleNext();
        }
      } else {
        setAutoFocusIndex((index + 1) % taskboxes.length);
      }
      e.preventDefault();
    }
  };

  const handleUpdateEvent = async ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => {
    if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가

    const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
    const end = isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime };

    if (newTaskbox && newTaskbox.id === eventId) {
      setNewTaskbox(undefined);
      return;
    }

    const taskbox = draggedTaskboxEvents.find((item) => item.id === eventId);
    if (!taskbox) return;

    setDraggedTaskboxEvents(draggedTaskboxEvents.map((item) => (item.id === taskbox.id ? { ...taskbox, start, end, allDay: isAllDay } : item)));
    setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...item, start, end, allDay: isAllDay } : item)));
  };

  const handleUpdateEventTitle = async ({ eventId, title }: { eventId: string; title: string; isAllDay: boolean }) => {
    if (newTaskbox && newTaskbox.id === eventId) {
      if (!title) {
        setNewTaskbox(undefined);
        return;
      }

      const taskbox = { id: uuidv4(), title, start: { datetime: newTaskbox.start?.datetime }, end: { datetime: newTaskbox.end?.datetime }, focus: true };
      setDraggedTaskboxEvents([...draggedTaskboxEvents, taskbox]);
      setTaskboxes([...taskboxes, taskbox]);
      setNewTaskbox(undefined);

      if (activeStep === 1 && isSkipped) {
        setTodoTooltipPopover(false);
        setSuccessTooltipPopover(true);
      }
    } else {
      const taskbox = draggedTaskboxEvents.find((item) => item.id === eventId);
      if (!taskbox) return;
      if (taskbox.title === title) return;

      setDraggedTaskboxEvents(draggedTaskboxEvents.map((item) => (item.id === taskbox.id ? { ...taskbox, title } : item)));
      setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...item, title } : item)));
    }
  };

  const handleClickTimeSlot = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    if (taskboxes.length >= 1) return;
    if (activeStep === 1 && isSkipped) {
      const id = uuidv4();
      setNewTaskbox((prevTaskbox) => ({
        ...prevTaskbox,
        id: id,
        title: prevTaskbox?.title || '',
        start: isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime },
        end: isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime },
        allDay: isAllDay,
      }));
      setTimeout(() => setSelectedEventId(id), 100);
    }
  };

  const handleDropFromOutside = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    if (!taskViewDragContext) return;
    const { id, title } = taskViewDragContext;
    const allDay = Math.abs(dayjs(startTime).diff(endTime, 'day')) > 0 || isAllDay;
    const start = allDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
    const end = allDay
      ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
      : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) };
    const taskbox = taskboxes.find((item) => item.id === id);
    if (!taskbox) return;

    const focus = taskboxes.filter((item) => item.focus).length === 0 ? true : false;

    setDraggedTaskboxEvents(draggedTaskboxEvents.map((item) => (item.id === taskbox.id ? { ...taskbox, focus, title, start, end, allDay } : item)));
    setTaskboxes(taskboxes.map((item) => (item.id === id ? { ...item, focus, start, end, allDay } : item)));

    if (activeStep === 1) {
      setDragTooltipPopover(false);
      setSuccessTooltipPopover(true);
    }
  };

  // if (animation)
  //   return (
  //     <Container>
  //       <WelcomeAnimation />
  //     </Container>
  //   );

  return (
    <Container>
      <HeaderStepperWrapper>
        <div style={{ minWidth: 100 }}>
          {activeStep > 0 && (
            <Button
              size="small"
              disableElevation
              variant="outlined"
              onClick={handleBack}
              sx={{ background: '#fff !important', color: `${COLORS.gray800}`, border: `1px solid ${COLORS.gray200}`, borderRadius: '8px' }}
            >
              <b>뒤로</b>
            </Button>
          )}
        </div>
        <div style={{ width: 410 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', minWidth: 180 }}>
          {activeStep === 0 && (
            <Button
              disableElevation
              size="small"
              color="inherit"
              onClick={handleSkip}
              sx={{ margin: 0, borderRadius: '8px' }}
              style={{ minWidth: 72, marginRight: 8 }}
            >
              <b>건너뛰기</b>
            </Button>
          )}
          {activeStep === 0 && (
            <Button
              disableElevation
              size="small"
              color="primary"
              variant="contained"
              disabled={taskboxes.filter((v) => (v.title || '')?.length > 0).length === 0}
              onClick={handleNext}
              sx={{ margin: 0, borderRadius: '8px' }}
              style={{ minWidth: 72 }}
            >
              <b>다음</b>
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              disableElevation
              disabled={draggedTaskboxEvents.filter((v) => !v.allDay && v.start && v.end).length <= 0}
              size="small"
              color="primary"
              variant="contained"
              onClick={handleStart}
              sx={{ margin: 0, borderRadius: '8px' }}
              style={{ minWidth: 72 }}
            >
              <b>시작하기</b>
            </Button>
          )}
        </div>
      </HeaderStepperWrapper>
      {activeStep === 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 116 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h3" fontSize={24} fontWeight={'bold'}>
              오늘 할 일은 무엇인가요? 3가지만 적어보세요.
            </Typography>
            <Typography variant="subtitle1" fontSize={16} color={COLORS.gray700} style={{ marginTop: 8 }}>
              {`'이 일들만 하면 오늘 하루는 성공이다' 싶은 일들을 정리해보세요.`}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 40 }}>
            {taskboxes.map((taskbox, idx) => (
              <TaskboxInput
                key={taskbox.id}
                id={taskbox.id}
                title={taskbox.title}
                focus={taskbox.focus}
                autoFocus={autoFocusIndex === idx}
                onChange={handleChangeTaskbox}
                onKeydown={handleTaskboxInputKeyDown}
              />
            ))}
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
          <div
            style={{
              width: 294,
              maxWidth: 294,
              display: 'flex',
              flexDirection: 'column',
              padding: '24px 32px 0px 32px',
              borderRight: `1px solid ${COLORS.gray300}`,
            }}
          >
            <Typography variant="subtitle1" fontSize={16} fontWeight={'bold'}>
              {dayjs().format('MM월 DD일 (ddd)')}
            </Typography>
            <div ref={refCalendarView} style={{ width: '100%', height: '100%' }}>
              <CalendarView
                newEventId={newTaskbox?.id}
                events={events}
                currentDate={new Date()}
                onSelectEvent={handleSelectEvent}
                onUpdateEvent={handleUpdateEvent}
                onUpdateEventTitle={handleUpdateEventTitle}
                onClickTimeSlot={handleClickTimeSlot}
                onDropFromOutside={handleDropFromOutside}
              />
            </div>
          </div>
          {!isSkipped && (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 116 }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: -390 }}>
                  <span style={{ display: 'inline-flex' }}>
                    <Typography variant="h3" fontSize={24} fontWeight={'bold'}>
                      오늘 할 일 중에서 가장 중요한 일을 계획해보세요.
                    </Typography>
                    <span style={{ marginLeft: 8 }}>
                      <Icons.Required />
                    </span>
                  </span>
                  <Typography variant="subtitle1" fontSize={16} color={COLORS.gray700} style={{ marginTop: 8 }}>
                    가장 중요한 일을 가장 집중할 수 있는 시간에 배치해보세요.
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 40, marginLeft: -294 }}>
                  {taskboxes.map((v) => (
                    <TaskboxInput
                      key={v.id}
                      id={v.id}
                      title={v.title}
                      focus={v.focus}
                      readonly={true}
                      start={v?.start}
                      end={v?.end}
                      verticalBar={true}
                      draggable={true}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {isSkipped && (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 116 }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: -452 }}>
                  <span style={{ display: 'inline-flex' }}>
                    <Typography variant="h3" fontSize={24} fontWeight={'bold'}>
                      오늘 가장 집중할 수 있는 때는 언제인가요?
                    </Typography>
                    <span style={{ marginLeft: 8 }}>
                      <Icons.Required />
                    </span>
                  </span>
                  <Typography variant="subtitle1" fontSize={16} color={COLORS.gray700} style={{ marginTop: 8 }}>
                    가장 집중할 수 있는 때에 가장 중요한 일을 계획해보세요.
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 40, marginLeft: -294 }}>
                  {taskboxes.map((v) => (
                    <TaskboxInput
                      key={v.id}
                      id={v.id}
                      title={v.title}
                      focus={v.focus}
                      readonly={true}
                      start={v?.start}
                      end={v?.end}
                      verticalBar={true}
                      draggable={true}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div ref={refTooltip} style={{ position: 'absolute', right: 32, bottom: 32 }}>
        <Popper open={dragTooltipPopover} anchorEl={refTooltip.current} container={refTooltip.current} placement="bottom-end" transition style={{ zIndex: 30 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <DragTooltipPopoverWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography fontSize={20} fontWeight={'bold'} color={COLORS.gray700}>
                    캘린더에 추가할 때는 왼쪽으로 해당 아이콘을 드래그 하세요.
                  </Typography>
                  <IconButton size={'small'} color="inherit" sx={{ padding: 0 }} disableRipple onClick={() => setDragTooltipPopover(false)}>
                    <b>&#x2715;</b>
                  </IconButton>
                </div>

                <img src={require('assets/images/drag_tooltip.png')} />
              </DragTooltipPopoverWrapper>
            </Fade>
          )}
        </Popper>
        <Popper open={todoTooltipPopover} anchorEl={refTooltip.current} container={refTooltip.current} placement="bottom-end" transition style={{ zIndex: 30 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <DragTooltipPopoverWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography fontSize={20} fontWeight={'bold'} color={COLORS.gray700}>
                    타임라인을 클릭해서 할 일을 생성해요.
                  </Typography>
                  <IconButton size={'small'} color="inherit" sx={{ padding: 0 }} disableRipple onClick={() => setTodoTooltipPopover(false)}>
                    <b>&#x2715;</b>
                  </IconButton>
                </div>
                <img src={require('assets/images/todo_tooltip.png')} />
              </DragTooltipPopoverWrapper>
            </Fade>
          )}
        </Popper>
      </div>
      <div ref={refSuccessTooltip} style={{ position: 'absolute', right: 32, top: 52 }}>
        <Popper
          open={successTooltipPopover}
          anchorEl={refSuccessTooltip.current}
          container={refSuccessTooltip.current}
          placement="bottom-end"
          transition
          style={{ zIndex: 30 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={{ enter: 200, exit: 0 }}>
              <SuccessTooltipPopoverWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography fontSize={20} color={COLORS.gray900}>
                    모든 준비를 마쳤어요!
                  </Typography>
                  <Typography fontSize={24} fontWeight={'bold'} color={COLORS.gray900}>
                    성공적인 하루를 시작합니다.
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography fontSize={24} color={COLORS.gray500}>
                    ↑
                  </Typography>
                  <Typography fontSize={16} fontWeight={'bold'} color={COLORS.gray500}>
                    🥳 Click!
                  </Typography>
                </div>
              </SuccessTooltipPopoverWrapper>
            </Fade>
          )}
        </Popper>
      </div>
    </Container>
  );
};
export default Onboarding;
