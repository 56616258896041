import styled from '@emotion/styled';
import { Button, Divider, FormControlLabel, IconButton, MenuItem, Popover, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Icons } from 'components';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { DATE_FORMAT_2, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { WeekDay, InRecurrence, RecurrenceMonthlyType } from 'queries/model';

interface RecurrenceSettingProps {
  date?: Date;
  defaultCustomRecurrence?: InRecurrence;
  onClose?: () => void;
  onChange?: (recurrence: InRecurrence) => void;
}

export const RecurrenceCustomDialog = ({ date, defaultCustomRecurrence, onClose, onChange }: RecurrenceSettingProps) => {
  const [intervalUnit, setIntervalUnit] = useState<string>(
    defaultCustomRecurrence?.intervalUnit === 'DAILY'
      ? '일'
      : defaultCustomRecurrence?.intervalUnit === 'WEEKLY'
      ? '주'
      : defaultCustomRecurrence?.intervalUnit === 'MONTHLY'
      ? '개월'
      : defaultCustomRecurrence?.intervalUnit === 'YEARLY'
      ? '년'
      : '주',
  );

  const [interval, setInterval] = useState<number>(defaultCustomRecurrence?.interval ? defaultCustomRecurrence.interval : 1);
  const [weekDays, setWeekDays] = useState<WeekDay[]>(
    defaultCustomRecurrence?.weekdays ? defaultCustomRecurrence.weekdays : ([dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[]),
  );
  const [recurrenceEnd, setRecurrenceEnd] = useState<string>(
    defaultCustomRecurrence?.endDate ? 'date' : defaultCustomRecurrence?.occurrences && defaultCustomRecurrence.occurrences !== 730 ? 'number' : 'none',
  );

  const [dayIndex, setDayIndex] = useState<number[]>([]);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date>(date!);
  const [datePopover, setDatePopover] = useState<HTMLElement | null>(null);
  const [recurrenceEndNumber, setRecurrenceEndNumber] = useState<number>(13);

  const selectableIntervalUnit = ['일', '주', '개월', '년'];
  const selectableRecurrenceDays = [
    { kr: '일', en: 'SU' },
    { kr: '월', en: 'MO' },
    { kr: '화', en: 'TU' },
    { kr: '수', en: 'WE' },
    { kr: '목', en: 'TH' },
    { kr: '금', en: 'FR' },
    { kr: '토', en: 'SA' },
  ];

  const [customMonth, setCustomMonth] = useState<string | undefined>(
    defaultCustomRecurrence?.monthlyType === 'day_of_month'
      ? `매월 ${dayjs(date).format('DD')}일`
      : defaultCustomRecurrence?.monthlyType === 'nth_weekday'
      ? `매월 ${defaultCustomRecurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`
      : defaultCustomRecurrence?.monthlyType === 'last_week_of_month'
      ? `매월 마지막 ${dayjs(date).format('dddd')}`
      : `매월 ${dayjs(date).format('DD')}일`,
  );
  const selectableRecurrenceMonth = [
    `매월 ${dayjs(date).format('DD')}일`,
    `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
    `매월 마지막 ${dayjs(date).format('dddd')}`,
  ];

  const handleIntervalNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isNaN(Number(e.target.value)) || e.target.value === '') setInterval(1);
    else setInterval(Number(e.target.value));
  };

  const handleIntervalNumberCountUp = () => {
    setInterval(interval + 1);
  };

  const handleIntervalNumberCountDown = () => {
    if (interval > 1) {
      setInterval(interval - 1);
    }
  };

  const handleChangeRecurrenceDay = (index: number) => {
    if (dayIndex.includes(index)) {
      dayIndex.splice(dayIndex.indexOf(index), 1);
    } else {
      dayIndex.push(index);
    }

    const sortDayIndex =
      dayIndex.length > 0
        ? dayIndex.sort((a, b) => a - b)
        : [selectableRecurrenceDays.findIndex((day) => day.en === dayjs(date).locale('en').format('dd').toUpperCase())];

    const indexList: string[] = [];

    sortDayIndex.forEach((item) => {
      const day = selectableRecurrenceDays.find((day, index) => index === item);
      if (day) {
        indexList.push(day.en as WeekDay);
      }
    });

    const weekday = indexList.length === 0 ? ([dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[]) : ([...indexList] as WeekDay[]);

    setDayIndex(sortDayIndex);
    setWeekDays(weekday);
  };

  const handleRecurrenceEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecurrenceEnd(e.target.value);
  };

  const handleCloseDatePopover = () => {
    setDatePopover(null);
  };

  const handleClickDate = (value: Dayjs | null) => {
    if (value) {
      setRecurrenceEndDate(dayjs(value).toDate());
    }
    setDatePopover(null);
  };

  const hadleRecurrenceEndNumberCountUp = () => {
    setRecurrenceEndNumber(recurrenceEndNumber + 1);
  };

  const handleRecurrenceEndNumberCountDown = () => {
    if (recurrenceEndNumber > 1) {
      setRecurrenceEndNumber(recurrenceEndNumber - 1);
    }
  };

  const handleCloseRecurrenceSetting = () => {
    onClose && onClose();
  };

  const handleSaveRecurrenceSetting = () => {
    let monthlyType: RecurrenceMonthlyType = 'day_of_month';
    if (intervalUnit === '개월') {
      const index = selectableRecurrenceMonth.findIndex((item) => item === customMonth);
      if (index === 0) {
        monthlyType = 'day_of_month';
      } else if (index === 1) {
        monthlyType = 'nth_weekday';
      } else {
        monthlyType = 'last_week_of_month';
      }
    }
    const recurrence: InRecurrence = {
      interval: interval,
      intervalUnit: intervalUnit === '주' ? 'WEEKLY' : intervalUnit === '개월' ? 'MONTHLY' : intervalUnit === '년' ? 'YEARLY' : 'DAILY',
      weekdays: intervalUnit === '주' ? weekDays : undefined,
      endDate: recurrenceEnd === 'date' ? dayjs(recurrenceEndDate).format(DATE_FORMAT_4) : undefined,
      occurrences: recurrenceEnd === 'number' ? recurrenceEndNumber : undefined,
      monthlyType: intervalUnit === '개월' ? monthlyType : undefined,
      monthlyTypeOrder: monthlyType === 'nth_weekday' ? dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1 : undefined,
    };

    onClose && onClose();
    onChange && onChange(recurrence);
  };

  useEffect(() => {
    if (defaultCustomRecurrence && defaultCustomRecurrence.weekdays) {
      const index: number[] = [];
      defaultCustomRecurrence.weekdays.forEach((item) => {
        index.push(selectableRecurrenceDays.findIndex((day) => day.en === item));
      });
      setDayIndex(index);
    } else {
      setDayIndex([selectableRecurrenceDays.findIndex((day) => day.en === dayjs(date).locale('en').format('dd').toUpperCase())]);
    }
  }, [defaultCustomRecurrence]);

  return (
    <RecurrenceContainer>
      <RecurrenceTitle>반복 설정</RecurrenceTitle>
      <Divider sx={{ width: '100%', margin: '12px 0px' }} />
      <RecurrenceCycle>
        <div style={{ fontSize: '13px', fontWeight: 700, marginRight: '12px' }}>반복 주기</div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
          <span style={{ fontSize: '13px', marginRight: '4px' }}>매</span>
          <TextField
            value={interval}
            onChange={(e) => {
              handleIntervalNumber(e);
            }}
            sx={{ width: '60px', height: '44px' }}
            inputProps={{ style: { textAlign: 'center', fontSize: '13px', padding: '0px', height: '44px' } }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2px' }}>
            <IconButton sx={{ padding: '6px' }} onClick={handleIntervalNumberCountUp}>
              <Icons.ArrowUpSmall />
            </IconButton>
            <IconButton sx={{ padding: '6px' }} onClick={handleIntervalNumberCountDown}>
              <Icons.ArrowDownSmall />
            </IconButton>
          </div>
        </div>
        <Select
          value={intervalUnit}
          onChange={(e) => setIntervalUnit(e.target.value)}
          style={{ width: '98px', height: 44, fontSize: '13px' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 372,
              },
            },
          }}
        >
          {selectableIntervalUnit.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span style={{ fontSize: '12px' }}>{v}</span>
            </MenuItem>
          ))}
        </Select>
      </RecurrenceCycle>
      {intervalUnit === '주' && (
        <RecurrenceDays>
          <RecurrenceDaysTitle style={{ fontSize: '13px', fontWeight: 700, marginRight: '12px' }}>반복 요일</RecurrenceDaysTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {selectableRecurrenceDays.map((day, index) => (
              <DayWrapper
                key={index}
                onClick={() => handleChangeRecurrenceDay(index)}
                style={dayIndex.includes(index) ? { fontSize: '12px', backgroundColor: COLORS.brand1, color: COLORS.white } : { fontSize: '12px' }}
              >
                {day.kr}
              </DayWrapper>
            ))}
          </div>
        </RecurrenceDays>
      )}
      {intervalUnit === '개월' && (
        <Select
          value={customMonth}
          onChange={(e) => setCustomMonth(e.target.value)}
          style={{ width: '135px', height: 44, fontSize: '13px', marginBottom: '16px' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 372,
              },
            },
          }}
        >
          {selectableRecurrenceMonth.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span style={{ fontSize: '12px' }}>{v}</span>
            </MenuItem>
          ))}
        </Select>
      )}
      <RecurrenceEnd>
        <RecurrenceEndTitle>종료</RecurrenceEndTitle>
        <RecurrenceEndSettings>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={recurrenceEnd}
            name="radio-buttons-group"
            onChange={(e) => {
              handleRecurrenceEnd(e);
            }}
          >
            <FormControlLabel value="none" control={<Radio />} label="없음" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel value="date" control={<Radio />} label="날짜" />
              <TextField
                value={recurrenceEnd === 'date' ? dayjs(recurrenceEndDate).format(DATE_FORMAT_2) : '지정 안함'}
                onClick={(e) => {
                  setDatePopover(e.currentTarget);
                }}
                disabled={recurrenceEnd !== 'date'}
                sx={recurrenceEnd !== 'date' ? { backgroundColor: COLORS.gray200, width: '178px', borderRadius: '8px' } : { width: '178px' }}
                InputProps={{
                  readOnly: true,
                  style: { height: '36px', padding: '0px', textAlign: 'center', fontSize: '13px', cursor: 'pointer' },
                }}
              />
            </div>
            <Popover
              open={Boolean(datePopover)}
              anchorEl={datePopover}
              onClose={handleCloseDatePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={recurrenceEndDate}
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => {
                  handleClickDate(dayjs(value));
                }}
              />
            </Popover>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel value="number" control={<Radio />} label="다음" />
              <TextField
                value={`${recurrenceEndNumber}회 반복`}
                disabled={recurrenceEnd !== 'number'}
                sx={recurrenceEnd !== 'number' ? { backgroundColor: COLORS.gray200, width: '178px', borderRadius: '8px' } : { width: '178px' }}
                InputProps={{
                  readOnly: true,
                  style: { height: '36px', padding: '0px', textAlign: 'center', fontSize: '13px', cursor: 'pointer' },
                }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2px' }}>
                <IconButton sx={{ padding: '2px' }} onClick={hadleRecurrenceEndNumberCountUp}>
                  <Icons.ArrowUpSmall />
                </IconButton>
                <IconButton sx={{ padding: '2px' }} onClick={handleRecurrenceEndNumberCountDown}>
                  <Icons.ArrowDownSmall />
                </IconButton>
              </div>
            </div>
          </RadioGroup>
        </RecurrenceEndSettings>
      </RecurrenceEnd>
      <ButtonWrapper>
        <Button
          variant="contained"
          disableElevation
          style={{ width: 138, height: 40, color: `${COLORS.black}`, backgroundColor: `${COLORS.gray100}`, marginRight: 8 }}
          onClick={handleCloseRecurrenceSetting}
        >
          취소하기
        </Button>
        <Button variant="contained" disableElevation style={{ width: 138, height: 40 }} onClick={handleSaveRecurrenceSetting}>
          저장하기
        </Button>
      </ButtonWrapper>
    </RecurrenceContainer>
  );
};

const RecurrenceContainer = styled.div`
  padding: 16px;
`;

const RecurrenceTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const RecurrenceCycle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const RecurrenceDays = styled.div`
  margin-bottom: 16px;
`;

const RecurrenceDaysTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-right: 12px;
  margin-bottom: 8px;
`;

const DayWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${COLORS.gray100};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const RecurrenceEnd = styled.div`
  margin-bottom: 24px;
`;

const RecurrenceEndTitle = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const RecurrenceEndSettings = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
