import styled from '@emotion/styled';
import { Button, TextField, Tooltip } from '@mui/material';
import { OutUserDetail } from 'queries/model';
import { COLORS } from 'styles/constants';

interface AccountProps {
  me?: OutUserDetail;
  userName?: string;
  onChangeName?: (name: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onOpenDeleteDialog?: () => void;
}

const AccountSetting = ({ me, userName, onChangeName = () => {}, onBlur, onKeyDown, onOpenDeleteDialog }: AccountProps) => {
  return (
    <Container>
      <MainHeaderText>개인정보설정</MainHeaderText>
      <UserInfoWrapper>
        <UserNameWrapper>
          <UserInfoText>이름</UserInfoText>
          <TextField fullWidth value={userName ? userName : ''} onChange={(e) => onChangeName(e.target.value)} onBlur={onBlur} onKeyDown={onKeyDown} />
        </UserNameWrapper>
        <UserProfile>
          <UserInfoText>프로필 사진</UserInfoText>
          <UserProfileImage>
            <ProfileImage>{me?.name ? <img src={me?.profileImage} /> : <EmptyProfileImage />}</ProfileImage>
            <Tooltip title={'준비중'}>
              <Button
                variant="outlined"
                sx={{
                  'color': COLORS.gray800,
                  'borderColor': COLORS.gray200,
                  'borderRadius': '8px',
                  'fontSize': '13px',
                  'fontWeight': 700,
                  'padding': '6px 12px',
                  '&:hover': { borderColor: COLORS.gray200 },
                }}
              >
                변경하기
              </Button>
            </Tooltip>
          </UserProfileImage>
        </UserProfile>
        <UserGoogle>
          <UserInfoText>구글 계정</UserInfoText>
          <UserGoogleEmail>{me?.email}</UserGoogleEmail>
        </UserGoogle>
      </UserInfoWrapper>
      <AccountDelete>
        <DeleteText>
          <div style={{ fontSize: '16px', fontWeight: 700 }}>계정 삭제하기</div>
          <div style={{ fontSize: '13px' }}>계정을 삭제하면 계정과 관련된 모든 데이터가 지워져요.</div>
        </DeleteText>
        <Button
          variant="contained"
          color="error"
          onClick={onOpenDeleteDialog}
          sx={{ padding: '6px 12px', borderRadius: '8px', fontSize: '13px', boxShadow: 'none', backgroundColor: COLORS.negative1 }}
        >
          탈퇴하기
        </Button>
      </AccountDelete>
    </Container>
  );
};

const Container = styled.div``;

const MainHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

const UserInfoWrapper = styled.div`
  width: 630px;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
  background-color: ${COLORS.white};
  margin-bottom: 24px;
`;

const UserInfoText = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const UserNameWrapper = styled.div``;

const UserProfile = styled.div`
  margin: 24px 0px;
`;

const UserProfileImage = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  cursor: pointer;
`;

const EmptyProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #abb0bf;
`;

const UserGoogle = styled.div``;

const UserGoogleEmail = styled.div`
  font-size: 13px;
`;

const AccountDelete = styled.div`
  padding: 16px 24px;
  width: 100%;
  background-color: ${COLORS.gray200};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteText = styled.div``;

export default AccountSetting;
