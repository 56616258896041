import React, { FC, useCallback } from 'react';
import { TaskListExtension } from '@remirror/extension-list';
import { useActive, useCommands } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';

export interface ToggleTaskListButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const ToggleTaskListButton: FC<ToggleTaskListButtonProps> = (props) => {
  const { toggleTaskList } = useCommands<TaskListExtension>();
  const onClose = props.onClose;

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (toggleTaskList.enabled()) {
      toggleTaskList();
    }
  }, [toggleTaskList]);

  const active = useActive<TaskListExtension>().taskList();
  const enabled = toggleTaskList.enabled();

  return <CommandButton {...props} commandName="체크리스트" active={active} enabled={enabled} onSelect={handleSelect} />;
};
