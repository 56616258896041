import styled from '@emotion/styled';
import { Button, Divider, Drawer, IconButton, Popover, Skeleton, Stack, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import {
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  createCategoryV1CategoryPost,
  createProjectV1ProjectPost,
  createWorkboxV2WorkboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet,
  getAllProjectV1ProjectGet,
  getAProjectV1ProjectProjectIdGet,
  getARoutineV1RoutineRoutineIdGet,
  listRoutineV1RoutineGet,
  orderProjectV1ProjectProjectIdOrderPatch,
  updateCategoryV1CategoryCategoryIdPatch,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
  updateProjectV1ProjectProjectIdPatch,
} from 'queries';
import { OutCategory, OutFeatureStorage, OutProject, OutRoutine, OutTaskboxDetailProjectOnly, OutTaskboxDetailResponse } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'lib/dayjs';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { useAtom, useSetAtom } from 'jotai';
import { userAtom } from 'atoms/user';
import { hideScroll } from 'styles/utils';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { categoryAtom } from 'atoms/category';
import { quickTaskPopupVisibleAtom } from 'atoms/popup';
import { QuickTaskInputPopup } from 'pages/Task';
import { tasksAtom } from 'atoms/tasks';
import { useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { dragContextAtom } from 'atoms/works';
import ProductionPlan from 'pages/Beta/ProductionPlan';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { projectsAtom, selectedProjectAtom } from 'atoms/projects';
import SidePanel from './components/SidePanel';
import RoutineCreationView from './components/RoutineCreationView';
import TaskWeek from 'pages/Task/Week';
import { routinesAtom, selectedRoutineAtom } from 'atoms/routine';
import RoutineView from './components/RoutineView';

type projectTask = {
  id: string;
  content?: string;
  dueDate?: string;
  done?: boolean;
  doneAt?: string;
};

export type ProjectStatus = 'DONE' | 'HOLD' | 'CANCEL' | 'UNLOCK';

interface DeskIssue {
  id: string;
  content?: string;
  dueDate?: string;
  done?: boolean;
  doneAt?: string;
  tasks?: projectTask[];
  status?: string;
}

export const Desk = () => {
  const [render, setRender] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [inputTitle, setInputTitle] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [deskProjects, setDeskProjects] = useState<OutProject[]>([]);
  const [cabinetProjects, setCabinetProjects] = useState<OutProject[]>([]);
  const [archiveProjects, setArchiveProjects] = useState<OutProject[]>([]);
  const [user] = useAtom(userAtom);
  const [isOpen, setIsOpen] = useAtom(quickTaskPopupVisibleAtom);
  const [showTooltip, setShowTooltip] = useState(true);
  const updateTasks = useSetAtom(tasksAtom);
  const [dragContext, setDragContext] = useAtom(dragContextAtom);
  const [intro, setIntro] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [quickCategory, setQuickCategory] = useState<OutCategory | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [quickDeadline, setQuickDeadline] = useState<string | null>(null);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [projects] = useAtom(projectsAtom);
  const [projectFeature, setProjectFeature] = useState<OutFeatureStorage | null>(null);
  const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);

  useEffect(() => {
    fetchProjectFeature();
    localStorage.getItem('desk-expanded-status') && setExpanded(JSON.parse(localStorage.getItem('desk-expanded-status') as string));
    fetchRoutineList();
    routines.length > 0 && projects.length === 0 && setTabValue(1);
  }, []);

  useEffect(() => {
    fetchProject();
    if (projectFeature && !projectFeature.data.create && projects.length) {
      handleUpdateProjectFeature(projectFeature);
    }
  }, [projects]);

  useKeyboardEvent(
    true,
    (ev) => {
      const isQuickTaskCmd = (ev.metaKey || ev.ctrlKey) && ev.key === '/';
      const isTaskboardInputCmd = !ev.metaKey && !ev.ctrlKey && ev.key === '/';
      if (!isQuickTaskCmd && !isTaskboardInputCmd) return;

      if (isQuickTaskCmd) {
        setShowTooltip(false);
        setIsOpen(true);
      }

      if (isTaskboardInputCmd) {
        const element = document.activeElement;

        if (element) {
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
        }

        const taskView = document.getElementById('task-view');
        const taskBoard = document.querySelector(`[data-board-id="${dayjs().format('YYYY-MM-DD')}"]`) as HTMLDivElement;
        taskView?.scrollTo({ top: taskBoard?.offsetTop - 12, behavior: 'auto' });

        setTimeout(() => {
          document.getElementById('taskboard-input')?.focus();
        }, 100);
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const fetchProjectFeature = async () => {
    const feature = await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('project');
    setProjectFeature(feature);
  };

  const fetchProject = async (suppress?: boolean) => {
    const data = await getAllProjectV1ProjectGet();
    if (data.length) {
      setIntro(false);
      localStorage.setItem('project-data', JSON.stringify(true));
    } else {
      localStorage.setItem('project-data', JSON.stringify(false));
    }
    setDeskProjects(data.filter((item) => item.place === 'DESK'));
    setCabinetProjects(data.filter((item) => item.place === 'DRAWER_MOVED' || item.place === 'DRAWER_CREATED'));
    setArchiveProjects(data.filter((item) => item.place === 'ARCHIVE'));
    setRender(true);

    if (selectedProject) {
      if (suppress) {
        setSelectedProject(null);
        return;
      }
      const project = data.find((item) => item.id === selectedProject.id);
      if (project) setSelectedProject(project);
    }
  };

  const handleUpdateProjectFeature = async (feature: OutFeatureStorage) => {
    const data = {
      data: {
        create: true,
      },
    };
    await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(feature.id, data);
  };

  const handleCreateTask = async (taskbox: OutTaskboxDetailResponse) => {
    if (taskbox) {
      await createWorkboxV2WorkboxesPost({ id: uuidv4(), title: taskbox.title! });
      toast.success('새로운 태스크를 생성하였습니다.');
      updateTasks();
    }
  };

  const handlePopupClose = () => {
    setIsOpen(false);
    setShowTooltip(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInputTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputTitle(event.target.value);
  };

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      if (inputTitle) {
        refInput.current?.blur();
        return;
      }
      e.preventDefault();
      refInput.current?.blur();
      setIsFocus(false);
      if (quickCategory) setQuickCategory(null);
      if (quickDeadline) setQuickDeadline(null);
    }

    if (e.key === 'Enter') {
      if (!inputTitle) return;
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      try {
        await createProjectV1ProjectPost({
          id: uuidv4(),
          title: inputTitle,
          place: 'DESK',
          categoryIds: quickCategory ? [quickCategory.id] : undefined,
          due: quickDeadline ? quickDeadline : null,
        });

        if (deskProjects.length >= 7) {
          toast.success('데스크 개수 제한이 초과되어 서랍에 새로운 프로젝트가 추가되었습니다.');
        } else {
          toast.success('새로운 프로젝트를 생성하였습니다.');
        }
        fetchProject();
        setInputTitle('');
        if (quickCategory) setQuickCategory(null);
        if (quickDeadline) setQuickDeadline(null);
        if (projectFeature && !projectFeature.data.create) handleUpdateProjectFeature(projectFeature);
      } catch (error) {
        toast.error('프로젝트 생성에 실패하였습니다.');
      }
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleKeydownProject = async (e: React.KeyboardEvent<HTMLDivElement>, projectId: string) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const title = e.currentTarget.textContent || '';
      const success = await updateProjectV1ProjectProjectIdPatch(projectId, { title });
      if (success) toast.success('프로젝트 제목을 수정하였습니다.');
    }
  };

  const handleClickProjectCheck = async (projectId: string, status: ProjectStatus) => {
    switch (status) {
      case 'DONE':
        {
          try {
            await updateProjectV1ProjectProjectIdPatch(projectId, { done: true, place: 'ARCHIVE' });
            toast.success('완료한 프로젝트를 아카이브로 옮겼습니다.');
          } catch (error) {
            toast.error('프로젝트 완료에 실패하였습니다.');
          }
        }
        break;
      case 'HOLD': {
        try {
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: 'DRAWER_MOVED' });
          toast.success('홀드된 프로젝트는 서랍으로 이동되었습니다.');
        } catch (error) {
          toast.error('프로젝트 홀드에 실패하였습니다.');
        }
        break;
      }
      case 'CANCEL': {
        try {
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: 'ARCHIVE' });
          toast.success('취소된 프로젝트는 아카이브로 이동되었습니다.');
        } catch (error) {
          toast.error('프로젝트 취소에 실패하였습니다.');
        }
        break;
      }
      case 'UNLOCK': {
        try {
          const project = archiveProjects.find((item) => item.id === projectId);
          await updateProjectV1ProjectProjectIdPatch(projectId, { place: deskProjects.length >= 7 ? 'DRAWER_MOVED' : 'DESK', done: false });
          if (deskProjects.length >= 7) {
            toast.success('데스크의 개수 제한이 초과되어 프로젝트가 서랍으로 이동되었습니다.');
          } else {
            toast.success('프로젝트가 데스크로 이동되었습니다.');
          }
        } catch (error) {
          toast.error('프로젝트 해제에 실패하였습니다.');
        }
        break;
      }
    }
    fetchProject();
  };

  const handleSelectProject = async (project: OutProject) => {
    const targetProject = await getAProjectV1ProjectProjectIdGet(project.id);
    if (targetProject) {
      setSelectedProject(targetProject);
    }
  };

  const handleDropInput = async () => {
    if (!dragContext) return;

    try {
      await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(dragContext.id!);
      if (deskProjects.length >= 7) {
        toast.success('데스크 개수 제한이 초과되어 서랍에 새로운 프로젝트가 추가되었습니다.');
      } else {
        toast.success('새로운 프로젝트를 생성하였습니다.');
      }
    } catch {
      toast.error('새로운 프로젝트를 생성할 수 없습니다.');
    }

    setIsDragOver(false);
    setDragContext(null);
    fetchProject();
    updateTasks();
  };

  const handleDropToProject = async (projectId: string) => {
    if (!dragContext) return;
    await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(projectId, { taskIds: [dragContext.id!] });
    setIsDragOver(false);
    setDragContext(null);
    fetchProject();
    updateTasks();
  };

  const handleStartProject = () => {
    setIntro(false);
  };

  const [foldProjectDrawer, setFoldProjectDrawer] = useState(false);
  const handleClickFoldProjectDrawer = () => {
    setFoldProjectDrawer(!foldProjectDrawer);
  };

  const handleBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    if (result.source.droppableId === 'desk-project') {
      if (result.destination.droppableId === 'desk-project') {
        const newProject = deskProjects.filter((item) => item.id !== result.draggableId);
        newProject.splice(result.destination.index, 0, deskProjects[result.source.index]);
        setDeskProjects([...newProject]);
        if (result.source.index < result.destination.index) {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: deskProjects[result.destination.index].id,
              place: 'DESK',
            });
          } catch (error) {
            toast.error('프로젝트 순서 변경에 실패하였습니다.');
          }
        } else {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskProjects[result.destination.index - 1].id,
              place: 'DESK',
            });
          } catch (error) {
            toast.error('프로젝트 순서 변경에 실패하였습니다.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'cabinet-project') {
        const newDeskProject = deskProjects.filter((item) => item.id !== result.draggableId);
        cabinetProjects.splice(result.destination.index, 0, deskProjects[result.source.index]);
        setDeskProjects([...newDeskProject]);
        setCabinetProjects([...cabinetProjects]);

        try {
          const success = await updateProjectV1ProjectProjectIdPatch(result.draggableId, { place: 'DRAWER_MOVED' });
          if (success) {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
              place: 'DRAWER',
            });
          }
        } catch (error) {
          toast.error('프로젝트 순서 변경에 실패하였습니다.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'cabinet-project') {
      if (result.destination.droppableId === 'cabinet-project') {
        const newProject = cabinetProjects.filter((item) => item.id !== result.draggableId);
        newProject.splice(result.destination.index, 0, cabinetProjects[result.source.index]);
        setCabinetProjects([...newProject]);
        if (result.source.index < result.destination.index) {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: cabinetProjects[result.destination.index].id,
              place: 'DRAWER',
            });
          } catch (error) {
            toast.error('프로젝트 순서 변경에 실패하였습니다.');
          }
        } else {
          try {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
              place: 'DRAWER',
            });
          } catch (error) {
            toast.error('프로젝트 순서 변경에 실패하였습니다.');
          }
        }
        return;
      } else if (result.destination.droppableId === 'desk-project') {
        if (deskProjects.length >= 7) {
          toast.error('데스크가 가득 찼습니다.');
          return;
        }

        const newCabinetProject = cabinetProjects.filter((item) => item.id !== result.draggableId);
        const moveToDeskProject = cabinetProjects[result.source.index];
        moveToDeskProject.place = 'DESK';
        deskProjects.splice(result.destination.index, 0, moveToDeskProject);
        setCabinetProjects([...newCabinetProject]);
        setDeskProjects([...deskProjects]);

        try {
          const success = await updateProjectV1ProjectProjectIdPatch(result.draggableId, { place: 'DESK' });
          if (success) {
            await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
              beforeId: result.destination.index - 1 < 0 ? undefined : deskProjects[result.destination.index - 1].id,
              place: 'DESK',
            });
          }
        } catch (error) {
          toast.error('프로젝트 순서 변경에 실패하였습니다.');
        }
        return;
      }
    }

    if (result.source.droppableId === 'archive-project') {
      const newProject = archiveProjects.filter((item) => item.id !== result.draggableId);
      newProject.splice(result.destination.index, 0, archiveProjects[result.source.index]);
      setArchiveProjects([...newProject]);

      // if (result.source.index < result.destination.index) {
      //   try {
      //     await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
      //       beforeId: cabinetProjects[result.destination.index].id,
      //       place: 'ARCHIVE',
      //     });
      //   } catch (error) {
      //     toast.error('프로젝트 순서 변경에 실패하였습니다.');
      //   }
      // } else {
      //   try {
      //     await orderProjectV1ProjectProjectIdOrderPatch(result.draggableId, {
      //       beforeId: result.destination.index - 1 < 0 ? undefined : cabinetProjects[result.destination.index - 1].id,
      //       place: 'ARCHIVE',
      //     });
      //   } catch (error) {
      //     toast.error('프로젝트 순서 변경에 실패하였습니다.');
      //   }
      // }
      return;
    }
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          setQuickCategory(category);

          if (inputTitle.includes('#')) {
            setInputTitle(inputTitle.replace(/#/g, ''));
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error('카테고리를 생성할 수 없습니다.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            fetchProject();
          } catch (e) {
            toast.error('카테고리를 삭제할 수 없습니다.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            fetchProject();
          } catch (e) {
            toast.error('카테고리를 수정할 수 없습니다.');
          }
        }
        break;
    }
  };

  const handleChangeDeadline = (date: Dayjs) => {
    setTimeout(() => {
      setDeadlineAnchorEl(null);
    }, 100);
    setQuickDeadline(date.toISOString());
    if (inputTitle.includes('$')) {
      setInputTitle(inputTitle.replace(/\$/g, ''));
    }
  };

  const projectData = localStorage.getItem('project-data');

  const [routines] = useAtom(routinesAtom);
  const [testRoutineList, setTestRoutineList] = useState<OutRoutine[]>([]);
  const [openRoutineCreation, setOpenRoutineCreation] = useState(false);
  const handleCloseRoutineCreation = () => {
    fetchRoutineList();
    setOpenRoutineCreation(false);
  };

  useEffect(() => {
    setTestRoutineList(routines);
    const targetRoutine = routines.find((item) => item.id === selectedRoutine?.id);
    if (selectedRoutine && targetRoutine) {
      setSelectedRoutine(targetRoutine);
    }
  }, [routines]);

  const fetchRoutineList = async () => {
    // setDeskProjects(data.filter((item) => item.place === 'DESK'));
    // setCabinetProjects(data.filter((item) => item.place === 'DRAWER_MOVED' || item.place === 'DRAWER_CREATED'));
    // setArchiveProjects(data.filter((item) => item.place === 'ARCHIVE'));
    // setRender(true);

    const data = await listRoutineV1RoutineGet();

    if (data.length) setIntro(false);
    setTestRoutineList(data);
    if (selectedRoutine) {
      const targetRoutine = data.find((item) => item.id === selectedRoutine.id);
      if (targetRoutine) {
        setSelectedRoutine(targetRoutine);
      }
    }
  };

  const handleSelectRoutine = async (routine: OutRoutine) => {
    const targetRoutine = testRoutineList.find((item) => item.id === routine.id);
    if (targetRoutine) {
      setSelectedRoutine(targetRoutine);
    }
  };

  return (
    <>
      {render ? (
        <>
          {intro ? (
            <ProductionPlan suppressBeta onStart={handleStartProject} />
          ) : (
            <Container
              onClick={() => {
                setSelectedProject(null);
                setSelectedRoutine(null);
              }}
            >
              <PlanViewWrapper>
                <PlanHeader>{user?.name}님의 중요한 업무는 무엇인가요?</PlanHeader>
                <TabPanelHeader>
                  <Tabs value={tabValue} onChange={handleChangeTab}>
                    <Tooltip
                      placement="top-start"
                      title={
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
                            <Icons.Wand />
                            <span style={{ marginLeft: 4 }}> Magic number 7</span>
                          </div>
                          <div style={{ margin: '8px 0px 8px 0px' }}>인간이 단기간 기억할 수 있는 아이템의 개수는 7개라고 합니다.</div>
                          <div>현재 중요한 일만 책상에 두고 처리해보세요.</div>
                        </div>
                      }
                      disableInteractive
                    >
                      <Tab
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>프로젝트</span>
                            <span style={{ color: deskProjects.length === 7 ? COLORS.negative1 : COLORS.gray400, margin: '0px 4px 0px 4px' }}>
                              ({deskProjects.length}/7)
                            </span>
                            <Icons.QuestionInfo fill={COLORS.gray400} />
                          </div>
                        }
                      />
                    </Tooltip>
                    <Tab label="루틴" />
                    <Tab label="아카이브" />
                  </Tabs>
                </TabPanelHeader>
                <TabPanel hidden={tabValue !== 0}>
                  <ProjectViewWrapper>
                    {dragContext ? (
                      <CreateProjectDropWrapper
                        dragOver={isDragOver}
                        onDrop={handleDropInput}
                        onDragOver={(e) => {
                          e.preventDefault();
                          setIsDragOver(true);
                        }}
                        onDragLeave={() => setIsDragOver(false)}
                      >
                        <Icons.DropZone stroke={isDragOver ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '4px' }}> 여기로 드래그해서 프로젝트로 만들어보세요</span>
                      </CreateProjectDropWrapper>
                    ) : (
                      <CreateProjectWrapper>
                        <IconButton
                          onClick={() => refInput.current?.focus()}
                          sx={{
                            'padding': '0px',
                            ':hover': {
                              backgroundColor: COLORS.white,
                            },
                          }}
                        >
                          <Icons.Plus height={16} width={16} fill={COLORS.gray400} />
                        </IconButton>
                        <TextField
                          inputRef={refInput}
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          value={inputTitle}
                          placeholder={isFocus ? '프로젝트를 입력해주세요' : '새로운 프로젝트 입력하기'}
                          onKeyDown={handleKeydownInput}
                          onChange={handleInputTitle}
                          onFocus={() => setIsFocus(true)}
                          onBlur={() => setIsFocus(false)}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              fontSize: 16,
                              fontWeight: 700,
                              color: COLORS.gray900,
                              marginLeft: 8,
                              height: 24,
                            },
                          }}
                          sx={{ flex: 1 }}
                        />
                        {quickCategory && (
                          <CategoryShowingWrapper textColor={getCategoryTextColor(quickCategory.color)} bgColor={getCategoryBgColor(quickCategory.color)}>
                            {`# ${quickCategory.name}`}
                          </CategoryShowingWrapper>
                        )}
                        {quickDeadline && (
                          <DeadlineShowingWrapper date={quickDeadline}>
                            <Icons.Flag
                              fill={dayjs(quickDeadline).isToday() ? COLORS.brand1 : dayjs(quickDeadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                            />
                            <span style={{ marginLeft: '2px' }}>
                              {dayjs(quickDeadline).isToday()
                                ? '오늘'
                                : dayjs(quickDeadline).isYesterday()
                                ? '어제'
                                : dayjs(quickDeadline).isTomorrow()
                                ? '내일'
                                : dayjs(quickDeadline).format('M월 D일 (dd)')}
                            </span>
                          </DeadlineShowingWrapper>
                        )}
                        {isFocus && !quickCategory && !quickDeadline && (
                          <CategoryDeadlineInfoPlaceholder>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '16px',
                                  height: '16px',
                                  borderRadius: '2px',
                                  backgroundColor: COLORS.gray100,
                                  marginRight: '4px',
                                }}
                              >
                                #
                              </div>
                              <span style={{ marginRight: '4px' }}>으로 카테고리 지정</span>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '16px',
                                  height: '16px',
                                  borderRadius: '2px',
                                  backgroundColor: COLORS.gray100,
                                  marginRight: '4px',
                                }}
                              >
                                $
                              </div>
                              <span>으로 기한 지정</span>
                            </div>
                          </CategoryDeadlineInfoPlaceholder>
                        )}
                      </CreateProjectWrapper>
                    )}
                    <DragDropContext onDragEnd={handleBeautifulDragEnd}>
                      <ProjectViewScroll>
                        <Droppable droppableId="desk-project">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {deskProjects.length > 0 ? (
                                deskProjects.map((project, index) => (
                                  <Draggable key={project.id} draggableId={project.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <ProjectView
                                            key={project.id}
                                            project={project}
                                            onKeydownProject={handleKeydownProject}
                                            onClickProjectCheck={handleClickProjectCheck}
                                            onSelectProject={handleSelectProject}
                                            onFetch={fetchProject}
                                            onDrop={handleDropToProject}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))
                              ) : (
                                <EmptyDestWrapper>
                                  <Icons.Desk />
                                  <EmptyDeskTitle>
                                    <Fonts.H5 style={{ color: COLORS.gray800, marginBottom: '4px' }}>현재 집중할 프로젝트는 무엇인가요?</Fonts.H5>
                                    <Fonts.Blockquote style={{ color: COLORS.gray500 }}>데스크에서 한 주 계획이나 중요한 일을 관리해보세요.</Fonts.Blockquote>
                                  </EmptyDeskTitle>
                                </EmptyDestWrapper>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="cabinet-project">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {cabinetProjects.length > 0 && tabValue !== 1 && (
                                <CabinetContainer>
                                  <CabinetHeader>
                                    <div style={{ width: '231px', height: '1px', borderTop: `1px dashed ${COLORS.gray300}` }} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Tooltip
                                        title={
                                          <div>
                                            <div style={{ fontWeight: 700 }}>중요한 일이 너무 많다면?</div>
                                            <div>
                                              잠시 대기 중인 프로젝트, 상대적으로 주의력을
                                              <br />
                                              적게 요구하는 프로젝트는 서랍에서 관리해보세요.
                                            </div>
                                          </div>
                                        }
                                        disableInteractive
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'default' }}>
                                          <Icons.Cabinet />
                                          <div style={{ fontWeight: 700, fontSize: '13px', margin: '0px 4px' }}>서랍</div>
                                          <div style={{ fontWeight: 700, fontSize: '13px', color: COLORS.gray400 }}>{`(${cabinetProjects.length})`}</div>
                                        </div>
                                      </Tooltip>
                                      <Tooltip title={foldProjectDrawer ? '서랍 열기' : '서랍 접기'}>
                                        <IconButton
                                          onClick={handleClickFoldProjectDrawer}
                                          sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '0px' }}
                                        >
                                          {foldProjectDrawer ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <div style={{ width: '231px', height: '1px', borderTop: `1px dashed ${COLORS.gray300}` }} />
                                  </CabinetHeader>
                                  {cabinetProjects.length > 0 &&
                                    !foldProjectDrawer &&
                                    cabinetProjects.map((project, index) => (
                                      <Draggable key={project.id} draggableId={project.id} index={index}>
                                        {(provided) => {
                                          const style = {
                                            width: '100%',
                                            marginBottom: '8px',
                                            ...provided.draggableProps.style,
                                          };
                                          return (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                              <ProjectView
                                                key={project.id}
                                                project={project}
                                                exceed={deskProjects.length >= 7}
                                                onKeydownProject={handleKeydownProject}
                                                onClickProjectCheck={handleClickProjectCheck}
                                                onSelectProject={handleSelectProject}
                                                onFetch={fetchProject}
                                                onDrop={handleDropToProject}
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                </CabinetContainer>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </ProjectViewScroll>
                    </DragDropContext>
                  </ProjectViewWrapper>
                </TabPanel>
                <TabPanel hidden={tabValue !== 1}>
                  <RoutineViewWrapper>
                    <div style={{ width: 582, marginBottom: 32 }}>
                      <Button
                        variant="contained"
                        startIcon={<Icons.Plus width={16} height={16} fill={COLORS.gray900} />}
                        onClick={() => setOpenRoutineCreation(true)}
                        sx={{
                          'backgroundColor': COLORS.white,
                          'borderRadius': '8px',
                          'color': COLORS.gray900,
                          'boxShadow': 'none',
                          'fontSize': '13px',
                          'fontWeight': 700,
                          ':hover': {
                            backgroundColor: COLORS.gray200,
                          },
                        }}
                      >
                        새로운 루틴 만들기
                      </Button>
                    </div>
                    <DragDropContext onDragEnd={handleBeautifulDragEnd}>
                      <RoutineViewScroll>
                        <Droppable droppableId="desk-routine">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {testRoutineList.length > 0 ? (
                                testRoutineList.map((routine, index) => (
                                  <Draggable key={routine.id} draggableId={routine.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <RoutineView routine={routine} onSelectRoutine={handleSelectRoutine} />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))
                              ) : (
                                <EmptyRoutineWrapper>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M53.2234 2.17129C51.5432 0.598904 48.9064 0.686304 47.334 2.3665C45.7617 4.0467 45.849 6.68344 47.5292 8.25582L53.8138 14.1371L50.0272 14.1136C49.8747 14.1127 49.7241 14.1199 49.5757 14.135C27.5841 14.3561 9.37521 31.3709 7.70896 53.3512C6.03169 75.4722 21.6857 95.1506 43.6167 98.4935C65.5503 101.839 86.3564 87.7162 91.3465 66.0992C91.8641 63.857 90.466 61.6197 88.2238 61.1021C85.9816 60.5845 83.7443 61.9826 83.2267 64.2248C79.2169 81.5952 62.4979 92.9435 44.8732 90.2554L44.8728 90.2554C27.2497 87.5691 14.6707 71.7559 16.0184 53.9812L16.0185 53.9811C17.3663 36.2015 32.1826 22.4661 50.0095 22.4661C50.1365 22.4661 50.2621 22.4605 50.3861 22.4494L54.2468 22.4733L47.0947 29.4364C45.4459 31.0417 45.4106 33.6797 47.0159 35.3285C48.6211 36.9773 51.2591 37.0126 52.9079 35.4073L67.3412 21.3552C68.1571 20.5609 68.6123 19.4673 68.6011 18.3287C68.5899 17.19 68.1131 16.1056 67.2817 15.3275L53.2234 2.17129Z"
                                      fill="url(#paint0_linear_26864_287505)"
                                      fillOpacity="0.3"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M96.7924 24.0357C98.3662 25.7145 98.2812 28.3513 96.6024 29.9252L54.9357 68.9877C53.333 70.4902 50.8389 70.4902 49.2362 68.9877L28.4029 49.4564C26.7241 47.8825 26.639 45.2457 28.2129 43.5669C29.7868 41.8881 32.4236 41.8031 34.1024 43.3769L52.086 60.2365L90.9029 23.8457C92.5817 22.2718 95.2185 22.3569 96.7924 24.0357Z"
                                      fill="url(#paint1_linear_26864_287505)"
                                      fillOpacity="0.3"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_26864_287505"
                                        x1="49.5201"
                                        y1="1.04687"
                                        x2="49.5201"
                                        y2="98.9831"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#02CB8F" />
                                        <stop offset="1" stopColor="#0CB0BB" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint1_linear_26864_287505"
                                        x1="62.5026"
                                        y1="22.7187"
                                        x2="62.5026"
                                        y2="70.1146"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#02CB8F" />
                                        <stop offset="1" stopColor="#0CB0BB" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                  <div style={{ color: COLORS.gray800, fontSize: 12, fontWeight: 700, marginTop: 20 }}>내 중심을 잡아줄 루틴은 무엇인가요?</div>
                                  <div style={{ color: COLORS.gray500, fontSize: 12, marginTop: 4 }}>
                                    루틴으로 급하지 않지만 중요한 일들을 꾼주히 관리해보세요.
                                  </div>
                                </EmptyRoutineWrapper>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="cabinet-routine">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {cabinetProjects.length > 0 && tabValue !== 1 && (
                                <CabinetContainer>
                                  <CabinetHeader>
                                    <div style={{ width: '231px', height: '1px', borderTop: `1px dashed ${COLORS.gray300}` }} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Tooltip
                                        title={
                                          <div>
                                            <div style={{ fontWeight: 700 }}>중요한 일이 너무 많다면?</div>
                                            <div>
                                              잠시 대기 중인 프로젝트, 상대적으로 주의력을
                                              <br />
                                              적게 요구하는 프로젝트는 서랍에서 관리해보세요.
                                            </div>
                                          </div>
                                        }
                                        disableInteractive
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px', cursor: 'default' }}>
                                          <Icons.Cabinet />
                                          <div style={{ fontWeight: 700, fontSize: '13px', margin: '0px 4px' }}>서랍</div>
                                          <div style={{ fontWeight: 700, fontSize: '13px', color: COLORS.gray400 }}>{`(${cabinetProjects.length})`}</div>
                                        </div>
                                      </Tooltip>
                                      <Tooltip title={foldProjectDrawer ? '서랍 열기' : '서랍 접기'}>
                                        <IconButton
                                          onClick={handleClickFoldProjectDrawer}
                                          sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '0px' }}
                                        >
                                          {foldProjectDrawer ? <Icons.ArrowDownSmall /> : <Icons.ArrowUpSmall />}
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <div style={{ width: '231px', height: '1px', borderTop: `1px dashed ${COLORS.gray300}` }} />
                                  </CabinetHeader>
                                  {cabinetProjects.length > 0 &&
                                    !foldProjectDrawer &&
                                    cabinetProjects.map((project, index) => (
                                      <Draggable key={project.id} draggableId={project.id} index={index}>
                                        {(provided) => {
                                          const style = {
                                            width: '100%',
                                            marginBottom: '8px',
                                            ...provided.draggableProps.style,
                                          };
                                          return (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                              <ProjectView
                                                key={project.id}
                                                project={project}
                                                exceed={deskProjects.length >= 7}
                                                onKeydownProject={handleKeydownProject}
                                                onClickProjectCheck={handleClickProjectCheck}
                                                onSelectProject={handleSelectProject}
                                                onFetch={fetchProject}
                                                onDrop={handleDropToProject}
                                              />
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                </CabinetContainer>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </RoutineViewScroll>
                    </DragDropContext>
                  </RoutineViewWrapper>
                </TabPanel>
                <TabPanel hidden={tabValue !== 2}>
                  <DragDropContext onDragEnd={handleBeautifulDragEnd}>
                    <ProjectViewScroll isArchive={true}>
                      <Droppable droppableId="archive-project">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            <ArchieveWrapper>
                              {archiveProjects.length > 0 ? (
                                archiveProjects.map((project, index) => (
                                  <Draggable key={project.id} draggableId={project.id} index={index}>
                                    {(provided) => {
                                      const style = {
                                        width: '100%',
                                        marginBottom: '8px',
                                        ...provided.draggableProps.style,
                                      };
                                      return (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
                                          <ProjectView
                                            key={project.id}
                                            project={project}
                                            exceed={deskProjects.length >= 7}
                                            onKeydownProject={handleKeydownProject}
                                            onClickProjectCheck={handleClickProjectCheck}
                                            onSelectProject={handleSelectProject}
                                            onFetch={fetchProject}
                                            onDrop={handleDropToProject}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                ))
                              ) : (
                                <EmptyArchiveWrapper>
                                  <Fonts.H5>완료, 취소된 프로젝트가 표시됩니다.</Fonts.H5>
                                  {/* <Fonts.Blockquote>완료된 이슈는 완료 후 7일이 지나면 자동으로 아카이브로 옮겨집니다.</Fonts.Blockquote> */}
                                </EmptyArchiveWrapper>
                              )}
                            </ArchieveWrapper>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ProjectViewScroll>
                  </DragDropContext>
                </TabPanel>
              </PlanViewWrapper>
              <SidePanelWrapper>
                <SidePanel />
              </SidePanelWrapper>
              <RoutineCreationView open={openRoutineCreation} onClose={handleCloseRoutineCreation} />
              {isOpen && <QuickTaskInputPopup isOpen={isOpen} onClose={handlePopupClose} onCreate={handleCreateTask} showTooltip={showTooltip} />}
              <Popover
                open={Boolean(categoryAnchorEl)}
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setCategoryAnchorEl(null)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{ marginTop: '4px' }}
              >
                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
              </Popover>
              <Popover
                open={Boolean(deadlineAnchorEl)}
                anchorEl={deadlineAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setDeadlineAnchorEl(null)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{ marginTop: '4px' }}
              >
                <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
              </Popover>
            </Container>
          )}
        </>
      ) : (
        <>
          {intro && projectData === 'false' ? (
            <Stack spacing={1} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Skeleton variant="rounded" animation="wave" width={320} height={32} style={{ marginTop: '20px' }} />
              <Skeleton variant="rounded" animation="wave" width={260} height={32} />
              <Skeleton variant="rounded" animation="wave" width={450} height={24} style={{ marginTop: '12px' }} />
              <Skeleton variant="rounded" animation="wave" width={98} height={40} style={{ marginTop: '32px' }} />
              <Skeleton variant="rounded" animation="wave" width={830} height={460} style={{ marginTop: '32px' }} />
            </Stack>
          ) : (
            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
              <div style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
                <Skeleton variant="rounded" animation="wave" width={357} height={36} style={{ width: 582 }} />
                <div style={{ width: 582, display: 'flex', alignItems: 'center', margin: '35px 0px' }}>
                  <Skeleton variant="rounded" animation="wave" width={113} height={24} style={{ marginRight: 4 }} />
                  <Skeleton variant="rounded" animation="wave" width={113} height={24} />
                </div>
                <Skeleton variant="rounded" animation="wave" width={582} height={102} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={582} height={102} style={{ marginTop: 8 }} />
              </div>
              <Divider orientation="vertical" sx={{ height: '100%' }} />
              <div style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="rounded" animation="wave" width={148} height={24} style={{ marginRight: 12 }} />
                  <Skeleton variant="rounded" animation="wave" width={148} height={24} />
                </div>
                <div style={{ width: '308px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0px 12px 0px' }}>
                  <Skeleton variant="rounded" animation="wave" width={67} height={24} style={{ marginRight: 12 }} />
                  <Skeleton variant="rounded" animation="wave" width={24} height={24} />
                </div>
                <Skeleton variant="rounded" animation="wave" width={308} height={36} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
                <Skeleton variant="rounded" animation="wave" width={308} height={36} style={{ marginTop: 8 }} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${COLORS.gray100};
`;

const PlanViewWrapper = styled.div`
  width: 100%;
  min-height: 649px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const PlanHeader = styled.div`
  width: 582px;
  font-size: 24px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-bottom: 24px;
`;

const TabPanelHeader = styled.div`
  width: 582px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabPanel = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const ProjectViewScroll = styled.div<{ isArchive?: boolean }>`
  width: 100%;
  height: calc(100vh - 254px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${hideScroll()}

  ${(props) =>
    props.isArchive &&
    css`
      height: calc(100vh - 180px);
    `}
`;

const ProjectViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyDestWrapper = styled.div`
  width: 582px;
  height: 360px;
  border: 1px dashed ${COLORS.gray400};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyDeskTitle = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const ArchieveWrapper = styled.div``;

const EmptyArchiveWrapper = styled.div`
  width: 582px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${COLORS.gray400};
  height: 240px;
  border-radius: 8px;
`;

const CreateProjectDropWrapper = styled.div<{ dragOver?: boolean }>`
  width: 582px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  border-radius: 8px;
  color: ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  margin-bottom: 24px;
  padding: 12px;
  font-size: 13px;
`;

const CreateProjectWrapper = styled.div`
  width: 582px;
  display: flex;
  align-items: center;
  border: 1px solid ${COLORS.gray200};
  background-color: ${COLORS.white};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 12px;
`;

const SidePanelWrapper = styled.div`
  border-left: 1px solid ${COLORS.gray400};
`;

const CategoryDeadlineInfoPlaceholder = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  margin-right: 8px;
`;

const RoutineViewScroll = styled.div`
  width: 100%;
  height: calc(100vh - 254px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  ${hideScroll()}
`;

const RoutineViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RoutineWrapper = styled.div``;

const EmptyRoutineWrapper = styled.div`
  width: 582px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${COLORS.gray400};
  border-radius: 8px;
`;

interface ProjectViewProps {
  project?: OutProject;
  exceed?: boolean;
  onKeydownProject?: (e: React.KeyboardEvent<HTMLDivElement>, projectId: string) => void;
  onClickProjectCheck?: (projectId: string, status: ProjectStatus) => void;
  onSelectProject?: (project: OutProject) => void;
  onFetch?: () => void;
  onDrop?: (projectId: string) => void;
}

const ProjectView = ({
  project,
  exceed = false,
  onKeydownProject = () => {},
  onClickProjectCheck = () => {},
  onSelectProject = () => {},
  onFetch = () => {},
  onDrop = () => {},
}: ProjectViewProps) => {
  const [projectStatusPopover, setProjectStatusPopover] = useState<HTMLElement | null>();
  const [projectSubtasks, setProjectSubtasks] = useState<OutTaskboxDetailProjectOnly[]>(project?.tasks || []);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedProject] = useAtom(selectedProjectAtom);

  useEffect(() => {
    setProjectSubtasks(project!.tasks!);
  }, [project]);

  const handleClickProjectCheck = async (status: ProjectStatus) => {
    onClickProjectCheck(project!.id, status);
    setProjectStatusPopover(null);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!project) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            await updateProjectV1ProjectProjectIdPatch(project.id!, { categoryIds: category ? [category.id] : [] });
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 선택할 수 없습니다.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error('카테고리를 생성할 수 없습니다.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 삭제할 수 없습니다.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 수정할 수 없습니다.');
          }
        }
        break;
    }
  };

  const handleChangeDeadline = async (date: Dayjs | null) => {
    if (!project) return;
    setDeadlineAnchorEl(null);
    try {
      await updateProjectV1ProjectProjectIdPatch(project.id!, { due: date ? date.format('YYYY-MM-DD') : null });
      onFetch && onFetch();
    } catch (e) {
      toast.error('기한을 설정할 수 없습니다.');
    }
  };

  const handleDropToProject = async (projectId: string) => {
    onDrop(projectId);
    setIsDragOver(false);
  };

  const isInstance = project && project.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK').length > 0;

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(e) => {
        if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragOver(false);
      }}
      style={{ width: '100%' }}
    >
      <div hidden={isDragOver} className="project-card">
        <ProjectContainer
          key={project!.id}
          isFocus={selectedProject?.id === project?.id}
          done={project?.status === 'DONE'}
          onClick={(e) => {
            e.stopPropagation();
            onSelectProject(project!);
          }}
        >
          {isInstance && <ProjectLine />}
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0px 4px', marginLeft: '8px' }}>
            <div>
              <ProjectHeader>
                {project && project.category && (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(project.category.color)}
                    bgColor={getCategoryBgColor(project.category.color)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategoryAnchorEl(e.currentTarget);
                    }}
                  >
                    {`# ${project.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(project.category!.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} fill={getCategoryTextColor(project.category!.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                )}
                {project?.due && (
                  <DeadlineShowingWrapper
                    date={project.due}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeadlineAnchorEl(e.currentTarget);
                    }}
                  >
                    <Icons.Flag
                      fill={dayjs(project.due).isToday() ? COLORS.brand1 : dayjs(project.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                    />
                    <span style={{ marginLeft: '2px' }}>
                      {dayjs(project.due).isToday()
                        ? '오늘'
                        : dayjs(project.due).isYesterday()
                        ? '어제'
                        : dayjs(project.due).isTomorrow()
                        ? '내일'
                        : dayjs(project.due).format('M월 D일 (dd)')}
                    </span>
                    <IconButton
                      className="deadline-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDeadline(null);
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(
                            dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600,
                            0.3,
                          ),
                        },
                      }}
                      style={deadlineAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close
                        width={8}
                        height={8}
                        fill={dayjs(project!.due).isToday() ? COLORS.brand1 : dayjs(project!.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                      />
                    </IconButton>
                  </DeadlineShowingWrapper>
                )}
              </ProjectHeader>
              <ProjectContent>
                {project?.place === 'DESK' && (
                  <PriorityIssueCheckbox
                    onClick={(e) => {
                      e.stopPropagation();
                      setProjectStatusPopover(e.currentTarget);
                    }}
                    checked={project.done}
                    bordercolor={COLORS.issue2}
                    width={20}
                    height={20}
                  />
                )}
                {(project?.place === 'DRAWER_CREATED' || project?.place === 'DRAWER_MOVED') && (
                  <Tooltip title={exceed ? '데스크가 가득 찼습니다' : '홀드 해제'} disableInteractive>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        exceed ? toast.error('데스크가 가득 찼습니다.') : onClickProjectCheck(project!.id, 'UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueHold width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                )}
                {project?.place === 'ARCHIVE' && project?.done && (
                  <Tooltip title={'완료 해제'} disableInteractive>
                    <IconButton
                      disableRipple
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickProjectCheck(project!.id, 'UNLOCK');
                      }}
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}
                    >
                      <Icons.DeskIssueCheck />
                    </IconButton>
                  </Tooltip>
                )}
                {project?.place === 'ARCHIVE' && !project?.done && (
                  <Tooltip title={'취소 해제'} disableInteractive>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickProjectCheck(project!.id, 'UNLOCK');
                      }}
                      disableRipple
                      sx={{ width: '20px', height: '20px', padding: '0px', backgroundColor: COLORS.issue2 }}
                    >
                      <Icons.DeskIssueCancel width={12} height={12} />
                    </IconButton>
                  </Tooltip>
                )}
                <ProjectContentTitle contentEditable={false} suppressContentEditableWarning={true} onKeyDown={(e) => onKeydownProject(e, project!.id)}>
                  {project!.title}
                </ProjectContentTitle>
              </ProjectContent>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {project && project.memo && (
                <div>
                  <Icons.TaskboxMemo width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {project && project.links && project.links.length > 0 && (
                <div style={{ marginLeft: 4 }}>
                  <Icons.Link2 width={18} height={18} stroke={COLORS.gray500} />
                </div>
              )}
              {projectSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length > 0 && (
                <ProjectSubtask>
                  <Icons.Subtask width={18} height={18} stroke={COLORS.gray500} />
                  <div style={{ color: COLORS.gray500, marginLeft: '4px', marginTop: 2 }}>
                    {projectSubtasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length}
                  </div>
                </ProjectSubtask>
              )}
            </div>
          </div>
          {projectStatusPopover && (
            <Popover
              open={Boolean(projectStatusPopover)}
              anchorEl={projectStatusPopover}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{ marginTop: 0.5 }}
              onClose={() => setProjectStatusPopover(null)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ProjectStatus>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('DONE');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCheck />
                  </IconButton>
                  완료
                </ProjectStatusDetail>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('HOLD');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueHold />
                  </IconButton>
                  홀드
                </ProjectStatusDetail>
                <ProjectStatusDetail
                  onClick={() => {
                    handleClickProjectCheck('CANCEL');
                  }}
                >
                  <IconButton disableRipple sx={{ width: '16px', height: '16px', padding: '0px', backgroundColor: COLORS.issue2, marginRight: '8px' }}>
                    <Icons.DeskIssueCancel />
                  </IconButton>
                  취소
                </ProjectStatusDetail>
              </ProjectStatus>
            </Popover>
          )}
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setCategoryAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
          <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setDeadlineAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={project && project.due ? dayjs(project!.due).toDate() : dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
          </Popover>
        </ProjectContainer>
      </div>
      <ProjectDropContainer hidden={!isDragOver} onDrop={() => handleDropToProject(project!.id)} />
    </div>
  );
};

const ProjectDropContainer = styled.div`
  width: 582px;
  height: 102px;
  background-color: ${COLORS.sub5};
  border-radius: 8px;
  border: 1px dashed ${COLORS.sub4};
`;

const ProjectContainer = styled.div<{ done?: boolean; isFocus?: boolean }>`
  width: 582px;
  height: 102px;
  display: flex;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid ${COLORS.gray200};
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 20px 1px ${COLORS.gray200};

    .project-detail {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isFocus &&
    css`
      border: 1px solid ${COLORS.issue2};
    `}

  ${(props) =>
    props.done &&
    css`
      opacity: 0.6;
    `}
`;

const ProjectLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.issue2};
  border-radius: 100px;
`;

const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  position: relative;
`;

const ProjectContentTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.gray900};
  margin-left: 8px;
`;

const ProjectSubtask = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 4px;
`;

const CabinetContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CabinetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ProjectStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
`;

const ProjectStatusDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px 8px 16px;
  font-size: 12px;
  width: 80px;
  height: 32px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;
