import { setCaretToEnd } from 'utils/moveCaret';

export const truncate = (width?: string) => {
  return `
    width: ${width || 'auto'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
};

export const hideScroll = () => {
  return `
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    ::-webkit-scrollbar {
      display: none; 
    }
  `;
};

export const setFocusElement = (query: string) => {
  setTimeout(() => {
    const el = document.querySelector(query);
    el && setCaretToEnd(el);
  }, 50);
};
