import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Popover, TextField, Tooltip } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { OutCategory, OutFrequentWork } from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import TaskBlockInputList, { TaskBlockHandle } from './TaskBlockInputList';
import { TemplateType } from '../Week';
import CategoryPopover, { CategoryActionType } from './CategoryPopover';
import { set } from 'lodash';

interface TemplateDialogProps {
  open?: boolean;
  templateList?: OutFrequentWork[];
  categoryList?: OutCategory[];
  onClose?: () => void;
  onPut?: (template: OutFrequentWork) => void;
  onDelete?: (template: OutFrequentWork) => void;
  onAdd?: (template: OutFrequentWork) => void;
  onChange?: (template: OutFrequentWork, type: TemplateType) => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
}

const TemplateDialog = ({
  open = false,
  templateList = [],
  categoryList = [],
  onClose = () => {},
  onPut = () => {},
  onDelete = () => {},
  onAdd = () => {},
  onChange = () => {},
  onClickCategory = () => {},
}: TemplateDialogProps) => {
  const [template, setTemplate] = useState<OutFrequentWork | null>(null);

  const handleClickTemplate = (template: OutFrequentWork) => {
    setTemplate(template);
  };

  const handleDeleteTemplate = async (template: OutFrequentWork) => {
    onDelete(template);
    onChange(template, 'DELETE_TEMPLATE');
    await new Promise((resolve) => setTimeout(resolve, 300));
    setTemplate(null);
  };

  const handleChangeTaskBlocks = (tasks: { id: string; content?: string; category?: OutCategory; deadine?: string }[] = []) => {
    setTemplate({ ...template, tasks: tasks });
  };

  const handleClickPrevButton = async () => {
    onPut(template!);
    onChange(template!, 'UPDATE_TEMPLATE');
    await new Promise((resolve) => setTimeout(resolve, 100));
    setTemplate(null);
  };

  const handleClose = async () => {
    onClose();
    if (template) {
      onPut(template!);
      onChange(template!, 'UPDATE_TEMPLATE');
    }
    await new Promise((resolve) => setTimeout(resolve, 100));
    setTemplate(null);
  };

  const handleTemplateToTaskbox = async (template: OutFrequentWork) => {
    onPut(template);
    onAdd(template);
    onChange(template, 'LOAD_TEMPLATE');
    onChange(template, 'UPDATE_TEMPLATE');
    await new Promise((resolve) => setTimeout(resolve, 100));
    setTemplate(null);
  };

  const handleChangeTemplate = (template: OutFrequentWork) => {
    setTemplate(template);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      {template ? (
        <TemplateContainer>
          <TemplateInput
            template={template}
            categoryList={categoryList}
            onDelete={handleDeleteTemplate}
            onAdd={handleTemplateToTaskbox}
            onChangeTemplate={handleChangeTemplate}
            onChangeTaskBlocks={handleChangeTaskBlocks}
            onClickPrevButton={handleClickPrevButton}
            onClickCategory={onClickCategory}
          />
        </TemplateContainer>
      ) : (
        <TemplateContainer>
          <TemplateTitle>템플릿</TemplateTitle>
          {templateList.length === 0 ? (
            <TemplateEmptyWrapper>
              <TemplateEmptyTitle>
                <Icons.Template width={32} height={32} />
                <div style={{ margin: '8px 0px 4px 0px', color: COLORS.gray700, fontSize: '12px', fontWeight: 700 }}>
                  자주하는 업무를 템플릿으로 저장해보세요
                </div>
              </TemplateEmptyTitle>

              <TemplateEmptyImage>
                <img src={require('assets/images/template.png')} style={{ border: `1px solid ${COLORS.gray200}`, borderRadius: '8px' }} />
              </TemplateEmptyImage>
            </TemplateEmptyWrapper>
          ) : (
            <TemplateScroll>
              {templateList.map((item) => (
                <TemplateContent
                  key={item.id}
                  onClick={() => {
                    handleClickTemplate(item);
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icons.Template fill={COLORS.brand1} />
                    <TemplateContentTitle>{item.title}</TemplateContentTitle>
                    {item.tasks!.length > 0 && (
                      <TemplateSubtask>
                        <Icons.Subtask stroke={COLORS.gray500} />
                        <TemplateSubtaskLength>{item.tasks?.length}</TemplateSubtaskLength>
                      </TemplateSubtask>
                    )}
                  </div>
                </TemplateContent>
              ))}
            </TemplateScroll>
          )}
        </TemplateContainer>
      )}
    </Dialog>
  );
};

export default TemplateDialog;

const TemplateContainer = styled.div`
  width: 540px;
  height: 450px;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
`;

const TemplateTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 0px 24px 0px 24px;
  margin-bottom: 12px;
`;

const TemplateEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  height: 100%;
`;

const TemplateEmptyTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TemplateEmptyImage = styled.div`
  padding: 0px 24px;
`;

const TemplateScroll = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const TemplateContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  height: 36px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
    .frequent-detail {
      opacity: 1;
    }
  }
`;

const TemplateContentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0px 8px;
`;

const TemplateSubtask = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
`;

const TemplateSubtaskLength = styled.div`
  margin-top: 2px;
  margin-left: 4px;
  font-size: 13px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-left: 8px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

interface TemplateInputProps {
  template?: OutFrequentWork;
  categoryList?: OutCategory[];
  onPut?: (template: OutFrequentWork) => void;
  onAdd?: (template: OutFrequentWork) => void;
  onDelete?: (template: OutFrequentWork) => void;
  onChangeTemplate?: (template: OutFrequentWork) => void;
  onChangeTaskBlocks?: (tasks: { id: string; content?: string; dueDate?: string }[]) => void;
  onClickPrevButton?: (template: OutFrequentWork) => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
}

const TemplateInput = ({
  template,
  categoryList = [],
  onAdd = () => {},
  onDelete = () => {},
  onChangeTemplate = () => {},
  onChangeTaskBlocks = () => {},
  onClickPrevButton = () => {},
  onClickCategory = () => {},
}: TemplateInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const [templateDetail, setTemplateDetail] = useState<HTMLElement | null>(null);
  const [deleteTemplate, setDeleteTemplate] = useState(false);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      refTaskBlockList?.current?.add();
    }
  };

  const handleBlurInput = () => {
    if (!refInput.current) return;
    onChangeTemplate({ ...template!, title: refInput.current.value });
  };

  const handleCloseTemplatePopover = () => {
    setTemplateDetail(null);
  };

  const handleTemplateTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeTemplate({ ...template!, title: e.target.value });
  };

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    onClickCategory && onClickCategory(category, action);
  };

  return (
    <TemplateDialogWrapper>
      <div>
        <TemplatePrev onClick={() => onClickPrevButton(template!)}>
          <Icons.ArrowLeftSmall fill={COLORS.gray400} />
          <span style={{ fontSize: '12px', color: COLORS.gray500, marginLeft: '4px', marginTop: '1px' }}>뒤로</span>
        </TemplatePrev>
        <TemplateToDo>
          <TextField
            inputRef={refInput}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder={'자주 할 일을 입력해주세요'}
            onKeyDown={handleKeydownInput}
            onBlur={handleBlurInput}
            onChange={(e) => {
              handleTemplateTitle(e);
            }}
            defaultValue={template ? template?.title : ''}
            InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
            style={{ marginLeft: 8 }}
          />
          {template?.id && (
            <IconButton onClick={(e) => setTemplateDetail(e.currentTarget)} style={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}>
              <Icons.More width={16} height={16} fill={COLORS.gray300} />
            </IconButton>
          )}
        </TemplateToDo>
        <div style={{ padding: '0px 28px 0px 10px' }}>
          <TaskBlockInputList
            ref={refTaskBlockList}
            categoryList={categoryList}
            suppressCheckboxToggle
            suppressTemplate
            suppressDeadline
            tasks={template?.tasks || []}
            onChange={onChangeTaskBlocks}
            onClickCategory={handleClickCategory}
          />
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <Button
          variant="contained"
          onClick={() => {
            onAdd(template!);
          }}
          sx={{ height: '40px', fontSize: '13px', fontWeight: 700, borderRadius: '8px', padding: '10px 16px' }}
        >
          추가하기
        </Button>
      </div>
      <Popover
        open={Boolean(templateDetail)}
        anchorEl={templateDetail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseTemplatePopover}
        sx={{ marginTop: '4px' }}
      >
        <DetailModal>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              setDeleteTemplate(true);
            }}
            style={{ color: `${COLORS.negative1}` }}
          >
            <Icons.Delete fill={COLORS.negative1} />
            <span style={{ marginLeft: '8px' }}>삭제</span>
          </Fonts.Blockquote>
        </DetailModal>
      </Popover>
      <Dialog
        open={deleteTemplate}
        onClose={() => {
          setDeleteTemplate(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}`, textAlign: 'center' }}>
          템플릿을 삭제하시겠어요?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            관련 데이터를 모두 삭제합니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteTemplate(false);
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              setDeleteTemplate(false);
              setTemplateDetail(null);
              setTimeout(() => {
                onDelete(template!);
              }, 200);
            }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </TemplateDialogWrapper>
  );
};

const TemplateDialogWrapper = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TemplatePrev = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-left: 24px;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
  }
`;

const TemplateToDo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0px 24px;
`;

const DetailModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .detail-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

/** */
