import styled from '@emotion/styled';
import { Button, IconButton, Modal, TextField } from '@mui/material';
import { Icons } from 'components';
import { useState } from 'react';
import { COLORS } from 'styles/constants';
import { css } from '@emotion/react';
import { PriorityIssueCheck, PriorityIssueUncheck } from 'components/Icons';
import { RecordUserSurveyV1SurveysSurveyIdPostBody } from 'queries/model';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiInputBase-input {
    font-size: 13px;
  }
  .MuiInputBase-root {
    padding: 14px 16px;
  }
`;

const SurveyWrapper = styled.div`
  width: 480px;
  height: 356px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.white};
  border-radius: 16px;
  padding: 24px;
`;

const Section = styled.div`
  width: 100%;
`;

const SurveyHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SurveyHeaderTitle = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: bold;
`;

const SurveyBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SurveyBodyTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
const SurveyBodySubTitle = styled.div`
  font-size: 13px;
  margin-top: 8px;
`;

const SurveyFooter = styled.div``;

const SurveyFooterLater = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

const RequiredIcon = styled.div`
  width: 16px;
  height: 16px;
  font-size: 14px;
  background-color: ${COLORS.gray100};
  border-radius: 50%;
  text-align: center;
  margin-left: 4px;
`;

const RecommendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Gray_200, #e7eaf4);
  background: #f2f5fc;
`;

const ScoreWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  background-color: ${COLORS.white};
`;

const ScoreNumber = styled.div<{ select?: boolean }>`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  color: ${COLORS.gray500};
  justify-content: center;
  padding: 4px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.sub3};
  }
  ${(props) =>
    props.select &&
    css`
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.sub2};
      color: ${COLORS.sub2};
    `}
`;

const ScoreText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
`;

const DisappointmentWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  > div:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const DisappointmentContent = styled.div<{ select?: boolean }>`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  font-size: 13px;
  padding: 12px;

  &:hover {
    background-color: ${COLORS.sub3};
  }

  ${(props) =>
    props.select &&
    css`
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.sub2};
    `}
`;

const DisappointmentImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const DisappointmentText = styled.div`
  text-align: center;
`;

interface PMFSurveyProps {
  open?: boolean;
  onClose?: () => void;
  onClickLater?: () => void;
  onClickSubmit?: (survey?: RecordUserSurveyV1SurveysSurveyIdPostBody) => void;
}

const PMFSurvey = ({ open = false, onClose, onClickLater, onClickSubmit }: PMFSurveyProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleClickNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handelClickPrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const scoreList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [selectedScore, setSelectedScore] = useState(0);
  const handleClickScore = (score: number) => {
    setSelectedScore(score);
  };

  const disappointmentList = [
    { image: require('assets/images/VeryDisappointment.png'), text: '매우 실망할 것이다.' },
    { image: require('assets/images/Disappointment.png'), text: '어느 정도 실망할 것이다.' },
    { image: require('assets/images/NotDisappointment.png'), text: '실망하지 않을 것이다.' },
  ];
  const [selectedDisappointment, setSelectedDisappointment] = useState(0);
  const handleClickDisappointment = (disappointment: number) => {
    setSelectedDisappointment(disappointment);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleClickLater = () => {
    onClickLater && onClickLater();
  };

  const handleClickSubmit = () => {
    const survey = [
      { score: selectedScore },
      { disappointment: selectedDisappointment === 1 ? 'very disappointed' : selectedDisappointment === 2 ? 'disappointed' : 'not disappointed' },
      ...(isAhamomentSurvey ? [{ ahamoment: userAhamoment }] : []), // Conditionally add ahamoment
      { satisfaction: userSatisfaction },
      { improvement: userImprovement },
    ];

    onClickSubmit && onClickSubmit(survey);
    handleClickNext();
  };

  const [userAhamoment, setUserAhamoment] = useState('');
  const handleChangeUserAhamoment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserAhamoment(e.target.value);
  };

  const [userSatisfaction, setUserSatisfaction] = useState('');
  const handleChangeUserSatisfaction = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSatisfaction(e.target.value);
  };

  const [userImprovement, setUserImprovement] = useState('');
  const handleChangeUserImprovement = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserImprovement(e.target.value);
  };

  const isAhamomentSurvey = selectedScore === 10 || selectedDisappointment === 1;

  return (
    <Modal open={open} onClose={handleClose}>
      <Container>
        {activeStep === 0 && (
          <SurveyWrapper>
            <SurveyHeader>
              <div style={{ width: '20px', height: '20px' }} />
              <SurveyHeaderTitle>💖&nbsp;&nbsp;&nbsp;3분도 안걸려요!</SurveyHeaderTitle>
              <div style={{ width: '20px', height: '20px' }} />
            </SurveyHeader>
            <SurveyBody>
              <SurveyBodyTitle>딱 4개 질문만 답변해주세요</SurveyBodyTitle>
              <SurveyBodySubTitle>내게 꼭 맞는 업무도구를 만드는 데에 동참해봐요.</SurveyBodySubTitle>
            </SurveyBody>
            <SurveyFooter>
              <Button
                variant="contained"
                onClick={handleClickNext}
                sx={{ width: '192px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                🙌&nbsp;&nbsp;&nbsp;동참할게요
              </Button>
              <SurveyFooterLater>
                <div style={{ fontSize: '12px', marginRight: '9px' }}>바쁘신가요?</div>
                <Button
                  sx={{ 'fontSize': '12px', 'padding': '0px', 'minWidth': '0px', '&:hover': { backgroundColor: COLORS.white } }}
                  onClick={handleClickLater}
                >
                  나중에 하기
                </Button>
              </SurveyFooterLater>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 1 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <SurveyBodyTitle style={{ fontSize: '13px' }}>동료나 친구들에게 SLASH를 추천할 의향이 있으신가요?</SurveyBodyTitle>
                  <RequiredIcon>*</RequiredIcon>
                </div>
                <RecommendWrapper>
                  <ScoreWrapper>
                    {scoreList.map((score) => (
                      <ScoreNumber key={score} onClick={() => handleClickScore(score)} select={score === selectedScore}>
                        {score}
                      </ScoreNumber>
                    ))}
                  </ScoreWrapper>
                  <ScoreText>
                    <div>거의 없다</div>
                    <div>매우 있다</div>
                  </ScoreText>
                </RecommendWrapper>
              </SurveyBody>
            </Section>
            <SurveyFooter>
              <Button
                variant="contained"
                disabled={selectedScore === 0}
                onClick={handleClickNext}
                sx={{ width: '192px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                다음
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {activeStep === 2 && (
          <SurveyWrapper>
            <Section>
              <SurveyHeader>
                <div style={{ width: '20px', height: '20px' }} />
                <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                <div style={{ width: '20px', height: '20px' }} />
              </SurveyHeader>
              <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <SurveyBodyTitle style={{ fontSize: '13px' }}>만약 내일부터 SLASH를 사용하지 못한다면 어떻게 느끼실까요?</SurveyBodyTitle>
                  <RequiredIcon>*</RequiredIcon>
                </div>
                <DisappointmentWrapper>
                  {disappointmentList.map((disappointment, index) => (
                    <DisappointmentContent key={index} onClick={() => handleClickDisappointment(index + 1)} select={index + 1 === selectedDisappointment}>
                      {index + 1 === selectedDisappointment ? <PriorityIssueCheck /> : <PriorityIssueUncheck />}
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <DisappointmentImage src={disappointment.image} />
                        <DisappointmentText>{disappointment.text}</DisappointmentText>
                      </div>
                    </DisappointmentContent>
                  ))}
                </DisappointmentWrapper>
              </SurveyBody>
            </Section>
            <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handelClickPrev}
                sx={{
                  'width': '204px',
                  'height': '48px',
                  'borderRadius': '8px',
                  'padding': '14px 16px',
                  'fontWeight': 'bold',
                  'boxShadow': 'none',
                  'color': COLORS.gray900,
                  'backgroundColor': COLORS.gray100,
                  '&:hover': { backgroundColor: COLORS.gray200 },
                }}
              >
                이전
              </Button>
              <Button
                variant="contained"
                disabled={selectedDisappointment === 0}
                onClick={handleClickNext}
                sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
              >
                다음
              </Button>
            </SurveyFooter>
          </SurveyWrapper>
        )}
        {isAhamomentSurvey ? (
          <>
            {activeStep === 3 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <SurveyBodyTitle style={{ fontSize: '13px' }}>SLASH를 계속 써야겠다고 마음을 먹은 순간은 언제인가요?</SurveyBodyTitle>
                      <RequiredIcon>*</RequiredIcon>
                    </div>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userAhamoment}
                      placeholder="이건 정말 필요하다고 느꼈던 때를 설명해주세요."
                      onChange={handleChangeUserAhamoment}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    이전
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!userAhamoment}
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    다음
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 4 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <SurveyBodyTitle style={{ fontSize: '13px' }}>SLASH의 어떤 점이 만족스러우신가요?</SurveyBodyTitle>
                      <RequiredIcon>*</RequiredIcon>
                    </div>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userSatisfaction}
                      placeholder="어떤 상황의 어떤 사람에게 추천해줄 것 같다고 서술해주셔도 좋습니다."
                      onChange={handleChangeUserSatisfaction}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    이전
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!userSatisfaction}
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    다음
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 5 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`추가 질문`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>SLASH의 어떤 점을 개선하면 좋을까요?</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userImprovement}
                      placeholder="이 기능이 지원되면 더 잘 쓸 것 같다는 점을 써주셔도 좋습니다."
                      onChange={handleChangeUserImprovement}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    이전
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickSubmit}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    완료하기
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 6 && (
              <SurveyWrapper style={{ justifyContent: 'center' }}>
                <SurveyBody>
                  <div
                    style={{
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '32px',
                      backgroundColor: COLORS.sub3,
                      borderRadius: '50%',
                    }}
                  >
                    ☺️
                  </div>
                  <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>감사합니다!</SurveyBodyTitle>
                  <div>소중한 의견 참고해 한 걸음 더 진화할게요🔥</div>
                </SurveyBody>
              </SurveyWrapper>
            )}
          </>
        ) : (
          <>
            {activeStep === 3 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>SLASH의 어떤 점이 만족스러우신가요?</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userSatisfaction}
                      placeholder="어떤 상황의 어떤 사람에게 추천해줄 것 같다고 서술해주셔도 좋습니다."
                      onChange={handleChangeUserSatisfaction}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    이전
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickNext}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    다음
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 4 && (
              <SurveyWrapper>
                <Section>
                  <SurveyHeader>
                    <div style={{ width: '20px', height: '20px' }} />
                    <SurveyHeaderTitle style={{ color: COLORS.brand1 }}>{`질문 ${activeStep}/4`}</SurveyHeaderTitle>
                    <div style={{ width: '20px', height: '20px' }} />
                  </SurveyHeader>
                  <SurveyBody style={{ alignItems: 'flex-start', marginTop: '32px' }}>
                    <SurveyBodyTitle style={{ fontSize: '13px', marginBottom: '8px' }}>SLASH의 어떤 점을 개선하면 좋을까요?</SurveyBodyTitle>
                    <TextField
                      multiline
                      minRows={5.2}
                      maxRows={5.2}
                      value={userImprovement}
                      placeholder="이 기능이 지원되면 더 잘 쓸 것 같다는 점을 써주셔도 좋습니다."
                      onChange={handleChangeUserImprovement}
                      sx={{ width: '100%' }}
                    />
                  </SurveyBody>
                </Section>
                <SurveyFooter style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={handelClickPrev}
                    sx={{
                      'width': '204px',
                      'height': '48px',
                      'borderRadius': '8px',
                      'padding': '14px 16px',
                      'fontWeight': 'bold',
                      'boxShadow': 'none',
                      'color': COLORS.gray900,
                      'backgroundColor': COLORS.gray100,
                      '&:hover': { backgroundColor: COLORS.gray200 },
                    }}
                  >
                    이전
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClickSubmit}
                    sx={{ width: '204px', height: '48px', borderRadius: '8px', padding: '14px 16px', fontWeight: 'bold', boxShadow: 'none' }}
                  >
                    완료하기
                  </Button>
                </SurveyFooter>
              </SurveyWrapper>
            )}
            {activeStep === 5 && (
              <SurveyWrapper style={{ justifyContent: 'center' }}>
                <SurveyBody>
                  <div
                    style={{
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '32px',
                      backgroundColor: COLORS.sub3,
                      borderRadius: '50%',
                    }}
                  >
                    ☺️
                  </div>
                  <SurveyBodyTitle style={{ margin: '40px 0px 4px 0px' }}>감사합니다!</SurveyBodyTitle>
                  <div>소중한 의견 참고해 한 걸음 더 진화할게요🔥</div>
                </SurveyBody>
              </SurveyWrapper>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default PMFSurvey;
