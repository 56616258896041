import { SVGProps } from 'react';

export const CalendarWeek = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.33594 6.67187H16.6693M3.33594 6.67187V14.0054C3.33594 14.9388 3.33594 15.4053 3.51759 15.7618C3.67738 16.0754 3.93216 16.3306 4.24577 16.4904C4.60194 16.6719 5.06843 16.6719 6.00003 16.6719H14.0052C14.9368 16.6719 15.4026 16.6719 15.7588 16.4904C16.0724 16.3306 16.328 16.0754 16.4878 15.7618C16.6693 15.4056 16.6693 14.9398 16.6693 14.0082V6.67187M3.33594 6.67187V6.00537C3.33594 5.07195 3.33594 4.60489 3.51759 4.24837C3.67738 3.93477 3.93216 3.67999 4.24577 3.5202C4.60229 3.33854 5.06935 3.33854 6.00277 3.33854H6.66927M16.6693 6.67187V6.00263C16.6693 5.07104 16.6693 4.60454 16.4878 4.24837C16.328 3.93477 16.0724 3.67999 15.7588 3.5202C15.4022 3.33854 14.9362 3.33854 14.0028 3.33854H13.3359M6.66927 3.33854H13.3359M6.66927 3.33854V1.67188M13.3359 3.33854V1.67188M13.3359 10.0052H6.66927"
        stroke="#1F2023"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 18.875C17.364 18.875 18.875 17.364 18.875 15.5C18.875 13.636 17.364 12.125 15.5 12.125C13.636 12.125 12.125 13.636 12.125 15.5C12.125 17.364 13.636 18.875 15.5 18.875Z"
        fill="#1F2023"
        stroke="white"
        strokeWidth="0.83"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.5 13.8125V16.0625H16.625" stroke="white" strokeWidth="0.83" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
