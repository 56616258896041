import TextField, { TextFieldProps } from '@mui/material/TextField';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Avatar,
  Box,
  Button,
  Chip,
  DialogContentText,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  PopperProps,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Alarm, Close, People, Map as MapIcon } from 'components/Icons';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { getAllCalendarV1CalendarGet, getMembersV1UsersMembersGet, getUserMeV1UsersMeGet } from 'queries';
import {
  CreateEventWithParam,
  TransparencyEnum,
  VisibilityEnum,
  OutEvent,
  OutUserDetail,
  OutCalendar,
  OutCategory,
  CreateAttendee,
  ReadCreator,
  ReadAttendee,
  OutReadEvent,
  AttendantResponseStatusEnum,
  InRecurrence,
  WeekDay,
  CreateEventWithParamRecurrence,
  ReadOrganizer,
} from 'queries/model';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DATE_FORMAT_1, DATE_FORMAT_3, TIME_FORMAT_2, DATE_FORMAT_4, DATE_FORMAT_7, DATE_FORMAT_2 } from 'utils/datetimeFormat';
import dayjs from 'lib/dayjs';
import toast from 'react-hot-toast';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import styled from '@emotion/styled';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { useClickOutside, useUpdateEffect } from '@react-hookz/web';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { useAtom } from 'jotai';
import { categoryAtom } from 'atoms/category';
import { userAtom } from 'atoms/user';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import { RecurrenceCustomDialog } from 'components/Recurrence/RecurrenceCustomDialog';
import { isEqual } from 'lodash';
import { StaticDatePicker } from 'components/StaticDatePicker';

const Container = styled.div`
  width: 540px;
  padding: 16px;
`;

const ModifyAlarmWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${COLORS.negative2};
  border-radius: 8px;
  color: ${COLORS.negative1};
  font-size: 10px;
  margin-bottom: 12px;
  padding: 4px 9px;
`;

const EventCreationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const TextFieldWrapper = styled.div`
  margin-bottom: 8px;
  .MuiInputBase-input {
    padding: 0px;
  }
`;

const EventStatusButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EventCreationTimeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.gray200};
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }
`;

const EventDetailViewWrapper = styled.div``;

const EventDetailContent = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 12px;
  }

  /* .MuiInputBase-root {
    height: 34px;
  } */

  .MuiInputBase-multiline {
    padding: 0px;
  }
`;

const EventDetailContentText = styled.div`
  width: 100%;
  color: ${COLORS.gray400};
  border-radius: 8px;
  font-size: 13px;
  font-weight: 700;
  margin-left: 4px;
  padding: 7px 8px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const AttendeeInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DateWrapper = styled.div`
  min-width: 65px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7eaf4;
  border-radius: 8px;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  :hover {
    border-color: ${COLORS.gray900};
  }

  .MuiInputBase-root {
    font-size: 12px;
    padding: 8px;
  }

  .MuiInputBase-input {
    text-align: center;
    padding: 0px;
  }

  .MuiInputAdornment-root {
    display: none;
  }

  .Mui-disabled {
    color: ${COLORS.gray900} !important;
  }
`;

const TimeSelectWrapper = styled.div<{ timeError?: boolean; durationError?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 8px;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.timeError && `border-color: ${COLORS.negative1};`}
    ${(props) => props.durationError && `border-color: ${COLORS.negative1};`}
  }

  .MuiAutocomplete-noOptions {
    display: none;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiInputBase-input {
    text-align: center;
    padding: 0px !important;
  }
`;

const StartTimeWrapper = styled.div`
  position: relative;
  :hover {
    .start-close-button {
      opacity: 1;
    }
  }
`;

const DurationTimeWrapper = styled.div`
  position: relative;
  :hover {
    .duration-close-button {
      opacity: 1;
    }
  }
`;

const UserChip = styled.div<{ guest: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  background-color: ${(props) => (props.guest ? '#FFF6DF' : '#E2ECFF')};
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const ResponseStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiToggleButton-root {
    height: 32px;
    border-radius: 8px !important;
    border: 1px solid ${COLORS.gray200} !important;
    margin-right: 8px;
  }

  .Mui-selected {
    background-color: ${COLORS.sub3} !important;
    border-color: ${COLORS.brand1} !important;
    :hover {
      background-color: ${COLORS.sub3};
    }
  }
`;

const RecurrenceWrapper = styled.div``;

export interface EventCreationModalProps {
  event?: CreateEventWithParam & {
    allDay: boolean;
    category?: OutCategory | null;
    isInvited?: boolean;
    isUpdated?: boolean;
    creator?: ReadCreator;
    canModifyEvent?: boolean;
    canAnswerInvitation?: boolean;
    canAddAttendee?: boolean;
    canSwitchAccount?: boolean;
    recurringEventId?: string;
    organizer?: ReadOrganizer;
  };
  currentDate?: Date;
  eventId?: string;
  eventTitle?: string;
  eventStartTime?: string;
  eventEndTime?: string;
  eventLocation?: string;
  isNewEvent?: boolean;
  openDialog?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
  onSave?: () => void;
  onChange?: (event: CreateEventWithParam & { allDay: boolean; category?: OutCategory | null; isInvited?: boolean; isUpdated?: boolean }) => void;
  onClickDelete?: (id: string) => void;
  onClickCategory?: (category: OutCategory | null, action: CategoryActionType) => void;
  onChangeDialog?: () => void;
}

const EventCreationPopover = ({
  event,
  currentDate,
  eventId,
  eventTitle,
  eventStartTime,
  eventEndTime,
  eventLocation,
  isNewEvent,
  openDialog = false,
  onClose,
  onDelete,
  onChange,
  onSave,
  onClickDelete,
  onClickCategory,
  onChangeDialog,
  ...props
}: EventCreationModalProps) => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState<Date>(dayjs(event?.start.date || event?.start.datetime).toDate() || new Date());
  const [me] = useAtom(userAtom);
  const [calendarList, setCalendarList] = useState<OutCalendar[]>([]);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [startTime, setStartTime] = useState<string | undefined>(
    event?.start?.datetime ? dayjs(event?.start?.datetime, { utc: true }).format(DATE_FORMAT_1) : undefined,
  );
  const [endTime, setEndTime] = useState<string | undefined>(
    event?.end?.datetime ? dayjs(event?.end?.datetime, { utc: true }).format(DATE_FORMAT_1) : undefined,
  );
  const [isAllDay, setIsAllDay] = useState<boolean | undefined>(false);
  const [isOpenRecurrenceDialog, setIsOpenRecurrenceDialog] = useState<boolean>(false);
  const [inputStartTimeSelectValue, setInputStartTimeSelectValue] = useState(dayjs(startTime).format(TIME_FORMAT_2));
  const [inputEndTimeSelectValue, setInputEndTimeSelectValue] = useState(dayjs(endTime).format(TIME_FORMAT_2));
  const [timeError, setTimeError] = useState(false);
  const [openStartTime, setOpenStartTime] = useState(false);
  const [openEndTime, setOpenEndTime] = useState(false);
  const [openDurationTime, setOpenDurationTime] = useState(false);
  const refTimeSelection = useRef<HTMLDivElement>(null);
  const refPopper = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [transparencyAnchorEl, setTransparencyAnchorEl] = useState<HTMLElement | null>(null);
  const [visibilityAnchorEl, setVisibilityAnchorEl] = useState<HTMLElement | null>(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null);
  const [isEditLocation, setIsEditLocation] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [startDatePickerAnchorEl, setStartDatePickerAnchorEl] = useState<HTMLElement | null>(null);
  const [endDatePickerAnchorEl, setEndDatePickerAnchorEl] = useState<HTMLElement | null>(null);
  const [isAddTimeButton, setIsAddTimeButton] = useState(false);
  const [attendees, setAttendees] = useState<ReadAttendee[]>(event?.attendees ? event.attendees.sort() : []);
  const [inviteDetailAnchorEl, setInviteDetailAnchorEl] = useState<HTMLElement | null>(null);
  const [isAddAttendee, setIsAddAttendee] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const attendeeRefInput = useRef<HTMLInputElement>(null);
  const [prevEvent, setPrevEvent] = useState<CreateEventWithParam & { allDay: boolean; category?: OutCategory }>({
    ...(event as CreateEventWithParam & { allDay: boolean; category: OutCategory }),
    recurrence: event ? event?.recurrence : undefined,
  });

  const [responseStatus, setResponseStatus] = useState<AttendantResponseStatusEnum | null>(
    attendees?.find((attendee) => attendee.self)?.responseStatus || null,
  );
  const [recurrenceUnit, setRecurrenceUnit] = useState<string>('반복 안 함');
  const [isActiveRecurrence, setIsActiveRecurrence] = useState(false);
  const [eventRecurrence, setEventRecurrence] = useState<CreateEventWithParamRecurrence | undefined>(undefined);
  const [customRecurrence, setCustomRecurrence] = useState<CreateEventWithParamRecurrence | undefined>();
  const [selectableRecurrence, setSeletableRecurrence] = useState([
    '반복 안 함',
    '매일',
    `매주 ${dayjs().format('dddd')}`,
    `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
    `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`,
    '주중 매일(월-금)',
    '사용자 설정',
  ]);
  const selectableRecurrenceDays = [
    { kr: '일', en: 'SU' },
    { kr: '월', en: 'MO' },
    { kr: '화', en: 'TU' },
    { kr: '수', en: 'WE' },
    { kr: '목', en: 'TH' },
    { kr: '금', en: 'FR' },
    { kr: '토', en: 'SA' },
  ];
  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs()
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(TIME_FORMAT_2),
    ),
  ];

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!event) return;

    if (isInvite) {
      onSave?.();
      setIsInvite(false);
    }

    if (event.recurrence) handleClickRecurrenceIcon();

    setIsAddTimeButton(Boolean(event.allDay));
  }, [event]);

  useUpdateEffect(() => {
    if (!event) return;
    const isEventChanged = !isEqual(prevEvent, event);

    if (isEventChanged && !isNewEvent && !event.isUpdated) {
      setIsUpdate(true);
      onChange?.({ ...event, isUpdated: true });
    }
  }, [event]);

  useEffect(() => {
    if (openStartTime && !startTime) {
      const currentTime =
        dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15) >=
        dayjs().set('hour', 23).set('minute', 45)
          ? dayjs().set('hour', 23).set('minute', 45).format(DATE_FORMAT_1)
          : dayjs().set('hour', dayjs().toDate().getHours()).set('minute', dayjs().toDate().getMinutes()).ceil('minutes', 15).format(DATE_FORMAT_1);

      const index = selectableTime.findIndex((time) => time === dayjs(currentTime).format(TIME_FORMAT_2));

      setTimeout(() => {
        if (index === -1) return;
        const target = document.querySelector(`[data-option-index="${index}"]`);
        if (target) {
          target.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
    }
  }, [openStartTime]);

  useEffect(() => {
    const index = selectableRecurrence.findIndex((v) => v === recurrenceUnit);

    if (index < 6) {
      setSeletableRecurrence([
        '반복 안 함',
        '매일',
        `매주 ${dayjs(date).format('dddd')}`,
        `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
        `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`,
        '주중 매일(월-금)',
        '사용자 설정',
      ]);
    }

    if (index === 0) {
      if (event?.recurrence) {
        handleChangeRecurrence({ interval: 0 });
        setCustomRecurrence(undefined);
      } else {
        handleChangeRecurrence(undefined);
        setCustomRecurrence(undefined);
      }
    } else if (index === 1) {
      handleChangeRecurrence({ interval: 1, intervalUnit: 'DAILY', weekStart: event?.recurrence?.weekStart });
      setCustomRecurrence({ interval: 1, intervalUnit: 'DAILY', weekStart: event?.recurrence?.weekStart });
    } else if (index === 2) {
      const weekdays = [dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[];
      handleChangeRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays, weekStart: event?.recurrence?.weekStart });
      setCustomRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays, weekStart: event?.recurrence?.weekStart });
    } else if (index === 3) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
        weekStart: event?.recurrence?.weekStart,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
        weekStart: event?.recurrence?.weekStart,
      });
    } else if (index === 4) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
        weekStart: event?.recurrence?.weekStart,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
        weekStart: event?.recurrence?.weekStart,
      });
    } else if (index === 5) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
        weekStart: event?.recurrence?.weekStart,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
        weekStart: event?.recurrence?.weekStart,
      });
    } else {
      handleChangeRecurrence(customRecurrence);
    }
  }, [recurrenceUnit]);

  useEffect(() => {
    setCustomRecurrence(event?.recurrence);
    handleChangeRecurrence(event?.recurrence);
    if (event?.recurrence?.interval === 1) {
      if (event?.recurrence?.intervalUnit === 'DAILY') {
        if (event?.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매일, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event?.recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, ${event?.recurrence.occurrences}회`);
          else list.splice(6, 1, `매일, ${event?.recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit('매일');
        }
      } else if (event?.recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];
        let weekEveryday = false;
        const targetArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];

        for (let i = 0; i < event!.recurrence!.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === event!.recurrence!.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (newWeekdays.length === targetArray.length) {
          for (let i = 0; i < targetArray.length; i++) {
            if (newWeekdays[i] !== targetArray[i]) weekEveryday = false;
            else weekEveryday = true;
          }
        }

        if (event.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event.recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${event.recurrence.occurrences}회`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${event.recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            setRecurrenceUnit('주중 매일(월-금)');
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (event?.recurrence?.intervalUnit === 'MONTHLY') {
        if (event.recurrence?.monthlyType === 'day_of_month') {
          if (event.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, ${event.recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, ${event.recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (event.recurrence?.monthlyType === 'nth_weekday') {
          if (event.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `매월 ${event.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `매월 ${event.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `매월 ${event.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${event.recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${event.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${event.recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            setRecurrenceUnit(`매월 ${event.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (event.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${event.recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${event.recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (event?.recurrence?.intervalUnit === 'YEARLY') {
        if (event.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`,
            );
          else
            list.splice(
              6,
              1,
              `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event.recurrence.endDate).format(DATE_FORMAT_2)}`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event.recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${event.recurrence.occurrences}회`);
          else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${event.recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
        }
      }
    } else {
      if (event?.recurrence?.intervalUnit === 'DAILY') {
        if (event?.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}일마다, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `${event?.recurrence.interval}일마다, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event?.recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}일마다, ${event?.recurrence.occurrences}회`);
          else list.splice(6, 1, `${event?.recurrence.interval}일마다, ${event?.recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}일마다`);
          else list.splice(6, 1, `${event?.recurrence.interval}일마다`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (event?.recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        for (let i = 0; i < event.recurrence.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === event?.recurrence?.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (event?.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
            );
          else
            list.splice(
              6,
              1,
              `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event?.recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${event?.recurrence.occurrences}회`);
          else list.splice(6, 1, `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${event?.recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          else list.splice(6, 1, `${event?.recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (event?.recurrence?.intervalUnit === 'MONTHLY') {
        if (event?.recurrence?.monthlyType === 'day_of_month') {
          if (event?.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event?.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${event?.recurrence.occurrences}회`);
            else list.splice(6, 1, `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${event?.recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `${event?.recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (event?.recurrence?.monthlyType === 'nth_weekday') {
          if (event?.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(
                  event?.recurrence.endDate,
                ).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(
                  event?.recurrence.endDate,
                ).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event?.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${
                  event?.recurrence.occurrences
                }회`,
              );
            else
              list.splice(
                6,
                1,
                `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${
                  event?.recurrence.occurrences
                }회`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${event?.recurrence.interval}개월마다 ${event?.recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (event?.recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(event?.recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (event?.recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${event?.recurrence.occurrences}회`);
            else list.splice(6, 1, `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${event?.recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${event?.recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (event?.recurrence?.intervalUnit === 'YEARLY') {
        if (event?.recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(
                event?.recurrence.endDate,
              ).format(DATE_FORMAT_2)}`,
            );
          else
            list.splice(
              6,
              1,
              `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(
                event?.recurrence.endDate,
              ).format(DATE_FORMAT_2)}`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (event?.recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${event?.recurrence.occurrences}회`,
            );
          else
            list.splice(
              6,
              1,
              `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${event?.recurrence.occurrences}회`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          else list.splice(6, 1, `${event?.recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  }, [event?.recurrence]);

  useClickOutside(refTimeSelection, () => {
    if (refPopper.current) return;
    // event?.isRecurrence ? handleOpenRecurrenceDialog() : handleClickSave();
  });

  const initialize = async () => {
    const calendarData = await getAllCalendarV1CalendarGet();
    setCalendarList(calendarData.filter((v) => v.shown && v.accessRole === 'owner'));
  };

  const handleClickSave = () => {
    if (!`${title}`.trim()) {
      toast.error('회의 이름을 입력해주세요.');
      return;
    }

    if (+dayjs(startTime) >= +dayjs(endTime)) {
      toast.error('회의 시간을 올바르게 입력해주세요.');
      return;
    }
  };

  /** 시작일 설정 로직 */
  //유저가 시간 타이핑하면 그 시간으로 바꿔주기(onInputChange)
  const handleInputStartTimeSelectValue = (value: string) => {
    setInputStartTimeSelectValue(value);
  };

  /** 종료일 설정 로직 */
  //유저가 시간 타이핑하면 그 시간으로 바꿔주기(onInputChange)
  const handleInputEndTimeSelectValue = (value: string) => {
    setInputEndTimeSelectValue(value);
  };

  //유저가 시간 클릭 시 그 시간으로 바꿔주기(onChange) 오후 01:00 -> DATE_FORMAT_1형태로 변경
  const formatDateTime = (value: string, isEnd?: boolean) => {
    const isPM = value.includes('오후');
    const isAM = value.includes('오전');
    let timeString = value.replace(/(오전|오후|:|\s)/g, '');

    // 오전 12시를 00시로 변경
    if (isAM && timeString.startsWith('12')) {
      timeString = timeString.replace('12', '00');
    }

    let convertedTime = '';
    convertedTime = convertToTime(Number(timeString));
    const isAfterNoon = dayjs(convertedTime, 'HH:mm').isAfter(dayjs('12:00', 'HH:mm')) || dayjs(convertedTime, 'HH:mm').isSame(dayjs('12:00', 'HH:mm'));
    const startDateTime = event?.start?.datetime
      ? dayjs(event?.start?.datetime, { utc: true }).format(DATE_FORMAT_1)
      : dayjs(event?.start?.date).format(DATE_FORMAT_1);

    const [datePart, timePart] = startDateTime.split('T');

    // timePart에서 + 또는 -를 추출하고, 이를 timezoneSign에 저장
    const timezoneSign = timePart.includes('-') ? '-' : '+';
    const timezoneOffset = timePart.split(/[+-]/)[1] || '00:00';

    // timezoneSign을 사용해 formattedDateTime 생성
    const formattedDateTime = `${datePart}T${convertedTime}:00${timezoneSign}${timezoneOffset}`;

    return isPM && !isAfterNoon
      ? dayjs(formattedDateTime, { utc: true }).add(12, 'hour').format(DATE_FORMAT_1)
      : dayjs(formattedDateTime, { utc: true }).format(DATE_FORMAT_1);
  };

  // 0100 -> 01:00형태로 변경
  const convertToTime = (time: number): string => {
    const timeStr = time.toString().padStart(4, '0');

    const hours = parseInt(timeStr.slice(0, 2), 10);
    const minutes = parseInt(timeStr.slice(2), 10);

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleStartTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setOpenStartTime(true);

    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      if (timeError || inputStartTimeSelectValue === '') {
        setStartTime(startTime);
        setInputStartTimeSelectValue(dayjs(startTime).format(TIME_FORMAT_2));
        if (event) onChange && onChange({ ...event, start: { datetime: startTime }, allDay: false });
      } else {
        setStartTime(formatDateTime(inputStartTimeSelectValue));
        if (event) {
          if (!endTime) {
            setEndTime(dayjs(formatDateTime(inputStartTimeSelectValue)).add(1, 'hour').format(DATE_FORMAT_1));
            onChange &&
              onChange({
                ...event,
                start: { datetime: formatDateTime(inputStartTimeSelectValue) },
                end: { datetime: dayjs(formatDateTime(inputStartTimeSelectValue)).add(1, 'hour').format(DATE_FORMAT_1) },
                allDay: false,
              });
          } else if (dayjs(startTime).isAfter(dayjs(endTime))) {
            const diff = dayjs(endTime).diff(dayjs(startTime), 'minute');
            setEndTime(dayjs(formatDateTime(inputStartTimeSelectValue)).add(diff, 'minute').format(DATE_FORMAT_1));
            onChange &&
              onChange({
                ...event,
                start: { datetime: formatDateTime(inputStartTimeSelectValue) },
                end: { datetime: dayjs(formatDateTime(inputStartTimeSelectValue)).add(diff, 'minute').format(DATE_FORMAT_1) },
                allDay: false,
              });
          }
        }
      }
      if (openStartTime) setOpenStartTime(false);
    }

    if (e.key === 'Escape') {
      e.stopPropagation();
      setOpenStartTime(false);
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleEndTimeKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setOpenEndTime(true);

    if (e.key === 'Enter' || e.key === 'Tab') {
      e.stopPropagation();
      if (timeError || inputEndTimeSelectValue === '') {
        setEndTime(endTime);
        setInputEndTimeSelectValue(dayjs(endTime).format(TIME_FORMAT_2));
        if (event) onChange && onChange({ ...event, end: { datetime: endTime } });
      } else {
        setEndTime(formatDateTime(inputEndTimeSelectValue, true));
        if (event) onChange && onChange({ ...event, end: { datetime: formatDateTime(inputEndTimeSelectValue, true) } });
      }
      if (openEndTime) setOpenEndTime(false);
    }

    if (e.key === 'Escape') {
      e.stopPropagation();
      setOpenEndTime(false);
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleRemoveStartTime = () => {
    if (!canModify) {
      toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
      return;
    }
    setStartTime(undefined);
    setEndTime(undefined);
    setIsAllDay(true);
    if (event)
      onChange &&
        onChange({
          ...event,
          summary: event.summary,
          start: { date: dayjs(event.start?.date || event.start?.datetime).format(DATE_FORMAT_4) },
          end: {
            date: dayjs(event.end?.date || event.end?.datetime)
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          allDay: true,
        });
  };

  const handleRemoveEndTime = () => {
    if (!canModify) {
      toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
      return;
    }
    setStartTime(undefined);
    setEndTime(undefined);
    setIsAllDay(true);
    if (event)
      onChange &&
        onChange({
          ...event,
          summary: event.summary,
          start: { date: dayjs(event.start?.date || event.start?.datetime).format(DATE_FORMAT_4) },
          end: {
            date: dayjs(event.end?.date || event.end?.datetime)
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          allDay: true,
        });
  };

  const handleChangeTransparency = (transparency: TransparencyEnum) => {
    if (!transparency) return;
    if (event) onChange && onChange({ ...event, transparency });
    setTransparencyAnchorEl(null);
  };

  const handleChangeVisibility = (visibility: VisibilityEnum) => {
    if (!visibility) return;
    if (event) onChange && onChange({ ...event, visibility });
    setVisibilityAnchorEl(null);
  };

  const handleDelete = () => {
    if (!canModify) {
      toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
      return;
    }

    if (!isNewEvent && isUpdate) {
      setInviteDetailAnchorEl(null);
      onChange && onChange(prevEvent);
      setStartTime(prevEvent.start.date ? undefined : dayjs(prevEvent.start.datetime).format(DATE_FORMAT_1));
      setEndTime(prevEvent.end.date ? undefined : dayjs(prevEvent.end.datetime).format(DATE_FORMAT_1));
      setIsUpdate(false);
      return;
    }

    onDelete && onDelete();
  };

  const handleChangeLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (event) onChange && onChange({ ...event, location: e.currentTarget.value });
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (event) onChange && onChange({ ...event, description: e.currentTarget.value });
  };

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      if (event) onChange && onChange({ ...event, categoryId: category?.id, category });
      setCategoryAnchorEl(null);
    } else {
      onClickCategory && onClickCategory(category, action);
    }
  };

  const handleInputKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSave && onSave();
    }
  };

  const handleClickStartDate = (date: Date | null) => {
    if (!event) return;
    const convertDate = event?.start.date
      ? {
          date: dayjs(event?.start.date)
            .set('year', dayjs(date).get('year'))
            .set('month', dayjs(date).get('month'))
            .set('date', dayjs(date).get('date'))
            .format(DATE_FORMAT_4),
        }
      : {
          datetime: dayjs(event?.start.datetime)
            .set('year', dayjs(date).get('year'))
            .set('month', dayjs(date).get('month'))
            .set('date', dayjs(date).get('date'))
            .format(DATE_FORMAT_1),
        };

    if (dayjs(convertDate.date || convertDate.datetime).isAfter(dayjs(event?.end.date || event?.end.datetime), 'date')) {
      const diff = dayjs(event?.end.date || event?.end.datetime).diff(dayjs(event?.start.date || event?.start.datetime), 'day');
      const endDate =
        diff > 0
          ? event?.end.date
            ? {
                date: dayjs(convertDate.date).add(diff, 'day').format(DATE_FORMAT_4),
              }
            : {
                datetime: dayjs(convertDate.datetime).add(diff, 'day').format(DATE_FORMAT_1),
              }
          : event?.end.date
          ? {
              date: dayjs(event?.end.date)
                .set('year', dayjs(date).get('year'))
                .set('month', dayjs(date).get('month'))
                .set('date', dayjs(date).get('date'))
                .format(DATE_FORMAT_4),
            }
          : {
              datetime: dayjs(event?.end.datetime)
                .set('year', dayjs(date).get('year'))
                .set('month', dayjs(date).get('month'))
                .set('date', dayjs(date).get('date'))
                .format(DATE_FORMAT_1),
            };

      if (event) onChange && onChange({ ...event, start: convertDate, end: endDate });
    } else {
      onChange && onChange({ ...event, start: convertDate });
    }
    setStartDatePickerAnchorEl(null);
  };

  const handleClickEndDate = (date: Date | null) => {
    const convertDate = event?.end.date
      ? {
          date: dayjs(event?.end.date)
            .set('year', dayjs(date).get('year'))
            .set('month', dayjs(date).get('month'))
            .set('date', dayjs(date).get('date'))
            .add(1, 'day')
            .format(DATE_FORMAT_4),
        }
      : {
          datetime: dayjs(event?.end.datetime)
            .set('year', dayjs(date).get('year'))
            .set('month', dayjs(date).get('month'))
            .set('date', dayjs(date).get('date'))
            .format(DATE_FORMAT_1),
        };

    if (event) onChange && onChange({ ...event, end: convertDate });
    setEndDatePickerAnchorEl(null);
  };

  const handleChangeDialog = () => {
    onChangeDialog && onChangeDialog();
  };

  const handleKeyDownAttendeesInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!attendeeRefInput.current) return;
    const value = attendeeRefInput.current?.value;

    if (e.key === 'Escape') {
      if (value) return;
      attendeeRefInput.current.blur();
      setIsAddAttendee(false);
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing || e.repeat) {
        e.preventDefault();
        return;
      }

      if (!validateEmail(value)) {
        toast.error('올바른 이메일 주소를 입력해주세요.');
        return;
      }

      if (value) {
        if (attendees.length === 0) {
          if (me?.email) {
            const newAttendees = [{ email: me.email }, { email: value }];
            setAttendees(newAttendees);
            if (event) onChange?.({ ...event, attendees: newAttendees });
          }
        } else {
          const newAttendee = { email: value };
          const updatedAttendees = [...attendees, newAttendee];
          setAttendees(updatedAttendees);
          if (event) onChange?.({ ...event, attendees: updatedAttendees });
        }
        attendeeRefInput.current.value = '';
        attendeeRefInput.current.focus();
      }
    }
  };

  const handleDeleteAttendee = (email: string) => {
    const newAttendees = attendees.filter((attendee) => attendee.email !== email);
    setAttendees(newAttendees);
    if (event) onChange && onChange({ ...event, attendees: newAttendees });
  };

  const handleClickInvite = () => {
    if (event) onChange && onChange({ ...event, sendUpdate: true, isInvited: true });
    setIsInvite(true);
  };

  const handleClickWithoutInvite = () => {
    if (!canModify) {
      toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
      return;
    }
    if (event) onChange && onChange({ ...event, sendUpdate: false, isInvited: true });
    setIsInvite(true);
  };

  const handleChangeResponse = (e: React.MouseEvent<HTMLElement>, status: AttendantResponseStatusEnum) => {
    const updatedAttendees = attendees.map((attendee) => {
      if (attendee.self) {
        return { ...attendee, responseStatus: status };
      }
      return attendee;
    });
    if (event) onChange && onChange({ ...event, attendees: updatedAttendees });
    setResponseStatus(status);
  };

  const handleClickRecurrenceIcon = () => {
    setIsActiveRecurrence(true);
  };

  const handleChangeRecurrence = (recurrence: CreateEventWithParamRecurrence | undefined) => {
    if (event) {
      onChange && onChange({ ...event, recurrence });
    }
    setEventRecurrence(recurrence);
  };

  const handleCloseCustomReccurence = () => {
    if (customRecurrence) {
      if (customRecurrence.interval === 1) {
        if (customRecurrence.intervalUnit === 'DAILY') {
          setRecurrenceUnit('매일');
        } else if (customRecurrence.intervalUnit === 'WEEKLY') {
          const newWeekdays = [];
          let weekEveryday = false;
          const targetArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];

          for (let i = 0; i < customRecurrence.weekdays!.length!; i++) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === customRecurrence.weekdays![i]);
            newWeekdays.push(`${weekday?.kr}요일`);
          }

          for (let i = 0; i < targetArray.length; i++) {
            if (newWeekdays[i] !== targetArray[i]) weekEveryday = false;
            else weekEveryday = true;
          }

          if (weekEveryday) {
            setRecurrenceUnit('주중 매일(월-금)');
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (customRecurrence.intervalUnit === 'MONTHLY') {
          if (customRecurrence.monthlyType === 'nth_weekday') {
            setRecurrenceUnit(`매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`);
          } else {
            setRecurrenceUnit(selectableRecurrence[6]);
          }
        } else if (customRecurrence.intervalUnit === 'YEARLY') {
          setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
        }
      } else {
        setRecurrenceUnit(selectableRecurrence[6]);
      }
    } else {
      setRecurrenceUnit(selectableRecurrence[0]);
    }
  };

  const handleClickCustomRecurrence = (recurrence: InRecurrence) => {
    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);

    if (recurrence?.interval === 1) {
      if (recurrence?.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit('매일');
        }
      } else if (recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];
        let weekEveryday = false;
        const targetArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];
        for (let i = 0; i < recurrence.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === recurrence?.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (newWeekdays.length === targetArray.length) {
          for (let i = 0; i < targetArray.length; i++) {
            if (newWeekdays[i] !== targetArray[i]) weekEveryday = false;
            else weekEveryday = true;
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            setRecurrenceUnit('주중 매일(월-금)');
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
        }
      }
    } else {
      if (recurrence?.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
          else list.splice(6, 1, `${recurrence.interval}일마다`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        for (let i = 0; i < recurrence.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === recurrence?.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else
              list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(
                DATE_FORMAT_2,
              )}`,
            );
          else
            list.splice(
              6,
              1,
              `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(
                DATE_FORMAT_2,
              )}`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  };

  const handleCloseRecurrenceDialog = () => {
    setIsOpenRecurrenceDialog(false);
  };

  const validateEmail = (value = '') => RegExp(/^[A-Za-z0-9_!#$%&'*+\\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm').test(value);
  const organizer = attendees.find((attendee) => attendee.organizer) ? attendees.find((attendee) => attendee.organizer) : me;
  const canModify = event?.canModifyEvent || isNewEvent;

  return (
    <Container ref={ref} className="event-creation-popover">
      {!canModify && (
        <ModifyAlarmWrapper>
          <Icons.ExclamationInfo width={10} height={10} stroke={COLORS.negative1} style={{ marginRight: '4px' }} />
          <span>수정 권한이 없는 일정입니다.</span>
        </ModifyAlarmWrapper>
      )}
      <EventCreationHeader>
        {event?.category ? (
          <Tooltip title="카테고리 설정하기" disableInteractive>
            <CategoryShowingWrapper
              textColor={getCategoryTextColor(event?.category.color)}
              bgColor={getCategoryBgColor(event?.category.color)}
              onClick={(e) => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                setCategoryAnchorEl(e.currentTarget);
              }}
            >
              {`# ${event?.category.name}`}
              <IconButton
                className="category-detach-button"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!canModify) {
                    toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                    return;
                  }
                  handleClickCategory(null, 'SELECT');
                }}
                sx={{
                  'width': '12px',
                  'height': '12px',
                  'borderRadius': '4px',
                  'marginLeft': '4px',
                  'marginTop': '1px',
                  'padding': '0px',
                  ':hover': {
                    backgroundColor: hexToRGBA(getCategoryTextColor(event.category.color)!, 0.3),
                  },
                }}
                style={categoryAnchorEl ? { display: 'flex' } : {}}
              >
                <Icons.Close width={8} height={8} fill={getCategoryTextColor(event.category.color)} />
              </IconButton>
            </CategoryShowingWrapper>
          </Tooltip>
        ) : (
          <Tooltip title="카테고리 설정하기" disableInteractive>
            <IconButton
              onClick={(e) => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                setCategoryAnchorEl(e.currentTarget);
              }}
              sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
            >
              <Icons.Hashtag />
            </IconButton>
          </Tooltip>
        )}
        {categoryAnchorEl && (
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setCategoryAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
          </Popover>
        )}
        <EventStatusButtonWrapper>
          <Button
            onClick={(e) => {
              if (!event?.canSwitchAccount) {
                toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                return;
              }
              setCalendarAnchorEl(e.currentTarget);
            }}
            sx={{
              height: '28px',
              border: `1px solid ${COLORS.gray200}`,
              borderRadius: '8px',
              fontSize: '12px',
              padding: '5px 12px',
              textTransform: 'none',
            }}
          >
            <div style={{ maxWidth: 163, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {calendarList.find((v) => v.googleCalendarId === event?.googleCalendarId)
                ? calendarList.find((v) => v.googleCalendarId === event?.googleCalendarId)?.summary
                : calendarList.find((v) => v.default)?.summary}
            </div>
            <div style={{ marginLeft: 12 }}>
              <Icons.ArrowDownSmall />
            </div>
          </Button>
          <Menu
            open={Boolean(calendarAnchorEl)}
            anchorEl={calendarAnchorEl}
            onClose={() => setCalendarAnchorEl(null)}
            MenuListProps={{ style: { padding: 8, minWidth: 211 } }}
            sx={{ marginTop: '2px' }}
          >
            {calendarList.map((calendar) => (
              <MenuItem
                key={calendar.id}
                onClick={() => {
                  if (event) onChange && onChange({ ...event, googleCalendarId: calendar.googleCalendarId });
                  setCalendarAnchorEl(null);
                }}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                <div style={{ width: 8, height: 8, backgroundColor: calendar.slashCalendarColor, borderRadius: 8, marginRight: 8 }} />
                <span>{calendar.summary}</span>
              </MenuItem>
            ))}
          </Menu>
          <div style={{ border: `1px solid ${COLORS.gray200}`, borderRadius: 8, marginLeft: 4, padding: '0px 1px' }}>
            <IconButton
              onClick={() => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                handleDelete();
              }}
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '8px',
                padding: '4px',
              }}
            >
              <Icons.Delete fill={COLORS.negative1} />
            </IconButton>
            <Tooltip
              title={event?.transparency === 'opaque' ? '바쁨' : '한가함'}
              disableInteractive
              open={!!event?.transparency} // Tooltip을 투명도 값이 있을 때만 표시
            >
              <IconButton
                onClick={(e) => {
                  if (!canModify) {
                    toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                    return;
                  }
                  setTransparencyAnchorEl(e.currentTarget);
                }}
                sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '8px',
                  padding: '4px',
                }}
              >
                <Icons.CalendarTransparency />
              </IconButton>
            </Tooltip>
            <Menu
              open={Boolean(transparencyAnchorEl)}
              anchorEl={transparencyAnchorEl}
              onClose={() => setTransparencyAnchorEl(null)}
              MenuListProps={{ style: { padding: 8 } }}
              sx={{ marginTop: '2px' }}
            >
              <MenuItem
                onClick={() => handleChangeTransparency('opaque')}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                바쁨
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeTransparency('transparent')}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                한가함
              </MenuItem>
            </Menu>
            <IconButton
              onClick={(e) => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                setVisibilityAnchorEl(e.currentTarget);
              }}
              sx={{ width: '24px', height: '24px', borderRadius: '8px', padding: '4px' }}
            >
              {event?.visibility === 'private' ? <Icons.CalendarInvisibility /> : <Icons.CalendarVisibility />}
            </IconButton>
            <Menu
              open={Boolean(visibilityAnchorEl)}
              anchorEl={visibilityAnchorEl}
              onClose={() => setVisibilityAnchorEl(null)}
              MenuListProps={{ style: { padding: 8 } }}
              sx={{ marginTop: '2px' }}
            >
              <MenuItem
                onClick={() => handleChangeVisibility('default')}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                기본 공개 설정
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeVisibility('public')}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                전체공개
              </MenuItem>
              <MenuItem
                onClick={() => handleChangeVisibility('private')}
                sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
              >
                비공개
              </MenuItem>
            </Menu>
          </div>
        </EventStatusButtonWrapper>
      </EventCreationHeader>
      <TextFieldWrapper>
        <TextField
          autoComplete="off"
          autoFocus={event?.summary === ''}
          fullWidth
          variant="standard"
          placeholder="일정 입력하기"
          ref={refInput}
          value={event?.summary || ''}
          onChange={(e) =>
            onChange &&
            onChange({ ...event, summary: e.currentTarget.value, start: event!.start!, end: event!.end!, eventType: event!.eventType, allDay: event!.allDay })
          }
          onKeyDown={(e) => handleInputKeydown(e)}
          // onChange={project ? (e) => setProject({ ...project, title: e.currentTarget.value }) : undefined}
          // onBlur={handleUpdateProjectTitle}
          // onKeyDown={(e) => handleKeyDown(e)}
          InputProps={{
            readOnly: !canModify,
            disableUnderline: true,
            style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray900 },
          }}
        />
      </TextFieldWrapper>
      <EventCreationTimeInfoWrapper>
        <DateWrapper
          onClick={(e) => {
            if (!canModify) {
              toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
              return;
            }
            setStartDatePickerAnchorEl(e.currentTarget);
          }}
          style={{ marginRight: 8 }}
        >
          {event?.start?.date ? dayjs(event?.start?.date, { utc: true }).format('M월 D일') : dayjs(event?.start?.datetime, { utc: true }).format('M월 D일')}
        </DateWrapper>
        <Popover
          disablePortal
          open={Boolean(startDatePickerAnchorEl)}
          anchorEl={startDatePickerAnchorEl}
          onClose={() => setStartDatePickerAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={dayjs(event?.end?.date || event?.end?.datetime, { utc: true }).format(DATE_FORMAT_4)}
            onChange={(value) => handleClickStartDate(dayjs(value || '').toDate())}
            renderInput={(params) => <TextField {...params} />}
          />
        </Popover>
        {!isAddTimeButton && (
          <TimeSelectWrapper>
            <StartTimeWrapper>
              <Autocomplete
                className="auto-complete"
                freeSolo
                readOnly={!canModify}
                open={openStartTime}
                value={startTime ? dayjs(startTime, { utc: true }).format(TIME_FORMAT_2) : null}
                inputValue={inputStartTimeSelectValue}
                placeholder="시작 시간"
                onChange={(e, newValue) => {
                  if (!newValue) return;
                  setStartTime(formatDateTime(newValue!));
                  if (event) {
                    if (!endTime) {
                      setEndTime(dayjs(formatDateTime(newValue!)).add(1, 'hour').format(DATE_FORMAT_1));
                      onChange &&
                        onChange({
                          ...event,
                          start: { datetime: formatDateTime(newValue!) },
                          end: { datetime: dayjs(formatDateTime(newValue!)).add(1, 'hour').format(DATE_FORMAT_1) },
                          allDay: false,
                        });
                    } else if (dayjs(formatDateTime(newValue!)).isAfter(dayjs(endTime))) {
                      const diff = dayjs(endTime).diff(dayjs(startTime), 'minute');
                      setEndTime(dayjs(formatDateTime(newValue!)).add(diff, 'minute').format(DATE_FORMAT_1));
                      onChange &&
                        onChange({
                          ...event,
                          start: { datetime: formatDateTime(newValue!) },
                          end: { datetime: dayjs(formatDateTime(newValue!)).add(diff, 'minute').format(DATE_FORMAT_1) },
                          allDay: false,
                        });
                    } else {
                      onChange && onChange({ ...event, start: { datetime: formatDateTime(newValue!) } });
                    }
                  }
                  setOpenStartTime(false);
                  setIsAllDay(false);
                  (document.activeElement as HTMLElement).blur();
                }}
                onInputChange={(e, newValue) => {
                  handleInputStartTimeSelectValue(newValue);
                }}
                onKeyDown={(e) => {
                  handleStartTimeKeyDown(e);
                }}
                onFocus={() => {
                  setOpenStartTime(true);
                }}
                onBlur={() => {
                  // if (timeError || inputStartTimeSelectValue === '') {
                  //   setStartTime(startTime);
                  //   setinputStartTimeSelectValue(dayjs(startTime).format(TIME_FORMAT_2));
                  // } else {
                  //   setStartTime(formatDateTime(inputStartTimeSelectValue));
                  // }
                  setOpenStartTime(false);
                }}
                options={selectableTime}
                isOptionEqualToValue={(option, value) => option === value}
                size="small"
                PopperComponent={(props: PopperProps) => (
                  <Popper
                    {...props}
                    ref={refPopper}
                    placement="bottom"
                    sx={{
                      'width': '100px !important',
                      '&.MuiAutocomplete-noOptions': {
                        display: 'none',
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                )}
                ListboxProps={{
                  style: {
                    display: timeError ? 'none' : 'block',
                    maxHeight: '264px',
                    fontSize: '12px',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="시작 시간"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        width: '68px',
                        height: '34px',
                        fontSize: '12px',
                        padding: '8px',
                      },
                    }}
                  />
                )}
              />
              {startTime && (
                <IconButton
                  className="start-close-button"
                  onClick={handleRemoveStartTime}
                  sx={{
                    'width': '16px',
                    'height': '16px',
                    'backgroundColor': COLORS.gray600,
                    'opacity': 0,
                    'padding': '0px',
                    'position': 'absolute',
                    'top': '-6px',
                    'left': '59px',
                    ':hover': {
                      backgroundColor: COLORS.gray900,
                    },
                  }}
                >
                  <Icons.Close width={10} height={10} fill={COLORS.white} />
                </IconButton>
              )}
            </StartTimeWrapper>
            <span style={{ margin: '0px 8px' }}>-</span>
            <DurationTimeWrapper>
              <Autocomplete
                className="auto-complete"
                freeSolo
                disabled={!startTime}
                readOnly={!canModify}
                open={openEndTime}
                value={endTime ? dayjs(endTime, { utc: true }).format(TIME_FORMAT_2) : null}
                inputValue={inputEndTimeSelectValue}
                placeholder="종료 시간"
                onChange={(e, newValue) => {
                  if (!newValue) return;
                  setEndTime(formatDateTime(newValue!, true));
                  if (event) onChange && onChange({ ...event, end: { datetime: formatDateTime(newValue, true) } });
                  setOpenEndTime(false);
                  setIsAllDay(false);
                  (document.activeElement as HTMLElement).blur();
                }}
                onInputChange={(e, newValue) => {
                  handleInputEndTimeSelectValue(newValue);
                }}
                onKeyDown={(e) => {
                  handleEndTimeKeyDown(e);
                }}
                onFocus={() => {
                  setOpenEndTime(true);
                }}
                onBlur={() => {
                  // if (durationError || durationLimitError || inputDurationSelectValue === '') {
                  //   setEndTime(endTime);
                  //   setInputDurationSelectValue(durationToshowingTime(timeDiff));
                  // } else {
                  //   setTimeDiff(showingTimeToDuration(inputDurationSelectValue)!);
                  // }
                  setOpenEndTime(false);
                }}
                options={
                  dayjs(endTime).isSame(startTime, 'date')
                    ? selectableTime.slice(selectableTime.indexOf(dayjs(startTime).format(TIME_FORMAT_2) || '') + 1)
                    : selectableTime
                }
                isOptionEqualToValue={(option, value) => option === value}
                PopperComponent={(props: PopperProps) => (
                  <Popper
                    {...props}
                    ref={refPopper}
                    placement="bottom"
                    sx={{
                      'width': '100px !important',
                      '&.MuiAutocomplete-noOptions': {
                        display: 'none',
                      },
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="종료 시간"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        width: '68px',
                        height: '34px',
                        fontSize: '12px',
                        padding: '8px',
                      },
                    }}
                  />
                )}
                ListboxProps={{
                  style: {
                    display: timeError ? 'none' : 'block',
                    maxHeight: '264px',
                    fontSize: '12px',
                  },
                }}
              />
              {endTime && (
                <IconButton
                  className="duration-close-button"
                  onClick={handleRemoveEndTime}
                  sx={{
                    'width': '16px',
                    'height': '16px',
                    'backgroundColor': COLORS.gray600,
                    'opacity': 0,
                    'padding': '0px',
                    'position': 'absolute',
                    'top': '-6px',
                    'left': '59px',
                    ':hover': {
                      backgroundColor: COLORS.gray900,
                    },
                  }}
                >
                  <Icons.Close width={10} height={10} fill={COLORS.white} />
                </IconButton>
              )}
            </DurationTimeWrapper>
          </TimeSelectWrapper>
        )}
        <DateWrapper
          onClick={(e) => {
            if (!canModify) {
              toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
              return;
            }
            setEndDatePickerAnchorEl(e.currentTarget);
          }}
          style={
            event?.allDay && dayjs(event.end.date).diff(event.start.date, 'day') <= 1
              ? {
                  color: COLORS.gray700,
                  backgroundColor: COLORS.gray200,
                }
              : {}
          }
        >
          {event?.allDay
            ? dayjs(event.end.date).diff(event.start.date, 'day') > 1
              ? dayjs(event?.end.date, { utc: true }).subtract(1, 'day').format('M월 D일')
              : '종료일 설정'
            : dayjs(event?.end.datetime, { utc: true }).format('M월 D일')}
        </DateWrapper>
        <Popover
          disablePortal
          open={Boolean(endDatePickerAnchorEl)}
          anchorEl={endDatePickerAnchorEl}
          onClose={() => setEndDatePickerAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            minDate={dayjs(event?.start.date) ? dayjs(event?.start.date).add(1, 'day').toDate() : dayjs(event?.start.datetime).toDate()}
            value={
              event?.end.date
                ? dayjs(event.end.date, { utc: true }).subtract(1, 'day').format(DATE_FORMAT_4)
                : dayjs(event?.end?.datetime, { utc: true }).format(DATE_FORMAT_4)
            }
            onChange={(value) => handleClickEndDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Popover>
        {isAddTimeButton && (
          <Button
            onClick={() => {
              setIsAddTimeButton(false);
            }}
            sx={{
              height: '34px',
              backgroundColor: COLORS.gray200,
              border: `1px solid ${COLORS.gray200}`,
              borderRadius: '8px',
              color: COLORS.gray700,
              fontSize: '12px',
              padding: '8px',
              textTransform: 'none',
              marginLeft: '8px',
            }}
          >
            시간 추가
          </Button>
        )}
        <Tooltip title="준비중">
          <IconButton sx={{ border: `1px solid ${COLORS.gray200}`, borderRadius: '8px', margin: '0px 8px' }}>
            <Icons.Globe stroke={COLORS.gray500} />
          </IconButton>
        </Tooltip>
        {isActiveRecurrence || recurrenceUnit ? (
          <RecurrenceWrapper className="recurrence">
            <Select
              readOnly={!canModify}
              value={recurrenceUnit}
              onChange={(e) => {
                setRecurrenceUnit(e.target.value);
              }}
              style={{ width: '100%', height: 34, fontSize: '13px' }}
              renderValue={() => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: '12px' }}>{recurrenceUnit}</div>
                </div>
              )}
              inputProps={{
                sx: {
                  padding: '8px',
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 372,
                  },
                },
              }}
            >
              {selectableRecurrence.map((v, idx) => (
                <MenuItem key={idx} value={v}>
                  <span style={{ fontSize: '12px', width: '100%' }}>{v}</span>
                </MenuItem>
              ))}
            </Select>
          </RecurrenceWrapper>
        ) : (
          <IconButton
            onClick={() => {
              if (!canModify) {
                toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                return;
              }
              handleClickRecurrenceIcon();
            }}
            sx={{ border: `1px solid ${COLORS.gray200}`, borderRadius: '8px' }}
          >
            <Icons.Reload stroke={COLORS.gray500} />
          </IconButton>
        )}
      </EventCreationTimeInfoWrapper>
      <Divider sx={{ margin: '12px 0px' }} />
      <EventDetailViewWrapper>
        <EventDetailContent style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <AttendeeInputWrapper>
            <Icons.People />
            {!isAddAttendee && (
              <EventDetailContentText
                onClick={() => {
                  if (!event?.canAddAttendee) {
                    toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                    return;
                  }
                  setIsAddAttendee(true);
                }}
              >
                참가자
              </EventDetailContentText>
            )}
            {isAddAttendee && (
              <TextField
                inputRef={attendeeRefInput}
                autoFocus
                fullWidth
                inputProps={{
                  style: {
                    padding: 8,
                    fontSize: 13,
                    fontWeight: 700,
                  },
                }}
                onKeyDown={handleKeyDownAttendeesInput}
                sx={{ marginLeft: '8px', height: '34px' }}
              />
            )}
          </AttendeeInputWrapper>
          {attendees.length > 0 && (
            <Stack spacing={1} sx={{ mt: 1, ml: 3 }}>
              <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
                {attendees
                  .sort((a, b) => (a.organizer ? -1 : 1) - (b.organizer ? -1 : 1))
                  .map((attendee, index) => (
                    <Chip
                      key={index}
                      avatar={
                        attendee.responseStatus === 'accepted' ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <circle cx="9" cy="10" r="8" fill="#C8CDDB" />
                            <circle cx="9" cy="7.5" r="2.5" fill="#F2F5FC" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.0629 16.2953C4.0215 16.0363 4 15.7707 4 15.5C4 12.7386 6.23858 10.5 9 10.5C11.7614 10.5 14 12.7386 14 15.5C14 15.7707 13.9785 16.0363 13.9371 16.2953C12.5774 17.3632 10.863 18 9 18C7.13695 18 5.42264 17.3632 4.0629 16.2953Z"
                              fill="#F2F5FC"
                            />
                            <circle cx="15" cy="14" r="4" fill="#10B8C2" />
                            <path d="M13 14.25L14.3333 15.5L17 13" stroke="#E9F9FA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="8" fill="#C8CDDB" />
                            <circle cx="10" cy="7.5" r="2.5" fill="#F2F5FC" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.0629 16.2953C5.0215 16.0363 5 15.7707 5 15.5C5 12.7386 7.23858 10.5 10 10.5C12.7614 10.5 15 12.7386 15 15.5C15 15.7707 14.9785 16.0363 14.9371 16.2953C13.5774 17.3632 11.863 18 10 18C8.13695 18 6.42264 17.3632 5.0629 16.2953Z"
                              fill="#F2F5FC"
                            />
                          </svg>
                        )
                      }
                      label={
                        attendee.email === organizer?.email ? (
                          <div>
                            <span>{attendee.email}</span>
                            <span style={{ color: COLORS.gray500 }}>{'(주최자)'}</span>
                          </div>
                        ) : (
                          attendee.email
                        )
                      }
                      onDelete={
                        canModify
                          ? () => handleDeleteAttendee(attendee.email)
                          : event?.canAddAttendee && !attendee.responseStatus
                          ? () => handleDeleteAttendee(attendee.email)
                          : undefined
                      }
                      sx={{ display: 'flex', alignItems: 'center', background: 'rgba(226, 236, 255, 0.6)', borderRadius: '8px' }}
                    />
                  ))}
              </Stack>
            </Stack>
          )}

          {/* <Stack direction={'row'} alignItems={'center'} sx={{ mt: 3 }}>
            <People />
            <Autocomplete
              fullWidth
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              // blurOnSelect
              autoHighlight
              value={null}
              options={members}
              // options={members.filter((v) => v.id !== user?.id)}
              renderInput={(params) => <TextField {...params} placeholder="이름 또는 이메일로 참석자를 추가해주세요." />}
              onChange={handleChangeMember}
              style={{ marginLeft: 18 }}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: 372,
                  },
                },
              }}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
              renderOption={(props, option) => (
                <></>
                // <Box key={option.id} component="li" {...props} sx={{ display: 'flex' }}>
                //   <Avatar alt={option.name} src={option.profileImage} sx={{ width: 24, height: 24, marginRight: 1 }} />
                //   <span>{option.name}</span>
                // </Box>
              )}
              isOptionEqualToValue={(option, value) => option.email === value.email}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 1, ml: 5 }}>
            <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
              {Array.from(selectedParticipants).map(([key, value]) => (
                <UserChip key={key} guest={!value.name}>
                  <div style={{ position: 'relative', marginRight: 8 }}>
                    <Avatar alt={value.name || ''} src={value.profileImage || ''} sx={{ width: 16, height: 16 }} />
                    {value.id === user?.id && (
                      <span style={{ position: 'absolute', top: 8, right: -2 }}>
                        <Icons.OrganizerMarker width={8} height={8} />
                      </span>
                    )}
                  </div>
                  <span>{value.name || value.email}</span>
                  {value.id === user?.id && <span style={{ marginLeft: 4, color: COLORS.gray500 }}>(주최자)</span>}
                  <span style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleMemberDelete(key)}>
                    <Icons.Close width={12} height={12} fill={COLORS.gray500} />
                  </span>
                </UserChip>
              ))}
            </Stack>
          </Stack> */}
        </EventDetailContent>
        <EventDetailContent>
          <Icons.Map />
          {isEditLocation || event?.location ? (
            <TextField
              fullWidth
              value={event?.location}
              autoFocus={isEditLocation}
              inputProps={{
                readOnly: !canModify,
                style: { padding: 8, fontSize: 13, fontWeight: 700 },
              }}
              onChange={handleChangeLocation}
              sx={{ height: '34px', marginLeft: '8px' }}
            />
          ) : (
            <EventDetailContentText
              onClick={() => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                setIsEditLocation(true);
              }}
            >
              장소
            </EventDetailContentText>
          )}
        </EventDetailContent>
        <EventDetailContent style={{ alignItems: 'flex-start' }}>
          <div style={{ marginTop: '8px' }}>
            <Icons.Description />
          </div>
          <Tooltip title="준비중">
            <EventDetailContentText>설명</EventDetailContentText>
          </Tooltip>
          {/* {isEditDescription || event?.description ? (
            <TextField
              fullWidth
              multiline
              maxRows={7}
              variant="standard"
              value={event?.description}
              autoFocus={isEditDescription}
              inputProps={{ readOnly: !canModify, style: { padding: '4px 8px 0px 8px', fontSize: 13 } }}
              onChange={handleChangeDescription}
              sx={{
                'marginLeft': '8px',
                'padding': '0px',
                '& .MuiInput-underline:before': {
                  borderBottom: 'none',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: 'none',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none',
                },
              }}
            />
          ) : (
            <EventDetailContentText
              onClick={() => {
                if (!canModify) {
                  toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
                  return;
                }
                setIsEditDescription(true);
              }}
            >
              설명
            </EventDetailContentText>
          )} */}
        </EventDetailContent>
      </EventDetailViewWrapper>
      {attendees.length > 0 && event?.canAnswerInvitation && <Divider sx={{ margin: '12px 0px' }} />}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {attendees.length > 0 && event?.canAnswerInvitation && attendees.find((attendee) => attendee.self) ? (
          <ResponseStatusWrapper>
            <span style={{ fontSize: 13, fontWeight: 700, marginRight: 8 }}>참석 여부</span>
            <ToggleButtonGroup value={responseStatus} exclusive onChange={handleChangeResponse}>
              <ToggleButton value={'accepted'} sx={{ borderRadius: '8px' }}>
                예
              </ToggleButton>
              <ToggleButton value={'declined'}>아니오</ToggleButton>
              <ToggleButton value={'tentative'}>미정</ToggleButton>
            </ToggleButtonGroup>
          </ResponseStatusWrapper>
        ) : (
          <div />
        )}
        {attendees.length > 0 &&
          !dayjs(event?.end.date ? dayjs(event?.end.date).subtract(1, 'day') : event?.end.datetime).isBefore(dayjs(), 'date') &&
          (canModify || attendees.find((v) => !v.responseStatus)) && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                variant="contained"
                onClick={handleClickInvite}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 'fit-content',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  padding: '10px 12px',
                  position: 'relative',
                  fontSize: '13px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Send width={16} height={16} />
                  <span style={{ marginLeft: 4 }}>{!isNewEvent && isUpdate ? '업데이트하기' : '초대하기'}</span>
                </div>
              </Button>
              <Button
                variant="contained"
                onClick={(e) => setInviteDetailAnchorEl(e.currentTarget)}
                sx={{
                  width: '36px',
                  height: '42.75px',
                  minWidth: '0px',
                  backgroundColor: COLORS.brand1,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                  marginLeft: '2px',
                  padding: '10px 8px',
                }}
              >
                <Icons.MoreVertical width={20} height={20} stroke={COLORS.white} />
              </Button>
            </div>
          )}
        <Menu
          open={Boolean(inviteDetailAnchorEl)}
          anchorEl={inviteDetailAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          MenuListProps={{ style: { padding: 8 } }}
          onClose={() => setInviteDetailAnchorEl(null)}
        >
          <MenuItem
            onClick={handleDelete}
            sx={{
              'borderRadius': '8px',
              'fontSize': '12px',
              'padding': '8px',
              ':hover': {
                backgroundColor: COLORS.gray100,
              },
            }}
          >
            {!isNewEvent && isUpdate ? '변경사항 삭제' : '일정 삭제'}
          </MenuItem>
          <MenuItem
            onClick={handleClickWithoutInvite}
            sx={{
              'borderRadius': '8px',
              'fontSize': '12px',
              'padding': '8px',
              ':hover': {
                backgroundColor: COLORS.gray100,
              },
            }}
          >
            {!isNewEvent && isUpdate ? '메일 보내지 않고 업데이트하기' : '메일 보내지 않고 생성하기'}
          </MenuItem>
        </Menu>
      </div>
      <Dialog open={openDialog}>
        <div style={{ width: 298, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DialogTitle>📩</DialogTitle>
          <DialogContent sx={{ padding: '32px 24px' }}>
            <DialogContentText sx={{ color: COLORS.gray900, textAlign: 'center', fontSize: '16px', fontWeight: 700 }}>
              {isUpdate ? (
                <>
                  기존 구글 캘린더 참석자에게
                  <br />
                  업데이트 이메일을 보내시겠습니까?
                </>
              ) : (
                <>
                  구글 캘린더 참석자에게
                  <br />
                  초대 이메일을 보내시겠습니까?
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 24px 24px 24px' }}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClickInvite}
              sx={{
                'borderRadius': '8px',
                'boxShadow': 'none',
                'fontSize': '13px',
                'fontWeight': 700,
                'padding': '10px 16px',
                ':hover': {
                  boxShadow: 'none',
                },
              }}
            >
              보내기
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClickWithoutInvite}
              sx={{
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': COLORS.gray900,
                'backgroundColor': COLORS.gray100,
                'margin': '12px 0px',
                'fontSize': '13px',
                'fontWeight': 700,
                'padding': '10px 16px',
                ':hover': {
                  backgroundColor: COLORS.gray200,
                  boxShadow: 'none',
                },
              }}
            >
              보내지 않기
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleChangeDialog}
              sx={{
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': COLORS.gray900,
                'backgroundColor': COLORS.gray100,
                'fontSize': '13px',
                'fontWeight': 700,
                'padding': '10px 16px',
                ':hover': {
                  backgroundColor: COLORS.gray200,
                  boxShadow: 'none',
                },
              }}
            >
              계속 수정
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <UpdateRecurrenceDialog open={isOpenRecurrenceDialog} onClose={handleCloseRecurrenceDialog} onUpdateRecurrence={handleClickSave} />
      <Dialog open={recurrenceUnit === '사용자 설정'} onClose={handleCloseCustomReccurence}>
        <RecurrenceCustomDialog
          date={date}
          defaultCustomRecurrence={customRecurrence as InRecurrence}
          onClose={handleCloseCustomReccurence}
          onChange={handleClickCustomRecurrence}
        />
      </Dialog>
    </Container>
  );
};

export default EventCreationPopover;
