import { SVGProps } from 'react';

export const Shortcut = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4575_26627)">
        <path d="M20.0001 4L13.6001 10.4" stroke="#1E1E23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.4004 4H20.0004V9.6" stroke="#1E1E23" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.9744 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13.2632"
          stroke="#1E1E23"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4575_26627">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
