import { SVGProps } from 'react';

export const Slack = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2035_2832)">
        <path
          d="M6.21659 14.6332C6.21659 15.7947 5.27784 16.7334 4.11635 16.7334C2.95486 16.7334 2.01611 15.7947 2.01611 14.6332C2.01611 13.4717 2.95486 12.533 4.11635 12.533H6.21659V14.6332ZM7.26671 14.6332C7.26671 13.4717 8.20545 12.533 9.36694 12.533C10.5284 12.533 11.4672 13.4717 11.4672 14.6332V19.8838C11.4672 21.0453 10.5284 21.984 9.36694 21.984C8.20545 21.984 7.26671 21.0453 7.26671 19.8838V14.6332Z"
          fill="#E01E5A"
        />
        <path
          d="M9.36674 6.20047C8.20525 6.20047 7.2665 5.26173 7.2665 4.10024C7.2665 2.93874 8.20525 2 9.36674 2C10.5282 2 11.467 2.93874 11.467 4.10024V6.20047H9.36674ZM9.36674 7.2665C10.5282 7.2665 11.467 8.20525 11.467 9.36674C11.467 10.5282 10.5282 11.467 9.36674 11.467H4.10024C2.93874 11.467 2 10.5282 2 9.36674C2 8.20525 2.93874 7.2665 4.10024 7.2665H9.36674Z"
          fill="#36C5F0"
        />
        <path
          d="M17.7838 9.36674C17.7838 8.20525 18.7225 7.2665 19.884 7.2665C21.0455 7.2665 21.9843 8.20525 21.9843 9.36674C21.9843 10.5282 21.0455 11.467 19.884 11.467H17.7838V9.36674ZM16.7337 9.36674C16.7337 10.5282 15.7949 11.467 14.6334 11.467C13.4719 11.467 12.5332 10.5282 12.5332 9.36674V4.10024C12.5332 2.93874 13.4719 2 14.6334 2C15.7949 2 16.7337 2.93874 16.7337 4.10024V9.36674Z"
          fill="#2EB67D"
        />
        <path
          d="M14.6334 17.7836C15.7949 17.7836 16.7337 18.7223 16.7337 19.8838C16.7337 21.0453 15.7949 21.984 14.6334 21.984C13.4719 21.984 12.5332 21.0453 12.5332 19.8838V17.7836H14.6334ZM14.6334 16.7334C13.4719 16.7334 12.5332 15.7947 12.5332 14.6332C12.5332 13.4717 13.4719 12.533 14.6334 12.533H19.8999C21.0614 12.533 22.0002 13.4717 22.0002 14.6332C22.0002 15.7947 21.0614 16.7334 19.8999 16.7334H14.6334Z"
          fill="#ECB22E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2035_2832">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
