import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, LinearProgress, Tab, Tabs, Typography, linearProgressClasses, keyframes, Popover, IconButton, Menu, MenuItem } from '@mui/material';
import { Icons } from 'components';
import dayjs, { Dayjs } from 'lib/dayjs';
import {
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  createCategoryV1CategoryPost,
  createFrequentWorkV1FrequentworksPost,
  createRecordV1RecordPost,
  createTaskboxV1TaskboxesPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  getADateRecordV1RecordDateDateGet,
  getAllStateStorageV1StateStorageGet,
  getEventsV1EventsGet,
  mergeWorkOrWorkBoxesV2V2WorksMergePost,
  readAutocompleteListV1AutocompleteGet,
  readFrequentWorksV1FrequentworksGet,
  readTaskboxesV1TaskboxesGet,
  removeTaskV1WorksWorkIdDelete,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  removeTaskboxesV1TaskboxesDelete,
  updateBulkTaskboxesV1TaskboxesBulkPut,
  updateCategoryV1CategoryCategoryIdPatch,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
  updateRecordV1RecordRecordIdPatch,
  updateStateStorageV1StateStoragePatch,
  updateSubtaskV1TasksTaskIdPatch,
  updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  updateTaskboxV1TaskboxesTaskboxIdPut,
} from 'queries';
import {
  CreateFrequentWork,
  CreateFrequentWorkTask,
  CreateTaskbox,
  CreateTaskboxTask,
  DateTime,
  InCreateRecord,
  InUpdateRecord,
  OutAutoComplete,
  OutCategory,
  OutFrequentWork,
  OutRecord,
  OutTaskboxDetailResponse,
  OutTaskboxDetailResponseForSubtask,
  RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
  RemoveTaskboxesV1TaskboxesDeleteParams,
  UpdateBulkTaskbox,
  UpdateTaskForTaskBox,
} from 'queries/model';
import { useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_5 } from 'utils/datetimeFormat';
import TaskboxBoardView from './TaskboxBoardView';
import ReflectionView from './ReflectionView';
import StatView from './StatView';
import CalendarView, { CustomEvent } from './CalendarView';
import { useAtom } from 'jotai';
import { dragContextAtom } from 'atoms/works';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { pick, update } from 'lodash';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { AutoCompleteWorkList } from './components/CreateTaskboxInput';
import { DragStart, DropResult } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import InboxContextMenu, { InboxContextMenuType } from 'components/InboxContextMenu';
import { RemoveRecurrenceDialog } from 'components/Recurrence/RemoveRecurrenceDialog';
import { categoryAtom } from 'atoms/category';
import { startNowPopupVisibleAtom } from 'atoms/popup';
import { changedProjectIdAtom, fetchInstancesFromProjectDetailViewAtom, projectsAtom } from 'atoms/projects';
import { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { GAEventTrigger } from 'lib/gtag';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 74px;
  min-width: 950px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid ${COLORS.gray300};
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
`;

const HeaderProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderProgressButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizeContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 74px);
  display: flex;
`;

const TabPanel = styled.div`
  height: 100%;
  width: 100%;
`;

const TaskboxDragImage = styled.div`
  width: 183px;
  height: 100px;
  cursor: pointer;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray300};
  border-radius: 8px;
  padding: 5px 6px 5px 8px;
  display: flex;
  position: absolute;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxDragImageLine = styled.div<{ taskboxDone?: boolean; taskboxRecurrence?: boolean }>`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.brand1};
  border-radius: 8px;
  margin-right: 8px;

  ${(props) =>
    props.taskboxDone &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.taskboxRecurrence &&
    css`
      background-color: ${COLORS.sub4};
    `}
`;

const TaskboxDragImageTitle = styled.div<{ taskboxDone?: boolean; taskboxRecurrence?: boolean }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #1f2023;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  ${(props) =>
    props.taskboxDone &&
    css`
      opacity: 0.5;
      text-decoration: line-through;
    `}
`;

const TaskboxMultiSelectDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxMultiSelectDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid ${COLORS.brand1};
  background: ${COLORS.brand1};
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

export type MultiSelectType = typeof MultiSelect[keyof typeof MultiSelect];

export const MultiSelect = {
  TOMORROW: 'TOMORROW',
  NEXT_WEEK: 'NEXT_WEEK',
  LATER: 'LATER',
  CALENDAR: 'CALENDAR',
  MERGE: 'MERGE',
  DELETE: 'DELETE',
} as const;

const OrganizeToday = () => {
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [taskboxes, setTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [record, setRecord] = useState<OutRecord>();
  const [taskboxDragImage, setTaskboxDragImage] = useState<OutTaskboxDetailResponse>();
  const [entireTaskboxList, setEntireTaskboxList] = useState<OutAutoComplete[]>([]);
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteWorkList[]>([]);
  const [templateList, setTemplateList] = useState<OutFrequentWork[]>([]);
  const [newTaskbox, setNewTaskbox] = useState<CreateTaskbox & { allDay: boolean }>();
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();
  const [multiSelectTaskboxIds, setMultiSelectTaskboxIds] = useState<string[]>([]);
  const [multiSelectAnchorEl, setMultiSelectAnchorEl] = useState<HTMLElement | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [calendarEventEditing, setCalendarEventEditing] = useState(false);
  const [updateRecurringTaskboxParams, setUpdateRecurringTaskboxParams] =
    useState<{ eventId: string; start: DateTime | null; end: DateTime | null } | null>(null);
  const [taskViewDragContext, setTaskViewDragContext] = useAtom(dragContextAtom);
  const [continueTaskbox, setContinueTaskbox] = useState<boolean>(
    localStorage.getItem('ritual-continue') === null ? true : Boolean(JSON.parse(localStorage.getItem('ritual-continue')!)),
  );
  const [animation, setAnimation] = useState(true);
  const [taskboxDetailPopover, setTaskboxDetailPopover] = useState<HTMLDivElement | null>(null);
  const [contextTaskbox, setContextTaskbox] = useState<OutTaskboxDetailResponse>();
  const [removeRecurringTaskboxPopup, setRemoveRecurringTaskboxPopup] = useState(false);
  const refCalendarView = useRef<HTMLDivElement>(null);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [projects] = useAtom(projectsAtom);
  const [fetchInstancesFromProjectDetailView, setFetchInstancesFromProjectDetailView] = useAtom(fetchInstancesFromProjectDetailViewAtom);
  const [changedProjectId, setChangedProjectId] = useAtom(changedProjectIdAtom);
  const [isStartAfter, setIsStartAfter] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [startNowPopupVisible, fetchStartNowPopupVisible] = useAtom(startNowPopupVisibleAtom);
  const [isFirstRitual, setIsFirstRitual] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLElement | null>(null);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    init();
    setTimeout(() => setAnimation(false), 4000);
  }, []);

  useEffect(() => {
    if (contextTaskbox) {
      const currentTaskboxes = taskboxes.filter((taskbox) => taskbox.matchDate?.includes(dayjs(date).format(DATE_FORMAT_4)) && !taskbox.allDay);
      const index = currentTaskboxes.findIndex((item) => item.id === contextTaskbox.id);
      setIsStartAfter(currentTaskboxes[index - 1] ? true : false);
    } else {
      setIsStartAfter(false);
    }
  }, [contextTaskbox]);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement;

      if (ev.key === 'Escape') {
        if (newTaskbox) {
          setCalendarEventEditing(false);
          setNewTaskbox(undefined);
        }

        if (selectedEventId) {
          (element as HTMLElement).blur();
          setSelectedEventId(undefined);
        }
      }
    },
    [],

    { event: 'keydown', eventOptions: { passive: true } },
  );

  useClickOutside(
    refCalendarView,
    () => {
      if (!calendarEventEditing) {
        setSelectedEventId(undefined);
      }

      if (newTaskbox && !calendarEventEditing) {
        setNewTaskbox(undefined);
      }
    },
    ['mouseup'],
  );

  useUpdateEffect(() => {
    if (fetchInstancesFromProjectDetailView) {
      if (taskboxes.filter((v) => v.project && v.project.id === changedProjectId).length !== 0) {
        updateTaskboxes(date);
      }
      setChangedProjectId(null);
      setFetchInstancesFromProjectDetailView(false);
    }
  }, [fetchInstancesFromProjectDetailView]);

  const calendarEvents = useMemo(() => {
    const meetingEvents: CustomEvent[] = meetings.map((item: any) => ({
      id: item.eventId || '',
      title: item.title || '',
      start: dayjs(item.startTime).toDate(),
      end: item.allDay ? dayjs(item.startTime).toDate() : dayjs(item.endTime).toDate(),
      data: item,
      type: 'meeting',
      allDay: item.allDay,
    }));

    const taskboxEvents: CustomEvent[] = taskboxes.map((item: OutTaskboxDetailResponse) => ({
      id: item.id || '',
      title: item.title || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime).toDate(),
      data: item.tasks,
      type: 'task',
      done: item.done,
      allDay: item.allDay,
      focus: item.focus,
      lockedIn: item.lockedIn,
      isRecurrence: item.isRecurrence,
      isProject: !!item.project,
      category: item.category,
      durationMin: item.durationMin,
    }));

    const newTaskboxEvent: CustomEvent[] =
      newTaskbox && newTaskbox.id
        ? [
            {
              id: newTaskbox.id,
              title: newTaskbox.title || '',
              start: newTaskbox.start?.date ? dayjs(newTaskbox.start?.date).toDate() : dayjs(newTaskbox.start?.datetime).toDate(),
              end: newTaskbox.end?.date ? dayjs(newTaskbox.end?.date).toDate() : dayjs(newTaskbox.end?.datetime).toDate(),
              type: 'task',
              allDay: Boolean(newTaskbox.allDay),
              category: newTaskbox.categoryId && newTaskbox.categoryId.length > 0 ? categoryList.find((v) => v.id === newTaskbox.categoryId![0]) : null,
            },
          ]
        : [];

    return [...meetingEvents, ...taskboxEvents, ...newTaskboxEvent];
  }, [meetings, taskboxes, newTaskbox]);

  const init = async () => {
    await refresh(dayjs(date).toDate());
    await fetchRecords();
    await fetchAutoCompleteList();
    await fetchAllTaskboxes();
    await fetchCategoryList();
    await fetchTemplateList();
    await fetchIsFirstRitual();
    setContinueTaskbox(localStorage.getItem('ritual-continue') === null ? true : Boolean(JSON.parse(localStorage.getItem('ritual-continue')!)));
    setRender(true);
  };

  const refresh = async (date: Date, refresh?: boolean) => {
    const meetings = await fetchMeetings(date, refresh);
    const taskboxes = await fetchTaskboxes(date);
    setMeetings(meetings);
    setTaskboxes(taskboxes);
  };

  const handleRefresh = async () => {
    if (isLoading) return;
    setMeetings([]);
    setTaskboxes([]);
    setLoading(true);
    await refresh(date, true);
    setLoading(false);
  };

  const fetchIsFirstRitual = async () => {
    const firstRitual = await getAllStateStorageV1StateStorageGet();
    const hasSeenReflection = firstRitual?.data?.hasSeenReflection ?? false;
    setIsFirstRitual(!hasSeenReflection);
  };

  const fetchMeetings = async (date: Date, refresh?: boolean) => {
    const meetings = await getEventsV1EventsGet({
      startTime: dayjs(date).startOf('day').format(DATE_FORMAT_1),
      endTime: dayjs(date).endOf('day').format(DATE_FORMAT_1),
      refresh,
    });
    return meetings.data || [];
  };

  const fetchTaskboxes = async (date: Date) => {
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const datas = await readTaskboxesV1TaskboxesGet({
      start_date: formattedDate,
      end_date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4),
    });

    const taskboxes = datas.filter((data) => data.matchDate?.includes(formattedDate));

    const multiDayTaskboxes = taskboxes.filter((item) => item.type === 'TERM_TASK');
    const allDayTaskboxes = taskboxes.filter((item) => item.type !== 'TERM_TASK' && item.allDay);
    const timeTaskboxes = taskboxes.filter((item) => item.type !== 'TERM_TASK' && !item.allDay);
    return [...multiDayTaskboxes, ...allDayTaskboxes, ...timeTaskboxes];
  };

  const fetchRecords = async () => {
    try {
      const record = await getADateRecordV1RecordDateDateGet(dayjs(date).format('YYYY-MM-DD'));
      if (record) {
        setRecord(record);
      }
    } catch (error) {
      const emptyRecord: OutRecord = {
        id: uuidv4(),
        date: dayjs(date).format('YYYY-MM-DD'),
        content: { memo: '', keep: '', problem: '', try: '' },
        status: 'ACTIVE',
        createdAt: '',
        updatedAt: '',
        comboHit: 0,
      };
      setRecord(emptyRecord);
    }
  };

  //오토컴플릿
  const fetchAutoCompleteList = async (title?: string) => {
    const param = title ? { title } : undefined;
    const data: OutAutoComplete[] = await readAutocompleteListV1AutocompleteGet(param);
    const incompleteWorkList = data
      .filter((item) => item.done === false && dayjs(item.start?.date || item.start?.datetime).isBefore(dayjs(date).subtract(1, 'day')))
      .map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_5) }))
      .sort((a, b) => (a.date > b.date ? -1 : 1));
    setAutoCompleteList([...incompleteWorkList]);
  };

  const fetchAllTaskboxes = async () => {
    const param = undefined;
    const data: OutAutoComplete[] = await readAutocompleteListV1AutocompleteGet(param);
    setEntireTaskboxList(data);
  };

  const fetchTemplateList = async () => {
    const data = await readFrequentWorksV1FrequentworksGet();
    setTemplateList(data);
  };

  const updateTaskboxes = async (date: Date) => {
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const datas = await readTaskboxesV1TaskboxesGet({
      start_date: formattedDate,
      end_date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4),
    });
    const taskboxes = datas.filter((data) => data.matchDate?.includes(formattedDate));
    setTaskboxes(taskboxes);
  };

  const handleChangeSubtask = (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => {
    const index = taskboxes.findIndex((taskbox) => taskbox.id === id);
    taskboxes[index].tasks = tasks;
    setTaskboxes([...taskboxes]);
  };

  const handleSelectEvent = async (eventId: string) => {
    const event = calendarEvents.find((item) => item.id === eventId);
    setSelectedEventId(event?.id || undefined);
  };

  const handleUpdateEvent = async ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => {
    if (dayjs(startTime).isBefore(dayjs(date).add(5, 'minute')) && dayjs(startTime).isAfter(dayjs(date).subtract(5, 'minute'))) {
      if (startNowPopupVisible && startNowPopupVisible.data.popup === undefined) {
        await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(startNowPopupVisible.id, {
          data: { popup: true },
        });
        fetchStartNowPopupVisible();
      }
    }

    if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가

    const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
    const end = isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime };

    if (newTaskbox && newTaskbox.id === eventId) {
      setNewTaskbox({ ...newTaskbox, start, end, allDay: isAllDay });
      return;
    }

    const taskbox = taskboxes.find((item) => item.id === eventId);
    if (!taskbox) return;
    if (taskbox.type === 'TERM_TASK') {
      toast.error('종료일이 지정된 업무는 시간 설정을 할 수 없습니다.');
      return;
    }
    if (taskbox?.isRecurrence) {
      setUpdateRecurringTaskboxParams({ eventId: taskbox.id!, start: { datetime: startTime }, end: { datetime: endTime } });
    } else {
      setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...taskbox, start, end, allDay: isAllDay } : item)));
      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        ...pick(taskbox, ['title', 'lockedIn']),
        start,
        end,
        tasks: taskbox.tasks as UpdateTaskForTaskBox[],
      });

      updateTaskboxes(date);
      taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
    }
  };

  const handleUpdateEventTitle = async ({ eventId, title }: { eventId: string; title: string; isAllDay: boolean }) => {
    if (calendarEventEditing) setCalendarEventEditing(false);
    if (newTaskbox && newTaskbox.id === eventId) {
      if (!title) return;

      const result = await createTaskboxV1TaskboxesPost({ ...newTaskbox, id: newTaskbox.id || uuidv4(), title: title });
      if (result) {
        updateTaskboxes(date);
        setNewTaskbox(undefined);
        toast.success('새로운 태스크박스를 생성하였습니다.');
      }
    } else {
      const taskbox = taskboxes.find((item) => item.id === eventId);
      if (!taskbox) return;
      if (title === taskbox.title) return;

      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        ...pick(taskbox, ['lockedIn', 'start', 'end']),
        title,
        tasks: taskbox.tasks as UpdateTaskForTaskBox[],
      });

      updateTaskboxes(date);
      taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
    }
  };

  const handleChangeEventEditing = () => {
    setCalendarEventEditing(true);
  };

  const handleClickTimeSlot = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    const id = uuidv4();
    setNewTaskbox((prevTaskbox) => {
      if (prevTaskbox)
        return {
          ...prevTaskbox,
          id: id,
          start: isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime },
          end: isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime },
          allDay: isAllDay,
        };
      else
        return {
          id: id,
          title: '',
          tasks: [],
          start: isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime },
          end: isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime },
          allDay: isAllDay,
        };
    });
    setTimeout(() => setSelectedEventId(id), 100);
  };

  const handleDropFromOutside = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    const offsetMin = +dayjs(endTime).add(15, 'minutes') >= +dayjs(date).endOf('day') ? 0 : 30;
    if (!taskViewDragContext) return;

    const { id, type, title, view, data } = taskViewDragContext;
    const taskList: { id: string; content: string; type: string }[] = [];

    if (Array.isArray(data)) {
      for (const item of data) {
        taskList.push({ id: item.id, content: item.title, type: item.type });
      }
    }

    if (type === 'multi-taskbox' && Array.isArray(data) && data.length > 0) {
      const diffDay = dayjs(startTime).diff(endTime, 'day');
      if (Math.abs(diffDay) > 0) return;

      const updateTaskbox = async (taskbox: OutTaskboxDetailResponse) => {
        const diffTime = dayjs(taskbox.end?.datetime).diff(taskbox.start?.datetime, 'minute');
        const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
        const end = isAllDay
          ? { date: dayjs(endTime).format(DATE_FORMAT_4) }
          : {
              datetime: dayjs(startTime)
                .add(taskbox.durationMin ? taskbox.durationMin : 60, 'minute')
                .format(DATE_FORMAT_1),
            };

        if (taskbox.start?.datetime && taskbox.end?.datetime) {
          end.datetime = dayjs(startTime).add(diffTime, 'minute').format(DATE_FORMAT_1);
        }

        if (taskbox.isRecurrence) {
          setUpdateRecurringTaskboxParams({
            eventId: taskbox.id!,
            start: start,
            end: end,
          });
        } else {
          const result = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end, tasks: taskbox.tasks as UpdateTaskForTaskBox[] });
          if (result) {
            updateTaskboxes(date);
            taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
          }
        }
      };

      const taskboxList = taskboxes.filter((item) => data.includes(item.id));
      await Promise.all(taskboxList.map(updateTaskbox));
    } else {
      const taskbox = taskboxes.find((item) => item.id === id);
      if (taskbox) {
        if (taskbox.type === 'TERM_TASK') {
          toast.error('종료일이 지정된 업무는 시간 설정을 할 수 없습니다.');
          return;
        }
        const diffTime = dayjs(taskbox.end?.datetime).diff(taskbox.start?.datetime, 'minute');
        const diffDay = dayjs(startTime).diff(endTime, 'day');
        if (Math.abs(diffDay) > 0) return;

        const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
        const end = isAllDay
          ? { date: dayjs(endTime).format(DATE_FORMAT_4) }
          : {
              datetime: dayjs(startTime)
                .add(taskbox.durationMin ? taskbox.durationMin : 60, 'minute')
                .format(DATE_FORMAT_1),
            };

        if (taskbox.start?.datetime && taskbox.end?.datetime) {
          end.datetime = dayjs(startTime).add(diffTime, 'minute').format(DATE_FORMAT_1);
        }

        if (taskbox?.isRecurrence) {
          setUpdateRecurringTaskboxParams({
            eventId: taskbox.id!,
            start: start,
            end: end,
          });
        } else {
          const result = await updateTaskboxV1TaskboxesTaskboxIdPut(id!, {
            ...taskbox,
            start: start,
            end: end,
            tasks: taskbox.tasks as UpdateTaskForTaskBox[],
          });
          if (result) {
            updateTaskboxes(date);
            taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
          }
        }
      } else {
        const result = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: title || '',
          start: { datetime: startTime },
          end: { datetime: dayjs(startTime).add(60, 'minute').format(DATE_FORMAT_1) },
          issueId: type === 'issue' ? id : undefined,
        });

        if (result && result.id) {
          if (Array.isArray(data)) {
            const tasks = data.map((item) => ({ ...item, id: uuidv4(), type: 'task' }));
            await updateTaskboxV1TaskboxesTaskboxIdPut(result.id, { tasks: tasks });
            for (const item of data) {
              if (item.id) await removeTaskV1WorksWorkIdDelete(item.id);
            }
          } else {
            await removeTaskV1WorksWorkIdDelete(id!);
          }
          toast.success('새로운 태스크박스를 생성하였습니다.');
          updateTaskboxes(date);
        }
      }
    }
  };

  const handleCreateTaskbox = async (taskbox: CreateTaskbox) => {
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const result = await createTaskboxV1TaskboxesPost({
      id: uuidv4(),
      title: taskbox.title,
      start: { date: formattedDate },
      end: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
      done: taskbox.done,
      categoryId: taskbox.categoryId,
      deadline: taskbox.deadline,
    });

    if (result) {
      toast.success(taskbox.done ? '완료한 일을 추가하였습니다.' : '미완료한 일을 추가하였습니다.');
      updateTaskboxes(date);
    }
  };

  //태스크박스 드래그 시 이미지 대체 로직
  const handleChangeTaskboxImage = (taskbox: OutTaskboxDetailResponse) => {
    setTaskboxDragImage(taskbox);
  };

  const handleDropTaskbox = async (taskbox: OutTaskboxDetailResponse, done: boolean) => {
    if (!taskbox) return;
    const newTasks = taskbox.tasks!.map((item) => ({ ...item, done }));
    const result = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, done, tasks: newTasks as UpdateTaskForTaskBox[] });
    if (result) {
      toast.success(done ? '완료한 일을 추가하였습니다.' : '미완료한 일을 추가하였습니다.');
      updateTaskboxes(date);
    }
  };

  const handleDropMultiSelectTaskbox = async (taskboxes: OutTaskboxDetailResponse[], done: boolean) => {
    if (!taskboxes) return;
    const updateBultTaskbox = taskboxes.map((item) => ({ ...item, id: item.id!, done, tasks: item.tasks!.map((task) => ({ ...task, done })) }));
    await updateBulkTaskboxesV1TaskboxesBulkPut(updateBultTaskbox as UpdateBulkTaskbox[]);
    toast.success(done ? '완료한 일을 추가하였습니다.' : '미완료한 일을 추가하였습니다.');
    updateTaskboxes(date);
  };

  const handleBeautifulDragStart = (start: DragStart) => {
    const targetTaskbox = taskboxes.find((item) => item.id === start.draggableId);
    if (targetTaskbox?.type === 'TERM_TASK') {
      toast.error('종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.');
      return;
    }
  };

  const handleBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;
    if (result.source.droppableId === 'all-taskbox') {
      if (taskboxes[result.source.index].type === 'TERM_TASK') return;
      if (taskboxes[result.destination.index].type === 'TERM_TASK') {
        toast.error('종료일이 지정된 태스크박스는 순서를 변경할 수 없어요.');
        return;
      }

      const draggableTaskbox = taskboxes.find((item) => item.id === result.draggableId);
      if (!draggableTaskbox) return;
      if (draggableTaskbox?.done) {
        const doneTaskboxes = taskboxes.filter((item) => item.done === true);
        const destinationTaskbox = doneTaskboxes[result.destination.index];
        const departureIndex = taskboxes.findIndex((item) => item.id === draggableTaskbox.id);
        const destinationIndex = taskboxes.findIndex((item) => item.id === destinationTaskbox.id);
        const newTaskbox = taskboxes.filter((item) => item.id !== draggableTaskbox.id);

        newTaskbox.splice(destinationIndex, 0, draggableTaskbox);
        setTaskboxes([...newTaskbox]);

        if (departureIndex < destinationIndex) {
          const beforeId = destinationTaskbox.id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(draggableTaskbox.id!, { beforeId });
        } else {
          const beforeId = destinationIndex - 1 < 0 ? null : taskboxes[destinationIndex - 1].id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(draggableTaskbox.id!, { beforeId });
        }
      } else {
        const undoneTaskboxes = taskboxes.filter((item) => item.done === false);
        const destinationTaskbox = undoneTaskboxes[result.destination.index];
        const departureIndex = taskboxes.findIndex((item) => item.id === draggableTaskbox.id);
        const destinationIndex = taskboxes.findIndex((item) => item.id === destinationTaskbox.id);
        const newTaskbox = taskboxes.filter((item) => item.id !== draggableTaskbox.id);

        newTaskbox.splice(destinationIndex, 0, draggableTaskbox);
        setTaskboxes([...newTaskbox]);

        if (departureIndex < destinationIndex) {
          const beforeId = destinationTaskbox.id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(draggableTaskbox.id!, { beforeId });
        } else {
          const beforeId = destinationIndex - 1 < 0 ? null : taskboxes[destinationIndex - 1].id;
          await updateTaskboxV1TaskboxesTaskboxIdPut(draggableTaskbox.id!, { beforeId });
        }
      }
      return;
    }

    const startTaskboxIndex = taskboxes.findIndex((item) => item.id === result.source.droppableId);
    const startTaskBox = taskboxes.find((item) => item.id === result.source.droppableId);
    const endTaskboxIndex = taskboxes.findIndex((item) => item.id === result.destination!.droppableId);
    const endTaskBox = taskboxes.find((item) => item.id === result.destination!.droppableId);

    if (startTaskBox && endTaskBox) {
      if (startTaskBox === endTaskBox) {
        const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
        if (!srcTask) return;

        const dstTask = startTaskBox.tasks![result.destination.index];
        if (!dstTask) return;

        const srcTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === srcTask.id);
        if (srcTaskIndex === -1) return;
        const dstTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === dstTask.id);
        if (dstTaskIndex === -1) return;

        const newTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
        newTasks.splice(dstTaskIndex, 0, srcTask);

        startTaskBox.tasks = newTasks;
        taskboxes[startTaskboxIndex] = startTaskBox;
        setTaskboxes([...taskboxes]);

        if (srcTaskIndex < dstTaskIndex) {
          await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id!, { ...srcTask, beforeId: dstTask.id });
        } else {
          const id = dstTaskIndex - 1 >= 0 ? startTaskBox.tasks[dstTaskIndex - 1].id : null;
          await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id!, { ...srcTask, beforeId: id });
        }
      } else {
        const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
        if (!srcTask) return;

        const startNewTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
        startTaskBox.tasks = startNewTasks;
        taskboxes[startTaskboxIndex] = startTaskBox;

        const endNewTasks = endTaskBox.tasks;
        endNewTasks!.splice(result.destination.index, 0, srcTask);

        endTaskBox.tasks = endNewTasks;
        taskboxes[endTaskboxIndex] = endTaskBox;

        setTaskboxes([...taskboxes]);

        const id = result.destination.index - 1 < 0 ? null : endTaskBox.tasks![result.destination.index - 1].id;
        await assignTasksV1TaskboxesTaskboxIdAssignPost(endTaskBox.id!, { beforeId: id, tasks: [srcTask.id!] });
        if (srcTask.done === true) {
          endTaskBox.tasks![result.destination.index].done = true;
          await updateSubtaskV1TasksTaskIdPatch(endTaskBox.tasks![result.destination.index].id!, endTaskBox.tasks![result.destination.index]);
        }
      }
    }
  };

  const handleCloseMultiSelect = () => {
    setMultiSelectAnchorEl(null);
    setMultiSelectTaskboxIds([]);
  };

  const handleChangeMultiSelect = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    if (taskboxes.find((item) => item.id === id)?.type === 'TERM_TASK') {
      toast.error('종료일이 지정된 업무는 다중 선택을 할 수 없습니다.');
      return;
    }

    if (e.ctrlKey || e.metaKey) {
      if (multiSelectTaskboxIds.includes(id)) {
        setMultiSelectTaskboxIds(multiSelectTaskboxIds.filter((item) => item !== id));
      } else {
        setMultiSelectTaskboxIds([...multiSelectTaskboxIds, id]);
        setMultiSelectAnchorEl(e.currentTarget);
      }
    }
  };

  const handleClickMultiSelectActions = (type: MultiSelectType, date?: Dayjs) => {
    switch (type) {
      case 'TOMORROW':
        handleClickMultiSelectTomorrow();
        break;
      case 'NEXT_WEEK':
        handleClickMultiSelectNextWeek();
        break;
      case 'LATER':
        handleClickMultiSelectLater();
        break;
      case 'CALENDAR':
        handleClickMultiSelectCalendar(date!);
        break;
      case 'DELETE':
        handleClickMultiSelectDelete();
        break;
      case 'MERGE':
        handleClickMultiSelectMerge();
        break;
    }
  };

  /** 멀티 셀렉된 태스크박스 다음날로 옮기기 */
  const handleClickMultiSelectTomorrow = async () => {
    handleCloseMultiSelect();
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const newDate = dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4);
    const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectTaskboxIds.map((id) => {
      return { id, start: { date: newDate }, end: { date: newDate } };
    });
    await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);
    toast.success('선택된 태스크박스를 다음 날로 옮겼습니다.');
  };

  /** 멀티 셀렉된 태스크박스 다음주로 옮기기 */
  const handleClickMultiSelectNextWeek = async () => {
    handleCloseMultiSelect();
    const newDate = dayjs(date).add(1, 'week').day(1).format(DATE_FORMAT_4);
    const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectTaskboxIds.map((id) => {
      return { id, start: { date: newDate }, end: { date: newDate } };
    });
    await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);
    toast.success('선택된 태스크박스를 다음주 월요일로 옮겼습니다.');
  };

  /** 멀티 셀렉된 태스크박스 나중에로 옮기기 */
  const handleClickMultiSelectLater = async () => {
    //TODO
    // 완료된 하위태스크는 빼고 나중에로 옮기기
    handleCloseMultiSelect();
    const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectTaskboxIds.map((id) => {
      return { id, workSectionType: 'someday' };
    });
    await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);
    toast.success('선택된 태스크박스를 나중에로 옮겼습니다.');
  };

  /** 멀티셀렉된 태스크박스 특정 일자로 미루는 로직 */
  const handleClickMultiSelectCalendar = async (value: Dayjs) => {
    handleCloseMultiSelect();
    const newDate = dayjs(value).format(DATE_FORMAT_4);
    const bulkTaskbox: UpdateBulkTaskbox[] = multiSelectTaskboxIds.map((id) => {
      return { id, start: { date: newDate }, end: { date: newDate } };
    });
    await updateBulkTaskboxesV1TaskboxesBulkPut(bulkTaskbox);
    toast.success(`선택된 태스크박스를 ${dayjs(value).format(DATE_FORMAT_5)}로 옮겼습니다.`);
  };

  /** 멀티셀렉된 태스크박스 삭제 */
  const handleClickMultiSelectDelete = async () => {
    handleCloseMultiSelect();
    const params: RemoveTaskboxesV1TaskboxesDeleteParams = {
      id: multiSelectTaskboxIds,
    };

    const options = {
      paramsSerializer: (params: any) => {
        if (Array.isArray(params.id)) {
          return params.id.map((id: any) => `id=${id}`).join('&');
        }
      },
    };

    await removeTaskboxesV1TaskboxesDelete(params, options);
    toast.success('선택된 태스크박스를 삭제했습니다.');
    updateTaskboxes(date);
  };

  /** 멀티셀렉된 태스크박스 합치기 */
  const handleClickMultiSelectMerge = async () => {
    handleCloseMultiSelect();
    const success = await mergeWorkOrWorkBoxesV2V2WorksMergePost({ taskboxIds: multiSelectTaskboxIds });
    if (success) {
      toast.success('선택된 태스크박스를 합쳤습니다.');
      updateTaskboxes(date);
    }
  };

  /**멀티 셀렉된 태스크박스 드래그 시작 */
  const handleMultiSelectDragStart = (e: React.DragEvent<HTMLDivElement>, done: boolean) => {
    setMultiSelectAnchorEl(null);
    const el = document.querySelector('#grouping-taskbox-drag-image');
    e.dataTransfer.setDragImage(el!, 80, 3);
    setTaskViewDragContext({ type: 'multi-taskbox', view: 'taskboard', data: multiSelectTaskboxIds, done });
  };

  /**멀티 셀렉된 태스크박스 드래그 끝 */
  const handleMultiSelectDragEnd = () => {
    handleCloseMultiSelect();
    setTaskViewDragContext(null);
  };

  const handleChangeContinueToggle = () => {
    setContinueTaskbox(!continueTaskbox);
    localStorage.setItem('ritual-continue', JSON.stringify(!continueTaskbox));
  };

  //이어서 하기 로직
  const handleChangeContinueTaskbox = async () => {
    const formattedDate = dayjs(date).format(DATE_FORMAT_4);
    const nextDayTaskboxes = await readTaskboxesV1TaskboxesGet({
      start_date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4),
      end_date: dayjs(formattedDate).add(2, 'day').format(DATE_FORMAT_4),
    });

    const undoneTaskboxes = taskboxes.filter((taskbox) => !taskbox.done && taskbox.type !== 'TERM_TASK' && !taskbox.isRecurrence);
    await Promise.all(
      undoneTaskboxes.map(async (taskbox) => {
        if (taskbox.tasks!.filter((task) => !task.done).length !== taskbox.tasks!.length) {
          const updateTaskbox = {
            ...taskbox,
            done: true,
            tasks: taskbox.tasks!.filter((task) => task.done) as UpdateTaskForTaskBox[],
          };
          const createTaskbox: CreateTaskbox = {
            ...taskbox,
            id: uuidv4(),
            title: taskbox.title!,
            start: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
            end: { date: dayjs(formattedDate).add(2, 'day').format(DATE_FORMAT_4) },
            tasks: taskbox.tasks!.filter((task) => !task.done).map((task) => ({ ...task, id: uuidv4() })) as CreateTaskboxTask[],
            projectId: taskbox.project ? [taskbox.project.id] : undefined,
          };
          await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, updateTaskbox);
          const create = await createTaskboxV1TaskboxesPost(createTaskbox);

          if (taskbox.project && nextDayTaskboxes.some((item) => item.project?.id === taskbox.project?.id && item.allDay) && create) {
            const sameProjectInstance = nextDayTaskboxes.find((item) => item.project?.id === taskbox.project?.id);
            if (sameProjectInstance) await assignTasksV1TaskboxesTaskboxIdAssignPost(sameProjectInstance.id!, { tasks: [create.id!] });
          }
        } else {
          if (taskbox.project && nextDayTaskboxes.some((item) => item.project?.id === taskbox.project?.id && item.allDay)) {
            const sameProjectInstance = nextDayTaskboxes.find((item) => item.project?.id === taskbox.project?.id);
            if (sameProjectInstance) await assignTasksV1TaskboxesTaskboxIdAssignPost(sameProjectInstance.id!, { tasks: [taskbox.id!] });
          } else {
            const updateTaskbox = {
              ...taskbox,
              start: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
              end: { date: dayjs(formattedDate).add(2, 'day').format(DATE_FORMAT_4) },
              tasks: taskbox.tasks as UpdateTaskForTaskBox[],
            };
            await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, updateTaskbox);
          }
        }
      }),
    );
    navigate(`/task`);
  };

  const handleSaveReflection = async (record: OutRecord) => {
    if (record.createdAt === '') {
      const inCreateRecord: InCreateRecord = {
        id: record.id,
        content: record.content,
        date: record.date,
      };
      await createRecordV1RecordPost(inCreateRecord);
    } else {
      const inUpdateRecord: InUpdateRecord = {
        content: record.content,
        date: record.date,
        status: 'ACTIVE',
      };
      await updateRecordV1RecordRecordIdPatch(record.id, inUpdateRecord);
    }
  };

  const handleContextMenu = async (eventId: string) => {
    const event = calendarEvents.find((item) => item.id === eventId);
    if (!event) return;

    const taskbox = taskboxes.find((item) => item.id === event?.id);
    if (!taskbox) return;

    setTaskboxDetailPopover(document.querySelector(`[data-calendar-id="${eventId}"]`) as HTMLDivElement);
    setContextTaskbox(taskbox);
  };

  const handleCompleteTaskbox = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    const tasks = contextTaskbox.tasks!.map((v) => ({ ...v, done: !contextTaskbox.done }));
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(contextTaskbox!.id!, {
      ...contextTaskbox,
      done: !contextTaskbox.done,
      tasks: tasks as UpdateTaskForTaskBox[],
    });
    if (success) {
      updateTaskboxes(date);
    }
  };

  const handleDuplicateTaskbox = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    const newTasks = contextTaskbox.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] })) as CreateTaskboxTask[];
    const newTaskbox: CreateTaskbox = {
      ...contextTaskbox,
      id: uuidv4(),
      title: contextTaskbox.title!,
      categoryId: contextTaskbox.category ? [contextTaskbox.category.id] : [],
      tasks: newTasks,
    };
    const success = await createTaskboxV1TaskboxesPost(newTaskbox);
    if (success) {
      updateTaskboxes(date);
      toast.success('태스크박스가 복제되었습니다.');
    }
  };

  const handleRemoveTaskbox = async () => {
    if (!contextTaskbox) return;
    if (contextTaskbox?.isRecurrence) {
      setRemoveRecurringTaskboxPopup(true);
      return;
    }
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    await removeTaskboxV1TaskboxesTaskboxIdDelete(contextTaskbox!.id!);
    toast.success('태스크박스를 삭제하였습니다.');
    updateTaskboxes(date);
  };

  const handleRemoveRecurrenceTaskbox = async (params: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
    if (!contextTaskbox) return;
    setRemoveRecurringTaskboxPopup(false);
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    await removeTaskboxV1TaskboxesTaskboxIdDelete(contextTaskbox.id!, params);
    toast.success('반복 업무를 삭제하였습니다.');
    updateTaskboxes(date);
  };

  const handleCreateTemplate = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    const newTemplate: CreateFrequentWork = {
      id: uuidv4(),
      title: contextTaskbox?.title,
      tasks: contextTaskbox!.tasks!.map((item) => ({ ...item, id: uuidv4(), categoryId: item.category ? [item.category.id] : [] })) as CreateFrequentWorkTask[],
    };
    const success = await createFrequentWorkV1FrequentworksPost(newTemplate);
    if (success) {
      fetchAllTaskboxes();
      toast.success('템플릿 목록에 추가되었습니다.');
    }
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'START_NOW':
          handleStartNow();
          break;
        case 'START_AFTER':
          handleStartAfter();
          break;
        case 'ADD_TIME':
          handleAddTime();
          break;
        case 'COMPLETE_NOW':
          handleCompleteNow();
          break;
        case 'COMPLETE':
          handleCompleteTaskbox();
          break;
        case 'DELETE':
          handleRemoveTaskbox();
          break;
        case 'TEMPLATE':
          handleCreateTemplate();
          break;
        case 'DUPLICATE':
          handleDuplicateTaskbox();
          break;
        case 'CONVERT_TO_PROJECT':
          handleConvertToProject();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
  };

  const handleConvertToProject = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);

    try {
      await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(contextTaskbox.id!);
      if (projects.filter((project) => project.place === 'DESK').length >= 7) {
        toast.success('데스크가 가득 차서 서랍에 프로젝트를 생성했습니다.');
      } else {
        toast.success('태스크박스를 프로젝트로 전환했습니다.');
      }
    } catch {
      toast.error('프로젝트로 전환하는데 실패하였습니다.');
    }

    updateTaskboxes(date);
  };

  const handleStartNow = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);

    const timeDiff = dayjs(contextTaskbox?.end?.datetime).diff(dayjs(contextTaskbox?.start?.datetime), 'minute');

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(contextTaskbox!.id!, {
        start: { datetime: dayjs(date, { utc: true }).format(DATE_FORMAT_1) },
        end: {
          datetime: !timeDiff
            ? dayjs(date, { utc: true }).add(1, 'hour').format(DATE_FORMAT_1)
            : dayjs(date, { utc: true }).add(timeDiff, 'minute').format(DATE_FORMAT_1),
        },
      });
    } catch (e) {
      toast.error('지금 시작하기를 할 수 없습니다.');
    }
    updateTaskboxes(date);
  };

  const handleStartAfter = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);

    const timeDiff = dayjs(contextTaskbox?.end?.datetime).diff(dayjs(contextTaskbox?.start?.datetime), 'minute');
    const index = taskboxes.findIndex((item) => item.id === contextTaskbox?.id);
    const beforeTaskbox = taskboxes[index - 1];

    try {
      if (beforeTaskbox) {
        await updateTaskboxV1TaskboxesTaskboxIdPut(contextTaskbox!.id!, {
          start: { datetime: dayjs(beforeTaskbox.end?.datetime, { utc: true }).format(DATE_FORMAT_1) },
          end: { datetime: dayjs(beforeTaskbox.end?.datetime, { utc: true }).add(timeDiff, 'minute').format(DATE_FORMAT_1) },
        });
      }
    } catch (e) {
      toast.error('이어서 시작하기를 할 수 없습니다.');
    }
    updateTaskboxes(date);
  };

  const handleAddTime = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(contextTaskbox!.id!, {
        start: { datetime: dayjs(contextTaskbox.start?.datetime, { utc: true }).format(DATE_FORMAT_1) },
        end: { datetime: dayjs(contextTaskbox.end?.datetime, { utc: true }).add(30, 'minute').format(DATE_FORMAT_1) },
      });
    } catch (e) {
      toast.error('시간을 추가할 수 없습니다.');
    }
    updateTaskboxes(date);
  };

  const handleCompleteNow = async () => {
    if (!contextTaskbox) return;
    setContextTaskbox(undefined);
    setTaskboxDetailPopover(null);
    const timeDiff = dayjs(contextTaskbox?.end?.datetime).diff(dayjs(contextTaskbox?.start?.datetime), 'minute');

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(contextTaskbox!.id!, {
        start: dayjs(contextTaskbox.start?.datetime, { utc: true }).isAfter(dayjs())
          ? { datetime: dayjs().subtract(timeDiff, 'minute').format(DATE_FORMAT_1) }
          : { datetime: dayjs(contextTaskbox.start?.datetime, { utc: true }).format(DATE_FORMAT_1) },
        end: { datetime: dayjs().format(DATE_FORMAT_1) },
        done: true,
      });
    } catch (e) {
      toast.error('지금 완료할 수 없습니다.');
    }
    updateTaskboxes(date);
  };

  const handleClickTaskbox = async (taskbox: OutTaskboxDetailResponse) => {
    setSelectedEventId(taskbox.id);
  };

  const handleClickWrapDay = async () => {
    handleChangeContinueTaskbox();
    if (isFirstRitual) localStorage.setItem('reflection-dialog', JSON.stringify(true));
    await updateStateStorageV1StateStoragePatch({ data: { hasSeenReflection: true } });
  };

  const handleCloseRitual = async () => {
    navigate(`/task`);
  };

  const handleClickCategoryActions = async (eventId: string, category: OutCategory | null, action: CategoryActionType) => {
    switch (action) {
      case 'SELECT':
        {
          if (calendarEventEditing) setCalendarEventEditing(false);
          if (newTaskbox && newTaskbox.id === eventId) {
            setNewTaskbox({ ...newTaskbox, categoryId: category ? [category.id] : [] });
          } else {
            const taskbox = taskboxes.find((item) => item.id === eventId);
            if (!taskbox) return;
            await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
              ...pick(taskbox, ['lockedIn', 'start', 'end', 'title']),
              tasks: taskbox.tasks as UpdateTaskForTaskBox[],
              categoryId: category ? [category.id] : [],
            });
            updateTaskboxes(date);
            taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          const success = await createCategoryV1CategoryPost(category);
          if (success) {
            fetchCategoryList();
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
          if (success) {
            fetchCategoryList();
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
          fetchCategoryList();
        }
        break;
    }
  };

  const contextMenus = (() => {
    if (!contextTaskbox?.project && contextTaskbox?.allDay && !contextTaskbox?.isRecurrence) {
      return ['START_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE', 'PROJECT'];
    }

    if (!contextTaskbox?.project && !contextTaskbox?.allDay && !contextTaskbox?.done && contextTaskbox?.isRecurrence) {
      if (isStartAfter) {
        return ['START_NOW', 'START_AFTER', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE'];
      } else {
        return ['START_NOW', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE'];
      }
    }

    if (!contextTaskbox?.project && !contextTaskbox?.allDay && !contextTaskbox?.done) {
      if (isStartAfter) {
        return ['START_NOW', 'START_AFTER', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE', 'PROJECT'];
      } else {
        return ['START_NOW', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE', 'PROJECT'];
      }
    }

    if (contextTaskbox?.project && !contextTaskbox?.allDay && !contextTaskbox?.done) {
      if (isStartAfter) {
        return ['START_NOW', 'START_AFTER', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE'];
      } else {
        return ['START_NOW', 'ADD_TIME', 'COMPLETE_NOW', 'COMPLETE', 'DELETE'];
      }
    }

    return ['START_NOW', 'COMPLETE', 'DELETE', 'TEMPLATE', 'DUPLICATE'];
  })();

  const handleChangeDate = (date: Date) => {
    setDate(date);
    setDateAnchorEl(null);
  };

  useUpdateEffect(() => {
    refresh(date);
    fetchRecords();
  }, [date]);

  if (animation)
    return (
      <Container>
        <WelcomeAnimation />
      </Container>
    );

  return (
    <Container>
      {render && (
        <>
          <HeaderWrapper>
            <HeaderTitleWrapper>
              {/* 왜 밖에서 한번 사용해야 안쪽 컴포넌트에서 보이지...??? 나중에 탐구해보기 */}
              <div style={{ position: 'absolute', top: '-10000px' }}>
                <Icons.FocusInprogressCheck />
                <Icons.InprogressCheck />
                <Icons.FocusCheck />
                <Icons.FocusUncheck />
              </div>
              <div
                style={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: COLORS.white,
                  borderRadius: 8,
                  marginRight: 8,
                }}
              >
                <Icons.OrganizeToday />
              </div>
              <div>정리하기</div>
            </HeaderTitleWrapper>
            <HeaderProgressWrapper>
              <Button
                endIcon={dateAnchorEl ? <Icons.ArrowUpSmall width={12} height={12} /> : <Icons.ArrowDownSmall width={12} height={12} />}
                onClick={(e) => setDateAnchorEl(e.currentTarget)}
                sx={{
                  color: dayjs(date).isSame(dayjs(date), 'date') ? COLORS.brand1 : COLORS.gray600,
                  padding: '2px 8px',
                  border: `1px solid ${COLORS.gray300}`,
                  borderRadius: '40px',
                  fontSize: '13px',
                  fontWeight: 700,
                  marginRight: '8px',
                }}
              >
                {dayjs(date).isSame(dayjs(), 'date') ? '오늘' : '어제'}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={dateAnchorEl}
                open={Boolean(dateAnchorEl)}
                onClose={() => setDateAnchorEl(null)}
                MenuListProps={{
                  style: {
                    width: '74px',
                    padding: '8px',
                  },
                }}
              >
                <MenuItem
                  onClick={() => handleChangeDate(dayjs().toDate())}
                  sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
                >
                  오늘
                </MenuItem>
                <MenuItem
                  onClick={() => handleChangeDate(dayjs().subtract(1, 'day').toDate())}
                  sx={{ 'borderRadius': '6px', 'fontSize': '12px', 'padding': '8px', ':hover': { backgroundColor: COLORS.gray100 } }}
                >
                  어제
                </MenuItem>
              </Menu>
              <div style={{ display: 'flex', color: dayjs(date).isSame(dayjs(date), 'date') ? COLORS.brand1 : COLORS.gray900, marginRight: 12 }}>
                <div style={{ fontWeight: 700, marginRight: 4 }}> {dayjs(date).format('dd요일')}</div>
                <div>{dayjs(date).format('M월 D일')}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '504px', marginRight: 8 }}>
                  <CustomLinearProgress
                    value={(taskboxes.filter((v) => v.done).length / taskboxes.length) * 100}
                    barColor={
                      taskboxes.filter((v) => v.done).length > 0 && taskboxes.filter((v) => v.done).length < taskboxes.length
                        ? COLORS.brand1
                        : taskboxes.filter((v) => v.done).length > 0 && taskboxes.filter((v) => v.done).length === taskboxes.length
                        ? COLORS.positive1
                        : 'transparent'
                    }
                  />
                </div>
                <div style={{ fontSize: '16px', fontWeight: 700, color: COLORS.brand1 }}>
                  {`(${taskboxes.filter((v) => v.done).length}/${taskboxes.length})`}
                </div>
              </div>
            </HeaderProgressWrapper>
            <HeaderProgressButtonWrapper>
              <Button
                onClick={() => {
                  handleCloseRitual();
                  GAEventTrigger({ action: 'ritual_shutdown_save', category: 'ritual_shutdown_save' });
                }}
                sx={{
                  minWidth: '0px',
                  height: '40px',
                  padding: '14px 16px',
                  borderRadius: '8px',
                  marginRight: '12px',
                  color: COLORS.brand1,
                  backgroundColor: COLORS.white,
                  fontWeight: 700,
                  fontSize: 13,
                }}
              >
                닫기
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleClickWrapDay();
                  GAEventTrigger({ action: 'ritual_shutdown_complete', category: 'ritual_shutdown_complete' });
                }}
                sx={{ minWidth: '0px', height: '40px', padding: '14px 16px', borderRadius: '8px', fontWeight: 700, fontSize: 13 }}
              >
                <Icons.DoubleCheck style={{ marginRight: '3px' }} />
                정리 완료하기
              </Button>
            </HeaderProgressButtonWrapper>
          </HeaderWrapper>
          <OrganizeContentWrapper>
            <div style={{ width: '22%', minWidth: '310px', height: '100%', padding: '24px', borderRight: `1px solid ${COLORS.gray300}` }}>
              <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
                <Tab label="통계" />
                <Tab label="타임라인" />
              </Tabs>
              <div style={{ marginTop: 16, height: '100%', width: '100%' }}>
                <TabPanel role="tabpanel" hidden={!(tabIndex === 0)}>
                  <StatView taskboxes={taskboxes} />
                </TabPanel>
                <TabPanel ref={refCalendarView} role="tabpanel" hidden={!(tabIndex === 1)}>
                  <CalendarView
                    events={calendarEvents}
                    selectedEventId={selectedEventId}
                    currentDate={dayjs(date).toDate()}
                    newEventId={newTaskbox?.id}
                    onSelectEvent={handleSelectEvent}
                    onUpdateEvent={handleUpdateEvent}
                    onUpdateEventTitle={handleUpdateEventTitle}
                    onChangeEventEditing={handleChangeEventEditing}
                    onClickTimeSlot={handleClickTimeSlot}
                    onDropFromOutside={handleDropFromOutside}
                    onContextMenuEvent={handleContextMenu}
                    onClickRefresh={handleRefresh}
                    onClickCategoryActions={handleClickCategoryActions}
                  />
                </TabPanel>
              </div>
            </div>
            <TaskboxBoardView
              date={date}
              taskboxes={taskboxes}
              selectedEventId={selectedEventId}
              entireTaskboxList={entireTaskboxList}
              autoCompleteList={autoCompleteList}
              multiSelectIds={multiSelectTaskboxIds}
              multiSelectAnchorEl={multiSelectAnchorEl}
              continueTaskbox={continueTaskbox}
              categoryList={categoryList}
              templateList={templateList}
              onCreateTaskbox={handleCreateTaskbox}
              onFetchTaskbox={updateTaskboxes}
              onFetchEntireTaskboxList={fetchAllTaskboxes}
              onFetchAutoCompleteList={fetchAutoCompleteList}
              onChangeSubtask={handleChangeSubtask}
              onChangeTaskboxDragImage={handleChangeTaskboxImage}
              onBeautifulDragEnd={handleBeautifulDragEnd}
              onBeautifulDragStart={handleBeautifulDragStart}
              onDropTaskbox={handleDropTaskbox}
              onDropMultiSelectTaskbox={handleDropMultiSelectTaskbox}
              onChangeMultiSelectIds={handleChangeMultiSelect}
              onCloseMultiSelect={handleCloseMultiSelect}
              onClickMultiSelectActions={handleClickMultiSelectActions}
              onMultiSelectDragStart={handleMultiSelectDragStart}
              onMultiSelectDragEnd={handleMultiSelectDragEnd}
              onChangeContinueToggle={handleChangeContinueToggle}
              onClickTaskbox={handleClickTaskbox}
              onFetchCategoryList={fetchCategoryList}
              onFetchTemplateList={fetchTemplateList}
            />
            <ReflectionView
              taskboxes={taskboxes}
              record={record!}
              onChangeContinueTaskbox={handleChangeContinueTaskbox}
              onSaveReflection={handleSaveReflection}
            />
          </OrganizeContentWrapper>
          <TaskboxDragImage id="taskbox-drag-image">
            <TaskboxDragImageLine taskboxDone={taskboxDragImage?.done} taskboxRecurrence={taskboxDragImage?.isRecurrence} />
            <TaskboxDragImageTitle taskboxDone={taskboxDragImage?.done} taskboxRecurrence={taskboxDragImage?.isRecurrence}>
              {taskboxDragImage?.title ? taskboxDragImage.title : '제목 없음'}
            </TaskboxDragImageTitle>
          </TaskboxDragImage>
          <TaskboxMultiSelectDragImage id="grouping-taskbox-drag-image">
            <TaskboxMultiSelectDragImageCount>{multiSelectTaskboxIds.length}</TaskboxMultiSelectDragImageCount>
            <span style={{ fontSize: 13, marginLeft: 8 }}>선택됨</span>
          </TaskboxMultiSelectDragImage>
          {taskboxDetailPopover && (
            <Popover
              open={Boolean(taskboxDetailPopover)}
              anchorEl={taskboxDetailPopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{ marginLeft: 1, zIndex: 10 }}
              onClose={() => {
                setTaskboxDetailPopover(null);
                setContextTaskbox(undefined);
              }}
            >
              <InboxContextMenu
                id={contextTaskbox!.id!}
                type={contextTaskbox?.project ? 'instance' : 'taskbox'}
                done={contextTaskbox?.done}
                menus={contextMenus as InboxContextMenuType[]}
                data={contextTaskbox?.tasks}
                onClickMenu={handleClickContextMenu}
              />
            </Popover>
          )}
          {removeRecurringTaskboxPopup && (
            <RemoveRecurrenceDialog
              open={removeRecurringTaskboxPopup}
              onClose={() => setRemoveRecurringTaskboxPopup(false)}
              onRemoveRecurrence={handleRemoveRecurrenceTaskbox}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default OrganizeToday;

interface CustomLinearProgressProps {
  value?: number;
  color?: string;
  barColor?: string;
}
const CustomLinearProgress = ({ value = 0, color = COLORS.gray200, barColor = 'transparent' }: CustomLinearProgressProps) => {
  return (
    <LinearProgress
      value={value}
      variant="determinate"
      sx={{
        height: '12px',
        borderRadius: '8px',
        border: '2px solid white',
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: color,
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: barColor,
        },
      }}
    />
  );
};

const fadeInOutAnimation = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const AnimationWrapper = styled.div<{ opacity?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeInOutAnimation} ease-in-out 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const WelcomeAnimation = () => (
  <AnimationWrapper style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <img src={require('assets/images/illust.png')} width={276} height={320} style={{ width: 276, height: 320 }} />
    <div style={{ display: 'flex', marginLeft: 12, marginTop: 16 }}>
      <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
        “
      </Typography>
      <Typography variant="subtitle2" color={COLORS.gray700} fontSize={40}>
        수고하셨어요! 한숨 돌리고 오늘 하루를 정리해봐요
      </Typography>
      <Typography variant="subtitle2" color={COLORS.sub2} fontSize={40} fontWeight={'bold'} fontFamily={'Inter'}>
        ”
      </Typography>
    </div>
  </AnimationWrapper>
);
