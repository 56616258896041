import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BGImage from 'assets/images/mobileBackground.png';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Modal, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { getSignupSurveyV1SurveysSignupGet, getUserMeV1UsersMeGet, recordUserSurveyV1SurveysSurveyIdPost, updateMyProfileV1UsersMePatch } from 'queries';
import { OutUserDetail, WelcomeSurveyOut } from 'queries/model';
import { useAtom } from 'jotai';
import { updateUserAtom } from 'atoms/user';
import { useEventListener } from '@react-hookz/web';

const Container = styled.div`
  width: 100%;
  min-width: 320px;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-image: url(${BGImage});
  background-size: cover;
`;

const WelcomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px;
  .MuiInputBase-root {
    height: 40px;
    width: 157px;
    font-size: 16px;
  }
`;

const WelcomeLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoDescription = styled.div`
  color: ${COLORS.gray500};
  font-size: 10px;
`;

const WelcomeTextWrapper = styled.div`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WelcomeFooter = styled.div`
  width: 100%;
  background-color: ${COLORS.white};
  padding: 20px 20px 24px 20px;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -8px 16px 0px rgba(45, 106, 223, 0.1);
`;

const MobileSurveyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileSurveyMain = styled.div`
  width: 100%;
  height: 100%;
`;

const ProgressWrapper = styled.div<{ collapse?: boolean }>`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 16px 16px;
  ${(props) =>
    props.collapse &&
    css`
      box-shadow: 0px 8px 16px 0px rgba(45, 106, 223, 0.1);
    `}
`;

const ProgressUnit = styled.div<{ active?: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${COLORS.gray500};
  border-radius: 50%;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.active &&
    css`
      background-color: ${COLORS.sub2};
    `}
`;

const SurveyContentWrapper = styled.div<{ maxHeight?: number }>`
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 20px 20px 20px;
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    `}

  .etc {
    height: 36px;

    .MuiInputBase-root {
      width: 125%;
      height: 45px;
      font-size: 16px;
      background-color: ${COLORS.white};
      transform: scale(0.8);
      transform-origin: left top;
    }
  }

  .MuiSelect-select {
    margin-top: 4px;
  }

  .MuiFormControlLabel-root {
    font-size: 13px;
    margin-right: 0px;
  }

  .MuiInputBase-root {
    width: 100%;
    height: 36px;
    font-size: 13px;
    background-color: ${COLORS.white};
  }

  .MuiInputBase-multiline {
    width: 100%;
    height: auto;
  }

  .select {
    .MuiFormControl-root {
      width: 100%;
      padding: 0px;
      height: 44px;
    }

    .MuiInputBase-root {
      width: 100%;
      height: 44px;
      font-size: 13px;
      background-color: ${COLORS.white};
    }

    .etc {
      height: 34px;
      margin-top: 8px;

      .MuiInputBase-root {
        width: 125%;
        height: 43px;
        font-size: 16px;
        background-color: ${COLORS.white};
        transform: scale(0.8);
        transform-origin: left top;
      }
    }
  }

  .multiline {
    height: auto;
    .MuiInputBase-root {
      width: 125%;

      font-size: 16px;
      background-color: ${COLORS.white};
      transform: scale(0.8);
      transform-origin: left top;
    }
  }
`;

const MobileSurveyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${COLORS.white};
  padding: 20px 20px 24px 20px;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -8px 16px 0px rgba(45, 106, 223, 0.1);
  position: absolute;
  bottom: 0px;
`;

const SurveyQuestionWrapper = styled.div`
  width: 320px;
  height: fit-content;
  padding: 24px;
  border: 1px solid ${COLORS.white};
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12), 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
`;

const SurveyQuestionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
`;

const OccupationWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const OccupationModalWrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 0px;
`;

const OccupationContent = styled.div`
  width: 260px;
  font-size: 13px;
  padding: 10px 20px;
  cursor: pointer;
`;

const PositionWrapper = styled.div`
  width: 100%;
`;

const PositionModalWrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 8px 0px;
`;

const PositionContent = styled.div`
  width: 260px;
  font-size: 13px;
  padding: 10px 20px;
  cursor: pointer;
`;

const MobileSurvey = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<OutUserDetail>();
  const [userName, setUserName] = useState('');
  const [survey, setSurvey] = useState<WelcomeSurveyOut>();
  const [, fetchUser] = useAtom(updateUserAtom);
  const [activeStep, setActiveStep] = useState(0);
  const [isCollapse, setIsCollapse] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const surveyRef = useRef<HTMLDivElement>(null);
  const [requiredError, setRequiredError] = useState({
    funnel: false,
    tool: false,
    occupation: false,
    position: false,
  });
  const [funnelEtcInput, setFunnelEtcInput] = useState('');
  const [funnelEtcInputError, setFunnelEtcInputError] = useState(false);
  const [toolEtcInput, setToolEtcInput] = useState('');
  const [toolEtcInputError, setToolEtcInputError] = useState(false);
  const [occupationEtcInput, setOccupationEtcInput] = useState('');
  const [occupationEtcInputError, setOccupationEtcInputError] = useState(false);
  const [occupation, setOccupation] = useState('');
  const [isOccupationModal, setIsOccupationModal] = useState(false);
  const [position, setPosition] = useState('');
  const [isPositionModal, setIsPositionModal] = useState(false);
  const [userOpinion, setUserOpinion] = useState('');
  const [funnelOptions, setFunnelOptions] = useState([
    {
      name: 'acquaintance',
      status: false,
      label: '지인 추천',
    },
    { name: 'slash', status: false, label: 'SLASH 팀원의 추천' },
    { name: 'google', status: false, label: '구글 검색' },
    { name: 'naver', status: false, label: '네이버 검색' },
    { name: 'instagram/facebook', status: false, label: '인스타그램/페이스북' },
    { name: 'community', status: false, label: '커뮤니티(오픈채팅방 등)' },
    { name: 'etc', status: false, label: '기타' },
  ]);

  const [toolOptions, setToolOptions] = useState([
    { name: 'notion', status: false, label: 'Notion' },
    { name: 'slack', status: false, label: 'Slack' },
    { name: 'google', status: false, label: 'Google Workspace' },
    { name: 'naver', status: false, label: '네이버웍스/라인웍스' },
    { name: 'jira', status: false, label: 'Jira' },
    { name: 'ms', status: false, label: 'MS Teams' },
    { name: 'asana', status: false, label: 'Asana' },
    { name: 'flow', status: false, label: '플로우' },
    { name: 'dooray', status: false, label: '두레이' },
    { name: 'todoist', status: false, label: 'Todoist' },
    { name: 'trello', status: false, label: 'Trello' },
    { name: 'ticktick', status: false, label: 'TickTick' },
    { name: 'sunsama', status: false, label: 'Sunsama' },
    { name: 'akiflow', status: false, label: 'Akiflow' },
    { name: 'obsidian', status: false, label: 'Obsidian' },
    { name: 'etc', status: false, label: '기타' },
  ]);

  const occupationList = [
    '마케팅',
    '제품기획(소프트웨어)',
    '상품기획(제조업/하드웨어)',
    '소프트웨어 엔지니어',
    '디자이너',
    '영업/고객 성공',
    'HR',
    '재무/전략',
    '금융',
    '전문직',
    '연구',
    '대학생',
    '기타',
  ];

  const positionList = ['관리자(대표, 임원 등)', '중간관리자(팀장 등)', '실무자', '1인 사업가 또는 프리랜서'];

  const steps = [1, 2, 3, 4];

  useEffect(() => {
    (async () => {
      const survey = await getSignupSurveyV1SurveysSignupGet();
      const user = await getUserMeV1UsersMeGet();
      if (!survey.isTarget) {
        navigate('/m/home');
      }
      setUser(user);
      setUserName(user.name!);
      setSurvey(survey);
    })();
    setMaxHeight(window.innerHeight - 70 - 95);
  }, []);

  useEventListener(
    mainRef,
    'scroll',
    () => {
      handleScroll();
    },
    { passive: true },
  );

  const handleScroll = () => {
    if (headerRef.current && surveyRef.current) {
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      const surveyTop = surveyRef.current.getBoundingClientRect().top;

      setIsCollapse(surveyTop < headerBottom);
    }
  };

  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user) setUserName(event.target.value);
  };

  const handleClickSurveyStart = async () => {
    handleNext();
    if (user?.name !== userName) {
      const success = await updateMyProfileV1UsersMePatch({ ...user!, name: userName });
      if (success) fetchUser();
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /** 유입경로  */
  const handleChangeFunnel = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = funnelOptions[idx];
    checkedOption.status = event.target.checked;
    setFunnelOptions([...funnelOptions]);
    if (requiredError.funnel) setRequiredError({ ...requiredError, funnel: false });
    if (event.target.name === 'etc') {
      setFunnelEtcInputError(false);
    }
  };

  const handleChangeFunnelEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunnelEtcInput(event.target.value);
    setFunnelEtcInputError(false);
  };

  const handleFunnelEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (funnelEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 생산성 툴  */
  const handleChangeTool = (event: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const checkedOption = toolOptions[idx];
    checkedOption.status = event.target.checked;
    setToolOptions([...toolOptions]);

    if (requiredError.tool) setRequiredError({ ...requiredError, tool: false });
    if (event.target.name === 'etc') {
      setToolEtcInputError(false);
    }
  };

  const handleChangeToolEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToolEtcInput(event.target.value);
    setToolEtcInputError(false);
  };

  const handleToolEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (toolEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직군 로직 */
  const handleChangeOccupation = (occupation: string) => {
    setTimeout(() => {
      setOccupation(occupation);
      setIsOccupationModal(false);
      if (requiredError.occupation) setRequiredError({ ...requiredError, occupation: false });
      if (occupationEtcInputError) setOccupationEtcInputError(false);
    }, 200);
  };

  const handleOpenOccupationModal = () => {
    setIsOccupationModal(true);
  };

  const handleCloseOccupationModal = () => {
    setIsOccupationModal(false);
  };

  const handleChangeOccupationEtcInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOccupationEtcInput(event.target.value);
    setOccupationEtcInputError(false);
  };

  const handleOccupationEtcInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault;
    if (event.key === 'Enter') {
      if (occupationEtcInput) (document.activeElement as HTMLElement)?.blur();
    }
  };

  /** 직책 로직 */
  const handleChangePosition = (position: string) => {
    setPosition(position);
    setIsPositionModal(false);
    if (requiredError.position) setRequiredError({ ...requiredError, position: false });
  };

  const handleOpenPositionModal = () => {
    setIsPositionModal(true);
  };

  const handleClosePositionModal = () => {
    setIsPositionModal(false);
  };

  /** 자유 의견 로직 */
  const handleUserOpinion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserOpinion(event.target.value);
  };

  /** 제출하고 시작하기 버튼 클릭 시 */
  const handleSubmit = async () => {
    const funnelList: any[] = funnelOptions.filter((v) => v.status).map((v) => v.name);
    const funnelEtcIndex = funnelList.findIndex((v) => v === 'etc');
    if (funnelEtcIndex !== -1) funnelList[funnelEtcIndex] = { etc: funnelEtcInput };
    const toolList: any[] = toolOptions.filter((v) => v.status).map((v) => v.name);
    const toolEtcIndex = toolList.findIndex((v) => v === 'etc');
    if (toolEtcIndex !== -1) toolList[toolEtcIndex] = { etc: toolEtcInput };

    const userSurveyResponse = {
      funnel: funnelList,
      tool: toolList,
      occupation: occupation === '기타' ? { etc: occupationEtcInput } : occupation,
      position: position,
      userOpinion: userOpinion,
    };

    const success = await recordUserSurveyV1SurveysSurveyIdPost(survey!.survey!.id, userSurveyResponse);

    if (success) {
      navigate('/m/home');
    }
  };

  return (
    <Container>
      {activeStep === 0 && userName && (
        <WelcomeWrapper>
          <WelcomeLogoWrapper>
            <Icons.SlashBeta width={100} height={28} />
            <div style={{ marginTop: '8px' }}>
              <LogoDescription>Live as you think</LogoDescription>
            </div>
          </WelcomeLogoWrapper>
          <WelcomeTextWrapper>
            <TextField autoComplete="off" value={userName} onChange={handleChangeUserName} sx={{ marginRight: '8px' }} />
            <div>님, 반가워요!</div>
          </WelcomeTextWrapper>
          <WelcomeMain>
            <div style={{ fontSize: '13px', fontWeight: 700, padding: '4px 12px', borderRadius: '100px', backgroundColor: COLORS.sub3 }}>
              💖&nbsp;&nbsp;3분도 안걸려요!
            </div>
            <div style={{ fontSize: '20px', margin: '32px 0px', textAlign: 'center' }}>
              맞춤 기능을 개발할 수 있도록
              <br />
              <span style={{ color: COLORS.sub2, fontWeight: 700 }}>4가지 질문</span>에 답해주세요.
            </div>
            <Icons.MobileSurveyArrow />
          </WelcomeMain>
          <WelcomeFooter>
            <Button
              variant="contained"
              onClick={handleClickSurveyStart}
              sx={{ width: '100%', borderRadius: '8px', padding: '14px 16px', fontSize: '13px', fontWeight: '700' }}
            >
              시작하기
            </Button>
          </WelcomeFooter>
        </WelcomeWrapper>
      )}
      {activeStep > 0 && (
        <MobileSurveyWrapper>
          <MobileSurveyMain>
            <ProgressWrapper ref={headerRef} collapse={isCollapse}>
              {steps.map((step, idx) => (
                <div key={step} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: step !== 4 ? '8px' : '0px' }}>
                    <ProgressUnit active={activeStep === step}>{step}</ProgressUnit>
                    {activeStep === step && (
                      <div style={{ fontSize: '10px', color: COLORS.sub2, marginTop: '2px', position: 'absolute' }}>{(activeStep / 4) * 100}%</div>
                    )}
                  </div>

                  {activeStep === step && activeStep !== 4 && <Divider sx={{ width: '38px', marginRight: '8px' }} />}
                </div>
              ))}
            </ProgressWrapper>
            <SurveyContentWrapper ref={mainRef} maxHeight={maxHeight}>
              {activeStep === 1 && (
                <SurveyQuestionWrapper>
                  <SurveyQuestionTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ fontWeight: 700, marginRight: '4px' }}>
                        1. SLASH를 <span style={{ color: COLORS.sub2 }}>어떻게</span> 알게 되었나요? <span style={{ fontWeight: 400 }}>(복수선택)</span>
                      </div>
                      <div
                        style={{ width: '16px', height: '16px', fontSize: '14px', textAlign: 'center', backgroundColor: COLORS.gray100, borderRadius: '50%' }}
                      >
                        *
                      </div>
                    </div>
                    {requiredError.funnel && (
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            backgroundColor: COLORS.negative1,
                            color: COLORS.white,
                            textAlign: 'center',
                            fontWeight: 700,
                            marginRight: '4px',
                          }}
                        >
                          !
                        </div>
                        <div>필수 질문에 응답하지 않았어요.</div>
                      </div>
                    )}
                  </SurveyQuestionTitle>
                  <FormControl component="fieldset" variant="standard" sx={{ marginTop: '16px' }}>
                    <FormGroup>
                      {funnelOptions.map((option, idx) => (
                        <div key={option.name}>
                          <FormControlLabel
                            control={<Checkbox checked={option.status} onChange={(e) => handleChangeFunnel(e, idx)} name={option.name} />}
                            label={option.label}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </FormControl>
                  {funnelOptions.find((v) => v.name === 'etc')?.status && (
                    <TextField
                      className="etc"
                      error={funnelEtcInputError}
                      value={funnelEtcInput}
                      autoComplete="off"
                      fullWidth
                      autoFocus
                      placeholder="직접 입력해주세요."
                      onChange={handleChangeFunnelEtcInput}
                      onKeyDown={handleFunnelEtcInputKeyDown}
                      sx={{ marginTop: '4px' }}
                    />
                  )}
                </SurveyQuestionWrapper>
              )}
              {activeStep === 2 && (
                <SurveyQuestionWrapper ref={surveyRef}>
                  <SurveyQuestionTitle>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <div style={{ fontWeight: 700, marginRight: '4px' }}>
                        2. 주로 사용하고 계신
                        <br />
                        <span style={{ color: COLORS.sub2 }}>협업툴/생산성</span> 툴을 알려주세요. <span style={{ fontWeight: 400 }}>(복수선택)</span>
                      </div>
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          fontSize: '14px',
                          textAlign: 'center',
                          backgroundColor: COLORS.gray100,
                          borderRadius: '50%',
                          position: 'absolute',
                          top: '22px',
                          right: '-16px',
                        }}
                      >
                        *
                      </div>
                    </div>
                    {requiredError.tool && (
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            backgroundColor: COLORS.negative1,
                            color: COLORS.white,
                            textAlign: 'center',
                            fontWeight: 700,
                            marginRight: '4px',
                          }}
                        >
                          !
                        </div>
                        <div>필수 질문에 응답하지 않았어요.</div>
                      </div>
                    )}
                  </SurveyQuestionTitle>
                  <FormControl component="fieldset" variant="standard" sx={{ marginTop: '8px' }}>
                    <FormGroup>
                      {toolOptions.map((option, idx) => (
                        <div key={option.name}>
                          <FormControlLabel
                            control={<Checkbox checked={option.status} onChange={(e) => handleChangeTool(e, idx)} name={option.name} />}
                            label={option.label}
                          />
                        </div>
                      ))}
                    </FormGroup>
                  </FormControl>
                  {toolOptions.find((v) => v.name === 'etc')?.status && (
                    <TextField
                      className="etc"
                      value={toolEtcInput}
                      error={toolEtcInputError}
                      autoComplete="off"
                      autoFocus
                      fullWidth
                      placeholder="직접 입력해주세요."
                      onChange={handleChangeToolEtcInput}
                      onKeyDown={handleToolEtcInputKeyDown}
                      sx={{ marginTop: '4px' }}
                    />
                  )}
                </SurveyQuestionWrapper>
              )}
              {activeStep === 3 && (
                <SurveyQuestionWrapper>
                  <SurveyQuestionTitle>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <div style={{ fontWeight: 700, marginRight: '4px' }}>
                        3. 자신의 <span style={{ color: COLORS.sub2 }}>직군과 직책</span>을<br />
                        가장 잘 설명하는 것을 선택해주세요.
                      </div>
                      <div
                        style={{
                          width: '16px',
                          height: '16px',
                          fontSize: '14px',
                          textAlign: 'center',
                          backgroundColor: COLORS.gray100,
                          borderRadius: '50%',
                          position: 'absolute',
                          top: '22px',
                          right: '-16px',
                        }}
                      >
                        *
                      </div>
                    </div>
                    {(requiredError.occupation || requiredError.position) && (
                      <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: COLORS.negative1 }}>
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            backgroundColor: COLORS.negative1,
                            color: COLORS.white,
                            textAlign: 'center',
                            fontWeight: 700,
                            marginRight: '4px',
                          }}
                        >
                          !
                        </div>
                        <div>필수 질문에 응답하지 않았어요.</div>
                      </div>
                    )}
                  </SurveyQuestionTitle>
                  <SelectWrapper className="select">
                    <OccupationWrapper>
                      <FormControl error={requiredError.occupation}>
                        <TextField
                          fullWidth
                          placeholder="직군을 선택해주세요."
                          value={occupation}
                          onClick={handleOpenOccupationModal}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {requiredError.occupation && <FormHelperText>항목을 선택해주세요.</FormHelperText>}
                      </FormControl>

                      {occupation === '기타' && (
                        <TextField
                          className="etc"
                          value={occupationEtcInput}
                          fullWidth
                          error={occupationEtcInputError}
                          autoComplete="off"
                          autoFocus
                          placeholder="직접 입력해주세요."
                          onChange={handleChangeOccupationEtcInput}
                          onKeyDown={handleOccupationEtcInputKeyDown}
                          sx={{ marginTop: '4px' }}
                        />
                      )}
                      <Modal
                        open={isOccupationModal}
                        onClose={handleCloseOccupationModal}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <OccupationModalWrapper>
                          {occupationList.map((occupation, idx) => (
                            <OccupationContent key={idx} onClick={() => handleChangeOccupation(occupation)}>
                              {occupation}
                            </OccupationContent>
                          ))}
                        </OccupationModalWrapper>
                      </Modal>
                    </OccupationWrapper>
                    <PositionWrapper>
                      <FormControl error={requiredError.position}>
                        <TextField
                          placeholder="직책을 선택해주세요."
                          value={position}
                          onClick={handleOpenPositionModal}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {requiredError.position && <FormHelperText>항목을 선택해주세요.</FormHelperText>}
                      </FormControl>
                      <Modal open={isPositionModal} onClose={handleClosePositionModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PositionModalWrapper>
                          {positionList.map((position, idx) => (
                            <PositionContent key={idx} onClick={() => handleChangePosition(position)}>
                              {position}
                            </PositionContent>
                          ))}
                        </PositionModalWrapper>
                      </Modal>
                    </PositionWrapper>
                  </SelectWrapper>
                </SurveyQuestionWrapper>
              )}
              {activeStep === 4 && (
                <SurveyQuestionWrapper style={{ padding: '24px 24px 8px 24px' }}>
                  <SurveyQuestionTitle>
                    <div style={{ fontWeight: 700, marginRight: '4px' }}>
                      4. 업무관리에 있어서 어떤 <span style={{ color: COLORS.sub2 }}>어려움을</span> 겪고 있으신가요?
                    </div>
                  </SurveyQuestionTitle>
                  <TextField
                    className="multiline"
                    multiline
                    fullWidth
                    autoComplete="off"
                    minRows={2}
                    onChange={handleUserOpinion}
                    sx={{ width: '100%', marginTop: '12px' }}
                    placeholder="불편사항, 아이디어 등을 가감없이 제안해주시면 서비스에 적극 반영하겠습니다 :)"
                   />
                </SurveyQuestionWrapper>
              )}
            </SurveyContentWrapper>
          </MobileSurveyMain>
          <MobileSurveyFooter>
            <Button
              variant="contained"
              onClick={handlePrev}
              sx={{
                'width': '40%',
                'borderRadius': '8px',
                'padding': '14px 16px',
                'fontSize': '13px',
                'fontWeight': 700,
                'color': COLORS.gray900,
                'backgroundColor': COLORS.gray100,
                'boxShadow': 'none',
                '&:hover': {
                  backgroundColor: COLORS.gray200,
                },
              }}
            >
              이전
            </Button>
            <Button
              variant="contained"
              disabled={
                (activeStep === 1 && funnelOptions.filter((v) => v.status).length === 0) ||
                (activeStep === 2 && toolOptions.filter((v) => v.status).length === 0) ||
                (activeStep === 3 && !occupation) ||
                (activeStep === 3 && !position) ||
                (activeStep === 1 && funnelOptions.find((v) => v.name === 'etc')?.status && !funnelEtcInput) ||
                (activeStep === 2 && toolOptions.find((v) => v.name === 'etc')?.status && !toolEtcInput) ||
                (activeStep === 3 && occupation === '기타' && !occupationEtcInput)
              }
              onClick={activeStep === 4 ? () => handleSubmit() : () => handleNext()}
              sx={{ width: '55%', borderRadius: '8px', padding: '14px 16px', fontSize: '13px', fontWeight: 700, boxShadow: 'none' }}
            >
              {activeStep === 4 ? '제출하고 가입완료하기' : '다음'}
            </Button>
          </MobileSurveyFooter>
        </MobileSurveyWrapper>
      )}
    </Container>
  );
};

export default MobileSurvey;
