/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Custom title
 * 커스텀 OpenAPI 스키마
 * OpenAPI spec version: 3.0.2
 */

export type OutBlockQuestionType = typeof OutBlockQuestionType[keyof typeof OutBlockQuestionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutBlockQuestionType = {
  Question: 'Question',
} as const;
