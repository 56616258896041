import dayjs from 'dayjs';
import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { listRoutineV1RoutineGet } from 'queries';
import { OutReadEvent, OutRoutine } from 'queries/model';

const request = async () => await listRoutineV1RoutineGet();
export const routines = atomWithDefault<OutRoutine[]>(request);
export const routinesAtom = atom(
  (get) => get(routines),
  async (_, set) => {
    const res = await listRoutineV1RoutineGet();
    set(routines, res || []);
  },
);

export const selectedRoutineAtom = atom<OutRoutine | null>(null);
export const syncPlanRoutine = atom<OutRoutine | null>(null);
export const fetchRoutineFromDetailViewAtom = atom<boolean>(false);
export const selectedRoutineDateAtom = atom<Date>(new Date(dayjs().format('YYYY-MM-DD HH:mm:ss')));
