import { SVGProps } from 'react';

export const UpdateRecurrence = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.5 8.97613C3.5 7.72273 4.0025 6.58329 4.82 5.75529L3.755 4.67661C2.675 5.77808 2 7.29734 2 8.97613C2 12.0754 4.2875 14.6278 7.25 15V13.4655C5.1275 13.1009 3.5 11.2322 3.5 8.97613ZM14 8.97613C14 5.61856 11.315 2.89908 8 2.89908C7.955 2.89908 7.91 2.90667 7.865 2.90667L8.6825 2.07868L7.625 1L5 3.65871L7.625 6.31742L8.6825 5.24634L7.8725 4.42594C7.9175 4.42594 7.9625 4.41834 8 4.41834C10.4825 4.41834 12.5 6.46175 12.5 8.97613C12.5 11.2322 10.8725 13.1009 8.75 13.4655V15C11.7125 14.6278 14 12.0754 14 8.97613Z"
        fill="#858994"
      />
    </svg>
  );
};
