import styled from '@emotion/styled';
import { Icons } from 'components';
import { OutProject, WorkSectionType } from 'queries/model';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import InboxContextMenuPopover, { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { css } from '@emotion/react';
import { useEventListener } from '@react-hookz/web';
import dayjs from 'lib/dayjs';
import { categoryColors } from 'constants/categoryColor';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 32px;

  :hover {
    .drag-handle {
      opacity: 0.4;
    }
  }
`;

const IssueWrapper = styled.div<{ groupSelected?: boolean; selected?: boolean; highlight?: boolean }>`
  width: 100%;
  padding: 8px;
  align-items: flex-start;
  background-color: ${(props) => (props.groupSelected ? COLORS.sub3 : COLORS.white)};
  border: 1px solid ${(props) => (props.groupSelected || props.selected ? COLORS.gray300 : COLORS.gray200)};
  border-radius: 8px;
  display: flex;

  :focus-within {
    border: 1px solid ${COLORS.gray300};
  }

  :hover {
    cursor: pointer;
    box-shadow: 0px 4px 6px 0px rgba(151, 153, 158, 0.16);
  }

  ${(props) =>
    props.highlight &&
    css`
      border: 1px solid ${COLORS.gray600};
    `}
`;

const IssueItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  :hover .more-btn {
    opacity: 1;
  }
`;

const IssueItemRect = styled.div`
  width: 4px;
  align-self: stretch;
  border-radius: 100px;
  background: var(--Issue, #cd6cfc);
`;

const IssueItemContentWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const IssueItemCollapseButtonWrapper = styled.div`
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EditableTextField = styled.div<{ expanded?: boolean }>`
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  color: ${COLORS.gray900};
  font-size: 16px;
  font-weight: bold;

  ${(props) =>
    !props.expanded &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`;

const ProjectAddsOnWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TaskboxPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const DragHandleIcon = styled.div<{ planHeight?: number }>`
  position: absolute;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.planHeight! / 2 - 10}px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

export type Issue = {
  id: string;
  type?: string;
  title?: string;
  tasks?: any[];
};

export interface IssueItemProps {
  value: OutProject;
  selected?: boolean;
  groupSelected?: boolean;
  draggable?: boolean;
  editable?: boolean;
  hidden?: boolean;
  highlight?: boolean;
  popoverMenus?: InboxContextMenuType[];
  isGrouping: boolean;
  dragIndex?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onChangeTask?: (
    id: string,
    type: string,
    params: {
      content?: string;
      done?: boolean;
      workSectionType?: WorkSectionType;
      dueDate?: string;
    },
  ) => void;
  onChangeTitle?: (id: string, type: string, title: string) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseCalendar?: () => void;
}

const IssueItem = (props: IssueItemProps) => {
  const {
    value,
    selected,
    groupSelected,
    draggable,
    editable,
    hidden,
    highlight,
    popoverMenus,
    isGrouping,
    dragIndex,
    onClick,
    onDragStart,
    onDrag,
    onDragEnd,
    onChangeTask,
    onChangeTitle,
    onClickContextMenu,
  } = props;
  const [editing, setEditing] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [contextMenuOwnerId, setContextMenuOwnerId] = useState<string | undefined>();
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();
  const [isMetaKeyDown, setMetaKeyDown] = useState(false);
  const refIssueItem = useRef<HTMLDivElement>(null);
  const [planHeight, setPlanHeight] = useState<number | undefined>();

  useEffect(() => {
    setPlanHeight(refIssueItem.current?.getBoundingClientRect().height);
  }, [value]);

  useEventListener(
    window,
    'keydown',
    (event: React.MouseEvent) => {
      setMetaKeyDown(event.metaKey || event.ctrlKey);
    },
    { passive: true },
  );

  useEventListener(
    window,
    'keyup',
    (event: React.MouseEvent) => {
      setMetaKeyDown(event.metaKey || event.ctrlKey);
    },
    { passive: true },
  );

  useEffect(() => {
    if (!editing) window?.getSelection()?.removeAllRanges();
  }, [editing]);

  const handleClickTask = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e, value.id);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    onDragStart?.(e, value.id);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDrag?.(e);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    onDragEnd?.(e);
  };

  const handleClickTextField = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) return;
    if (!editable) return;

    // e.stopPropagation();
  };

  const handleKeydownTitleTextField = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const content = e.currentTarget.textContent || '';
      // onChangeTitle?.(value.id, value.type!, `${content}`.trim());
      setEditing(false);
    }
  };

  const handleBlurTitleTextField = (e: React.FocusEvent<HTMLElement>) => {
    if (!editable) return;

    const content = e.currentTarget.textContent || '';
    // onChangeTitle?.(value.id, value.type!, `${content}`.trim());
    setEditing(false);
    window?.getSelection()?.removeAllRanges();
  };

  const handleClickContextMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickContextMenu?.(id, type, menu, data);
    setContextMenuPopover(null);
    setContextMenuOwnerId(undefined);
  };

  const handleCloseContextMenu = () => {
    setContextMenuPopover(null);
    setContextMenuOwnerId(undefined);
  };

  const handleToggleCollapse = () => {
    setCollapse(!collapse);
    if (!collapse) setEditing(false);
  };

  const isEditable = editable && !isMetaKeyDown && !isGrouping;
  const isInstance = value.tasks && value.tasks.filter((v) => v.type !== 'PROJ_SOMEDAY_TASK').length > 0;

  return (
    <>
      <Draggable draggableId={value.id} index={dragIndex!}>
        {(provided) => {
          const style = {
            width: '100%',
            marginBottom: '8px',
            ...provided.draggableProps.style,
          };
          return (
            <div ref={provided.innerRef} {...provided.draggableProps} style={style}>
              <Container
                ref={refIssueItem}
                draggable={draggable}
                hidden={hidden}
                onClickCapture={handleClickTask}
                onDragStart={handleDragStart}
                onDrag={handleDrag}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
              >
                <DragHandleIcon className="drag-handle" planHeight={planHeight} {...provided.dragHandleProps} />
                <IssueWrapper groupSelected={groupSelected} highlight={highlight} selected={selected}>
                  <IssueItemWrapper>
                    {isInstance ? <IssueItemRect style={{ marginRight: 8 }} /> : <div style={{ width: '4px', marginRight: 8 }} />}
                    <div style={{ width: 'calc(100% - 12px)', display: 'flex', flexDirection: 'column' }}>
                      {(value.category || value.due) && (
                        <TaskboxPropertyWrapper>
                          {value.category && (
                            <CategoryShowingWrapper
                              textColor={categoryColors.find((v) => v.color === value.category!.color)?.textColor}
                              bgColor={categoryColors.find((v) => v.color === value.category!.color)?.bgColor}
                            >{`# ${value.category.name}`}</CategoryShowingWrapper>
                          )}
                          {value.due && (
                            <DeadlineShowingWrapper date={value.due}>
                              <Icons.Flag
                                fill={dayjs(value.due).isToday() ? COLORS.brand1 : dayjs(value.due).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600}
                              />
                              <span style={{ marginLeft: '2px' }}>
                                {dayjs(value.due).isToday()
                                  ? '오늘'
                                  : dayjs(value.due).isYesterday()
                                  ? '어제'
                                  : dayjs(value.due).isTomorrow()
                                  ? '내일'
                                  : dayjs(value.due).format('M월 D일 (dd)')}
                              </span>
                            </DeadlineShowingWrapper>
                          )}
                        </TaskboxPropertyWrapper>
                      )}
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InputWrapper>
                          <Icons.Issue style={{ marginRight: 8 }} />
                          <IssueItemContentWrapper>
                            <EditableTextField
                              data-id={`${value.id}`}
                              contentEditable={editing && isEditable}
                              suppressContentEditableWarning={true}
                              onKeyDown={handleKeydownTitleTextField}
                              onBlur={handleBlurTitleTextField}
                              onClick={handleClickTextField}
                              dangerouslySetInnerHTML={{ __html: value.title || '' }}
                              expanded={editing || collapse}
                            />
                          </IssueItemContentWrapper>
                        </InputWrapper>
                        <ProjectAddsOnWrapper>
                          {value.memo && <Icons.TaskboxMemo />}
                          {value.links && value.links.length > 0 && (
                            <div style={{ marginLeft: 4 }}>
                              <Icons.Link2 stroke={COLORS.gray500} />
                            </div>
                          )}
                          {value.tasks && value.tasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length > 0 && (
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                              <Icons.Subtask stroke={COLORS.gray500} />
                              <span style={{ color: COLORS.gray500, fontSize: '13px', margin: '1px 0px 0px 4px' }}>
                                {value.tasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK').length}
                              </span>
                            </div>
                          )}
                        </ProjectAddsOnWrapper>
                      </div>
                    </div>
                  </IssueItemWrapper>
                </IssueWrapper>
              </Container>
            </div>
          );
        }}
      </Draggable>
      {contextMenuPopover && contextMenuOwnerId && (
        <InboxContextMenuPopover
          id={contextMenuOwnerId}
          type={'project'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 0.5 }}
          menus={popoverMenus}
          onClose={handleCloseContextMenu}
          onClickMenu={handleClickContextMenu}
        />
      )}
    </>
  );
};

export default IssueItem;
