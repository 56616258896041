type State = { open: boolean; error?: Error };
type SetState = React.Dispatch<React.SetStateAction<State>>;
type CloseHandler = NonNullable<DialogProps['onClose']>;
type ConfirmHandler = (data?: any) => Promise<void> | void;

export type DialogElement = { open: () => void };
export type ConfirmDialogProps = Omit<DialogProps, 'open'> & {
  onConfirm?: ConfirmHandler;
  data?: any;
  type?: string;
};

import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { Icons } from 'components';
import React, { useImperativeHandle, useState } from 'react';
import { COLORS } from 'styles/constants';

export const ConfirmDialog = React.forwardRef<DialogElement, ConfirmDialogProps>(function ConfirmDialog(props, ref): JSX.Element {
  const { onClose, onConfirm, children, title, data, type, ...other } = props;
  const [state, setState] = useState<State>({ open: false });
  const handleClose = useHandleClose(setState, onClose);
  const handleConfirm = useHandleConfirm(setState, onConfirm);

  useImperativeHandle(ref, () => ({
    open() {
      setState({ open: true });
    },
  }));

  return (
    <Dialog
      className="confirm-dialog"
      open={state.open}
      onClose={handleClose}
      {...other}
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '16px',
          padding: '24px',
        },
      }}
    >
      {title && (
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${COLORS.gray200}`,
            borderRadius: '100px',
            fontSize: '13px',
            fontWeight: '700',
            padding: '4px 12px',
          }}
        >
          {type === 'task' && <Icons.TaskCheckbox />}
          {type === 'taskbox' && <Icons.PriorityIssueCheck width={16} height={16} />}
          {type === 'merge' && <Icons.TaskGroupCheckbox />}
          {type === 'project' && <Icons.Issue />}
          {type === 'routine' && <Icons.Issue stroke={COLORS.sub4} fill={COLORS.sub4} />}
          <span style={{ margin: '1px 0px 0px 8px' }}>{title}</span>
        </DialogTitle>
      )}
      <DialogContent style={{ minWidth: 300, padding: '32px 20px' }}>{children}</DialogContent>
      <DialogActions sx={{ width: '100%', padding: '0px' }}>
        <Button
          fullWidth
          variant="contained"
          onClick={(e) => handleClose(e, 'escapeKeyDown')}
          sx={{
            'height': '40px',
            'backgroundColor': COLORS.gray100,
            'borderRadius': '8px',
            'boxShadow': 'none',
            'color': COLORS.gray700,
            'fontSize': '13px',
            'fontWeight': 700,
            ':hover': {
              backgroundColor: COLORS.gray100,
            },
          }}
        >
          취소
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleConfirm(data)}
          sx={{
            'height': '40px',
            'backgroundColor': COLORS.negative1,
            'borderRadius': '8px',
            'boxShadow': 'none',
            'fontSize': '13px',
            'fontWeight': 700,
            ':hover': {
              backgroundColor: COLORS.negative1,
            },
          }}
        >
          삭제
        </Button>
      </DialogActions>
    </Dialog>
  );
});

function useHandleClose(setState: SetState, handleClose?: CloseHandler) {
  return React.useCallback<CloseHandler>(function (event, reason) {
    setState({ open: false });
    handleClose?.(event, reason ?? 'backdropClick');
  }, []);
}

function useHandleConfirm(setState: SetState, handleConfirm?: ConfirmHandler) {
  return React.useCallback(async function (data?: any) {
    setState({ open: false });
    await handleConfirm?.(data);
  }, []);
}

export default ConfirmDialog;
