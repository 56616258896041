import styled from '@emotion/styled';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Close, Edit, File2, Link2, Shortcut } from 'components/Icons';
import {
  deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete,
  retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost,
  retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet,
  retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost,
  updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut,
} from 'queries';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { COLORS, FontSize } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { AttachmentType, OutAttachment } from 'queries/model';
import { SectionTitle } from './SectionHeader';
import { saveAs } from 'file-saver';
import LinkInputPopover from './LinkInputPopover';
import LinkEditPopover from './LinkEditPopover';
import { setCaretToEnd } from 'utils/moveCaret';

const Container = styled.div`
  width: 100%;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const AttachItemContainer = styled.aside`
  width: 100%;
  margin-top: 8px;
`;

const AttachItemWrapper = styled.li`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  background: ${COLORS.gray100};
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
  :hover {
    background: #e2ecff;
  }
`;

const AttachItemNameWrapper = styled.div`
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
`;

const AttachItemLoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AttachItemName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${COLORS.gray900};
  font-size: ${FontSize.h5};
  font-weight: bold;
`;

const AttachItemFileLink = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: blue;
  font-size: ${FontSize.h5};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const AttachItemActionWrapper = styled.div`
  display: flex;
`;

const AttachItemInput = styled.input`
  width: 100%;
  color: ${COLORS.gray500};
  font-size: ${FontSize.h5};
  font-weight: bold;
  background: transparent;
`;

type AttachItem = Pick<OutAttachment, 'id' | 'content' | 'type' | 'title'> & { loading?: boolean; editing?: boolean };

interface AttachmentsSectionProps {
  meetingNoteId: string;
  attachedFiles?: OutAttachment[];
}

const AttachmentsSection = ({ meetingNoteId, attachedFiles }: AttachmentsSectionProps) => {
  const [attachItems, setAttachItems] = useState<AttachItem[]>([]);
  const { open, acceptedFiles } = useDropzone({ noClick: true, noKeyboard: true });
  const [linkInputPopover, setLinkInputPopover] = useState<HTMLElement | null>(null);
  const [linkEditPopover, setLinkEditPopover] = useState<HTMLElement | null>(null);
  const [linkEditPopoverProps, setLinkEditPopoverProps] = useState<{ id: string; title?: string; url?: string } | null>(null);

  useEffect(() => {
    if (!attachedFiles) return;

    setAttachItems(attachedFiles.map((item) => ({ id: item.id, type: item.type, content: item.content, title: item.title })));
  }, [attachedFiles]);

  useEffect(() => {
    if (!acceptedFiles.length) return;

    addFile(acceptedFiles);
  }, [acceptedFiles]);

  const addFile = async (acceptedFiles: File[]) => {
    const newAttachItems = acceptedFiles.map((file) => ({ id: uuidv4(), type: AttachmentType.file, content: file.name, loading: true, editing: false }));
    setAttachItems([...attachItems, ...newAttachItems]);

    for (const [idx, item] of newAttachItems.entries()) {
      await uploadS3(item, acceptedFiles[idx]);
    }
  };

  const uploadS3 = async (item: AttachItem, file: File) => {
    if (!item || !file) return;

    const link = await retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost(meetingNoteId, { id: item.id, filename: item.content });
    if (!link.s3Link) return;

    fetch(link.s3Link, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } })
      .then((res) => {
        if (!res.ok) toast.error(`${item.content} 파일 업로드에 실패하였습니다.`);
      })
      .catch((_err) => {
        toast.error(`${item.content} 파일 업로드에 실패하였습니다.`);
      })
      .finally(() => {
        setAttachItems((state) => {
          const newAttachItems = (state || []).map((value) => (value.id === item.id ? { ...value, loading: false } : value));
          return newAttachItems;
        });
      });
  };

  const setFocusElement = (id: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-edit-id="${id}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickAddFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    open();
  };

  const handleClickAddLink = (title = '', url = '') => {
    const id = uuidv4();
    retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost(meetingNoteId, { id: id, link: url, title: title });
    setAttachItems([...attachItems, { id, type: AttachmentType.link, content: url, title: title, loading: false, editing: false }]);
  };

  const handleClickEditLink = (id: string, title = '', url = '') => {
    updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut(meetingNoteId, id, { content: url, title: title });
    setAttachItems(attachItems.map((item) => (item.id === id ? { ...item, content: url, title: title, editing: false } : item)));
  };

  const handleClickDelete = (id: string) => {
    deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete(meetingNoteId, id);
    setAttachItems(attachItems.filter((item) => item.id !== id));
  };

  const handleClickShortcut = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleClickDownload = async (id: string, fileName: string) => {
    const link = await retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet(meetingNoteId, id);
    if (!link.s3Link) return;

    fetch(link.s3Link)
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, fileName));
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>, value: AttachItem) => {
    if (value.type === 'file') {
      setAttachItems(attachItems.map((item) => (item.id === value.id ? { ...item, editing: true } : item)));
      setFocusElement(value.id);
    } else if (value.type === 'link') {
      setLinkEditPopover(e.currentTarget);
      setLinkEditPopoverProps({ id: value.id, title: value.title, url: value.content });
    }
  };

  const handleEditBlur = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, id: string) => {
    e.preventDefault();
    updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut(meetingNoteId, id, { content: e.currentTarget.value });
    setAttachItems(attachItems.map((item) => (item.id === id ? { ...item, content: e.currentTarget.value, editing: false } : item)));
  };

  return (
    <Container>
      <SectionTitleWrapper>
        <SectionTitle>첨부</SectionTitle>
        <div>
          <Button disableElevation color="inherit" variant="outlined" onClick={handleClickAddFile}>
            <File2 width={16} height={16} />
            <span style={{ marginLeft: 4 }}>파일 첨부</span>
          </Button>
          <Button disableElevation color="inherit" variant="outlined" style={{ marginLeft: 8 }} onClick={(e) => setLinkInputPopover(e.currentTarget)}>
            <Link2 width={16} height={16} />
            <span style={{ marginLeft: 4 }}>링크 첨부</span>
          </Button>
          <LinkInputPopover
            open={Boolean(linkInputPopover)}
            anchorEl={linkInputPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setLinkInputPopover(null)}
            onApply={(title, url) => {
              setLinkInputPopover(null);
              handleClickAddLink(title, url);
            }}
            sx={{ marginTop: 0.5 }}
          />
        </div>
      </SectionTitleWrapper>
      <AttachItemContainer>
        <ul>
          {attachItems.map((item) => (
            <AttachItemWrapper key={item.id}>
              {item.loading ? (
                <AttachItemLoadingWrapper>
                  <CircularProgress size={20} sx={{ color: COLORS.gray500 }} />
                </AttachItemLoadingWrapper>
              ) : (
                <AttachItemNameWrapper>
                  {item.editing ? (
                    <AttachItemInput
                      data-edit-id={item.id}
                      defaultValue={item.content}
                      onBlur={(e) => handleEditBlur(e, item.id)}
                      onKeyPress={(e) => e.key === 'Enter' && handleEditBlur(e, item.id)}
                    />
                  ) : (
                    <>
                      {item.type === 'file' ? (
                        <AttachItemFileLink onClick={() => handleClickDownload(item.id, item.content)}>{item.content}</AttachItemFileLink>
                      ) : (
                        <AttachItemName>{item.title || item.content}</AttachItemName>
                      )}
                    </>
                  )}
                </AttachItemNameWrapper>
              )}
              {!item.editing && !item.loading && (
                <AttachItemActionWrapper>
                  {item.type === 'link' && (
                    <IconButton color="primary" aria-label="shortcut" sx={{ padding: '4px' }} onClick={() => handleClickShortcut(item.content || '')}>
                      <Shortcut width={16} height={16} />
                    </IconButton>
                  )}
                  <IconButton color="primary" aria-label="edit" sx={{ padding: '4px' }} onClick={(e) => handleClickEdit(e, item)} tabIndex={0}>
                    <Edit width={16} height={16} />
                  </IconButton>
                  <IconButton color="primary" aria-label="delete" sx={{ padding: '4px' }} onClick={() => handleClickDelete(item.id)}>
                    <Close width={16} height={16} fill="red" />
                  </IconButton>
                </AttachItemActionWrapper>
              )}
            </AttachItemWrapper>
          ))}
        </ul>
        {linkEditPopoverProps?.id && (
          <LinkEditPopover
            open={Boolean(linkEditPopover)}
            anchorEl={linkEditPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ marginTop: 2, marginLeft: 5 }}
            {...linkEditPopoverProps}
            onClose={() => {
              setLinkEditPopover(null);
              setLinkEditPopoverProps(null);
            }}
            onApply={(id, title, url) => {
              setLinkEditPopover(null);
              setLinkEditPopoverProps(null);
              handleClickEditLink(id, title, url);
            }}
          />
        )}
      </AttachItemContainer>
    </Container>
  );
};

export default AttachmentsSection;
