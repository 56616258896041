import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  MenuList,
  Modal,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { useKeyboardEvent } from '@react-hookz/web';
import { Icons } from 'components';
import dayjs, { Dayjs } from 'lib/dayjs';
import { MultiSelectType } from 'pages/Ritual/Organize';
import { osName } from 'react-device-detect';
import { COLORS } from 'styles/constants';
import ConfirmDialog, { DialogElement } from 'components/ConfirmDialog';
import { useRef } from 'react';

interface TaskboxMultiSelectPopoverProps {
  date?: Date;
  multiSelectCount?: number;
  openDeleteDialog?: boolean;
  onOpenDeleteDialog?: () => void;
  onCloseDeleteDialog?: () => void;
  onClickMultiSelectActions?: (type: MultiSelectType, date?: Dayjs) => void;
}

export const TaskboxMultiSelectPopover = ({
  date,
  multiSelectCount = 0,
  openDeleteDialog = false,
  onOpenDeleteDialog = () => {},
  onCloseDeleteDialog = () => {},
  onClickMultiSelectActions = () => {},
}: TaskboxMultiSelectPopoverProps) => {
  const refConfirm = useRef<DialogElement>(null);
  useKeyboardEvent(
    true,
    (ev) => {
      if ((osName === 'Windows' && ev.code === 'Delete') || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey)) onOpenDeleteDialog();
      if (ev.code === 'KeyG') onClickMultiSelectActions('MERGE');
      if (ev.code === 'KeyN') onClickMultiSelectActions('TOMORROW');
      if (ev.code === 'KeyM') onClickMultiSelectActions('NEXT_WEEK');
      if (ev.code === 'KeyL') onClickMultiSelectActions('LATER');
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleConfirm = () => {
    onClickMultiSelectActions('DELETE');
  };

  const handleClickDelete = () => {
    refConfirm.current?.open();
  };

  return (
    <Container>
      <Header>
        <span style={{ color: COLORS.brand1 }}>{multiSelectCount}</span>
        <span>개의 태스크박스가 선택되었습니다.</span>
      </Header>
      <Divider />
      <DateWrapper>
        <PostponeMenuWrapper>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>{`다음 날 (${dayjs(date).add(1, 'day').format('M월 D일 dd요일')})`}</span>
                <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  N
                </KeyboardButtonRect>
              </div>
            }
            disableInteractive
          >
            <PostponeMenuItem
              onClick={() => {
                onClickMultiSelectActions('TOMORROW');
              }}
            >
              <Icons.Tomorrow width={20} height={20} stroke={COLORS.gray600} />
              <div style={{ marginTop: '4px' }}>다음 날</div>
            </PostponeMenuItem>
          </Tooltip>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>{`다음주 월요일 (${dayjs(date).day(8).format('M월 D일 dd요일')})`}</span>
                <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  M
                </KeyboardButtonRect>
              </div>
            }
            disableInteractive
          >
            <PostponeMenuItem
              onClick={() => {
                onClickMultiSelectActions('NEXT_WEEK');
              }}
            >
              <Icons.NextMonday width={20} height={20} stroke={COLORS.gray600} />
              <div style={{ marginTop: '4px' }}>다음주 월요일</div>
            </PostponeMenuItem>
          </Tooltip>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>나중에</span>
                <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  L
                </KeyboardButtonRect>
              </div>
            }
            disableInteractive
          >
            <PostponeMenuItem
              onClick={() => {
                onClickMultiSelectActions('LATER');
              }}
            >
              <Icons.Later width={20} height={20} fill={COLORS.gray600} />
              <div style={{ marginTop: '4px' }}>나중에</div>
            </PostponeMenuItem>
          </Tooltip>
        </PostponeMenuWrapper>
        <Divider />
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={date}
          onChange={(newValue) => {
            onClickMultiSelectActions('CALENDAR', dayjs(newValue!));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </DateWrapper>
      <Divider sx={{ width: '100%' }} />
      <MenuWrapper>
        <MenuList sx={{ padding: '8px' }}>
          {multiSelectCount > 1 && (
            <MenuItem
              onClick={() => {
                onClickMultiSelectActions('MERGE');
              }}
              style={{ padding: '8px 12px' }}
              sx={{
                'width': '100%',
                'display': 'flex',
                'justifyContent': 'space-between',
                'alignItems': 'center',
                'fontSize': '12px',
                ':hover': {
                  borderRadius: '8px',
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Merge />
                <div style={{ marginLeft: '8px' }}>합치기</div>
              </div>
              <div>
                <KeyboardButtonRect>G</KeyboardButtonRect>
              </div>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => onOpenDeleteDialog()}
            sx={{
              'width': '100%',
              'display': 'flex',
              'justifyContent': 'space-between',
              'alignItems': 'center',
              'color': COLORS.negative1,
              'fontSize': '12px',
              'padding': '8px 12px',
              ':hover': {
                borderRadius: '8px',
                backgroundColor: COLORS.gray100,
              },
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.Delete width={16} height={16} fill={COLORS.negative1} />
              <div style={{ marginLeft: '8px' }}>삭제</div>
            </div>
            {osName === 'Windows' ? (
              <div>
                <KeyboardButtonRect small>Delete</KeyboardButtonRect>
              </div>
            ) : (
              <div>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  ⌘
                </KeyboardButtonRect>
                <KeyboardCommandPlus>+</KeyboardCommandPlus>
                <KeyboardButtonRect small>Backspace</KeyboardButtonRect>
              </div>
            )}
          </MenuItem>
        </MenuList>
      </MenuWrapper>
      {/* <ConfirmDialog ref={refConfirm} title={'선택된 태스크박스 삭제'} onConfirm={handleConfirm} onClose={onCloseDeleteDialog}>
        <div style={{ color: `${COLORS.negative1}`, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>{`선택된 태스크박스를 삭제하시겠어요?`}</div>
        <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>관련 데이터를 모두 삭제합니다.</div>
      </ConfirmDialog> */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          onCloseDeleteDialog();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '16px',
            padding: '24px',
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${COLORS.gray200}`,
            borderRadius: 100,
            color: COLORS.gray900,
            textAlign: 'center',
            fontSize: 13,
            fontWeight: 700,
            padding: '4px 12px',
          }}
        >
          선택된 태스크박스를 삭제하시겠어요?
        </DialogTitle>
        <DialogContent
          style={{
            padding: '35px 24px',
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: COLORS.negative1, textAlign: 'center', fontSize: 16, fontWeight: 700, marginBottom: '4px' }}
          >
            선택된 태스크박스를 삭제하시겠어요?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13 }}>
            관련 데이터를 모두 삭제합니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ width: '100%', display: 'flex', alignItems: 'center', padding: '0px' }}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onCloseDeleteDialog();
            }}
            sx={{
              'height': '40px',
              'backgroundColor': COLORS.gray100,
              'borderRadius': '8px',
              'boxShadow': 'none',
              'color': COLORS.gray700,
              'fontSize': '13px',
              'fontWeight': 700,
              ':hover': {
                backgroundColor: COLORS.gray100,
              },
            }}
          >
            취소
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onClickMultiSelectActions('DELETE');
            }}
            sx={{
              'height': '40px',
              'backgroundColor': COLORS.negative1,
              'borderRadius': '8px',
              'boxShadow': 'none',
              'fontSize': '13px',
              'fontWeight': 700,
              ':hover': {
                backgroundColor: COLORS.negative1,
              },
            }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  margin-left: 8px;

  /* .MuiPickersCalendarHeader-labelContainer {
    max-height: 40px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 100%;
  }

  .css-epd502 {
    width: 100%;
  }

  .MuiCalendarPicker-root {
    width: 100%;
  } */

  .MuiTypography-root {
    width: 32px;
    height: 32px;
  }

  .MuiPickersDay-root {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 266px;
    padding: 14px 0px 14px 0px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 248px;
    overflow-y: hidden;
  }

  .css-epd502 {
    width: 248px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .PrivatePickersSlideTransition-root {
    min-height: 174px;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding: 16px 16px 8px 16px;
`;

const DateWrapper = styled.div``;

const MenuWrapper = styled.div`
  .MuiButtonBase-root {
    padding: 8px 12px;
  }
`;

const PostponeMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
  padding: 0px 16px;
`;

const PostponeMenuItem = styled.div`
  width: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 8px;
  font-size: 12px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CalendarWrapper = styled.div`
  width: 196px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  font-size: 12px;
  padding: 8px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${COLORS.gray900};
  }
`;

const MultiSelecActionWrapper = styled.div`
  padding: 8px;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
    border-radius: 8px;
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  color: ${COLORS.gray500};
  padding: 1px 4px;
  font-weight: 700;
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.gray500};
  margin: 0px 4px;
`;
