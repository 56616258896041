import { SVGProps } from 'react';

export const TimerPause = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g clipPath="url(#clip0_20355_227023)">
        <path
          d="M4.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V9C2.5 9.26522 2.60536 9.51957 2.79289 9.70711C2.98043 9.89464 3.23478 10 3.5 10H4.5C4.76522 10 5.01957 9.89464 5.20711 9.70711C5.39464 9.51957 5.5 9.26522 5.5 9V3C5.5 2.73478 5.39464 2.48043 5.20711 2.29289C5.01957 2.10536 4.76522 2 4.5 2ZM8.5 2H7.5C7.23478 2 6.98043 2.10536 6.79289 2.29289C6.60536 2.48043 6.5 2.73478 6.5 3V9C6.5 9.26522 6.60536 9.51957 6.79289 9.70711C6.98043 9.89464 7.23478 10 7.5 10H8.5C8.76522 10 9.01957 9.89464 9.20711 9.70711C9.39464 9.51957 9.5 9.26522 9.5 9V3C9.5 2.73478 9.39464 2.48043 9.20711 2.29289C9.01957 2.10536 8.76522 2 8.5 2Z"
          fill="#2D6ADF"
        />
      </g>
      <defs>
        <clipPath id="clip0_20355_227023">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
