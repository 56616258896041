import styled from '@emotion/styled';
import { Button, Skeleton, Stack } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { createVoteV1VotesPost, getListVotesV1VotesGet } from 'queries';
import { CreateVote, OutVote, VoteType } from 'queries/model';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import { Vote } from './Vote';
import { v4 as uuidv4 } from 'uuid';
import { CompleteVote } from './CompleteVote';
import { hideScroll } from 'styles/utils';
import { useEventListener } from '@react-hookz/web';

export const ProductionGNB = () => {
  const [openVote, setOpenVote] = useState(false);
  const [openCompleteVote, setOpenCompleteVote] = useState(false);
  const [render, setRender] = useState<{ name: string; path: string; main: string; sub: string; img: any } | undefined>(undefined);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [isImgRendered, setIsImgRendered] = useState<boolean>(false);
  const [complete, setComplete] = useState(false);
  const [suppressScroll, setSuppressScroll] = useState(true);
  const location = useLocation();
  const refScrollContainer = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleSuppresScroll();
  }, []);

  useEffect(() => {
    init();
  }, [location]);

  useEffect(() => {
    if (!render) return;
    const img = new Image();
    img.src = render.img;
    img.onload = () => {
      setIsImgLoaded(true);
    };
  }, [render]);

  useEventListener(
    window,
    'resize',
    () => {
      handleSuppresScroll();
    },
    { passive: true },
  );

  const init = async () => {
    const index = gnb.findIndex((item) => item.path === location.pathname);
    const voteList = await getListVotesV1VotesGet();
    voteList.find((item) => item.voteType === gnb[index].name) ? setComplete(true) : setComplete(false);
    setRender(gnb[index]);
  };

  const gnb = [
    {
      name: 'goal',
      path: '/testgoal',
      main: `나를 나답게 만들어주는 '목표',\n어디에 적어두고 있나요?`,
      sub: `SLASH에 목표를 적어두고, 잊지 않도록 리마인드를 받아보세요.`,
      img: require('assets/images/ProductionGoal.png'),
    },
    {
      name: 'plan',
      path: '/testplan',
      main: `하루 계획과 중장기 프로젝트, 더 이상 따로 작성할 필요 없어요.`,
      sub: `진행중인 프로젝트를 오늘 할 일 목록으로 가져오면 끝.\n'중요한 것'과 '급한 것'의 균형을 찾을 수 있어요.`,
      img: require('assets/images/ProductionPlan.png'),
    },
    {
      name: 'retro',
      path: '/testretro',
      main: `'생각대로'의 삶을 살고 있나요?`,
      sub: `내가 어디로 가고 있는지, 중요한 일은 무엇인지, 더 나아지기 위해 무엇을 해야하는지.\n회고를 통해 '사는대로'가 아닌 '생각대로' 살아보세요.`,
      img: require('assets/images/ProductionRetro.png'),
    },
  ];
  const handleOpenVote = () => {
    setOpenVote(true);
  };
  const handleCloseVote = () => {
    setOpenVote(false);
  };

  const handleOpenCompleteVote = () => {
    setOpenCompleteVote(true);
  };

  const handleCloseCompleteVote = () => {
    setOpenCompleteVote(false);
  };

  const handleClickVote = async (score: number, description: string) => {
    const newVote: CreateVote = {
      id: uuidv4(),
      voteType: render!.name! as VoteType,
      score,
      description,
    };

    const success = await createVoteV1VotesPost(newVote);

    if (success) {
      setOpenVote(false);
      setTimeout(() => {
        handleOpenCompleteVote();
      }, 300);
      init();
    }
  };

  const handleSuppresScroll = () => {
    const scrollContainer = refScrollContainer.current;
    const container = refContainer.current;

    if (!scrollContainer || !container) return;
    const scrollContainerHeight = scrollContainer.clientHeight;
    const containerHeight = container.clientHeight;

    if (scrollContainerHeight - containerHeight > 40) {
      setSuppressScroll(true);
    } else {
      setSuppressScroll(false);
    }
  };

  return (
    <ScrollConatainer ref={refScrollContainer} className="gnb-scroll-container" suppressScroll={suppressScroll}>
      <Container ref={refContainer} className="gnb-container" suppressScroll={suppressScroll}>
        {render && isImgLoaded ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: isImgRendered ? 1 : 0 }}>
            <MainContent>
              <MainContentHeader>
                <div
                  style={{
                    width: '14px',
                    height: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: COLORS.brand1,
                    borderRadius: '50%',
                    marginRight: '4px',
                  }}
                >
                  <Icons.Preparing />
                </div>
                <Fonts.H4>준비중</Fonts.H4>
              </MainContentHeader>
              <MainContentMain>
                <Fonts.H1>{render.main}</Fonts.H1>
              </MainContentMain>
              <MainContentFooter>
                <Fonts.Body1>{render.sub}</Fonts.Body1>
              </MainContentFooter>
            </MainContent>
            {complete ? (
              <div
                style={{
                  padding: '10px 16px',
                  color: COLORS.brand1,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  border: `1px solid ${COLORS.brand1}`,
                  backgroundColor: COLORS.white,
                  margin: '32px 0px',
                }}
              >
                👍 기대돼요 전달완료!
              </div>
            ) : (
              <Button variant="contained" sx={{ height: '40px', padding: '10px 16px', borderRadius: '8px', margin: '32px 0px' }} onClick={handleOpenVote}>
                👍 기대돼요
              </Button>
            )}
            <img src={render.img} alt="goal-img" style={{ width: 827 }} onLoad={() => setIsImgRendered(true)} />
          </div>
        ) : (
          <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Skeleton variant="rounded" animation="wave" width={76} height={28} />
            <Skeleton variant="rounded" animation="wave" width={320} height={32} style={{ marginTop: '20px' }} />
            <Skeleton variant="rounded" animation="wave" width={260} height={32} />
            <Skeleton variant="rounded" animation="wave" width={450} height={24} style={{ marginTop: '12px' }} />
            <Skeleton variant="rounded" animation="wave" width={98} height={40} style={{ marginTop: '32px' }} />
            <Skeleton variant="rounded" animation="wave" width={830} height={460} style={{ marginTop: '32px' }} />
          </Stack>
        )}
        <Vote open={openVote} onClose={handleCloseVote} onClick={handleClickVote} />
        <CompleteVote open={openCompleteVote} onClose={handleCloseCompleteVote} />
      </Container>
    </ScrollConatainer>
  );
};

const ScrollConatainer = styled.div<{ suppressScroll: boolean }>`
  width: 100%;
  overflow-y: scroll;
  ${hideScroll}
  display: flex;
  flex-direction: column;
  ${({ suppressScroll }) =>
    suppressScroll &&
    `
    justify-content: center;
    align-items: center;
  `}
`;

const Container = styled.div<{ suppressScroll: boolean }>`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  ${({ suppressScroll }) =>
    suppressScroll &&
    `
    margin-top: 0px;
  `}
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContentHeader = styled.div`
  color: ${COLORS.brand1};
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
`;

const MainContentMain = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 12px;
  text-align: center;
  white-space: pre-line;
`;

const MainContentFooter = styled.div`
  color: ${COLORS.gray500};
  white-space: pre-line;
  text-align: center;
`;

export default ProductionGNB;
