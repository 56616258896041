import styled from '@emotion/styled';
import { Divider, TextField, Tooltip } from '@mui/material';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { useKeyboardEvent } from '@react-hookz/web';
import dayjs, { Dayjs } from 'lib/dayjs';
import { COLORS } from 'styles/constants';

export type DeadlineType = typeof DeadlineSelect[keyof typeof DeadlineSelect];

export const DeadlineSelect = {
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  MONDAY: 'MONDAY',
  CALENDAR: 'CALENDAR',
} as const;

interface TaskboxMultiSelectPopoverProps {
  date?: Date;
  onChangeDeadline?: (date: Dayjs) => void;
}

export const DeadlinePopover = ({ date, onChangeDeadline = () => {} }: TaskboxMultiSelectPopoverProps) => {
  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.code === 'KeyT') onChangeDeadline(dayjs());
      if (ev.code === 'KeyN') onChangeDeadline(dayjs().add(1, 'day'));
      if (ev.code === 'KeyM') onChangeDeadline(dayjs().day(8));
      // if (ev.code === 'KeyG') onClickDeadlineSelectActions('MERGE');
      // if (ev.code === 'KeyN') onClickDeadlineSelectActions('TOMORROW');
      // if (ev.code === 'KeyM') onClickDeadlineSelectActions('NEXT_WEEK');
      // if (ev.code === 'KeyL') onClickDeadlineSelectActions('LATER');
    },
    [],
    { eventOptions: { passive: true } },
  );

  return (
    <Container className="deadline-popover">
      <DateWrapper>
        <DeadlinMenuWrapper>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>{`${dayjs().format('M월 D일 dd요일')}`}</span>
                {/* <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  T
                </KeyboardButtonRect> */}
              </div>
            }
            disableInteractive
          >
            <DeadlineMenuItem
              onClick={() => {
                onChangeDeadline(dayjs());
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M8.66797 3V1.5M5.13247 4.4645L4.07197 3.404M3.66797 8H2.16797M8.66797 14.5V13M13.264 12.596L12.2035 11.536M15.168 8H13.668M5.13247 11.5355L4.07197 12.596M13.264 3.404L12.204 4.4645M11.668 8C11.668 8.79565 11.3519 9.55871 10.7893 10.1213C10.2267 10.6839 9.46362 11 8.66797 11C7.87232 11 7.10926 10.6839 6.54665 10.1213C5.98404 9.55871 5.66797 8.79565 5.66797 8C5.66797 7.20435 5.98404 6.44129 6.54665 5.87868C7.10926 5.31607 7.87232 5 8.66797 5C9.46362 5 10.2267 5.31607 10.7893 5.87868C11.3519 6.44129 11.668 7.20435 11.668 8Z"
                  stroke="#656871"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                <div style={{ marginRight: '4px' }}>오늘</div>
                <KeyboardButtonRect>T</KeyboardButtonRect>
              </div>
            </DeadlineMenuItem>
          </Tooltip>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>{`${dayjs().add(1, 'day').format('M월 D일 dd요일')}`}</span>
                {/* <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  N
                </KeyboardButtonRect> */}
              </div>
            }
            disableInteractive
          >
            <DeadlineMenuItem
              onClick={() => {
                onChangeDeadline(dayjs().add(1, 'day'));
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M2.5 10.5H13.5M3.5 13H12.5M5 8C5 7.20435 5.31607 6.44129 5.87868 5.87868C6.44129 5.31607 7.20435 5 8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8M3.25 8H2.25M4.5 4.5L3.75 3.75M8 3V2M11.5 4.5L12.2425 3.7575M14 8H12.75"
                  stroke="#656871"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                <div style={{ marginRight: '4px' }}>내일</div>
                <KeyboardButtonRect>N</KeyboardButtonRect>
              </div>
            </DeadlineMenuItem>
          </Tooltip>
          <Tooltip
            title={
              <div style={{ padding: '2px 0px' }}>
                <span>{`${dayjs().day(8).format('M월 D일 dd요일')}`}</span>
                {/* <KeyboardButtonRect small style={{ marginLeft: '10px' }}>
                  M
                </KeyboardButtonRect> */}
              </div>
            }
            disableInteractive
          >
            <DeadlineMenuItem onClick={() => onChangeDeadline(dayjs().day(8))}>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path
                  d="M12.3333 3.32812H4.33333C3.59695 3.32812 3 3.92508 3 4.66146V12.6615C3 13.3978 3.59695 13.9948 4.33333 13.9948H12.3333C13.0697 13.9948 13.6667 13.3978 13.6667 12.6615V4.66146C13.6667 3.92508 13.0697 3.32812 12.3333 3.32812Z"
                  stroke="#656871"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M11 2V4.66667" stroke="#656871" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.66797 2V4.66667" stroke="#656871" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M5.33594 6.39844H6.76305L8.30525 9.65369H8.36663L9.90883 6.39844H11.3359V11.1984H10.2157V8.0559H10.1697L8.71957 11.1785H7.95231L6.50218 8.04264H6.45614V11.1984H5.33594V6.39844Z"
                  fill="#656871"
                />
              </svg>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
                <div style={{ width: '64px', marginRight: '4px' }}>다음주 월요일</div>
                <KeyboardButtonRect>M</KeyboardButtonRect>
              </div>
            </DeadlineMenuItem>
          </Tooltip>
        </DeadlinMenuWrapper>
        <Divider sx={{ marginTop: '8px' }} />
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={date}
          onChange={(newValue) => {
            onChangeDeadline(dayjs(newValue!));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </DateWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 16px;
  /* .MuiPickersCalendarHeader-labelContainer {
    max-height: 40px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 100%;
  }

  .css-epd502 {
    width: 100%;
  }

  .MuiCalendarPicker-root {
    width: 100%;
  } */

  .MuiTypography-root {
    width: 32px;
    height: 32px;
  }

  .MuiPickersDay-root {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    margin-top: 14px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 248px;
    overflow-y: hidden;
  }

  .css-epd502 {
    width: 248px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .PrivatePickersSlideTransition-root {
    min-height: 174px;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }
`;

const DateWrapper = styled.div``;

const DeadlinMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeadlineMenuItem = styled.div`
  width: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px 8px;
  font-size: 12px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const CalendarWrapper = styled.div`
  width: 196px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  font-size: 12px;
  padding: 8px;
  margin-top: 16px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${COLORS.gray900};
  }
`;

const MultiSelecActionWrapper = styled.div`
  padding: 8px 16px;
`;

const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
    border-radius: 8px;
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 2px;
  font-size: 12px;
  color: ${COLORS.gray500};
  padding: 5px;
  font-weight: 700;
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.gray500};
  margin: 0px 4px;
`;
