import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as Home } from 'assets/images/icons/menus/home.svg';
import { ReactComponent as Meeting } from 'assets/images/icons/menus/meeting.svg';
import { ReactComponent as Work } from 'assets/images/icons/menus/work.svg';
import { ReactComponent as Plan } from 'assets/images/icons/menus/plan.svg';
import { ReactComponent as Stats } from 'assets/images/icons/menus/stats.svg';
import { ReactComponent as Lounge } from 'assets/images/icons/menus/lounge.svg';
import { ReactComponent as Retro } from 'assets/images/icons/menus/retro.svg';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import {
  createFeatureStorageV1FeatureStoragePost,
  getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet,
  getAFeatureStorageV1FeatureStorageFeatureStorageIdGet,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
} from 'queries';
import { InCreateFeatureStorage, OutFeatureStorage } from 'queries/model';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'lib/dayjs';

const MenuItemWrapper = styled.div<{ active?: boolean }>`
  width: 72px;
  height: 70px;
  margin: 8px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    .prepare-icon {
      background-color: var(--brand1);
    }

    .icon {
      filter: ${(props) =>
        !props.active && 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);'};
    }

    label {
      filter: ${(props) =>
        !props.active && 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);'};
    }
  }

  .icon {
    margin-right: 6px;
    margin-bottom: 4px;
    filter: ${(props) =>
      props.active
        ? 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%)'
        : 'brightness(0) saturate(100%) invert(34%) sepia(9%) saturate(420%) hue-rotate(191deg) brightness(94%) contrast(90%)'};
  }

  .prepare-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${(props) => (props.active ? 'var(--brand1)' : 'var(--gray-700)')};
    position: absolute;
    z-index: 100;
    top: 6px;
    right: 12px;
  }

  label {
    margin-right: 6px;
    font-size: 12px;
    color: ${(props) => (props.active ? 'var(--brand1)' : 'var(--gray-700)')};
    user-select: none;
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #f2f5fc;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      ::before {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: -20px;
        right: 0px;
        height: 20px;
        width: 12px;
        border-top-right-radius: 30px;
        box-shadow: 0px -10px 0px 0px #f2f5fc;
      }

      ::after {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: 70px;
        right: 0px;
        height: 20px;
        width: 12px;
        border-top-left-radius: 30px;
        box-shadow: 0px -10px 0px 0px #f2f5fc;
        transform: rotate(0.5turn);
      }
    `}
`;

const NewFeature = styled.div`
  width: 24px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${COLORS.brand1};
  color: white;
  position: absolute;
  z-index: 100;
  top: 4px;
  right: 4px;
  font-size: 8px;
  font-weight: bold;
`;

const MenuItem = ({ icon, label, active, expire }: { icon?: ReactNode; label?: string; active?: boolean; expire?: boolean }) => (
  <>
    {process.env.REACT_APP_MODE === 'production' ? (
      <MenuItemWrapper active={active} style={{ position: 'relative' }}>
        <span className="icon">{icon}</span>
        <label>
          <b>{label}</b>
        </label>
        {label === '목표' && (
          <div className="prepare-icon">
            <Icons.Preparing />
          </div>
        )}
        {label === '회고' && !expire && <NewFeature>NEW</NewFeature>}
        {label === '계획' && !expire && <NewFeature>NEW</NewFeature>}
      </MenuItemWrapper>
    ) : (
      <MenuItemWrapper active={active}>
        <span className="icon">{icon}</span>
        <label>
          <b>{label}</b>
        </label>
        {label === '회고' && !expire && <NewFeature>NEW</NewFeature>}
        {label === '계획' && !expire && <NewFeature>NEW</NewFeature>}
      </MenuItemWrapper>
    )}
  </>
);

export const menus =
  process.env.REACT_APP_MODE === 'production'
    ? [
        { name: 'prodHome', label: '홈', path: '/home', icon: <Home /> },
        { name: 'testgoal', label: '목표', path: '/goal', icon: <Plan /> },
        { name: 'plan', label: '계획', path: '/plan', icon: <Plan /> },
        { name: 'prodTask', label: '실행', path: '/task', icon: <Work /> },
        { name: 'reflection', label: '회고', path: '/reflection', icon: <Retro /> },
      ]
    : [
        { name: 'home', label: '홈', path: '/home', icon: <Home /> },
        { name: 'testgoal', label: '목표', path: '/testgoal', icon: <Plan /> },
        { name: 'plan', label: '계획', path: '/plan', icon: <Plan /> },
        { name: 'task', label: '실행', path: '/task', icon: <Work /> },
        { name: 'reflection', label: '회고', path: '/reflection', icon: <Retro /> },
        //Divider 밑으로
        { name: 'graph', label: '통계', path: '/graph', icon: <Stats /> },
        { name: 'meeting', label: '회의', path: '/meeting', icon: <Meeting /> },
        { name: 'testplan', label: '계획', path: '/testplan', icon: <Meeting /> },
        { name: 'testretro', label: '회고', path: '/testretro', icon: <Retro /> },
      ];

const Menu = ({ selectedMenu }: { selectedMenu?: string }) => {
  const [reflectionFeature, setReflectionFeature] = useState<OutFeatureStorage | null>(null);
  const [planFeature, setPlanFeature] = useState<OutFeatureStorage | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const reflection = await getFeatureData('reflection');
      if (reflection) setReflectionFeature(reflection);
      const plan = await getFeatureData('plan');
      if (plan) setPlanFeature(plan);
    };
    fetchData();
  }, []);

  const getFeatureData = async (feature: string) => {
    try {
      const featureData = await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet(feature);
      if (featureData && featureData.data) {
        if (!featureData.data.expire && dayjs().diff(featureData.data.clickTime, 'day') > 1) {
          featureData.data.expire = true;
          await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(featureData.id, { ...featureData, data: { ...featureData.data, expire: true } });
        }
        return featureData;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickFeature = async (featureName: string) => {
    const feature = featureName === 'reflection' ? reflectionFeature : planFeature;

    if (feature) {
      if (!feature.data.clickTime) {
        await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(feature.id, {
          ...feature,
          data: { ...feature.data, expire: false, clickTime: new Date() },
        });
      }
    } else {
      const newFeature = {
        id: uuidv4(),
        name: featureName,
        data: {
          expire: false,
          clickTime: new Date(),
        },
      };
      await createFeatureStorageV1FeatureStoragePost(newFeature);
    }
  };

  const handleClickReflection = () => handleClickFeature('reflection');
  const handleClickPlan = () => handleClickFeature('plan');

  return (
    <>
      {process.env.REACT_APP_MODE === 'production' ? (
        <div>
          {menus.map((v) =>
            v.name !== 'stats' ? (
              <Link
                to={v.path}
                key={v.name}
                onClick={() => {
                  if (v.label === '회고') {
                    handleClickReflection();
                  } else if (v.label === '계획') {
                    handleClickPlan();
                  }
                }}
              >
                <MenuItem
                  icon={v.icon}
                  label={v.label}
                  active={selectedMenu === v.name}
                  expire={v.label === '계획' ? planFeature?.data.expire : v.label === '회고' ? reflectionFeature?.data.expire : undefined}
                />
              </Link>
            ) : (
              <MenuItem key={v.name} icon={v.icon} label={v.label} active={selectedMenu === v.name} />
            ),
          )}
        </div>
      ) : (
        <div>
          {menus.map((v) =>
            v.name !== 'stats' ? (
              <div key={v.name}>
                {v.label === '통계' && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Divider sx={{ width: '100%', borderColor: 'var(--brand1)' }} />
                  </div>
                )}
                <Link
                  to={v.path}
                  key={v.name}
                  onClick={() => {
                    if (v.label === '회고') {
                      handleClickReflection();
                    } else if (v.label === '계획') {
                      handleClickPlan();
                    }
                  }}
                >
                  <MenuItem
                    icon={v.icon}
                    label={v.label}
                    active={selectedMenu === v.name}
                    expire={v.label === '계획' ? planFeature?.data.expire : v.label === '회고' ? reflectionFeature?.data.expire : undefined}
                  />
                </Link>
              </div>
            ) : (
              <MenuItem key={v.name} icon={v.icon} label={v.label} active={selectedMenu === v.name} />
            ),
          )}
        </div>
      )}
    </>
  );
};

export default Menu;
