import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils';
import { getAllProjectV1ProjectGet } from 'queries';
import { OutProject, OutTaskboxDetailProjectOnly, OutTaskboxDetailResponse } from 'queries/model';

const request = async () => await getAllProjectV1ProjectGet();
export const projects = atomWithDefault<OutProject[]>(request);
export const projectsAtom = atom(
  (get) => get(projects),
  async (_, set) => {
    const res = await getAllProjectV1ProjectGet();
    set(projects, res || []);
  },
);

export const fetchInstancesFromProjectDetailViewAtom = atom<boolean>(false);
export const changedProjectIdAtom = atom<string | null>(null);
export const loadSubtaskFromProjectAtom = atom<boolean>(false);
export const selectedProjectAtom = atom<OutProject | null>(null);
export const selectedInstanceAtom = atom<OutTaskboxDetailResponse | null>(null);
export const moveToInstanceAtom = atom<OutTaskboxDetailProjectOnly | null>(null);
export const syncInstanceAtom = atom<(OutTaskboxDetailProjectOnly & { syncType: string }) | null>(null);
