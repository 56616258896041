import * as React from 'react';

export const Loading = ({
  width = 60,
  height = 60,
  fill = '#7727db',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }}
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#7727db">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.352112676056338s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
         />
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#7727db;#d0b5f2;#b989f4;#9c5fe8;#7727db"
          begin="0s"
         />
      </circle>
      <circle cx="16" cy="50" r="10" fill="#7727db">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
         />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
         />
      </circle>
      <circle cx="50" cy="50" r="10" fill="#9c5fe8">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.352112676056338s"
         />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.352112676056338s"
         />
      </circle>
      <circle cx="84" cy="50" r="10" fill="#b989f4">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.704225352112676s"
         />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.704225352112676s"
         />
      </circle>
      <circle cx="16" cy="50" r="10" fill="#d0b5f2">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.056338028169014s"
         />
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.408450704225352s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.056338028169014s"
         />
      </circle>
    </svg>
  );
};

export default Loading;
