import styled from '@emotion/styled';
import { Icons, Login } from 'components';
import { COLORS } from 'styles/constants';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BGImage from 'assets/images/mobileBackground.png';
import { GoogleOAuthProvider, UseGoogleLoginOptionsAuthCodeFlow, useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import Fonts from 'components/Fonts';

const Container = styled.div`
  width: 100%;
  min-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-image: url(${BGImage});
  background-size: cover;
`;

const LogoDescription = styled.div`
  color: ${COLORS.gray500};
  font-size: 13px;
`;

const GuideMessage = styled.h1`
  color: ${COLORS.gray900};
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
`;

const GuideDescription = styled.h3`
  color: ${COLORS.gray800};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.25px;
`;

const LoginErrorWrapper = styled.div`
  display: flex;
  padding: 16px;
  margin-top: 30px;
  border-radius: 8px;
  background-color: ${COLORS.negative2};
`;

const MobileSignIn = () => {
  const location = useLocation();
  const params = new URLSearchParams({ from: 'm' });
  const loginError = new URLSearchParams(location.search).get('error');
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
  const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
    const onHandleLoginGoogle = useGoogleLogin(options);
    return <Login width="100%" onClick={onHandleLoginGoogle} label={label} />;
  };
  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/auth${params ? `?${params}` : ''}`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  return (
    <Container>
      <div style={{ marginTop: 90, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Icons.SlashBeta width={143} height={40} />
        <div style={{ marginTop: '8px' }}>
          <LogoDescription>Live as you think</LogoDescription>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: 60, zIndex: 2 }}>
        <div>
          {loginError ? (
            <GuideMessage>
              SLASH에 구글 캘린더를
              <br />
              연동해주세요
            </GuideMessage>
          ) : (
            <GuideMessage>
              SLASH에 오신 것을
              <br />
              환영합니다
            </GuideMessage>
          )}
        </div>
        <div style={{ marginTop: 24 }}>
          <GuideDescription>
            여러분의 편리한 일정 관리를 위해
            <br />
            구글 아이디를 사용하여 구글 캘린더를 연동합니다.
          </GuideDescription>
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '32px', padding: '0px 20px' }}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <GoogleLogInButton options={googleLoginOptions} label={'구글 계정으로 시작하기'} />
        </GoogleOAuthProvider>
      </div>

      <div style={{ fontSize: '12px', textAlign: 'center', marginTop: '32px', color: COLORS.gray600 }}>
        서비스 가입 시
        <a href="/" style={{ color: COLORS.sub2, textDecoration: 'underline' }}>
          이용약관
        </a>
        에 동의하며
        <br />
        <a href="http://policy.doslash.io/" target="_blank" rel="noreferrer" style={{ color: COLORS.sub2, textDecoration: 'underline' }}>
          개인정보 처리방침
        </a>
        의 내용을 확인한 것으로 간주됩니다.
      </div>
      {loginError && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 20px' }}>
          <LoginErrorWrapper>
            <div
              style={{
                width: '24px',
                height: '24px',
                marginRight: '8px',
                borderRadius: '50%',
                backgroundColor: COLORS.negative1,
                color: COLORS.white,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              !
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Fonts.H3>액서스 설정 실패</Fonts.H3>
              <Fonts.Body2
                style={{ textAlign: 'left', marginTop: '4px' }}
              >{`SLASH를 사용하시려면 '모든 캘린더의 일정 보기 \n및 수정'을 허용해 주셔야 합니다.`}</Fonts.Body2>
            </div>
          </LoginErrorWrapper>
        </div>
      )}
    </Container>
  );
};

export default MobileSignIn;
