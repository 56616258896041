import styled from '@emotion/styled';
import { Button, Dialog, IconButton, Popover, TextField, Tooltip } from '@mui/material';
import dayjs from 'lib/dayjs';
import { createRecordV1RecordPost, deleteRecordV1RecordRecordIdDelete, getADateRecordV1RecordDateDateGet, updateRecordV1RecordRecordIdPatch } from 'queries';
import { InCreateRecord, InReflection, InUpdateRecord, OutRecord } from 'queries/model';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { useClickOutside, useEventListener, useKeyboardEvent } from '@react-hookz/web';
import { hideScroll } from 'styles/utils';
import { ReflectionCalendar } from './ReflectionCalendar';
import { Icons } from 'components';
import { v4 as uuidv4 } from 'uuid';
import { ReflectionTemplate } from 'components/ReflectionTemplate/ReflectionTemplate';
import { ReflectionCalendarReadOnly } from 'components/ReflectionCalendarReadOnly/ReflectionCalendarReadOnly';
import InboxContextMenuPopover from 'components/InboxContextMenuPopover';
import InboxContextMenu from 'components/InboxContextMenu';
import { isEqual } from 'lodash';

export interface InReflectionDetail extends InReflection {
  date?: string;
  done?: boolean;
}

export interface ReflectionWritingProps {
  date?: Date;
  records?: OutRecord[];
  onChangeDate?: (date: Date) => void;
  onDelete?: (record: OutRecord) => void;
  onFetchRecords?: (date: Date) => void;
  onMonthChange?: (date: Date) => void;
}

export const ReflectionWriting = ({ date, records = [], onChangeDate, onDelete, onFetchRecords, onMonthChange }: ReflectionWritingProps) => {
  const isCalendar = records.map((v) => v.date).includes(dayjs(date).format('YYYY-MM-DD'));
  const [render, setRender] = useState<boolean>(false);
  const [record, setRecord] = useState<OutRecord>();
  const [tempRecord, setTempRecord] = useState<OutRecord>();
  const [editRecord, setEditRecord] = useState<boolean>(false);
  const [memoFocus, setMemoFocus] = useState(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const memoRef = useRef<HTMLDivElement>(null);
  const refReflection = useRef<HTMLDivElement>(null);
  const [isReflectionDialogOpen, setIsReflectionDialogOpen] = useState(false);
  const [isCompleteCreateDialogOpen, setIsCompleteCreateDialogOpen] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [detailAnchorEl, setDetailAnchorEl] = useState<HTMLElement | null>(null);
  const [confirmReflectionDialog, setConfirmReflectionDialog] = useState(false);
  const [originalRecord, setOriginalRecord] = useState<OutRecord>();

  useEffect(() => {
    init();
  }, [date, records]);

  useEffect(() => {
    countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
    if (countDown <= 0) {
      setIsCompleteCreateDialogOpen(false);
    }
  }, [countDown]);

  useLayoutEffect(() => {
    setContentHeight(refReflection.current?.getBoundingClientRect().height || 0);
  }, [refReflection]);

  useEventListener(
    window,
    'resize',
    () => {
      setContentHeight(refReflection.current?.getBoundingClientRect().height || 0);
    },
    { passive: true },
  );

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === 'ArrowRight') {
        if (isReflectionDialogOpen) return;
        if (dayjs(date).isSame(dayjs(), 'date')) return;
        handleClickNextDayButton();
      }

      if (ev.key === 'ArrowLeft') {
        if (isReflectionDialogOpen) return;
        handleClickPrevDayButton();
      }
    },
    [],
    { eventOptions: { passive: false } },
  );

  useClickOutside(memoRef, () => {
    setMemoFocus(false);
  });

  const init = async () => {
    if (isCalendar) {
      const record = records.find((v) => dayjs(v.date).isSame(dayjs(date), 'date'));
      // const record = await getADateRecordV1RecordDateDateGet(dayjs(date).format('YYYY-MM-DD'));
      setRecord(record);
      setOriginalRecord(record);
    } else {
      setRecord({
        date: dayjs(date).format('YYYY-MM-DD'),
        id: '',
        status: 'ACTIVE',
        createdAt: '',
        updatedAt: '',
        comboHit: 0,
        content: {},
      });
    }
    setRender(true);
  };

  const handleClickPrevDayButton = () => {
    onChangeDate && onChangeDate(dayjs(date).subtract(1, 'day').toDate());
  };

  const handleClickNextDayButton = () => {
    onChangeDate && onChangeDate(dayjs(date).add(1, 'day').toDate());
  };

  const handleCompleteEdit = async () => {
    if (!record) return;
    const inUpdateRecord: InUpdateRecord = {
      content: record.content,
      date: record.date,
      status: 'ACTIVE',
    };

    await updateRecordV1RecordRecordIdPatch(record.id, inUpdateRecord);
    onFetchRecords && onFetchRecords(dayjs(record.date).toDate());
    setOriginalRecord(record);
    setEditRecord(false);
    setIsReflectionDialogOpen(false);
  };

  const handleCreateRecord = async () => {
    if (!record) return;
    const inCreateRecord: InCreateRecord = {
      content: record.content,
      date: record.date,
    };
    await createRecordV1RecordPost(inCreateRecord);
    onFetchRecords && onFetchRecords(dayjs(inCreateRecord.date).toDate());
    setEditRecord(false);
    setIsReflectionDialogOpen(false);
    setCountDown(3);
    setIsCompleteCreateDialogOpen(true);
  };

  const handleDeleteRecord = async () => {
    if (!record) return;
    setDetailAnchorEl(null);
    onDelete && onDelete(record);
    onChangeDate && onChangeDate(dayjs(date).toDate());
    setIsReflectionDialogOpen(false);
    setEditRecord(false);
  };

  const handleSaveConfirmReflectionDialog = () => {
    setConfirmReflectionDialog(false);
    isCalendar ? handleCompleteEdit() : handleCreateRecord();
  };

  const handleCloseConfirmReflectionDialog = () => {
    setConfirmReflectionDialog(false);
    setRecord(tempRecord);
    setEditRecord(false);
    setIsReflectionDialogOpen(false);
  };

  return (
    <Container ref={refReflection}>
      {render && record && (
        <>
          <ReflectionCalendarWrapper>
            <div style={{ marginBottom: '24px' }}>데일리 회고</div>
            <ReflectionCalendar date={date} records={records} onChangeDate={onChangeDate} onMonthChange={onMonthChange} />
          </ReflectionCalendarWrapper>
          <ReflectionWritingWrapper>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <HeaderWrapper>
                <HeaderDateWrapper>{dayjs(date).format('M월 DD일 (dd)')}</HeaderDateWrapper>
                <HeaderButtonWrapper>
                  <>
                    <Tooltip
                      className="tooltip"
                      title={
                        <TooltipWrapper>
                          <span>이전 회고 보기</span>
                          <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                            ←
                          </KeyboardButtonRect>
                        </TooltipWrapper>
                      }
                      placement="bottom"
                      disableInteractive
                    >
                      <IconButton
                        onClick={handleClickPrevDayButton}
                        sx={{
                          'width': 32,
                          'height': 32,
                          'border': `1px solid ${COLORS.gray200}`,
                          'borderRadius': '8px',
                          'backgroundColor': COLORS.white,
                          ':hover': {
                            backgroundColor: `${COLORS.sub3}`,
                          },
                        }}
                      >
                        <Icons.ArrowLeftSmall fill={'#1F2023'} />
                      </IconButton>
                    </Tooltip>
                    <Button
                      onClick={() => onChangeDate && onChangeDate(new Date())}
                      size="small"
                      sx={{
                        height: '32px',
                        minWidth: '0px',
                        border: `1px solid ${COLORS.gray200}`,
                        borderRadius: '8px',
                        backgroundColor: COLORS.white,
                        color: dayjs(date).isSame(dayjs(), 'date') ? COLORS.brand1 : COLORS.gray800,
                        fontSize: '13px',
                        fontWeight: 700,
                        margin: '0px 4px',
                        padding: '8px 12px',
                      }}
                    >
                      오늘
                    </Button>
                    <Tooltip
                      className="tooltip"
                      title={
                        <TooltipWrapper>
                          <span>다음 회고 보기</span>
                          <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                            →
                          </KeyboardButtonRect>
                        </TooltipWrapper>
                      }
                      placement="bottom"
                      disableInteractive
                    >
                      <>
                        <IconButton
                          onClick={handleClickNextDayButton}
                          disabled={dayjs(date).isSame(dayjs(), 'date')}
                          sx={{
                            'width': 32,
                            'height': 32,
                            'border': `1px solid ${COLORS.gray200}`,
                            'borderRadius': '8px',
                            'backgroundColor': COLORS.white,
                            ':hover': {
                              backgroundColor: `${COLORS.sub3}`,
                            },
                          }}
                        >
                          <Icons.ArrowRightSmall fill={'#1F2023'} />
                        </IconButton>
                      </>
                    </Tooltip>
                  </>
                </HeaderButtonWrapper>
              </HeaderWrapper>
              {!editRecord &&
                (isCalendar ? (
                  <ReflectionTemplate
                    readOrcreateOrUpdate={'read'}
                    record={record}
                    isReflectionReadView
                    customHeader={
                      <ReflectionEditHeader>
                        <Button
                          onClick={() => {
                            setEditRecord(true);
                            setIsReflectionDialogOpen(true);
                            setTempRecord(record);
                          }}
                          sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.sub2, fontSize: '12px', padding: '2px 6px' }}
                        >
                          <Icons.Edit width={16} height={16} stroke={COLORS.sub2} />
                          <span style={{ margin: '1px 0px 0px 4px' }}>수정하기</span>
                        </Button>
                        <IconButton
                          onClick={(e) => setDetailAnchorEl(e.currentTarget)}
                          sx={{ width: '24px', height: '24px', borderRadius: '6px', marginLeft: '4px', padding: '4px' }}
                        >
                          <Icons.More />
                        </IconButton>
                      </ReflectionEditHeader>
                    }
                    onChange={setRecord}
                  />
                ) : (
                  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BlankWrapper>
                      <img src={require('assets/images/reflectionImg.png')} style={{ width: '220px', height: '220px' }} />
                      <BlankTitle>어떤 하루를 보냈나요?</BlankTitle>
                      <BlankSubTitle>회고로 하루 1% 성장을 완성해봐요.</BlankSubTitle>
                      <BlankReflectionBtn>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setEditRecord(true);
                            setIsReflectionDialogOpen(true);
                            setTempRecord(record);
                          }}
                        >
                          <Icons.Edit width={20} height={20} stroke={COLORS.white} />
                          회고 작성하기
                        </Button>
                      </BlankReflectionBtn>
                    </BlankWrapper>
                  </div>
                ))}
              <Popover
                open={Boolean(detailAnchorEl)}
                anchorEl={detailAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setDetailAnchorEl(null)}
              >
                <InboxContextMenu id={''} type={'reflection'} menus={['DELETE']} onClickMenu={handleDeleteRecord} />
              </Popover>
              <Dialog
                open={isReflectionDialogOpen}
                onClose={() => {
                  if (record.content && (record.content.memo || record.content.keep || record.content.problem || record.content.try)) {
                    if (isCalendar) {
                      if (!isEqual(record, originalRecord)) {
                        setConfirmReflectionDialog(true);
                      } else {
                        setRecord(tempRecord);
                        setEditRecord(false);
                        setIsReflectionDialogOpen(false);
                      }
                    } else {
                      setConfirmReflectionDialog(true);
                    }
                  } else {
                    setRecord(tempRecord);
                    setEditRecord(false);
                    setIsReflectionDialogOpen(false);
                  }
                }}
                maxWidth="md"
              >
                <div style={{ height: '726px', width: '790px' }}>
                  <ReflectionTemplate
                    readOrcreateOrUpdate={isCalendar ? 'update' : 'create'}
                    record={record}
                    padding="0px 80px 32px 80px"
                    closeDialog={() => {
                      setRecord(tempRecord);
                      setEditRecord(false);
                      setIsReflectionDialogOpen(false);
                    }}
                    onDelete={handleDeleteRecord}
                    onChange={setRecord}
                    createOrUpdateRecord={isCalendar ? handleCompleteEdit : handleCreateRecord}
                  />
                </div>
              </Dialog>
              <Dialog open={isCompleteCreateDialogOpen} onClose={() => setIsCompleteCreateDialogOpen(false)} maxWidth="md">
                <CompleteCreateWrapper>
                  <img src={require('assets/images/completeCreateReflection.png')} style={{ width: '94px', height: '94px' }} />
                  <CompleteCreateTitle>회고록 작성 완료!</CompleteCreateTitle>
                  <CompleteCreateSubTitle>수고하셨어요! 한 걸음 더 성장했어요.</CompleteCreateSubTitle>
                  <ReflectionCalendarReadOnly records={records} recordDate={record.date} />
                  <CompleteCreateNotice>{countDown}초 후 자동으로 창이 닫혀요.</CompleteCreateNotice>
                </CompleteCreateWrapper>
              </Dialog>
              <Dialog open={confirmReflectionDialog} onClose={() => setConfirmReflectionDialog(false)} maxWidth="md">
                <ConfirmReflectionDialogWrapper>
                  <div style={{ fontSize: 16, fontWeight: 700, marginBottom: 32 }}>{isCalendar ? '변경사항을 저장할까요?' : '작성한 회고를 저장할까요?'}</div>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSaveConfirmReflectionDialog}
                    sx={{ height: '40px', borderRadius: '8px', boxShadow: 'none', fontWeight: 700, marginBottom: '12px' }}
                  >
                    저장하고 닫기
                  </Button>
                  <Button
                    fullWidth
                    onClick={handleCloseConfirmReflectionDialog}
                    sx={{ height: '40px', backgroundColor: COLORS.gray100, borderRadius: '8px', boxShadow: 'none', color: COLORS.gray900, fontWeight: 700 }}
                  >
                    {isCalendar ? '닫기' : '삭제하기'}
                  </Button>
                </ConfirmReflectionDialogWrapper>
              </Dialog>
            </div>
          </ReflectionWritingWrapper>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${COLORS.gray100};

  .MuiInputBase-root {
    min-height: 64px;
  }
`;

const ReflectionCalendarWrapper = styled.div`
  font-size: 20px;
  font-weight: 700;
  padding: 24px;
  border-right: 1px dashed ${COLORS.gray300};
`;

const ReflectionWritingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
  .codex-editor__redactor {
    padding-bottom: 0px !important;
  }
`;

const HeaderWrapper = styled.div`
  width: 630px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderDateWrapper = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${COLORS.gray900};
`;

const HeaderButtonWrapper = styled.div`
  .Mui-disabled {
    background-color: ${COLORS.gray200} !important;
  }
`;

const BlankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BlankTitle = styled.div`
  margin-top: 32px;
  color: ${COLORS.gray900};
  font-size: 32px;
  font-weight: 700;
`;

const BlankSubTitle = styled.div`
  margin-top: 8px;
  color: var(--Gray_900, #1f2023);
  font-size: 20px;
  font-weight: 400;
`;

const BlankReflectionBtn = styled.div`
  margin-top: 24px;
  svg {
    margin-right: 6px;
  }
  button {
    height: 40px;
    padding: 22px 32px 22px 28px;
    border-radius: 8px;
    box-shadow: none;
    font-size: 16px;
    font-weight: 700;
  }
`;

const TooltipWrapper = styled.div`
  padding: 5px 0px;
  font-size: 12px;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const ReflectionEditHeader = styled.div`
  display: flex;
  justify-content: end;
`;

const CompleteCreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const CompleteCreateTitle = styled.div`
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  color: ${COLORS.gray900};
`;

const CompleteCreateSubTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.gray900};
`;

const CompleteCreateNotice = styled.div`
  margin-top: 24px;
  font-size: 13px;
  font-weight: 400;
  color: ${COLORS.gray900};
`;

const ConfirmReflectionDialogWrapper = styled.div`
  width: 298px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;
