import { SVGProps } from 'react';

export const Hand = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1852_574)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 3.5V5.5C10.0002 5.10218 9.84221 4.72064 9.5609 4.43934C9.2796 4.15804 8.89807 4 8.50024 4C8.10242 4 7.72089 4.15804 7.43958 4.43934C7.15828 4.72064 7.00024 5.10218 7.00024 5.5V13L5.53018 11.53C5.23572 11.2359 4.85143 11.0486 4.43834 10.9978C4.02526 10.9471 3.60706 11.0359 3.25018 11.25C2.91513 11.4509 2.67166 11.7745 2.57157 12.1522C2.47147 12.5298 2.52263 12.9315 2.71418 13.272C4.59318 16.612 5.68818 18.521 6.00018 19C6.06526 19.1002 6.13059 19.2002 6.19618 19.3C6.7427 20.1299 7.48676 20.811 8.36157 21.2823C9.23577 21.7532 10.2132 21.9998 11.2062 22H13.0002C14.5915 22 16.1176 21.3679 17.2428 20.2426C18.368 19.1174 19.0002 17.5913 19.0002 16V7.5C19.0002 7.10218 18.8421 6.72064 18.5608 6.43934C18.2795 6.15804 17.898 6 17.5002 6C17.1024 6 16.7208 6.15804 16.4395 6.43934C16.1614 6.71745 16.0038 7.09353 16.0002 7.48646V5.5C16.0002 5.10218 15.8422 4.72064 15.5609 4.43934C15.2796 4.15804 14.8981 4 14.5002 4C14.1024 4 13.7209 4.15804 13.4396 4.43934C13.1583 4.72064 13.0002 5.10218 13.0002 5.5V3.5C13.0002 3.30302 12.9614 3.10796 12.8861 2.92597C12.8107 2.74399 12.7002 2.57863 12.5609 2.43934C12.4216 2.30005 12.2563 2.18956 12.0743 2.11418C11.8923 2.0388 11.6972 2 11.5002 2C11.3033 2 11.1082 2.0388 10.9262 2.11418C10.7442 2.18956 10.5789 2.30005 10.4396 2.43934C10.3003 2.57863 10.1898 2.74399 10.1144 2.92597C10.039 3.10796 10.0002 3.30302 10.0002 3.5Z"
          fill="#FFCC4D"
        />
        <path d="M10 6V9" stroke="white" strokeLinecap="round" />
        <path d="M13 6V9" stroke="white" strokeLinecap="round" />
        <path d="M16 8V11" stroke="white" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1852_574">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
