import { SVGProps } from 'react';

export const File = ({ width = 24, height = 24 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1891_717)">
        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E7EAF4" />
        <path
          d="M15.3335 18H8.66683C8.31321 18 7.97407 17.8595 7.72402 17.6095C7.47397 17.3594 7.3335 17.0203 7.3335 16.6667V7.33333C7.3335 6.97971 7.47397 6.64057 7.72402 6.39052C7.97407 6.14048 8.31321 6 8.66683 6H13.3335L16.6668 9.33333V16.6667C16.6668 17.0203 16.5264 17.3594 16.2763 17.6095C16.0263 17.8595 15.6871 18 15.3335 18Z"
          fill="#E2ECFF"
        />
        <path
          d="M10.5 12L12 11L13.5 12"
          stroke="#0039A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11V15"
          stroke="#0039A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3335 6V8.66667C13.3335 8.84348 13.4037 9.01305 13.5288 9.13807C13.6538 9.2631 13.8234 9.33333 14.0002 9.33333H16.6668"
          fill="#0039A7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1891_717">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
