import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import IssueListView from 'components/Task/IssueListView';
import { useAtom } from 'jotai';
import { fetchInstancesFromProjectDetailViewAtom, projectsAtom } from 'atoms/projects';
import { deleteProjectV1ProjectProjectIdDelete } from 'queries';
import toast from 'react-hot-toast';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ListWrapper = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
`;

export interface PlanProps {
  active?: boolean;
}

const Plan = ({ active }: PlanProps) => {
  const [projects, fetchProjects] = useAtom(projectsAtom);
  const [, setFetchInstancesFromProjectDetailViewAtom] = useAtom(fetchInstancesFromProjectDetailViewAtom);

  useEffect(() => {
    if (active) fetchProjects();
  }, [active]);

  const handleFetchProjects = () => {
    fetchProjects();
  };

  const handleDeleteProject = async (id: string) => {
    try {
      await deleteProjectV1ProjectProjectIdDelete(id);
      toast.success('프로젝트를 삭제하였습니다.');
      fetchProjects();
      setFetchInstancesFromProjectDetailViewAtom(true);
    } catch (e) {
      toast.error('프로젝트를 삭제할 수 없습니다.');
    }
  };

  return (
    <Container>
      <ListWrapper>
        <IssueListView
          items={projects.filter((v) => v.place === 'DESK')}
          draggable={true}
          editable={true}
          dragView="side-project"
          multipleDrag={true}
          canAddItem={false}
          onFetchProjects={handleFetchProjects}
          onDeleteProject={handleDeleteProject}
        />
      </ListWrapper>
    </Container>
  );
};

export default Plan;
