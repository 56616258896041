import { SVGProps } from 'react';

export const Speaker = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1852_532)">
        <path
          d="M18 8C18.7956 8 19.5587 8.31607 20.1213 8.87868C20.6839 9.44129 21 10.2044 21 11C21 11.7956 20.6839 12.5587 20.1213 13.1213C19.5587 13.6839 18.7956 14 18 14"
          fill="#10B8C2"
        />
        <path
          d="M9.75 9V19.3125C9.75 19.5611 9.64464 19.7996 9.45711 19.9754C9.26957 20.1512 9.01522 20.25 8.75 20.25H7.75C7.48478 20.25 7.23043 20.1512 7.04289 19.9754C6.85536 19.7996 6.75 19.5611 6.75 19.3125V9"
          fill="#0039A7"
        />
        <path
          d="M12.3 8.34821L16.5978 4.70164C16.7227 4.59573 16.8746 4.52826 17.0358 4.50713C17.197 4.486 17.3608 4.51208 17.5079 4.58233C17.6551 4.65257 17.7795 4.76407 17.8667 4.90375C17.9538 5.04343 18.0001 5.2055 18 5.37098V17.129C18.0001 17.2945 17.9538 17.4566 17.8667 17.5963C17.7795 17.7359 17.6551 17.8474 17.5079 17.9177C17.3608 17.9879 17.197 18.014 17.0358 17.9929C16.8746 17.9717 16.7227 17.9043 16.5978 17.7984L12.3 14.1518H4.7C4.44804 14.1518 4.20641 14.0499 4.02825 13.8685C3.85009 13.6871 3.75 13.4411 3.75 13.1845V9.31548C3.75 9.05894 3.85009 8.81292 4.02825 8.63152C4.20641 8.45012 4.44804 8.34821 4.7 8.34821H12.3Z"
          fill="#0039A7"
        />
        <rect x="6.75" y="8.25" width="3" height="6" fill="#10B8C2" />
      </g>
      <defs>
        <clipPath id="clip0_1852_532">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
