import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { Icons, Login } from 'components';
import Fonts from 'components/Fonts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Slide from 'components/Slide';
import { COLORS } from 'styles/constants';
import { useEffect } from 'react';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  header {
    position: absolute;
    left: 40px;
    top: 40px;
  }

  section.signin {
    white-space: pre-line;
    padding: 24px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.25px;
    }
    footer {
      position: absolute;
      bottom: 80px;
    }
  }
  section.intro {
    flex: 3;
    overflow: hidden;
    background-color: #2d6adf;
  }
`;

const LoginErrorWrapper = styled.div`
  width: 400px;
  display: flex;
  padding: 16px 24px;
  margin-top: 30px;
  border-radius: 8px;
  background-color: ${COLORS.negative2};
`;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const onHandleLoginGoogle = useGoogleLogin(options);
  return <Login onClick={onHandleLoginGoogle} label={label} />;
};

const SlidePages = [
  {
    title: '구글 캘린더와 연동해서 일정과 업무를\n한 눈에 보세요',
    image: require('assets/images/newSwipe1.png'),
  },
  {
    title: '하루를 계획하는 가장 간편한 방법을\n경험해보세요',
    image: require('assets/images/newSwipe2.png'),
  },
  {
    title: '갑자기 생긴 일, 일단 적어두어\n머리를 맑게 비워요',
    image: require('assets/images/newSwipe3.png'),
  },
];

const SignIn = () => {
  const location = useLocation();
  const loginError = new URLSearchParams(location.search).get('error');
  const surveyStatus = new URLSearchParams(location.search).get('survey_target');

  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/auth`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  useEffect(() => {
    if (surveyStatus === 'false') {
      localStorage.setItem('surveyTarget', 'false');
    }
  }, []);

  return (
    <Wrapper>
      <header>
        <Icons.SlashBeta width={96} height={28} />
      </header>
      <section className="signin">
        {loginError ? (
          <div className="title pb-3">{'SLASH에 구글 캘린더를\n연동해 주세요'}</div>
        ) : (
          <div className="title pb-3">{'SLASH에 오신 것을\n환영합니다'}</div>
        )}

        <Fonts.Body1 className="text-gray800 pb-6">{'여러분의 편리한 일정 관리를 위해\n구글 아이디를 사용하여 구글 캘린더를 연동합니다.'}</Fonts.Body1>

        <div className="pb-8">
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogInButton options={googleLoginOptions} label={'구글 계정으로 시작하기'} />
          </GoogleOAuthProvider>
        </div>

        <div style={{ fontSize: '12px' }}>
          서비스 가입 시{' '}
          <a href="/" style={{ color: COLORS.brand1, textDecoration: 'underline' }}>
            이용약관
          </a>
          에 동의하며{' '}
          <a href="http://policy.doslash.io/" target="_blank" rel="noreferrer" style={{ color: COLORS.brand1, textDecoration: 'underline' }}>
            개인정보 처리방침
          </a>
          의 내용을 확인한 것으로 간주됩니다.
        </div>

        {loginError && (
          <LoginErrorWrapper>
            <div
              style={{
                width: '24px',
                height: '24px',
                marginRight: '8px',
                borderRadius: '50%',
                backgroundColor: COLORS.negative1,
                color: COLORS.white,
                fontWeight: 'bold',
              }}
            >
              !
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Fonts.H3>액서스 설정 실패</Fonts.H3>
              <Fonts.Body1
                style={{ textAlign: 'left', marginTop: '4px' }}
              >{`SLASH를 사용하시려면 '모든 캘린더의 일정 보기 \n및 수정'을 허용해 주셔야 합니다.`}</Fonts.Body1>
            </div>
          </LoginErrorWrapper>
        )}
      </section>
      <section className="intro px-16">
        <Slide pages={SlidePages} />
      </section>
    </Wrapper>
  );
};

export default SignIn;
