import styled from '@emotion/styled';
import { Button, Divider, IconButton, Popover, TextField, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { Task as TaskType } from 'components/Task/TaskItem2';
import { TaskBlockHandle } from 'pages/Task/components/TaskBlockInputList';
import { useEffect, useRef, useState } from 'react';
import TaskGroupBlockInputList, { TaskBlock } from 'pages/Task/components/TaskGroupBlockInputList';
import { InboxContextMenuType } from 'components/InboxContextMenu';
import { CreateTaskboxTask, InCreateLink, OutCategory, OutLink, UpdateTaskboxTask } from 'queries/model';
import ConfirmDialog, { DialogElement } from 'components/ConfirmDialog';
import { osName } from 'react-device-detect';
import { useEventListener, useKeyboardEvent } from '@react-hookz/web';
import { createLinkV1LinkPost, updateLinkV1LinkLinkIdPatch, updateTaskboxV1TaskboxesTaskboxIdPut } from 'queries';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import dayjs, { Dayjs } from 'lib/dayjs';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType } from 'remirror';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { hideScroll } from 'styles/utils';
import { isMouseInsideMemoContainer, removeMemoHandle } from 'components/Remirror/utils';

const Container = styled.div`
  max-height: 500px;
  width: 456px;
  overflow-y: scroll;
  ${hideScroll()}
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px 16px;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: ${COLORS.white};
`;

const TaskItemFieldWrapper = styled.div`
  padding: 4px 16px 12px 16px;
`;

const TaskItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TaskItemTitle = styled.div`
  font-size: 13px;
  margin-left: 8px;
`;

const SubTaskWrapper = styled.div``;

const MemoWrapper = styled.div`
  padding: 16px;

  .codex-editor__redactor {
    padding-bottom: 0px !important;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
`;

const LinkContentWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 0px 8px 8px;
  position: relative;

  .link-icon {
    opacity: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :hover {
    background-color: ${COLORS.gray100};
    .link-icon {
      opacity: 1;
    }
  }
`;

const CreateLinkButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const LinkPopoverContainer = styled.div`
  width: 365px;
`;

const LinkPopoverWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
`;

const LinkURLWrapper = styled.div``;

const LinkTitleWrapper = styled.div`
  margin-top: 12px;
`;

const LinkDeleteWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

interface TaskItemEditProps {
  taskItem?: TaskType;
  categoryList?: OutCategory[];
  onChange?: (taskItem: TaskType) => void;
  onClickContextMenu?: (id: string, type: string, menu: InboxContextMenuType, data: any) => void;
  onClickCheckbox?: (type: string, workId: string) => void;
  onCreateWorkInWorkbox?: (work: CreateTaskboxTask) => void;
  onUpdateWorkInWorkbox?: (workId: string, work: UpdateTaskboxTask) => void;
  onDeleteWorkInWorkbox?: (workId: string) => void;
  onClickCategoryActions?: (category: OutCategory, action: CategoryActionType) => void;
  onChangeDeadline?: (date: Dayjs) => void;
}

const TaskItemEdit = ({
  taskItem,
  categoryList,
  onChange,
  onClickContextMenu,
  onClickCheckbox,
  onCreateWorkInWorkbox,
  onDeleteWorkInWorkbox,
  onUpdateWorkInWorkbox,
  onClickCategoryActions,
}: TaskItemEditProps) => {
  const [taskItemState, setTaskItemState] = useState<TaskType>(taskItem!);
  const [hover, setHover] = useState(false);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [taskItemMemo, setTaskItemMemo] = useState<Element | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const taskItemMemo = document.querySelector('.task-item-memo');
      setTaskItemMemo(taskItemMemo);
      const remirror = taskItemMemo?.firstChild as HTMLDivElement;
      if (remirror) remirror.classList.add('task-item-memo');
    }, 100);
  }, []);

  useEffect(() => {
    setTaskItemState(taskItem!);
  }, [taskItem]);

  useKeyboardEvent(
    true,
    (ev) => {
      if ((osName === 'Windows' && ev.code === 'Delete') || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey)) refConfirm.current?.open();
    },
    [],
    { eventOptions: { passive: true } },
  );

  useEventListener(
    taskItemMemo,
    'mouseleave',
    (e: any) => {
      const inside = isMouseInsideMemoContainer(e, taskItemMemo as Element);
      if (!inside) {
        removeMemoHandle();
      }
    },
    { passive: true },
  );

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refTaskBlockList?.current?.add();
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }

    if (e.key === '$') {
      setTimeout(() => {
        setDeadlineAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleBlurInput = () => {
    if (!refInput.current) return;
    const newTaskItem: TaskType = { ...taskItemState, title: refInput.current.value, id: taskItemState?.id || '' };
    onChange && onChange(newTaskItem);
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newTaskItem: TaskType = { ...taskItemState, title: e.target.value, id: taskItemState?.id || '' };
    onChange && onChange(newTaskItem);
  };

  const handleChangeTaskBlock = (tasks: TaskBlock[]) => {
    const newTaskItem: TaskType = { ...taskItemState, tasks, id: taskItemState?.id || '' };
    onChange && onChange(newTaskItem);
  };

  const handleClickCheckbox = (workId: string) => {
    onClickCheckbox && onClickCheckbox('SOMEDAY_TASK', workId);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const refConfirm = useRef<DialogElement>(null);
  const handleConfirm = () => {
    onClickContextMenu && onClickContextMenu(taskItemState!.id!, taskItemState!.type!, 'DELETE', taskItemState?.tasks);
  };

  const saveMemo = async (data: any) => {
    if (!data) return;
    await updateTaskboxV1TaskboxesTaskboxIdPut(taskItemState!.id!, { ...data, memo: data });
  };

  const handleChangeMemo = (data: any) => {
    if (!data) return;
    setTaskItemState({ ...taskItemState, memo: data });
    saveMemo(data);
  };

  const [selectedLink, setSelectedLink] = useState<OutLink | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState<HTMLElement | null>(null);
  const createLinkInputRef = useRef<HTMLInputElement>(null);

  const handleCreateLink = () => {
    const links = taskItemState?.links || [];
    if (links[links.length - 1]?.title === '' && links[links.length - 1]?.url === '') {
      setSelectedLink(links[links.length - 1]);
      setTimeout(() => {
        if (createLinkInputRef.current) createLinkInputRef.current?.focus();
      }, 100);
    } else {
      const newLink = {
        title: '',
        url: '',
        id: uuidv4(),
        taskId: taskItemState?.id,
      };
      setSelectedLink(newLink as OutLink);
      onChange && onChange({ ...(taskItemState as TaskType), links: [...links, newLink] as OutLink[] });
    }
  };

  const handleSaveLink = async () => {
    if (!selectedLink || (selectedLink?.title === '' && selectedLink?.url === '')) {
      clearLinkState();
      return;
    }

    const links = taskItemState?.links || [];
    const targetLink = links.find((v) => v.id === selectedLink.id);
    if (targetLink) {
      targetLink.url = selectedLink.url;
      targetLink.title = selectedLink.title;
      if (targetLink?.createdAt) {
        await updateLinkV1LinkLinkIdPatch(targetLink.id, { ...targetLink });
        onChange && onChange({ ...(taskItemState as TaskType), links: [...links] });
      } else {
        const success = await createLinkV1LinkPost(targetLink as InCreateLink);
        if (success) {
          targetLink.createdAt = success.createdAt;
          onChange && onChange({ ...(taskItemState as TaskType), links: [...links] });
          await updateTaskboxV1TaskboxesTaskboxIdPut(taskItemState!.id!, { linkIds: links.map((link) => link.id) });
        }
      }
    }
    clearLinkState();
  };

  const clearLinkState = () => {
    setSelectedLink(null);
    setLinkAnchorEl(null);
  };

  const handleClickLinkUpdate = (link: OutLink) => {
    setSelectedLink(link);
    setTimeout(() => {
      const el = document.querySelector(`[data-link-id="${link.id}"]`) as HTMLDivElement;
      setLinkAnchorEl(el);
    }, 100);
  };

  const handleCopyClipBoard = async (text: string) => {
    if (text === '') return toast.error('링크가 없습니다.');

    try {
      await navigator.clipboard.writeText(text);
      toast.success('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      toast.error('복사에 실패하였습니다');
    }
  };

  const handleDeleteLink = async () => {
    await updateTaskboxV1TaskboxesTaskboxIdPut(taskItemState!.id!, {
      linkIds: taskItemState?.links?.filter((link) => link.id !== selectedLink?.id).map((link) => link.id),
    });
    clearLinkState();
    onChange && onChange({ ...(taskItemState as TaskType), links: taskItemState?.links?.filter((link) => link.id !== selectedLink?.id) });
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickCategory = (category: OutCategory | null, action: CategoryActionType) => {
    if (action === 'SELECT') {
      const updatedTask = { ...(taskItemState as TaskType), category };

      const value = refInput.current?.value;
      if (value && value.includes('#')) {
        const updatedValue = value.replace(/#/g, '');
        refInput.current.value = updatedValue;
        updatedTask.title = updatedValue;
      }

      onChange && onChange(updatedTask);
      setCategoryAnchorEl(null);
      return;
    } else {
      if (action === 'DELETE') {
        const updatedTasks = taskItemState?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: undefined };
          }
          return task;
        });
        onChange && onChange({ ...taskItemState!, category: null, tasks: updatedTasks });
        setCategoryAnchorEl(null);
      } else if (action === 'UPDATE') {
        const updatedTasks = taskItemState?.tasks?.map((task) => {
          if (task.category?.id === category?.id) {
            return { ...task, category: category! };
          }
          return task;
        });
        onChange &&
          onChange({
            ...taskItemState!,
            category: taskItemState.category && taskItemState.category.id === category?.id ? category : taskItemState.category,
            tasks: updatedTasks,
          });
      }
    }
    onClickCategoryActions && onClickCategoryActions(category!, action);
  };

  const handleClickDeadline = (date: Dayjs | null) => {
    const updatedTask = { ...(taskItemState as TaskType), deadline: date ? date.format('YYYY-MM-DD') : null };

    const value = refInput.current?.value;
    if (value && value.includes('$')) {
      const updatedValue = value.replace(/\$/g, '');
      refInput.current.value = updatedValue;
      updatedTask.title = updatedValue;
    }

    onChange && onChange(updatedTask);
    setDeadlineAnchorEl(null);
  };

  return (
    <Container className="taskitem-container">
      <HeaderWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {taskItemState && taskItemState.category ? (
            <Tooltip title="카테고리 설정하기" disableInteractive>
              <CategoryShowingWrapper
                textColor={getCategoryTextColor(taskItemState.category!.color)}
                bgColor={getCategoryBgColor(taskItemState.category!.color)}
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
              >
                {`# ${taskItemState!.category.name}`}
                <IconButton
                  className="category-detach-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickCategory(null, 'SELECT');
                  }}
                  sx={{
                    'width': '12px',
                    'height': '12px',
                    'borderRadius': '4px',
                    'marginLeft': '4px',
                    'marginTop': '1px',
                    'padding': '0px',
                    ':hover': {
                      backgroundColor: hexToRGBA(getCategoryTextColor(taskItemState.category!.color)!, 0.3),
                    },
                  }}
                  style={categoryAnchorEl ? { display: 'flex' } : {}}
                >
                  <Icons.Close width={8} height={8} fill={getCategoryTextColor(taskItemState.category!.color)} />
                </IconButton>
              </CategoryShowingWrapper>
            </Tooltip>
          ) : (
            <Tooltip title="카테고리 설정하기" disableInteractive>
              <IconButton
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
              >
                <Icons.Hashtag />
              </IconButton>
            </Tooltip>
          )}
          {taskItemState && taskItemState.deadline ? (
            <Tooltip title="기한 설정하기" disableInteractive>
              <DeadlineShowingWrapper date={taskItemState.deadline} onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}>
                <Icons.Flag
                  fill={
                    dayjs(taskItemState.deadline).isToday()
                      ? COLORS.brand1
                      : dayjs(taskItemState.deadline).isBefore(dayjs())
                      ? COLORS.negative1
                      : COLORS.gray600
                  }
                />
                <span style={{ marginLeft: '2px' }}>
                  {dayjs(taskItemState.deadline).isToday()
                    ? '오늘'
                    : dayjs(taskItemState.deadline).isYesterday()
                    ? '어제'
                    : dayjs(taskItemState.deadline).isTomorrow()
                    ? '내일'
                    : dayjs(taskItemState.deadline).format('M월 D일 (dd)')}
                </span>
                <IconButton
                  className="deadline-detach-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickDeadline(null);
                  }}
                  sx={{
                    'width': '12px',
                    'height': '12px',
                    'borderRadius': '4px',
                    'marginLeft': '4px',
                    'marginTop': '1px',
                    'padding': '0px',
                    ':hover': {
                      backgroundColor: hexToRGBA(
                        dayjs(taskItemState.deadline).isToday()
                          ? COLORS.brand1
                          : dayjs(taskItemState.deadline).isBefore(dayjs())
                          ? COLORS.negative1
                          : COLORS.gray600,
                        0.3,
                      ),
                    },
                  }}
                  style={deadlineAnchorEl ? { display: 'flex' } : {}}
                >
                  <Icons.Close
                    width={8}
                    height={8}
                    fill={
                      dayjs(taskItemState.deadline).isToday()
                        ? COLORS.brand1
                        : dayjs(taskItemState.deadline).isBefore(dayjs())
                        ? COLORS.negative1
                        : COLORS.gray600
                    }
                  />
                </IconButton>
              </DeadlineShowingWrapper>
            </Tooltip>
          ) : (
            <Tooltip title="기한 설정하기" disableInteractive>
              <IconButton
                onClick={(e) => setDeadlineAnchorEl(e.currentTarget)}
                sx={{ width: '20px', height: '20px', border: `1px solid ${COLORS.gray200}`, borderRadius: '4px', padding: '0px', marginRight: '4px' }}
              >
                <Icons.Flag />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div style={{ display: 'flex', height: '24px' }}>
          <Button
            onClick={() =>
              onClickContextMenu && onClickContextMenu(taskItemState!.id!, taskItemState!.type!, 'COMPLETED_AND_MOVE_TO_TODAY', taskItemState?.tasks)
            }
            sx={{
              'display': 'flex',
              'alignItems': 'center',
              'borderRadius': '8px',
              'padding': '3px 6px',
              'fontSize': '12px',
              'color': COLORS.gray400,
              ':hover': {
                '.check': {
                  filter: 'invert(67%) sepia(12%) saturate(274%) hue-rotate(186deg) brightness(79%) contrast(83%)',
                },
                'color': COLORS.gray500,
                'backgroundColor': COLORS.gray100,
              },
            }}
          >
            <div className="check">
              <Icons.Check fill={COLORS.gray400} />
            </div>
            <div style={{ marginLeft: '4px' }}>완료하고 오늘로 보내기</div>
          </Button>
          <Tooltip
            title={
              <div style={{ margin: '2px 4px', display: 'flex', alignItems: 'center' }}>
                <span>삭제</span>
                <div style={{ marginLeft: '8px' }}>
                  {osName === 'Windows' ? (
                    <KeyboardButtonRect small>Delete</KeyboardButtonRect>
                  ) : (
                    <>
                      <KeyboardButtonRect small>⌘</KeyboardButtonRect> + <KeyboardButtonRect small>Backspace</KeyboardButtonRect>
                    </>
                  )}
                </div>
              </div>
            }
            disableInteractive
          >
            <IconButton
              onClick={async () => refConfirm.current?.open()}
              sx={{
                'borderRadius': '6px',
                'padding': '4px',
                ':hover': {
                  '.delete': {
                    filter: 'invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%)',
                  },
                  'backgroundColor': COLORS.gray100,
                },
              }}
            >
              <div className="delete">
                <Icons.Delete fill={COLORS.gray400} />
              </div>
            </IconButton>
          </Tooltip>
        </div>
      </HeaderWrapper>
      <TaskItemFieldWrapper>
        <TaskItemTitleWrapper>
          {taskItemState!.tasks!.length > 0 ? (
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => onClickCheckbox && onClickCheckbox('SOMEDAY_TASKBOX', taskItemState!.id)}
              style={{ cursor: 'pointer' }}
            >
              {hover ? (
                <Tooltip title={'그룹 풀기'} disableInteractive>
                  <div>
                    <Icons.TaskUngroupCheckbox />
                  </div>
                </Tooltip>
              ) : (
                <Icons.TaskGroupCheckbox />
              )}
            </div>
          ) : (
            <Icons.TaskCheckbox />
          )}
          <TextField
            inputRef={refInput}
            fullWidth
            autoComplete="off"
            variant="standard"
            onKeyDown={handleKeydownInput}
            onBlur={handleBlurInput}
            onChange={(e) => {
              handleChangeTitle(e);
            }}
            defaultValue={taskItemState?.title}
            InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray900, padding: '0px', height: '30px' } }}
            style={{ marginLeft: 8 }}
          />
        </TaskItemTitleWrapper>
        <SubTaskWrapper>
          <TaskGroupBlockInputList
            ref={refTaskBlockList}
            date={dayjs().toDate()}
            tasks={taskItemState?.tasks}
            category={categoryList}
            draggable={false}
            onChange={handleChangeTaskBlock}
            onClickCheckbox={handleClickCheckbox}
            onCreateWorkInWorkbox={onCreateWorkInWorkbox}
            onUpdateWorkInWorkbox={onUpdateWorkInWorkbox}
            onDeleteWorkInWorkbox={onDeleteWorkInWorkbox}
            onClickCategory={handleClickCategory}
          />
        </SubTaskWrapper>
      </TaskItemFieldWrapper>
      <Divider />
      <MemoWrapper className="task-item-memo">
        <RemirrorEditor data={taskItemState && taskItemState.memo ? (taskItemState.memo as RemirrorContentType) : undefined} onChangeData={handleChangeMemo} />
      </MemoWrapper>
      <Divider />
      <LinkWrapper>
        {taskItemState?.links?.map((link) => (
          <LinkContentWrapper data-link-id={link.id} key={link.id} style={{ height: '28px' }}>
            <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
              <Icons.Link2 />
              {link.url === '' ? (
                <TextField
                  data-link-input-id={link.id}
                  inputRef={createLinkInputRef}
                  fullWidth
                  autoFocus
                  placeholder="URL 주소(https://…)를 붙여넣고 Enter로 입력하기"
                  onChange={(e) => {
                    setSelectedLink({ ...link, url: e.target.value, title: e.target.value });
                  }}
                  onBlur={handleSaveLink}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (e.nativeEvent.isComposing) return;
                      e.preventDefault();
                      handleSaveLink();
                    }
                  }}
                  inputProps={{
                    style: { border: 'none', fontSize: '13px', padding: '0px' },
                  }}
                  sx={{ border: 'none', marginLeft: '8px' }}
                />
              ) : (
                <Tooltip title={link.url} disableInteractive>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      width: '100%',
                      fontSize: '13px',
                      marginLeft: '8px',
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {link.title || link.url}
                  </a>
                </Tooltip>
              )}
            </div>
            <div
              className="link-icon"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '28px',
                backgroundColor: COLORS.white,
                border: `1px solid ${COLORS.gray200}`,
                borderRadius: '8px',
                position: 'absolute',
                right: '0px',
              }}
            >
              <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                <Icons.Duplicate stroke={COLORS.gray400} />
              </IconButton>
              <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleClickLinkUpdate(link)}>
                <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
              </IconButton>
            </div>
          </LinkContentWrapper>
        ))}
        <CreateLinkButton onClick={handleCreateLink}>
          <Icons.AddLink />
          <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>링크 추가하기</div>
        </CreateLinkButton>
      </LinkWrapper>

      {linkAnchorEl && (
        <Popover
          open={Boolean(linkAnchorEl)}
          anchorEl={linkAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleSaveLink}
          sx={{ marginLeft: '30px' }}
        >
          <LinkPopoverContainer>
            <LinkPopoverWrapper>
              <LinkURLWrapper>
                <div>링크 URL</div>
                <TextField
                  autoFocus
                  fullWidth
                  value={selectedLink?.url}
                  placeholder="링크 URL 주소(https://…)를 입력해주세요."
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, url: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkURLWrapper>
              <LinkTitleWrapper>
                <div>링크 제목</div>
                <TextField
                  fullWidth
                  value={selectedLink?.title}
                  placeholder="링크 제목을 입력해주세요."
                  onChange={(e) => {
                    if (!selectedLink) return;
                    setSelectedLink({ ...selectedLink, title: e.target.value });
                  }}
                  sx={{ marginTop: '4px' }}
                  inputProps={{
                    style: { padding: '8px 12px', fontSize: '12px' },
                  }}
                />
              </LinkTitleWrapper>
            </LinkPopoverWrapper>
            <Divider />
            <LinkDeleteWrapper>
              <Button
                onClick={handleDeleteLink}
                sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
              >
                <Icons.Delete fill={COLORS.negative1} />
                <span style={{ marginLeft: '8px' }}>링크 삭제</span>
              </Button>
            </LinkDeleteWrapper>
          </LinkPopoverContainer>
        </Popover>
      )}
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
      {/* 태스크박스 기한 */}
      {deadlineAnchorEl && (
        <Popover
          open={Boolean(deadlineAnchorEl)}
          anchorEl={deadlineAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setDeadlineAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleClickDeadline} />
        </Popover>
      )}
      <ConfirmDialog ref={refConfirm} type="task" title="태스크 삭제" onConfirm={handleConfirm}>
        <div style={{ color: COLORS.negative1, fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
          {`${
            taskItemState?.type === 'merge'
              ? '선택된 태스크'
              : taskItemState?.type === 'SOMEDAY_TASK'
              ? '태스크'
              : taskItemState?.type === 'SOMEDAY_TASKBOX'
              ? '태스크박스'
              : '이슈'
          }를 삭제 하시겠습니까?`}
        </div>
        <div style={{ color: COLORS.gray900, textAlign: 'center', fontSize: 13, marginTop: 4 }}>관련 데이터를 모두 삭제합니다.</div>
      </ConfirmDialog>
    </Container>
  );
};

export default TaskItemEdit;
