import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icons } from 'components';
import { CreateTaskbox, OutCategory, OutFrequentWork, OutTaskboxDetailResponse, OutTaskboxDetailResponseForSubtask } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import CreateTaskboxInput, { AutoCompleteWorkList } from './components/CreateTaskboxInput';
import TaskboxView from './components/TaskboxView';
import { useAtom } from 'jotai';
import { dragContextAtom } from 'atoms/works';
import { MultiSelectType } from '.';
import { Dayjs } from 'lib/dayjs';
import { Switch } from '@mui/material';
import { createCategoryV1CategoryPost, deleteCategoryV1CategoryCategoryIdDelete, updateCategoryV1CategoryCategoryIdPatch } from 'queries';
import { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DragStart, DropResult } from 'react-beautiful-dnd';

const Container = styled.div`
  width: 66%;
  padding: 24px;
`;

const TaskboxBoardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DoneTaskboxWrapper = styled.div`
  width: 48%;
`;

const UndoneTaskboxWrapper = styled.div`
  width: 48%;
`;

const TaskboxHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
`;

const TaskboxInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0px;
  padding: 0px 24px;
`;

const TaskboxEmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 80px;
`;

const TaskboxEmptyTitle = styled.div`
  text-align: center;
  font-weight: 700;
  color: ${COLORS.gray700};
  margin: 8px 0px 4px 0px;
`;

const TaskboxEmptySubTitle = styled.div`
  text-align: center;
  color: ${COLORS.gray500};
`;

const TaskboxDropZoneWrapper = styled.div<{ dragEnter?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.sub3};
  border-radius: 8px;
  border: 1px dashed ${COLORS.brand1};
  color: ${COLORS.brand1};

  ${(props) =>
    props.dragEnter &&
    css`
      color: ${COLORS.sub4};
      background-color: ${COLORS.sub5};
      border: 1px dashed ${COLORS.sub4};
    `}
`;

const TooltipWrapper = styled.div`
  width: 265px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${COLORS.gray900};
  color: ${COLORS.white};
  position: absolute;
  z-index: -1;
  opacity: 0;
  font-size: 12px;
  font-weight: 400;
`;

interface TaskboxBoardViewProps {
  date?: Date;
  taskboxes?: OutTaskboxDetailResponse[];
  autoCompleteList?: AutoCompleteWorkList[];
  entireTaskboxList?: AutoCompleteWorkList[];
  selectedEventId?: string;
  multiSelectIds?: string[];
  multiSelectAnchorEl?: HTMLElement | null;
  continueTaskbox?: boolean;
  categoryList?: OutCategory[];
  templateList?: OutFrequentWork[];
  onFetchTaskbox?: (date: Date) => void;
  onCreateTaskbox?: (taskbox: CreateTaskbox) => void;
  onFetchEntireTaskboxList?: (title?: string) => void;
  onFetchAutoCompleteList?: (title?: string) => void;
  onChangeSubtask?: (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => void;
  onBeautifulDragEnd?: (result: DropResult) => void;
  onBeautifulDragStart?: (start: DragStart) => void;
  onChangeTaskboxDragImage?: (taskbox: OutTaskboxDetailResponse) => void;
  onDropTaskbox?: (taskbox: OutTaskboxDetailResponse, done: boolean) => void;
  onDropMultiSelectTaskbox?: (taskboxes: OutTaskboxDetailResponse[], done: boolean) => void;
  onChangeMultiSelectIds?: (e: React.MouseEvent<HTMLDivElement>, eventId: string) => void;
  onCloseMultiSelect?: () => void;
  onClickMultiSelectActions?: (tpye: MultiSelectType, date?: Dayjs) => void;
  onMultiSelectDragStart?: (e: React.DragEvent<HTMLDivElement>, done: boolean) => void;
  onMultiSelectDragEnd?: () => void;
  onChangeContinueToggle?: (continueTaskbox: boolean) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onFetchCategoryList?: () => void;
  onFetchTemplateList?: () => void;
}

const TaskboxBoardView = ({
  date,
  taskboxes = [],
  entireTaskboxList = [],
  autoCompleteList = [],
  multiSelectIds = [],
  selectedEventId,
  multiSelectAnchorEl,
  continueTaskbox,
  categoryList = [],
  templateList = [],
  onFetchTaskbox,
  onCreateTaskbox,
  onFetchEntireTaskboxList,
  onFetchAutoCompleteList,
  onChangeSubtask,
  onBeautifulDragEnd,
  onBeautifulDragStart,
  onChangeTaskboxDragImage,
  onDropTaskbox,
  onDropMultiSelectTaskbox,
  onChangeMultiSelectIds,
  onCloseMultiSelect,
  onClickMultiSelectActions,
  onMultiSelectDragStart,
  onMultiSelectDragEnd,
  onChangeContinueToggle,
  onClickTaskbox,
  onFetchCategoryList,
  onFetchTemplateList,
}: TaskboxBoardViewProps) => {
  const [doneTaskboxes, setDoneTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [undoneTaskboxes, setUndoneTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [taskboxDragContext, setTaskboxDragContext] = useAtom(dragContextAtom);
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  useEffect(() => {
    setDoneTaskboxes(taskboxes.length === 0 ? [] : taskboxes.filter((taskbox) => taskbox.done));
    setUndoneTaskboxes(taskboxes.length === 0 ? [] : taskboxes.filter((taskbox) => !taskbox.done));
  }, [taskboxes]);

  const handleDragEnter = () => {
    setDragEnter(true);
  };

  const handleDragLeave = () => {
    setDragEnter(false);
  };

  const handleDropTaskbox = (done: boolean) => {
    if (taskboxDragContext?.type === 'taskbox') {
      const taskbox = taskboxes.find((v) => v.id === taskboxDragContext?.id);
      if (taskbox) onDropTaskbox?.(taskbox!, done);
    } else if (taskboxDragContext?.type === 'multi-taskbox') {
      const multiSelectTaskboxes = taskboxes.filter((v) => multiSelectIds.includes(v.id!));
      if (multiSelectTaskboxes.length !== 0) onDropMultiSelectTaskbox?.(multiSelectTaskboxes, done);
    }
  };

  const handleChangeContinueTaskbox = () => {
    onChangeContinueToggle?.(!continueTaskbox);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => {
    switch (action) {
      case 'CREATE':
        {
          if (!category) return;
          const success = await createCategoryV1CategoryPost(category);
          if (success) {
            onFetchCategoryList && onFetchCategoryList();
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
          if (success) {
            onFetchCategoryList && onFetchCategoryList();
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
          onFetchCategoryList && onFetchCategoryList();
        }
        break;
    }
  };

  return (
    <Container>
      <TaskboxBoardWrapper style={taskboxes.length !== 0 ? { height: '100%' } : {}}>
        <DoneTaskboxWrapper>
          <TaskboxHeaderWrapper>
            <div style={{ fontSize: '16px', fontWeight: 700 }}>완료</div>
            <div style={{ fontSize: '12px', color: COLORS.gray600 }}>오늘 어떤 일을 완료했나요?</div>
          </TaskboxHeaderWrapper>
          <TaskboxInputWrapper>
            <CreateTaskboxInput
              date={date!}
              onCreate={onCreateTaskbox}
              onChange={onFetchTaskbox}
              doneStatus={true}
              autoCompleteList={autoCompleteList}
              entireTaskboxList={entireTaskboxList}
              onFetchEntireTaskboxList={onFetchEntireTaskboxList}
              onFetchAutoCompleteList={onFetchAutoCompleteList}
            />
          </TaskboxInputWrapper>
          {taskboxDragContext && !taskboxDragContext.done ? (
            <div style={{ width: '100%', height: 'calc(100% - 84px)', padding: '0px 24px' }}>
              <TaskboxDropZoneWrapper
                dragEnter={dragEnter}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={() => handleDropTaskbox(true)}
              />
            </div>
          ) : taskboxes.length !== 0 ? (
            taskboxes.filter((v) => v.done).length !== 0 ? (
              <TaskboxView
                date={date}
                doneStatus={true}
                taskboxes={taskboxes.filter((v) => v.done)}
                selectedEvent={taskboxes.find((v) => v.id === selectedEventId) as any}
                multiSelectIds={multiSelectIds}
                multiSelectAnchorEl={multiSelectAnchorEl}
                categoryList={categoryList}
                templateList={templateList}
                onFetchTaskbox={onFetchTaskbox}
                onChangeTaskboxDragImage={onChangeTaskboxDragImage}
                onChangeSubtask={onChangeSubtask}
                onBeautifulDragEnd={onBeautifulDragEnd}
                onBeautifulDragStart={onBeautifulDragStart}
                onChangeMultiSelectIds={onChangeMultiSelectIds}
                onCloseMultiSelect={onCloseMultiSelect}
                onClickMultiSelectActions={onClickMultiSelectActions}
                onMultiSelectDragStart={onMultiSelectDragStart}
                onMultiSelectDragEnd={onMultiSelectDragEnd}
                onClickTaskbox={onClickTaskbox}
                onFetchCategoryList={onFetchCategoryList}
                onFetchTemplateList={onFetchTemplateList}
                onFetchEntireTaskboxList={onFetchEntireTaskboxList}
              />
            ) : (
              <TaskboxEmptyWrapper>
                <Icons.RitualDoneEmpty />
                <TaskboxEmptyTitle>어떤 일을 완료했나요?</TaskboxEmptyTitle>
                <TaskboxEmptySubTitle>미완료한 일 중 완료한 일을 체크해보세요.</TaskboxEmptySubTitle>
              </TaskboxEmptyWrapper>
            )
          ) : (
            <></>
          )}
        </DoneTaskboxWrapper>
        <UndoneTaskboxWrapper>
          <TaskboxHeaderWrapper>
            <div style={{ fontSize: '16px', fontWeight: 700 }}>미완료</div>
          </TaskboxHeaderWrapper>
          <TaskboxInputWrapper>
            <CreateTaskboxInput
              date={date!}
              doneStatus={false}
              onCreate={onCreateTaskbox}
              onChange={onFetchTaskbox}
              autoCompleteList={autoCompleteList}
              entireTaskboxList={entireTaskboxList}
              categoryList={categoryList}
              onFetchEntireTaskboxList={onFetchEntireTaskboxList}
              onFetchAutoCompleteList={onFetchAutoCompleteList}
              onClickCategoryActions={handleClickCategoryActions}
            />
          </TaskboxInputWrapper>
          {taskboxDragContext && taskboxDragContext.done ? (
            <div style={{ width: '100%', height: 'calc(100% - 84px)', padding: '0px 24px' }}>
              <TaskboxDropZoneWrapper
                dragEnter={dragEnter}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={() => handleDropTaskbox(false)}
              />
            </div>
          ) : taskboxes.length !== 0 ? (
            taskboxes.filter((v) => !v.done).length !== 0 ? (
              <TaskboxView
                date={date}
                doneStatus={false}
                taskboxes={taskboxes.filter((v) => !v.done)}
                selectedEvent={taskboxes.find((v) => v.id === selectedEventId) as any}
                multiSelectIds={multiSelectIds}
                multiSelectAnchorEl={multiSelectAnchorEl}
                categoryList={categoryList}
                templateList={templateList}
                onFetchTaskbox={onFetchTaskbox}
                onChangeTaskboxDragImage={onChangeTaskboxDragImage}
                onChangeSubtask={onChangeSubtask}
                onBeautifulDragEnd={onBeautifulDragEnd}
                onBeautifulDragStart={onBeautifulDragStart}
                onChangeMultiSelectIds={onChangeMultiSelectIds}
                onCloseMultiSelect={onCloseMultiSelect}
                onClickMultiSelectActions={onClickMultiSelectActions}
                onMultiSelectDragStart={onMultiSelectDragStart}
                onMultiSelectDragEnd={onMultiSelectDragEnd}
                onClickTaskbox={onClickTaskbox}
                onFetchCategoryList={onFetchCategoryList}
                onFetchTemplateList={onFetchTemplateList}
                onFetchEntireTaskboxList={onFetchEntireTaskboxList}
              />
            ) : (
              <TaskboxEmptyWrapper>
                <Icons.RitualUndoneEmpty />
                <TaskboxEmptyTitle>모든 할 일을 완료했어요!</TaskboxEmptyTitle>
                <TaskboxEmptySubTitle>회고를 통해 오늘 하루를 기록해보세요.</TaskboxEmptySubTitle>
              </TaskboxEmptyWrapper>
            )
          ) : (
            <></>
          )}
        </UndoneTaskboxWrapper>
      </TaskboxBoardWrapper>

      {taskboxes.length === 0 && (
        <TaskboxEmptyWrapper>
          <Icons.RitualEmpty />
          <TaskboxEmptyTitle>오늘 한 일을 정리하고 마무리해요</TaskboxEmptyTitle>
          <TaskboxEmptySubTitle>
            할 일과 한 일을 정리하고
            <br />
            회고를 통해 오늘 하루를 기록해보세요.
          </TaskboxEmptySubTitle>
        </TaskboxEmptyWrapper>
      )}
    </Container>
  );
};

export default TaskboxBoardView;

const AntSwitch = styled(Switch)(({ theme }) => ({
  'width': 28,
  'height': 16,
  'padding': 0,
  'display': 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    '&.Mui-checked': {
      'transform': 'translateX(12px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: COLORS.brand1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: 'width 0.2s',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: COLORS.gray300,
    boxSizing: 'border-box',
  },
}));
