import { SVGProps } from 'react';

export const ArrowRightLong = ({ width = 32, height = 32, fill = '#1F2023' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2201_10333)">
        <path d="M6.6665 16H25.3332" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3335 24L25.3335 16" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3335 8L25.3335 16" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2201_10333">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
