import { SVGProps } from 'react';

export const UnfocusMode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_20173_319176)">
        <path d="M18 10H14V6" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 4L14 10" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 14H10V18" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 14L4 20" stroke="#858994" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_20173_319176">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
