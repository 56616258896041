import styled from '@emotion/styled';
import dayjs from 'lib/dayjs';
import { Divider, MenuItem, MenuList, TextField } from '@mui/material';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { PropsWithChildren } from 'react';
import { COLORS } from 'styles/constants';

const TaskboxCalendarContainer = styled.div`
  font-size: 12px;
  margin: 12px;

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickerStaticWrapper-root {
    width: 200px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
    margin: 0;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

const TaskboxMenuItem = styled(MenuItem)`
  padding: 4px;
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

export interface TaskboxCalendarProps {
  currentDate?: Date | null;
  onChange?: (date: Date | null, isAllDay?: boolean) => void;
}

export interface TaskboxCalendarMenuItem {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}

export const TaskboxCalendarMenuItem = ({ icon, label, onClick }: TaskboxCalendarMenuItem) => {
  return (
    <TaskboxMenuItem sx={{ padding: '4px !important' }} onClick={onClick}>
      {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
      {label && <span style={{ fontSize: 12 }}>{label}</span>}
    </TaskboxMenuItem>
  );
};

const TaskboxCalendar = ({ currentDate, onChange, children }: TaskboxCalendarProps & PropsWithChildren) => {
  return (
    <>
      <TaskboxCalendarContainer>
        {children && (
          <>
            <MenuList>{children}</MenuList>
            <Divider style={{ margin: '12px 0px' }} />
          </>
        )}
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={currentDate}
          onChange={(newValue) => onChange?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TaskboxCalendarContainer>
    </>
  );
};

export default TaskboxCalendar;
