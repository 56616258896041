import styled from '@emotion/styled';
import { CommandButtonGroup } from '@remirror/react';
import { ToggleHeadingButton } from '../Buttons/HeadingButton';
import { ToggleBulletListButton } from '../Buttons/BulletListButton';
import { ToggleOrderedListButton } from '../Buttons/OrderedListButton';
import { ToggleTaskListButton } from '../Buttons/CheckListButton';
import { CreateTableButton } from '../Buttons/TableButton';
import { InsertHorizontalRuleButton } from '../Buttons/DividerButton';
import { InsertCodeBlockButton } from '../Buttons/CodeBlockButton';
import { InsertTrailingNodeButton } from '../Buttons/QuoteButton';
import { useRef, useState } from 'react';
import { useKeyboardEvent } from '@react-hookz/web';
import React from 'react';
import { MemoActionType } from '.';

interface MenuButtonsProps {
  onClose?: () => void;
  onChangeUserAction?: (actionType: MemoActionType) => Element | null;
}

const MenuButtons = ({ onClose, onChangeUserAction }: MenuButtonsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % buttons.length);
      }

      if (ev.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + buttons.length) % buttons.length);
      }
    },
    [],
    { eventOptions: { passive: false } },
  );

  const buttons = [
    <ToggleHeadingButton
      key={1}
      attrs={{ level: 1 }}
      commandName="헤딩 1"
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M10.6667 6.66927L12.6667 6.0026L12.6667 12.6693M2 3.33594V8.0026M2 8.0026V12.6693M2 8.0026H7.33333M7.33333 3.33594V8.0026M7.33333 8.0026V12.6693"
            stroke="#1F2023"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <ToggleHeadingButton
      key={2}
      attrs={{ level: 2 }}
      commandName="헤딩 2"
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M10.6667 6.66927L12.6667 6.0026L12.6667 12.6693M2 3.33594V8.0026M2 8.0026V12.6693M2 8.0026H7.33333M7.33333 3.33594V8.0026M7.33333 8.0026V12.6693"
            stroke="#1F2023"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <ToggleHeadingButton
      key={3}
      attrs={{ level: 3 }}
      commandName="헤딩 3"
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M10.6667 6.66927L12.6667 6.0026L12.6667 12.6693M2 3.33594V8.0026M2 8.0026V12.6693M2 8.0026H7.33333M7.33333 3.33594V8.0026M7.33333 8.0026V12.6693"
            stroke="#1F2023"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <ToggleBulletListButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M5.99998 11.3307H12.6666M5.99998 7.9974H12.6666M5.99998 4.66406H12.6666M3.33462 11.3307V11.3321L3.33331 11.332V11.3307H3.33462ZM3.33462 7.9974V7.99873L3.33331 7.9987V7.9974H3.33462ZM3.33462 4.66406V4.6654L3.33331 4.66536V4.66406H3.33462Z"
            stroke="#1F2023"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <ToggleOrderedListButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M6.66669 11.3307H13.3334M2.66669 10.4543V10.3307C2.66669 9.77844 3.1144 9.33073 3.66669 9.33073H3.69371C4.23107 9.33073 4.66683 9.76635 4.66683 10.3037C4.66683 10.5376 4.59082 10.7653 4.45047 10.9525L2.66669 13.3309L4.66669 13.3307M6.66669 7.9974H13.3334M6.66669 4.66406H13.3334M2.66669 3.33073L4.00002 2.66406V6.66406"
            stroke="#1F2023"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <ToggleTaskListButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M7.33335 11.3359H13.3334M5.33335 10.0026L3.66669 12.0026L2.66669 11.3359M7.33335 8.0026H13.3334M5.33335 6.66927L3.66669 8.66927L2.66669 8.0026M7.33335 4.66927H13.3334M5.33335 3.33594L3.66669 5.33594L2.66669 4.66927"
            stroke="#1F2023"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <CreateTableButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M2.66669 9.9974V11.1975C2.66669 11.9443 2.66669 12.3174 2.81201 12.6027C2.93984 12.8535 3.14367 13.0577 3.39455 13.1855C3.67949 13.3307 4.05268 13.3307 4.79796 13.3307H8.00002M2.66669 9.9974V5.9974M2.66669 9.9974H8.00002M2.66669 5.9974V4.79753C2.66669 4.05079 2.66669 3.67714 2.81201 3.39193C2.93984 3.14104 3.14367 2.93722 3.39455 2.80939C3.67977 2.66406 4.05341 2.66406 4.80015 2.66406H8.00002M2.66669 5.9974H8.00002M8.00002 2.66406H11.2002C11.9469 2.66406 12.3201 2.66406 12.6053 2.80939C12.8562 2.93722 13.0603 3.14104 13.1882 3.39193C13.3334 3.67686 13.3334 4.05006 13.3334 4.79534V5.9974M8.00002 2.66406V5.9974M8.00002 5.9974V9.9974M8.00002 5.9974H13.3334M8.00002 9.9974V13.3307M8.00002 9.9974H13.3334M8.00002 13.3307H11.2024C11.9477 13.3307 12.3203 13.3307 12.6053 13.1855C12.8562 13.0577 13.0603 12.8535 13.1882 12.6027C13.3334 12.3177 13.3334 11.9451 13.3334 11.1998V9.9974M13.3334 9.9974V5.9974"
            stroke="#1F2023"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
    <InsertHorizontalRuleButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M2.66669 7.33594H13.3334" stroke="black" strokeWidth="1.33" strokeLinecap="round" />
        </svg>
      }
      onClose={onClose}
    />,
    <InsertCodeBlockButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M6.00002 11.3359L2.66669 8.0026L6.00002 4.66927" stroke="#1F2023" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.99998 4.66406L13.3333 7.9974L9.99998 11.3307" stroke="#1F2023" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      onClose={onClose}
    />,
    <InsertTrailingNodeButton
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M9.33331 7.9974V10.2641C9.33331 10.6374 9.33331 10.824 9.40598 10.9666C9.46989 11.092 9.5718 11.1942 9.69725 11.2581C9.83971 11.3307 10.0263 11.3307 10.3989 11.3307H11.601C11.9736 11.3307 12.16 11.3307 12.3024 11.2581C12.4279 11.1942 12.5301 11.092 12.5941 10.9665C12.6666 10.8241 12.6666 10.6377 12.6666 10.2651V9.06303C12.6666 8.69039 12.6666 8.5038 12.5941 8.36133C12.5301 8.23589 12.428 8.13397 12.3025 8.07006C12.1599 7.9974 11.9733 7.9974 11.6 7.9974H9.33331ZM9.33331 7.9974V6.66406C9.33331 5.55949 10.2287 4.66406 11.3333 4.66406M3.33331 7.9974V10.2641C3.33331 10.6374 3.33331 10.824 3.40598 10.9666C3.46989 11.092 3.5718 11.1942 3.69725 11.2581C3.83971 11.3307 4.02631 11.3307 4.39895 11.3307H5.60101C5.97365 11.3307 6.15998 11.3307 6.30244 11.2581C6.42789 11.1942 6.53014 11.092 6.59406 10.9665C6.66665 10.8241 6.66665 10.6377 6.66665 10.2651V9.06304C6.66665 8.6904 6.66665 8.5038 6.59406 8.36133C6.53014 8.23589 6.42795 8.13397 6.30251 8.07006C6.1599 7.9974 5.97335 7.9974 5.59998 7.9974H3.33331ZM3.33331 7.9974V6.66406C3.33331 5.55949 4.22874 4.66406 5.33331 4.66406"
            stroke="#1F2023"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      onClose={onClose}
    />,
  ];

  return (
    <ButtonContainer className="memo-menu-buttons" ref={ref} tabIndex={0}>
      <CommandButtonGroup sx={{ width: '200px' }}>
        {/* 각 버튼을 렌더링할 때 선택된 인덱스에 따라 배경색을 설정 */}
        {buttons.map((button, index) =>
          React.cloneElement(button, { key: index, hover: selectedIndex === index, onClose, onChangeUserAction, onMouseEnter: () => setSelectedIndex(index) }),
        )}
      </CommandButtonGroup>
    </ButtonContainer>
  );
};

export default MenuButtons;

const ButtonContainer = styled.div`
  padding: 6px;
  border-radius: 6px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);

  .MuiToolbar-root {
    padding: 0px 0px 0px 5px;
  }

  .MuiBox-root {
    display: flex;
    flex-direction: column;
  }

  .MuiPopper-root {
    flex-direction: column;
  }
`;
