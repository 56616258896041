import React, { FC, useCallback } from 'react';
import { BulletListExtension } from '@remirror/extension-list';
import { useActive, useCommands } from '@remirror/react-core';
import { CommandButton, CommandButtonProps } from './CommandButton';

export interface ToggleBulletListButtonProps extends Omit<CommandButtonProps, 'commandName' | 'active' | 'enabled' | 'attrs' | 'onSelect'> {
  onClose?: () => void;
}

export const ToggleBulletListButton: FC<ToggleBulletListButtonProps> = (props) => {
  const { toggleBulletList } = useCommands<BulletListExtension>();
  const onClose = props.onClose;

  const handleSelect = useCallback(() => {
    onClose && onClose();
    if (toggleBulletList.enabled()) {
      toggleBulletList();
    }
  }, [toggleBulletList]);

  const active = useActive<BulletListExtension>().bulletList();
  const enabled = toggleBulletList.enabled();

  return <CommandButton {...props} commandName="불릿 리스트" active={active} enabled={enabled} onSelect={handleSelect} />;
};
