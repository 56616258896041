import React, { memo, useCallback, useRef, useState } from 'react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import {
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  DropCursorExtension,
  HeadingExtension,
  HorizontalRuleExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  NodeFormattingExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TaskListExtension,
  TextColorExtension,
  TextHighlightExtension,
  TrailingNodeExtension,
  UnderlineExtension,
  wysiwygPreset,
} from 'remirror/extensions';
import { TableComponents, TableExtension } from '@remirror/extension-react-tables';
import { EditorComponent, OnChangeJSON, ReactComponentExtension, Remirror, ThemeProvider, useEditorEvent, useRemirror } from '@remirror/react';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';
import { languages } from '@codemirror/language-data';
import { oneDark } from '@codemirror/theme-one-dark';
import CustomFloatingToolbar from './FloatingToolbar';
import { InvalidContentHandler, RemirrorContentType } from 'remirror';
import Menubar from './Menubar';
import { debounce } from 'lodash';
import { DragDropExtension } from './Extension/DragDrop';
import styled from '@emotion/styled';
import { hideScroll } from 'styles/utils';
import { useClickOutside, useEventListener, useKeyboardEvent } from '@react-hookz/web';
import { setCaretToEnd } from 'utils';
import { Popover } from '@mui/material';
import { COLORS } from 'styles/constants';
import { Icons } from 'components';
import MenuButtons from './Menubar/MenuButtons';

interface RemirrorEditorProps {
  data?: RemirrorContentType;
  onChangeData?: (data: RemirrorContentType) => void;
}

export type MemoMenuType = typeof MemoMenuType[keyof typeof MemoMenuType];

export const MemoMenuType = {
  CONVERT: 'CONVERT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
} as const;

const RemirrorEditor = ({ data, onChangeData }: RemirrorEditorProps) => {
  const [slashMenuStatus, setSlashMenuStatus] = useState(false);
  const [controlToolbar, setControlToolbar] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);
  const linkExtension = new LinkExtension({});
  linkExtension.addHandler('onClick', (_, data) => {
    window.open(data.href, '_blank');
    return true;
  });

  const { manager, state, setState, onChange } = useRemirror({
    extensions: () => [
      new PlaceholderExtension({ placeholder: '메모를 작성해보세요.' }),
      new BoldExtension({}),
      new ItalicExtension(),
      new HeadingExtension({}),
      new UnderlineExtension(),
      new BulletListExtension({}),
      new TaskListExtension(),
      new OrderedListExtension(),
      new ReactComponentExtension({}),
      new TableExtension({}),
      new HorizontalRuleExtension({}),
      new ImageExtension({ enableResizing: true }),
      new DropCursorExtension({}),
      new NodeFormattingExtension({}),
      new TextHighlightExtension({}),
      new TextColorExtension({}),
      new CodeExtension(),
      new StrikeExtension(),
      new CodeMirrorExtension({ languages, extensions: [oneDark] }),
      new TrailingNodeExtension({}),
      new DragDropExtension(),
      linkExtension,
      ...wysiwygPreset(),
    ],
    selection: 'end',
    content: data,
    stringHandler: 'html',
    onError,
  });

  const handleChangeData = useCallback(
    debounce((json: any) => onChangeData?.(json), 300),
    [onChangeData],
  );

  useEventListener(
    //TODO : handle 찾는 부분은 개선해
    document.querySelector('.project-memo')
      ? document.querySelector('.project-memo')!.querySelector('.memo-menu-handle')
      : document.querySelectorAll('.memo-menu-handle').length > 1
      ? document.querySelectorAll('.memo-menu-handle')[1]
      : document.querySelectorAll('.memo-menu-handle')[0],
    'click',
    (e: any) => {
      const elements = document.elementsFromPoint(e.clientX + 50, e.clientY);
      const newElement = document.createElement('p');
      const lineBreak = document.createElement('br');
      lineBreak.className = 'ProseMirror-trailingBreak';

      let targetElement: Element | null = null;
      for (const elem of elements) {
        if (elem && elem.parentNode instanceof HTMLElement) {
          if (elem.parentNode.classList.contains('ProseMirror') || elem.parentNode.classList.contains('column')) {
            targetElement = elem;
            break;
          }
        }
      }

      const parentNode = targetElement!.parentNode;
      if (targetElement!.textContent === '') {
        setCaretToEnd(targetElement as HTMLElement);
      } else {
        parentNode!.insertBefore(newElement, targetElement!.nextSibling);
        setCaretToEnd(newElement);
      }

      setTimeout(() => {
        setSlashMenuStatus(true);
      }, 100);
    },
    { passive: true },
    [document.querySelector('.memo-menu-handle')],
  );

  useEventListener(
    //TODO : handle 찾는 부분은 개선해야함.
    document.querySelector('.project-memo')
      ? document.querySelector('.project-memo')!.querySelector('.memo-drag-handle')
      : document.querySelectorAll('.memo-drag-handle').length > 1
      ? document.querySelectorAll('.memo-drag-handle')[1]
      : document.querySelectorAll('.memo-drag-handle')[0],
    'click',
    (e: any) => {
      const target = e.currentTarget;
      const elements = document.elementsFromPoint(e.clientX + 50, e.clientY);
      let element: Element | null = null;

      for (const elem of elements) {
        if (elem && elem.parentNode instanceof HTMLElement) {
          if (elem.parentNode.classList.contains('ProseMirror') || elem.parentNode.classList.contains('column')) {
            element = elem;
            break;
          }
        }
      }

      if (element) {
        const focusElement = element as HTMLElement;
        setCaretToEnd(focusElement);
      }

      setTimeout(() => {
        setMenuAnchorEl(target);
      }, 50);
    },
    { passive: true },
  );

  useClickOutside(ref, (e: any) => {
    if (slashMenuStatus || controlToolbar || menuAnchorEl || subMenuAnchorEl) {
      const elements = document.elementsFromPoint(e.clientX, e.clientY);
      const containsTargetClass = elements.some((element) => {
        return element.classList.contains('base-Popper-root') || element.classList.contains('memo-menu-buttons');
      });

      if (!containsTargetClass) {
        setSlashMenuStatus(false);
        setControlToolbar(false);
        setMenuAnchorEl(null);
        setSubMenuAnchorEl(null);
      }
    }
  });

  useKeyboardEvent(
    true,
    (e) => {
      handleKeyDown(e);
    },
    [slashMenuStatus],
    { eventOptions: { passive: false }, target: document.querySelector('.ProseMirror') as HTMLElement },
  );

  const handleClickPopoverMenu = (type: MemoMenuType, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //TODO : handle 찾는 부분은 개선해야함.
    const dragElement =
      document.querySelectorAll('.memo-drag-handle').length > 1
        ? document.querySelectorAll('.memo-drag-handle')[1]
        : document.querySelectorAll('.memo-drag-handle')[0];
    const elements = document.elementsFromPoint(dragElement!.getBoundingClientRect().x + 50, dragElement!.getBoundingClientRect().y);

    let element: Element | null = null;

    for (const elem of elements) {
      if (elem && elem.parentNode instanceof HTMLElement) {
        if (elem.parentNode.classList.contains('ProseMirror') || elem.parentNode.classList.contains('column')) {
          element = elem;
          break;
        }
      }
    }

    switch (type) {
      case MemoMenuType.CONVERT:
        {
          if (e && e.currentTarget) setSubMenuAnchorEl(e.currentTarget);
        }
        break;
      case MemoMenuType.DUPLICATE:
        {
          if (element) {
            const newElement = element.cloneNode(true);
            element.parentNode!.insertBefore(newElement, element.nextSibling);
          }
          setMenuAnchorEl(null);
        }
        break;
      case MemoMenuType.DELETE:
        {
          if (element) {
            element.remove();
          }
          setMenuAnchorEl(null);
        }
        break;
      default:
        break;
    }
  };

  const handleCloseMenu = () => {
    setSlashMenuStatus(false);
    setSubMenuAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === '/') {
      setSlashMenuStatus(true);
    } else if (slashMenuStatus && (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Enter')) {
      e.preventDefault();
      setSlashMenuStatus(true);
    } else {
      setSlashMenuStatus(false);
    }
  };

  const handleMouseEnter = () => {
    const dragElement =
      document.querySelectorAll('.memo-drag-handle').length > 1
        ? document.querySelectorAll('.memo-drag-handle')[1]
        : (document.querySelector('.memo-drag-handle') as HTMLDivElement);
    const menuElement =
      document.querySelectorAll('.memo-menu-handle').length > 1
        ? document.querySelectorAll('.memo-menu-handle')[1]
        : (document.querySelector('.memo-menu-handle') as HTMLDivElement);
    if (dragElement) dragElement.classList.remove('hidden');
    if (menuElement) menuElement.classList.remove('hidden');
    setControlToolbar(true);
  };

  return (
    <Container className="memo-container" ref={ref} onMouseEnter={handleMouseEnter}>
      <AllStyledComponent style={{ height: '100%' }}>
        <ThemeProvider theme={{}}>
          <Remirror
            manager={manager}
            initialContent={data}
            hooks={[
              // () =>
              //   useEditorEvent(
              //     'keydown',
              //     useCallback(
              //       (e) => {
              //         handleKeyDown(e);
              //       },
              //       [slashMenuStatus],
              //     ),
              //   ), ===> 한글 문제로 주석 처리
              () =>
                useEditorEvent(
                  'mousedown',
                  useCallback(() => {
                    setSlashMenuStatus(false);
                  }, []),
                ),
            ]}
          >
            <EditorComponent />
            <TableComponents />
            {controlToolbar && <CustomFloatingToolbar />}
            <Menubar open={slashMenuStatus} positioner={'cursor'} onClose={handleCloseMenu} />
            <OnChangeJSON onChange={handleChangeData} />
            {menuAnchorEl && (
              <Popover
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => setMenuAnchorEl(null)}
                sx={{ zIndex: 9999 }}
              >
                <PopoverContainer>
                  <PopoverMenu onClick={(e) => handleClickPopoverMenu('CONVERT', e)}>
                    <Icons.ConvertMenu />
                    <span style={{ marginLeft: 8 }}>전환</span>
                  </PopoverMenu>
                  <PopoverMenu onClick={() => handleClickPopoverMenu('DUPLICATE')}>
                    <Icons.Duplicate />
                    <span style={{ marginLeft: 8 }}>복제</span>
                  </PopoverMenu>
                  <PopoverMenu onClick={() => handleClickPopoverMenu('DELETE')}>
                    <Icons.Delete fill={COLORS.negative1} />
                    <span style={{ marginLeft: 8, color: COLORS.negative1 }}>삭제</span>
                  </PopoverMenu>
                </PopoverContainer>
              </Popover>
            )}
            {subMenuAnchorEl && (
              <Popover
                open={Boolean(subMenuAnchorEl)}
                anchorEl={subMenuAnchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                onClose={() => setSubMenuAnchorEl(null)}
                sx={{
                  left: '8px',
                  zIndex: 9999,
                }}
              >
                <MenuButtons onClose={handleCloseMenu} />
              </Popover>
            )}
          </Remirror>
        </ThemeProvider>
      </AllStyledComponent>
    </Container>
  );
};

export default memo(RemirrorEditor);

const Container = styled.div`
  height: 100%;

  .ProseMirror {
    padding: 0px 16px !important;

    > * + * {
      margin-top: 0.5em;
    }

    p {
      padding-left: 4px;
      margin-left: 10px !important;
      font-size: 14px;
    }

    p.is-editor-empty:first-of-type::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    a {
      color: #0039a7;
      text-decoration: underline;
      cursor: pointer;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    h3 {
      font-size: 1.17rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    h4 {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    h5 {
      font-size: 0.83rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    h6 {
      font-size: 0.67rem;
      font-weight: bold;
      line-height: 1.1;
      margin-left: 10px !important;
      padding-left: 4px;
    }

    ul {
      color: black;
      list-style-type: disc;
      padding-left: 22px;
      margin-left: 10px !important;

      p {
        padding-left: 0;
        margin-left: 0 !important;
      }
    }

    ol {
      list-style-type: decimal;
      padding-left: 1.6rem;
      padding-left: 22px;
      margin-left: 10px !important;

      p {
        padding-left: 0;
        margin-left: 0 !important;
      }
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin-left: 12px !important;

      p {
        padding-left: 0;
        margin-left: 0 !important;
      }
    }

    hr {
      border: none;
      border-top: 2px solid rgba(13, 13, 13, 0.1);
      margin: 2rem 0;
    }

    :focus {
      outline: none;
    }

    table {
      p {
        margin-left: 0 !important;
        padding-left: 0;
      }
    }

    code {
      background: rgba(250, 239, 240, 0.78);
      color: #b44437;
      padding: 3px 4px;
      border-radius: 5px;
    }
  }

  .remirror-theme {
    height: 100%;
  }

  .remirror-editor-wrapper {
    height: 100%;
    padding-top: 0px !important;
  }

  .remirror-editor.ProseMirror {
    height: 100%;
    min-height: 64px;
    box-shadow: none !important;
    ${hideScroll}
  }

  .remirror-editor .ProseMirror-selectednode {
    background-color: rgba(45, 170, 219, 0.3);
    border-radius: 4px;
    outline: none !important;
  }

  .remirror-editor li.ProseMirror-selectednode {
    background-color: transparent;
  }

  .remirror-editor.dragging {
    .ProseMirror-selectednode {
      background-color: transparent;
    }
    li.ProseMirror-selectednode {
      background-color: transparent;
    }
  }

  .remirror-editor li.ProseMirror-selectednode::after {
    left: -22px !important;
    border-radius: 4px;
  }

  .remirror-list-item-marker-container {
    position: absolute;
    left: -24px !important;
    top: 1px;
    display: inline-block;
    text-align: center;
    user-select: none;
  }

  .remirror-button {
    display: none;
  }
`;

const PopoverContainer = styled.div`
  padding: 8px;
`;

const PopoverMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;
