import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0039A7',
      light: '#E2ECFF',
      dark: '#2D6ADF',
    },
    secondary: {
      main: '#2D6ADF',
    },
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250, // most basic recommended timing
      standard: 300, // this is to be used in complex animations
      complex: 375, // recommended when something is entering screen
      enteringScreen: 225, // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  typography: {
    fontFamily: [
      'Pretendard Variable',
      'Pretendard',
      '-apple-system',
      'BlinkMacSystemFont',
      'system-ui',
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          borderRadius: 2,
        },
      },
      styleOverrides: {
        outlinedInherit: {
          border: '1px solid #E7EAF4',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          borderRadius: 2,
          // height: 48,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        sx: {
          backgroundColor: '#E2ECFF',
          height: 26,
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        disableTypography: true,
        sx: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    // MuiPaper: {
    //   defaultProps: {
    //     sx: {},
    //   },
    // },
    MuiPopover: {
      styleOverrides: {
        paper: {
          // transitionDuration: '0s !important',
          border: '1px solid #E7EAF4',
          boxShadow: '0px 8px 16px rgba(26, 30, 39, 0.16)',
          borderRadius: 8,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          borderRadius: 2,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px #fff inset',
            WebkitTextFillColor: 'var(--text-primary)',
          },
        },
      },
    },
    MuiModal: {
      defaultProps: {
        sx: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
        textColorPrimary: '#fff',
      },
      defaultProps: {
        sx: {
          fontSize: 16,
          minHeight: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#1F2023',
        },
      },
      defaultProps: {
        textColor: 'inherit',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#ABB0BF" />
          </svg>
        ),
        checkedIcon: (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="4" fill="#0039A7" />
            <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        ),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          'backgroundColor': '#1F2023',
          '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementTop': {
              marginBottom: '4px',
            },
            '&.MuiTooltip-tooltipPlacementBottom': {
              marginTop: '4px',
            },
            '&.MuiTooltip-tooltipPlacementRight': {
              marginLeft: '4px',
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
              marginRight: '4px',
            },
          },
        },
      },
    },
  },
});

export default theme;
