import { SVGProps } from 'react';

export const LinkToProject = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M9.92575 12.2906L12.2965 9.92003M9.03689 10.8089L8.44421 11.4015C7.78956 12.0562 7.78937 13.1176 8.44402 13.7722C9.09868 14.4268 10.1604 14.4266 10.8151 13.772L11.4071 13.1794M10.8147 9.03081L11.4074 8.43817C12.062 7.78355 13.1233 7.78367 13.7779 8.43829C14.4326 9.0929 14.4326 10.1543 13.7779 10.8089L13.1855 11.4015"
        stroke="#1F2023"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 1C3.91015 1 1 3.91015 1 7.5C1 10.9185 3.63895 13.7206 6.99081 13.9803C6.683 13.5663 6.4377 13.1031 6.26889 12.6046C3.96349 12.0506 2.25026 9.97537 2.25026 7.5C2.25026 4.60065 4.60065 2.25026 7.5 2.25026C9.97231 2.25026 12.0455 3.95926 12.6025 6.26036C13.1014 6.42834 13.565 6.67286 13.9795 6.97997C13.7146 3.63326 10.9148 1 7.5 1Z"
        fill="#1F2023"
      />
      <path d="M7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z" fill="#1F2023" />
    </svg>
  );
};
