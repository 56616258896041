import styled from '@emotion/styled';
import { Divider, IconButton, Popover, TextField } from '@mui/material';
import { projectsAtom } from 'atoms/projects';
import { Icons } from 'components';
import { categoryColors } from 'constants/categoryColor';
import { useAtom } from 'jotai';
import { OutCategory, OutProject } from 'queries/model';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';

export const CategoryAction = {
  CREATE: 'CREATE',
  SELECT: 'SELECT',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  DETACH: 'DETACH',
} as const;

export type CategoryActionType = typeof CategoryAction[keyof typeof CategoryAction];

interface ProjectListPopoverProps {
  onClick?: (project: OutProject) => void;
}

const ProjectListPopover = ({ onClick }: ProjectListPopoverProps) => {
  const [projects, setProjects] = useAtom(projectsAtom);
  const [drawerProjectList, setDrawerProjectList] = useState<OutProject[]>(projects.filter((p) => p.place === 'DESK'));
  const [filteredProjectList, setFilteredProjectListList] = useState<OutProject[]>([]);
  const [projectName, setProjectName] = useState<string>('');
  const [hoverId, setHoverId] = useState<string | null>(null);

  useEffect(() => {
    setDrawerProjectList(projects.filter((p) => p.place === 'DESK'));
  }, [projects]);

  const handleFilterProjectList = (value: string) => {
    if (value === '') {
      setFilteredProjectListList([]);
      setProjectName('');
      return;
    }

    setProjectName(value);
    const filtered = drawerProjectList.filter((p) => p.title.includes(value));
    setFilteredProjectListList(filtered);
  };

  const handleKeyDownTextField = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredProjectList.length > 0 ? setHoverId(filteredProjectList[0].id) : setHoverId(drawerProjectList[0].id);
      const el =
        filteredProjectList.length > 0
          ? (document.querySelector(`[data-project-id="${filteredProjectList[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-project-id="${drawerProjectList[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const active = document.activeElement as HTMLElement;
      active.blur();
      filteredProjectList.length > 0
        ? setHoverId(filteredProjectList[filteredProjectList.length - 1].id)
        : setHoverId(drawerProjectList[drawerProjectList.length - 1].id);
      const el =
        filteredProjectList.length > 0
          ? (document.querySelector(`[data-project-id="${filteredProjectList[0].id}"]`) as HTMLElement)
          : (document.querySelector(`[data-project-id="${drawerProjectList[0].id}"]`) as HTMLElement);
      el.focus();
      return;
    }
  };

  const handleKeyDownProjectList = (e: React.KeyboardEvent, project: OutProject) => {
    if (e.key === 'Enter') {
      onClick && onClick(project);
    }

    if (e.key === 'ArrowDown') {
      const index =
        filteredProjectList.length > 0 ? filteredProjectList.findIndex((c) => c.id === hoverId) : drawerProjectList.findIndex((c) => c.id === hoverId);
      if (index === filteredProjectList.length - 1 || index === drawerProjectList.length - 1) return;

      const nextProject = filteredProjectList.length > 0 ? filteredProjectList[index + 1] : drawerProjectList[index + 1];
      setHoverId(nextProject.id);
      const el = document.querySelector(`[data-project-id="${nextProject.id}"]`) as HTMLElement;
      el.focus();
      return;
    }

    if (e.key === 'ArrowUp') {
      const index =
        filteredProjectList.length > 0 ? filteredProjectList.findIndex((c) => c.id === hoverId) : drawerProjectList.findIndex((c) => c.id === hoverId);
      if (index === 0) return;

      const nextProject = filteredProjectList.length > 0 ? filteredProjectList[index - 1] : drawerProjectList[index - 1];
      setHoverId(nextProject.id);
      const el = document.querySelector(`[data-project-id="${nextProject.id}"]`) as HTMLElement;
      el.focus();
      return;
    }
  };

  const handleClickProject = (project: OutProject) => {
    onClick && onClick(project);
  };

  return (
    <Container className="project-popover">
      <TextFieldWrapper>
        <TextField
          autoFocus
          autoComplete="off"
          fullWidth
          placeholder="프로젝트를 검색하거나 선택해주세요."
          value={projectName}
          inputProps={{
            style: {
              padding: '0px',
              fontSize: '12px',
            },
          }}
          onChange={(e) => handleFilterProjectList(e.target.value)}
          onKeyDown={(e) => handleKeyDownTextField(e)}
        />
      </TextFieldWrapper>
      <Divider />
      <div style={{ padding: 8 }}>
        {filteredProjectList.length > 0
          ? filteredProjectList.map((v) => (
              <ProjectShowingWrapper
                key={v.id}
                data-project-id={v.id}
                tabIndex={0}
                isHover={hoverId === v.id}
                onClick={() => handleClickProject(v)}
                onKeyDown={(e) => handleKeyDownProjectList(e, v)}
              >
                <Icons.Issue />
                <div style={{ marginLeft: 8, fontSize: 12 }}>{v.title}</div>
              </ProjectShowingWrapper>
            ))
          : drawerProjectList.map((v) => (
              <ProjectShowingWrapper
                key={v.id}
                data-project-id={v.id}
                tabIndex={0}
                isHover={hoverId === v.id}
                onClick={() => handleClickProject(v)}
                onKeyDown={(e) => handleKeyDownProjectList(e, v)}
              >
                <Icons.Issue />
                <div style={{ marginLeft: 8, fontSize: 12 }}>{v.title}</div>
              </ProjectShowingWrapper>
            ))}
      </div>
      {/* {category.length === 0 && newCategoryName === '' && (
        <>
          <Divider />
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '10px', padding: '12px 16px' }}>
            <Icons.ExclamationInfo width={12} height={12} />
            <span style={{ marginLeft: '4px' }}>최대 8개까지 생성해서 관리할 수 있어요</span>
          </div>
        </>
      )}
      {filteredCategory.length === 0 && newCategoryName !== '' && (
        <>
          <Divider />
          <div style={{ padding: '8px' }}>
            <CreateNewCategoryWrapper onClick={handleClickCreateNewCategory}>
              <Icons.Plus width={12} height={12} fill={COLORS.gray500} />
              <span style={{ fontSize: '12px', color: COLORS.gray500, margin: '0px 4px' }}>새 카테고리 추가:</span>
              <div
                style={{ backgroundColor: COLORS.gray200, borderRadius: '4px', color: COLORS.gray600, fontSize: '10px', padding: '2px 6px' }}
              >{`# ${newCategoryName}`}</div>
            </CreateNewCategoryWrapper>
          </div>
        </>
      )}
      {(filteredCategory.length > 0 || category.length > 0) && (
        <>
          <Divider />
          <div style={{ padding: '8px' }}>
            {filteredCategory.length > 0
              ? filteredCategory.map((v) => (
                  <CategoryShowingWrapper
                    key={v.id}
                    data-category-id={v.id}
                    tabIndex={0}
                    isHover={hoverId === v.id}
                    onClick={() => onClickCategoryAction && onClickCategoryAction(v, 'SELECT')}
                    onKeyDown={(e) => handleKeyDownCategory(e, v)}
                  >
                    <CategoryShowingName
                      textColor={categoryColors.find((c) => c.color === v.color)?.textColor}
                      bgColor={categoryColors.find((c) => c.color === v.color)?.bgColor}
                    >
                      {`# ${v.name}`}
                    </CategoryShowingName>
                    <IconButtonWrapper>
                      <IconButton
                        onClick={(e) => handleClickUpdateButton(e, v)}
                        sx={{
                          borderRadius: '6px',
                          padding: '4px',
                        }}
                      >
                        <Icons.Edit width={16} height={16} stroke={COLORS.gray300} />
                      </IconButton>
                      <div className="delete-btn">
                        <IconButton className="delete" onClick={(e) => handleClickDeleteButton(e, v)} sx={{ borderRadius: '6px', padding: '4px' }}>
                          <Icons.Delete width={16} height={16} fill={COLORS.gray300} />
                        </IconButton>
                      </div>
                    </IconButtonWrapper>
                  </CategoryShowingWrapper>
                ))
              : category.map((v) => (
                  <CategoryShowingWrapper
                    key={v.id}
                    tabIndex={0}
                    data-category-id={v.id}
                    isHover={hoverId === v.id}
                    onClick={() => onClickCategoryAction && onClickCategoryAction(v, 'SELECT')}
                    onKeyDown={(e) => handleKeyDownCategory(e, v)}
                  >
                    <CategoryShowingName
                      textColor={categoryColors.find((c) => c.color === v.color)?.textColor}
                      bgColor={categoryColors.find((c) => c.color === v.color)?.bgColor}
                    >
                      {`# ${v.name}`}
                    </CategoryShowingName>
                    <IconButtonWrapper>
                      <IconButton
                        onClick={(e) => handleClickUpdateButton(e, v)}
                        sx={{
                          borderRadius: '6px',
                          padding: '4px',
                        }}
                      >
                        <Icons.Edit width={16} height={16} stroke={COLORS.gray300} />
                      </IconButton>
                      <div className="delete-btn">
                        <IconButton
                          className="delete"
                          onClick={(e) => handleClickDeleteButton(e, v)}
                          sx={{
                            borderRadius: '6px',
                            padding: '4px',
                          }}
                        >
                          <Icons.Delete width={16} height={16} fill={COLORS.gray300} />
                        </IconButton>
                      </div>
                    </IconButtonWrapper>
                  </CategoryShowingWrapper>
                ))}
          </div>
        </>
      )} */}
    </Container>
  );
};

export default ProjectListPopover;

const Container = styled.div`
  min-width: 200px;
`;

const TextFieldWrapper = styled.div`
  height: 50px;
  padding: 16px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const ProjectShowingWrapper = styled.div<{ isHover?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
  }

  ${(props) =>
    props.isHover &&
    `
    background-color: ${COLORS.gray100};
  `}
`;

const CategoryShowingWrapper = styled.div<{ isHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
  }

  ${(props) =>
    props.isHover &&
    `
    background-color: ${COLORS.gray100};
  `}
`;

const CreateNewCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const CategoryShowingName = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  font-size: 10px;
  padding: 2px 6px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  .delete-btn {
    :hover {
      .delete {
        filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
      }
    }
  }
`;

const EditButton = styled.div`
  border-radius: 6px;
  padding: 4px;
  :hover {
    background-color: ${COLORS.gray200};
  }
`;

const UpdateCategoryPopoverWrapper = styled.div`
  padding: 8px;
`;
