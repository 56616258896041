import { RefObject, useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { IconButton, Tooltip } from '@mui/material';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';
import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import { useMountEffect, useRafCallback } from '@react-hookz/web';
import { osName } from 'react-device-detect';
import React from 'react';
import { OutCategory, OutTaskboxDetailResponseCategory } from 'queries/model';
import TodayCalendarEvent, { TodayCalendarEventProps } from 'pages/Task/Today/TodayCalendarEvent';
import { CategoryActionType } from 'pages/Task/components/CategoryPopover';

const CalendarViewWrapper = styled.div`
  height: 100%;
  background-color: #f2f5fc;
  position: relative;
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 128px);
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};
  margin-top: 6px;

  .rbc-events-container {
    /* margin-right: 14px; */
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-time-header {
    height: 62px;
    /* margin-bottom: 20px; */
    overflow: hidden;
    /* max-height: 300px; */
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    min-height: 62px;
    /* display: none; */
  }

  .rbc-time-header-gutter {
    width: 49.1406px;
    min-width: 49.1406px;
    max-width: 49.1406px;
  }

  .rbc-time-header.rbc-overflowing {
    margin-right: 8px !important;
  }

  .rbc-event.rbc-selected {
    /* background-color: ${COLORS.sub3}; */
    /* border: 1px solid ${COLORS.brand1}; */
    /* box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16); */
  }

  .rbc-event {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-row-content {
    z-index: 0;
  }
`;

const AllDayEventContainer = styled.div<{ height?: number }>`
  width: 100%;
  display: flex;
  position: relative;
`;

const ViewMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 24px;
  cursor: pointer;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
  isRecurrence?: boolean;
  isProject?: boolean;
  focus?: boolean;
  category?: OutTaskboxDetailResponseCategory;
  durationMin?: number | null;
} & Event;

export interface CalendarViewProps {
  refCalendarView?: RefObject<HTMLDivElement>;
  newEventId?: string | null;
  events?: CustomEvent[];
  selectedEventId?: string;
  currentDate: Date;
  onClickRefresh?: () => void;
  onClickToggleView?: () => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEvent?: ({
    eventId,
    startTime,
    endTime,
    isAllDay,
    title,
  }: {
    eventId: string;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
    title?: string;
  }) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onChangeEventEditing?: () => void;
  onClickTimeSlot?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDoubleClickEvent?: (eventId: string) => void;
  onContextMenuEvent?: (eventId: string) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onClickFoldCalendarView?: () => void;
  onClickCategoryActions?: (eventId: string, category: OutCategory | null, action: CategoryActionType) => void;
}

const CalendarView = React.forwardRef(
  (
    {
      refCalendarView,
      events = [],
      selectedEventId,
      currentDate,
      onClickRefresh,
      onSelectEvent,
      onUpdateEvent,
      onUpdateEventTitle,
      onChangeEventEditing,
      onClickTimeSlot,
      onDoubleClickEvent,
      onContextMenuEvent,
      onChangeCurrentDate,
      onDropFromOutside,
      onClickFoldCalendarView,
      onClickCategoryActions,
    }: CalendarViewProps,
    parentRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisibleViewMore, setIsVisibleViewMore] = useState(false);
    const [isHoverWidthLine, setIsHoverWidthLine] = useState(false);

    useMountEffect(() => {
      scrollToCurrentIndicator();
    });

    const formats = useMemo(
      () => ({
        timeGutterFormat: 'a hh시',
        eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
          `${localizer?.format(range.start, 'a h:mm', culture)}~${localizer?.format(range.end, 'a h:mm', culture)}`,
      }),
      [],
    );

    const draggableAccessor = useCallback((event: CustomEvent) => event.type === 'task', [events]);
    const resizableAccessor = useCallback((event: CustomEvent) => event.type === 'task' && !event.allDay, [events]);
    const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
    const eventPropGetter = useCallback(
      (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
        if (event.type === 'meeting') return {};

        // if (event.type === 'task' && event.id === newEventId) {
        //   return {
        //     style: {
        //       background: `rgba(0, 57, 167, 0.3)`,
        //     },
        //   };
        // }

        // 종일 taskbox를 특정 시간대로 이동시 스타일
        if (event.type === 'task' && event.allDay && isSelected === undefined) {
          return {
            style: { maxHeight: '28px' },
          };
        }

        if (isSelected && event.focus) {
          return {
            style: {
              border: '1px solid transparent',
              backgroundImage: 'linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%)',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
            },
          };
        }

        return {
          style: { border: `1px solid ${isSelected ? (event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1) : COLORS.gray200}` },
        };
      },
      [events],
    );

    const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
      onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
    });

    const [handleContextMenu] = useRafCallback((event: CustomEvent) => {
      onContextMenuEvent && onContextMenuEvent(event.id!);
    });

    const [updateCategory] = useRafCallback((event: CustomEvent, category: OutCategory | null, action: CategoryActionType) => {
      if (!event.id) return;
      onClickCategoryActions && onClickCategoryActions(event.id, category, action);
    });

    const scrollToCurrentIndicator = () => {
      setTimeout(() => {
        if (!ref || !ref.current) return;
        const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
        if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
      }, 30);
    };

    const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
      if (event.allDay && isAllDay) return; // 종일 > 종일로 이동 불가
      let endTime = event.allDay && !isAllDay ? dayjs(start).add(event.durationMin ? event.durationMin : 60, 'minute') : dayjs(end); // 종일 이벤트를 특정시간으로 이동시 1시간으로 조정

      // endTime이 15분 단위가 아닌 경우
      if (dayjs(endTime).get('minute') % 15 !== 0) {
        endTime = dayjs(endTime).set('minutes', Math.round(dayjs(endTime).get('minute') / 15) * 15);
      }

      onUpdateEvent &&
        onUpdateEvent({
          eventId: event.id!,
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(endTime).format(DATE_FORMAT_1),
          isAllDay: Boolean(isAllDay),
        });
    };

    const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
      onUpdateEvent &&
        onUpdateEvent({
          eventId: event.id!,
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(end).format(DATE_FORMAT_1),
          isAllDay: Boolean(isAllDay),
        });
    };

    const handleSelectEvent = (event: CustomEvent) => {
      if (event.type === 'meeting') return;
      onSelectEvent && onSelectEvent(event.id!);
    };

    const handleSelectSlot = (slot: SlotInfo) => {
      const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
      const endTime =
        slot.slots.length === 2
          ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
          : dayjs(slot.end).format(DATE_FORMAT_1);

      onClickTimeSlot && onClickTimeSlot({ startTime, endTime, isAllDay: slot?.slots.length === 1 ? true : false });
    };

    const handleDoubleClickEvent = (event: CustomEvent) => {
      onDoubleClickEvent && onDoubleClickEvent(event.id!);
    };

    const handleDropFromOutside = ({ start, end, allDay }: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
      onDropFromOutside &&
        onDropFromOutside({
          startTime: dayjs(start).format(DATE_FORMAT_1),
          endTime: dayjs(end).format(DATE_FORMAT_1),
          isAllDay: allDay,
        });
    };

    const handleClickViewMore = () => {
      const timeHeader = ref.current?.querySelector('.rbc-time-header') as HTMLDivElement;
      timeHeader.style.height = `${isVisibleViewMore ? 52 : 152}px`;
      setIsVisibleViewMore(!isVisibleViewMore);
    };

    const handleEventInput = (event: CustomEvent, title: string) => {
      updateEventTitle(event, title);
    };

    const handleEventCategory = (event: CustomEvent, category: OutCategory | null, action: CategoryActionType) => {
      updateCategory(event, category, action);
    };

    const EventComponentWrapper = useCallback((props: TodayCalendarEventProps) => {
      return (
        <TodayCalendarEvent
          {...props}
          onInput={(event, value) => handleEventInput(event, value)}
          onChangeCategory={(event, category, action) => handleEventCategory(event, category, action)}
          onChangeEditing={onChangeEventEditing}
          onContextMenu={handleContextMenu}
        />
      );
    }, []);

    return (
      <CalendarViewWrapper className="ritual-calendar" ref={refCalendarView}>
        <CalendarViewDaySchedulerWrapper>
          <AllDayEventContainer>
            <ViewMoreButtonWrapper style={{ top: isVisibleViewMore ? 146 : 52 }} onClick={handleClickViewMore}>
              <IconButton size="small" style={{ padding: 2, marginRight: 8, border: `1px solid ${COLORS.gray200}`, background: COLORS.gray200 }}>
                {isVisibleViewMore ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
              </IconButton>
            </ViewMoreButtonWrapper>
          </AllDayEventContainer>
          <CalendarContainer ref={ref}>
            <DnDCalendar
              style={{ height: '100%' }}
              date={currentDate}
              selected={events.find((item) => item.id === selectedEventId)}
              formats={formats}
              defaultView="day"
              toolbar={false}
              timeslots={4}
              step={15}
              // max={dayjs(currentDate).endOf('day').toDate()}
              events={events.filter((v) => v.type === 'task' || v.allDay)}
              backgroundEvents={events.filter((v) => v.type === 'meeting' && !v.allDay)}
              dayLayoutAlgorithm={'overlap'}
              selectable={true}
              draggableAccessor={draggableAccessor}
              resizableAccessor={resizableAccessor}
              allDayAccessor={allDayAccessor}
              components={{ event: EventComponentWrapper }}
              eventPropGetter={eventPropGetter}
              onDropFromOutside={handleDropFromOutside}
              onEventDrop={handleEventDrop}
              onEventResize={handleEventResize}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              onDoubleClickEvent={handleDoubleClickEvent}
              onNavigate={() => ({})}
              showAllEvents={true}
              showMultiDayTimes={true}
            />
          </CalendarContainer>
        </CalendarViewDaySchedulerWrapper>
        <div style={{ position: 'absolute', bottom: 60, right: 16 }}>
          <IconButton
            aria-label="sync"
            sx={{ boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
            style={{ background: 'white', zIndex: 30 }}
            onClick={onClickRefresh}
          >
            <Icons.Reload width={28} height={28} strokeWidth={1} />
          </IconButton>
        </div>
        <div onMouseOver={() => setIsHoverWidthLine(true)} onMouseLeave={() => setIsHoverWidthLine(false)} style={{ width: '15px' }}>
          {isHoverWidthLine && (
            <Tooltip
              title={
                <div style={{ padding: '5px 8px' }}>
                  <span>왼쪽 탭 접기/펼치기</span>
                  <KeyboardButtonRect style={{ marginLeft: 8 }}> {osName === 'Windows' ? 'Ctrl' : '⌘'}</KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect>{osName === 'Windows' ? 'Alt' : 'Option'}</KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect>{`[`}</KeyboardButtonRect>
                </div>
              }
              placement="right"
              disableInteractive
              sx={{ padding: '0px' }}
            >
              <IconButton
                onClick={() => onClickFoldCalendarView?.()}
                sx={{
                  'width': '32px',
                  'height': '32px',
                  'position': 'absolute',
                  'right': -18,
                  'top': 114,
                  'zIndex': 10000,
                  'backgroundColor': COLORS.white,
                  'borderRadius': '8px',
                  'border': `1px solid ${COLORS.gray300}`,
                  'boxShadow': `0px 8px 16px 0px rgba(26, 30, 39, 0.16)`,
                  'padding': '4px',
                  ':hover': {
                    backgroundColor: COLORS.sub3,
                  },
                }}
              >
                <Icons.ArrowLeftSmall />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </CalendarViewWrapper>
    );
  },
);

export default CalendarView;
