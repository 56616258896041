import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';

export interface PriorityIssueCheckboxProps extends CheckboxProps {
  bordercolor?: string;
  width?: number;
  height?: number;
  focus?: boolean;
}

const PriorityIssueCheckbox = ({ bordercolor, width, height, focus = false, ...props }: PriorityIssueCheckboxProps) => {
  const UncheckedIcon = focus ? (
    <Icons.FocusUncheck width={width} height={height} />
  ) : (
    <Icons.PriorityIssueUncheck stroke={bordercolor} width={width} height={height} />
  );
  const CheckedIcon = focus ? (
    <Icons.FocusCheck width={width} height={height} />
  ) : (
    <Icons.PriorityIssueCheck stroke={bordercolor} fill={bordercolor} width={width} height={height} />
  );
  return <Checkbox {...props} icon={UncheckedIcon} checkedIcon={CheckedIcon} sx={{ width, height, padding: 0, margin: 0, ...props.sx }} />;
};
export default PriorityIssueCheckbox;
