import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { hideScroll } from 'styles/utils';
import { getANoteV1NoteGet, updateNoteV1NotePatch } from 'queries';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType } from 'remirror';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MemoWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  ${hideScroll()};
`;

export interface MemoProps {
  active?: boolean;
  showFilter?: boolean;
}

const Memo = ({ active }: MemoProps) => {
  const [data, setData] = useState<RemirrorContentType>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (active) fetchMemo();
  }, [active]);

  const fetchMemo = async () => {
    const { content } = await getANoteV1NoteGet();
    if (content) setData(content as any);
    setIsLoaded(true);
  };

  const saveData = async (data: any) => {
    await updateNoteV1NotePatch({ content: data });
  };

  const handleChangeData = (data: any) => {
    if (!data) return;
    setData(data);
    saveData(data);
  };

  return (
    <Container className="side-panel-memo">
      <MemoWrapper>{isLoaded && <RemirrorEditor data={data} onChangeData={handleChangeData} />}</MemoWrapper>
    </Container>
  );
};

export default Memo;
