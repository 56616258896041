import styled from '@emotion/styled';
import {
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  createCategoryV1CategoryPost,
  createTaskboxV1TaskboxesPost,
  createWorkboxV2WorkboxesPost,
  createWorkInWorkboxV1WorkboxesWorkboxIdWorksPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  detachWorksInWorkboxV1WorkboxesWorkboxIdDetachPut,
  mergeWorkOrWorkBoxesV2V2WorksMergePost,
  readTaskboxV1TaskboxesTaskboxIdGet,
  removeTaskV1WorksWorkIdDelete,
  removeWorkboxV1WorkboxesWorkboxIdDelete,
  removeWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDelete,
  reorderWorkboxV2WorkboxesWorkboxIdReorderPut,
  splitWorkboxV1WorkboxesWorkboxIdSplitPost,
  subtaskToTaskV1TaskboxesSubtasksTaskTypePatch,
  updateBulkTaskboxesV1TaskboxesBulkPut,
  updateCategoryV1CategoryCategoryIdPatch,
  updateStateStorageV1StateStoragePatch,
  updateTaskboxV1TaskboxesTaskboxIdPut,
  updateWorkboxesV2V2WorkboxesWorkboxIdPut,
  updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut,
  updateWorkV2V2WorksWorkIdPut,
} from 'queries';
import { CreateTaskboxTask, OutCategory, OutTaskboxDetailResponse, UpdateTaskbox, UpdateTaskboxTask, WorkSectionType } from 'queries/model';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import TaskListView from 'components/Task/TaskListView2';
import { useAtom } from 'jotai';
import { tasksAtom } from 'atoms/tasks';
import { quickTaskPopupVisibleAtom } from 'atoms/popup';
import { COLORS } from 'styles/constants';
import { Autocomplete, Checkbox, Chip, Divider, FormControlLabel, FormGroup, IconButton, Popover, TextField, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { dragContextAtom } from 'atoms/works';
import { Task } from 'components/Task/TaskItem2';
import { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import dayjs from 'lib/dayjs';
import { dragContextAtom as calendarDragContextAtom } from 'atoms/calendar';
import { useEventListener } from '@react-hookz/web';
import { truncate } from 'styles/utils';
import { categoryAtom } from 'atoms/category';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { userStateAtom } from 'atoms/userState';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';
import { get } from 'lodash';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: calc(100% - 72px);
`;

const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EmptyTaskWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(180deg, #e7eaf4 0%, #f2f5fc 100%);
  padding: 20px 16px;
`;

const TaskListTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const TaskListTitleCount = styled.h3`
  font-weight: bold;
  color: ${COLORS.gray400};
`;

const TaskListHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 16px 20px 0px 20px;
`;

const TaskListShowingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TaskListFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const DropWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 32px 0px 32px;
`;

const TaskDropZone = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.sub5};
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TaskDropZoneTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray700};
  margin: 8px 0px 4px 0px;
`;

const TaskDropZoneDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${COLORS.gray500};
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: default;
  font-size: 10px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const EventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  width: 160px;
  height: 48px;
  padding: 6px 8px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  background-color: white;
  border: 1px solid #c8cddb;
  cursor: pointer;
`;

const EventTitle = styled.div<{ done?: boolean; color?: string }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => (props.done ? COLORS.gray400 : COLORS.gray900)};
  display: flex;
  align-items: center;
  ${truncate('100%')};
`;

const VerticalBar = styled.div<{ done?: boolean; isRecurrence?: boolean }>`
  border-right: ${(props) => `2px solid ${props.isRecurrence ? COLORS.sub4 : COLORS.brand1}`};
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const VerticalBarFocus = styled.div<{ done?: boolean }>`
  border: 1px solid transparent;
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  -webkit-border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image-slice: 1;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const CategoryFilterWrapper = styled.div`
  width: 248px;
`;

const CategorySelectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

const CategoryFilterClickWrapper = styled.div``;

export interface BacklogProps {
  active?: boolean;
  showFilter?: boolean;
}

const Backlog = ({ active }: BacklogProps) => {
  const [tasks, fetchTasks] = useAtom(tasksAtom);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [, setQuickTaskPopupVisible] = useAtom(quickTaskPopupVisibleAtom);
  const [taskDragContext] = useAtom(dragContextAtom);
  const [calendarDragContext] = useAtom(calendarDragContextAtom);
  const [userState, setUserState] = useAtom(userStateAtom);
  const [dragEnter, setDragEnter] = useState(false);
  const [draggingTask, setDraggingTask] = useState({ title: '', x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const [deadlineFilter, setDeadlineFilter] = useState(userState.data.isFilteringByDue);
  const [categoryFilter, setCategoryFilter] = useState<string[]>(userState.data.filteringCategoryIds || []);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (active) {
      fetchTasks();
      fetchCategoryList();
    }
  }, [active]);

  useEffect(() => {
    if (userState.data.isFilteringByDue) setDeadlineFilter(userState.data.isFilteringByDue);
  }, [userState.data.isFilteringByDue]);

  useEffect(() => {
    if (userState.data.filteringCategoryIds) setCategoryFilter(userState.data.filteringCategoryIds);
  }, [userState.data.filteringCategoryIds]);

  useEventListener(refContainer, 'mousemove', (e: any) => {
    if (!calendarDragContext) return;

    const { title } = calendarDragContext;
    setDraggingTask({ title: title || '', x: e.clientX, y: e.clientY });
  });

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    const subTask = tasks?.flatMap((task) => task?.tasks).find((task) => task?.id === id);
    const isSubTask = !!subTask;
    const taskboxId = isSubTask ? tasks?.find((task) => task?.tasks?.find((subTask) => subTask?.id === id))?.id : id;

    try {
      switch (menu) {
        case 'COMPLETED_AND_MOVE_TO_TODAY':
          if (isSubTask) {
            await updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut(taskboxId!, id, { id, content: subTask.content!, done: true, beforeId: null });
          } else {
            await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, {
              start: { date: dayjs().format('YYYY-MM-DD') },
              end: { date: dayjs().add(1, 'day').format('YYYY-MM-DD') },
              done: true,
              beforeId: null,
              tasks: data.map((item: any) => ({ ...item, done: true })),
            });
          }
          toast.success('완료하고 오늘로 이동하였습니다.');
          break;
        case 'START_NOW':
          {
            await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, {
              start: { datetime: dayjs().format(DATE_FORMAT_1) },
              end: { datetime: dayjs().add(1, 'hour').format(DATE_FORMAT_1) },
              done: false,
              beforeId: null,
              tasks: data,
            });
            toast.success('시작하고 오늘로 이동하였습니다.');
          }
          break;
        case 'MOVE_TO_AFTER':
          // if (isSubTask) {
          //   await updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut(taskboxId!, id, { workSectionType: 'someday' });
          // } else {
          //   if (type === 'SOMEDAY_TASKBOX') await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, { workSectionType: 'someday' });
          //   else if (type === 'SOMEDAY_TASK') await updateWorkV2V2WorksWorkIdPut(id, { workSectionType: 'someday' });
          // }
          if (type === 'SOMEDAY_TASKBOX') await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, { workSectionType: 'someday' });
          else if (type === 'SOMEDAY_TASK') await updateWorkV2V2WorksWorkIdPut(id, { workSectionType: 'someday' });
          toast.success('나중에 목록으로 이동하였습니다.');
          break;
        case 'MOVE_TO_TOP':
          await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, { beforeId: null });
          toast.success('태스크를 맨 위로 이동하였습니다.');
          break;
        case 'MOVE_TO_BOTTOM':
          await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, { beforeId: filteredTasks[filteredTasks.length - 1].id });
          toast.success('태스크를 맨 아래로 이동하였습니다.');
          break;
        case 'DELETE':
          if (isSubTask) {
            await removeWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDelete(taskboxId!, id);
          } else {
            if (type === 'task') await removeWorkboxV1WorkboxesWorkboxIdDelete(id);
            else if (type === 'SOMEDAY_TASKBOX') await removeWorkboxV1WorkboxesWorkboxIdDelete(id);
            else if (type === 'SOMEDAY_TASK') await removeTaskV1WorksWorkIdDelete(id);
            else if (type === 'merge') {
              await Promise.all(
                data.map(async (item: any) => {
                  if (item.type === 'SOMEDAY_TASKBOX') await removeWorkboxV1WorkboxesWorkboxIdDelete(item.id);
                  else if (item.type === 'SOMEDAY_TASK') await removeTaskV1WorksWorkIdDelete(item.id);
                }),
              );
            }
          }
          toast.success('태스크를 삭제하였습니다.');
          break;
        case 'MERGE_TASK_GROUP':
          if (!Array.isArray(data)) return;
          await mergeWorkOrWorkBoxesV2V2WorksMergePost({
            taskIds: data.filter((item) => item.type === 'SOMEDAY_TASK').map((item) => item.id),
            taskboxIds: data.filter((item) => item.type === 'SOMEDAY_TASKBOX').map((item) => item.id),
          });
          toast.success('선택하신 테스크 그룹으로 테스크가 합쳐졌습니다.');
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
    fetchTasks();
  };

  const handleCreateTask = async (value: string) => {
    if (!value) return;

    await createWorkboxV2WorkboxesPost({ id: uuidv4(), title: value });
    toast.success('새로운 태스크를 생성하였습니다.');
    fetchTasks();
  };

  const handleChangeTask = async (
    id: string,
    type: string,
    params: {
      content?: string;
      done?: boolean;
      workSectionType?: WorkSectionType;
      dueDate?: string;
    },
  ) => {
    const subTask = tasks?.flatMap((task) => task?.tasks).find((task) => task?.id === id);
    if (!subTask) return;
    if (params.content === subTask?.content) return;
    await updateWorkV2V2WorksWorkIdPut(id, { content: params.content || '' });
    toast.success('태스크를 수정하였습니다.');
    fetchTasks();
  };

  const handleChangeTitle = async (id: string, type: string, title: string) => {
    const task = tasks?.find((task) => task?.id === id);
    if (!task) return;
    if (title === task?.title) return;
    await updateWorkboxesV2V2WorkboxesWorkboxIdPut(id, { title: title || '' });
    toast.success('태스크를 수정하였습니다.');
    fetchTasks();
  };

  const handleClickCreate = () => {
    setQuickTaskPopupVisible(true);
  };

  const handleDrop = async () => {
    if (!taskDragContext) return;
    const taskboxList = taskDragContext.data!;
    const type = taskDragContext.type;
    const dropItem =
      type === 'multi-taskbox'
        ? taskboxList!.map((id: string) => {
            return { id, workSectionType: 'someday' };
          })
        : [{ id: taskDragContext!.id, workSectionType: 'someday' }];

    if (type === 'subtask') {
      await subtaskToTaskV1TaskboxesSubtasksTaskTypePatch({ subtaskIds: taskDragContext.data.map((item: { id: any }) => item.id) });
    } else if (type === 'multi-subtask') {
      const taskbox = await readTaskboxV1TaskboxesTaskboxIdGet(taskDragContext.id!);
      try {
        const create = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: taskDragContext.title!,
          categoryId: taskbox.category && [taskbox.category.id],
          deadline: taskbox.deadline,
        });

        if (create) {
          await assignTasksV1TaskboxesTaskboxIdAssignPost(create.id!, { tasks: taskDragContext.data });
        }
      } catch (e) {
        toast.error('나중에로 이동할 수 없습니다.');
      }
    } else {
      await updateBulkTaskboxesV1TaskboxesBulkPut(dropItem);
    }
    fetchTasks();
    setDragEnter(false);
  };

  const handleDragEnter = () => {
    if (taskDragContext && taskDragContext.projectId && taskDragContext.type === 'subtask') return;
    setDragEnter(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
    setDragEnter(false);
  };

  //TODO 태스크 그룹 한번에 처리할 수 있는 api 필요
  // const handleChangeWorkInWorkbox = async (type:string, workboxId:string, workId:string, content:) => {};
  const handleCloseTaskEditPopover = async (taskItem: Task) => {
    if (!taskItem) return;
    const { memo, category, tasks, ...updateTask } = taskItem;
    const updateTaskbox: UpdateTaskbox = {
      ...updateTask,
      categoryId: taskItem.category ? [taskItem.category.id] : [],
      tasks: tasks?.map((task) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category && task.category ? [task.category.id] : [],
        };
      }),
    };
    await updateWorkboxesV2V2WorkboxesWorkboxIdPut(taskItem.id, updateTaskbox);
    fetchTasks();
  };

  const handleClickTaskEditCheckbox = async (type: string, workboxId?: string, workId?: string) => {
    if (type === 'SOMEDAY_TASKBOX') {
      await splitWorkboxV1WorkboxesWorkboxIdSplitPost(workboxId!);
    } else if (type === 'SOMEDAY_TASK') {
      await detachWorksInWorkboxV1WorkboxesWorkboxIdDetachPut(workboxId!, { workIds: [workId!] });
    }
    fetchTasks();
  };

  const handleCreateWorkInWorkbox = async (workboxId: string, work: CreateTaskboxTask) => {
    await createWorkInWorkboxV1WorkboxesWorkboxIdWorksPost(workboxId, work);
  };

  const handleUpdateWorkInWorkbox = async (workboxId: string, workId: string, work: UpdateTaskboxTask) => {
    updateWorkInWorkboxV1WorkboxesWorkboxIdWorksWorkIdPut(workboxId, workId, work);
  };

  const handleDeleteWorkInWorkbox = async (workboxId: string, workId: string) => {
    removeWorkInWorkBoxesV1WorkboxesWorkboxIdWorksWorkIdDelete(workboxId, workId);
  };

  const handleClickCategoryActions = async (category: OutCategory, action: CategoryActionType) => {
    switch (action) {
      case 'CREATE':
        {
          const success = await createCategoryV1CategoryPost(category);
          if (success) {
            fetchCategoryList && fetchCategoryList();
          }
        }
        break;
      case 'UPDATE':
        {
          const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
          if (success) {
            fetchCategoryList && fetchCategoryList();
          }
        }
        break;
      case 'DELETE':
        {
          await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
          fetchCategoryList && fetchCategoryList();
        }
        break;
    }
  };

  const handleMouseUp = async () => {
    setIsDragging(false);
    setDragEnter(false);

    if (!calendarDragContext) return;

    const { id } = calendarDragContext;
    await updateTaskboxV1TaskboxesTaskboxIdPut(id, { workSectionType: 'someday' });

    fetchTasks();
  };

  const handleMouseEnter = () => {
    setIsDragging(true);
    setDragEnter(true);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setDragEnter(false);
  };

  const uncheckedIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke={COLORS.gray300} />
    </svg>
  );

  const checkedIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" stroke={COLORS.gray300} />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

  const handleDeadlineFilter = async () => {
    setDeadlineFilter(!deadlineFilter);

    try {
      const update = await updateStateStorageV1StateStoragePatch({ data: { ...userState.data, isFilteringByDue: !deadlineFilter } });
      if (update) setUserState({ ...userState, data: { ...userState.data, isFilteringByDue: !deadlineFilter } });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCategoryFilter = async (category: OutCategory) => {
    const index = categoryFilter.findIndex((id) => id === category.id);
    const updatedCategoryFilter = index === -1 ? [...categoryFilter, category.id] : categoryFilter.filter((id) => id !== category.id);
    setCategoryFilter(updatedCategoryFilter);
  };

  const handleRemoveCategoryFilter = async (category: OutCategory) => {
    const updatedCategoryFilter = categoryFilter.filter((id) => id !== category.id);
    setCategoryFilter(updatedCategoryFilter);
  };

  const handleResetCategoryFilter = async () => {
    setCategoryFilter([]);
  };

  const handleCloseCategoryFilter = async () => {
    setCategoryAnchorEl(null);
    try {
      const update = await updateStateStorageV1StateStoragePatch({ data: { ...userState.data, filteringCategoryIds: categoryFilter } });
      if (update) setUserState({ ...userState, data: { ...userState.data, filteringCategoryIds: categoryFilter } });
    } catch (e) {
      console.error(e);
    }
  };

  const filteredTasks = tasks.filter((task) => {
    // deadlineFilter가 활성화된 경우, deadline이 있는지 확인
    if (deadlineFilter && !task.deadline) return false;

    // categoryFilter가 활성화된 경우, 해당 카테고리가 필터에 포함되는지 확인
    if (categoryFilter.length > 0 && (!task.category || !categoryFilter.includes(task.category.id))) return false;

    return true;
  }) as (OutTaskboxDetailResponse & { id: string; title: string })[];

  return (
    <Container
      ref={refContainer}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {((taskDragContext && taskDragContext.view === 'taskboard') || calendarDragContext) && dragEnter ? (
        <DropWrapper>
          <TaskDropZone onDrop={handleDrop}>
            <Icons.Todo width={32} height={32} />
            <TaskDropZoneTitle>나중에 할 일을 모아두세요</TaskDropZoneTitle>
            <TaskDropZoneDescription>
              <div>일단 적어두고 언제든 할 일로 보낼 수 있어요.</div>
              <div>지금 할 일에만 집중해봐요.</div>
            </TaskDropZoneDescription>
          </TaskDropZone>
        </DropWrapper>
      ) : (
        <ListWrapper>
          <TaskListHeaderWrapper>
            <TaskListShowingWrapper>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 12 }}>
                <IconButton size={'small'} color="inherit" style={{ padding: 2 }} onClick={handleClickCreate}>
                  <Icons.Plus width={16} height={16} strokeWidth={2} />
                </IconButton>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
                <TaskListTitle>태스크</TaskListTitle>
                <TaskListTitleCount style={{ marginLeft: 4 }}>({tasks.length})</TaskListTitleCount>
              </div>
            </TaskListShowingWrapper>
            <TaskListFilterWrapper>
              <Tooltip title="기한 있는 태스크만 보기">
                <IconButton
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: deadlineFilter ? '#E7EAF4' : 'transparent',
                    border: deadlineFilter ? `1px solid #C8CDDB` : '1px solid transparent',
                    borderRadius: '4px',
                    padding: '3px 4px',
                  }}
                  onClick={handleDeadlineFilter}
                >
                  <Icons.Flag width={16} height={16} fill={deadlineFilter ? COLORS.brand1 : COLORS.gray500} />
                </IconButton>
              </Tooltip>
              <div style={{ position: 'relative' }}>
                <Tooltip title="선택한 카테고리의 태스크만 보기">
                  <IconButton
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: categoryFilter.length > 0 ? '#E7EAF4' : 'transparent',
                      border: categoryAnchorEl ? `1px solid ${COLORS.brand1}` : categoryFilter.length > 0 ? `1px solid #C8CDDB` : '1px solid transparent',
                      borderRadius: '4px',
                      padding: '3px 4px',
                      marginLeft: '4px',
                    }}
                    onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                  >
                    <Icons.TaskListFilter width={16} height={16} fill={categoryFilter.length > 0 ? COLORS.brand1 : COLORS.gray500} />
                  </IconButton>
                </Tooltip>
                {categoryFilter.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 12,
                      height: 12,
                      backgroundColor: COLORS.brand1,
                      borderRadius: '50%',
                      color: COLORS.white,
                      fontSize: 8,
                      fontWeight: 700,
                      position: 'absolute',
                      top: -5,
                      left: 22,
                    }}
                  >
                    {categoryFilter.length}
                  </div>
                )}
              </div>
            </TaskListFilterWrapper>
          </TaskListHeaderWrapper>
          {tasks.length === 0 ? (
            <div style={{ padding: '0px 32px' }}>
              <EmptyTaskWrapper>
                <Icons.Todo width={32} height={32} />
                <div style={{ color: COLORS.gray700, fontSize: 12, fontWeight: 700, margin: '8px 0px 4px 0px' }}>나중에 할 일은 무엇인가요?</div>
                <div style={{ color: COLORS.gray500, fontSize: 12 }}>적어두고 마음 편히 잊어버리세요.</div>
              </EmptyTaskWrapper>
            </div>
          ) : (
            <TaskListView
              tasks={filteredTasks}
              categoryList={categoryList}
              draggable={true}
              editable={true}
              dragView="backlog"
              multipleDrag={true}
              showAddTooltip={true}
              onCreate={handleCreateTask}
              onChangeTask={handleChangeTask}
              onChangeTitle={handleChangeTitle}
              onClickContextMenu={handleClickContextMenu}
              onCloseTaskEditPopover={handleCloseTaskEditPopover}
              onClickTaskEditCheckbox={handleClickTaskEditCheckbox}
              onCreateWorkInWorkbox={handleCreateWorkInWorkbox}
              onUpdateWorkInWorkbox={handleUpdateWorkInWorkbox}
              onDeleteWorkInWorkbox={handleDeleteWorkInWorkbox}
              onClickCategoryActions={handleClickCategoryActions}
            />
          )}
        </ListWrapper>
      )}
      {calendarDragContext && isDragging && (
        <div
          style={{
            position: 'fixed',
            left: draggingTask.x - 10,
            top: draggingTask.y - 10,
          }}
        >
          <EventWrapper>
            {calendarDragContext?.focus ? (
              <VerticalBarFocus done={calendarDragContext.done} style={{ marginRight: 8 }} />
            ) : (
              <VerticalBar done={calendarDragContext.done} isRecurrence={calendarDragContext.isRecurrence} style={{ marginRight: 8 }} />
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <EventTitle done={calendarDragContext.done}>
                {calendarDragContext?.lockedIn && <span style={{ marginRight: 4 }}>{calendarDragContext.done ? '✅' : '🌟'}</span>}
                <span style={{ textDecoration: calendarDragContext.done ? 'line-through' : '' }}>{calendarDragContext.title}</span>
              </EventTitle>
            </div>
          </EventWrapper>
        </div>
      )}
      <Popover
        open={Boolean(categoryAnchorEl)}
        anchorEl={categoryAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseCategoryFilter}
      >
        <CategoryFilterWrapper>
          {categoryFilter.length > 0 && (
            <>
              <CategorySelectedWrapper>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }}>
                  {categoryList
                    .filter((category) => categoryFilter.includes(category.id))
                    .map((category) => (
                      <CategoryShowingWrapper textColor={getCategoryTextColor(category.color)} bgColor={getCategoryBgColor(category.color)}>
                        <span>{`# ${category.name}`}</span>
                        <IconButton
                          className="category-detach-button"
                          onClick={() => handleRemoveCategoryFilter(category)}
                          sx={{
                            'width': '12px',
                            'height': '12px',
                            'display': 'flex',
                            'alignItems': 'center',
                            'justifyContent': 'center',
                            'borderRadius': '4px',
                            'marginLeft': '4px',
                            'padding': '0px',
                            ':hover': {
                              backgroundColor: hexToRGBA(getCategoryTextColor(category.color)!, 0.3),
                            },
                          }}
                        >
                          <Icons.Close width={7} height={7} fill={getCategoryTextColor(category.color)} />
                        </IconButton>
                      </CategoryShowingWrapper>
                    ))}
                </div>
                {categoryFilter.length > 0 && (
                  <IconButton
                    onClick={handleResetCategoryFilter}
                    sx={{
                      'width': '16px',
                      'height': '16px',
                      'backgroundColor': COLORS.gray200,
                      'padding': '0px',
                      ':hover': {
                        backgroundColor: COLORS.gray200,
                      },
                    }}
                  >
                    <Icons.Close width={10} height={10} fill={COLORS.gray900} />
                  </IconButton>
                )}
              </CategorySelectedWrapper>
              <Divider />
            </>
          )}
          <FormGroup sx={{ padding: '8px' }}>
            {categoryList.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    checkedIcon={
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#C8CDDB" />
                        <path d="M3 8.125L6.33333 11.25L13 5" stroke="#0039A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    }
                    checked={categoryFilter.includes(category.id)}
                    onChange={() => handleCategoryFilter(category)}
                    name={category.name}
                    sx={{ marginRight: '4px', padding: '0px' }}
                  />
                }
                label={
                  <CategoryFilterClickWrapper>
                    <CategoryShowingWrapper textColor={getCategoryTextColor(category.color)} bgColor={getCategoryBgColor(category.color)}>
                      {`# ${category.name}`}
                    </CategoryShowingWrapper>
                  </CategoryFilterClickWrapper>
                }
                sx={{
                  'display': 'flex',
                  'alignItems': 'center',
                  'marginLeft': '0px',
                  'marginRight': '0px',
                  'padding': '8px',
                  ':hover': {
                    backgroundColor: COLORS.gray100,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  },
                }}
              />
            ))}
          </FormGroup>
        </CategoryFilterWrapper>
      </Popover>
    </Container>
  );
};

export default Backlog;
