import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Autocomplete,
  Button,
  Dialog,
  Divider,
  Drawer,
  duration,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { categoryAtom } from 'atoms/category';
import { Icons } from 'components';
import dayjs from 'lib/dayjs';
import { useAtom } from 'jotai';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import TaskBlockInputList, { TaskBlock } from 'pages/Task/components/TaskBlockInputList';
import TaskWeek from 'pages/Task/Week';
import {
  createCategoryV1CategoryPost,
  createRoutineV1RoutinePost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
} from 'queries';
import {
  AppKernelComponentLinkModelJsonLink,
  InCreateRoutine,
  InCreateRoutineTask,
  InRecurrence,
  OutCategory,
  OutLink,
  OutTaskboxDetailResponse,
  OutTaskboxDetailResponseMemo,
  WeekDay,
} from 'queries/model';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { DATE_FORMAT_1, DATE_FORMAT_2, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { RecurrenceCustomDialog } from 'components/Recurrence/RecurrenceCustomDialog';
import { RemirrorContentType } from 'remirror';
import RemirrorEditor from 'components/Remirror';
import { v4 as uuidv4 } from 'uuid';

interface RoutineCreationViewProps {
  open?: boolean;
  onClose?: () => void;
}

const RoutineCreationView = ({ open, onClose }: RoutineCreationViewProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [routine, setRoutine] = useState<InCreateRoutine>({
    id: uuidv4(),
    title: '',
    start: {
      date: dayjs().format(DATE_FORMAT_4),
    },
    end: {
      date: dayjs().add(1, 'day').format(DATE_FORMAT_4),
    },
    recurrence: { interval: 1, intervalUnit: 'DAILY' },
  });
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [autoWidth, setAutoWidth] = useState(false);
  const [date, setDate] = useState(
    routine?.start?.date ? dayjs(routine?.start?.date, { utc: true }).toDate() : dayjs(routine?.start?.datetime, { utc: true }).toDate(),
  );

  useEffect(() => {
    const newDate = routine?.start?.date ? dayjs(routine?.start?.date, { utc: true }).toDate() : dayjs(routine?.start?.datetime, { utc: true }).toDate();
    if (dayjs(newDate).isSame(date)) return;
    const recurrenceIndex = selectableRecurrence.findIndex((recurrence) => recurrence === recurrenceUnit);
    const newSelectableRecurrence = [
      '매일',
      `매주 ${dayjs(newDate).format('dddd')}`,
      `매월 ${dayjs(newDate).diff(dayjs(newDate).startOf('month'), 'week') + 1}번째 ${dayjs(newDate).format('dddd')}`,
      `매년 ${dayjs(newDate).format('M')}월 ${dayjs(newDate).format('DD')}일`,
      '주중 매일(월-금)',
      '사용자 설정',
    ];
    setDate(newDate);
    setRecurrenceUnit(newSelectableRecurrence[recurrenceIndex]);
    setSeletableRecurrence(newSelectableRecurrence);
  }, [routine]);

  useEffect(() => {
    if (!openCalendar) {
      const timer = setTimeout(() => {
        setAutoWidth(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setAutoWidth(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [openCalendar]);

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    if (!routine) return;
    switch (action) {
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          try {
            if (routine.title?.includes('#')) {
              setRoutine({ ...routine, title: routine.title.replace(/#/g, ''), categoryId: category ? category.id : undefined });
              //   await updateProjectV1ProjectProjectIdPatch(project.id!, {
              //     title: project.title.includes('#') ? project.title.replace(/#/g, '') : project.title,
              //     categoryIds: category ? [category.id] : [],
              //   });
            } else {
              setRoutine({ ...routine, categoryId: category ? category.id : undefined });
              //   await updateProjectV1ProjectProjectIdPatch(project.id!, {
              //     categoryIds: category ? [category.id] : [],
              //   });
            }
            // onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 선택할 수 없습니다.');
          }
        }
        break;
      case 'CREATE':
        {
          if (!category) return;
          try {
            await createCategoryV1CategoryPost(category);
            fetchCategoryList();
          } catch (e) {
            toast.error('카테고리를 생성할 수 없습니다.');
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          try {
            await deleteCategoryV1CategoryCategoryIdDelete(category.id);
            fetchCategoryList();
            // onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 삭제할 수 없습니다.');
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          try {
            await updateCategoryV1CategoryCategoryIdPatch(category.id, { ...category, name: category.name });
            fetchCategoryList();
            // onFetch && onFetch();
          } catch (e) {
            toast.error('카테고리를 수정할 수 없습니다.');
          }
        }
        break;
    }
  };

  const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLDivElement | null>(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLDivElement | null>(null);
  const calendarRef = useRef(null);
  const timeSelectRef = useRef(null);
  const refTimeSelection = useRef<HTMLDivElement>(null);

  const handleChangeStartDate = (date: Date | null) => {
    if (date) {
      const start = routine.start.date
        ? { date: dayjs(date).format(DATE_FORMAT_4) }
        : {
            datetime: `${dayjs(date).format(DATE_FORMAT_4)}T${routine.start.datetime?.split('T')[1]}`,
          };

      const end = routine.end.date
        ? { date: dayjs(date).add(1, 'day').format(DATE_FORMAT_4) }
        : { datetime: `${dayjs(date).format(DATE_FORMAT_4)}T${routine.end.datetime?.split('T')[1]}` };

      setRoutine({ ...routine, start, end });
      setCalendarAnchorEl(null);
    }
  };

  const handleChangeTime = (startTime: string, endTime: string, timeDiff: number, eventId?: string, isAllDay?: boolean) => {
    const datetime = isAllDay
      ? {
          start: { date: dayjs(routine.start?.date || routine.start?.datetime, { utc: true }).format(DATE_FORMAT_4) },
          end: {
            date: dayjs(routine.start?.date || routine.start?.datetime, { utc: true })
              .add(1, 'day')
              .format(DATE_FORMAT_4),
          },
          durationMin: timeDiff,
          beforeId: null,
        }
      : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
      ? {
          start: { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) },
          end: { date: dayjs(endTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) },
          beforeId: null,
        }
      : {
          start: { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
          end: { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
        };

    setRoutine({
      ...routine,
      ...datetime,
    });

    setTimeAnchorEl(null);
  };

  const timeFormat = (routine: InCreateRoutine) => {
    const time = dayjs(routine.start?.datetime, { utc: true }).format('A h시');
    const minutes = dayjs(routine.start?.datetime, { utc: true }).format('mm');
    const formattedTime = minutes === '00' ? time : `${time} ${minutes}분`;
    return formattedTime;
  };

  const duringTimeFormat = (routine: InCreateRoutine) => {
    const timeDiff = dayjs(routine.end?.datetime).diff(dayjs(routine.start?.datetime), 'minute');
    const hour = Math.floor(timeDiff / 60);
    const minute = timeDiff % 60;
    const formattedTime = hour === 0 ? `${minute}분` : minute === 0 ? `${hour}시간` : `${hour}시간 ${minute}분`;
    return formattedTime;
  };

  const [recurrenceUnit, setRecurrenceUnit] = useState<string>('매일');
  const [selectableRecurrence, setSeletableRecurrence] = useState([
    '매일',
    `매주 ${dayjs().format('dddd')}`,
    `매월 ${dayjs().diff(dayjs().startOf('month'), 'week') + 1}번째 ${dayjs().format('dddd')}`,
    `매년 ${dayjs().format('M')}월 ${dayjs().format('DD')}일`,
    '주중 매일(월-금)',
    '사용자 설정',
  ]);
  const selectableRecurrenceDays = [
    { kr: '일', en: 'SU' },
    { kr: '월', en: 'MO' },
    { kr: '화', en: 'TU' },
    { kr: '수', en: 'WE' },
    { kr: '목', en: 'TH' },
    { kr: '금', en: 'FR' },
    { kr: '토', en: 'SA' },
  ];

  const [taskboxRecurrence, setTaskboxRecurrence] = useState<InRecurrence | undefined>(undefined);
  const [customRecurrence, setCustomRecurrence] = useState<InRecurrence | undefined>();

  const handleCloseCustomReccurence = () => {
    if (customRecurrence) {
      if (customRecurrence.interval === 1) {
        if (customRecurrence.intervalUnit === 'DAILY') {
          setRecurrenceUnit('매일');
        } else if (customRecurrence.intervalUnit === 'WEEKLY') {
          const newWeekdays = [];
          let weekEveryday = false;
          const targetArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];

          for (let i = 0; i < customRecurrence.weekdays!.length!; i++) {
            const weekday = selectableRecurrenceDays.find((day) => day.en === customRecurrence.weekdays![i]);
            newWeekdays.push(`${weekday?.kr}요일`);
          }

          for (let i = 0; i < targetArray.length; i++) {
            if (newWeekdays[i] !== targetArray[i]) weekEveryday = false;
            else weekEveryday = true;
          }

          if (weekEveryday) {
            setRecurrenceUnit('주중 매일(월-금)');
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (customRecurrence.intervalUnit === 'MONTHLY') {
          if (customRecurrence.monthlyType === 'nth_weekday') {
            setRecurrenceUnit(`매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`);
          } else {
            setRecurrenceUnit(selectableRecurrence[6]);
          }
        } else if (customRecurrence.intervalUnit === 'YEARLY') {
          setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
        }
      } else {
        setRecurrenceUnit(selectableRecurrence[6]);
      }
    } else {
      setRecurrenceUnit(selectableRecurrence[0]);
    }
  };

  const handleChangeRecurrence = (recurrence: InRecurrence | undefined) => {
    setTaskboxRecurrence(recurrence);
  };

  const handleClickCustomRecurrence = (recurrence: InRecurrence) => {
    setCustomRecurrence(recurrence);
    handleChangeRecurrence(recurrence);

    if (recurrence?.interval === 1) {
      if (recurrence?.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit('매일');
        }
      } else if (recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];
        let weekEveryday = false;
        const targetArray = ['월요일', '화요일', '수요일', '목요일', '금요일'];
        for (let i = 0; i < recurrence.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === recurrence?.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (newWeekdays.length === targetArray.length) {
          for (let i = 0; i < targetArray.length; i++) {
            if (newWeekdays[i] !== targetArray[i]) weekEveryday = false;
            else weekEveryday = true;
          }
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          if (weekEveryday) {
            setRecurrenceUnit('주중 매일(월-금)');
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매주 ${newWeekdays.join(', ')}`);
            else list.splice(6, 1, `매주 ${newWeekdays.join(', ')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `매월 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            setRecurrenceUnit(`매월 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `매월 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `매월 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          setRecurrenceUnit(`매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
        }
      }
    } else {
      if (recurrence?.intervalUnit === 'DAILY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `${recurrence.interval}일마다, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence?.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}일마다, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}일마다`);
          else list.splice(6, 1, `${recurrence.interval}일마다`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'WEEKLY') {
        const newWeekdays = [];

        for (let i = 0; i < recurrence.weekdays!.length!; i++) {
          const weekday = selectableRecurrenceDays.find((day) => day.en === recurrence?.weekdays![i]);
          newWeekdays.push(`${weekday?.kr}요일`);
        }

        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          else list.splice(6, 1, `${recurrence.interval}주마다 ${newWeekdays.join(', ')}`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      } else if (recurrence?.intervalUnit === 'MONTHLY') {
        if (recurrence?.monthlyType === 'day_of_month') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${dayjs(date).format('DD')}일`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else if (recurrence?.monthlyType === 'nth_weekday') {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(
                  DATE_FORMAT_2,
                )}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else
              list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 ${recurrence.monthlyTypeOrder}번째 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        } else {
          if (recurrence.endDate) {
            const list = [...selectableRecurrence];
            if (list.length === 7)
              list.splice(
                6,
                0,
                `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            else
              list.splice(
                6,
                1,
                `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, 종료일: ${dayjs(recurrence.endDate).format(DATE_FORMAT_2)}`,
              );
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else if (recurrence.occurrences) {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}, ${recurrence.occurrences}회`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          } else {
            const list = [...selectableRecurrence];
            if (list.length === 7) list.splice(6, 0, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            else list.splice(6, 1, `${recurrence.interval}개월마다 마지막 ${dayjs(date).format('dddd')}`);
            setSeletableRecurrence(list);
            setRecurrenceUnit(list[6]);
          }
        }
      } else if (recurrence?.intervalUnit === 'YEARLY') {
        if (recurrence.endDate) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(
              6,
              0,
              `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(
                DATE_FORMAT_2,
              )}`,
            );
          else
            list.splice(
              6,
              1,
              `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, 종료일: ${dayjs(recurrence.endDate).format(
                DATE_FORMAT_2,
              )}`,
            );
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else if (recurrence.occurrences) {
          const list = [...selectableRecurrence];
          if (list.length === 7)
            list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일, ${recurrence.occurrences}회`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        } else {
          const list = [...selectableRecurrence];
          if (list.length === 7) list.splice(6, 0, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          else list.splice(6, 1, `${recurrence.interval}년마다 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`);
          setSeletableRecurrence(list);
          setRecurrenceUnit(list[6]);
        }
      }
    }
  };

  useEffect(() => {
    const index = selectableRecurrence.findIndex((v) => v === recurrenceUnit);

    if (index < 5) {
      setSeletableRecurrence([
        '매일',
        `매주 ${dayjs(date).format('dddd')}`,
        `매월 ${dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1}번째 ${dayjs(date).format('dddd')}`,
        `매년 ${dayjs(date).format('M')}월 ${dayjs(date).format('DD')}일`,
        '주중 매일(월-금)',
        '사용자 설정',
      ]);
    }

    if (index === 0) {
      handleChangeRecurrence({ interval: 1, intervalUnit: 'DAILY' });
      setCustomRecurrence({ interval: 1, intervalUnit: 'DAILY' });
    } else if (index === 1) {
      const weekdays = [dayjs(date).locale('en').format('dd').toUpperCase()] as WeekDay[];
      handleChangeRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
      setCustomRecurrence({ interval: 1, intervalUnit: 'WEEKLY', weekdays: weekdays });
    } else if (index === 2) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'MONTHLY',
        monthlyType: 'nth_weekday',
        monthlyTypeOrder: dayjs(date).diff(dayjs(date).startOf('month'), 'week') + 1,
      });
    } else if (index === 3) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'YEARLY',
      });
    } else if (index === 4) {
      handleChangeRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
      setCustomRecurrence({
        interval: 1,
        intervalUnit: 'WEEKLY',
        weekdays: ['MO', 'TU', 'WE', 'TH', 'FR'],
      });
    } else {
      handleChangeRecurrence(customRecurrence);
    }
  }, [recurrenceUnit]);

  const handleChangeMemo = (data: RemirrorContentType) => {
    setRoutine({ ...routine, memo: data as OutTaskboxDetailResponseMemo });
  };

  const refCreateLinkInput = useRef<HTMLInputElement>(null);

  const [selectedLink, setSelectedLink] = useState<(AppKernelComponentLinkModelJsonLink & { index: number }) | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState<HTMLElement | null>(null);

  const handleCreateLink = () => {
    if (!routine) return;
    const links = routine?.links || [];
    if (links[links.length - 1]?.title === '' && links[links.length - 1]?.url === '') {
      setSelectedLink({ ...links[links.length - 1], index: links.length - 1 });
      setTimeout(() => {
        if (refCreateLinkInput.current) refCreateLinkInput.current?.focus();
      }, 100);
    } else {
      const newLink = {
        title: '',
        url: '',
      };
      setSelectedLink({ ...newLink, index: links.length });
      setRoutine({ ...routine, links: [...links, newLink] as OutLink[] });
    }
  };

  const handleSaveLink = async () => {
    if (!routine) return;
    if (!selectedLink || selectedLink?.url === '') {
      clearLinkState();
      return;
    }

    const links = routine?.links || [];
    const targetLink = links[selectedLink.index];

    if (targetLink) {
      targetLink.url = selectedLink.url;
      targetLink.title = selectedLink.title;
    }
    clearLinkState();
  };

  const clearLinkState = () => {
    setSelectedLink(null);
    setLinkAnchorEl(null);
  };

  const handleClickLinkUpdate = (link: AppKernelComponentLinkModelJsonLink, index: number) => {
    setSelectedLink({
      ...link,
      index: index,
    });
    setTimeout(() => {
      const el = document.querySelector(`[data-link-idx="${index}"]`) as HTMLDivElement;
      setLinkAnchorEl(el);
    }, 100);
  };

  const handleCopyClipBoard = async (text: string) => {
    if (text === '') return toast.error('링크가 없습니다.');

    try {
      await navigator.clipboard.writeText(text);
      toast.success('클립보드에 링크가 복사되었습니다.');
    } catch (e) {
      toast.error('복사에 실패하였습니다');
    }
  };

  const handleDeleteLink = async () => {
    if (!routine || !selectedLink) return;
    try {
      const links = routine?.links?.filter((_, idx) => idx !== selectedLink.index);
      setRoutine({ ...routine, links });
      clearLinkState();
    } catch (e) {
      toast.error('링크를 삭제할 수 없습니다.');
    }
  };

  const handleChangeSubtask = (blocks: TaskBlock[]) => {
    setRoutine({
      ...routine,
      tasks: blocks.map((block) => {
        return { ...block, title: block.content } as InCreateRoutineTask;
      }),
    });
  };

  const handleClose = () => {
    onClose && onClose();
    setRoutine({
      id: uuidv4(),
      title: '',
      start: {
        date: dayjs().format(DATE_FORMAT_4),
      },
      end: {
        date: dayjs().add(1, 'day').format(DATE_FORMAT_4),
      },
      recurrence: { interval: 1, intervalUnit: 'DAILY' },
    });
    setRecurrenceUnit('매일');
  };

  const handleCreateRoutine = async () => {
    if (!routine.title) {
      toast.error('루틴 제목을 입력해주세요.');
      return;
    }
    if (!taskboxRecurrence) {
      toast.error('반복 주기를 선택해주세요.');
      return;
    }
    const newRoutine: InCreateRoutine = {
      ...routine,
      recurrence: taskboxRecurrence,
    };

    try {
      await createRoutineV1RoutinePost(newRoutine);
      toast.success('루틴이 생성되었습니다.');
      handleClose();
    } catch (e) {
      toast.error('루틴을 생성할 수 없습니다.');
    }
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: openCalendar ? '98%' : autoWidth ? 'auto' : '98%', // 캘린더가 열릴 때 너비 변경
          borderRadius: openCalendar ? '16px 0px 0px 16px' : '0px',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Container>
        <Drawer
          anchor={'right'}
          open={openCalendar}
          variant="persistent"
          PaperProps={{
            sx: {
              width: '67%', // 캘린더 Drawer의 너비
              position: 'absolute',
              right: 414,
              zIndex: openCalendar && !autoWidth ? 1 : -1, // 메인 Drawer 뒤에서 슬라이드되도록 설정
            },
          }}
        >
          <TaskWeek routineView />
        </Drawer>
        <RoutineWrapper>
          <TitleWrapper>
            <RoutineTitle>
              <Icons.Issue width={20} height={20} stroke={COLORS.sub4} fill={COLORS.sub4} />
              <TextField
                autoComplete="off"
                autoFocus
                fullWidth
                variant="standard"
                placeholder="루틴 제목 입력하기"
                ref={refInput}
                value={routine.title || ''}
                onChange={(e) => setRoutine({ ...routine, title: e.currentTarget.value })}
                // onBlur={handleUpdateProjectTitle}
                // onKeyDown={(e) => handleKeyDown(e)}
                InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
                style={{ marginLeft: 8 }}
              />
            </RoutineTitle>
            <ButtonWrapper>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  'border': `1px solid ${COLORS.gray200}`,
                  'borderRadius': '8px',
                  'color': COLORS.gray600,
                  'fontSize': '12px',
                  'fontWeight': 700,
                  'marginRight': '8px',
                  'padding': ' 5px 8px',
                  ':hover': {
                    border: `1px solid ${COLORS.gray300}`,
                  },
                }}
              >
                취소
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateRoutine}
                sx={{ borderRadius: '8px', boxShadow: 'none', color: COLORS.white, fontSize: '12px', fontWeight: 700, padding: ' 5px 8px' }}
              >
                생성 완료
              </Button>
            </ButtonWrapper>
          </TitleWrapper>
          <CategoryWrapper>
            <PropertyTitleWrapper>카테고리</PropertyTitleWrapper>
            {routine.categoryId ? (
              <CategoryShowingWrapper
                textColor={getCategoryTextColor(categoryList!.find((v) => v.id === routine.categoryId)!.color)}
                bgColor={getCategoryBgColor(categoryList!.find((v) => v.id === routine.categoryId)!.color)}
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
              >
                {`# ${categoryList!.find((v) => v.id === routine.categoryId)!.name}`}
                <IconButton
                  className="category-detach-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickCategoryActions(null, 'SELECT');
                  }}
                  sx={{
                    'width': '12px',
                    'height': '12px',
                    'borderRadius': '4px',
                    'marginLeft': '4px',
                    'marginTop': '1px',
                    'padding': '0px',
                    ':hover': {
                      backgroundColor: hexToRGBA(getCategoryTextColor(categoryList!.find((v) => v.id === routine.categoryId)!.color)!, 0.3),
                    },
                  }}
                  style={categoryAnchorEl ? { display: 'flex' } : {}}
                >
                  <Icons.Close width={8} height={8} fill={getCategoryTextColor(categoryList!.find((v) => v.id === routine.categoryId)!.color)} />
                </IconButton>
              </CategoryShowingWrapper>
            ) : (
              <Button
                onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                sx={{ display: 'flex', alignItems: 'center', borderRadius: '6px', color: COLORS.gray500, fontSize: '12px', padding: '2px 6px' }}
              >
                <Icons.Category width={16} height={16} fill={COLORS.gray500} />
                <span style={{ margin: '1px 0px 0px 8px' }}>카테고리 추가하기</span>
              </Button>
            )}
          </CategoryWrapper>
          <Divider
            sx={{
              margin: '16px 0px',
            }}
          />
          <SubtaskWrapper>
            <PropertyTitleWrapper>반복 하위 업무</PropertyTitleWrapper>
            <TaskBlockInputList
              draggable={false}
              isRecurrence
              suppressTemplate
              suppressCheckboxToggle
              tasks={routine.tasks as TaskBlock[]}
              onChange={handleChangeSubtask}
            />
          </SubtaskWrapper>
          <Divider
            sx={{
              margin: '16px 0px',
            }}
          />
          <RecurrenceWrapper>
            <PropertyTitleWrapper style={{ marginBottom: 12 }}>반복 스케줄</PropertyTitleWrapper>
            <RecurrenceActionsWrapper>
              <div style={{ width: 60 }}>시작일</div>
              <StartDateWrapper
                ref={calendarRef}
                focus={Boolean(calendarAnchorEl)}
                onClick={(e) => {
                  setCalendarAnchorEl(e.currentTarget);
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.CalendarWithoutClock width={12} height={12} fill={COLORS.gray600} />
                  <div style={{ height: '16px', marginLeft: 6 }}>
                    {dayjs(routine.start.date || routine.start.datetime, { utc: true }).format('M월 D일 ddd요일')}
                  </div>
                </div>
              </StartDateWrapper>
            </RecurrenceActionsWrapper>
            <RecurrenceActionsWrapper>
              <div style={{ width: 60 }}>시간</div>
              <TimeSelectWrapper
                focus={Boolean(timeAnchorEl)}
                onClick={(e) => {
                  setTimeAnchorEl(e.currentTarget);
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icons.Time width={12} height={12} />
                  <div style={{ height: '16px', marginLeft: 6 }}>
                    {routine.start.date ? '종일' : `${timeFormat(routine)}부터, ${duringTimeFormat(routine)}`}
                  </div>
                </div>
              </TimeSelectWrapper>
            </RecurrenceActionsWrapper>
            <RecurrenceActionsWrapper>
              <div style={{ width: 60 }}>주기</div>
              <RecurrenceWrapper className="recurrence">
                <Select
                  value={recurrenceUnit}
                  onChange={(e) => {
                    setRecurrenceUnit(e.target.value);
                  }}
                  style={{ width: '100%', height: 22, marginLeft: '8px', borderRadius: 6, backgroundColor: COLORS.gray100 }}
                  renderValue={() => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icons.Recurrence width={12} height={12} stroke={COLORS.gray600} style={{ marginRight: 6 }} />
                      <div style={{ color: COLORS.gray600, fontSize: '12px' }}>{recurrenceUnit}</div>
                    </div>
                  )}
                  inputProps={{
                    sx: {
                      padding: '8px',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 372,
                      },
                    },
                  }}
                >
                  {selectableRecurrence.map((v, idx) => (
                    <MenuItem key={idx} value={v}>
                      <span style={{ fontSize: '12px', width: '100%' }}>{v}</span>
                    </MenuItem>
                  ))}
                </Select>
              </RecurrenceWrapper>
            </RecurrenceActionsWrapper>
            <Button
              variant="contained"
              startIcon={openCalendar ? <Icons.ChevronRightDuo /> : <Icons.ChevronLeftDuo />}
              onClick={() => setOpenCalendar(!openCalendar)}
              sx={{
                'backgroundColor': openCalendar ? COLORS.sub2 : COLORS.white,
                'border': openCalendar ? `1px solid ${COLORS.sub2}` : `1px solid ${COLORS.brand1}`,
                'borderRadius': '8px',
                'boxShadow': 'none',
                'color': openCalendar ? COLORS.white : COLORS.brand1,
                'fontSize': '12px',
                'fontWeight': 700,
                'padding': '5px 8px',
                ':hover': {
                  backgroundColor: openCalendar ? COLORS.sub2 : COLORS.gray100,
                },
              }}
            >
              {openCalendar ? '내 일정 접기' : '내 일정 확인하기'}
            </Button>
          </RecurrenceWrapper>
          <Divider
            sx={{
              margin: '16px 0px',
            }}
          />
          <MemoWrapper className={'create-routine-memo'}>
            <RemirrorEditor data={routine.memo as RemirrorContentType} onChangeData={handleChangeMemo} />
          </MemoWrapper>
          <Divider
            sx={{
              margin: '16px 0px',
            }}
          />
          <LinkWrapper>
            {routine?.links?.map((link, idx) => (
              <LinkContentWrapper data-link-idx={idx} key={idx}>
                <div style={{ width: '100%', flex: 1, display: 'flex', alignItems: 'center' }}>
                  <Icons.Link2 />
                  {link.url === '' ? (
                    <TextField
                      inputRef={refCreateLinkInput}
                      fullWidth
                      autoFocus
                      placeholder="URL 주소(https://…)를 붙여넣고 Enter로 입력하기"
                      onChange={(e) => {
                        setSelectedLink({ ...link, url: e.target.value, title: e.target.value, index: idx });
                      }}
                      onBlur={handleSaveLink}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (e.nativeEvent.isComposing) return;
                          e.preventDefault();
                          handleSaveLink();
                        }
                      }}
                      inputProps={{
                        style: { padding: '0px', fontSize: '13px', border: 'none' },
                      }}
                      sx={{ border: 'none', fontSize: '13px', marginLeft: '8px' }}
                    />
                  ) : (
                    <Tooltip title={link.url} disableInteractive>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          width: '100%',
                          fontSize: '13px',
                          marginLeft: '8px',
                          textDecoration: 'underline',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {link.title || link.url}
                      </a>
                    </Tooltip>
                  )}
                </div>
                <div
                  className="link-icon"
                  style={{
                    backgroundColor: COLORS.white,
                    border: `1px solid ${COLORS.gray200}`,
                    borderRadius: '8px',
                    padding: '2px',
                    position: 'absolute',
                    right: '8px',
                  }}
                >
                  <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleCopyClipBoard(link.url)}>
                    <Icons.Duplicate stroke={COLORS.gray400} />
                  </IconButton>
                  <IconButton sx={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }} onClick={() => handleClickLinkUpdate(link, idx)}>
                    <Icons.Edit width={16} height={16} stroke={COLORS.gray400} />
                  </IconButton>
                </div>
              </LinkContentWrapper>
            ))}
            <CreateLinkButton onClick={handleCreateLink}>
              <Icons.AddLink />
              <div style={{ fontSize: '13px', color: COLORS.gray500, marginLeft: '8px' }}>링크 추가하기</div>
            </CreateLinkButton>
          </LinkWrapper>
          <Divider
            sx={{
              margin: '16px 0px',
            }}
          />
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => setCategoryAnchorEl(null)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
          <Popover
            disablePortal
            open={Boolean(calendarAnchorEl)}
            anchorEl={calendarAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={() => setCalendarAnchorEl(null)}
            sx={{ marginTop: '2px' }}
          >
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={dayjs(date).toDate()}
              onChange={(newValue) => handleChangeStartDate?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate())}
              renderInput={(params) => <TextField {...params} />}
            />
          </Popover>
          <Popover
            disablePortal
            open={Boolean(timeAnchorEl)}
            anchorEl={timeAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className="time-select"
            onClose={() => setTimeAnchorEl(null)}
            sx={{ marginTop: '2px' }}
          >
            <SimpleTimeSelectionModal
              event={{ ...routine, type: 'ROUTINE_TASK' } as OutTaskboxDetailResponse}
              onClose={() => setTimeAnchorEl(null)}
              onChangeTime={handleChangeTime}
            />
          </Popover>
          {linkAnchorEl && (
            <Popover
              open={Boolean(linkAnchorEl)}
              anchorEl={linkAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handleSaveLink}
              sx={{ marginLeft: '30px' }}
            >
              <LinkPopoverContainer className="project-link">
                <LinkPopoverWrapper>
                  <LinkURLWrapper>
                    <div>링크 URL</div>
                    <TextField
                      autoFocus
                      fullWidth
                      value={selectedLink?.url}
                      placeholder="링크 URL 주소(https://…)를 입력해주세요."
                      onChange={(e) => {
                        if (!selectedLink) return;
                        setSelectedLink({ ...selectedLink, url: e.target.value });
                      }}
                      sx={{ marginTop: '4px' }}
                      inputProps={{
                        style: { padding: '8px 12px', fontSize: '12px' },
                      }}
                    />
                  </LinkURLWrapper>
                  <LinkTitleWrapper>
                    <div>링크 제목</div>
                    <TextField
                      fullWidth
                      value={selectedLink?.title}
                      placeholder="링크 제목을 입력해주세요."
                      onChange={(e) => {
                        if (!selectedLink) return;
                        setSelectedLink({ ...selectedLink, title: e.target.value });
                      }}
                      sx={{ marginTop: '4px' }}
                      inputProps={{
                        style: { padding: '8px 12px', fontSize: '12px' },
                      }}
                    />
                  </LinkTitleWrapper>
                </LinkPopoverWrapper>
                <Divider />
                <LinkDeleteWrapper>
                  <Button
                    onClick={handleDeleteLink}
                    sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: COLORS.negative1 }}
                  >
                    <Icons.Delete fill={COLORS.negative1} />
                    <span style={{ marginLeft: '8px' }}>링크 삭제</span>
                  </Button>
                </LinkDeleteWrapper>
              </LinkPopoverContainer>
            </Popover>
          )}
          <Dialog open={recurrenceUnit === '사용자 설정'} onClose={handleCloseCustomReccurence}>
            <RecurrenceCustomDialog
              date={dayjs().toDate()}
              defaultCustomRecurrence={customRecurrence}
              onClose={handleCloseCustomReccurence}
              onChange={handleClickCustomRecurrence}
            />
          </Dialog>
        </RoutineWrapper>
      </Container>
    </Drawer>
  );
};

export default RoutineCreationView;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .recurrence {
    .MuiSvgIcon-root {
      display: none;
    }

    .MuiSelect-select {
      padding-right: 0px;
    }
  }
`;

const RoutineWrapper = styled.div`
  height: 100%;
  padding: 32px;
  background-color: ${COLORS.white};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RoutineTitle = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryWrapper = styled.div`
  margin-top: 24px;
`;

const RecurrenceActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const StartDateWrapper = styled.div<{ focus?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f5fc;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 8px 0px 8px;
  padding: 2px 6px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    ${(props) => !props.focus && `border: 1px solid ${COLORS.gray900};`}
  }
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
      padding: 2px 6px;
    `}
`;

const TimeSelectWrapper = styled.div<{ focus?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f5fc;
  border-radius: 6px;
  border: 1px solid #cbcbcb;
  font-size: 12px;
  font-weight: 400;
  margin: 0px 8px 0px 8px;
  padding: 2px 6px;
  color: ${COLORS.gray600};
  cursor: pointer;
  &:hover {
    ${(props) => !props.focus && `border: 1px solid ${COLORS.gray900};`}
  }
  ${(props) =>
    props.focus &&
    css`
      border: 1px solid ${COLORS.brand1};
      padding: 2px 6px;
    `}
`;

const SubtaskWrapper = styled.div``;

const RecurrenceWrapper = styled.div``;

const MemoWrapper = styled.div``;

const LinkWrapper = styled.div``;

const LinkContentWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  position: relative;

  .link-icon {
    opacity: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  :hover {
    background-color: ${COLORS.gray100};
    .link-icon {
      opacity: 1;
    }
  }
`;

const CreateLinkButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
`;

const LinkPopoverContainer = styled.div`
  width: 365px;
`;

const LinkPopoverWrapper = styled.div`
  font-size: 12px;
  padding: 16px;
`;

const LinkURLWrapper = styled.div``;

const LinkTitleWrapper = styled.div`
  margin-top: 12px;
`;

const LinkDeleteWrapper = styled.div`
  width: 100%;
  padding: 8px;
`;

const PropertyTitleWrapper = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;
