import styled from '@emotion/styled';
import {
  createTaskboxV1TaskboxesPost,
  getEventsV1EventsGet,
  mergeWorkOrWorkBoxesV2V2WorksMergePost,
  readTaskboxesV1TaskboxesGet,
  removeTaskV1WorksWorkIdDelete,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  updateTaskboxV1TaskboxesTaskboxIdPut,
  updateWorkboxesV2V2WorkboxesWorkboxIdPut,
  createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost,
  createFrequentWorkV1FrequentworksPost,
  deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete,
  updateFrequentWorkV1FrequentworksFrequentWorkIdPut,
  updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch,
  getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet,
  readFrequentWorksV1FrequentworksGet,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  createCategoryV1CategoryPost,
  updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch,
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  createTaskboxTaskV1TaskboxesTaskboxIdTasksPost,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  deleteProjectV1ProjectProjectIdDelete,
  updateProjectV1ProjectProjectIdPatch,
  absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost,
  createEventV1EventsPost,
  updateEventsV1EventsCalendarIdEventIdPut,
  removeEventV1EventsCalendarIdEventIdDelete,
  linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost,
  removeTaskboxesV1TaskboxesDelete,
  createRoutineFromTaskV1RoutineFromTaskTaskIdPost,
  unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch,
  updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch,
} from 'queries';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomEvent } from './MonthCalendarView';
import dayjs, { Dayjs } from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import {
  CreateTaskbox,
  DateTime,
  InCreateTaskBoxInRecurrence,
  InCreateTaskBoxInRecurrenceTarget,
  OutTaskboxDetailResponse,
  RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
  UpdateTaskbox,
  CreateFrequentWork,
  OutFrequentWork,
  OutFeatureStorage,
  UpdateTaskForTaskBox,
  CreateFrequentWorkTask,
  OutCategory,
  OutTaskboxDetailResponseForSubtask,
  CreateTaskboxTask,
  InRecurrence,
  UpdateTaskBoxRecurrence,
  OutProject,
  CreateEventWithParam,
  OutReadEvent,
  UpdateEventWithParam,
  RemoveEventV1EventsCalendarIdEventIdDeleteParams,
  InUpdateRoutineRuleOptionalTime,
  OutRoutine,
} from 'queries/model';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { dragContextAtom } from 'atoms/works';
import { nowAtom } from 'atoms/now';
import { tasksAtom } from 'atoms/tasks';
import TaskboxCreationView from '../components/TaskboxCreationView';
import { CircularProgress, Divider, IconButton, MenuItem, MenuList, Popover, TextField, Tooltip } from '@mui/material';
import { COLORS } from 'styles/constants';
import { Icons } from 'components';
import { SlotInfo } from 'react-big-calendar';
import { pick } from 'lodash';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { StaticDatePicker } from 'components/StaticDatePicker';
import { RemoveRecurrenceDialog } from 'components/Recurrence/RemoveRecurrenceDialog';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import ConfettiExplosion from 'react-confetti-explosion';
import { HighlightTimeSelectionModal } from '../components/HighlightTimeSelectionModal';
import TimeDateSelectionModal from 'components/TimeSelectionModal/TimeDateSelectionModal';
import { DateType } from '../components/TimelineTaskbox';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { focusModeTaskboxAtom, hideFocusModeTaskboxAtom } from 'atoms/focus';
import FocusModeDialog from '../components/FocusModeDialog';
import FrequentWorkDialog from '../components/TemplateDialog';
import { TimerActionType } from '..';
import { ConfirmDeleteDialog } from '../components/ConfirmDeleteDialog';
import { osName } from 'react-device-detect';
import InboxContextMenu from 'components/InboxContextMenu';
import MonthCalendarView from './MonthCalendarView';
import { sidePanelWidthWithCalendarAtom, taskViewAtom } from 'atoms/foldView';
import CategoryPopover, { CategoryActionType } from '../components/CategoryPopover';
import { DeadlinePopover } from '../components/DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import { categoryAtom } from 'atoms/category';
import {
  projectsAtom,
  fetchInstancesFromProjectDetailViewAtom,
  changedProjectIdAtom,
  selectedInstanceAtom,
  selectedProjectAtom,
  syncInstanceAtom,
} from 'atoms/projects';
import { ProjectTaskBlockAction, TaskBlock } from '../components/TaskBlockInputList';
import EventCreationPopover from 'pages/Meeting/components/EventCreationPopover';
import { CustomCreateMeetingEvent, MultiSelectSubtaskIds } from '../Today';
import ProjectListPopover from '../components/ProjectListPopover';
import { syncTaskboxDetailViewAtom, taskboxDetailViewAtom } from 'atoms/taskboxDetail';
import { fetchRoutineFromDetailViewAtom, routinesAtom, selectedRoutineAtom, selectedRoutineDateAtom } from 'atoms/routine';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const MonthCalendarViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid ${COLORS.gray200};
`;

const TaskboxCalendarContainer = styled.div`
  font-size: 12px;
  margin: 12px;
  /* width: 200px;
  height: 190px; */

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickerStaticWrapper-root {
    width: 200px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
    margin: 0;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

const TaskboxDatetimeWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  .remove-datetime-btn {
    opacity: 0;
  }

  :hover .remove-datetime-btn {
    opacity: 1;
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const FocusTaskboxBanner = styled.div`
  width: 162px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  bottom: 30px;
  box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
  padding: 8px 16px;
  border-radius: 8px;
  z-index: 10;
  cursor: pointer;
  overflow: hidden;
`;

const FocusTaskboxInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TaskboxPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-left: 25px;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

export interface TaskboxCalendarProps {
  date: Date;
  onChange?: (date: Date | null, isAllDay?: boolean) => void;
}

export type TemplateType = 'UPDATE_TEMPLATE' | 'DELETE_TEMPLATE' | 'LOAD_TEMPLATE';

const TaskboxCalendar = ({ date, onChange }: TaskboxCalendarProps) => {
  return (
    <>
      <TaskboxCalendarContainer>
        <MenuList>
          <MenuItem style={{ marginBottom: 4 }} onClick={() => onChange?.(dayjs(date).add(1, 'days').toDate(), true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Tomorrow />
            </span>
            <span style={{ fontSize: 12 }}>다음 날</span>
          </MenuItem>
          <MenuItem style={{ marginBottom: 4 }} onClick={() => onChange?.(dayjs(date).day(8).toDate(), true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Postpone stroke="#1C1B1F" fill="#1C1B1F" />
            </span>
            <span style={{ fontSize: 12 }}>다음주 월요일</span>
          </MenuItem>
          <MenuItem onClick={() => onChange?.(null, true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Later width={16} height={16} fill="#1C1B1F" />
            </span>
            <span style={{ fontSize: 12 }}>나중에</span>
          </MenuItem>
        </MenuList>
        <Divider style={{ margin: '12px 0px' }} />
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={date}
          onChange={(newValue) => onChange?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TaskboxCalendarContainer>
    </>
  );
};

interface TaskWeekProps {
  onChangeTimer?: (type: TimerActionType, time?: Date) => void;
}

export type CalendarEventsFilter = 'ALL' | 'TASK' | 'MEETING';

export const TaskMonth = ({ onChangeTimer }: TaskWeekProps) => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<OutReadEvent[]>([]);
  const [taskboxes, setTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [currentDate, setCurrentDate] = useAtom(nowAtom);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | undefined>();
  const [newTaskbox, setNewTaskbox] = useState<CreateTaskbox & { allDay?: boolean; type?: string }>();
  const [newMeeting, setNewMeeting] = useState<CreateEventWithParam & { allDay: boolean; id: string; isInvited?: boolean }>();
  const refCalendarView = useRef<HTMLDivElement>(null);
  const [selectedTaskbox, setSelectedTaskbox] = useState<OutTaskboxDetailResponse>();
  const [taskboxDetailPopover, setTaskboxDetailPopover] = useState<HTMLElement | null>(null);
  const refTaskboxInput = useRef<HTMLInputElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>(null);
  const [taskboxCalendarPopover, setTaskboxCalendarPopover] = useState<HTMLElement | null>(null);
  const [weekTasks, setWeekTasks] = useState<{ date: Date; tasks: OutTaskboxDetailResponse[] }[]>([]);
  const [taskViewDragContext] = useAtom(dragContextAtom);
  const [backlogTasks, fetchBacklogTasks] = useAtom(tasksAtom);
  const refIsAllDayDrop = useRef(false);
  const [timeSelectionPopover, setTimeSelectionPopover] = useState<HTMLElement | null>(null);
  const [simpleTimeSelectionPopover, setSimpleTimeSelectionPopover] = useState<HTMLElement | null>(null);
  const [highlightPopover, setHighlightPopover] = useState<HTMLElement | null>(null);
  const [removeRecurringTaskPopup, setRemoveRecurringTaskPopup] = useState(false);
  const [updateRecurringTaskboxParams, setUpdateRecurringTaskboxParams] =
    useState<{ eventId: string; recurrence: InRecurrence; start: DateTime | null; end: DateTime | null } | null>(null);
  const [removeRecurringEventPopup, setRemoveRecurringEventPopup] = useState(false);
  const [updateRecurringEventParams, setUpdateRecurringEventParams] = useState<OutReadEvent | null>(null);
  const [isExploding, setIsExploding] = useState(false);
  const [templateList, setTemplateList] = useState<OutFrequentWork[]>([]);
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [focusModeTaskbox, setFocusModeTaskbox] = useAtom(focusModeTaskboxAtom);
  const [hideFocusMode, setHideFocusMode] = useAtom(hideFocusModeTaskboxAtom);
  const [, setTaskView] = useAtom(taskViewAtom);
  const [categoryList, fetchCategoryList] = useAtom(categoryAtom);
  const [taskboxContextDetailPopover, setTaskboxContextDetailPopover] = useState<HTMLElement | null>(null);
  const [controlPosition, setControlPosition] = useState(false);
  const [calendarFilter, setCalendarFilter] = useState<CalendarEventsFilter>((localStorage.getItem('calendarFilter') as CalendarEventsFilter) || 'ALL');
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);
  const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
  const [fetchInstancesFromProjectDetailView, setFetchInstancesFromProjectDetailView] = useAtom(fetchInstancesFromProjectDetailViewAtom);
  const [changedProjectId, setChangedProjectId] = useAtom(changedProjectIdAtom);
  const [projects, fetchProjects] = useAtom(projectsAtom);
  const [selectedProject, setSelectedProject] = useAtom(selectedProjectAtom);
  const [selectedInstance, setSelectedInstance] = useAtom(selectedInstanceAtom);
  const [sidePanelWidth] = useAtom(sidePanelWidthWithCalendarAtom);
  const [monthDates, setMonthDates] = useState<string[]>([]);
  const [taskboxDetailView, setTaskboxDetailView] = useAtom(taskboxDetailViewAtom);
  const [syncTaskboxDetailView, setSyncTaskboxDetailView] = useAtom(syncTaskboxDetailViewAtom);
  const [routines, fetchRoutines] = useAtom(routinesAtom);
  const [selectedRoutine, setSelectedRoutine] = useAtom(selectedRoutineAtom);
  const [fetchRoutineFromDetailView, setFetchRoutineFromDetailView] = useAtom(fetchRoutineFromDetailViewAtom);
  const [, setSelectedRoutineDate] = useAtom(selectedRoutineDateAtom);

  const refresh = useCallback(
    async (isRefresh?: boolean) => {
      setMeetings([]);
      setTaskboxes([]);
      setTemplateList([]);
      setLoading(true);
      await fetchMeetings(isRefresh);
      await fetchTaskboxes();
      await fetchTemplates();
      await fetchCategoryList();
      setLoading(false);
    },
    [currentDate],
  );

  useClickOutside(
    refCalendarView,
    () => {
      const active = document.activeElement as HTMLElement;
      if (active !== document.body) return;
      if (newTaskbox) {
        setTimeout(() => {
          setNewTaskbox(undefined);
        }, 100);
      }
      if (newMeeting && !meetingAnchorEl) {
        setNewMeeting(undefined);
      }
    },
    ['mouseup'],
  );

  useEffect(() => {
    refresh();
    localStorage.setItem('task-view', 'month');
    setTaskView('month');
  }, []);

  useEffect(() => {
    handleControlPosition();
  }, [taskboxDetailPopover]);

  useEffect(() => {
    const start = dayjs(currentDate).startOf('month').startOf('week');
    const end = dayjs(currentDate).endOf('month').endOf('week');
    const dates = [];
    for (let i = start; i.isBefore(end); i = i.add(1, 'day')) {
      dates.push(i.format(DATE_FORMAT_4));
    }
    setMonthDates(dates);
  }, [currentDate]);

  useUpdateEffect(() => {
    const fetchData = async () => {
      await fetchMeetings();
      await fetchTaskboxes();
    };

    fetchData();
  }, [backlogTasks]);

  useUpdateEffect(() => {
    refresh();
  }, [currentDate]);

  useUpdateEffect(() => {
    if (!fetchInstancesFromProjectDetailView) return;
    if (taskboxes.filter((v) => v.project && v.project.id === changedProjectId).length !== 0) {
      fetchTaskboxes();
    }
    setChangedProjectId(null);
    setFetchInstancesFromProjectDetailView(false);
  }, [fetchInstancesFromProjectDetailView]);

  useUpdateEffect(() => {
    if (!fetchRoutineFromDetailView) return;
    fetchTaskboxes();

    setFetchRoutineFromDetailView(false);
  }, [fetchRoutineFromDetailView]);

  useUpdateEffect(() => {
    if (!selectedTaskbox) return;
    if (!syncTaskboxDetailView) return;
    if (syncTaskboxDetailView.syncType === 'detail') {
      const { syncType, ...others } = syncTaskboxDetailView;
      if (selectedTaskbox.id === syncTaskboxDetailView?.id) setSelectedTaskbox({ ...others });
    }
  }, [syncTaskboxDetailView]);

  useKeyboardEvent(
    true,
    (ev) => {
      const element = document.activeElement as HTMLElement;
      if (!element) return;

      if (
        element.tagName === 'TEXTAREA' ||
        (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true') ||
        element.classList.contains('MuiPaper-root')
      ) {
        return;
      }

      if (ev.shiftKey && ev.code === 'KeyF') {
        if (hideFocusMode) {
          setHideFocusMode(false);
          return;
        }
      }

      if (ev.code === 'KeyF') {
        if (!selectedTaskbox) return;
        if (!taskboxDetailPopover) return;
        handleClickFocusMode(selectedTaskbox);
      }

      // 일간뷰로 이동 단축키
      if (ev.code === 'KeyD') {
        navigate('/task/today');
      }

      // 주간뷰로 이동 단축키
      if (ev.code === 'KeyW') {
        navigate('/task/week');
      }

      // 저번달 일지로 이동 단축키
      if (ev.code === 'ArrowLeft') {
        if (taskboxDetailPopover) return;
        setCurrentDate(dayjs(currentDate).subtract(1, 'month').toDate());
      }

      // 다음달 일지로 이동 단축키
      if (ev.code === 'ArrowRight') {
        if (taskboxDetailPopover) return;
        setCurrentDate(dayjs(currentDate).add(1, 'month').toDate());
      }

      // 오늘로 가기 단축키
      if (ev.code === 'KeyT' && ev.shiftKey && !ev.altKey && !ev.ctrlKey && !ev.metaKey) {
        if (taskboxDetailPopover) return;
        const childElement = element.childNodes[0] as HTMLElement;
        if (childElement && childElement.classList && childElement.classList.contains('deadline-popover')) return;
        setCurrentDate(new Date());
      }

      // 날짜 및 시간 지정 popover 열기 단축키
      if (ev.code === 'KeyT' && !ev.altKey && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
        if (!selectedTaskbox) return;
        if (!taskboxDetailPopover) return;
        const childElement = element.childNodes[0] as HTMLElement;
        if (childElement && childElement.classList && childElement.classList.contains('deadline-popover')) return;
        const el = document.querySelector(`[data-timeselectionid="${selectedTaskbox.id}"]`) as HTMLDivElement;
        if (el) {
          setTimeSelectionPopover(el);
        }
      }

      if ((osName === 'Windows' && ev.code === 'Delete') || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey)) {
        if (!selectedTaskbox) return;
        if (!taskboxDetailPopover) return;
        setConfirmDelete(true);
      }

      if ((ev.metaKey || ev.ctrlKey) && !ev.altKey && !ev.shiftKey) {
        if (!element) return;
        if (
          element.tagName === 'TEXTAREA' ||
          (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
          (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        ) {
          return;
        }
        setIsOptionPressed(true);
      }
    },
    [],
    { event: 'keydown', eventOptions: { passive: true } },
  );

  const calendarEvents = useMemo(() => {
    const meetingEvents: CustomEvent[] = meetings.map((item: OutReadEvent) => ({
      id: item.id || '',
      title: item.summary || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime, { utc: true }).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime, { utc: true }).toDate(),
      data: item,
      type: 'meeting',
      allDay: item.allDay,
      calendarColor: item.calendarColor,
      category: item.category,
    }));
    const taskboxEvents: CustomEvent[] = taskboxes.map((item: OutTaskboxDetailResponse) => ({
      id: item.id || '',
      title: item.title || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime, { utc: true }).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime, { utc: true }).toDate(),
      data: item.tasks,
      type: 'task',
      done: item.done,
      lockedIn: item.lockedIn,
      focus: item.focus,
      allDay: item.allDay,
      isRecurrence: item.isRecurrence || !!item.routine,
      isProject: !!item.project,
      category: item.category,
    }));
    const newTaskboxEvent: CustomEvent[] =
      newTaskbox && newTaskbox.id
        ? [
            {
              id: newTaskbox.id,
              title: newTaskbox.title || '',
              start: newTaskbox.start?.date ? dayjs(newTaskbox.start?.date).toDate() : dayjs(newTaskbox.start?.datetime, { utc: true }).toDate(),
              end: newTaskbox.end?.date ? dayjs(newTaskbox.end?.date).toDate() : dayjs(newTaskbox.end?.datetime, { utc: true }).toDate(),
              type: 'task',
              allDay: newTaskbox.allDay,
              category: newTaskbox.categoryId && newTaskbox.categoryId.length > 0 ? categoryList.find((v) => v.id === newTaskbox.categoryId![0]) : null,
            },
          ]
        : [];
    const newMeetingEvent: CustomCreateMeetingEvent[] = newMeeting
      ? [
          {
            id: newMeeting.id,
            title: newMeeting.summary || '',
            start: newMeeting.start?.date ? dayjs(newMeeting.start?.date).toDate() : dayjs(newMeeting.start?.datetime, { utc: true }).toDate(),
            end: newMeeting.end?.date ? dayjs(newMeeting.end?.date).toDate() : dayjs(newMeeting.end?.datetime, { utc: true }).toDate(),
            type: 'meeting',
            attendees: newMeeting.attendees || [],
            location: '',
            description: '',
            transparency: 'transparent',
            visibility: 'default',
            allDay: Boolean(newMeeting.allDay),
            eventType: newMeeting.eventType,
            sendUpdate: false,
          },
        ]
      : [];
    return [...taskboxEvents, ...meetingEvents, ...newTaskboxEvent, ...newMeetingEvent];
  }, [meetings, taskboxes, newTaskbox, newMeeting]);

  const fetchMeetings = async (isRefresh?: boolean) => {
    const meetingList = await getEventsV1EventsGet({
      startTime: dayjs(currentDate).startOf('month').subtract(1, 'week').format(DATE_FORMAT_1),
      endTime: dayjs(currentDate).endOf('month').add(1, 'week').format(DATE_FORMAT_1),
      refresh: isRefresh,
    });

    setMeetings(meetingList.data || []);
  };

  const fetchTaskboxes = async () => {
    const start = dayjs(currentDate).startOf('month').subtract(1, 'week').format(DATE_FORMAT_4);
    const end = dayjs(currentDate).endOf('month').add(1, 'week').format(DATE_FORMAT_4);

    const taskboxList = await readTaskboxesV1TaskboxesGet({ start_date: start, end_date: end });
    setTaskboxes(taskboxList);
    if (selectedTaskbox) {
      const foundTaskbox = taskboxList.find((item) => item.id === selectedTaskbox.id);
      if (foundTaskbox) setSelectedTaskbox(foundTaskbox);
    }

    if (focusModeTaskbox) {
      const foundTaskbox = taskboxList.find((item) => item.id === focusModeTaskbox.id);
      if (foundTaskbox) setFocusModeTaskbox(foundTaskbox);
    }
  };

  const fetchTemplates = async () => {
    const data: OutFrequentWork[] = await readFrequentWorksV1FrequentworksGet(undefined);
    setTemplateList(data);
  };

  const handleRefresh = (isRefresh?: boolean) => refresh(isRefresh);

  const [selectedMeeting, setSelectedMeeting] =
    useState<(OutReadEvent & { googleCalendarId?: string; sendUpdate?: boolean; isInvited?: boolean; isUpdated?: boolean }) | undefined>();
  const [originGoogleCalendarId, setOriginGoogleCalendarId] = useState<string | null>(null);

  const handleSelectEvent = async (eventId: string) => {
    const meeting = meetings.find((item) => item.id === eventId);
    if (meeting) {
      setSelectedMeeting(meeting);
      setOriginGoogleCalendarId(meeting.googleCalendarId || null);
      const el = document.querySelector(`[data-id="${eventId}"]`) as HTMLElement;
      setMeetingAnchorEl(el);
      return;
    }

    const event = calendarEvents.find((item) => item.id === eventId);
    if (!event) return;

    const taskbox = taskboxes.find((item) => item.id === event?.id);
    if (!taskbox) return;

    setSelectedEvent(event || undefined);
    setSelectedTaskbox(taskbox);
    const el = document.querySelector(`[data-id="${eventId}"]`) as HTMLElement;

    setTaskboxDetailPopover(el.parentElement);
    if (selectedProject) {
      setSelectedInstance(taskbox);
    }
  };

  const handleUpdateEvent = async ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => {
    if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가
    const isBefore = dayjs(startTime, { utc: true }).isBefore(dayjs(), 'date');
    const taskbox = taskboxes.find((item) => item.id === eventId);
    if (taskbox) {
      const start =
        dayjs(endTime, { utc: true }).diff(dayjs(startTime, { utc: true }), 'day') > 1 || dayjs(taskbox?.end?.date).diff(dayjs(taskbox?.start?.date), 'day') > 1
          ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) }
          : isAllDay
          ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) }
          : { datetime: startTime };
      const end =
        dayjs(endTime, { utc: true }).diff(dayjs(startTime, { utc: true }), 'day') > 1 || dayjs(taskbox?.end?.date).diff(dayjs(taskbox?.start?.date), 'day') > 1
          ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) }
          : isAllDay
          ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) }
          : { datetime: endTime };

      if (taskbox.project && start.date && end.date) {
        const sameProjectInstance = taskboxes
          .filter((item) => item.matchDate?.includes(dayjs(start.date).format(DATE_FORMAT_4)))
          .find((item) => item.project?.id === taskbox.project?.id && item.allDay);
        if (sameProjectInstance) {
          try {
            await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(sameProjectInstance.id!, { taskIds: [taskbox.id!] });
            toast.success('해당 날짜에 동일한 프로젝트의 인스턴스가 존재해 하나로 합쳤어요');
          } catch (error) {
            toast.error('인스턴스를 수정할 수 없습니다.');
          }
        } else {
          try {
            isBefore
              ? await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end, done: true, tasks: taskbox.tasks as UpdateTaskForTaskBox[] })
              : await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end, tasks: taskbox.tasks as UpdateTaskForTaskBox[] });
            toast.success('인스턴스를 수정하였습니다.');
          } catch (error) {
            toast.error('인스턴스를 수정할 수 없습니다.');
          }
        }
        fetchTaskboxes();
        setTaskboxDetailPopover(null);
        return;
      }

      if (newTaskbox && newTaskbox.id === eventId) {
        setNewTaskbox({ ...newTaskbox, start, end, allDay: isAllDay });
        return;
      }

      if (taskbox?.isRecurrence) {
        setUpdateRecurringTaskboxParams({
          eventId: taskbox.id!,
          recurrence: taskbox.recurrence as InRecurrence,
          start: { datetime: startTime },
          end: { datetime: endTime },
        });
      } else {
        setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...taskbox, start, end, allDay: isAllDay } : item)));
        isBefore
          ? await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end, done: true, tasks: taskbox.tasks as UpdateTaskForTaskBox[] })
          : await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end, tasks: taskbox.tasks as UpdateTaskForTaskBox[] });

        taskbox.project?.id ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
        fetchTaskboxes();
        setTaskboxDetailPopover(null);
      }
    }

    const meeting = meetings.find((item) => item.id === eventId);
    if (meeting) {
      const start =
        dayjs(endTime, { utc: true }).diff(dayjs(startTime, { utc: true }), 'day') > 1 || dayjs(meeting?.end?.date).diff(dayjs(meeting?.start?.date), 'day') > 1
          ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) }
          : isAllDay
          ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) }
          : { datetime: startTime };
      const end =
        dayjs(endTime, { utc: true }).diff(dayjs(startTime, { utc: true }), 'day') > 1 || dayjs(meeting?.end?.date).diff(dayjs(meeting?.start?.date), 'day') > 1
          ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) }
          : isAllDay
          ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) }
          : { datetime: endTime };

      if (meeting) {
        if (!meeting.canModify) {
          toast.error('수정 권한이 없어 일정을 수정할 수 없습니다.');
          return;
        }

        if (meeting.recurringEventId) {
          setUpdateRecurringEventParams({
            ...meeting,
            start: { datetime: startTime },
            end: { datetime: endTime },
          });
        } else {
          setMeetings(meetings.map((item) => (item.id === meeting.id ? { ...meeting, start, end } : item)));
          const { googleCalendarId, ...others } = meeting;
          const updatedMeeting = {
            ...others,
            start,
            end,
          };

          try {
            await updateEventsV1EventsCalendarIdEventIdPut(meeting.googleCalendarId, eventId, updatedMeeting as UpdateEventWithParam);
            await fetchMeetings();
            toast.success('일정을 수정하였습니다.');
          } catch (error) {
            toast.error('일정을 수정하는데 실패하였습니다.');
          }
        }
      }
    }
  };

  const handleUpdateEventSchedule = async (
    startTime: string,
    endTime: string,
    timeDiff?: number,
    eventId?: string,
    isAllDay?: boolean,
    taskboxRecurrence?: InUpdateRoutineRuleOptionalTime,
  ) => {
    // if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가
    const start = selectedTaskbox?.start;
    const end = selectedTaskbox?.end;

    // 마지막 항목이 비어있다면 삭제
    const tasks = (selectedTaskbox?.tasks as UpdateTaskForTaskBox[]) || [];
    if (tasks.length > 0) {
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].content === '') tasks.pop();
        else break;
      }
    }

    const { category, ...selectedTaskboxWithoutCategory } = selectedTaskbox || {};

    await updateTaskboxV1TaskboxesTaskboxIdPut(eventId!, {
      ...selectedTaskboxWithoutCategory,
      start,
      end,
      tasks,
      categoryId: selectedTaskbox?.category ? [selectedTaskbox?.category.id] : null,
    });

    if (taskboxRecurrence && !selectedTaskbox?.routine) {
      await createRoutineFromTaskV1RoutineFromTaskTaskIdPost(eventId!, taskboxRecurrence);
      // if (selectedTaskbox?.isRecurrence)
      //   await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(eventId!, {
      //     ...taskboxRecurrence,
      //     isCancelled: taskboxRecurrence.recurrence.interval === 0,
      //   } as UpdateTaskBoxRecurrence);
      // else await createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost(eventId!, taskboxRecurrence);
      await new Promise((resolve) => setTimeout(resolve, 100));
      fetchTaskboxes();
    } else {
      const datetime = isAllDay
        ? {
            start: {
              date: dayjs(start?.date || start?.datetime, { utc: true }).format(DATE_FORMAT_4),
            },
            end: {
              date: dayjs(start?.date || start?.datetime, { utc: true })
                .add(1, 'day')
                .format(DATE_FORMAT_4),
            },
            durationMin: timeDiff,
            beforeId: null,
          }
        : dayjs(endTime, { utc: true }).diff(dayjs(startTime), 'day') > 0
        ? {
            start: { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) },
            end: { date: dayjs(endTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) },
            beforeId: null,
          }
        : {
            start: { datetime: dayjs(startTime, { utc: true }).format(DATE_FORMAT_1) },
            end: { datetime: dayjs(endTime, { utc: true }).format(DATE_FORMAT_1) },
          };
      await updateTaskboxV1TaskboxesTaskboxIdPut(eventId!, datetime);
      fetchTaskboxes();
    }
    selectedTaskbox?.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
    setTaskboxDetailPopover(null);
  };

  const handleUpdateEventTitle = async ({ eventId, title }: { eventId: string; title: string; isAllDay: boolean }) => {
    if (newTaskbox && newTaskbox.id === eventId) {
      if (!title) return;

      const result = await createTaskboxV1TaskboxesPost({
        ...newTaskbox,
        id: newTaskbox.id || uuidv4(),
        title: title,
        done: dayjs(newTaskbox.start?.date || newTaskbox.start?.datetime).isBefore(dayjs(), 'date'),
      });
      if (result) {
        fetchTaskboxes();
        setNewTaskbox(undefined);
        toast.success('새로운 태스크박스를 생성하였습니다.');
      }
    } else {
      const taskbox = taskboxes.find((item) => item.id === eventId);
      if (!taskbox) return;
      if (title === taskbox.title) return;

      await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
        ...pick(taskbox, ['lockedIn', 'start', 'end']),
        title,
        tasks: taskbox.tasks as UpdateTaskForTaskBox[],
      });
      fetchTaskboxes();
      taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
    }
  };

  const [meetingAnchorEl, setMeetingAnchorEl] = useState<HTMLElement | null>(null);
  const [isOptionPressed, setIsOptionPressed] = useState(false);

  useEffect(() => {
    if (isOptionPressed) {
      setTimeout(() => {
        setIsOptionPressed(false);
      }, 1500);
    }
  }, [isOptionPressed]);

  const handleClickTimeSlot = async ({
    action,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: SlotInfo['action'];
    bounds: SlotInfo['bounds'];
    box: SlotInfo['box'];
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => {
    if (action === 'select' || action === 'click') {
      const id = uuidv4();
      if (isOptionPressed) {
        setNewMeeting((prevMeeting) => {
          if (prevMeeting)
            return {
              ...prevMeeting,
              id: id,
              summary: '',
              start: isAllDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
              end: isAllDay ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: endTime },
              allDay: isAllDay,
              eventType: 'default',
            };
          else
            return {
              id: id,
              summary: '',
              start: isAllDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
              end: isAllDay ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: endTime },
              allDay: isAllDay,
              eventType: 'default',
            };
        });

        setTimeout(() => {
          const el = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
          setIsOptionPressed(false);
        }, 100);
        return;
      }
      setNewTaskbox((prevTaskbox) => {
        if (prevTaskbox)
          return {
            ...prevTaskbox,
            id: id,
            start: isAllDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
            end: isAllDay ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: endTime },
            allDay: isAllDay,
          };
        else
          return {
            id: id,
            title: '',
            tasks: [],
            start: isAllDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
            end: isAllDay ? { date: dayjs(endTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: endTime },
            allDay: isAllDay,
          };
      });
    } else {
      setNewTaskbox(undefined);
    }
  };

  const handleCloseTaskboxCreationPopover = () => {
    const active = document.activeElement as HTMLElement;
    active.blur();
    setNewTaskbox(undefined);
    setTaskboxDetailPopover(null);
    setSelectedEvent(undefined);
    setSelectedTaskbox(undefined);
  };

  const handleChangeDetailTaskbox = async (taskbox: any | undefined) => {
    setSelectedTaskbox(taskbox as OutTaskboxDetailResponse);
    if (taskboxDetailView) setSyncTaskboxDetailView({ ...taskbox, syncType: 'taskbox' });

    if (taskbox?.done) {
      setIsExploding(true);
    }
  };

  const handleCloseTaskboxDetailPopover = async () => {
    if (!selectedTaskbox) return;

    const { memo, category, ...updateSelectedTaskbox } = selectedTaskbox;
    const title = selectedTaskbox.project && selectedTaskbox.title === selectedTaskbox.project.title ? '' : selectedTaskbox.title;
    const start = selectedTaskbox.start;
    const end = selectedTaskbox.end;
    const categoryId = selectedTaskbox.category ? [selectedTaskbox.category.id] : [];

    // 마지막 항목이 비어있다면 삭제
    const tasks =
      (selectedTaskbox!.tasks!.map((task) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    if (tasks.length > 0) {
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].content === '') tasks.pop();
        else break;
      }
    }

    if (selectedTaskbox.project) {
      await updateProjectV1ProjectProjectIdPatch(selectedTaskbox.project.id!, {
        categoryIds: category ? [category.id!] : [],
        due: selectedTaskbox.deadline,
      });
      fetchProjects();
    }

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...updateSelectedTaskbox, title, tasks, start, end, categoryId });
    await fetchTaskboxes();
    await fetchBacklogTasks();
    setSelectedInstance(null);
    setSelectedProject(null);
    setSelectedRoutine(null);
    setSelectedTaskbox(undefined);
    setSelectedEvent(undefined);
    setTaskboxDetailPopover(null);
    setIsExploding(false);
    setTaskboxDetailView(null);
    setSyncTaskboxDetailView(null);
    setSelectedRoutineDate(dayjs().toDate());
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'DELETE':
          if (!selectedTaskbox) return;
          if (selectedTaskbox?.isRecurrence) {
            setRemoveRecurringTaskPopup(true);
            return;
          }
          if (selectedTaskbox.tasks && selectedTaskbox.tasks.length > 0 && selectedTaskbox.project) {
            const { memo, category, ...updateSelectedTaskbox } = selectedTaskbox;
            const categoryId = selectedTaskbox.category ? [selectedTaskbox.category.id] : [];

            // 마지막 항목이 비어있다면 삭제
            const tasks =
              (selectedTaskbox!.tasks!.map((task) => {
                return {
                  ...task,
                  content: task.content,
                  categoryId: task.category ? [task.category.id] : [],
                };
              }) as UpdateTaskForTaskBox[]) || [];

            if (tasks.length > 0) {
              for (let i = tasks.length - 1; i >= 0; i--) {
                if (tasks[i].content === '') tasks.pop();
                else break;
              }
            }

            const update = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...updateSelectedTaskbox, tasks, categoryId });
            if (update) {
              await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(selectedTaskbox.project.id, {
                taskIds: selectedTaskbox.tasks.map((task) => task.id) as string[],
              });
            }
          }
          await removeTaskboxV1TaskboxesTaskboxIdDelete(id);
          await fetchTaskboxes();
          if (selectedTaskbox.project) fetchProjects();
          setSelectedTaskbox(undefined);
          setTaskboxDetailPopover(null);
          toast.success(
            selectedTaskbox.project
              ? selectedTaskbox.tasks && selectedTaskbox.tasks.length > 0
                ? '하위업무를 프로젝트로 옮겼습니다.'
                : '선택한 인스턴스를 삭제했습니다.'
              : '태스크박스를 삭제했습니다.',
          );
          break;
        case 'TEMPLATE':
          handleCreateTemplate();
          break;
        case 'DUPLICATE':
          handleDuplicateTaskbox();
          break;
        case 'COMPLETE':
          handleCompleteTaskbox();
          break;
        case 'CATEGORY':
          handleClickCategory();
          break;
        case 'DEADLINE':
          handleClickDealine();
          break;
        case 'CONVERT_TO_PROJECT':
          handleConvertToProject();
          break;
        case 'LINK_TO_PROJECT':
          handleLinkToProject();
          break;
        case 'START_NOW':
          handleStartNow();
          break;
        case 'START_AFTER':
          handleStartAfter();
          break;
        case 'ADD_TIME':
          handleAddTime();
          break;
        case 'COMPLETE_NOW':
          handleCompleteNow();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }

    if (menu !== 'LINK_TO_PROJECT') {
      setContextMenuPopover(null);
      if (taskboxContextDetailPopover) setTaskboxContextDetailPopover(null);
    }
  };

  const handleDuplicateTaskbox = async () => {
    if (!selectedTaskbox) return;
    const newTasks = selectedTaskbox.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] })) as CreateTaskboxTask[];
    const newTaskbox: CreateTaskbox = {
      ...selectedTaskbox,
      id: uuidv4(),
      title: selectedTaskbox.title!,
      categoryId: selectedTaskbox.category ? [selectedTaskbox.category.id] : [],
      tasks: newTasks,
    };
    const success = await createTaskboxV1TaskboxesPost(newTaskbox);
    if (success) {
      await fetchTaskboxes();
      setSelectedTaskbox(undefined);
      setTaskboxDetailPopover(null);
      toast.success('태스크박스가 복제되었습니다.');
    }
  };

  const handleClickTaskboxLockIn = async () => {
    if (!selectedTaskbox) return;

    const toggleLock = !selectedTaskbox?.lockedIn;
    const result = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, {
      ...selectedTaskbox,
      lockedIn: toggleLock,
      tasks: selectedTaskbox.tasks as UpdateTaskForTaskBox[],
    });

    if (result) toggleLock ? toast.success('구글 캘린더에 할 일을 표시했습니다.') : toast.success('구글 캘린더에서 할 일을 표시 해제했습니다.');

    fetchTaskboxes();
  };

  const handleClickTaskboxFocus = async () => {
    if (!selectedTaskbox) return;

    const toggleFocus = !selectedTaskbox?.focus;
    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, {
      ...selectedTaskbox,
      focus: toggleFocus,
      tasks: selectedTaskbox.tasks as UpdateTaskForTaskBox[],
    });

    fetchTaskboxes();
  };

  const handleChangeTaskboxDate = async (date: Date | null, isAllDay = false, dateType: DateType | null = null) => {
    if (!selectedTaskbox) return;

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...selectedTaskbox, tasks: selectedTaskbox.tasks as UpdateTaskForTaskBox[] });
    const dateFormat = dayjs(date).format(DATE_FORMAT_4);
    if (date) {
      const start = isAllDay
        ? { date: dayjs(dateFormat).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dateFormat}T${dayjs(selectedTaskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = isAllDay
        ? { date: dayjs(dateFormat).add(1, 'day').format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dateFormat}T${dayjs(selectedTaskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      if (selectedTaskbox.project) {
        const targetBoardTaskboxes = await readTaskboxesV1TaskboxesGet({
          start_date: dayjs(dateFormat).format(DATE_FORMAT_4),
          end_date: dayjs(dateFormat).add(1, 'day').format(DATE_FORMAT_4),
        });

        const sameProjectInstance = targetBoardTaskboxes.find((taskbox) => taskbox.project?.id === selectedTaskbox.project?.id && taskbox.allDay);
        if (sameProjectInstance) {
          try {
            await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(sameProjectInstance.id!, { taskIds: [selectedTaskbox.id!] });
            toast.success('해당 날짜에 동일한 프로젝트의 인스턴스가 존재해 하나로 합쳤어요');
          } catch (error) {
            toast.error('인스턴스를 수정할 수 없습니다.');
          }
          await fetchTaskboxes();
          setTaskboxCalendarPopover(null);
          setTimeSelectionPopover(null);
          setSelectedTaskbox(undefined);
          setTaskboxDetailPopover(null);
          fetchBacklogTasks();
          return;
        } else {
          try {
            await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { start, end });
          } catch (e) {
            toast.error('인스턴스를 수정할 수 없습니다.');
          }
        }
      } else {
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { start, end });
        } catch (e) {
          toast.error('태스크박스를 수정할 수 없습니다.');
        }
      }

      if (dateType === 'tomorrow') {
        selectedTaskbox.project?.id ? toast.success('인스턴스를 다음 날로 옮겼습니다.') : toast.success('태스크박스를 다음 날로 옮겼습니다.');
      } else if (dateType === 'nextWeek') {
        selectedTaskbox.project?.id ? toast.success('인스터스를 다음주 월요일로 옮겼습니다.') : toast.success('태스크박스를 다음주 월요일로 옮겼습니다.');
      } else if (dateType === 'calendar') {
        selectedTaskbox.project?.id
          ? toast.success(`인스턴스를 ${dayjs(date).format('YYYY년 MM월 DD일 ddd요일')}로 옮겼습니다.`)
          : toast.success(`태스크박스를 ${dayjs(date).format('YYYY년 MM월 DD일 ddd요일')}로 옮겼습니다.`);
      }
    } else {
      await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { workSectionType: 'someday' });
      toast.success('태스크박스를 나중에로 옮겼습니다.');
    }
    await fetchTaskboxes();
    setTaskboxCalendarPopover(null);
    setTimeSelectionPopover(null);
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);
    fetchBacklogTasks();
  };

  const handleDropFromOutside = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    if (!taskViewDragContext) return;
    if (refIsAllDayDrop.current) return;

    const { id, type, title, view, data, projectId } = taskViewDragContext;
    const allDay = Math.abs(dayjs(startTime, { utc: true }).diff(endTime, 'day')) > 0 || isAllDay;
    const isBefore = dayjs(startTime, { utc: true }).isBefore(dayjs(), 'date');
    refIsAllDayDrop.current = allDay;

    if (view === 'backlog' && Array.isArray(data) && data.length > 0) {
      let result;
      const taskList = data.map((item) => ({ id: item.id, content: item.title, type: item.type }));
      const datetimeParam = {
        start: allDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
        end: allDay
          ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
          : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) },
        done: isBefore,
      };

      if (taskList.length === 1) {
        result = await updateWorkboxesV2V2WorkboxesWorkboxIdPut(taskList[0].id, datetimeParam);
      } else {
        const taskIds = taskList.filter((item) => item.type === 'task').map((item) => item.id);
        const taskboxIds = taskList.filter((item) => item.type === 'taskbox').map((item) => item.id);
        const mergedResult = await mergeWorkOrWorkBoxesV2V2WorksMergePost({ taskIds: taskIds, taskboxIds: taskboxIds });
        result = await updateWorkboxesV2V2WorkboxesWorkboxIdPut(mergedResult.id, datetimeParam);
      }

      if (result) {
        toast.success('새로운 태스크박스를 생성하였습니다.');
        fetchTaskboxes();
        fetchBacklogTasks();
      }
    } else if (view === 'side-project') {
      try {
        const result = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: '',
          start: allDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
          end: allDay
            ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
            : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) },
          projectId: id ? [id] : null,
          done: isBefore,
        });

        if (result) {
          toast.success('프로젝트의 인스턴스를 생성하였습니다.');
          fetchTaskboxes();
          fetchProjects();
        }
      } catch (e) {
        toast.error('작업을 수행할 수 없습니다.');
      }
    } else if (view === 'project-detail') {
      try {
        const create = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: '',
          start: allDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
          end: allDay
            ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
            : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) },
          projectId: projectId ? [projectId] : null,
          done: isBefore,
        });

        if (create && create.id) {
          await absorbProjectTasksV1TaskboxesTaskboxIdSubtaskFromProjectPost(create.id, {
            beforeId: null,
            taskIds: type === 'project-multi-subtask' ? data : data.map((task: any) => task.id!),
            done: isBefore,
          });
        }
        toast.success('프로젝트의 인스턴스를 생성하였습니다.');
        fetchTaskboxes();
        fetchProjects();
      } catch (e) {
        toast.error('프로젝트의 인스턴스를 생성하는데 실패하였습니다.');
      }
    } else {
      const taskbox = taskboxes.find((item) => item.id === id);
      if (taskbox) {
        const diffDay = dayjs(startTime, { utc: true }).diff(endTime, 'day');
        if (Math.abs(diffDay) > 0) return;

        const start = allDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime };
        const end = allDay
          ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
          : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) };
        const result = await updateTaskboxV1TaskboxesTaskboxIdPut(id!, { ...taskbox, start: start, end: end, tasks: taskbox.tasks as UpdateTaskForTaskBox[] });
        if (result) {
          taskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
        }
      } else {
        const result = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: title || '',
          start: allDay ? { date: dayjs(startTime, { utc: true }).format(DATE_FORMAT_4) } : { datetime: startTime },
          end: allDay
            ? { date: dayjs(startTime, { utc: true }).add(1, 'day').format(DATE_FORMAT_4) }
            : { datetime: dayjs(startTime, { utc: true }).add(60, 'minute').format(DATE_FORMAT_1) },
          issueId: type === 'issue' ? id : undefined,
        });

        if (result && result.id) {
          if (Array.isArray(data)) {
            const tasks = data.map((item) => ({ ...item, id: uuidv4(), type: 'task' }));
            await updateTaskboxV1TaskboxesTaskboxIdPut(result.id, { tasks: tasks });
            for (const item of data) {
              if (item.id) await removeTaskV1WorksWorkIdDelete(item.id);
            }
          } else {
            await removeTaskV1WorksWorkIdDelete(id!);
          }
          toast.success('새로운 태스크박스를 생성하였습니다.');
          fetchBacklogTasks();
        }
      }

      await fetchTaskboxes();
      setSelectedTaskbox(undefined);
      setTaskboxDetailPopover(null);
    }

    refIsAllDayDrop.current = false;
  };

  const handleClickContinueOnAnotherDay = async (taskbox: (CreateTaskbox & { done?: boolean; allDay?: boolean }) | undefined, date: Date) => {
    if (!selectedTaskbox) return;

    const subTasks = taskbox?.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }
    const continuationTasks = subTasks.filter((v: any) => !v.done);
    const isTodayTimeBlockedTask = !selectedTaskbox.allDay && dayjs(date).diff(selectedTaskbox?.start?.datetime, 'date') === 0;

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: subTasks.filter((item: any) => item.done), done: true });
    await createTaskboxV1TaskboxesPost({
      ...selectedTaskbox,
      id: uuidv4(),
      title: selectedTaskbox.title!,
      tasks: continuationTasks,
      start: isTodayTimeBlockedTask ? { datetime: selectedTaskbox.start?.datetime } : { date: dayjs(date).format(DATE_FORMAT_4) },
      end: isTodayTimeBlockedTask ? { datetime: selectedTaskbox.end?.datetime } : { date: dayjs(date).format(DATE_FORMAT_4) },
    });

    await fetchTaskboxes();
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);

    toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다');
  };

  const handleClickInstanceContinue = async (
    taskbox: (CreateTaskbox & { done?: boolean; allDay?: boolean; project?: OutProject }) | OutTaskboxDetailResponse | undefined,
  ) => {
    if (!selectedTaskbox) return;

    const subTasks = taskbox?.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }

    const update = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: subTasks } as UpdateTaskbox);

    if (update && update.tasks) {
      const targetProject = projects.find((project) => project.id === selectedTaskbox.project?.id);
      if (!targetProject) return;

      const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
      const index = instances.findIndex((instance) => instance.id === selectedTaskbox?.id);
      const nextInstance = instances[index + 1];
      const assignTasks = update.tasks.filter((task) => !task.done).map((task) => task.id!);

      try {
        if (nextInstance) {
          const nextInstanceTasks = nextInstance.tasks;
          await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
            beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
            tasks: assignTasks,
          });
          toast.success('미완료 업무가 프로젝트의 다음 인스턴스로 이동했습니다.');
        } else {
          await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
            taskIds: assignTasks,
          });
          toast.success('미완료 업무가 프로젝트의 미할당으로 이동했습니다.');
        }
      } catch (error) {
        toast.error('일부 완료에 실패했습니다.');
      }

      await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: update.tasks.filter((item: any) => item.done), done: true } as UpdateTaskbox);

      if (focusModeTaskbox) {
        handleCloseFocusMode();
      } else {
        await fetchTaskboxes();
        setSelectedTaskbox(undefined);
        setTaskboxDetailPopover(null);
      }
      fetchProjects();
    }
  };

  const handleClickRemoveDatetime = async () => {
    if (!selectedTaskbox) return;

    if (selectedTaskbox.isRecurrence) {
      setRemoveRecurringTaskPopup(true);
      return;
    }

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, {
      start: { date: dayjs(selectedTaskbox?.start?.datetime).format(DATE_FORMAT_4) },
      end: { date: dayjs(selectedTaskbox?.start?.datetime).add(1, 'day').format(DATE_FORMAT_4) },
      beforeId: null,
    });

    selectedTaskbox.project ? toast.success('인스턴스를 수정하였습니다.') : toast.success('태스크박스를 수정하였습니다.');
    fetchTaskboxes();
    setTaskboxDetailPopover(null);
  };

  const handleRemoveRecurrenceTask = async (params: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
    if (!selectedTaskbox) return;

    await removeTaskboxV1TaskboxesTaskboxIdDelete(selectedTaskbox.id!, params);
    toast.success('반복 업무를 삭제하였습니다.');
    fetchTaskboxes();
    setContextMenuPopover(null);
    setTaskboxDetailPopover(null);
    if (taskboxContextDetailPopover) setTaskboxContextDetailPopover(null);
    setRemoveRecurringTaskPopup(false);
  };

  const handleUpdateRecurrenceTask = async (target: InCreateTaskBoxInRecurrenceTarget) => {
    if (!updateRecurringTaskboxParams) return;
    const { eventId, recurrence, start, end } = updateRecurringTaskboxParams;
    await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(eventId, { target, recurrence, start, end } as UpdateTaskBoxRecurrence);
    toast.success('태스크박스를 수정하였습니다.');
    fetchTaskboxes();
    setUpdateRecurringTaskboxParams(null);
  };

  const handleRemoveRecurrenceEvent = async (params: RemoveEventV1EventsCalendarIdEventIdDeleteParams) => {
    if (!selectedMeeting) return;

    try {
      await removeEventV1EventsCalendarIdEventIdDelete(selectedMeeting.googleCalendarId!, selectedMeeting.id!, params);
      toast.success('반복 일정을 삭제하였습니다.');
      setRemoveRecurringEventPopup(false);
      setMeetingAnchorEl(null);
      setSelectedMeeting(undefined);
      fetchMeetings();
    } catch (e) {
      toast.error('반복 일정을 삭제할 수 없습니다.');
    }
  };

  const handleUpdateRecurrenceEvent = async (target: InCreateTaskBoxInRecurrenceTarget) => {
    if (!updateRecurringEventParams) return;
    const { googleCalendarId, ...others } = updateRecurringEventParams;
    try {
      await updateEventsV1EventsCalendarIdEventIdPut(googleCalendarId, updateRecurringEventParams.id, {
        ...(originGoogleCalendarId !== googleCalendarId ? updateRecurringEventParams : others),
        target,
      } as UpdateEventWithParam);
      await fetchMeetings();
      toast.success('일정을 수정하였습니다.');
      setUpdateRecurringEventParams(null);
      setMeetingAnchorEl(null);
      setSelectedMeeting(undefined);
    } catch (e) {
      toast.error('일정을 수정하는데 실패하였습니다');
    }
  };

  /** 종일 업무 하이라이트 지정하기 */
  const handleClickHighlightTime = async (startTime: string, endTime: string) => {
    if (!selectedTaskbox) return;
    const updateTaskbox = {
      start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
      end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
      focus: true,
    };
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, updateTaskbox);
    if (success) {
      fetchTaskboxes();
      setHighlightPopover(null);
      setTaskboxDetailPopover(null);
    }
  };

  const showingTime = (event?: OutTaskboxDetailResponse, duration?: number) => {
    // 시간과 기간이 모두 제공되지 않으면 기본 메시지를 반환
    if (!event && duration === undefined) {
      return '시간 설정';
    }

    // 이벤트 객체가 제공된 경우
    if (event?.start?.datetime && event.end?.datetime) {
      const start = dayjs(event.start.datetime);
      const end = dayjs(event.end.datetime);
      duration = end.diff(start, 'minutes'); // 기간 계산
    }

    // 기간이 제공된 경우
    if (duration !== undefined) {
      const hour = Math.floor(duration / 60);
      const minute = duration % 60;

      const durationTime = hour === 0 ? `${minute}분` : minute === 0 ? `${hour}시간 동안` : `${hour}시간 ${minute}분 동안`;

      // 이벤트 객체가 제공된 경우 시간 포맷과 결합하여 반환
      if (event?.start?.datetime) {
        const timeboxTime = `${dayjs(event.start.datetime, { utc: true }).format(TIME_FORMAT_2)}, ${durationTime}`;
        return timeboxTime;
      }

      // 이벤트 객체가 제공되지 않은 경우 기간만 반환
      return durationTime;
    }

    return '시간 설정';
  };

  const handleClickFocusModeBanner = () => {
    setHideFocusMode(false);
  };

  const handleCloseFocusModeBanner = () => {
    setHideFocusMode(false);
    setFocusModeTaskbox(null);
    // onChangeTimer && onChangeTimer('RESET');
  };

  const handleClickFocusMode = async (taskbox: OutTaskboxDetailResponse) => {
    if (!taskbox) return;
    setFocusModeTaskbox(taskbox!);
    setHideFocusMode(false);
    setTimeout(() => {
      setTaskboxDetailPopover(null);
    }, 100);

    if (taskbox.start?.datetime)
      onChangeTimer &&
        onChangeTimer(
          'RESET',
          dayjs()
            .add(dayjs(taskbox.end?.datetime, { utc: true }).diff(dayjs(taskbox.start?.datetime, { utc: true })))

            .toDate(),
        );
  };

  const handleCloseFocusMode = async (taskbox?: UpdateTaskbox | null) => {
    if (!taskbox) {
      await fetchTaskboxes();
      await new Promise((resolve) => setTimeout(resolve, 100));
      fetchBacklogTasks();
      setSelectedTaskbox(undefined);
      setFocusModeTaskbox(null);
      setHideFocusMode(false);
      return;
    }

    const typeTaskbox = taskbox as OutTaskboxDetailResponse;
    const { memo, category, ...updateTaskbox } = typeTaskbox;
    const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

    const tasks =
      (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    // 마지막 항목이 비어있다면 삭제
    if (tasks.length > 0) {
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].content === '') tasks.pop();
        else break;
      }
    }
    updateTaskbox.tasks = tasks;

    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
    if (success) {
      await fetchTaskboxes();
      await new Promise((resolve) => setTimeout(resolve, 200));
      fetchBacklogTasks();
      setSelectedTaskbox(undefined);
      setFocusModeTaskbox(null);
      setHideFocusMode(false);
    }
  };

  const handleHideFocusMode = async (taskbox: UpdateTaskbox) => {
    if (!taskbox) return;

    const typeTaskbox = taskbox as OutTaskboxDetailResponse;
    const { memo, category, ...updateTaskbox } = typeTaskbox;
    const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

    const tasks =
      (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    // 마지막 항목이 비어있다면 삭제
    if (tasks.length > 0) {
      for (let i = tasks.length - 1; i >= 0; i--) {
        if (tasks[i].content === '') tasks.pop();
        else break;
      }
    }
    updateTaskbox.tasks = tasks;

    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
    if (success) {
      fetchTaskboxes();
      await new Promise((resolve) => setTimeout(resolve, 100));
      setHideFocusMode(true);
    }
  };

  const handleDeleteFocusMode = async (taskbox: OutTaskboxDetailResponse) => {
    const success = await removeTaskboxV1TaskboxesTaskboxIdDelete(focusModeTaskbox!.id!);
    if (success) {
      await fetchTaskboxes();
      await new Promise((resolve) => setTimeout(resolve, 100));
      setFocusModeTaskbox(null);
      setHideFocusMode(false);
      setTaskboxDetailPopover(null);
      setSelectedTaskbox(undefined);
      toast.success('태스크박스를 삭제하였습니다.');
    }
  };

  const handleLoadTemplate = () => {
    setLoadTemplate(true);
  };

  const handleCreateTemplate = async () => {
    if (!selectedTaskbox) return;
    const newTemplate: CreateFrequentWork = {
      id: uuidv4(),
      title: selectedTaskbox?.title,
      tasks: selectedTaskbox!.tasks!.map((item) => ({
        ...item,
        id: uuidv4(),
        categoryId: item.category ? [item.category.id] : [],
      })) as CreateFrequentWorkTask[],
    };
    const success = await createFrequentWorkV1FrequentworksPost(newTemplate);
    if (success) {
      setSelectedTaskbox(undefined);
      setTaskboxDetailPopover(null);
      toast.success('템플릿 목록에 추가되었습니다.');
      fetchTemplates();
    }
  };

  const handleChangeTemplate = async (template: OutFrequentWork, type: TemplateType) => {
    if (!template) return;
    switch (type) {
      case 'UPDATE_TEMPLATE':
        await updateFrequentWorkV1FrequentworksFrequentWorkIdPut(template.id!, {
          ...template,
          categoryId: template.category ? [template.category.id] : [],
          tasks: template.tasks!.map((v) => ({ ...v, categoryId: v.category ? [v.category.id] : [] })),
        });
        fetchTemplates();
        break;
      case 'DELETE_TEMPLATE':
        await deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete(template.id!);
        fetchTemplates();
        toast.success('템플릿이 삭제되었습니다.');
        break;
      case 'LOAD_TEMPLATE':
        if (focusModeTaskbox && !hideFocusMode) {
          const taskboxTasks = focusModeTaskbox?.tasks || [];
          const templateTasks = template.tasks!.map((task) => ({ ...task, id: uuidv4(), categoryId: task.category ? [task.category.id] : [] }));
          setFocusModeTaskbox({ ...focusModeTaskbox, tasks: [...taskboxTasks, ...templateTasks] });
          updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, {
            ...focusModeTaskbox,
            tasks: [...taskboxTasks, ...templateTasks] as UpdateTaskForTaskBox[],
          });
        } else {
          const taskboxTasks = selectedTaskbox?.tasks || [];
          const templateTasks = template.tasks!.map((task) => ({ ...task, id: uuidv4(), categoryId: task.category ? [task.category.id] : [] }));
          setSelectedTaskbox({ ...selectedTaskbox, tasks: [...taskboxTasks, ...templateTasks] });
          updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, {
            ...selectedTaskbox,
            tasks: [...taskboxTasks, ...templateTasks] as UpdateTaskForTaskBox[],
          });
        }
        setLoadTemplate(false);
        break;
    }
  };

  const handleChangeFocusModeInprogress = async (taskbox: OutTaskboxDetailResponse, taskboxDate: Date | null, isAllDay = false) => {
    if (!taskbox) return;
    if (!taskboxDate) return;

    const start = isAllDay
      ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
      : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
    const end = isAllDay
      ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
      : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

    const doneTasks = taskbox.tasks!.filter((task) => task.done) as UpdateTaskForTaskBox[];
    const undoneTasks = taskbox
      .tasks!.filter((task) => !task.done)
      .map((task) => ({
        ...task,
        content: task.content || '',
      })) as CreateTaskboxTask[];

    const doneTaskbox: UpdateTaskbox = { tasks: doneTasks, done: true };
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, doneTaskbox);

    const continueTaskbox: CreateTaskbox = { ...taskbox, id: uuidv4(), title: taskbox.title!, tasks: undoneTasks, start, end };
    const success2 = await createTaskboxV1TaskboxesPost(continueTaskbox);

    if (success && success2) {
      handleCloseFocusMode();
      toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다.');
    }
  };

  const handleClickConfirmDelete = () => {
    handleClickContextMenu(selectedTaskbox!.id!, 'taskbox', 'DELETE');
    setConfirmDelete(false);
  };

  //태스크박스 우클릭 로직
  const handleContextMenu = async (eventId: string) => {
    const event = calendarEvents.find((item) => item.id === eventId);
    if (!event) return;

    const taskbox = taskboxes.find((item) => item.id === event?.id);
    if (!taskbox) return;

    const el = document.querySelector(`[data-id="${eventId}"]`) as HTMLElement;
    setTaskboxContextDetailPopover(el.parentElement);
    setSelectedTaskbox(taskbox);
  };

  const handleCompleteTaskbox = async () => {
    if (!selectedTaskbox) return;
    const tasks = selectedTaskbox.tasks!.map((v) => ({ ...v, done: !selectedTaskbox.done })) as UpdateTaskForTaskBox[];
    const success = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, { ...selectedTaskbox, done: !selectedTaskbox.done, tasks: tasks });
    if (success) {
      await fetchTaskboxes();
      setSelectedTaskbox(undefined);
    }
  };

  const handleControlPosition = () => {
    setControlPosition(!controlPosition);
  };

  const handleCalendarFilter = (filter: CalendarEventsFilter) => {
    if (filter !== null) {
      localStorage.setItem('calendarFilter', filter);
      setCalendarFilter(filter);
    }
  };

  //일정 생성 기능 추가 시 삭제
  const [meetingFilter, setMeetingFilter] = useState<OutFeatureStorage>();

  useEffect(() => {
    fetchCalendarFilter();
  }, []);

  const fetchCalendarFilter = async () => {
    const filter = await getAFeatureStorageByNameV1FeatureStorageNameFeatureStorageNameGet('calendar-filter');
    setMeetingFilter(filter);
  };

  const handleClickMeetingFilter = async () => {
    if (!meetingFilter) return;
    await updateFeatureStorageV1FeatureStorageFeatureStorageIdPatch(meetingFilter.id!, { ...meetingFilter, data: { click: true } });
    setMeetingFilter({ ...meetingFilter, data: { click: true } });
  };

  const handleClickCategory = () => {
    setCategoryAnchorEl(contextMenuPopover);
    setContextMenuPopover(null);
  };

  const handleClickDealine = () => {
    setDeadlineAnchorEl(contextMenuPopover);
    setContextMenuPopover(null);
  };

  const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType) => {
    switch (action) {
      case 'CREATE':
        {
          if (!category) return;
          const success = await createCategoryV1CategoryPost(category);
          if (success) {
            fetchCategoryList();
          }
        }
        break;
      case 'SELECT':
        {
          setCategoryAnchorEl(null);
          if (newTaskbox) setNewTaskbox({ ...newTaskbox, categoryId: category ? [category.id] : [] });
          else setSelectedTaskbox({ ...selectedTaskbox, category: category });
          if (taskboxDetailView) {
            setSyncTaskboxDetailView({ ...taskboxDetailView, category: category, syncType: 'taskbox' });
          }
        }
        break;
      case 'UPDATE':
        {
          if (!category) return;
          const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
          if (success) {
            const updatedTasks = selectedTaskbox?.tasks?.map((task) => {
              if (task.category?.id === category?.id) {
                return { ...task, category: category! };
              }
              return task;
            });
            setSelectedTaskbox({
              ...selectedTaskbox,
              category: selectedTaskbox?.category && selectedTaskbox.category.id === category.id ? category : selectedTaskbox?.category,
              tasks: updatedTasks,
            });
            fetchCategoryList();
          }
        }
        break;
      case 'DELETE':
        {
          if (!category) return;
          await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
          if (selectedTaskbox?.category?.id === category.id) {
            const updatedTasks = selectedTaskbox?.tasks?.map((task) => {
              if (task.category?.id === category?.id) {
                return { ...task, category: undefined };
              }
              return task;
            });
            setSelectedTaskbox({ ...selectedTaskbox, category: null, tasks: updatedTasks });
            setCategoryAnchorEl(null);
          }
          fetchCategoryList();
        }
        break;
    }
  };

  const handleChangeDeadline = async (deadlineDate: Dayjs | null) => {
    setSelectedTaskbox({ ...selectedTaskbox, deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null });
    if (focusModeTaskbox) setFocusModeTaskbox({ ...focusModeTaskbox!, deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null });
    setDeadlineAnchorEl(null);
    if (taskboxDetailView)
      setSyncTaskboxDetailView({
        ...selectedTaskbox,
        deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null,
        syncType: 'taskbox',
      });
  };

  const handleFetchFocusMode = async () => {
    fetchTaskboxes();
    if (focusModeTaskbox) {
      const focus = taskboxes.find((taskbox) => taskbox.id === focusModeTaskbox.id);
      setFocusModeTaskbox(focus!);
    }
  };

  useEffect(() => {
    if (selectedProject) {
      const project = projects.find((project) => project.id === selectedProject.id);
      if (project) {
        setSelectedProject(project);
      }
    }
  }, [projects]);

  const handleClickInstanceTitle = async (taskbox: CreateTaskbox & { project?: OutProject }) => {
    if (!selectedTaskbox) return;
    // 프로젝트 디테일창 열기 전에 태스크박스 수정사항 저장
    const { memo, category, ...updateSelectedTaskbox } = selectedTaskbox;
    const start = selectedTaskbox.start;
    const end = selectedTaskbox.end;
    const categoryId = selectedTaskbox.category ? [selectedTaskbox.category.id] : [];

    const tasks =
      (selectedTaskbox!.tasks!.map((task) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...updateSelectedTaskbox, tasks, start, end, categoryId });

    const targetProject = projects.find((project) => project.id === taskbox.project!.id);

    if (targetProject) {
      const targetInstance = targetProject.tasks.find((task) => task.id === taskbox?.id);
      targetInstance!.tasks = taskbox?.tasks;
      setSelectedProject(targetProject);
      setSelectedInstance(taskbox as OutTaskboxDetailResponse);
    }
  };

  const handleClickRoutineTitle = async (taskbox: CreateTaskbox & { routine?: OutRoutine }) => {
    if (!selectedTaskbox) return;

    const { memo, category, ...updateSelectedTaskbox } = selectedTaskbox;
    const start = selectedTaskbox.start;
    const end = selectedTaskbox.end;
    const categoryId = selectedTaskbox.category ? [selectedTaskbox.category.id] : [];

    const tasks =
      (selectedTaskbox!.tasks!.map((task) => {
        return {
          ...task,
          content: task.content,
          categoryId: task.category ? [task.category.id] : [],
        };
      }) as UpdateTaskForTaskBox[]) || [];

    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...updateSelectedTaskbox, tasks, start, end, categoryId });
    fetchRoutines();
    const targetRoutine = routines.find((routine) => routine.id === taskbox.routine!.id);
    if (targetRoutine) {
      setSelectedRoutine(targetRoutine);
      setSelectedRoutineDate(dayjs(selectedTaskbox.start?.date || selectedTaskbox.start?.datetime, { utc: true }).toDate());
    }
  };

  const handleCloseProjectDetail = () => {
    fetchProjects();
    fetchTaskboxes();
  };

  const handleDeleteProject = async (id: string) => {
    try {
      await deleteProjectV1ProjectProjectIdDelete(id);
      toast.success('프로젝트를 삭제하였습니다.');
      setTaskboxDetailPopover(null);
      setSelectedTaskbox(undefined);
      setFocusModeTaskbox(null);
      handleCloseProjectDetail();
    } catch (e) {
      toast.error('프로젝트를 삭제할 수 없습니다.');
    }
  };

  const handleChangeInstanceSubtask = async (block: TaskBlock, action: ProjectTaskBlockAction) => {
    if (!selectedTaskbox) return;

    switch (action) {
      case 'MOVE_TO_AFTER': {
        const targetProject = projects.find((project) => project.id === selectedTaskbox.project?.id);
        if (!targetProject) return;

        const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
        const index = instances.findIndex((instance) => instance.id === selectedTaskbox.id);
        const nextInstance = instances[index + 1];

        const taskExistsInTaskbox = selectedTaskbox.tasks?.some((task) => task.id === block.id);

        const assignTaskToNextInstance = async () => {
          if (nextInstance) {
            const nextInstanceTasks = nextInstance.tasks;
            await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
              beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
              tasks: [block.id],
            });
            toast.success('프로젝트의 다음 인스턴스로 이동했습니다.');
          } else {
            await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
              taskIds: [block.id],
            });
            toast.success('프로젝트의 미할당으로 이동했습니다.');
          }
        };

        try {
          if (taskExistsInTaskbox) {
            await assignTaskToNextInstance();
          } else {
            const create = await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(selectedTaskbox.id!, {
              id: block.id,
              content: block.content!,
            });

            if (create) {
              await assignTaskToNextInstance();
            }
          }
          fetchProjects();
        } catch (error) {
          toast.error('일부 완료에 실패했습니다.');
        }
      }
    }
  };

  const handleConvertToProject = async () => {
    if (selectedTaskbox && selectedTaskbox.id) await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(selectedTaskbox.id);
    await fetchTaskboxes();
    fetchProjects();
    setTaskboxDetailPopover(null);
    setSelectedTaskbox(undefined);
    if (projects.filter((project) => project.place === 'DESK').length >= 7) {
      toast.success('데스크가 가득 차서 서랍에 프로젝트를 생성했습니다.');
    } else {
      toast.success('태스크박스를 프로젝트로 전환했습니다.');
    }
  };

  const [projectListAnchorEl, setProjectListAnchorEl] = useState<HTMLElement | null>(null);
  const handleLinkToProject = async () => {
    const el = document.querySelector('.link-project') as HTMLElement;
    setProjectListAnchorEl(el);
  };

  const handleStartNow = async () => {
    if (!selectedTaskbox) return;
    setTaskboxDetailPopover(null);
    setSelectedTaskbox(undefined);

    const timeDiff = dayjs(selectedTaskbox?.end?.datetime).diff(dayjs(selectedTaskbox?.start?.datetime), 'minute');

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, {
        start: { datetime: dayjs().format(DATE_FORMAT_1) },
        end: { datetime: !timeDiff ? dayjs().add(1, 'hour').format(DATE_FORMAT_1) : dayjs().add(timeDiff, 'minute').format(DATE_FORMAT_1) },
      });
    } catch (e) {
      toast.error('지금 시작하기를 할 수 없습니다.');
    }
    await fetchTaskboxes();
  };

  const handleStartAfter = async () => {
    if (!selectedTaskbox) return;
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);

    const timeDiff = dayjs(selectedTaskbox?.end?.datetime).diff(dayjs(selectedTaskbox?.start?.datetime), 'minute');
    const index = taskboxes.findIndex((item) => item.id === selectedTaskbox?.id);
    const beforeTaskbox = taskboxes[index - 1];

    try {
      if (beforeTaskbox) {
        await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, {
          start: { datetime: dayjs(beforeTaskbox.end?.datetime, { utc: true }).format(DATE_FORMAT_1) },
          end: { datetime: dayjs(beforeTaskbox.end?.datetime, { utc: true }).add(timeDiff, 'minute').format(DATE_FORMAT_1) },
        });
      }
    } catch (e) {
      toast.error('이어서 시작하기를 할 수 없습니다.');
    }
    await fetchTaskboxes();
  };

  const handleAddTime = async () => {
    if (!selectedTaskbox) return;
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, {
        start: { datetime: dayjs(selectedTaskbox.start?.datetime, { utc: true }).format(DATE_FORMAT_1) },
        end: { datetime: dayjs(selectedTaskbox.end?.datetime, { utc: true }).add(30, 'minute').format(DATE_FORMAT_1) },
      });
    } catch (e) {
      toast.error('시간을 추가할 수 없습니다.');
    }
    await fetchTaskboxes();
  };

  const handleCompleteNow = async () => {
    if (!selectedTaskbox) return;
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);

    try {
      await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, {
        start: { datetime: dayjs(selectedTaskbox.start?.datetime, { utc: true }).format(DATE_FORMAT_1) },
        end: { datetime: dayjs().format(DATE_FORMAT_1) },
        done: true,
      });
    } catch (e) {
      toast.error('지금 완료할 수 없습니다.');
    }
    await fetchTaskboxes();
  };

  const handlePushProjectRecommendTask = async (blocks: TaskBlock[], blockId: string, index: number) => {
    if (!selectedTaskbox) return;
    const updateBlocks = blocks.map((block) => ({ ...block, content: block.content }));
    await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox!.id!, { tasks: updateBlocks as UpdateTaskForTaskBox[] });
    await assignTasksV1TaskboxesTaskboxIdAssignPost(selectedTaskbox!.id!, { tasks: [blockId], beforeId: blocks[index - 1]?.id });
    fetchProjects();
  };

  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const handleChangeInviteDialog = async () => {
    setOpenInviteDialog(false);
  };

  const handleCloseMeetingPopover = async () => {
    if (newMeeting) {
      const endDate = newMeeting?.end?.date ? dayjs(newMeeting.end.date, { utc: true }).subtract(1, 'day') : dayjs(newMeeting?.end?.datetime, { utc: true });
      const isBefore = endDate.isBefore(dayjs(), 'date');
      if (newMeeting.summary !== '' && !newMeeting.isInvited && newMeeting.attendees && newMeeting.attendees?.length > 0 && !openInviteDialog && !isBefore) {
        setOpenInviteDialog(true);
        return;
      }
      setMeetingAnchorEl(null);

      if (newMeeting.summary === '') {
        setNewMeeting(undefined);
        return;
      }

      try {
        setNewMeeting(undefined);
        await createEventV1EventsPost(newMeeting);
        fetchMeetings();
        toast.success('새로운 일정을 생성하였습니다.');
      } catch (error) {
        setNewMeeting(undefined);
        toast.error('새로운 일정을 생성하는데 실패하였습니다.');
      } finally {
        setOpenInviteDialog(false);
      }
      return;
    }

    if (selectedMeeting) {
      if (selectedMeeting.recurringEventId && selectedMeeting.isUpdated) {
        setUpdateRecurringEventParams({
          ...selectedMeeting,
        });
        return;
      }
      const endDate = selectedMeeting?.end?.date
        ? dayjs(selectedMeeting.end.date, { utc: true }).subtract(1, 'day')
        : dayjs(selectedMeeting?.end?.datetime, { utc: true });
      const isBefore = endDate.isBefore(dayjs(), 'date');
      if (
        selectedMeeting.summary !== '' &&
        !selectedMeeting.isInvited &&
        !openInviteDialog &&
        selectedMeeting.isUpdated &&
        selectedMeeting.attendees &&
        selectedMeeting.attendees?.length > 0 &&
        !isBefore
      ) {
        setOpenInviteDialog(true);
        return;
      }

      setMeetingAnchorEl(null);
      if (selectedMeeting.summary === '') return;
      if (!selectedMeeting.isUpdated) {
        setSelectedMeeting(undefined);
        return;
      }

      const start = selectedMeeting.allDay
        ? { date: dayjs(selectedMeeting.start?.date, { utc: true }).format(DATE_FORMAT_4) }
        : { datetime: dayjs(selectedMeeting.start?.datetime, { utc: true }).format(DATE_FORMAT_1) };
      const end = selectedMeeting.allDay
        ? { date: dayjs(selectedMeeting.end?.date, { utc: true }).format(DATE_FORMAT_4) }
        : { datetime: dayjs(selectedMeeting.end?.datetime, { utc: true }).format(DATE_FORMAT_1) };
      try {
        if (!originGoogleCalendarId) return;
        setSelectedMeeting(undefined);

        const { googleCalendarId, ...others } = selectedMeeting;
        const updatePayload = {
          ...(originGoogleCalendarId !== googleCalendarId ? selectedMeeting : others),
          start,
          end,
        } as UpdateEventWithParam;

        await updateEventsV1EventsCalendarIdEventIdPut(originGoogleCalendarId, selectedMeeting.id, updatePayload);
        fetchMeetings();
        setOriginGoogleCalendarId(null);
      } catch (error) {
        setSelectedMeeting(undefined);
        toast.error('일정을 수정하는데 실패하였습니다.');
      } finally {
        setOpenInviteDialog(false);
      }
    }
  };

  const handleChangeMeeting = (
    meeting: CreateEventWithParam & { allDay: boolean; category?: OutCategory | null; isInvited?: boolean; isUpdated?: boolean },
  ) => {
    if (meeting && newMeeting) {
      if (!monthDates.includes(dayjs(meeting.start?.date || meeting.start.datetime).format(DATE_FORMAT_4))) {
        setMeetingAnchorEl(null);
        setCurrentDate(dayjs(meeting.start?.date || meeting.start.datetime, { utc: true }).toDate());
        setTimeout(() => {
          setNewMeeting({
            ...meeting,
            id: newMeeting.id,
            allDay: meeting.allDay,
          });
        }, 1000);

        setTimeout(() => {
          const el = document.querySelector(`[data-id="${newMeeting.id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
        }, 1100);
        return;
      }

      setNewMeeting({
        ...meeting,
        id: newMeeting.id,
        allDay: meeting.allDay,
      });

      if (
        meeting.allDay !== newMeeting.allDay ||
        (dayjs(meeting.end.date || meeting.end.datetime).diff(dayjs(meeting.start.date || meeting.start.datetime), 'day') > 1 &&
          (dayjs(newMeeting.start.datetime || newMeeting.start.date).diff(dayjs(meeting.start.datetime || meeting.start.date)) !== 0 ||
            dayjs(newMeeting.end.datetime || newMeeting.end.date).diff(dayjs(meeting.end.datetime || meeting.end.date)) !== 0))
      ) {
        setMeetingAnchorEl(null);
        setTimeout(() => {
          const el = document.querySelector(`[data-id="${newMeeting.id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
        }, 100);
      }
      return;
    }

    if (meeting && selectedMeeting) {
      if (!monthDates.includes(dayjs(meeting.start?.date || meeting.start.datetime).format(DATE_FORMAT_4))) {
        setMeetingAnchorEl(null);
        setCurrentDate(dayjs(meeting.start?.date || meeting.start.datetime, { utc: true }).toDate());
        setTimeout(() => {
          setSelectedMeeting({
            ...(meeting as OutReadEvent),
          });

          const targetMeeting = meetings.find((item) => item.id === selectedMeeting.id);
          if (targetMeeting) {
            const index = meetings.indexOf(targetMeeting);
            const updatedMeetings = [...meetings];
            updatedMeetings[index] = {
              ...(meeting as OutReadEvent),
            };
            setMeetings(updatedMeetings);
          }

          const el = document.querySelector(`[data-id="${selectedMeeting.id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
        }, 1000);

        setTimeout(() => {
          const el = document.querySelector(`[data-id="${selectedMeeting.id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
        }, 1100);
        return;
      }

      setSelectedMeeting({
        ...(meeting as OutReadEvent),
      });

      const targetMeeting = meetings.find((item) => item.id === selectedMeeting.id);
      if (targetMeeting) {
        const index = meetings.indexOf(targetMeeting);
        const updatedMeetings = [...meetings];
        updatedMeetings[index] = {
          ...(meeting as OutReadEvent),
        };
        setMeetings(updatedMeetings);
      }

      if (
        meeting.allDay !== selectedMeeting.allDay ||
        (dayjs(meeting.end.date || meeting.end.datetime).diff(dayjs(meeting.start.date || meeting.start.datetime), 'day') > 1 &&
          (dayjs(selectedMeeting.start.datetime || selectedMeeting.start.date).diff(dayjs(meeting.start.datetime || meeting.start.date)) !== 0 ||
            dayjs(selectedMeeting.end.datetime || selectedMeeting.end.date).diff(dayjs(meeting.end.datetime || meeting.end.date)) !== 0))
      ) {
        setMeetingAnchorEl(null);
        setTimeout(() => {
          const el = document.querySelector(`[data-id="${selectedMeeting.id}"]`) as HTMLElement;
          setMeetingAnchorEl(el);
        }, 100);
      }
      return;
    }
  };

  const handleDeleteMeeting = async () => {
    if (newMeeting) {
      setMeetingAnchorEl(null);
      setNewMeeting(undefined);
      return;
    }
    if (selectedMeeting) {
      if (selectedMeeting.recurringEventId) {
        setRemoveRecurringEventPopup(true);
        return;
      }
      try {
        await removeEventV1EventsCalendarIdEventIdDelete(selectedMeeting.googleCalendarId!, selectedMeeting.id!);
        toast.success('일정을 삭제하였습니다.');
        setMeetingAnchorEl(null);
        setSelectedMeeting(undefined);
        fetchMeetings();
      } catch (error) {
        toast.error('일정을 삭제하는데 실패하였습니다.');
      }
    }
  };

  const handleClickProjectList = async (project: OutProject) => {
    try {
      setProjectListAnchorEl(null);
      setContextMenuPopover(null);
      await linkTaskToProjectV1ProjectProjectIdLinkingTaskTaskIdPost(project.id!, selectedTaskbox!.id!);
      toast.success('프로젝트에 연결되었습니다.');
      await fetchTaskboxes();
      setTaskboxDetailPopover(null);
      setSelectedTaskbox(undefined);
      fetchProjects();
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
  };

  const [syncInstance] = useAtom(syncInstanceAtom);

  useEffect(() => {
    if (!selectedProject) return;
    if (syncInstance?.syncType !== 'project') return;
    if (selectedTaskbox) {
      setSelectedTaskbox({
        ...selectedTaskbox,
        tasks: syncInstance?.tasks,
      });
    }
  }, [syncInstance]);

  const handleClickTaskboxTitle = () => {
    if (!selectedTaskbox) return;
    if (!taskboxDetailView || selectedTaskbox.id !== taskboxDetailView.id) {
      setTaskboxDetailView(selectedTaskbox);
    }
  };

  const [multiSelectSubtaskIds, setMultiSelectSubtaskIds] = useState<MultiSelectSubtaskIds[]>([]);
  const [multiSelectSubtaskAnchorEl, setMultiSelectSubtaskAnchorEl] = useState<HTMLElement | null>(null);

  const handleChangeMultiSelectSubtask = (multiSubtaskList: MultiSelectSubtaskIds[], e?: React.MouseEvent<HTMLDivElement>) => {
    setMultiSelectSubtaskIds(multiSubtaskList);

    if (e && (e.ctrlKey || e.metaKey)) {
      setMultiSelectSubtaskAnchorEl(e.currentTarget);
    }
  };

  const handleCloseMultiSelectSubtask = () => {
    setMultiSelectSubtaskAnchorEl(null);
    setMultiSelectSubtaskIds([]);
  };

  const handleMultiSelectSubtaskAnchorEl = () => {
    setMultiSelectSubtaskAnchorEl(null);
  };

  const handleClickMultiSelectSubtaskAction = (type: InboxContextMenuType, data: any) => {
    switch (type) {
      case 'MOVE_TO_AFTER':
        handleMoveToAfterMultiSelectSubtask();
        break;
      case 'DELETE':
        handleDeleteMultiSelectSubtask(data);
        break;
      case 'CONVERT_TO_TASKBOX':
        handleConvertToTaskboxMultiSubtask();
        break;
    }
  };

  const handleMoveToAfterMultiSelectSubtask = async () => {
    if (!selectedTaskbox) return;

    const subTasks = selectedTaskbox.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }

    const update = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: subTasks } as UpdateTaskbox);

    if (update) {
      const taskbox = taskboxes.find((item) => item.id === multiSelectSubtaskIds[0].taskboxId);
      if (!taskbox) return;
      if (taskbox.project) {
        const targetProject = projects.find((project) => project.id === taskbox.project?.id);
        if (!targetProject) return;

        const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
        const index = instances.findIndex((instance) => instance.id === taskbox.id);
        const nextInstance = instances[index + 1];

        try {
          if (nextInstance) {
            const nextInstanceTasks = nextInstance.tasks;
            await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
              beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
              tasks: multiSelectSubtaskIds[0].subtaskIds,
            });
            toast.success('프로젝트의 다음 인스턴스로 이동했습니다.');
          } else {
            await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
              taskIds: multiSelectSubtaskIds[0].subtaskIds,
            });
            toast.success('프로젝트의 미할당으로 이동했습니다.');
          }

          setMultiSelectSubtaskIds([]);
          setMultiSelectSubtaskAnchorEl(null);
          fetchProjects();
          fetchTaskboxes();
        } catch (error) {
          toast.error('일부 완료에 실패했습니다.');
        }
      } else {
        try {
          const create = await createTaskboxV1TaskboxesPost({
            id: uuidv4(),
            title: taskbox?.title,
            categoryId: [taskbox?.category?.id],
            deadline: taskbox?.deadline,
          } as CreateTaskbox);

          if (create) {
            await assignTasksV1TaskboxesTaskboxIdAssignPost(create.id!, {
              tasks: multiSelectSubtaskIds[0].subtaskIds,
            });
          }

          setMultiSelectSubtaskIds([]);
          setMultiSelectSubtaskAnchorEl(null);
          fetchTaskboxes();
          fetchBacklogTasks();
        } catch (e) {
          toast.error('나중에로 보낼 수 없습니다.');
        }
      }
    }
  };

  const handleDeleteMultiSelectSubtask = async (data: any) => {
    if (!selectedTaskbox) return;

    const subTasks = selectedTaskbox.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }

    const update = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: subTasks } as UpdateTaskbox);

    if (update) {
      if (multiSelectSubtaskIds[0].taskboxId !== 'unallocate-false' && multiSelectSubtaskIds[0].taskboxId !== 'unallocate-true') {
        const taskbox = taskboxes.find((item) => item.id === multiSelectSubtaskIds[0].taskboxId);
        if (!taskbox) return;
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox.id!, {
            ...taskbox,
            tasks: taskbox?.tasks?.filter((task) => !data.includes(task.id!)) as UpdateTaskForTaskBox[],
          });
          setMultiSelectSubtaskIds([]);
          setMultiSelectSubtaskAnchorEl(null);
          fetchTaskboxes();
        } catch (e) {
          toast.error('삭제할 수 없습니다.');
        }
      } else {
        const options = {
          paramsSerializer: (params: any) => {
            if (Array.isArray(params.id)) {
              return params.id.map((id: any) => `id=${id}`).join('&');
            }
          },
        };

        try {
          await removeTaskboxesV1TaskboxesDelete(
            {
              id: data,
            },
            options,
          );
          setMultiSelectSubtaskIds([]);
          setMultiSelectSubtaskAnchorEl(null);
          fetchTaskboxes();
        } catch (e) {
          toast.error('삭제할 수 없습니다.');
        }
      }
    }
  };

  const handleConvertToTaskboxMultiSubtask = async () => {
    if (!selectedTaskbox) return;

    const subTasks = selectedTaskbox.tasks || [];
    if (subTasks.length > 0) {
      for (let i = subTasks.length - 1; i >= 0; i--) {
        if (subTasks[i].content === '') subTasks.pop();
        else break;
      }
    }

    const update = await updateTaskboxV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { tasks: subTasks } as UpdateTaskbox);

    if (update) {
      const taskbox = taskboxes.find((item) => item.id === multiSelectSubtaskIds[0].taskboxId);
      if (!taskbox) return;

      try {
        const create = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: taskbox?.title,
          categoryId: [taskbox?.category?.id],
          deadline: taskbox?.deadline,
          start: {
            date: dayjs(taskbox?.start?.date || taskbox?.start?.datetime).format(DATE_FORMAT_4),
          },
          end: {
            date: dayjs(taskbox?.end?.date || taskbox?.end?.datetime).format(DATE_FORMAT_4),
          },
        } as CreateTaskbox);

        if (create) {
          await assignTasksV1TaskboxesTaskboxIdAssignPost(create.id!, {
            tasks: multiSelectSubtaskIds[0].subtaskIds,
          });
        }

        setMultiSelectSubtaskIds([]);
        setMultiSelectSubtaskAnchorEl(null);
        fetchTaskboxes();
      } catch (e) {
        toast.error('태스크박스로 전환할 수 없습니다.');
      }
    }
  };

  const handleReflectRoutine = async () => {
    if (!selectedTaskbox) return;
    const routine = routines.find((r) => r.id === selectedTaskbox.routine?.id);
    if (!routine) return;
    try {
      await updateRoutineRecurrenceV1RoutineRoutineIdRecurrencePatch(routine.id, {
        start: {
          datetime: selectedTaskbox.start?.datetime,
        },
        end: {
          datetime: selectedTaskbox.end?.datetime,
        },
        recurrence: selectedTaskbox.routine?.recurrence as InRecurrence,
      });
      toast.success('이후 인스턴스에 변경사항이 반영되었습니다.');
      handleCloseTaskboxDetailPopover();
    } catch (e) {
      toast.error('이후 인스턴스에 변경사항을 반영할 수 없습니다.');
    }
  };

  const handleUnlinkRoutine = async (eventId: string) => {
    try {
      await unlinkRoutineInstanceV1RoutineTaskTaskIdUnlinkPatch(eventId);
      toast.success('루틴과 연결을 해제했습니다.');
      setTimeSelectionPopover(null);
      handleCloseTaskboxDetailPopover();
      fetchTaskboxes();
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
  };

  return (
    <Container>
      <MonthCalendarViewWrapper
        ref={refCalendarView}
        onKeyDown={(e) => e.key === 'Escape' && handleCloseTaskboxCreationPopover()}
        onClick={() => {
          if (selectedProject) setSelectedProject(null);
        }}
      >
        <MonthCalendarView
          newEventId={newTaskbox?.id}
          events={
            calendarFilter === 'MEETING'
              ? calendarEvents.filter((v) => v.type === 'meeting')
              : calendarFilter === 'TASK'
              ? calendarEvents.filter((v) => v.type === 'task')
              : calendarEvents
          }
          selectedEvent={selectedEvent}
          currentDate={currentDate}
          calendarFilter={calendarFilter}
          meetingFilter={meetingFilter}
          onClickRefresh={handleRefresh}
          onClickToggleView={() => navigate('/task/today')}
          onClickCalendarFilter={handleCalendarFilter}
          onSelectEvent={handleSelectEvent}
          onUpdateEvent={handleUpdateEvent}
          onClickTimeSlot={handleClickTimeSlot}
          onUpdateEventTitle={handleUpdateEventTitle}
          onChangeCurrentDate={(date) => setCurrentDate(date)}
          onDropFromOutside={handleDropFromOutside}
          onContextMenuEvent={handleContextMenu}
          onClickMeetingFilter={handleClickMeetingFilter}
          onClickCategoryActions={handleClickCategoryActions}
        />
      </MonthCalendarViewWrapper>
      {isLoading && (
        <div style={{ position: 'absolute', top: `calc(100% - 50%)`, left: 'calc(100% - 50%)' }}>
          <CircularProgress size={32} sx={{ color: COLORS.brand1 }} />
        </div>
      )}
      <>
        {taskboxDetailPopover && (
          <Popover
            open={Boolean(taskboxDetailPopover)}
            anchorEl={taskboxDetailPopover}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleCloseTaskboxDetailPopover}
            slotProps={{
              paper: {
                style: {
                  transform:
                    (selectedInstance || selectedProject || taskboxDetailView || selectedRoutine) &&
                    taskboxDetailPopover.getBoundingClientRect().left + taskboxDetailPopover.getBoundingClientRect().width + 580 > window.innerWidth
                      ? `translate(-${(document.querySelector('.side-panel-resizable')?.getBoundingClientRect().width || sidePanelWidth) + 20}px)`
                      : (selectedInstance || selectedProject || taskboxDetailView || selectedRoutine) &&
                        taskboxDetailPopover.getBoundingClientRect().left + taskboxDetailPopover.getBoundingClientRect().width + 580 >
                          window.innerWidth - (document.querySelector('.side-panel-resizable')?.getBoundingClientRect().width || sidePanelWidth)
                      ? `translate(-${
                          taskboxDetailPopover.getBoundingClientRect().left +
                          taskboxDetailPopover.getBoundingClientRect().width +
                          620 -
                          window.innerWidth +
                          (document.querySelector('.side-panel-resizable')?.getBoundingClientRect().width || sidePanelWidth)
                        }px)`
                      : '', // 원하는 위치로 조정
                  transition: taskboxDetailPopover ? 'transform 0.4s ease-in-out' : '',
                },
              },
            }}
            sx={{ marginLeft: 1, zIndex: 102 }}
          >
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '16px 16px 0 20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #E7EAF4', borderRadius: 8, padding: 2 }}>
                  <div onClick={handleControlPosition} />
                  <>
                    <Tooltip title={selectedTaskbox?.focus ? '하이라이트 풀기' : '하이라이트 하기'}>
                      <IconButton
                        sx={{ borderRadius: '8px', padding: '4px' }}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          // selectedTaskbox?.allDay ? setHighlightPopover(e.currentTarget) : handleClickTaskboxFocus();
                          handleClickTaskboxFocus();
                        }}
                      >
                        {selectedTaskbox?.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
                      </IconButton>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem style={{ margin: '4px 2px' }} />
                  </>
                  <Tooltip
                    title={
                      <div style={{ margin: '2px 4px' }}>
                        <span>날짜 및 시간 설정</span>
                        <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                          T
                        </KeyboardButtonRect>
                      </div>
                    }
                    disableInteractive
                  >
                    <IconButton
                      data-timeselectionid={selectedTaskbox?.id}
                      sx={{ borderRadius: '8px', padding: '4px' }}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => setTimeSelectionPopover(e.currentTarget)}
                    >
                      <Icons.SelectTime />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      selectedTaskbox?.recurrence
                        ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                        : selectedTaskbox?.lockedIn
                        ? '구글 캘린더에서 해제하기'
                        : '구글 캘린더에 표시하기'
                    }
                  >
                    <IconButton sx={{ borderRadius: '8px', padding: '4px' }} style={{ cursor: 'pointer' }} onClick={handleClickTaskboxLockIn}>
                      {selectedTaskbox?.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div style={{ margin: '2px 4px' }}>
                        <span>포커스모드</span>
                        <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                          F
                        </KeyboardButtonRect>
                      </div>
                    }
                    disableInteractive
                  >
                    <IconButton
                      sx={{ borderRadius: '8px', padding: '5px' }}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        handleClickFocusMode(selectedTaskbox!);
                      }}
                    >
                      <Icons.FocusMode />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'더보기'}>
                    <IconButton
                      sx={{ borderRadius: '8px', padding: '5px' }}
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => setContextMenuPopover(e.currentTarget)}
                    >
                      <Icons.More width={14} height={14} fill={'#C8CDDB'} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <TaskboxPropertyWrapper>
                {selectedTaskbox?.category && selectedTaskbox?.category && (
                  <CategoryShowingWrapper
                    textColor={getCategoryTextColor(selectedTaskbox.category.color)}
                    bgColor={getCategoryBgColor(selectedTaskbox.category.color)}
                    onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
                  >
                    {`# ${selectedTaskbox?.category.name}`}
                    <IconButton
                      className="category-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickCategoryActions(null, 'SELECT');
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(getCategoryTextColor(selectedTaskbox.category.color)!, 0.3),
                        },
                      }}
                      style={categoryAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close width={8} height={8} fill={getCategoryTextColor(selectedTaskbox.category.color)} />
                    </IconButton>
                  </CategoryShowingWrapper>
                )}
                {selectedTaskbox?.deadline && (
                  <DeadlineShowingWrapper
                    date={selectedTaskbox?.deadline}
                    onClick={(e) => {
                      setDeadlineAnchorEl(e.currentTarget);
                    }}
                  >
                    <Icons.Flag
                      fill={
                        dayjs(selectedTaskbox?.deadline).isToday()
                          ? COLORS.brand1
                          : dayjs(selectedTaskbox?.deadline).isBefore(dayjs())
                          ? COLORS.negative1
                          : COLORS.gray600
                      }
                    />
                    <span style={{ marginLeft: '2px' }}>
                      {dayjs(selectedTaskbox?.deadline).isToday()
                        ? '오늘'
                        : dayjs(selectedTaskbox?.deadline).isYesterday()
                        ? '어제'
                        : dayjs(selectedTaskbox?.deadline).isTomorrow()
                        ? '내일'
                        : dayjs(selectedTaskbox?.deadline).format('M월 D일 (dd)')}
                    </span>
                    <IconButton
                      className="deadline-detach-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDeadline(null);
                      }}
                      sx={{
                        'width': '12px',
                        'height': '12px',
                        'borderRadius': '4px',
                        'marginLeft': '4px',
                        'marginTop': '1px',
                        'padding': '0px',
                        ':hover': {
                          backgroundColor: hexToRGBA(
                            dayjs(selectedTaskbox!.deadline).isToday()
                              ? COLORS.brand1
                              : dayjs(selectedTaskbox!.deadline).isBefore(dayjs())
                              ? COLORS.negative1
                              : COLORS.gray600,
                            0.3,
                          ),
                        },
                      }}
                      style={deadlineAnchorEl ? { display: 'flex' } : {}}
                    >
                      <Icons.Close
                        width={8}
                        height={8}
                        fill={
                          dayjs(selectedTaskbox!.deadline).isToday()
                            ? COLORS.brand1
                            : dayjs(selectedTaskbox!.deadline).isBefore(dayjs())
                            ? COLORS.negative1
                            : COLORS.gray600
                        }
                      />
                    </IconButton>
                  </DeadlineShowingWrapper>
                )}
                {(selectedTaskbox?.start?.datetime || selectedTaskbox?.durationMin) && (
                  <TaskboxDatetimeWrapper>
                    <Icons.Time width={16} height={16} stroke={COLORS.gray500} />
                    <div
                      style={{ marginLeft: 6, fontSize: 12, color: COLORS.gray500, cursor: 'pointer' }}
                      onClick={(e) => setSimpleTimeSelectionPopover(e.currentTarget)}
                    >
                      {selectedTaskbox.durationMin ? showingTime(undefined, selectedTaskbox.durationMin) : showingTime(selectedTaskbox)}
                    </div>
                    <Tooltip title={'시간 해제하기'} disableInteractive>
                      <IconButton
                        className="remove-datetime-btn"
                        onClick={handleClickRemoveDatetime}
                        sx={{ borderRadius: '8px', padding: '4px', height: '24px', width: '24px', marginLeft: '4px' }}
                      >
                        <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                      </IconButton>
                    </Tooltip>
                  </TaskboxDatetimeWrapper>
                )}
              </TaskboxPropertyWrapper>
              <TaskboxCreationView
                ref={refTaskboxInput}
                schedulable={true}
                date={dayjs(selectedTaskbox?.start?.date || selectedTaskbox?.start?.datetime, { utc: true }).toDate()}
                taskbox={
                  selectedTaskbox as CreateTaskbox & {
                    done?: boolean;
                    isRecurrence?: boolean;
                    focus?: boolean;
                    project?: OutProject;
                    category?: OutCategory;
                  }
                }
                categoryList={categoryList}
                multiSelectSubtaskIds={multiSelectSubtaskIds}
                multiSelectSubtaskAnchorEl={multiSelectSubtaskAnchorEl}
                onChange={handleChangeDetailTaskbox}
                onContinueOnAnotherDay={handleClickContinueOnAnotherDay}
                onClickInstanceContinue={handleClickInstanceContinue}
                onClickRoutineTitle={handleClickRoutineTitle}
                onLoadTemplate={handleLoadTemplate}
                onClickCategoryActions={handleClickCategoryActions}
                onClickInstanceTitle={handleClickInstanceTitle}
                onChangeInstanceSubtask={handleChangeInstanceSubtask}
                onPushProjectRecommendTask={handlePushProjectRecommendTask}
                onChangeDeadline={handleChangeDeadline}
                onClickProjectList={handleClickProjectList}
                onClickTaskboxTitle={handleClickTaskboxTitle}
                onChangeMultiSelectSubtaskIds={handleChangeMultiSelectSubtask}
                onCloseMultiSelectSubtask={handleCloseMultiSelectSubtask}
                onClickMultiSelectSubtaskAction={handleClickMultiSelectSubtaskAction}
                onMultiSelectSubtaskAnchorElNull={handleMultiSelectSubtaskAnchorEl}
                onReflectRoutine={handleReflectRoutine}
                style={{ border: 'none', width: 580 }}
              />
            </div>
            {taskboxDetailPopover && selectedTaskbox && selectedTaskbox?.focus && selectedTaskbox.done && isExploding && (
              <ConfettiExplosion
                force={0.6}
                duration={2500}
                particleCount={80}
                onComplete={() => setIsExploding(false)}
                zIndex={10000}
                style={{ position: 'absolute', top: '50%' }}
              />
            )}
          </Popover>
        )}
        {contextMenuPopover && selectedTaskbox && (
          <InboxContextMenuPopover
            id={selectedTaskbox.id!}
            type={selectedTaskbox?.project ? 'instance' : 'taskbox'}
            open={Boolean(contextMenuPopover)}
            anchorEl={contextMenuPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ marginTop: 0.5, marginLeft: 2 }}
            data={selectedTaskbox.tasks || []}
            menus={
              !selectedTaskbox.project && !selectedTaskbox.isRecurrence
                ? ['DELETE', 'TEMPLATE', 'DUPLICATE', 'CATEGORY', 'DEADLINE', 'CONVERT_TO_PROJECT', 'LINK_TO_PROJECT']
                : ['DELETE', 'CATEGORY', 'DEADLINE']
            }
            onClose={() => setContextMenuPopover(null)}
            onClickMenu={handleClickContextMenu}
          />
        )}
        {taskboxContextDetailPopover && selectedTaskbox && (
          <Popover
            open={Boolean(taskboxContextDetailPopover)}
            anchorEl={taskboxContextDetailPopover}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ marginLeft: 1, zIndex: 10 }}
            onClose={() => {
              setTaskboxContextDetailPopover(null);
              setSelectedTaskbox(undefined);
            }}
          >
            <InboxContextMenu
              id={selectedTaskbox.id!}
              type={selectedTaskbox.project ? 'instance' : 'taskbox'}
              done={selectedTaskbox.done}
              menus={!selectedTaskbox.project && !selectedTaskbox.isRecurrence ? ['TEMPLATE', 'DUPLICATE', 'DELETE', 'COMPLETE'] : ['DELETE', 'COMPLETE']}
              data={selectedTaskbox.tasks}
              onClickMenu={handleClickContextMenu}
            />
          </Popover>
        )}
        {meetingAnchorEl && (
          <Popover
            open={Boolean(meetingAnchorEl)}
            anchorEl={meetingAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ marginLeft: 1, zIndex: 102 }}
            onClose={handleCloseMeetingPopover}
          >
            <EventCreationPopover
              event={newMeeting || ({ ...selectedMeeting } as CreateEventWithParam & { allDay: boolean; category: OutCategory })}
              currentDate={currentDate}
              isNewEvent={Boolean(newMeeting)}
              openDialog={openInviteDialog}
              onChange={handleChangeMeeting}
              onDelete={handleDeleteMeeting}
              onSave={handleCloseMeetingPopover}
              onClickCategory={handleClickCategoryActions}
              onChangeDialog={handleChangeInviteDialog}
            />
          </Popover>
        )}
        {taskboxCalendarPopover && (
          <Popover
            open={Boolean(taskboxCalendarPopover)}
            anchorEl={taskboxCalendarPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ marginTop: 0.5, marginLeft: 2 }}
            onClose={() => setTaskboxCalendarPopover(null)}
          >
            <TaskboxCalendar
              date={dayjs(selectedTaskbox?.start?.date || selectedTaskbox?.start?.datetime, { utc: true }).toDate()}
              onChange={handleChangeTaskboxDate}
            />
          </Popover>
        )}
        {timeSelectionPopover && selectedTaskbox && (
          <Popover
            open={Boolean(timeSelectionPopover)}
            anchorEl={timeSelectionPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ marginTop: 1 }}
            onClose={() => setTimeSelectionPopover(null)}
          >
            <TimeDateSelectionModal
              event={selectedTaskbox}
              onChangeTime={handleUpdateEventSchedule}
              onChangeDate={handleChangeTaskboxDate}
              onClose={() => setTimeSelectionPopover(null)}
              onUnlinkRoutine={handleUnlinkRoutine}
            />
          </Popover>
        )}
        {highlightPopover && selectedTaskbox && (
          <Popover
            open={Boolean(highlightPopover)}
            anchorEl={highlightPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ marginTop: 1 }}
            onClose={() => setHighlightPopover(null)}
          >
            <HighlightTimeSelectionModal event={selectedTaskbox} onClick={handleClickHighlightTime} onClose={() => setHighlightPopover(null)} />
          </Popover>
        )}
        {(removeRecurringTaskPopup || removeRecurringEventPopup) && (
          <RemoveRecurrenceDialog
            open={removeRecurringTaskPopup || removeRecurringEventPopup}
            type={updateRecurringTaskboxParams ? 'TASK' : 'MEETING'}
            onClose={removeRecurringTaskPopup ? () => setRemoveRecurringTaskPopup(false) : () => setRemoveRecurringEventPopup(false)}
            onRemoveRecurrence={removeRecurringTaskPopup ? handleRemoveRecurrenceTask : handleRemoveRecurrenceEvent}
          />
        )}
        {(updateRecurringTaskboxParams || updateRecurringEventParams) && (
          <UpdateRecurrenceDialog
            open={Boolean(updateRecurringTaskboxParams || updateRecurringEventParams)}
            type={updateRecurringTaskboxParams ? 'TASK' : 'MEETING'}
            onClose={updateRecurringTaskboxParams ? () => setUpdateRecurringTaskboxParams(null) : () => setUpdateRecurringEventParams(null)}
            onUpdateRecurrence={updateRecurringTaskboxParams ? handleUpdateRecurrenceTask : handleUpdateRecurrenceEvent}
          />
        )}
        {simpleTimeSelectionPopover && selectedTaskbox && selectedTaskbox.id && (
          <Popover
            open={Boolean(simpleTimeSelectionPopover)}
            anchorEl={simpleTimeSelectionPopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ marginTop: 1 }}
            onClose={() => setSimpleTimeSelectionPopover(null)}
          >
            <SimpleTimeSelectionModal event={selectedTaskbox} onClose={() => setSimpleTimeSelectionPopover(null)} onChangeTime={handleUpdateEventSchedule} />
          </Popover>
        )}
        {/* 태스크박스 카테고리 */}
        {categoryAnchorEl && (
          <Popover
            open={Boolean(categoryAnchorEl)}
            anchorEl={categoryAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setCategoryAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
          </Popover>
        )}
        {/* 태스크박스 기한 */}
        {deadlineAnchorEl && (
          <Popover
            open={Boolean(deadlineAnchorEl)}
            anchorEl={deadlineAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              setDeadlineAnchorEl(null);
            }}
            sx={{ marginTop: '4px' }}
          >
            <DeadlinePopover date={dayjs().toDate()} onChangeDeadline={handleChangeDeadline} />
          </Popover>
        )}
        {/* 태스크박스 프로적트 리스트 팝오버 */}
        <Popover
          open={Boolean(projectListAnchorEl)}
          anchorEl={projectListAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setProjectListAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <ProjectListPopover onClick={handleClickProjectList} />
        </Popover>
      </>
      {/** 포커스 모드 dialog */}
      {focusModeTaskbox && (
        <FocusModeDialog
          open={Boolean(focusModeTaskbox) && !hideFocusMode}
          hide={hideFocusMode}
          taskbox={focusModeTaskbox}
          templateList={templateList}
          categoryList={categoryList}
          onFetch={handleFetchFocusMode}
          onClose={handleCloseFocusMode}
          onHide={handleHideFocusMode}
          onDelete={handleDeleteFocusMode}
          onCreateTemplate={handleCreateTemplate}
          onChangeTemplate={handleChangeTemplate}
          onChangeInprogress={handleChangeFocusModeInprogress}
          onChangeTimer={onChangeTimer}
          onClickCategoryActions={handleClickCategoryActions}
          onChangeDeadline={handleChangeDeadline}
          onClickInstanceContinue={handleClickInstanceContinue}
          onChangeInstanceSubtask={handleChangeInstanceSubtask}
          onPushProjectRecommendTask={handlePushProjectRecommendTask}
          onDeleteProject={handleDeleteProject}
        />
      )}
      {loadTemplate && (
        <FrequentWorkDialog
          open={loadTemplate}
          templateList={templateList}
          categoryList={categoryList}
          onClose={() => setLoadTemplate(false)}
          onChange={handleChangeTemplate}
          onClickCategory={handleClickCategoryActions}
        />
      )}
      <ConfirmDeleteDialog open={confirmDelete} onClose={() => setConfirmDelete(false)} onDelete={handleClickConfirmDelete} />
    </Container>
  );
};

export default TaskMonth;
