import { SVGProps } from 'react';

export const Notice = ({
  width = 32,
  height = 32,
  fill = '#1F2023',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1798_570)">
        <path
          d="M13 6C13 5.20435 13.3161 4.44129 13.8787 3.87868C14.4413 3.31607 15.2044 3 16 3C16.7956 3 17.5587 3.31607 18.1213 3.87868C18.6839 4.44129 19 5.20435 19 6C20.7226 6.81454 22.1911 8.08249 23.2481 9.66795C24.305 11.2534 24.9107 13.0966 25 15V19.5C25.1129 20.4326 25.4432 21.3256 25.9642 22.1072C26.4853 22.8888 27.1826 23.5371 28 24H4C4.81741 23.5371 5.51471 22.8888 6.03578 22.1072C6.55685 21.3256 6.88712 20.4326 7 19.5V15C7.08934 13.0966 7.69495 11.2534 8.75192 9.66795C9.8089 8.08249 11.2774 6.81454 13 6"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 24V25.25C12 26.2446 12.4214 27.1984 13.1716 27.9017C13.9217 28.6049 14.9391 29 16 29C17.0609 29 18.0783 28.6049 18.8284 27.9017C19.5786 27.1984 20 26.2446 20 25.25V24"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1798_570">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
