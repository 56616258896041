import { SVGProps } from 'react';

export const OrganizerMarker = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="8" fill="#10B8C2" />
      <path d="M4 8.5L6.66667 11L12 6" stroke="#E9F9FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
