import styled from '@emotion/styled';
import { TextField, Typography, Popper, Fade, Popover } from '@mui/material';
import { Icons } from 'components';
import React, { PropsWithChildren, ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import { useClickOutside, useKeyboardEvent } from '@react-hookz/web';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDraggableType, WorkDragView } from 'atoms/works';
import { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import TaskItem, { TaskItemProps, Task as TaskType } from 'components/Task/TaskItem2';
import InboxContextMenu from 'components/InboxContextMenu';
import TaskItemEdit from './TaskItemEdit';
import { CreateTaskboxTask, OutCategory, UpdateTaskboxTask, UpdateWorkV2 } from 'queries/model';
import { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { removeMemoHandle } from 'components/Remirror/utils';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import {
  reorderWorkboxV2WorkboxesWorkboxIdReorderPut,
  updateTaskboxV1TaskboxesTaskboxIdPut,
  updateWorkV2V2WorksWorkIdPut,
  updateWorkboxesV2V2WorkboxesWorkboxIdPut,
} from 'queries';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const NewTaskInputWrapper = styled.div`
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
`;

const TaskListViewItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  ${hideScroll()}
`;

const TaskListDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const EmptyListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(180deg, #e7eaf4 0%, #f2f5fc 100%);
`;

const TaskListDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid ${COLORS.brand1};
  background: ${COLORS.brand1};
  border-radius: 50%;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

const TaskGroupContextMenuPopper = styled.div`
  width: 240px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px ${COLORS.shadow100};
`;

export interface TaskListViewProps extends PropsWithChildren {
  title?: ReactNode;
  tasks?: TaskType[];
  categoryList?: OutCategory[];
  editable?: boolean;
  draggable?: boolean;
  dragView?: WorkDragView;
  multipleDrag?: boolean;
  popupMenus?: InboxContextMenuType[];
  taskPopoverMenus?: InboxContextMenuType[];
  showAddTooltip?: boolean;
  onCreate?: (value: string) => void;
  onChangeTask?: TaskItemProps['onChangeTask'];
  onChangeTitle?: TaskItemProps['onChangeTitle'];
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseTaskEditPopover?: (task: TaskType) => void;
  onClickTaskEditCheckbox?: (type: string, workboxId?: string, workId?: string) => void;
  onCreateWorkInWorkbox?: (workboxId: string, work: CreateTaskboxTask) => void;
  onUpdateWorkInWorkbox?: (workboxId: string, workId: string, work: UpdateTaskboxTask) => void;
  onDeleteWorkInWorkbox?: (workboxId: string, workId: string) => void;
  onClickCategoryActions?: (category: OutCategory, action: CategoryActionType) => void;
}

export type TaskListDragContext = Partial<{ dragging: boolean; draggingId: string }>;

const TaskListView = (props: TaskListViewProps) => {
  const {
    tasks = [],
    categoryList = [],
    editable,
    draggable,
    dragView,
    multipleDrag,
    popupMenus = ['MERGE_TASK_GROUP', 'DELETE'],
    taskPopoverMenus = ['DELETE', 'COMPLETED_AND_MOVE_TO_TODAY'],
    onCreate,
    onClickContextMenu,
    onChangeTask,
    onChangeTitle,
    onClickTaskEditCheckbox,
    onCreateWorkInWorkbox,
    onUpdateWorkInWorkbox,
    onDeleteWorkInWorkbox,
    onCloseTaskEditPopover,
    onClickCategoryActions,
  } = props;
  const [taskList, setTaskList] = useState<TaskType[]>(tasks);
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const refListView = useRef<HTMLDivElement>(null);
  const refTaskListView = useRef<HTMLDivElement>(null);
  const refPopperMenu = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState<Map<string, string>>(new Map());
  const [, setOutsideDragContext] = useAtom(dragContextAtom);
  const [dragContext, setDragContext] = useReducer((prev: TaskListDragContext, next: TaskListDragContext) => ({ ...prev, ...next }), {
    dragging: false,
    draggingId: '',
  });
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [currentSelectedTaskId, setCurrentSelectedTaskId] = useState<string | null>(null);
  const [taskItem, setTaskItem] = useState<TaskType | undefined>();
  const [taskEditPopoverEl, setTaskEditPopoverEl] = useState<HTMLElement | null>(null);
  const { dragging, draggingId } = dragContext;
  const [contextMenuTaskId, setContextMenuTaskId] = useState<string | null>(null);

  useEffect(() => {
    setTaskList(tasks);
  }, [tasks]);

  useEffect(() => {
    setTaskItem(tasks.find((v) => v.id === currentSelectedTaskId));
  }, [currentSelectedTaskId]);

  useClickOutside(refTaskListView, () => {
    const confirm = document.querySelector('.MuiDialog-paper') as HTMLDivElement;
    if (confirm) return;
    if (!popperEl) setSelectedTaskIds(new Map());
    if (!taskEditPopoverEl) setCurrentSelectedTaskId(null);
  });

  useClickOutside(refPopperMenu, (e: Event) => {
    const confirm = document.querySelector('.MuiDialog-paper') as HTMLDivElement;
    if (confirm) return;

    if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
    if (refTaskListView.current !== e.target && refTaskListView.current?.contains(e.target as Node)) return;
    setPopperEl(null);
    setSelectedTaskIds(new Map());
  });

  useKeyboardEvent(
    true,
    (ev) => {
      if (ev.key === 'Escape' && refPopperMenu) {
        setPopperEl(null);
        setSelectedTaskIds(new Map());
      }
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleKeydownTaskInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;
    if (e.key === 'Escape') {
      if (value) return;

      e.preventDefault();
      setIsVisibleInput(false);
    }

    if (e.key === 'Enter') {
      if (!value) return;
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refInput.current.value = '';
      onCreate?.(value);
    }
  };

  const handleClickTaskItem = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    if (e.ctrlKey || e.metaKey) {
      if (selectedTaskIds.has(id)) {
        setSelectedTaskIds((prev) => {
          const newState = new Map(prev);
          newState.delete(id);
          return newState;
        });
      } else {
        if (currentSelectedTaskId) {
          const type = tasks.find((item) => item.id === currentSelectedTaskId)?.type as string;
          setSelectedTaskIds((prev) => new Map(prev).set(currentSelectedTaskId, type));
        }

        const type = tasks.find((item) => item.id === id)?.type as string;
        setSelectedTaskIds((prev) => new Map(prev).set(id, type));
        setPopperEl(e.currentTarget);
      }
      setCurrentSelectedTaskId(null);
    } else {
      if (selectedTaskIds.size) setSelectedTaskIds(new Map());
      setPopperEl(null);
      setCurrentSelectedTaskId(id);
      setTaskEditPopoverEl(e.currentTarget);
    }
  };

  //task 마우스 우클릭시
  const handleClickTaskContext = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    setContextMenuTaskId(id);
    setTaskItem(tasks.find((v) => v.id === id));
    setTaskEditPopoverEl(e.currentTarget);
  };

  const handleCloseTaskContextPopover = (taskItem: TaskType) => {
    setContextMenuTaskId(null);
    setTaskItem(undefined);
    setTaskEditPopoverEl(null);
  };

  const handleClickTaskContextMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickContextMenu?.(id, type, menu, data);
    setTaskEditPopoverEl(null);
    setTaskItem(undefined);
    setContextMenuTaskId(null);
  };

  const handleClickPopperContextMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    onClickContextMenu?.(id, type, menu, data);
    setPopperEl(null);
    setSelectedTaskIds(new Map());
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
    if (popperEl) setPopperEl(null);
    setDragContext({ dragging: true, draggingId: id });

    const el = refListView?.current?.querySelector('#grouping-task-drag-image');
    e.dataTransfer.setDragImage(el!, 80, 2);

    const selectedTasks = selectedTaskIds.size ? tasks.filter((item) => selectedTaskIds.has(item.id)) : tasks.filter((item) => item.id === id);
    setOutsideDragContext({ id, view: dragView, title: selectedTasks[0].title, type: selectedTasks[0].type as WorkDraggableType, data: selectedTasks });
  };

  const handleDrag = (/*e: React.DragEvent<HTMLDivElement>*/) => {
    setDragContext({ dragging: true });
  };

  const handleDragEnd = () => {
    setDragContext({ dragging: false, draggingId: '' });
    setOutsideDragContext(null);
    setSelectedTaskIds(new Map());
  };

  const selectedTasks = Array.from(selectedTaskIds).map((v) => ({ id: v[0], type: v[1] }));
  const taskCount = selectedTasks.filter((v) => v.type === 'SOMEDAY_TASK').length;
  const taskGroupCount = selectedTasks.filter((v) => v.type === 'SOMEDAY_TASKBOX').length;

  const handleCloseTaskEditPopover = (taskItem: TaskType) => {
    removeMemoHandle();
    onCloseTaskEditPopover?.(taskItem);
    setCurrentSelectedTaskId(null);
    setTaskEditPopoverEl(null);
  };

  const handleChangeTaskItem = (taskItem: TaskType) => {
    // taskItem.type = taskItem!.tasks!.length > 0 ? 'SOMEDAY_TASKBOX' : taskItem.type;
    setTaskItem(taskItem);
  };

  const handleClickContextMenu = (id: string, type: string, menu: InboxContextMenuType, data: any) => {
    setTaskEditPopoverEl(null);
    setCurrentSelectedTaskId(null);
    onClickContextMenu?.(id, type, menu, data);
  };

  const handleClickCheckbox = (type: string, workId: string) => {
    if (type === 'SOMEDAY_TASKBOX') {
      setTaskEditPopoverEl(null);
      setCurrentSelectedTaskId(null);
    }
    onClickTaskEditCheckbox?.(type, currentSelectedTaskId!, workId);
  };

  const handleCreateWorkInWorkbox = (work: CreateTaskboxTask) => {
    onCreateWorkInWorkbox?.(currentSelectedTaskId!, work);
  };

  const handleUpdateWorkInWorkbox = (workId: string, work: UpdateTaskboxTask) => {
    onUpdateWorkInWorkbox?.(currentSelectedTaskId!, workId, work);
  };

  const handleDeleteWorkInWorkbox = (workId: string) => {
    onDeleteWorkInWorkbox?.(currentSelectedTaskId!, workId);
  };

  const handleBeautifulDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    const newTaskList = taskList.filter((item) => item.id !== result.draggableId);
    newTaskList.splice(result.destination.index, 0, taskList[result.source.index]);
    setTaskList([...newTaskList]);

    if (result.source.index < result.destination.index) {
      try {
        await updateWorkboxesV2V2WorkboxesWorkboxIdPut(result.draggableId, {
          beforeId: taskList[result.destination.index].id,
        });
      } catch (error) {
        toast.error('태스크 순서 변경에 실패하였습니다.');
      }
    } else {
      try {
        await updateWorkboxesV2V2WorkboxesWorkboxIdPut(result.draggableId, {
          beforeId: result.destination.index - 1 < 0 ? null : taskList[result.destination.index - 1].id,
        });
      } catch (error) {
        toast.error('태스크 순서 변경에 실패하였습니다.');
      }
    }
  };

  const contextMenus: InboxContextMenuType[] =
    taskList.length === 1
      ? ['START_NOW', 'DELETE']
      : taskList.findIndex((v) => v.id === contextMenuTaskId) === 0
      ? ['START_NOW', 'MOVE_TO_BOTTOM', 'DELETE']
      : taskList.findIndex((v) => v.id === contextMenuTaskId) === taskList.length - 1
      ? ['START_NOW', 'MOVE_TO_TOP', 'DELETE']
      : ['START_NOW', 'MOVE_TO_TOP', 'MOVE_TO_BOTTOM', 'DELETE'];

  return (
    <>
      <Container ref={refListView} onDragEnd={handleDragEnd}>
        {isVisibleInput && (
          <div style={{ marginBottom: '8px' }}>
            <NewTaskInputWrapper>
              <div>
                <Icons.TaskCheckbox style={{ marginRight: 8, marginLeft: 16 }} />
              </div>
              <TextField
                inputRef={refInput}
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder="새로운 태스크 만들기"
                onKeyDown={handleKeydownTaskInput}
                onBlur={() => !refInput?.current?.value && setIsVisibleInput(false)}
                InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray800 } }}
              />
            </NewTaskInputWrapper>
          </div>
        )}
        {taskList.length > 0 && (
          <DragDropContext onDragEnd={handleBeautifulDragEnd}>
            <Droppable droppableId="task">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ height: '100%' }}>
                  <TaskListViewItemWrapper ref={refTaskListView}>
                    {taskList.map((v, index) => {
                      const isGroupSelecting = selectedTaskIds.has(v.id);
                      const selectionCount = selectedTaskIds.size;
                      const isHidden = (isGroupSelecting && dragging) || (selectionCount === 0 && v.id === draggingId && dragging);
                      return (
                        <TaskItem
                          key={v.id}
                          value={v}
                          editable={editable}
                          draggable={draggable}
                          selected={currentSelectedTaskId === v.id}
                          groupSelected={multipleDrag && isGroupSelecting}
                          highlight={!!(selectedTasks.length >= 2 && selectedTasks[0].id === v.id)}
                          isGrouping={selectionCount > 0}
                          hidden={isHidden}
                          popoverMenus={taskPopoverMenus}
                          dragIndex={index}
                          onDragStart={handleDragStart}
                          onDrag={handleDrag}
                          onClick={(e, id) => handleClickTaskItem(e, id)}
                          onClickContext={handleClickTaskContext}
                          onChangeTask={onChangeTask}
                          onChangeTitle={onChangeTitle}
                          onClickContextMenu={onClickContextMenu}
                        />
                      );
                    })}
                  </TaskListViewItemWrapper>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!isVisibleInput && taskList.length === 0 && (
          <div style={{ height: '100%', padding: '0px 32px' }}>
            <EmptyListWrapper>
              <Icons.EmptyTask />
              <Typography variant={'subtitle2'} fontWeight={'bold'} fontSize={12} color={COLORS.gray700} style={{ marginTop: 12 }}>
                해당하는 태스크가 없어요
              </Typography>
            </EmptyListWrapper>
          </div>
        )}
        <TaskListDragImage id="grouping-task-drag-image">
          <TaskListDragImageCount>{selectedTaskIds.size || 1}</TaskListDragImageCount>
          <span style={{ fontSize: 13, marginLeft: 8 }}>선택됨</span>
        </TaskListDragImage>
      </Container>
      {selectedTaskIds.size > 0 && (
        <Popper
          transition
          ref={refPopperMenu}
          open={Boolean(popperEl)}
          anchorEl={popperEl}
          placement={'left-start'}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -24], // [horizontal offset, vertical offset]
              },
            },
          ]}
          style={{ zIndex: 102 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <TaskGroupContextMenuPopper>
                <div style={{ padding: '16px 16px 0px 16px', fontSize: 12, fontWeight: 'bold' }}>
                  {taskCount && taskGroupCount ? `${taskGroupCount}개의 태스크 그룹과 ${taskCount}개의 태스크가 선택되었습니다.` : null}
                  {taskCount && taskGroupCount === 0 ? `${taskCount}개의 태스크가 선택되었습니다.` : null}
                  {taskCount === 0 && taskGroupCount ? `${taskGroupCount}개의 태스크 그룹이 선택되었습니다.` : null}
                </div>
                <InboxContextMenu
                  id={selectedTasks[0].id || ''}
                  type="merge"
                  menus={selectedTaskIds.size === 1 ? ['DELETE'] : popupMenus}
                  data={selectedTasks}
                  onClickMenu={handleClickPopperContextMenu}
                />
              </TaskGroupContextMenuPopper>
            </Fade>
          )}
        </Popper>
      )}
      {currentSelectedTaskId === taskItem?.id && (
        <Popover
          open={Boolean(taskEditPopoverEl)}
          anchorEl={taskEditPopoverEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => handleCloseTaskEditPopover(taskItem)}
          style={{ left: 24 }}
        >
          <TaskItemEdit
            taskItem={taskItem}
            categoryList={categoryList}
            onChange={handleChangeTaskItem}
            onClickContextMenu={handleClickContextMenu}
            onClickCheckbox={handleClickCheckbox}
            onCreateWorkInWorkbox={handleCreateWorkInWorkbox}
            onUpdateWorkInWorkbox={handleUpdateWorkInWorkbox}
            onDeleteWorkInWorkbox={handleDeleteWorkInWorkbox}
            onClickCategoryActions={onClickCategoryActions}
          />
        </Popover>
      )}
      {contextMenuTaskId === taskItem?.id && (
        <Popover
          open={Boolean(taskEditPopoverEl)}
          anchorEl={taskEditPopoverEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleCloseTaskContextPopover}
          style={{ left: 24 }}
        >
          <InboxContextMenu id={taskItem.id} type={'task'} menus={contextMenus} onClickMenu={handleClickTaskContextMenu} />
        </Popover>
      )}
    </>
  );
};

export default TaskListView;
