import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Toaster from 'components/Toast';
import GNB from 'components/GNB';
import { getToken } from 'utils/token';
import { useAtom } from 'jotai';
import { meAtom, userAtom } from 'atoms/user';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { initialize } from 'lib/i18n';
import { useAuthAction } from 'hooks/useAuthAction';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import Meeting from './pages/Meeting';
import Task from './pages/Task';
import TaskWeek from './pages/Task/Week';
import JoinWorkspace from './pages/JoinWorkspace';
import Auth from 'pages/Auth';
import ProductionGNB from 'pages/Beta/ProductionGNB';
import { Desk } from 'pages/Desk';
import ReviewSignIn from 'pages/Reflection/SignIn';
import { MainLayout, NavLayout, PageLayout, UniversalLayout } from './Layout';
import ProductionGoal from 'pages/Beta/ProductionGoal';
import Mobile from 'pages/Mobile';
import { isMobile } from 'react-device-detect';
import ReflectionIntro from 'pages/NewReflection';
import Onboarding from 'pages/Onboarding';
import { OutOAuthUser, OutUserDetail } from 'queries/model';
import { settingMeasured } from 'utils/measured';
import SignUpSurvey from 'pages/Survey/SignUpSurvey';
import MobileSurvey from 'pages/Mobile/Survey';
import MobileSignIn from 'pages/Mobile/SignIn';
import Ritual from 'pages/Ritual';
import TaskMonth from 'pages/Task/Month';
import TestingMemo from 'pages/TestingMemo';
import UserSettings from 'pages/UserSettings';
import { CalendarSetting } from 'pages/CalendarSetting';
import { Universal } from 'components/Universal';
import dayjs from 'lib/dayjs';
import { timezoneAtom } from 'atoms/timezone';
import { partialUpdateUserSettingV1UsersSettingPatch } from 'queries';
import { Button } from '@mui/material';
import { ritualAlarmAtom } from 'atoms/notification';
import { ritualAlarmTimeAtom } from 'atoms/timer';
import { useUpdateEffect } from '@react-hookz/web';
// import { useTranslation } from 'react-i18next';

// i18next 초기화
initialize();

const AppLayout = ({ me, user, token }: { me?: OutOAuthUser; user: OutUserDetail | null; token: string | null }) => {
  const { reLogin } = useAuthAction();
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const isPathReview = location.pathname?.includes('/reflection');

  useEffect(() => {
    (async () => {
      if (token) await reLogin();
      setIsAuthChecked(true);
    })();
  }, []);

  //measured 설정
  useEffect(() => {
    if (user) {
      window.postMessage({
        name: 'setUserIdForTracker',
        arguments: {
          userId: user.id,
        },
      });
      window.postMessage({
        name: 'setUserPropertyForTracker',
        arguments: {
          key: 'name',
          value: user.name,
        },
      });
      settingMeasured(user);
    }
  }, [user]);

  if (!isAuthChecked) return <></>;
  return me?.name && token ? (
    <>
      <MainLayout>
        <UniversalLayout>
          <Universal />
        </UniversalLayout>
        <div style={{ width: '100%', height: 'calc(100% - 40px)', display: 'flex' }}>
          <NavLayout>
            <GNB />
          </NavLayout>
          <PageLayout>
            <Outlet />
          </PageLayout>
        </div>
      </MainLayout>
    </>
  ) : (
    <Navigate to={isMobile ? '/m/signIn' : isPathReview ? '/reflection/signIn' : '/signIn'} />
  );
};

const App = () => {
  const [me] = useAtom(meAtom);
  const [user] = useAtom(userAtom);
  const token = getToken();
  const [tutorialStatus, setTutorialStatus] = useState(false);
  const onboarding = localStorage.getItem('onboarding');
  const entrypoint = localStorage.getItem('entrypoint');
  const isEntryPointReview = entrypoint === 'reflection';
  const isEntryPointMobile = entrypoint === 'm';
  const LazyTaskToday = lazy(() => import('pages/Task/Today'));
  // const { t } = useTranslation();

  /* Start of timezone view */
  // const [timezone, setTimezone] = useAtom(timezoneAtom);
  // const [now, setNow] = useState(dayjs().format('YYYY-MM-DD HH:mm:ssZ'));

  // useEffect(() => {
  //   setInterval(() => {
  //     setNow(dayjs().format('YYYY-MM-DD HH:mm:ssZ'));
  //   }, 1000);
  // }, []);

  // const handleChangeTimezone = async (tz: string) => {
  //   setTimezone(tz);
  //   await partialUpdateUserSettingV1UsersSettingPatch({ timezone: tz });
  //   window.location.reload();
  // };

  /* End of timezone view */

  const handleMobileScreenSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    if (user) {
      setTutorialStatus(user.tutorialStatus!);
    }
  }, [user]);

  useEffect(() => {
    // ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
    handleMobileScreenSize();
  }, []);

  window.addEventListener('resize', () => handleMobileScreenSize());

  /* ritual alarm timer */
  const [, setRitualAlarm] = useAtom(ritualAlarmAtom);
  const [ritualAlarmTime, setRitualAlarmTime] = useAtom(ritualAlarmTimeAtom);
  const ritualTimerRef = useRef<number | null>(null);
  const startTimer = (time: number) => {
    // 이전 타이머가 있다면 정리
    if (ritualTimerRef.current) {
      navigator.serviceWorker.controller?.postMessage({ type: 'STOP_TIMER', timerId: ritualTimerRef.current });
      ritualTimerRef.current = null;
    }

    // 새로운 타이머 종료 시간 계산
    const endTime = Date.now() + time * 3600 * 1000;
    if (!localStorage.getItem('ritual-timer-end-time')) localStorage.setItem('ritual-timer-end-time', endTime.toString()); // 타이머 종료 시간 저장

    // 새로운 타이머 설정을 서비스 워커에 전달
    navigator.serviceWorker.controller?.postMessage({ type: 'START_TIMER', time: time * 3600 * 1000 });
  };

  useEffect(() => {
    const timerExpiredHandler = (event: { data: { type: string } }) => {
      if (event.data && event.data.type === 'TIMER_EXPIRED') {
        // 타이머가 서비스 워커에서 종료되면 handleExpireTimer 실행
        handleExpireTimer();
      }
    };

    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener('message', timerExpiredHandler);
    }

    const storedEndTime = localStorage.getItem('ritual-timer-end-time');
    if (storedEndTime) {
      const remainingTime = parseInt(storedEndTime, 10) - Date.now();
      if (remainingTime > 0) {
        // 타이머가 아직 남아 있으면 복원
        startTimer(Math.floor(remainingTime / 3600 / 1000));
      } else {
        // 만약 타이머가 끝났으면 바로 종료 처리
        handleExpireTimer();
      }
    }

    // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    return () => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.removeEventListener('message', timerExpiredHandler);
      }
    };
  }, []);

  useUpdateEffect(() => {
    if (ritualAlarmTime > 0) {
      startTimer(ritualAlarmTime);
    }
  }, [ritualAlarmTime]);

  const handleExpireTimer = () => {
    setRitualAlarm(true);
    setRitualAlarmTime(0);
    localStorage.setItem('ritual-alarm', 'true');
    localStorage.removeItem('snooze-time');
    localStorage.removeItem('ritual-timer-end-time');
  };

  return (
    <>
      {/* {process.env.REACT_APP_MODE === 'development' && (
        <div
          style={{
            position: 'absolute',
            top: 2,
            left: 100,
            color: 'red',
            fontSize: '24px',
            display: 'flex',
            gap: 12,
            fontFamily: 'Courier New',
            fontWeight: 'bold',
            zIndex: 9999,
          }}
        >
          <span>{now}</span>
          <span style={{ width: '200px' }}>{timezone}</span>
          <span style={{ width: '100px' }}>{`(${Math.floor(dayjs().utcOffset() / 60)}:${dayjs().utcOffset() % 60})`}</span>
          <Button variant="outlined" style={{ background: '#FFF' }} onClick={() => handleChangeTimezone('Asia/Seoul')}>
            서울(+9)
          </Button>
          <Button variant="outlined" style={{ background: '#FFF' }} onClick={() => handleChangeTimezone('Asia/Saigon')}>
            사이공(+7)
          </Button>
          <Button variant="outlined" style={{ background: '#FFF' }} onClick={() => handleChangeTimezone('Etc/Greenwich')}>
            그리니치(0)
          </Button>
          <Button variant="outlined" style={{ background: '#FFF' }} onClick={() => handleChangeTimezone('Pacific/Midway')}>
            태평양/미드웨이(-11)
          </Button>
        </div>
      )} */}
      <Toaster />
      <BrowserRouter>
        <Suspense>
          <Routes>
            {process.env.REACT_APP_MODE === 'production' && (
              <>
                <Route element={<AppLayout me={me} user={user} token={token} />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/task" element={<Task />}>
                    <Route path="today" element={<LazyTaskToday />} />
                    <Route path="week" element={<TaskWeek />} />
                    <Route path="month" element={<TaskMonth />} />
                  </Route>
                  <Route path="/plan" element={<Desk />} />
                  <Route path="/goal" element={<ProductionGoal />} />
                  <Route path="/reflection" element={<ReflectionIntro />} />
                </Route>
              </>
            )}
            {process.env.REACT_APP_MODE === 'development' && (
              <>
                <Route element={<AppLayout me={me} user={user} token={token} />}>
                  <Route path="/home" element={<Home />} />
                  <Route path="/meeting" element={<Meeting />} />
                  <Route path="/task" element={<Task />}>
                    <Route path="today" element={<LazyTaskToday />} />
                    <Route path="week" element={<TaskWeek />} />
                    <Route path="month" element={<TaskMonth />} />
                  </Route>
                  <Route path="/plan" element={<Desk />} />
                  <Route path="/reflection" element={<ReflectionIntro />} />
                  <Route path="/testmemo" element={<TestingMemo />} />
                  {/* GNB 투표기능 */}
                  <Route path="/testplan" element={<ProductionGNB />} />
                  <Route path="/testgoal" element={<ProductionGNB />} />
                  <Route path="/testretro" element={<ProductionGNB />} />
                </Route>
              </>
            )}
            {/* {process.env.REACT_APP_MODE === 'development' && (
              <>
                <Route element={<AppLayout me={me} user={user} token={token} />}>
                  <Route path="/reflection" element={<ReflectionIntro />} />
                  <Route path="/reflection/list" element={<ReflectionList />} />
                  <Route path="/reflection/settings" element={<ReflectionSettings />} />
                  <Route path="/reflection/board" element={<ReflectionBoard />} />
                </Route>
                <Route path="/reflection/writing/:id" element={<ReflectionWriting />} />
              </>
            )} */}
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/survey" element={<SignUpSurvey />} />
            <Route path="/joinWorkspace" element={<JoinWorkspace />} />
            <Route path="/reflection/signIn" element={<ReviewSignIn />} />
            <Route path="/ritual" element={<Ritual />} />
            <Route path="/auth" element={<Auth isMobile={isMobile} />} />
            <Route path="/calendar" element={<CalendarSetting />} />
            <Route path="/m/signIn" element={<MobileSignIn />} />
            {token && <Route path="/m/home" element={<Mobile />} />}
            {token && <Route path="/m/survey" element={<MobileSurvey />} />}
            {!tutorialStatus && !onboarding && <Route path="/welcome" element={<Onboarding />} />}
            <Route path="/" element={<Navigate to={isEntryPointMobile ? 'm/home' : isEntryPointReview ? '/reflection' : '/task'} />} />
            <Route path="*" element={<Navigate to={isEntryPointMobile ? 'm/home' : isEntryPointReview ? '/reflection' : '/task'} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
