import { SVGProps } from 'react';

export const Close = ({ width = 32, height = 32, fill = '#1F2023' }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1891_765)">
        <path d="M5 5L27 27" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 5L5 27" stroke={fill} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1891_765">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
