/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Custom title
 * 커스텀 OpenAPI 스키마
 * OpenAPI spec version: 3.0.2
 */

/**
 * An enumeration.
 */
export type OperationType = typeof OperationType[keyof typeof OperationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
} as const;
