import styled from '@emotion/styled';
import { Button, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { updateUserAtom, userAtom } from 'atoms/user';
import { Icons } from 'components';
import { useAtom } from 'jotai';
import {
  getAllCalendarV1CalendarGet,
  getUserMeV1UsersMeGet,
  initiateGoogleCalendarAndEventV1EventsSyncingGoogleCalendarGet,
  partialUpdateUserSettingV1UsersSettingPatch,
  removeUserDataV1UsersDeleteAccountPost,
  reorderTaskboxTimeSplitV1TaskboxesOrderingTimeSplitStylePut,
  retrieveUserSettingV1UsersSettingGet,
  syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost,
  updateCalendarV1CalendarCalendarIdPatch,
  updateMyProfileV1UsersMePatch,
  updateStateStorageV1StateStoragePatch,
} from 'queries';
import { InUpdateCalendar, OutCalendar, TaskViewEnum, UpdateUserMe, UserSettingModel } from 'queries/model';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import DeleteAccountDialog from './DeleteAccountDialog';
import Account from './Account';
import Alarm from './Notification';
import Notification from './Notification';
import { useResetAtom } from 'jotai/utils';
import { userSettingAtom } from 'atoms/userSetting';
import AccountSetting from './Account';
import CalendarSetting from './Calendar';
import NotificationSetting from './Notification';
import LanguageAndTimezoneSetting from './LanguageAndTimezone';
import { userStateAtom } from 'atoms/userState';

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiTabs-indicator {
    width: 0px;
  }

  .Mui-selected {
    background-color: ${COLORS.gray200};
  }
`;

const AccountSettingHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray-300);
  padding: 20px 24px;
`;

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-left: 6px;
`;

const AccountSettingMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const TabPanel = styled.div`
  width: 100%;
  display: ${(props: { hidden: boolean }) => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  padding-top: 28px;
`;

const UserSettingModels = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [userMe] = useAtom(userAtom);
  const [userName, setUserName] = useState(userMe?.name);
  const [tabValue, setTabValue] = useState(0);
  const [me, setMe] = useState(userMe);
  const [, setUserAtom] = useAtom(updateUserAtom);
  const [userSetting, fetchUserSetting] = useAtom(userSettingAtom);
  const [userState, fetchUserState] = useAtom(userStateAtom);
  const [googleCalendarList, setGoogleCalendarList] = useState<OutCalendar[]>([]);
  const [reloadGoogleCalendar, setReloadGoogleCalendar] = useState(false);

  useEffect(() => {
    if (!userMe) fetchUser();
    const searchParams = new URLSearchParams(window.location.search);
    const section = searchParams.get('section');
    if (section === 'notification') setTabValue(3);
    fetchUserSetting();
    getEntireGoogleCalendar();
  }, []);

  const fetchUser = async () => {
    const me = await getUserMeV1UsersMeGet();
    setMe(me);
    setUserName(me.name);
  };

  const getEntireGoogleCalendar = async () => {
    const calendarList = await getAllCalendarV1CalendarGet();
    setGoogleCalendarList(calendarList);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
    setDeleteAccount(false);
  };

  const handleDeleteAccount = async (reason: string) => {
    const success = await removeUserDataV1UsersDeleteAccountPost({ reason });
    if (success) setDeleteAccount(true);
  };

  const handleChangeName = (name: string) => {
    setUserName(name);
  };

  const handleBlur = async () => {
    if (userName === userMe?.name) return;
    if (userName === '') {
      toast.error('이름을 입력해주세요.');
      return;
    }

    const updateUserMe: UpdateUserMe = {
      name: userName,
    };

    const success = await updateMyProfileV1UsersMePatch(updateUserMe);
    if (success) {
      toast.success('이름이 변경되었습니다.');
      setUserAtom({ ...me, name: userName });
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChangeUserSettingModel = async (setting: UserSettingModel) => {
    await partialUpdateUserSettingV1UsersSettingPatch(setting);
    fetchUserSetting();
  };

  const handleChangeGoogleCalendar = async (calendarId: string, updateCalendar: InUpdateCalendar, sync?: boolean) => {
    try {
      await updateCalendarV1CalendarCalendarIdPatch(calendarId, updateCalendar);
      if (sync) await syncGoogleCalendarEventByIdsV1EventsSyncingGoogleCalendarEventByIdsPost([calendarId]);
    } catch (error) {
      toast.error('캘린더 설정을 변경하는 중 오류가 발생했습니다.');
    }
    getEntireGoogleCalendar();
  };

  const handleReloadGoogleCalendar = async () => {
    setReloadGoogleCalendar(true);
    try {
      await initiateGoogleCalendarAndEventV1EventsSyncingGoogleCalendarGet();
      await getEntireGoogleCalendar();
    } catch (error) {
      toast.error('구글 캘린더 동기화를 실패했습니다.');
    }
    setReloadGoogleCalendar(false);
  };

  const handleChangeBoardViewMode = async (viewMode: TaskViewEnum) => {
    try {
      await updateStateStorageV1StateStoragePatch({
        data: {
          ...userState,
          taskViewMode: viewMode,
        },
      });
      if (viewMode === TaskViewEnum.MERGED) {
        await reorderTaskboxTimeSplitV1TaskboxesOrderingTimeSplitStylePut();
      }
      fetchUserState({
        ...userState,
        taskViewMode: viewMode,
      });
    } catch (e) {
      toast.error('보드 뷰 모드 변경 중 오류가 발생했습니다.');
    }
  };

  return (
    <Container>
      <AccountSettingHeader>
        <Link to={'/task'}>
          <HeaderButton>
            <Icons.ArrowBack width={24} height={24} stroke={COLORS.gray900} />
            <HeaderText>워크스페이스로 돌아가기</HeaderText>
          </HeaderButton>
        </Link>
      </AccountSettingHeader>
      <AccountSettingMain>
        <Tabs
          orientation="vertical"
          value={tabValue}
          onChange={handleChangeTabs}
          sx={{ minWidth: '310px', height: '100%', borderRight: `1px solid ${COLORS.gray300}`, padding: '28px' }}
        >
          <Tab
            icon={<Icons.AccountSetting width={24} height={24} />}
            iconPosition="start"
            label="개인 정보 설정"
            sx={{ height: '48px', borderRadius: '8px', minHeight: '0px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
          />

          <Tab
            icon={<Icons.CalendarSetting width={24} height={24} />}
            iconPosition="start"
            label="캘린더 및 일지"
            sx={{
              height: '48px',
              borderRadius: '8px',
              minHeight: '0px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '12px',
            }}
          />
          <Tab
            icon={<Icons.Globe stroke={COLORS.gray900} width={24} height={24} style={{ marginRight: 8 }} />}
            iconPosition="start"
            label="언어 및 시간"
            sx={{
              height: '48px',
              borderRadius: '8px',
              minHeight: '0px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '12px',
            }}
          />
          <Tab
            icon={<Icons.UserAlarm />}
            iconPosition="start"
            label="알림"
            sx={{
              height: '48px',
              borderRadius: '8px',
              minHeight: '0px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '12px',
            }}
          />
        </Tabs>
        <TabPanel hidden={tabValue !== 0}>
          <AccountSetting
            me={me!}
            userName={userName}
            onChangeName={handleChangeName}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onOpenDeleteDialog={handleOpenDeleteDialog}
          />
        </TabPanel>
        <TabPanel hidden={tabValue !== 1} style={{ paddingTop: '0px' }}>
          <CalendarSetting
            setting={userSetting}
            userState={userState}
            reload={reloadGoogleCalendar}
            googleCalendarList={googleCalendarList}
            onChange={handleChangeUserSettingModel}
            onChangeGoogleCalendar={handleChangeGoogleCalendar}
            onClickReloadCalendar={handleReloadGoogleCalendar}
            onChangeBoardViewMode={handleChangeBoardViewMode}
          />
        </TabPanel>
        <TabPanel hidden={tabValue !== 2}>
          <LanguageAndTimezoneSetting setting={userSetting} onChange={handleChangeUserSettingModel} />
        </TabPanel>
        <TabPanel hidden={tabValue !== 3}>
          <NotificationSetting setting={userSetting} onChange={handleChangeUserSettingModel} />
        </TabPanel>
      </AccountSettingMain>
      <DeleteAccountDialog open={openDialog} isDelete={deleteAccount} onClose={handleCloseDeleteDialog} onClick={handleDeleteAccount} />
    </Container>
  );
};

export default UserSettingModels;
