import { SVGProps } from 'react';

export const Invi = ({
  width = 24,
  height = 24,
}: SVGProps<SVGSVGElement> | SVGProps<SVGMaskElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2032_2614)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 17.1716L19.4142 14.5858C18.6332 13.8047 17.3668 13.8047 16.5858 14.5858C15.9314 15.2402 15.8253 16.2351 16.2674 17H15C13.8954 17 13 17.8954 13 19H4.22222C3.63285 19 3.06762 18.7742 2.65087 18.3724C2.23413 17.9705 2 17.4255 2 16.8571V6.14286C2 5.57454 2.23413 5.02949 2.65087 4.62763C3.06762 4.22576 3.63285 4 4.22222 4H19.7778C20.3671 4 20.9324 4.22576 21.3491 4.62763C21.7659 5.02949 22 5.57454 22 6.14286V14.1786V17.1716Z"
          fill="#E2ECFF"
        />
        <path
          d="M15 19H21"
          stroke="#0039A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 16L21 19L18 22"
          stroke="#0039A7"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_2032_2614"
          maskUnits="userSpaceOnUse"
          x="2"
          y="4"
          width="20"
          height="16"
        >
          <path
            d="M12 20H4.22222C3.63285 20 3.06762 19.7592 2.65087 19.3305C2.23413 18.9019 2 18.3205 2 17.7143V6.28571C2 5.67951 2.23413 5.09812 2.65087 4.66947C3.06762 4.24082 3.63285 4 4.22222 4H19.7778C20.3671 4 20.9324 4.24082 21.3491 4.66947C21.7659 5.09812 22 5.67951 22 6.28571V14.8571"
            fill="#E2ECFF"
          />
        </mask>
        <g mask="url(#mask0_2032_2614)">
          <path
            d="M2 6.5L10.91 12.2915C11.5728 12.7223 12.4272 12.7223 13.09 12.2915L22 6.5V3H2V6.5Z"
            fill="#0039A7"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2032_2614">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
