import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Checkbox, CircularProgress, Dialog, Divider, IconButton, Popover, Popper, Tooltip } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import {
  updateTaskboxV1TaskboxesTaskboxIdPut,
  createTaskboxTaskV1TaskboxesTaskboxIdTasksPost,
  removeTaskboxV1TaskboxesTaskboxIdDelete,
  updateSubtaskV1TasksTaskIdPatch,
  detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  createTaskboxV1TaskboxesPost,
  assignTasksV1TaskboxesTaskboxIdAssignPost,
  updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost,
  syncRecurringTaskboxesV1TaskboxesTaskboxIdSyncPut,
  deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete,
  updateFrequentWorkV1FrequentworksFrequentWorkIdPut,
  createFrequentWorkV1FrequentworksPost,
  createCategoryV1CategoryPost,
  deleteCategoryV1CategoryCategoryIdDelete,
  updateCategoryV1CategoryCategoryIdPatch,
  createWorkboxV2WorkboxesPost,
  subtaskToTaskV1TaskboxesSubtasksTaskTypePatch,
  updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch,
  convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost,
  getAProjectV1ProjectProjectIdGet,
  deleteProjectV1ProjectProjectIdDelete,
  assignTasksToProjectV1ProjectProjectIdAssigningTaskPost,
  updateProjectV1ProjectProjectIdPatch,
} from 'queries';
import {
  UpdateTaskbox,
  AppApplicationTasksSchemasOutTask,
  OutPlanWeekly,
  UpdateIssueDetail,
  OutTaskboxDetailResponse,
  CreateWork,
  CreateTaskbox,
  RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams,
  InCreateTaskBoxInRecurrenceTarget,
  InCreateTaskBoxInRecurrence,
  InRecurrenceAction,
  OutFrequentWork,
  CreateFrequentWork,
  UpdateTaskForTaskBox,
  CreateFrequentWorkTask,
  CreateTaskboxTask,
  OutTaskboxDetailResponseForSubtask,
  OutCategory,
  UpdateTaskBoxRecurrence,
  RecurrenceActionType,
  InRecurrence,
  OutProject,
  OutTaskboxDetailProjectOnly,
} from 'queries/model';
import { ForwardedRef, forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { setCaretToEnd } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { convertDatetimeFormat, DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_5, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getCaretPosition } from 'utils/moveCaret';
import { IssueLinkage } from 'components/Icons';
import dayjs, { Dayjs } from 'lib/dayjs';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragContext } from 'atoms/works';
import toast from 'react-hot-toast';
import { useClickOutside, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { tasksAtom } from 'atoms/tasks';
import { UpdateRecurrenceDialog } from 'components/Recurrence/UpdateRecurrenceDialog';
import SubTaskCheckbox from 'components/SubTaskCheckbox';
import ConfettiExplosion from 'react-confetti-explosion';
import TimeDateSelectionModal from 'components/TimeSelectionModal/TimeDateSelectionModal';
import { SimpleTimeSelectionModal } from 'components/TimeSelectionModal/SimpleTimeSelectionModal';
import { focusModeTaskboxAtom, hideFocusModeTaskboxAtom } from 'atoms/focus';
import { RemoveRecurrenceDialog } from 'components/Recurrence/RemoveRecurrenceDialog';
import { osName } from 'react-device-detect';
import { InboxContextMenuType } from 'components/InboxContextMenu';
import { TimerActionType } from 'pages/Task';
import { SubtaskDetail } from 'pages/Task/components/SubtaskDetail';
import { HighlightTimeSelectionModal } from 'pages/Task/components/HighlightTimeSelectionModal';
import { TimelineTaskboxDetail } from 'pages/Task/components/TimelineTaskboxDetail';
import { TaskboxContinueDetail } from 'pages/Task/components/TaskboxContinueDetail';
import { TaskboxMultiSelectPopover } from 'pages/Task/components/TaskboxMultiSelectPopover';
import FocusModeDialog from 'pages/Task/components/FocusModeDialog';
import { ConfirmDeleteDialog } from 'pages/Task/components/ConfirmDeleteDialog';
import IssueDetailView from 'pages/Task/components/IssueDetailView';
import { MultiSelectType } from '..';
import CategoryPopover, { CategoryActionType } from 'pages/Task/components/CategoryPopover';
import { DeadlinePopover } from 'pages/Task/components/DeadlinePopover';
import { getCategoryBgColor, getCategoryTextColor, hexToRGBA } from 'utils/category';
import TemplateDialog from 'pages/Task/components/TemplateDialog';
import { projectsAtom } from 'atoms/projects';
import { debounce } from 'lodash';
import { TaskBlock } from 'pages/Task/components/TaskBlockInputList';
import { isPrintableKey } from 'utils/keyUp';
import ProjectDetailView from 'pages/Desk/components/SidePanel/ProjectDetailView';

export interface Props {
  index?: number;
  expanded?: boolean;
  date?: Date;
  doneStatus?: boolean;
  event: OutTaskboxDetailResponse;
  events?: OutTaskboxDetailResponse[];
  selectedEvent?: OutTaskboxDetailResponse;
  draggingTask?: WorkDragContext | null;
  multiSelected?: boolean;
  multiSelectIds?: string[];
  multiSelectAnchorEl?: HTMLElement | null;
  categoryList?: OutCategory[];
  templateList?: OutFrequentWork[];
  onChange?: (startDate: Date, endDate?: Date, move?: boolean) => void;
  onChangeSubtask?: (id: string, tasks: OutTaskboxDetailResponseForSubtask[]) => void;
  onChangeTaskboxImage?: (event: OutTaskboxDetailResponse) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onChangeMultiSelectIds?: (e: React.MouseEvent<HTMLDivElement>, eventId: string) => void;
  onCloseMultiSelect?: () => void;
  onClickMultiSelectActions?: (tpye: MultiSelectType, date?: Dayjs) => void;
  onMultiSelectDragStart?: (e: React.DragEvent<HTMLDivElement>, done: boolean) => void;
  onMultiSelectDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onChangeTimer?: (type: TimerActionType, time?: Date) => void;
  onFetchCategoryList?: () => void;
  onFetchTemplateList?: () => void;
}

//TODO 멀티셀렉 관련 함수 통합하기

type Tasks = {
  id: string;
  content?: string;
  beforeId?: string;
  done?: boolean;
};

export type DateType = 'tomorrow' | 'nextWeek' | 'calendar';
export type ContinueDateType = 'today' | 'tomorrow' | 'nextWeek' | 'calendar';
export type SubtaskContextMenu = 'CONVERT_TO_TASKBOX' | 'LATER' | 'DELETE' | 'CATEGORY' | 'DEADLINE';

export const Taskbox = forwardRef(
  (
    {
      index,
      expanded = true,
      doneStatus = false,
      date,
      event,
      events = [],
      selectedEvent,
      draggingTask,
      multiSelected = false,
      multiSelectIds = [],
      multiSelectAnchorEl,
      categoryList = [],
      templateList = [],
      onChange,
      onChangeSubtask,
      onChangeTaskboxImage,
      onClickTaskbox,
      onChangeMultiSelectIds,
      onCloseMultiSelect,
      onClickMultiSelectActions,
      onMultiSelectDragStart,
      onMultiSelectDragEnd,
      onChangeTimer,
      onFetchCategoryList,
      onFetchTemplateList,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [timeSelection, setTimeSelection] = useState<HTMLElement | null>(null);
    const [simpleTimeSelection, setSimpleTimeSelection] = useState<HTMLElement | null>(null);
    const [highlightTimeSelection, setHighlightTimeSelection] = useState<HTMLElement | null>(null);
    const [taskboxDetail, setTaskboxDetail] = useState<HTMLElement | null>(null);
    const [taskboxPostpone, setTaskboxPostpone] = useState<HTMLElement | null>(null);
    const [subTaskDetail, setSubTaskDetail] = useState<HTMLElement | null>(null);
    const [tasks, setTasks] = useState<OutTaskboxDetailResponseForSubtask[]>(event!.tasks!);
    const [subtaskId, setSubtaskId] = useState<string | undefined>();
    const [clickedIssue, setClickedIssue] = useState<string | undefined>();
    const [plan, setPlan] = useState<OutPlanWeekly[]>([]);
    const [taskboxHeight, setTaskboxHeight] = useState<number | undefined>();
    const [isDragging, setIsDragging] = useState(false);
    const [draggingToTaskbox, setDraggingToTaskbox] = useState(false);
    const [selectedTaskbox, setSelectedTaskbox] = useState<string | undefined>('');
    const [focusedTaskbox, setFocusedTaskbox] = useState<OutTaskboxDetailResponse | undefined>(selectedEvent);
    const [getCaret, setGetCaret] = useState<number | null>(null);
    const [inProgressPopover, setInProgressPopover] = useState<HTMLElement | null>(null);
    const [continueSettingPopover, setContinueSettingPopover] = useState<HTMLElement | null>(null);
    const [isOpenMultiSelectDeleteDialog, setIsOpenMultiSelectDeleteDialog] = useState(false);
    const [taskboxDone, setTaskboxDone] = useState<boolean>(event!.done!);
    const [updateRecurrence, setUpdateRecurrence] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const refTask = useRef<HTMLDivElement>(null);
    const refTaskbox = useRef<HTMLDivElement>(null);
    const refPopperMenu = useRef<HTMLDivElement>(null);
    const [, fetchTasks] = useAtom(tasksAtom);
    const [, setTaskboxDrag] = useAtom(dragContextAtom);
    const [focusModeTaskbox, setFocusModeTaskbox] = useAtom(focusModeTaskboxAtom);
    const [hideFocusMode, setHideFocusMode] = useAtom(hideFocusModeTaskboxAtom);
    const [deleteWithKeydown, setDeleteWithKeydown] = useState(false);
    const [deleteRecurrenceWithKeydown, setDeleteRecurrenceWithKeydown] = useState(false);
    const [openRecurrenceUpdate, setOpenRecurrenceUpdate] = useState(false);
    const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>('');
    const [projects, fetchProjects] = useAtom(projectsAtom);
    const [selectedProject, setSelectedProject] = useState<OutProject | null>(null);
    const [projectSubtasks, setProjectSubtasks] = useState<OutTaskboxDetailProjectOnly[]>([]);
    const [recommendTaskIndex, setRecommendTaskIndex] = useState<number>(0);
    const [isRecommendTask, setIsRecommendTask] = useState(false);
    const [delayRecommendTask, setDelayRecommendTask] = useState(false);

    useEffect(() => {
      handleDeleteSubtaskWithEmptyTitle();
    }, []);

    // useEffect(() => {
    //   if (event?.id !== selectedEvent?.id) {
    //     setTimeout(() => {
    //       handleDeleteSubtaskClickOutside();
    //     }, 100);
    //   }
    // }, [selectedEvent]);

    useEffect(() => {
      setTaskboxHeight(refTaskbox.current?.getBoundingClientRect().height);
    }, [tasks, focusedTaskbox, expanded]);

    useEffect(() => {
      if (getCaret) {
        focusInput(event!.id!);
      }
      setGetCaret(null);
    }, [getCaret]);

    useEffect(() => {
      if (selectedEvent !== focusedTaskbox) setFocusedTaskbox(undefined);
      if (selectedEvent) setFocusedTaskbox(selectedEvent);
    }, [selectedEvent]);

    useEffect(() => {
      if (event && event.project) {
        const project = projects.find((p) => p.id === event.project!.id);
        if (project && project?.tasks.length > 0) setProjectSubtasks(project!.tasks.filter((v) => v.type === 'PROJ_SOMEDAY_TASK' && !v.done) || []);
      }

      if (selectedProject) {
        const project = projects.find((p) => p.id === selectedProject.id);
        if (project) setSelectedProject(project);
      }
    }, [event, projects]);

    useEffect(() => {
      setTimeout(() => {
        if (delayRecommendTask) setDelayRecommendTask(false);
      }, 1000);
    }, [delayRecommendTask]);

    useEffect(() => {
      setTimeout(() => {
        if (delayRecommendTask) setDelayRecommendTask(false);
      }, 1000);
    }, [delayRecommendTask]);

    useLayoutEffect(() => {
      setTasks(event?.tasks ? event.tasks : []);
      if (focusModeTaskbox?.id === event?.id) {
        setFocusModeTaskbox(event!);
      }
    }, [event, event?.tasks]);

    useUpdateEffect(() => {
      setTaskboxDone(event!.done!);
      setIsExploding(event!.done!);
    }, [event!.done!]);

    useUpdateEffect(() => {
      setTimeout(() => {
        handleTaskboxDone();
      }, 100);
      setUpdateRecurrence(true);
    }, [tasks.length]);

    /**태스크박스 외부 클릭 시 태스크박스 닫기 */
    useClickOutside(refTaskbox, () => {
      if (event?.id === focusedTaskbox?.id) {
        setTimeout(() => {
          handleDeleteSubtaskClickOutside();
        }, 100);
      }

      if (
        focusedTaskbox !== selectedEvent &&
        !taskboxDetail &&
        !subTaskDetail &&
        !taskboxPostpone &&
        !timeSelection &&
        !simpleTimeSelection &&
        !highlightTimeSelection &&
        !inProgressPopover &&
        !continueSettingPopover &&
        !openTemplateDialog
      ) {
        setFocusedTaskbox(undefined);
      }
    });

    useClickOutside(refTask, () => {
      const element = document.activeElement as HTMLElement;
      if (element?.classList.contains('subtask')) {
        element.blur();
      }
      handleSelectedTaskId('');
    });

    /** 태스크박스 외부 클릭 시 멀티셀렉 풀리게 */
    useClickOutside(
      refPopperMenu,
      (e: Event) => {
        if (isOpenMultiSelectDeleteDialog) return;
        if ((e as MouseEvent)?.ctrlKey || (e as MouseEvent)?.metaKey) return;
        onCloseMultiSelect && onCloseMultiSelect();
      },
      ['mouseup'],
    );

    const generateTaskId = () => uuidv4();

    const showingTime = (event?: OutTaskboxDetailResponse, duration?: number) => {
      // 시간과 기간이 모두 제공되지 않으면 기본 메시지를 반환
      if (!event && duration === undefined) {
        return '시간 설정';
      }

      // 이벤트 객체가 제공된 경우
      if (event?.start?.datetime && event.end?.datetime) {
        const start = dayjs(event.start.datetime);
        const end = dayjs(event.end.datetime);
        duration = end.diff(start, 'minutes'); // 기간 계산
      }

      // 기간이 제공된 경우
      if (duration !== undefined) {
        const hour = Math.floor(duration / 60);
        const minute = duration % 60;

        const durationTime = hour === 0 ? `${minute}분` : minute === 0 ? `${hour}시간 동안` : `${hour}시간 ${minute}분 동안`;

        // 이벤트 객체가 제공된 경우 시간 포맷과 결합하여 반환
        if (event?.start?.datetime) {
          const timeboxTime = `${convertDatetimeFormat(event.start.datetime, TIME_FORMAT_2)}, ${durationTime}`;
          return timeboxTime;
        }

        // 이벤트 객체가 제공되지 않은 경우 기간만 반환
        return durationTime;
      }

      return '시간 설정';
    };

    /** 하위업무 만들고 바로 새로고침 시 title 없는 하위업무 제거 */
    const handleDeleteSubtaskWithEmptyTitle = async () => {
      if (event!.tasks!.length === 0) return;

      const lastTextIndex = event!
        .tasks!.slice()
        .reverse()
        .findIndex((task) => task.content !== '');

      if (lastTextIndex === -1) {
        setTasks([]);
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: [] });
      } else {
        const index = event!.tasks!.length - lastTextIndex - 1;
        if (index + 1 !== event!.tasks!.length) {
          const filter = event!.tasks!.slice(0, index + 1);
          setTasks(filter);
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: filter as UpdateTaskForTaskBox[] });
        }
      }
    };

    /** 태스크박스 외부 클릭 시 title 없는 하위업무 제거 */
    const handleDeleteSubtaskClickOutside = async () => {
      if (tasks.length === 0) return;

      const lastTextIndex = tasks
        .slice()
        .reverse()
        .findIndex((task) => task.content !== '');

      if (lastTextIndex === -1) {
        setTasks([]);
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: [] });
      } else {
        const index = tasks.length - lastTextIndex - 1;
        if (index + 1 !== tasks.length) {
          const filter = tasks.slice(0, index + 1);
          setTasks(filter);
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: filter as UpdateTaskForTaskBox[] });
        }
      }
    };

    /** 태스크박스 완료 */
    const handleTaskboxDone = async () => {
      if (taskboxDone && tasks.length !== tasks.filter((task) => task.done === true).length) {
        setTaskboxDone(false);
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { done: false });
        await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: tasks as UpdateTaskForTaskBox[] });
        onChange && onChange(date!);
      } else if (!taskboxDone && tasks.length > 0 && tasks.length === tasks.filter((task) => task.done === true).length) {
        setTaskboxDone(true);
        const deleteTask = async () => {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { done: true, tasks: tasks as UpdateTaskForTaskBox[] });
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: tasks as UpdateTaskForTaskBox[] });
          onChange && onChange(date!);
        };
        setTimeout(() => {
          deleteTask();
        }, 200);
      }
    };

    /** 태스크박스 상세창 닫기*/
    const handleCloseTimelineDetail = () => {
      setSelectedTaskbox(undefined);
      setTaskboxDetail(null);
    };

    /** 태스크박스 커서 focus*/
    const setFocusElement = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        el && setCaretToEnd(el);
      }, 20);
    };

    /** 타임라인 제목에서 blur 시 저장 */
    const handleBlurTitle = async (e: React.FocusEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const title = e.currentTarget.textContent;
      await new Promise((resolve) => setTimeout(resolve, 100));
      const categoryPopover = document.querySelector('.category-popover');
      const deadlinePopover = document.querySelector('.deadline-popover');
      if (categoryPopover || deadlinePopover) return;
      if (event.title !== title) {
        const targetTimeline: UpdateTaskbox = { title };
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, targetTimeline);
        if (success) {
          toast.success('태스크박스를 수정하였습니다.');
          onChange && onChange(date!);
        }
      }
    };

    /** 타임라인 시간 변경 */
    const handleChangeTime = async (
      startTime: string,
      endTime: string,
      timeDiff?: number,
      eventId?: string,
      isAllDay?: boolean,
      taskboxRecurrence?: InCreateTaskBoxInRecurrence,
    ) => {
      if (taskboxRecurrence) {
        if (event.isRecurrence)
          await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(eventId!, {
            ...taskboxRecurrence,
            isCancelled: taskboxRecurrence.recurrence.interval === 0,
          } as UpdateTaskBoxRecurrence);
        else await createRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPost(eventId!, taskboxRecurrence);
        await new Promise((resolve) => setTimeout(resolve, 300));
        toast.success('태스크박스를 수정하였습니다.');
        onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
      } else {
        const updateTaskbox: UpdateTaskbox = isAllDay
          ? {
              start: { date: dayjs(event.start?.date || event.start?.datetime).format(DATE_FORMAT_4) },
              end: {
                date: dayjs(event.start?.date || event.start?.datetime)
                  .add(1, 'day')
                  .format(DATE_FORMAT_4),
              },
              durationMin: timeDiff,
              beforeId: null,
            }
          : dayjs(endTime).diff(dayjs(startTime), 'day') > 0
          ? {
              start: { date: dayjs(startTime).format(DATE_FORMAT_4) },
              end: { date: dayjs(endTime).add(1, 'day').format(DATE_FORMAT_4) },
              beforeId: null,
            }
          : {
              start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
              end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
            };
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(eventId!, updateTaskbox);
        if (success) {
          toast.success('태스크박스를 수정하였습니다.');
          onChange && onChange(date!);
        }
      }
    };

    /** 종일 업무 하이라이트 지정하기 */
    const handleClickHighlightTime = async (startTime: string, endTime: string) => {
      const updateTaskbox: UpdateTaskbox = {
        start: { datetime: dayjs(startTime).format(DATE_FORMAT_1) },
        end: { datetime: dayjs(endTime).format(DATE_FORMAT_1) },
        focus: true,
      };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, updateTaskbox);
      if (success) {
        onChange && onChange(date!);
      }
    };

    /**태스크박스 시간 모달 열기 */
    const handleOpenTimeSelectionModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setTimeSelection(e.currentTarget);
      setSelectedTaskbox(event!.id!);
    };

    /**태스크박스 시간 모달 닫기 */
    const handleCloseTimeSelectionModal = () => {
      setTimeSelection(null);
      setSelectedTaskbox(undefined);
    };

    /** 태스크박스 하이라이트 시간 모달 열기 */
    const handleOpenHighlightTimeSelectionModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setHighlightTimeSelection(e.currentTarget);
      setSelectedTaskbox(event!.id!);
    };

    /**태스크박스 하이라이트 시간 모달 닫기 */
    const handleCloseHighlightTimeSelectionModal = () => {
      setHighlightTimeSelection(null);
      setSelectedTaskbox(undefined);
    };

    /** 타임라인 삭제 */
    const handleRemoveTimeline = async (event: OutTaskboxDetailResponse, params?: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      if (event.isRecurrence) {
        setDeleteRecurrenceWithKeydown(true);
        return;
      }

      const remove = await removeTaskboxV1TaskboxesTaskboxIdDelete(event.id!);
      setTaskboxDetail(null);
      if (remove) {
        toast.success('태스크박스를 삭제하였습니다.');
        onChange && onChange(date!);
      }
    };

    const handleDeleteRecurrence = async (target: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      setTaskboxDetail(null);
      setDeleteRecurrenceWithKeydown(false);
      const remove = await removeTaskboxV1TaskboxesTaskboxIdDelete(event!.id!, target);
      if (remove) {
        toast.success('반복 업무를 삭제하였습니다.');
        onChange && onChange(date!);
      }
    };

    useKeyboardEvent(
      true,
      (ev) => {
        if ((osName === 'Windows' && ev.code === 'Delete') || (osName === 'Mac OS' && ev.code === 'Backspace' && ev.metaKey)) {
          if (!focusedTaskbox) return;
          if (focusedTaskbox.id === event?.id) setDeleteWithKeydown(true);
        }

        if (ev.code === 'KeyT' && !ev.altKey && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
          if (!focusedTaskbox) return;
          const element = document.activeElement as HTMLElement;
          const childElement = element.childNodes[0] as HTMLElement;

          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true') ||
            childElement.classList.contains('deadline-popover')
          ) {
            return;
          }
          const el = document.querySelector(`[data-timeselectionid="${event!.id}"]`) as HTMLDivElement;
          if (el && event!.id === focusedTaskbox.id) {
            setTimeSelection(el);
            setSelectedTaskbox(event!.id!);
          }
        }

        if (ev.code === 'KeyF') {
          if (!focusedTaskbox) return;
          const element = document.activeElement as HTMLElement;
          if (!element) return;
          if (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
            (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
          ) {
            return;
          }
          handleClickFocusMode(focusedTaskbox);
          element.blur();
        }

        if (ev.code === 'KeyF' && ev.shiftKey && ev.altKey) {
          if (!focusedTaskbox) return;
          const element = document.activeElement as HTMLElement;
          handleClickFocusMode(focusedTaskbox);
          element.blur();
        }

        // if (ev.code === 'ArrowDown') {
        //   if (!focusedTaskbox) return;
        //   ev.stopPropagation();
        //   const element = document.activeElement as HTMLElement;
        //   if (!element) return;
        //   if (
        //     element.tagName === 'TEXTAREA' ||
        //     (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        //     (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        //   ) {
        //     return;
        //   }
        //   const index = events.findIndex((item) => item.id === focusedTaskbox.id);
        //   const nextTaskbox = events[index + 1];
        //   if (nextTaskbox) {
        //     handleClickTaskbox(nextTaskbox);
        //   }
        // }

        // if (ev.code === 'ArrowUp') {
        //   if (!focusedTaskbox) return;
        //   ev.stopPropagation();

        //   const element = document.activeElement as HTMLElement;
        //   if (!element) return;
        //   if (
        //     element.tagName === 'TEXTAREA' ||
        //     (element.tagName === 'INPUT' && (element as HTMLInputElement).type === 'text') ||
        //     (element.tagName === 'DIV' && (element as HTMLDivElement).contentEditable === 'true')
        //   ) {
        //     return;
        //   }
        //   const index = events.findIndex((item) => item.id === focusedTaskbox.id);
        //   const prevTaskbox = events[index - 1];
        //   if (prevTaskbox) {
        //     handleClickTaskbox(prevTaskbox);
        //   }
        // }
      },
      [],
      { eventOptions: { passive: true } },
    );

    /** 타임라인 키다운 로직 */
    const handleTimelineKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const filteredTimeline = events;
      const title = e.currentTarget.textContent;
      const filteredTimelineIndex = filteredTimeline.map(({ id }) => id).indexOf(event.id);

      if (e.key === 'Enter') {
        if (event.title === title) {
          e.preventDefault();
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            done: doneStatus,
          };
          const taskList: Tasks[] = [newTask, ...(tasks as Tasks[])];
          setTasks(taskList);
          setFocusElement(newTask.id);
          onChangeSubtask && onChangeSubtask(event.id!, taskList);
          handleSelectedTaskId && handleSelectedTaskId(newTask.id);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        } else {
          e.preventDefault();
          if (e.nativeEvent.isComposing) return;
          const targetTimeline: UpdateTaskbox = { title };
          const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, targetTimeline);
          if (success) {
            toast.success('태스크박스를 수정하였습니다.');
            onChange && onChange(date!);
          }

          setFocusElement(event.id!);
        }
        return;
      }

      if (e.key === 'Backspace') {
        if (title!.length !== 0) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        const prevTimeline = filteredTimelineIndex !== 0 ? filteredTimeline[filteredTimelineIndex - 1] : filteredTimeline[filteredTimeline.length - 1];
        if (prevTimeline) {
          if (prevTimeline.tasks && prevTimeline.tasks.length !== 0) {
            setFocusElement(prevTimeline.tasks![prevTimeline.tasks!.length - 1].id!);
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(prevTimeline.tasks![prevTimeline.tasks!.length - 1].id!);
            }, 0);
          } else {
            setFocusElement(prevTimeline.id!);
          }
          handleClickTaskbox(prevTimeline);
        }
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (event.tasks && event.tasks.length !== 0) {
          setFocusElement(event.tasks[0].id!);
          handleSelectedTaskId && handleSelectedTaskId(event.tasks[0].id!);
        } else {
          const nextTimeline = filteredTimeline[filteredTimelineIndex + 1];
          if (nextTimeline) {
            setFocusElement(nextTimeline.id!);
            handleClickTaskbox(nextTimeline);
          } else {
            setFocusElement(filteredTimeline[0].id!);
            handleClickTaskbox(filteredTimeline[0]);
          }
        }
        return;
      }

      if (e.key === '#') {
        if (event.id === focusedTaskbox!.id!) {
          const active = document.activeElement as HTMLElement;

          setTimeout(() => {
            active.blur();
            setIsQuickCategory(true);
            setCategoryAnchorEl(refTaskboxInput.current);
          }, 100);
        }
      }

      if (e.key === '$') {
        if (event.id === focusedTaskbox!.id!) {
          const active = document.activeElement as HTMLElement;
          setTimeout(() => {
            active.blur();
            setIsQuickDeadline(true);
            setDeadlineAnchorEl(refTaskboxInput.current);
          }, 100);
        }
      }
    };

    /** tasks 로직 */
    const createTask = (newTask: Tasks) => {
      setTasks([...tasks, newTask]);
    };

    const insertTask = (index: number, newTask: Tasks) => {
      tasks.splice(index + 1, 0, newTask);
      setTasks([...tasks]);
    };

    const removeTask = (taskId: string) => {
      setTasks(tasks.filter(({ id }) => id !== taskId));
    };

    const debouncedOnChangeTermTask = useCallback(
      debounce(() => {
        onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
      }, 5000),
      [],
    );

    const debouncedOnChangeProjectTask = useCallback(
      debounce(() => {
        fetchProjects();
      }, 1000),
      [],
    );

    const [isInstanceTyping, setIsInstanceTyping] = useState(false);
    const [isRemoveTextAndRecommend, setIsRemoveTextAndRecommend] = useState(false);

    /** 태스크 키다운 로직 */
    const handleTaskKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, taskId: string, index: number) => {
      if (delayRecommendTask) {
        e.preventDefault();
        return;
      }

      if (isRemoveTextAndRecommend) setIsRemoveTextAndRecommend(false);

      const text = e.currentTarget.textContent || '';
      if (text !== '') setIsRecommendTask(false);

      // 인스턴스 타이핑 시작하면 추천 태스크 숨김
      if (focusedTaskbox?.project && isPrintableKey(e.code)) setIsInstanceTyping(true);

      if (e.key === 'Escape') {
        e.preventDefault();
        e.currentTarget.blur();
      }

      if (e.nativeEvent.isComposing === false && e.key === 'Enter' && event) {
        const caret = getCaretPosition(e.target);
        const taskIndex = tasks.map(({ id }) => id).indexOf(taskId);
        if (e.repeat) {
          e.preventDefault();
          return;
        }
        if (caret === text.length) {
          if (isInstanceTyping) {
            setTimeout(() => {
              setIsInstanceTyping(false);
            }, 30);
          }
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId: taskId,
            done: doneStatus,
          };
          insertTask(taskIndex, newTask);
          onChangeSubtask && onChangeSubtask(event.id!, tasks);
          setFocusElement(newTask.id);
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
          }, 50);
          e.preventDefault();
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        } else if (caret === 0 && event) {
          if (isInstanceTyping) {
            setTimeout(() => {
              setIsInstanceTyping(false);
            }, 30);
          }
          const beforeId = tasks[taskIndex - 1] ? tasks[taskIndex - 1].id : undefined;
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId,
            done: doneStatus,
          };
          insertTask(taskIndex - 1, newTask);
          onChangeSubtask && onChangeSubtask(event.id!, tasks);
          setFocusElement(newTask.id);
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
          }, 50);
          e.preventDefault();
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        } else {
          const prevText = text.slice(0, caret);
          const nextText = text.slice(caret);
          const targetTask = { ...tasks[taskIndex], content: prevText };
          const newTask: Tasks = {
            id: generateTaskId(),
            content: nextText,
            beforeId: taskId,
            done: doneStatus,
          };
          tasks[taskIndex] = targetTask;
          tasks.splice(index + 1, 0, newTask);
          setTasks([...tasks]);
          setFocusElement(taskId);
          onChangeSubtask && onChangeSubtask(event.id!, tasks);
          setFocusElement(newTask.id);
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
          }, 50);
          e.preventDefault();
          await updateSubtaskV1TasksTaskIdPatch(tasks[taskIndex].id!, targetTask);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        }
      }

      if (e.key === 'Backspace' && event) {
        if (e.repeat) {
          if (text.length === 0) e.preventDefault();
          return;
        }

        const caret = getCaretPosition(e.target);
        const taskIndex = tasks.map(({ id }) => id).indexOf(taskId);

        //커서가 맨 앞에 있을 시 이전 현재 하위업무 삭제하고 하위업무와 합침
        if (caret === 0 && text.length > 0) {
          if (taskIndex === 0) return;
          const prevTask = tasks[taskIndex - 1];
          const newPrevTask = { ...prevTask, content: prevTask.content! + text };
          tasks[taskIndex - 1] = newPrevTask;
          const newTaskList = tasks.filter(({ id }) => id !== taskId);
          setTasks([...tasks]);
          onChangeSubtask && onChangeSubtask(event.id!, newTaskList);
          setFocusElement(newPrevTask.id!);
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newPrevTask.id!);
          }, 50);

          await updateSubtaskV1TasksTaskIdPatch(tasks[taskIndex - 1].id!, newPrevTask);
          await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, taskId);
        } else {
          if (isInstanceTyping && text.length === 1) {
            setTimeout(() => {
              setIsInstanceTyping(false);
            }, 30);
          }

          if (focusedTaskbox?.project && text.length === 1 && tasks.find(({ id }) => id === taskId)?.content !== '') {
            setIsRemoveTextAndRecommend(true);
          }

          if (text.length !== 0) return;
          const findIndex = index === 0 ? index + 1 : index - 1;
          const task = tasks[findIndex];
          if (task) {
            setFocusElement(task.id!);
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(task.id!);
            }, 50);
          } else {
            setFocusElement(event.id!);
            handleSelectedTaskId && handleSelectedTaskId('');
          }
          const changeTasks = tasks.filter(({ id }) => id !== taskId);
          removeTask(taskId);
          onChangeSubtask && onChangeSubtask(event.id!, changeTasks);

          await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, taskId);
        }
      }

      if (e.key === 'ArrowDown') {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        const filteredTimeline = events!;
        if (index === tasks.length - 1) {
          const timeboxIndex = filteredTimeline!.map(({ id }) => id).indexOf(event!.id);
          if (filteredTimeline[timeboxIndex + 1]) {
            setFocusElement(filteredTimeline[timeboxIndex + 1].id!);
            handleClickTaskbox(filteredTimeline[timeboxIndex + 1]);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          } else {
            setFocusElement(filteredTimeline[0].id!);
            handleClickTaskbox(filteredTimeline[0]);
            handleSelectedTaskId && handleSelectedTaskId('');
            e.preventDefault();
          }
        } else {
          const task = tasks[index + 1];
          if (task) {
            setFocusElement(task.id!);
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(task.id!);
            }, 50);
          }
          e.preventDefault();
        }
        return;
      }

      if (e.key === 'ArrowUp') {
        if (isInstanceTyping) {
          setTimeout(() => {
            setIsInstanceTyping(false);
          }, 30);
        }
        if (index === 0) {
          setFocusElement(event!.id!);
          handleSelectedTaskId && handleSelectedTaskId('');
          e.preventDefault();
        } else {
          const task = tasks[index - 1];
          if (task) {
            setFocusElement(task.id!);
            setTimeout(() => {
              handleSelectedTaskId && handleSelectedTaskId(task.id!);
            }, 50);
          }
          e.preventDefault();
        }
        return;
      }

      if (event.project && text === '') {
        if (e.key === 'ArrowRight') {
          setRecommendTaskIndex((prev) => (prev >= projectSubtasks.length - 1 ? 0 : prev + 1));
        }

        if (e.key === 'ArrowLeft') {
          setRecommendTaskIndex((prev) => (prev > 0 ? prev - 1 : projectSubtasks.length - 1));
        }

        if (e.key === 'Tab') {
          e.preventDefault();
          if (projectSubtasks.length === 0) return;
          if (delayRecommendTask) return;

          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId: taskId,
          };

          insertTask(tasks.map(({ id }) => id).indexOf(taskId), newTask);
          onChangeSubtask && onChangeSubtask(event.id!, tasks);
          if (event.type === 'TERM_TASK') {
            setFocusElement(`${newTask.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
          } else {
            setFocusElement(newTask.id);
          }
          setTimeout(() => {
            handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
            if (event.project) {
              setIsRecommendTask(true);
              if (event.project && projectSubtasks.length > 0) setDelayRecommendTask(true);
            }
          }, 50);
          e.preventDefault();
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask);
        }
      }

      if (event.type === 'TERM_TASK') {
        debouncedOnChangeTermTask();
      }

      // if (event.project) {
      //   debouncedOnChangeProjectTask();
      // }
    };

    /** 업무를 추가해주세요 클릭 시 테스크 생성 */
    const handleClickTaskAdd = async (event: OutTaskboxDetailResponse) => {
      handleClickTaskbox(event);
      const prevTask = tasks[tasks.length - 1];
      const newTask: Tasks = {
        id: generateTaskId(),
        content: '',
        beforeId: prevTask && prevTask.id,
        done: doneStatus,
      };

      createTask(newTask);
      const taskList = [...tasks, newTask];
      onChangeSubtask && onChangeSubtask(event.id!, taskList);
      if (event.type === 'TERM_TASK') {
        setFocusElement(`${newTask.id!}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`);
      } else {
        setFocusElement(newTask.id!);
      }
      setTimeout(() => {
        handleSelectedTaskId && handleSelectedTaskId(newTask.id!);
      }, 50);
      event && (await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask as CreateTaskboxTask));
    };

    /** Task 완료 여부 저장 */
    const handleCheckbox = async (taskIndex: number) => {
      setTasks([...tasks]);
      onChangeSubtask && onChangeSubtask(event.id!, tasks);
      const success = await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event!.id!, tasks[taskIndex].id!, { done: !tasks[taskIndex].done });
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!);
        }
        tasks[taskIndex].done = !tasks[taskIndex].done;
      }
    };

    /** focus out 시 변경된 하위업무 저장 */
    const handleSaveChangedTask = async (e: React.FocusEvent<HTMLDivElement>, taskIndex: number) => {
      const content = e.currentTarget.textContent!;

      if (projectSubtasks.length > 0 && isRecommendTask && tasks[taskIndex].content === '' && event.project) {
        if (recommendTaskIndex === projectSubtasks.length - 1) {
          setRecommendTaskIndex(0);
        }
        setProjectSubtasks(projectSubtasks.filter((subtask) => subtask.id !== projectSubtasks[recommendTaskIndex].id));

        const targetTask = { ...tasks[taskIndex], content: projectSubtasks[recommendTaskIndex].title };

        tasks[taskIndex] = targetTask;
        setTasks([...tasks]);
        onChangeSubtask && onChangeSubtask(event.id!, tasks);
        setIsRecommendTask(false);

        const assignTask = {
          tasks: [projectSubtasks[recommendTaskIndex].id!],
          beforeId: tasks[taskIndex - 1] && tasks[taskIndex - 1].id ? tasks[taskIndex - 1].id! : null,
        };

        await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, tasks[taskIndex].id!);
        await assignTasksV1TaskboxesTaskboxIdAssignPost(event.id!, assignTask);

        const updatedTask = {
          ...tasks[taskIndex],
          id: projectSubtasks[recommendTaskIndex].id!,
          content: projectSubtasks[recommendTaskIndex].title,
        };

        tasks[taskIndex] = updatedTask;
        setTasks([...tasks]);
        onChangeSubtask && onChangeSubtask(event.id!, tasks);

        // fetchProjects();
        return;
      }

      if (tasks[taskIndex].content !== content) {
        const targetTask = { ...tasks[taskIndex], content };
        tasks[taskIndex] = targetTask;
        setTasks([...tasks]);
        onChangeSubtask && onChangeSubtask(event.id!, tasks);
        await updateSubtaskV1TasksTaskIdPatch(tasks[taskIndex].id!, targetTask);
      }
    };

    /**이슈 상세창 열기 */
    const handleClickIssueDetail = (id: string) => {
      setClickedIssue(id);
      // setSelectedTasks(event?.tasks ? event.tasks : []);
    };

    /**이슈 상세창 닫기 */
    const handleClickCancelIssue = () => {
      setClickedIssue(undefined);
    };

    /**이슈 상세창 저장 */
    const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
      if (!value) return;

      await updateIssueV1WorksIssuesIssueIdPut(id, { content: value.content, description: value.description, dueDate: value.dueDate, tasks: value.tasks });

      // if (tasks.filter((task) => task.simple === true).length > 0) {
      //   const selectedTask = tasks.filter((task) => selectedTaskIds?.includes(task.id));
      //   const seletedId = selectedTask.map((item) => item.id);

      //   const addId = selectedTaskIds!.filter((id) => !seletedId.includes(id));
      //   const addTask: UpdateTaskForTaskBox[] = value.tasks!.filter((v) => addId.includes(v.id!)).map(({ id, content }) => ({ id: id!, content }));

      //   const renewalTask = [...selectedTask, ...tasks.filter((v) => v.simple === true)];
      //   const newTask: UpdateTaskForTaskBox[] = [...renewalTask, ...addTask];

      //   await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // } else {
      //   const newTask = value.tasks!.filter((task) => selectedTaskIds?.includes(task.id!)).map(({ id, content }) => ({ id: id!, content }));
      //   await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // }

      // onChange();
      setClickedIssue(undefined);
    };

    /**태스크박스 완료 체크 */
    const handleClickTaskboxCheck = async () => {
      if (event) {
        // setTaskboxDone(!event.done);
        const updateTaskbox: UpdateTaskbox = { done: !taskboxDone, tasks: tasks.map((task) => ({ ...task, done: !taskboxDone })) as UpdateTaskForTaskBox[] };
        const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, updateTaskbox);
        // if (tasks.length === 0) setTaskDone(!taskDone);
        if (success) {
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
        }
      }
    };

    /** 프로젝트 일부 완료하기 */
    const handleClickInstanceContinue = async () => {
      setInProgressPopover(null);
      if (!event) return;

      const targetProject = projects.find((project) => project.id === event.project?.id);
      if (!targetProject) return;

      const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
      const index = instances.findIndex((instance) => instance.id === event.id);
      const nextInstance = instances[index + 1];

      const assignTasks = tasks.filter((task) => !task.done).map((task) => task.id!);

      try {
        if (nextInstance) {
          const nextInstanceTasks = nextInstance.tasks;
          await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
            beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
            tasks: assignTasks,
          });
          toast.success('미완료 업무가 프로젝트의 다음 인스턴스로 이동했습니다.');
        } else {
          await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
            taskIds: assignTasks,
          });
          toast.success('미완료 업무가 프로젝트의 미할당으로 이동했습니다.');
        }
      } catch (error) {
        toast.error('일부 완료에 실패했습니다.');
      }

      onChange?.(date!);
      fetchProjects();
    };

    /** 태스크박스 날짜 변경 */
    const handleChangeTaskboxDate = async (taskboxDate: Date | null, isAllDay = false, dateType: DateType | null = null) => {
      // if (!selectedTaskbox) return;
      if (!event) return;
      if (taskboxDate) {
        const start = isAllDay
          ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
          : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
        const end = isAllDay
          ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
          : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

        await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { start, end });

        if (dateType === 'tomorrow') {
          onChange && onChange(date!);
          toast.success('태스크박스를 다음 날로 옮겼습니다.');
        } else if (dateType === 'nextWeek') {
          onChange && onChange(date!);
          toast.success('태스크박스를 다음주 월요일로 옮겼습니다.');
        } else if (dateType === 'calendar') {
          onChange && onChange(date!);
          toast.success(`태스크박스를 ${dayjs(taskboxDate).format(DATE_FORMAT_5)}로 옮겼습니다.`);
        }
      } else {
        await updateTaskboxV1TaskboxesTaskboxIdPut(event.id!, { workSectionType: 'someday' });
        toast.success('태스크박스를 나중에로 옮겼습니다.');
        onChange && onChange(date!);
      }
    };

    /**태스크박스 드래그 시작될 때 로직 */
    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      onChangeTaskboxImage && onChangeTaskboxImage(event!);
      const el = document.querySelector('#taskbox-drag-image');
      e.dataTransfer.setDragImage(el!, 40, 3);
      if (event) setTaskboxDrag({ type: 'taskbox', view: 'ritual', id: event.id, title: event.title!, data: event?.tasks, done: event.done });
    };

    /**서브태스크 상세창 닫는 로직 */
    const handleCloseSubTaskDetail = () => {
      handleSelectedTaskId && handleSelectedTaskId('');
      setSubTaskDetail(null);
    };

    /**서브태스크 detail 로직 */
    const handleClickSubtaskContextMenu = async (taskId: string, type: SubtaskContextMenu) => {
      if (type === 'CONVERT_TO_TASKBOX') {
        setSubTaskDetail(null);
        removeTask(taskId);
        const success = await subtaskToTaskV1TaskboxesSubtasksTaskTypePatch({
          subtaskIds: [taskId],
          start: { date: dayjs(date).format(DATE_FORMAT_4) },
          end: { date: dayjs(date).format(DATE_FORMAT_4) },
        });
        if (success) {
          if (event.type === 'TERM_TASK') {
            onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
          } else {
            onChange && onChange(date!);
          }
        }
      } else if (type === 'LATER') {
        setSubTaskDetail(null);
        removeTask(taskId);
        if (event.project) {
          const targetProject = projects.find((project) => project.id === event.project?.id);
          if (!targetProject) return;

          const instances = targetProject.tasks.filter((task) => task.type !== 'PROJ_SOMEDAY_TASK');
          const index = instances.findIndex((instance) => instance.id === event.id);
          const nextInstance = instances[index + 1];

          try {
            if (nextInstance) {
              const nextInstanceTasks = nextInstance.tasks;
              await assignTasksV1TaskboxesTaskboxIdAssignPost(nextInstance.id!, {
                beforeId: nextInstanceTasks && nextInstanceTasks.length > 0 ? nextInstanceTasks[nextInstanceTasks.length - 1].id : null,
                tasks: [taskId],
              });
              toast.success('프로젝트의 다음 인스턴스로 이동했습니다.');
            } else {
              await assignTasksToProjectV1ProjectProjectIdAssigningTaskPost(targetProject.id!, {
                taskIds: [taskId],
              });
              toast.success('프로젝트의 미할당으로 이동했습니다.');
            }
          } catch (error) {
            toast.error('일부 완료에 실패했습니다.');
          }

          onChange?.(date!);
          if (nextInstance) onChange?.(dayjs(nextInstance.matchDate![0]).toDate());
          fetchProjects();
        } else {
          await subtaskToTaskV1TaskboxesSubtasksTaskTypePatch({
            subtaskIds: [taskId],
          });
          setTimeout(() => {
            fetchTasks();
          }, 100);
        }
      } else if (type === 'DELETE') {
        setSubTaskDetail(null);
        removeTask(taskId);
        await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event!.id!, taskId);
        const changeTasks = tasks.filter(({ id }) => id !== taskId);
        onChangeSubtask && onChangeSubtask(event.id!, changeTasks);

        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!);
        }
      }
    };

    /**태스크박스에서 캘린더로의 드래그 시작되는 것 알리는 로직  */
    const handleDragToCalendarStart = () => {
      // 드래그 핸들에서 드래그 시작 시 호출되는 콜백 함수
      setIsDragging(true);
    };

    /**태스크박스에서 캘린더로의 드래그 끝나는 것 알리는 로직 */
    const handleDragEnd = () => {
      setTaskboxDrag(null);
    };

    /**태스크박스 락인/락아웃 로직 */
    const handleLockInOutButton = async (lock: boolean) => {
      if (event?.recurrence) return;

      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, {
        lockedIn: !lock,
      });

      if (success && lock) {
        toast.success('구글 캘린더에서 할 일을 표시 해제했습니다.');
        onChange && onChange(date!);
      } else if (success && !lock) {
        toast.success('구글 캘린더에 할 일을 표시했습니다.');
        onChange && onChange(date!);
      }
    };

    /**태스크박스에 태스크 들어왔을 때 로직 */
    const handleDragEnterToTaskbox = () => {
      if (!draggingTask || draggingTask.id === event?.id) return;
      if (draggingTask.type === 'multi-taskbox' && draggingTask.data.includes(event!.id!)) return;
      if (['task', 'taskbox', 'multi-taskbox'].includes(draggingTask!.type!)) {
        setDraggingToTaskbox(true);
      }
    };

    /**태스크박스에서 태스크 떠났을 때 로직 */
    const handleDragLeaveFromTaskbox = (e: React.DragEvent<HTMLDivElement>) => {
      if (e.relatedTarget instanceof Node && e.currentTarget.contains(e.relatedTarget)) return;
      setDraggingToTaskbox(false);
    };

    /**태스크를 태스크박스 안에 드랍하여 하위 태스크로 만드는 로직 */
    const handleDragDropToTaskbox = async (eventId: string) => {
      setDraggingToTaskbox(false);
      if (draggingTask && eventId) {
        const { data } = draggingTask;
        const tasks =
          draggingTask.view === 'backlog' ? [...data.map((tasks: any) => tasks.id)] : draggingTask.type === 'multi-taskbox' ? [...data] : [draggingTask.id];
        const result = await assignTasksV1TaskboxesTaskboxIdAssignPost(eventId, { tasks });
        if (result) {
          onChange && onChange(date!);
          setTimeout(() => {
            fetchTasks();
          }, 100);
        }
      }
    };

    /** 타임라인에서 할 일 목록으로 옮기기 위해 시간 없애는 로직 */
    const handleUnSelectTime = async (eventId: string) => {
      const formattedDate = dayjs(date).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = {
        start: { date: formattedDate },
        end: { date: dayjs(formattedDate).add(1, 'day').format(DATE_FORMAT_4) },
        beforeId: null,
      };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(eventId, updateTaskbox);

      if (success) {
        toast.success('태스크박스를 수정하였습니다.');
        //TODO 논의사항 : 태스크박스가 엄청 쌓여있으면 잘 못 알아봄
        onChange && onChange(date!);
      }
    };

    const focusInput = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        const range = document.createRange();
        const selection = window.getSelection()!;
        range.selectNodeContents(el);
        range.setStart(el.childNodes[0], getCaret!);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 50);
    };

    const handleClickTaskbox = (event: OutTaskboxDetailResponse) => {
      onClickTaskbox && onClickTaskbox(event);
    };

    const handleCloseInProgressPopover = () => {
      setSelectedTaskbox(undefined);
      setInProgressPopover(null);
    };

    const handleCloseContinueSettingPopover = () => {
      setContinueSettingPopover(null);
    };

    const handleChangeInprogress = async (taskboxDate: Date | null) => {
      setInProgressPopover(null);
      setContinueSettingPopover(null);

      if (!event) return;
      if (!taskboxDate) return;

      const start = event.allDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = event.allDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(event.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      const doneTasks = tasks.filter((task) => task.done) as UpdateTaskForTaskBox[];
      const undoneTasks = tasks
        .filter((task) => !task.done)
        .map((task) => ({
          ...task,
          content: task.content || '',
        })) as CreateTaskboxTask[];

      const doneTaskbox: UpdateTaskbox = { tasks: doneTasks, done: true };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, doneTaskbox);

      const continueTaskbox: CreateTaskbox = { ...event, id: uuidv4(), title: event.title!, tasks: undoneTasks, start, end };
      const success2 = await createTaskboxV1TaskboxesPost(continueTaskbox);

      if (success && success2) {
        onChange && onChange(date!);
        toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다.');
      }
    };

    const handleCloseRecurrenceUpdateDialog = () => {
      setSelectedTaskbox(undefined);
      setOpenRecurrenceUpdate(false);
    };

    const handleOpenRecurrenceUpdateDialog = () => {
      setSelectedTaskbox(event!.id!);
      setOpenRecurrenceUpdate(true);
    };

    // 이후 반복 업무에 변경사항 반영
    const handleReflectRecurrence = async () => {
      const inRecurrenceAction: InRecurrenceAction = { target: 'this_and_following' };
      await syncRecurringTaskboxesV1TaskboxesTaskboxIdSyncPut(event!.id!, inRecurrenceAction);
      toast.success('이후 반복 업무에 변경사항이 반영되었습니다.');
    };

    // 반복 업무 시간 해제하기
    const handleRemoveRecurrenceTime = async (target: InCreateTaskBoxInRecurrenceTarget) => {
      setOpenRecurrenceUpdate(false);
      const taskBoxInRecurrence: UpdateTaskBoxRecurrence = {
        target: target as RecurrenceActionType,
        recurrence: event!.recurrence as InRecurrence,
        start: { date: dayjs(date).format(DATE_FORMAT_4) },
        end: { date: dayjs(date).format(DATE_FORMAT_4) },
      };
      await updateRecurringTaskboxesV1TaskboxesTaskboxIdRecurrencesPatch(event!.id!, taskBoxInRecurrence);
      toast.success('태스크박스를 수정하였습니다.');
      onChange && onChange(date!);
    };

    const handleOpenTemplateDialog = () => {
      setSelectedTaskbox(event!.id!);
      setOpenTemplateDialog(true);
    };

    const handleCloseTemplateDialog = () => {
      setOpenTemplateDialog(false);
    };

    const handlePutTemplate = async (frequentWork: OutFrequentWork) => {
      const success = await updateFrequentWorkV1FrequentworksFrequentWorkIdPut(frequentWork.id!, {
        ...frequentWork,
        categoryId: frequentWork.category ? [frequentWork.category.id] : [],
        tasks: frequentWork.tasks!.map((v) => ({ ...v, categoryId: v.category ? [v.category.id] : [] })),
      });
      if (success) {
        onFetchTemplateList && onFetchTemplateList();
      }
    };

    const handleDeleteTemplate = async (frequentWork: OutFrequentWork) => {
      const success = await deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete(frequentWork.id!);
      if (success) {
        onFetchTemplateList && onFetchTemplateList();
        toast.success('템플릿이 삭제되었습니다.');
      }
    };

    const handleTemplateToTaskbox = async (frequentWork: OutFrequentWork) => {
      setFocusedTaskbox(event);
      // const assignTasks = frequentWork!.tasks!.map((v) => v.id);
      // await assignTasksV1TaskboxesTaskboxIdAssignPost(event!.id!, { tasks: assignTasks });

      const newTasks = frequentWork!.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] }));
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: [...event!.tasks!, ...newTasks] as UpdateTaskForTaskBox[] });

      if (success) {
        setOpenTemplateDialog(false);
        onChange && onChange(date!);
      }
    };

    const handleHighlightTaskbox = async () => {
      setIsExploding(false);
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { focus: !event?.focus });
      if (success) {
        onChange && onChange(date!);
      }
    };

    const handleOpenMultiSelectDeleteDialog = () => {
      setIsOpenMultiSelectDeleteDialog(true);
    };

    const handleCloseMultiSelectDeleteDialog = () => {
      setIsOpenMultiSelectDeleteDialog(false);
    };

    const handleOpenSimpleTimeSelectionModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setSelectedTaskbox(event!.id!);
      setSimpleTimeSelection(e.currentTarget);
    };

    const handleCloseSimpleTimeSelectionModal = () => {
      setSimpleTimeSelection(null);
    };

    const handleClickFocusMode = async (taskbox: OutTaskboxDetailResponse = event!) => {
      if (!taskbox) return;
      setFocusModeTaskbox(taskbox!);
      setHideFocusMode(false);
      if (taskbox.start?.datetime)
        onChangeTimer &&
          onChangeTimer(
            'RESET',
            dayjs()
              .add(dayjs(taskbox.end?.datetime).diff(dayjs(taskbox.start?.datetime)))
              .toDate(),
          );
    };

    const handleCloseFocusMode = async (taskbox?: UpdateTaskbox | null) => {
      if (!taskbox) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        onChange && onChange(date!);
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
        if (event.project) fetchProjects();
        return;
      }

      const typeTaskbox = taskbox as OutTaskboxDetailResponse;
      const { memo, category, ...updateTaskbox } = typeTaskbox;
      const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

      const tasks =
        (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
          return {
            ...task,
            content: task.content,
            categoryId: task.category ? [task.category.id] : [],
          };
        }) as UpdateTaskForTaskBox[]) || [];

      // 마지막 항목이 비어있다면 삭제
      if (tasks.length > 0) {
        for (let i = tasks.length - 1; i >= 0; i--) {
          if (tasks[i].content === '') tasks.pop();
          else break;
        }
      }
      taskbox.tasks = tasks;

      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (taskbox.workSectionType) fetchTasks();
        if (event.project) fetchProjects();
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
      }
    };

    const handleHideFocusMode = async (taskbox: UpdateTaskbox) => {
      if (!taskbox) return;

      const typeTaskbox = taskbox as OutTaskboxDetailResponse;
      const { memo, category, ...updateTaskbox } = typeTaskbox;
      const categoryId = typeTaskbox.category ? [typeTaskbox.category.id] : [];

      const tasks =
        (taskbox!.tasks!.map((task: OutTaskboxDetailResponseForSubtask) => {
          return {
            ...task,
            content: task.content,
            categoryId: task.category ? [task.category.id] : [],
          };
        }) as UpdateTaskForTaskBox[]) || [];

      // 마지막 항목이 비어있다면 삭제
      if (tasks.length > 0) {
        for (let i = tasks.length - 1; i >= 0; i--) {
          if (tasks[i].content === '') tasks.pop();
          else break;
        }
      }
      taskbox.tasks = tasks;

      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { ...(updateTaskbox as UpdateTaskbox), categoryId });
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        if (event.project) fetchProjects();
        setHideFocusMode(true);
      }
    };

    const handleDeleteFocusMode = async (taskbox: OutTaskboxDetailResponse) => {
      const success = await removeTaskboxV1TaskboxesTaskboxIdDelete(focusModeTaskbox!.id!);
      if (success) {
        if (event.type === 'TERM_TASK') {
          onChange && onChange(dayjs(event.matchDate?.[0]).toDate(), dayjs(event.matchDate?.[event.matchDate?.length - 1]).toDate());
        } else {
          onChange && onChange(date!, dayjs(date).add(7, 'day').toDate());
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        setFocusModeTaskbox(null);
        setHideFocusMode(false);
        toast.success('태스크박스를 삭제하였습니다.');
      }
    };

    const handleCreateTemplate = async (taskbox: OutTaskboxDetailResponse) => {
      const newTemplate: CreateFrequentWork = {
        id: uuidv4(),
        title: taskbox?.title,
        tasks: taskbox!.tasks!.map((item) => ({ ...item, id: uuidv4(), categoryId: item.category ? [item.category.id] : [] })) as CreateFrequentWorkTask[],
      };
      const success = await createFrequentWorkV1FrequentworksPost(newTemplate);
      if (success) {
        onFetchTemplateList && onFetchTemplateList();
        toast.success('템플릿 목록에 추가되었습니다.');
        setTaskboxDetail(null);
      }
    };

    const handleChangeFocusModeInprogress = async (
      taskbox: OutTaskboxDetailResponse,
      taskboxDate: Date | null,
      isAllDay = false,
      dateType: ContinueDateType,
    ) => {
      if (!taskbox) return;
      if (!taskboxDate) return;

      const start = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = isAllDay
        ? { date: dayjs(taskboxDate).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(taskboxDate).format('YYYY-MM-DD')}T${dayjs(taskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      const doneTasks = taskbox.tasks!.filter((task) => task.done) as UpdateTaskForTaskBox[];
      const undoneTasks = tasks
        .filter((task) => !task.done)
        .map((task) => ({
          ...task,
          content: task.content || '',
        })) as CreateTaskboxTask[];

      const doneTaskbox: UpdateTaskbox = { tasks: doneTasks, done: true };
      const success = await updateTaskboxV1TaskboxesTaskboxIdPut(taskbox!.id!, doneTaskbox);
      const continueTaskbox: CreateTaskbox = { ...taskbox, id: uuidv4(), title: taskbox.title!, tasks: undoneTasks, start, end };
      const success2 = await createTaskboxV1TaskboxesPost(continueTaskbox);

      if (success && success2) {
        handleCloseFocusMode();
        toast.success('미완료 하위 업무로 새로운 태스크박스를 생성했습니다.');
      }
    };

    const handleDeleteWithKeydown = () => {
      if (event?.isRecurrence) {
        setDeleteWithKeydown(false);
        setDeleteRecurrenceWithKeydown(true);
        return;
      } else {
        setDeleteWithKeydown(false);
        handleRemoveTimeline(event!);
      }
    };

    const handleDeleteRecurrenceWithKeydown = (target: RemoveTaskboxV1TaskboxesTaskboxIdDeleteParams) => {
      handleDeleteRecurrence(target);
    };

    const handleDuplicateTaskbox = async (taskbox: OutTaskboxDetailResponse) => {
      const newTasks = taskbox.tasks!.map((v) => ({ ...v, id: uuidv4(), categoryId: v.category ? [v.category.id] : [] })) as CreateTaskboxTask[];
      const newTaskbox: CreateTaskbox = {
        ...taskbox,
        id: uuidv4(),
        title: taskbox.title!,
        categoryId: taskbox.category ? [taskbox.category.id] : [],
        tasks: newTasks,
      };
      const success = await createTaskboxV1TaskboxesPost(newTaskbox);
      if (success) {
        onChange && onChange(date!);
        toast.success('태스크박스가 복제되었습니다.');
        setTaskboxDetail(null);
      }
    };

    const handleClickTaskboxContextMenu = async (menu: InboxContextMenuType) => {
      try {
        switch (menu) {
          case 'DELETE':
            handleRemoveTimeline(event!);
            break;
          case 'TEMPLATE':
            handleCreateTemplate(event!);
            break;
          case 'DUPLICATE':
            handleDuplicateTaskbox(event!);
            break;
          case 'CATEGORY':
            handleClickCategory();
            break;
          case 'DEADLINE':
            handleClickDealine();
            break;
          case 'CONVERT_TO_PROJECT':
            handleConvertToProject(event);
            break;
        }
      } catch (e) {
        toast.error('작업을 수행할 수 없습니다.');
      }
    };

    const handleSelectedTaskId = (id: string) => {
      setSelectedTaskId(id);
    };

    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null);

    const handleClickCategory = () => {
      setCategoryAnchorEl(taskboxDetail);
      setTaskboxDetail(null);
    };

    const [deadlineAnchorEl, setDeadlineAnchorEl] = useState<HTMLElement | null>(null);
    const handleClickDealine = () => {
      setDeadlineAnchorEl(taskboxDetail);
      setTaskboxDetail(null);
    };

    const handleClickCategoryActions = async (category: OutCategory | null, action: CategoryActionType, detachSubtaskId?: string) => {
      switch (action) {
        case 'CREATE':
          {
            if (!category) return;
            const success = await createCategoryV1CategoryPost(category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
            }
          }
          break;
        case 'SELECT':
          {
            if (subtaskId || detachSubtaskId) {
              const updateTasks = tasks.map((item) =>
                item.id === subtaskId || detachSubtaskId ? { ...item, categoryId: category ? [category.id!] : [] } : item,
              );
              // await updateTaskboxTaskV1TaskboxesTaskboxIdTasksTaskIdPut(event!.id!, subtaskId!, { categoryId: category ? [category.id!] : [] });
              try {
                await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: updateTasks as UpdateTaskForTaskBox[] });
                setCategoryAnchorEl(null);
                setSubtaskId(undefined);
                onChange && onChange(date!);
              } catch (e) {
                toast.error('작업을 수행할 수 없습니다.');
              }
            } else {
              try {
                const success = event.project
                  ? await updateProjectV1ProjectProjectIdPatch(event.project.id!, { categoryIds: category ? [category.id!] : [] })
                  : await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { categoryId: category ? [category.id!] : [] });

                if (success) {
                  setSelectedTaskbox(undefined);
                  setCategoryAnchorEl(null);
                  onChange && onChange(date!);
                  if (event.project) fetchProjects();
                }
              } catch (e) {
                toast.error('작업을 수행할 수 없습니다.');
              }
            }

            if (isQuickCategory) {
              setFocusElement(focusedTaskbox!.id!);
              setIsQuickCategory(false);

              const value = refTaskboxInput.current?.textContent;

              if (value && value.includes('#')) {
                refTaskboxInput.current.textContent = value.replace(/#/g, '');
              }
            }
          }
          break;
        case 'UPDATE':
          {
            if (!category) return;
            const success = await updateCategoryV1CategoryCategoryIdPatch(category.id!, category);
            if (success) {
              onFetchCategoryList && onFetchCategoryList();
              if (focusModeTaskbox) return;
              onChange && onChange(date!);
            }
          }
          break;
        case 'DELETE':
          {
            if (!category) return;
            await deleteCategoryV1CategoryCategoryIdDelete(category.id!);
            onFetchCategoryList && onFetchCategoryList();
            if (focusModeTaskbox) return;
            onChange && onChange(date!);
            setSelectedTaskbox(undefined);
            setCategoryAnchorEl(null);
          }
          break;
      }
    };

    const handleChangeDeadline = async (deadlineDate: Dayjs | null, detachSubtaskId?: string) => {
      if (subtaskId || detachSubtaskId) {
        const updateTasks = tasks.map((item) =>
          item.id === subtaskId || detachSubtaskId
            ? { ...item, deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null, categoryId: item.category ? [item.category.id] : [] }
            : item,
        );
        try {
          await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { tasks: updateTasks as UpdateTaskForTaskBox[] });
          setDeadlineAnchorEl(null);
          setSubtaskId(undefined);
          onChange && onChange(date!);
        } catch (e) {
          toast.error('작업을 수행할 수 없습니다.');
        }
      } else {
        try {
          const success = event.project
            ? await updateProjectV1ProjectProjectIdPatch(event.project.id!, { due: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null })
            : await updateTaskboxV1TaskboxesTaskboxIdPut(event!.id!, { deadline: deadlineDate ? dayjs(deadlineDate).format(DATE_FORMAT_4) : null });
          if (success) {
            setDeadlineAnchorEl(null);
            onChange && onChange(date!);
            if (event.project) fetchProjects();
          }
        } catch (e) {
          toast.error('작업을 수행할 수 없습니다.');
        }
      }
      if (isQuickDeadline) {
        setFocusElement(focusedTaskbox!.id!);
        setIsQuickDeadline(false);

        const value = refTaskboxInput.current?.textContent;

        if (value && value.includes('$')) {
          refTaskboxInput.current.textContent = value.replace(/\$/g, '');
        }
      }
    };

    const handleFetchFocusMode = async () => {
      onChange && onChange(date!);
      if (focusModeTaskbox) {
        const focus = events.find((event) => event.id === focusModeTaskbox.id);
        setFocusModeTaskbox(focus!);
      }
    };

    const handleClickInstance = async (event: OutTaskboxDetailResponse) => {
      if (event && event.project) {
        const project = await getAProjectV1ProjectProjectIdGet(event.project.id);
        setSelectedProject(project!);
      }
    };

    const handleCloseProjectDetail = () => {
      setSelectedProject(null);
      onChange && onChange(date!);
      fetchProjects();
    };

    const handleDeleteProject = async (id: string) => {
      try {
        await deleteProjectV1ProjectProjectIdDelete(id);
        toast.success('프로젝트를 삭제하였습니다.');
        handleCloseProjectDetail();
      } catch (e) {
        toast.error('프로젝트를 삭제할 수 없습니다.');
      }
    };

    const handleConvertToProject = async (taskbox: OutTaskboxDetailResponse) => {
      if (taskbox && taskbox.id) await convertTaskToProjectV1ProjectConvertingFromTaskTaskIdPost(taskbox.id);
      setTaskboxDetail(null);
      onChange && onChange(date!);
      fetchProjects();
      if (projects.filter((project) => project.place === 'DESK').length >= 7) {
        toast.success('데스크가 가득 차서 서랍에 프로젝트를 생성했습니다.');
      } else {
        toast.success('태스크박스를 프로젝트로 전환했습니다.');
      }
    };

    const handlePushProjectRecommendTask = async (blocks: TaskBlock[], blockId: string, index: number) => {
      if (!focusModeTaskbox) return;
      const updateBlocks = blocks.map((block) => ({ ...block, content: block.content }));
      await updateTaskboxV1TaskboxesTaskboxIdPut(focusModeTaskbox!.id!, { tasks: updateBlocks as UpdateTaskForTaskBox[] });
      await assignTasksV1TaskboxesTaskboxIdAssignPost(focusModeTaskbox!.id!, { tasks: [blockId], beforeId: blocks[index - 1]?.id });
      fetchProjects();
    };

    const refTaskboxInput = useRef<HTMLInputElement>(null);
    const [isQuickCategory, setIsQuickCategory] = useState(false);
    const [isQuickDeadline, setIsQuickDeadline] = useState(false);

    return (
      <>
        {event?.allDay ? (
          <div
            className="taskbox-drag-container"
            ref={refTaskbox}
            data-taskbox={`${event!.id}`}
            draggable={multiSelected ? (multiSelectIds.length > 0 ? true : false) : true}
            onDragStart={multiSelected ? (e) => onMultiSelectDragStart?.(e, doneStatus) : handleDragStart}
            onDragEnd={multiSelected ? onMultiSelectDragEnd : handleDragEnd}
            onDragEnter={handleDragEnterToTaskbox}
            onDragLeave={(e) => handleDragLeaveFromTaskbox(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) onChangeMultiSelectIds && onChangeMultiSelectIds(e, event.id!);
              if (focusedTaskbox?.id === event?.id) handleClickTaskbox(event);
            }}
          >
            <Draggable draggableId={`${event?.id}`} index={index!}>
              {(provided, snapshot) => {
                const style = {
                  backgroundColor: snapshot.isDragging ? 'white' : '',
                  boxShadow: snapshot.isDragging ? `0px 8px 16px 0px rgba(26, 30, 39, 0.16)` : '',
                  borderRadius: snapshot.isDragging ? '0px 16px 16px 0px' : '',
                  marginBottom: '8px',
                  ...provided.draggableProps.style,
                };
                return (
                  <TaskboxDragContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={style}
                    // draggable={multiSelected ? (multiSelectIds.length > 0 ? true : false) : true}
                    // onDragStart={multiSelected ? onMultiSelectDragStart : handleDragStart}
                    // onDragEnd={multiSelected ? onMultiSelectDragEnd : handleDragEnd}
                    // hidden={draggingToTaskbox}
                  >
                    <Tooltip title={'왼쪽으로 드래그해서 캘린더로 옮깁니다.'} disableInteractive>
                      <TaskboxDragToCalendarIcon
                        className="taskbox-drag-handle"
                        taskboxHeight={taskboxHeight}
                        // onMouseDown={handleDragToCalendarStart}
                        // onMouseUp={handleDragToCalendarEnd}
                      />
                    </Tooltip>

                    <Tooltip title={'위아래로 드래그해서 순서를 변경합니다.'} disableInteractive>
                      <TaskboxDragHandleIcon {...provided.dragHandleProps} className="taskbox-drag-handle" taskboxHeight={taskboxHeight} />
                    </Tooltip>

                    {expanded || focusedTaskbox?.id === event?.id ? (
                      <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                        {(provided) => (
                          <TaskDragContainer
                            className="timeline-container"
                            key={event?.id}
                            data-scroll={`${event?.id}`}
                            event={event}
                            plan={plan}
                            hidden={draggingToTaskbox}
                            taskboxDone={taskboxDone}
                            focusedTaskbox={focusedTaskbox}
                            selectedTaskbox={selectedTaskbox}
                            multiSelected={multiSelected}
                            onClick={(e) => {
                              if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                            }}
                          >
                            {event && (
                              <div
                                className="flex gap-4 timeline-border"
                                onClick={(e) => {
                                  if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                }}
                              >
                                <div
                                  className="line"
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                  }}
                                />
                                <div
                                  className="flex-1"
                                  style={{ pointerEvents: 'all' }}
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                                  }}
                                >
                                  <TaskboxPropertyWrapper style={{ justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      {event.category && (
                                        <CategoryShowingWrapper
                                          textColor={getCategoryTextColor(event.category!.color)}
                                          bgColor={getCategoryBgColor(event.category!.color)}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setCategoryAnchorEl(e.currentTarget);
                                          }}
                                        >
                                          {`# ${event.category.name}`}
                                          <IconButton
                                            className="category-detach-button"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleClickCategoryActions(null, 'SELECT');
                                            }}
                                            sx={{
                                              'width': '12px',
                                              'height': '12px',
                                              'borderRadius': '4px',
                                              'marginLeft': '4px',
                                              'padding': '0px',
                                              ':hover': {
                                                backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                              },
                                            }}
                                            style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                          >
                                            <Icons.Close width={8} height={8} fill={getCategoryTextColor(event.category!.color)} />
                                          </IconButton>
                                        </CategoryShowingWrapper>
                                      )}
                                      {event.deadline && (
                                        <DeadlineShowingWrapper
                                          date={event.deadline}
                                          onClick={(e) => {
                                            setSelectedTaskbox(event.id);
                                            setDeadlineAnchorEl(e.currentTarget);
                                          }}
                                        >
                                          <Icons.Flag
                                            fill={
                                              dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600
                                            }
                                          />
                                          <span style={{ marginLeft: '2px' }}>
                                            {dayjs(event.deadline).isToday()
                                              ? '오늘'
                                              : dayjs(event.deadline).isYesterday()
                                              ? '어제'
                                              : dayjs(event.deadline).isTomorrow()
                                              ? '내일'
                                              : dayjs(event.deadline).format('M월 D일 (dd)')}
                                          </span>
                                          <IconButton
                                            className="deadline-detach-button"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleChangeDeadline(null);
                                            }}
                                            sx={{
                                              'width': '12px',
                                              'height': '12px',
                                              'borderRadius': '4px',
                                              'marginLeft': '4px',
                                              'padding': '0px',
                                              ':hover': {
                                                backgroundColor: hexToRGBA(
                                                  dayjs(event!.deadline).isToday()
                                                    ? COLORS.brand1
                                                    : dayjs(event!.deadline).isBefore(dayjs())
                                                    ? COLORS.negative1
                                                    : COLORS.gray600,
                                                  0.3,
                                                ),
                                              },
                                            }}
                                            style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                          >
                                            <Icons.Close
                                              width={8}
                                              height={8}
                                              fill={
                                                dayjs(event!.deadline).isToday()
                                                  ? COLORS.brand1
                                                  : dayjs(event!.deadline).isBefore(dayjs())
                                                  ? COLORS.negative1
                                                  : COLORS.gray600
                                              }
                                            />
                                          </IconButton>
                                        </DeadlineShowingWrapper>
                                      )}
                                      {event.durationMin && event.durationMin > 0 && (
                                        <div
                                          className="flex gap-1 items-center time-modal"
                                          onClick={(e) => {
                                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                          }}
                                        >
                                          <div className="time-modal-container">
                                            <Icons.Time stroke={COLORS.gray500} />
                                            <Fonts.Blockquote
                                              className="text-gray500"
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  handleOpenSimpleTimeSelectionModal(e);
                                                }
                                              }}
                                              style={{ marginLeft: '4px' }}
                                            >
                                              {showingTime(undefined, event.durationMin)}
                                            </Fonts.Blockquote>
                                            {!multiSelected ? (
                                              <Tooltip title={'시간 해제하기'} disableInteractive>
                                                <IconButton
                                                  className="unselect-time"
                                                  onClick={(e) => {
                                                    if (!e.metaKey && !e.ctrlKey) {
                                                      event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                                    }
                                                  }}
                                                  sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                                >
                                                  <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                                                </IconButton>
                                              </Tooltip>
                                            ) : (
                                              <div style={{ width: '24px', height: '24px' }} />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      {event.type === 'TERM_TASK' && event.matchDate && (
                                        <Tooltip
                                          title={`${dayjs(event.matchDate?.[0]).format('M월D일')}~${dayjs(event.matchDate?.[event.matchDate.length - 1]).format(
                                            'M월D일',
                                          )}`}
                                          disableInteractive
                                        >
                                          <DateRangeShowingWrapper>
                                            <Icons.RangeTaskbox />
                                            <span style={{ marginLeft: '4px' }}>
                                              {`${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${
                                                event.matchDate?.length
                                              }일째`}
                                            </span>
                                          </DateRangeShowingWrapper>
                                        </Tooltip>
                                      )}
                                    </div>
                                    {event.type === 'TERM_TASK' && (
                                      <Tooltip title="종료일이 지정되어 상단에 고정됩니다.">
                                        <div>
                                          <Icons.ColorPin stroke={COLORS.brand1} />
                                        </div>
                                      </Tooltip>
                                    )}
                                  </TaskboxPropertyWrapper>
                                  <div className="flex justify-between items-start relative timeline-header">
                                    {/* 타임박스 제목 */}
                                    <div className="timelineEvents-title-container">
                                      {/* <Icons.PriorityIssueCheck
                                  // stroke={event.plansWeekly && event.plansWeekly[0] ? COLORS.brand1 : COLORS.gray400}
                                  stroke={plan[0] && plan[0].priority! > 0 ? COLORS.negative1 : plan[0] && plan[0].id! ? COLORS.brand1 : COLORS.gray400}
                                /> */}
                                      {tasks.filter((task) => task.done).length > 0 && !taskboxDone ? (
                                        <Checkbox
                                          checked={false}
                                          icon={
                                            event.focus ? (
                                              <Icons.FocusInprogressCheck />
                                            ) : (
                                              <Icons.InprogressCheck fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                                            )
                                          }
                                          sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                          style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              if (event.type === 'TERM_TASK') {
                                                handleClickTaskboxCheck();
                                              } else {
                                                setSelectedTaskbox(event.id);
                                                setInProgressPopover(e.currentTarget);
                                              }
                                            }
                                          }}
                                        />
                                      ) : (
                                        <PriorityIssueCheckbox
                                          width={20}
                                          height={20}
                                          focus={event.focus}
                                          bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                          checked={!!taskboxDone}
                                          // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                          // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                          }}
                                          style={{ marginTop: 2, marginRight: 8 }}
                                        />
                                      )}
                                      {isExploding && event.focus && (
                                        <ConfettiExplosion
                                          force={0.6}
                                          duration={2500}
                                          particleCount={80}
                                          width={1000}
                                          onComplete={() => setIsExploding(false)}
                                          style={{ position: 'absolute', top: 0, left: 0 }}
                                        />
                                      )}
                                      <TaskboxInputWrapper isProject={!!event.project}>
                                        {event.project && (
                                          <ProjectIcon>
                                            <Icons.Issue />
                                          </ProjectIcon>
                                        )}
                                        {event.lockedIn && <LockInIcon event={event} />}
                                        {event.project ? (
                                          <Tooltip title="프로젝트 상세보기" disableInteractive placement="bottom-start">
                                            <TaskboxInput
                                              className="timelineEvents-title"
                                              data-id={
                                                event.type === 'TERM_TASK'
                                                  ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                  : `${event.id}`
                                              }
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  handleClickTaskbox(event);
                                                  handleClickInstance(event);
                                                }
                                              }}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              {event.title}
                                            </TaskboxInput>
                                          </Tooltip>
                                        ) : (
                                          <TaskboxInput
                                            ref={refTaskboxInput}
                                            className="timelineEvents-title"
                                            data-id={
                                              event.type === 'TERM_TASK'
                                                ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                : `${event.id}`
                                            }
                                            onBlur={(e) => handleBlurTitle(e, event)}
                                            onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                            placeholder="Title"
                                            contentEditable={multiSelected ? false : true}
                                            suppressContentEditableWarning={true}
                                            spellCheck={false}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickTaskbox(event);
                                            }}
                                          >
                                            {event.title}
                                          </TaskboxInput>
                                        )}
                                      </TaskboxInputWrapper>
                                      {event.issueId && (
                                        <Button
                                          variant="outlined"
                                          color="inherit"
                                          onClick={() => handleClickIssueDetail(event.issueId!)}
                                          sx={{
                                            width: '100px',
                                            height: '24px',
                                            borderRadius: '8px',
                                            padding: '0px',
                                            color: `${COLORS.gray400}`,
                                            border: `1px solid ${COLORS.gray400}`,
                                          }}
                                        >
                                          <div className="timelineEvents-issue-detail">
                                            <IssueLinkage />
                                            <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                          </div>
                                        </Button>
                                      )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
                                      {event.memo && <Icons.TaskboxMemo />}
                                      {event.links!.length > 0 && (
                                        <div style={{ marginLeft: '4px' }}>
                                          <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                        </div>
                                      )}
                                      {event.isRecurrence && (
                                        <div style={{ marginLeft: '4px' }}>
                                          <Icons.Recurrence />
                                        </div>
                                      )}
                                      {tasks.length > 0 && (
                                        <div className="show-subtask">
                                          <Icons.Subtask />
                                          <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                        </div>
                                      )}
                                    </div>
                                    {!multiSelected && (
                                      <TaskboxOptionsContainer className="taskbox-option" style={selectedTaskbox ? { visibility: 'visible' } : {}}>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title={event.focus ? '하이라이트풀기' : '하이라이트하기'} disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleHighlightTaskbox();
                                                }}
                                              >
                                                {event.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <Divider
                                          orientation="vertical"
                                          variant="middle"
                                          flexItem
                                          sx={{
                                            margin: '4px 2px',
                                          }}
                                        />
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip
                                              title={
                                                <div style={{ margin: '2px 4px' }}>
                                                  <span>날짜 및 시간 설정</span>
                                                  <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                                    T
                                                  </KeyboardButtonRect>
                                                </div>
                                              }
                                              disableInteractive
                                            >
                                              <IconButton
                                                data-timeselectionid={event.id}
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleOpenTimeSelectionModal(e);
                                                }}
                                              >
                                                <Icons.SelectTime />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent id="tutorial-calendar">
                                          <Tooltip
                                            title={
                                              event.recurrence
                                                ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                                                : event.lockedIn
                                                ? '구글 캘린더에서 해제하기'
                                                : '구글 캘린더에 표시하기'
                                            }
                                            disableInteractive
                                          >
                                            <TaskboxOptionsIcon style={{ cursor: 'pointer' }}>
                                              <IconButton
                                                disabled={event.recurrence ? true : false}
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleLockInOutButton(event.lockedIn!);
                                                }}
                                              >
                                                {event.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                                              </IconButton>
                                            </TaskboxOptionsIcon>
                                          </Tooltip>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip
                                              title={
                                                <div style={{ margin: '2px 4px' }}>
                                                  <span>포커스모드</span>
                                                  {/* <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Shift
                                                  </KeyboardButtonRect>
                                                  +
                                                  <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Option
                                                  </KeyboardButtonRect>
                                                  + <KeyboardButtonRect small>F</KeyboardButtonRect> */}
                                                  <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                                    F
                                                  </KeyboardButtonRect>
                                                </div>
                                              }
                                              disableInteractive
                                            >
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) handleClickFocusMode();
                                                }}
                                              >
                                                <Icons.FocusMode />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title="더보기" disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '4px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  if (!e.metaKey && !e.ctrlKey) {
                                                    setSelectedTaskbox(event.id!);
                                                    setTaskboxDetail(e.currentTarget);
                                                  }
                                                }}
                                              >
                                                <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                      </TaskboxOptionsContainer>
                                    )}
                                  </div>
                                  <div className={`${event.id} `} {...provided.droppableProps} ref={provided.innerRef}>
                                    <SubtaskView>
                                      <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                        {event &&
                                          tasks?.map((task, index) => (
                                            <Draggable draggableId={task.id!} index={index} key={task.id}>
                                              {(provided) => (
                                                <EditableTextFieldWrapper
                                                  {...provided.draggableProps}
                                                  key={task.id}
                                                  className="flex justify-between items-center relative"
                                                  style={{ ...provided.draggableProps.style }}
                                                  ref={provided.innerRef}
                                                  selectedTaskId={multiSelected ? false : selectedTaskId === task.id ? true : false}
                                                  hover={multiSelected ? false : selectedEvent?.id === event.id && selectedTaskId === task.id ? false : true}
                                                >
                                                  <div className="flex items-center box-border task-container">
                                                    <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                                    <SubTaskCheckbox
                                                      focus={event.focus}
                                                      multiSelect={multiSelected}
                                                      bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                                      checked={!!task.done}
                                                      // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                                      // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) handleCheckbox(index);
                                                      }}
                                                    />
                                                    <SubTaskField
                                                      className="subtask flex items-center ml-2"
                                                      ref={refTask}
                                                      data-id={`${task.id}`}
                                                      data-timeboxid={event.id}
                                                      placeholder={
                                                        !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                          ? projectSubtasks[recommendTaskIndex].title === ''
                                                            ? '제목 없음'
                                                            : projectSubtasks[recommendTaskIndex].title!
                                                          : 'Title'
                                                      }
                                                      contentEditable={multiSelected ? false : true}
                                                      suppressContentEditableWarning={true}
                                                      taskboxDone={taskboxDone}
                                                      onBlur={(e) => handleSaveChangedTask(e, index)}
                                                      onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          handleClickTaskbox(event);
                                                          handleSelectedTaskId && handleSelectedTaskId(task.id!);
                                                        }
                                                      }}
                                                    >
                                                      {task.content}
                                                    </SubTaskField>
                                                  </div>
                                                  {(!isInstanceTyping &&
                                                    selectedTaskId === task.id &&
                                                    event.project &&
                                                    projectSubtasks.length > 0 &&
                                                    task.content === '') ||
                                                  (isRemoveTextAndRecommend && selectedTaskId === task.id && event.project && projectSubtasks.length > 0) ? (
                                                    !delayRecommendTask ? (
                                                      <RecommendTaskWrapper>
                                                        {projectSubtasks.length > 1 && (
                                                          <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                            <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                            <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                            <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                          </div>
                                                        )}
                                                        <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                                        <span>으로 추가하기</span>
                                                      </RecommendTaskWrapper>
                                                    ) : (
                                                      <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                                                    )
                                                  ) : (
                                                    <>
                                                      {!multiSelected && (
                                                        <SubtaskAddsOnWrapper className="subtask-addson">
                                                          {task.category ? (
                                                            <CategoryShowingWrapper
                                                              className="detail task-detail"
                                                              textColor={getCategoryTextColor(task.category.color)}
                                                              bgColor={getCategoryBgColor(task.category.color)}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setCategoryAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                            >
                                                              {`# ${task.category.name}`}
                                                              <IconButton
                                                                className="category-detach-button"
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  handleClickCategoryActions(null, 'SELECT', task.id);
                                                                }}
                                                                sx={{
                                                                  'width': '12px',
                                                                  'height': '12px',
                                                                  'borderRadius': '4px',
                                                                  'marginLeft': '4px',
                                                                  'padding': '0px',
                                                                  ':hover': {
                                                                    backgroundColor: hexToRGBA(getCategoryTextColor(task.category!.color)!, 0.3),
                                                                  },
                                                                }}
                                                                style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                              >
                                                                <Icons.Close width={8} height={8} fill={getCategoryTextColor(task.category.color)} />
                                                              </IconButton>
                                                            </CategoryShowingWrapper>
                                                          ) : (
                                                            <IconButton
                                                              className="detail task-detail"
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setCategoryAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '4px',
                                                                padding: '0px',
                                                                marginRight: '4px',
                                                              }}
                                                              style={
                                                                categoryAnchorEl && subtaskId === task.id && !multiSelected
                                                                  ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                  : {}
                                                              }
                                                            >
                                                              <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                                            </IconButton>
                                                          )}
                                                          {task.deadline ? (
                                                            <DeadlineShowingWrapper
                                                              className="detail task-detail"
                                                              date={task.deadline}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setDeadlineAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                            >
                                                              <Icons.Flag
                                                                fill={
                                                                  dayjs(task!.deadline).isToday()
                                                                    ? COLORS.brand1
                                                                    : dayjs(task!.deadline).isBefore(dayjs())
                                                                    ? COLORS.negative1
                                                                    : COLORS.gray600
                                                                }
                                                              />
                                                              <span style={{ marginLeft: '2px' }}>
                                                                {dayjs(task.deadline).isToday()
                                                                  ? '오늘'
                                                                  : dayjs(task.deadline).isYesterday()
                                                                  ? '어제'
                                                                  : dayjs(task.deadline).isTomorrow()
                                                                  ? '내일'
                                                                  : dayjs(task.deadline).format('M월 D일 (dd)')}
                                                              </span>
                                                              <IconButton
                                                                className="deadline-detach-button"
                                                                onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  handleChangeDeadline(null, task.id);
                                                                }}
                                                                sx={{
                                                                  'width': '12px',
                                                                  'height': '12px',
                                                                  'borderRadius': '4px',
                                                                  'marginLeft': '4px',
                                                                  'padding': '0px',
                                                                  ':hover': {
                                                                    backgroundColor: hexToRGBA(
                                                                      dayjs(task!.deadline).isToday()
                                                                        ? COLORS.brand1
                                                                        : dayjs(task!.deadline).isBefore(dayjs())
                                                                        ? COLORS.negative1
                                                                        : COLORS.gray600,
                                                                      0.3,
                                                                    ),
                                                                  },
                                                                }}
                                                                style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                              >
                                                                <Icons.Close
                                                                  width={8}
                                                                  height={8}
                                                                  fill={
                                                                    dayjs(task!.deadline).isToday()
                                                                      ? COLORS.brand1
                                                                      : dayjs(task!.deadline).isBefore(dayjs())
                                                                      ? COLORS.negative1
                                                                      : COLORS.gray600
                                                                  }
                                                                />
                                                              </IconButton>
                                                            </DeadlineShowingWrapper>
                                                          ) : (
                                                            <IconButton
                                                              className="detail task-detail"
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setDeadlineAnchorEl(e.currentTarget);
                                                                }
                                                              }}
                                                              sx={{
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '4px',
                                                                padding: '0px',
                                                                marginRight: '4px',
                                                              }}
                                                              style={
                                                                deadlineAnchorEl && subtaskId === task.id && !multiSelected
                                                                  ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                                  : {}
                                                              }
                                                            >
                                                              <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                                            </IconButton>
                                                          )}
                                                          <TaskIconWrapper
                                                            className="detail task-detail"
                                                            aria-label="later"
                                                            onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                          >
                                                            {event.project ? (
                                                              <Icons.InstanceLater />
                                                            ) : (
                                                              <div className="later">
                                                                <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                                                              </div>
                                                            )}
                                                          </TaskIconWrapper>
                                                          <TaskIconWrapper
                                                            className="detail task-detail"
                                                            aria-label="delete"
                                                            onClick={() => handleClickSubtaskContextMenu(task.id!, 'DELETE')}
                                                            style={{ marginRight: event.project ? 0 : 4 }}
                                                          >
                                                            <div className="delete">
                                                              <Icons.Delete fill={COLORS.gray400} />
                                                            </div>
                                                          </TaskIconWrapper>
                                                          {!event.project && (
                                                            <div
                                                              className="detail task-detail"
                                                              style={subTaskDetail && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                              onClick={(e) => {
                                                                if (!e.metaKey && !e.ctrlKey) {
                                                                  if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                                  setSubtaskId(task.id);
                                                                  setSubTaskDetail(e.currentTarget);
                                                                }
                                                              }}
                                                            >
                                                              <Icons.ColorMore />
                                                            </div>
                                                          )}
                                                        </SubtaskAddsOnWrapper>
                                                      )}
                                                    </>
                                                  )}
                                                  {/* 태스크 카테고리 */}
                                                  {subtaskId === task.id && (
                                                    <Popover
                                                      open={Boolean(categoryAnchorEl)}
                                                      anchorEl={categoryAnchorEl}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                      onClose={() => {
                                                        setSelectedTaskbox(undefined);
                                                        setSubtaskId(undefined);
                                                        setCategoryAnchorEl(null);
                                                      }}
                                                      sx={{ marginTop: '4px' }}
                                                    >
                                                      <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
                                                    </Popover>
                                                  )}
                                                  {/* 태스크 기한 */}
                                                  {subtaskId === task.id && (
                                                    <Popover
                                                      open={Boolean(deadlineAnchorEl)}
                                                      anchorEl={deadlineAnchorEl}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                      onClose={() => {
                                                        setSelectedTaskbox(undefined);
                                                        setSubtaskId(undefined);
                                                        setDeadlineAnchorEl(null);
                                                      }}
                                                      sx={{ marginTop: '4px' }}
                                                    >
                                                      <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
                                                    </Popover>
                                                  )}
                                                  {selectedTaskId === task.id && (
                                                    <SubtaskDetail
                                                      taskId={task.id}
                                                      detail={subTaskDetail}
                                                      menus={['CONVERT_TO_TASKBOX']}
                                                      onCloseDetail={handleCloseSubTaskDetail}
                                                      onClickSubtaskContextMenu={handleClickSubtaskContextMenu}
                                                    />
                                                  )}
                                                </EditableTextFieldWrapper>
                                              )}
                                            </Draggable>
                                          ))}
                                        {provided!.placeholder!}

                                        <TaskboxAddOn multiSelected={multiSelected}>
                                          <SubtaskWrapper
                                            data-timeboxid={event.id}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                handleClickTaskAdd(event);
                                              }
                                            }}
                                          >
                                            <Icons.Plus width={16} height={16} fill={COLORS.gray500} />
                                            <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>하위업무 추가하기</Fonts.Body2>
                                          </SubtaskWrapper>
                                          <TemplateWrapper className="template-btn">
                                            <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                            <TemplateButtonWrapper
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleOpenTemplateDialog();
                                              }}
                                            >
                                              <Icons.Template width={16} height={16} fill={COLORS.gray500} />
                                              <div style={{ marginLeft: '4px' }}>템플릿 불러오기</div>
                                            </TemplateButtonWrapper>
                                          </TemplateWrapper>
                                        </TaskboxAddOn>
                                        {event.isRecurrence && updateRecurrence && (
                                          <UpdateRecurrenceWrapper
                                            onClick={(e) => {
                                              handleReflectRecurrence();
                                            }}
                                          >
                                            <Icons.UpdateRecurrence />
                                            <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>
                                              이후 반복 업무에 변경사항 반영하기
                                            </Fonts.Body2>
                                          </UpdateRecurrenceWrapper>
                                        )}
                                      </div>
                                    </SubtaskView>
                                  </div>
                                  {event.memo && focusedTaskbox && focusedTaskbox.id === event.id && (
                                    <ShowingMemoWrapper>
                                      <Divider sx={{ margin: '4px 0px' }} />
                                      <Tooltip title="포커스모드에서 편집하기" followCursor>
                                        <ShowingMemoTextWrapper
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              handleClickFocusMode(event);
                                            }
                                          }}
                                        >
                                          {event.memo?.content?.[0]?.content?.[0]?.text && <div>{event.memo.content[0].content[0].text}</div>}
                                          {event.memo?.content?.[1]?.content?.[0]?.text && <div>{event.memo.content[1].content[0].text}</div>}
                                        </ShowingMemoTextWrapper>
                                      </Tooltip>
                                    </ShowingMemoWrapper>
                                  )}
                                </div>
                              </div>
                            )}
                          </TaskDragContainer>
                        )}
                      </Droppable>
                    ) : (
                      <TaskDragContainer
                        className="timeline-container"
                        key={event?.id}
                        data-scroll={`${event?.id}`}
                        event={event}
                        taskboxDone={taskboxDone}
                        focusedTaskbox={focusedTaskbox}
                        plan={plan}
                        hidden={draggingToTaskbox}
                        selectedTaskbox={selectedTaskbox}
                        multiSelected={multiSelected}
                        onClick={(e) => {
                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                        }}
                      >
                        {event && (
                          <div
                            className="flex gap-4 timeline-border"
                            onClick={(e) => {
                              if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                            }}
                          >
                            <div
                              className="line"
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                              }}
                            />
                            <div
                              className="flex-1"
                              style={{ pointerEvents: 'all', width: 'calc(100% - 20px)' }}
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event);
                              }}
                            >
                              <TaskboxPropertyWrapper style={{ justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {event.category && (
                                    <CategoryShowingWrapper
                                      textColor={getCategoryTextColor(event.category.color)}
                                      bgColor={getCategoryBgColor(event.category.color)}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setCategoryAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      {`# ${event.category.name}`}
                                      <IconButton
                                        className="category-detach-button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleClickCategoryActions(null, 'SELECT');
                                        }}
                                        sx={{
                                          'width': '12px',
                                          'height': '12px',
                                          'borderRadius': '4px',
                                          'marginLeft': '4px',
                                          'padding': '0px',
                                          ':hover': {
                                            backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                          },
                                        }}
                                        style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                      >
                                        <Icons.Close width={8} height={8} fill={getCategoryTextColor(event.category.color)} />
                                      </IconButton>
                                    </CategoryShowingWrapper>
                                  )}
                                  {event.deadline && (
                                    <DeadlineShowingWrapper
                                      date={event.deadline}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id);
                                        setDeadlineAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      <Icons.Flag
                                        fill={
                                          dayjs(event!.deadline).isToday()
                                            ? COLORS.brand1
                                            : dayjs(event!.deadline).isBefore(dayjs())
                                            ? COLORS.negative1
                                            : COLORS.gray600
                                        }
                                      />
                                      <span style={{ marginLeft: '2px' }}>
                                        {dayjs(event.deadline).isToday()
                                          ? '오늘'
                                          : dayjs(event.deadline).isYesterday()
                                          ? '어제'
                                          : dayjs(event.deadline).isTomorrow()
                                          ? '내일'
                                          : dayjs(event.deadline).format('M월 D일 (dd)')}
                                      </span>
                                      <IconButton
                                        className="deadline-detach-button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleChangeDeadline(null);
                                        }}
                                        sx={{
                                          'width': '12px',
                                          'height': '12px',
                                          'borderRadius': '4px',
                                          'marginLeft': '4px',
                                          'padding': '0px',
                                          ':hover': {
                                            backgroundColor: hexToRGBA(
                                              dayjs(event!.deadline).isToday()
                                                ? COLORS.brand1
                                                : dayjs(event!.deadline).isBefore(dayjs())
                                                ? COLORS.negative1
                                                : COLORS.gray600,
                                              0.3,
                                            ),
                                          },
                                        }}
                                        style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                      >
                                        <Icons.Close
                                          width={8}
                                          height={8}
                                          fill={
                                            dayjs(event!.deadline).isToday()
                                              ? COLORS.brand1
                                              : dayjs(event!.deadline).isBefore(dayjs())
                                              ? COLORS.negative1
                                              : COLORS.gray600
                                          }
                                        />
                                      </IconButton>
                                    </DeadlineShowingWrapper>
                                  )}
                                  {event.durationMin && event.durationMin > 0 && (
                                    <div
                                      className="flex gap-1 items-center time-modal"
                                      onClick={(e) => {
                                        if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                                      }}
                                    >
                                      <div className="time-modal-container">
                                        <Icons.Time stroke={COLORS.gray500} />
                                        <Fonts.Blockquote
                                          className="text-gray500"
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              handleOpenSimpleTimeSelectionModal(e);
                                            }
                                          }}
                                          style={{ marginLeft: '4px' }}
                                        >
                                          {showingTime(undefined, event.durationMin)}
                                        </Fonts.Blockquote>
                                        {!multiSelected ? (
                                          <Tooltip title={'시간 해제하기'} disableInteractive>
                                            <IconButton
                                              className="unselect-time"
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                                }
                                              }}
                                              sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                            >
                                              <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <div style={{ width: '24px', height: '24px' }} />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {event.type === 'TERM_TASK' && event.matchDate && (
                                    <Tooltip
                                      title={`${dayjs(event.matchDate[0]).format('M월D일')}~${dayjs(event.matchDate[event.matchDate.length - 1]).format(
                                        'M월D일',
                                      )}`}
                                    >
                                      <DateRangeShowingWrapper>
                                        <Icons.RangeTaskbox />
                                        <span style={{ marginLeft: '4px' }}>
                                          {`${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4)) + 1}/${event.matchDate.length}일째`}
                                        </span>
                                      </DateRangeShowingWrapper>
                                    </Tooltip>
                                  )}
                                </div>
                                {event.type === 'TERM_TASK' && (
                                  <Tooltip title="종료일이 지정되어 상단에 고정됩니다.">
                                    <div>
                                      <Icons.ColorPin stroke={COLORS.brand1} />
                                    </div>
                                  </Tooltip>
                                )}
                              </TaskboxPropertyWrapper>
                              <div className=" flex justify-between items-center relative timeline-header">
                                {/* 타임박스 제목 */}
                                <div
                                  className="timelineEvents-title-container"
                                  style={{
                                    width: `calc(100% - ${document.querySelector(`[data-foldShowingId="${event.id}"]`)?.getBoundingClientRect().width}px)`,
                                  }}
                                >
                                  {/* <Icons.PriorityIssueCheck
                              // stroke={event.plansWeekly && event.plansWeekly[0] ? COLORS.brand1 : COLORS.gray400}
                              stroke={plan[0] && plan[0].priority! > 0 ? COLORS.negative1 : plan[0] && plan[0].id! ? COLORS.brand1 : COLORS.gray400}
                            /> */}

                                  {tasks.filter((task) => task.done).length > 0 && !taskboxDone ? (
                                    <Checkbox
                                      checked={false}
                                      icon={
                                        event.focus ? (
                                          <Icons.FocusInprogressCheck />
                                        ) : (
                                          <Icons.InprogressCheck fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                                        )
                                      }
                                      sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                      style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          if (event.type === 'TERM_TASK') {
                                            handleClickTaskboxCheck();
                                          } else {
                                            setSelectedTaskbox(event.id);
                                            setInProgressPopover(e.currentTarget);
                                          }
                                        }
                                      }}
                                    />
                                  ) : (
                                    <PriorityIssueCheckbox
                                      width={20}
                                      height={20}
                                      focus={event.focus}
                                      bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                      checked={!!event.done}
                                      // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                      // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                      }}
                                      style={{ marginTop: 2, marginRight: 8 }}
                                    />
                                  )}
                                  {isExploding && event.focus && (
                                    <ConfettiExplosion
                                      force={0.6}
                                      duration={2500}
                                      particleCount={80}
                                      width={1000}
                                      onComplete={() => setIsExploding(false)}
                                      style={{ position: 'absolute', top: 0, left: 0 }}
                                    />
                                  )}
                                  <TaskboxInputWrapper isProject={!!event.project}>
                                    {event.project && (
                                      <ProjectIcon>
                                        <Icons.Issue />
                                      </ProjectIcon>
                                    )}
                                    {event.lockedIn && <LockInIcon event={event} />}
                                    {event.project ? (
                                      <Tooltip title="프로젝트 상세보기" disableInteractive placement="bottom-start">
                                        <TaskboxInput
                                          className="timelineEvents-title"
                                          data-id={
                                            event.type === 'TERM_TASK'
                                              ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                              : `${event.id}`
                                          }
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) {
                                              handleClickTaskbox(event);
                                              handleClickInstance(event);
                                            }
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          {event.title}
                                        </TaskboxInput>
                                      </Tooltip>
                                    ) : (
                                      <TaskboxInput
                                        ref={refTaskboxInput}
                                        className="timelineEvents-title"
                                        data-id={
                                          event.type === 'TERM_TASK'
                                            ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                            : `${event.id}`
                                        }
                                        onBlur={(e) => handleBlurTitle(e, event)}
                                        onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                        placeholder="Title"
                                        contentEditable={multiSelected ? false : true}
                                        suppressContentEditableWarning={true}
                                        spellCheck={false}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            handleClickTaskbox(event);
                                            setGetCaret(window.getSelection()!.anchorOffset);
                                          }
                                        }}
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {event.title}
                                      </TaskboxInput>
                                    )}
                                  </TaskboxInputWrapper>
                                  {event.issueId && (
                                    <Button
                                      variant="outlined"
                                      color="inherit"
                                      onClick={() => handleClickIssueDetail(event.issueId!)}
                                      sx={{
                                        width: '100px',
                                        height: '24px',
                                        borderRadius: '8px',
                                        padding: '0px',
                                        color: `${COLORS.gray400}`,
                                        border: `1px solid ${COLORS.gray400}`,
                                      }}
                                    >
                                      <div className="timelineEvents-issue-detail">
                                        <IssueLinkage />
                                        <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                      </div>
                                    </Button>
                                  )}
                                </div>
                                <div data-foldShowingId={`${event.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                                  {event.memo && <Icons.TaskboxMemo />}
                                  {event.links!.length > 0 && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                    </div>
                                  )}
                                  {event.isRecurrence && (
                                    <div style={{ marginLeft: '4px' }}>
                                      <Icons.Recurrence />
                                    </div>
                                  )}
                                  {tasks.length > 0 && (
                                    <div className="show-subtask">
                                      <Icons.Subtask />
                                      <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                    </div>
                                  )}
                                </div>
                                {!multiSelected && (
                                  <TaskboxOptionsContainer className="taskbox-option" style={selectedTaskbox ? { visibility: 'visible' } : {}}>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={event.focus ? '하이라이트풀기' : '하이라이트하기'} disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleHighlightTaskbox();
                                            }}
                                          >
                                            {event.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <Divider
                                      orientation="vertical"
                                      variant="middle"
                                      flexItem
                                      sx={{
                                        margin: '4px 2px',
                                      }}
                                    />
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip
                                          title={
                                            <div style={{ margin: '2px 4px' }}>
                                              <span>날짜 및 시간 설정</span>
                                              <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                                T
                                              </KeyboardButtonRect>
                                            </div>
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            data-timeselectionid={event.id}
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleOpenTimeSelectionModal(e);
                                            }}
                                          >
                                            <Icons.SelectTime />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent id="tutorial-calendar">
                                      <Tooltip
                                        title={
                                          event.recurrence
                                            ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                                            : event.lockedIn
                                            ? '구글 캘린더에서 해제하기'
                                            : '구글 캘린더에 표시하기'
                                        }
                                        disableInteractive
                                      >
                                        <TaskboxOptionsIcon style={{ cursor: 'pointer' }}>
                                          <IconButton
                                            disabled={event.recurrence ? true : false}
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleLockInOutButton(event.lockedIn!);
                                            }}
                                          >
                                            {event.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                                          </IconButton>
                                        </TaskboxOptionsIcon>
                                      </Tooltip>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip
                                          title={
                                            <div style={{ margin: '2px 4px' }}>
                                              <span>포커스모드</span>
                                              {/* <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Shift
                                                  </KeyboardButtonRect>
                                                  +
                                                  <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Option
                                                  </KeyboardButtonRect>
                                                  + <KeyboardButtonRect small>F</KeyboardButtonRect> */}
                                              <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                                F
                                              </KeyboardButtonRect>
                                            </div>
                                          }
                                          disableInteractive
                                        >
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) handleClickFocusMode();
                                            }}
                                          >
                                            <Icons.FocusMode />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title="더보기" disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '4px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              if (!e.metaKey && !e.ctrlKey) {
                                                setSelectedTaskbox(event.id!);
                                                setTaskboxDetail(e.currentTarget);
                                              }
                                            }}
                                          >
                                            <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                  </TaskboxOptionsContainer>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </TaskDragContainer>
                    )}
                  </TaskboxDragContainer>
                );
              }}
            </Draggable>
            {/* 하이라이트 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(highlightTimeSelection)}
                anchorEl={highlightTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseHighlightTimeSelectionModal}
              >
                <HighlightTimeSelectionModal event={event} onClose={handleCloseHighlightTimeSelectionModal} onClick={handleClickHighlightTime} />
              </Popover>
            )}
            {/** 간편 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(simpleTimeSelection)}
                anchorEl={simpleTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseSimpleTimeSelectionModal}
                disableEscapeKeyDown
              >
                <SimpleTimeSelectionModal event={event} onClose={handleCloseSimpleTimeSelectionModal} onChangeTime={handleChangeTime} />
              </Popover>
            )}
            {/* 날짜 및 시간 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(timeSelection)}
                anchorEl={timeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseTimeSelectionModal}
              >
                <TimeDateSelectionModal
                  event={event}
                  suppressMoveDate={event.type === 'TERM_TASK'}
                  onClose={handleCloseTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                />
              </Popover>
            )}
            {/* 태스크박스 상세 설정 팝오버 */}
            {event.id === selectedTaskbox && (
              <TimelineTaskboxDetail
                event={event}
                detail={taskboxDetail}
                suppressDivider={!!event.project}
                onCloseDetail={handleCloseTimelineDetail}
                onClickMenu={handleClickTaskboxContextMenu}
              />
            )}
            {/**이어서 완료하기 팝오버 */}
            {event.id === selectedTaskbox && (
              <Popover
                open={Boolean(inProgressPopover)}
                anchorEl={inProgressPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handleCloseInProgressPopover}
                sx={{ marginTop: '4px' }}
              >
                <InProgressTaskbox>
                  <InProgressTaskboxContent
                    onClick={() => {
                      handleClickTaskboxCheck();
                      handleCloseInProgressPopover();
                    }}
                  >
                    {event.focus ? (
                      <Icons.FocusCheck width={16} height={16} />
                    ) : (
                      <Icons.TaskboxCheck width={16} height={16} fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                    )}
                    <span style={{ marginLeft: '8px' }}>모두 완료하기</span>
                  </InProgressTaskboxContent>
                  {event.project ? (
                    <Tooltip
                      title={
                        <span>
                          인스턴스를 완료하고 미완료 하위 업무를
                          <br />
                          다음 인스턴스나 프로젝트로 보냅니다.
                        </span>
                      }
                      placement="right"
                    >
                      <InProgressTaskboxContent onClick={handleClickInstanceContinue}>
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '8px' }}>일부 완료하기</span>
                        <span style={{ marginLeft: '4px' }}>
                          <Icons.QuestionInfo />
                        </span>
                      </InProgressTaskboxContent>
                    </Tooltip>
                  ) : (
                    <InProgressTaskboxContent
                      onClick={(e) => {
                        setContinueSettingPopover(e.currentTarget);
                      }}
                    >
                      {event.focus ? (
                        <Icons.ContinueFocusTaskbox />
                      ) : (
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                      )}
                      <span style={{ marginLeft: '8px' }}>일부 완료하고 이어서하기</span>
                    </InProgressTaskboxContent>
                  )}
                </InProgressTaskbox>
              </Popover>
            )}
            {/** 태스크박스 이어서하기 설정창 */}
            <TaskboxContinueDetail
              anchorEl={continueSettingPopover}
              event={event}
              onCloseContinue={handleCloseContinueSettingPopover}
              onChangeDate={handleChangeInprogress}
            />
            {/* 태스크박스 카테고리 */}
            {
              <Popover
                open={Boolean(categoryAnchorEl)}
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setSelectedTaskbox(undefined);
                  setCategoryAnchorEl(null);
                  if (isQuickCategory) {
                    setFocusElement(focusedTaskbox!.id!);
                    setIsQuickCategory(false);
                  }
                }}
                sx={{ marginTop: '4px' }}
              >
                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
              </Popover>
            }
            {/* 태스크박스 기한 */}
            {
              <Popover
                open={Boolean(deadlineAnchorEl)}
                anchorEl={deadlineAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setSelectedTaskbox(undefined);
                  setDeadlineAnchorEl(null);
                }}
                sx={{ marginTop: '4px' }}
              >
                <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
              </Popover>
            }
            {/* 태스크박스 멀티셀렉  */}
            {multiSelectIds[multiSelectIds.length - 1] === event!.id && (
              <Popper open={Boolean(multiSelectAnchorEl)} anchorEl={multiSelectAnchorEl} placement={'right-start'} ref={refPopperMenu} sx={{ zIndex: 1000 }}>
                <TaskboxMultiSelectPopover
                  date={dayjs(
                    events?.find((v) => v.id === multiSelectIds[0])?.start?.date || events?.find((v) => v.id === multiSelectIds[0])?.start?.datetime,
                  ).toDate()}
                  multiSelectCount={multiSelectIds.length}
                  openDeleteDialog={isOpenMultiSelectDeleteDialog}
                  onOpenDeleteDialog={handleOpenMultiSelectDeleteDialog}
                  onCloseDeleteDialog={handleCloseMultiSelectDeleteDialog}
                  onClickMultiSelectActions={onClickMultiSelectActions}
                />
              </Popper>
            )}
            {/** 자주 하는 업무 팝오버 */}
            {event.id === selectedTaskbox && (
              <TemplateDialog
                open={openTemplateDialog}
                templateList={templateList}
                categoryList={categoryList}
                onClose={handleCloseTemplateDialog}
                onPut={handlePutTemplate}
                onDelete={handleDeleteTemplate}
                onAdd={handleTemplateToTaskbox}
                onClickCategory={handleClickCategoryActions}
              />
            )}
            {/** 포커스 모드 dialog */}
            {focusModeTaskbox && event.id === focusModeTaskbox!.id && (
              <FocusModeDialog
                open={Boolean(focusModeTaskbox) && !hideFocusMode}
                taskbox={focusModeTaskbox}
                templateList={templateList}
                categoryList={categoryList}
                hide={hideFocusMode}
                onFetch={handleFetchFocusMode}
                onClose={handleCloseFocusMode}
                onHide={handleHideFocusMode}
                onDelete={handleDeleteFocusMode}
                onCreateTemplate={handleCreateTemplate}
                onPutTemplate={handlePutTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onChangeInprogress={handleChangeFocusModeInprogress}
                onChangeTimer={onChangeTimer}
                onClickCategoryActions={handleClickCategoryActions}
                onChangeDeadline={handleChangeDeadline}
                onClickInstanceContinue={handleClickInstanceContinue}
                onPushProjectRecommendTask={handlePushProjectRecommendTask}
                onDeleteProject={handleDeleteProject}
              />
            )}
            {/** 프로젝트 상세보기 */}
            <ProjectDetailView
              selectedProject={selectedProject || undefined}
              onClose={handleCloseProjectDetail}
              onDelete={handleDeleteProject}
              onFetch={fetchProjects}
            />
            {event.id === focusedTaskbox?.id && deleteWithKeydown && (
              <ConfirmDeleteDialog open={deleteWithKeydown} onClose={() => setDeleteWithKeydown(false)} onDelete={handleDeleteWithKeydown} />
            )}
            {(event.id === focusedTaskbox?.id || event.id === selectedTaskbox) && (
              <UpdateRecurrenceDialog open={openRecurrenceUpdate} onClose={handleCloseRecurrenceUpdateDialog} onUpdateRecurrence={handleRemoveRecurrenceTime} />
            )}
            {(event.id === focusedTaskbox?.id || event.id === selectedTaskbox) && deleteRecurrenceWithKeydown && (
              <RemoveRecurrenceDialog
                open={deleteRecurrenceWithKeydown}
                onClose={() => setDeleteRecurrenceWithKeydown(false)}
                onRemoveRecurrence={handleDeleteRecurrenceWithKeydown}
              />
            )}
            {!!clickedIssue && plan && (
              <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                <IssueDetailView
                  issueId={event!.issueId!}
                  selectable={true}
                  priority={plan.length > 0 ? plan[0].priority : undefined}
                  // timelineView={timelineView}
                  // tasks={selectedTasks}
                  onClickCancel={handleClickCancelIssue}
                  onClickSave={handleClickSaveIssue}
                />
              </Dialog>
            )}
            <TaskDropContainer height={taskboxHeight} onDrop={() => handleDragDropToTaskbox(event.id!)} hidden={!draggingToTaskbox} />
          </div>
        ) : (
          <div
            className="taskbox-drag-container"
            ref={refTaskbox}
            data-taskbox={`${event!.id}`}
            draggable={multiSelected ? (multiSelectIds.length > 0 ? true : false) : true}
            onDragStart={multiSelected ? (e) => onMultiSelectDragStart?.(e, doneStatus) : handleDragStart}
            onDragEnd={multiSelected ? onMultiSelectDragEnd : handleDragEnd}
            onDragEnter={handleDragEnterToTaskbox}
            onDragLeave={(e) => handleDragLeaveFromTaskbox(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) onChangeMultiSelectIds && onChangeMultiSelectIds(e, event!.id!);
              if (focusedTaskbox?.id === event!.id) handleClickTaskbox(event!);
            }}
          >
            {expanded || focusedTaskbox?.id === event?.id ? (
              <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                {(provided) => (
                  <TaskDragContainer
                    className="timeline-container"
                    key={event?.id}
                    data-scroll={`${event?.id}`}
                    event={event}
                    taskboxDone={taskboxDone}
                    focusedTaskbox={focusedTaskbox}
                    plan={plan}
                    hidden={draggingToTaskbox}
                    selectedTaskbox={selectedTaskbox}
                    multiSelected={multiSelected}
                    onClick={(e) => {
                      if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                    }}
                  >
                    {event && (
                      <div
                        className="flex gap-4 timeline-border"
                        onClick={(e) => {
                          if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                        }}
                      >
                        <div
                          className="line"
                          onClick={(e) => {
                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                          }}
                        />
                        <div
                          className="flex-1"
                          onClick={(e) => {
                            if (e.currentTarget === e.target) handleClickTaskbox(event!);
                          }}
                          style={{ pointerEvents: 'all' }}
                        >
                          <TaskboxPropertyWrapper>
                            {event.category && (
                              <CategoryShowingWrapper
                                textColor={getCategoryTextColor(event.category.color)}
                                bgColor={getCategoryBgColor(event.category.color)}
                                onClick={(e) => {
                                  setSelectedTaskbox(event.id);
                                  setCategoryAnchorEl(e.currentTarget);
                                }}
                              >
                                {`# ${event.category.name}`}
                                <IconButton
                                  className="category-detach-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickCategoryActions(null, 'SELECT');
                                  }}
                                  sx={{
                                    'width': '12px',
                                    'height': '12px',
                                    'borderRadius': '4px',
                                    'marginLeft': '4px',
                                    'padding': '0px',
                                    ':hover': {
                                      backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                    },
                                  }}
                                  style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                >
                                  <Icons.Close width={8} height={8} fill={getCategoryTextColor(event.category.color)} />
                                </IconButton>
                              </CategoryShowingWrapper>
                            )}
                            {event.deadline && (
                              <DeadlineShowingWrapper
                                date={event.deadline}
                                onClick={(e) => {
                                  setSelectedTaskbox(event.id);
                                  setDeadlineAnchorEl(e.currentTarget);
                                }}
                              >
                                <Icons.Flag
                                  fill={
                                    dayjs(event!.deadline).isToday()
                                      ? COLORS.brand1
                                      : dayjs(event!.deadline).isBefore(dayjs())
                                      ? COLORS.negative1
                                      : COLORS.gray600
                                  }
                                />
                                <span style={{ marginLeft: '2px' }}>
                                  {dayjs(event.deadline).isToday()
                                    ? '오늘'
                                    : dayjs(event.deadline).isYesterday()
                                    ? '어제'
                                    : dayjs(event.deadline).isTomorrow()
                                    ? '내일'
                                    : dayjs(event.deadline).format('M월 D일 (dd)')}
                                </span>
                                <IconButton
                                  className="deadline-detach-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleChangeDeadline(null);
                                  }}
                                  sx={{
                                    'width': '12px',
                                    'height': '12px',
                                    'borderRadius': '4px',
                                    'marginLeft': '4px',
                                    'padding': '0px',
                                    ':hover': {
                                      backgroundColor: hexToRGBA(
                                        dayjs(event!.deadline).isToday()
                                          ? COLORS.brand1
                                          : dayjs(event!.deadline).isBefore(dayjs())
                                          ? COLORS.negative1
                                          : COLORS.gray600,
                                        0.3,
                                      ),
                                    },
                                  }}
                                  style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                                >
                                  <Icons.Close
                                    width={8}
                                    height={8}
                                    fill={
                                      dayjs(event!.deadline).isToday()
                                        ? COLORS.brand1
                                        : dayjs(event!.deadline).isBefore(dayjs())
                                        ? COLORS.negative1
                                        : COLORS.gray600
                                    }
                                  />
                                </IconButton>
                              </DeadlineShowingWrapper>
                            )}
                            {/* 타임박스 시간 */}
                            <div
                              className="flex gap-1 items-center time-modal"
                              onClick={(e) => {
                                if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                              }}
                            >
                              <div className="time-modal-container">
                                {event.start?.datetime ? <Icons.Time stroke={COLORS.gray500} /> : <Icons.Today />}
                                <Fonts.Blockquote
                                  className="text-gray500"
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleOpenSimpleTimeSelectionModal(e);
                                    }
                                  }}
                                  style={{ marginLeft: '4px' }}
                                >
                                  {event.start?.datetime ? showingTime(event) : '오늘'}
                                </Fonts.Blockquote>
                                {!multiSelected ? (
                                  <Tooltip title={'시간 해제하기'} disableInteractive>
                                    <IconButton
                                      className="unselect-time"
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                        }
                                      }}
                                      sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                    >
                                      <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <div style={{ width: '24px', height: '24px' }} />
                                )}
                              </div>
                            </div>
                          </TaskboxPropertyWrapper>
                          <div className="flex justify-between items-start relative timeline-header">
                            {/* 타임박스 제목 */}
                            <div className="timelineEvents-title-container">
                              {tasks.filter((task) => task.done).length > 0 && !taskboxDone ? (
                                <Checkbox
                                  checked={false}
                                  icon={
                                    event.focus ? (
                                      <Icons.FocusInprogressCheck />
                                    ) : (
                                      <Icons.InprogressCheck fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                                    )
                                  }
                                  sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      setSelectedTaskbox(event.id);
                                      setInProgressPopover(e.currentTarget);
                                    }
                                  }}
                                />
                              ) : (
                                <PriorityIssueCheckbox
                                  width={20}
                                  height={20}
                                  focus={event.focus}
                                  bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                  checked={!!event.done}
                                  // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                  // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                                  }}
                                  style={{ marginTop: 2, marginRight: 8 }}
                                />
                              )}
                              {isExploding && event.focus && (
                                <ConfettiExplosion
                                  force={0.6}
                                  duration={2500}
                                  particleCount={80}
                                  width={1000}
                                  onComplete={() => setIsExploding(false)}
                                  style={{ position: 'absolute', top: 0, left: 0 }}
                                />
                              )}
                              <TaskboxInputWrapper isProject={!!event.project}>
                                {event.project && (
                                  <ProjectIcon>
                                    <Icons.Issue />
                                  </ProjectIcon>
                                )}

                                {event.lockedIn && <LockInIcon event={event} />}
                                {event.project ? (
                                  <Tooltip title="프로젝트 상세보기" disableInteractive placement="bottom-start">
                                    <TaskboxInput
                                      className="timelineEvents-title"
                                      data-id={
                                        event.type === 'TERM_TASK'
                                          ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                          : `${event.id}`
                                      }
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) {
                                          handleClickTaskbox(event);
                                          handleClickInstance(event);
                                        }
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {event.title}
                                    </TaskboxInput>
                                  </Tooltip>
                                ) : (
                                  <TaskboxInput
                                    ref={refTaskboxInput}
                                    className="timelineEvents-title"
                                    data-id={
                                      event.type === 'TERM_TASK'
                                        ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                        : `${event.id}`
                                    }
                                    onBlur={(e) => handleBlurTitle(e, event)}
                                    onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                    placeholder="Title"
                                    contentEditable={multiSelected ? false : true}
                                    suppressContentEditableWarning={true}
                                    spellCheck={false}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        handleClickTaskbox(event);
                                        setGetCaret(window.getSelection()!.anchorOffset);
                                      }
                                    }}
                                  >
                                    {event.title}
                                  </TaskboxInput>
                                )}
                              </TaskboxInputWrapper>
                              {event.issueId && (
                                <Button
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    setClickedIssue(event.issueId!);
                                  }}
                                  sx={{
                                    width: '100px',
                                    height: '24px',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    color: `${COLORS.gray400}`,
                                    border: `1px solid ${COLORS.gray400}`,
                                  }}
                                >
                                  <div className="timelineEvents-issue-detail">
                                    <IssueLinkage />
                                    <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                  </div>
                                </Button>
                              )}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {event.memo && <Icons.TaskboxMemo />}
                              {event.links!.length > 0 && (
                                <div style={{ marginLeft: '4px' }}>
                                  <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                                </div>
                              )}
                              {event.isRecurrence && (
                                <div style={{ marginLeft: '4px' }}>
                                  <Icons.Recurrence />
                                </div>
                              )}

                              {tasks.length > 0 && (
                                <div className="show-subtask">
                                  <Icons.Subtask />
                                  <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                                </div>
                              )}
                            </div>
                            {!multiSelected && (
                              <TaskboxOptionsContainer className="taskbox-option" style={selectedTaskbox ? { visibility: 'visible' } : {}}>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip title={event.focus ? '하이라이트풀기' : '하이라이트하기'} disableInteractive>
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) handleHighlightTaskbox();
                                        }}
                                      >
                                        {event.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <Divider
                                  orientation="vertical"
                                  variant="middle"
                                  flexItem
                                  sx={{
                                    margin: '4px 2px',
                                  }}
                                />
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip
                                      title={
                                        <div style={{ margin: '2px 4px' }}>
                                          <span>날짜 및 시간 설정</span>
                                          <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                            T
                                          </KeyboardButtonRect>
                                        </div>
                                      }
                                      disableInteractive
                                    >
                                      <IconButton
                                        data-timeselectionid={event.id}
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) handleOpenTimeSelectionModal(e);
                                        }}
                                      >
                                        <Icons.SelectTime />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip
                                      title={
                                        event.recurrence
                                          ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                                          : event.lockedIn
                                          ? '구글 캘린더에서 해제하기'
                                          : '구글 캘린더에 표시하기'
                                      }
                                      disableInteractive
                                    >
                                      <TaskboxOptionsIcon style={{ cursor: 'pointer' }}>
                                        <IconButton
                                          disabled={event.recurrence ? true : false}
                                          sx={{ borderRadius: '8px', padding: '4px' }}
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => {
                                            if (!e.metaKey && !e.ctrlKey) handleLockInOutButton(event.lockedIn!);
                                          }}
                                        >
                                          {event.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                                        </IconButton>
                                      </TaskboxOptionsIcon>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip
                                      title={
                                        <div style={{ margin: '2px 4px' }}>
                                          <span>포커스모드</span>
                                          {/* <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Shift
                                                  </KeyboardButtonRect>
                                                  +
                                                  <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Option
                                                  </KeyboardButtonRect>
                                                  + <KeyboardButtonRect small>F</KeyboardButtonRect> */}
                                          <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                            F
                                          </KeyboardButtonRect>
                                        </div>
                                      }
                                      disableInteractive
                                    >
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) handleClickFocusMode();
                                        }}
                                      >
                                        <Icons.FocusMode />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                                <TaskboxOptionsContent>
                                  <TaskboxOptionsIcon>
                                    <Tooltip title="더보기" disableInteractive>
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '4px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                          if (!e.metaKey && !e.ctrlKey) {
                                            setSelectedTaskbox(event.id!);
                                            setTaskboxDetail(e.currentTarget);
                                          }
                                        }}
                                      >
                                        <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                      </IconButton>
                                    </Tooltip>
                                  </TaskboxOptionsIcon>
                                </TaskboxOptionsContent>
                              </TaskboxOptionsContainer>
                            )}
                          </div>
                          <div className={`${event.id} `} {...provided.droppableProps} ref={provided.innerRef}>
                            <SubtaskView>
                              <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                {event &&
                                  tasks?.map((task, index) => (
                                    <Draggable draggableId={task.id!} index={index} key={task.id}>
                                      {(provided) => (
                                        <EditableTextFieldWrapper
                                          {...provided.draggableProps}
                                          key={task.id}
                                          className="flex justify-between items-center relative"
                                          style={{ ...provided.draggableProps.style }}
                                          ref={provided.innerRef}
                                          selectedTaskId={multiSelected ? false : selectedTaskId === task.id ? true : false}
                                          hover={multiSelected ? false : selectedEvent?.id === event.id && selectedTaskId === task.id ? false : true}
                                        >
                                          <div className="flex items-center box-border task-container">
                                            <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                            <SubTaskCheckbox
                                              focus={event.focus}
                                              multiSelect={multiSelected}
                                              bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                                              checked={!!task.done}
                                              // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                              // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) handleCheckbox(index);
                                              }}
                                            />
                                            <SubTaskField
                                              className="subtask flex items-center ml-2"
                                              ref={refTask}
                                              data-id={
                                                event.type === 'TERM_TASK'
                                                  ? `${task.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                                  : `${task.id}`
                                              }
                                              data-timeboxid={event.id}
                                              placeholder={
                                                !delayRecommendTask && selectedTaskId === task.id && event.project && projectSubtasks.length > 0
                                                  ? projectSubtasks[recommendTaskIndex].title === ''
                                                    ? '제목 없음'
                                                    : projectSubtasks[recommendTaskIndex].title!
                                                  : 'Title'
                                              }
                                              contentEditable={multiSelected ? false : true}
                                              suppressContentEditableWarning={true}
                                              spellCheck={false}
                                              taskboxDone={taskboxDone}
                                              onBlur={(e) => handleSaveChangedTask(e, index)}
                                              onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                              onClick={(e) => {
                                                if (!e.metaKey && !e.ctrlKey) {
                                                  handleClickTaskbox(event);
                                                  handleSelectedTaskId && handleSelectedTaskId(task.id!);
                                                }
                                              }}
                                            >
                                              {task.content}
                                            </SubTaskField>
                                          </div>
                                          {(!isInstanceTyping &&
                                            selectedTaskId === task.id &&
                                            event.project &&
                                            projectSubtasks.length > 0 &&
                                            task.content === '') ||
                                          (isRemoveTextAndRecommend && selectedTaskId === task.id && event.project && projectSubtasks.length > 0) ? (
                                            !delayRecommendTask ? (
                                              <RecommendTaskWrapper>
                                                {projectSubtasks.length > 1 && (
                                                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                                    <RecommendButtonRect style={{ marginRight: 4 }}>←</RecommendButtonRect>
                                                    <RecommendButtonRect style={{ marginRight: 4 }}>→</RecommendButtonRect>
                                                    <span>{`으로 탐색하고 (${recommendTaskIndex + 1}/${projectSubtasks.length})`}</span>
                                                  </div>
                                                )}
                                                <RecommendButtonRect style={{ marginRight: 4 }}>Tab</RecommendButtonRect>
                                                <span>으로 추가하기</span>
                                              </RecommendTaskWrapper>
                                            ) : (
                                              <CircularProgress style={{ width: '16px', height: '16px', color: COLORS.issue2 }} />
                                            )
                                          ) : (
                                            <>
                                              {!multiSelected && (
                                                <SubtaskAddsOnWrapper className="subtask-addson">
                                                  {task.category ? (
                                                    <CategoryShowingWrapper
                                                      className="detail task-detail"
                                                      textColor={getCategoryTextColor(task.category.color)}
                                                      bgColor={getCategoryBgColor(task.category.color)}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                          setSubtaskId(task.id);
                                                          setCategoryAnchorEl(e.currentTarget);
                                                        }
                                                      }}
                                                      style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                    >
                                                      {`# ${task.category.name}`}
                                                      <IconButton
                                                        className="category-detach-button"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleClickCategoryActions(null, 'SELECT', task.id);
                                                        }}
                                                        sx={{
                                                          'width': '12px',
                                                          'height': '12px',
                                                          'borderRadius': '4px',
                                                          'marginLeft': '4px',
                                                          'padding': '0px',
                                                          ':hover': {
                                                            backgroundColor: hexToRGBA(getCategoryTextColor(task.category!.color)!, 0.3),
                                                          },
                                                        }}
                                                        style={categoryAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                      >
                                                        <Icons.Close width={8} height={8} fill={getCategoryTextColor(task.category.color)} />
                                                      </IconButton>
                                                    </CategoryShowingWrapper>
                                                  ) : (
                                                    <IconButton
                                                      className="detail task-detail"
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                          setSubtaskId(task.id);
                                                          setCategoryAnchorEl(e.currentTarget);
                                                        }
                                                      }}
                                                      sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '4px',
                                                        padding: '0px',
                                                        marginRight: '4px',
                                                      }}
                                                      style={
                                                        categoryAnchorEl && subtaskId === task.id && !multiSelected
                                                          ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                          : {}
                                                      }
                                                    >
                                                      <Icons.Hashtag width={16} height={16} fill={COLORS.gray400} />
                                                    </IconButton>
                                                  )}
                                                  {task.deadline ? (
                                                    <DeadlineShowingWrapper
                                                      className="detail task-detail"
                                                      date={task.deadline}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                          setSubtaskId(task.id);
                                                          setDeadlineAnchorEl(e.currentTarget);
                                                        }
                                                      }}
                                                      style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                    >
                                                      <Icons.Flag
                                                        fill={
                                                          dayjs(task!.deadline).isToday()
                                                            ? COLORS.brand1
                                                            : dayjs(task!.deadline).isBefore(dayjs())
                                                            ? COLORS.negative1
                                                            : COLORS.gray600
                                                        }
                                                      />
                                                      <span style={{ marginLeft: '2px' }}>
                                                        {dayjs(task.deadline).isToday()
                                                          ? '오늘'
                                                          : dayjs(task.deadline).isYesterday()
                                                          ? '어제'
                                                          : dayjs(task.deadline).isTomorrow()
                                                          ? '내일'
                                                          : dayjs(task.deadline).format('M월 D일 (dd)')}
                                                      </span>
                                                      <IconButton
                                                        className="deadline-detach-button"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleChangeDeadline(null, task.id);
                                                        }}
                                                        sx={{
                                                          'width': '12px',
                                                          'height': '12px',
                                                          'borderRadius': '4px',
                                                          'marginLeft': '4px',
                                                          'padding': '0px',
                                                          ':hover': {
                                                            backgroundColor: hexToRGBA(
                                                              dayjs(task!.deadline).isToday()
                                                                ? COLORS.brand1
                                                                : dayjs(task!.deadline).isBefore(dayjs())
                                                                ? COLORS.negative1
                                                                : COLORS.gray600,
                                                              0.3,
                                                            ),
                                                          },
                                                        }}
                                                        style={deadlineAnchorEl && subtaskId === task.id && !multiSelected ? { display: 'flex' } : {}}
                                                      >
                                                        <Icons.Close
                                                          width={8}
                                                          height={8}
                                                          fill={
                                                            dayjs(task!.deadline).isToday()
                                                              ? COLORS.brand1
                                                              : dayjs(task!.deadline).isBefore(dayjs())
                                                              ? COLORS.negative1
                                                              : COLORS.gray600
                                                          }
                                                        />
                                                      </IconButton>
                                                    </DeadlineShowingWrapper>
                                                  ) : (
                                                    <IconButton
                                                      className="detail task-detail"
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                          setSubtaskId(task.id);
                                                          setDeadlineAnchorEl(e.currentTarget);
                                                        }
                                                      }}
                                                      sx={{
                                                        width: '20px',
                                                        height: '20px',
                                                        borderRadius: '4px',
                                                        padding: '0px',
                                                        marginRight: '4px',
                                                      }}
                                                      style={
                                                        deadlineAnchorEl && subtaskId === task.id && !multiSelected
                                                          ? { opacity: 1, backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                                          : {}
                                                      }
                                                    >
                                                      <Icons.Flag width={16} height={16} fill={COLORS.gray400} />
                                                    </IconButton>
                                                  )}
                                                  <TaskIconWrapper
                                                    className="detail task-detail"
                                                    aria-label="later"
                                                    onClick={() => handleClickSubtaskContextMenu(task.id!, 'LATER')}
                                                  >
                                                    {event.project ? (
                                                      <Icons.InstanceLater />
                                                    ) : (
                                                      <div className="later">
                                                        <Icons.Later width={16} height={16} fill={COLORS.gray400} />
                                                      </div>
                                                    )}
                                                  </TaskIconWrapper>
                                                  <TaskIconWrapper
                                                    className="detail task-detail"
                                                    aria-label="delete"
                                                    onClick={() => handleClickSubtaskContextMenu(task.id!, 'DELETE')}
                                                    style={{ marginRight: event.project ? 0 : 4 }}
                                                  >
                                                    <div className="delete">
                                                      <Icons.Delete fill={COLORS.gray400} />
                                                    </div>
                                                  </TaskIconWrapper>
                                                  {!event.project && (
                                                    <div
                                                      className="detail task-detail"
                                                      style={subTaskDetail && subtaskId === task.id && !multiSelected ? { opacity: 1 } : {}}
                                                      onClick={(e) => {
                                                        if (!e.metaKey && !e.ctrlKey) {
                                                          if (selectedTaskId) handleSelectedTaskId(task.id!);
                                                          setSubtaskId(task.id);
                                                          setSubTaskDetail(e.currentTarget);
                                                        }
                                                      }}
                                                    >
                                                      <Icons.ColorMore />
                                                    </div>
                                                  )}
                                                </SubtaskAddsOnWrapper>
                                              )}
                                            </>
                                          )}
                                          {/* 태스크 카테고리 */}
                                          {subtaskId === task.id && (
                                            <Popover
                                              open={Boolean(categoryAnchorEl)}
                                              anchorEl={categoryAnchorEl}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                              }}
                                              onClose={() => {
                                                setSelectedTaskbox(undefined);
                                                setSubtaskId(undefined);
                                                setCategoryAnchorEl(null);
                                              }}
                                              sx={{ marginTop: '4px' }}
                                            >
                                              <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
                                            </Popover>
                                          )}
                                          {/* 태스크 기한 */}
                                          {subtaskId === task.id && (
                                            <Popover
                                              open={Boolean(deadlineAnchorEl)}
                                              anchorEl={deadlineAnchorEl}
                                              anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                              }}
                                              onClose={() => {
                                                setSelectedTaskbox(undefined);
                                                setSubtaskId(undefined);
                                                setDeadlineAnchorEl(null);
                                              }}
                                              sx={{ marginTop: '4px' }}
                                            >
                                              <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
                                            </Popover>
                                          )}
                                          {selectedTaskId === task.id && (
                                            <SubtaskDetail
                                              taskId={task.id}
                                              detail={subTaskDetail}
                                              menus={['CONVERT_TO_TASKBOX']}
                                              onCloseDetail={handleCloseSubTaskDetail}
                                              onClickSubtaskContextMenu={handleClickSubtaskContextMenu}
                                            />
                                          )}
                                        </EditableTextFieldWrapper>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided!.placeholder!}
                                <TaskboxAddOn multiSelected={multiSelected}>
                                  <SubtaskWrapper
                                    data-timeboxid={event.id}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        handleClickTaskAdd(event);
                                      }
                                    }}
                                  >
                                    <Icons.Plus width={16} height={16} fill={COLORS.gray500} />
                                    <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>하위업무 추가하기</Fonts.Body2>
                                  </SubtaskWrapper>
                                  <TemplateWrapper className="template-btn">
                                    <div style={{ width: '1px', height: '12px', backgroundColor: COLORS.gray300, margin: '0px 8px' }} />
                                    <TemplateButtonWrapper
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) handleOpenTemplateDialog();
                                      }}
                                    >
                                      <Icons.Template width={16} height={16} fill={COLORS.gray500} />
                                      <div style={{ marginLeft: '4px' }}>템플릿 불러오기</div>
                                    </TemplateButtonWrapper>
                                  </TemplateWrapper>
                                </TaskboxAddOn>
                                {event.isRecurrence && updateRecurrence && (
                                  <UpdateRecurrenceWrapper onClick={handleReflectRecurrence}>
                                    <Icons.UpdateRecurrence />
                                    <Fonts.Body2 style={{ marginTop: '2px', marginLeft: '4px', color: COLORS.gray500 }}>
                                      이후 반복 업무에 변경사항 반영하기
                                    </Fonts.Body2>
                                  </UpdateRecurrenceWrapper>
                                )}
                              </div>
                            </SubtaskView>
                          </div>
                          {event.memo && focusedTaskbox && focusedTaskbox.id === event.id && (
                            <ShowingMemoWrapper>
                              <Divider sx={{ margin: '4px 0px' }} />
                              <Tooltip title="포커스모드에서 편집하기" followCursor>
                                <ShowingMemoTextWrapper
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickFocusMode(event);
                                    }
                                  }}
                                >
                                  {event.memo?.content?.[0]?.content?.[0]?.text && <div>{event.memo.content[0].content[0].text}</div>}
                                  {event.memo?.content?.[1]?.content?.[0]?.text && <div>{event.memo.content[1].content[0].text}</div>}
                                </ShowingMemoTextWrapper>
                              </Tooltip>
                            </ShowingMemoWrapper>
                          )}
                        </div>
                      </div>
                    )}
                  </TaskDragContainer>
                )}
              </Droppable>
            ) : (
              <TaskDragContainer
                className="timeline-container"
                key={event?.id}
                data-scroll={`${event?.id}`}
                event={event}
                taskboxDone={taskboxDone}
                focusedTaskbox={focusedTaskbox}
                plan={plan}
                hidden={draggingToTaskbox}
                ref={refTaskbox}
                selectedTaskbox={selectedTaskbox}
                multiSelected={multiSelected}
                onClick={(e) => {
                  if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                }}
                style={{ position: 'relative' }}
              >
                {event && (
                  <div
                    className="flex gap-4 timeline-border"
                    onClick={(e) => {
                      if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                    }}
                  >
                    <div
                      className="line"
                      onClick={(e) => {
                        if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                      }}
                    />
                    <div
                      className="flex-1"
                      onClick={(e) => {
                        if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                      }}
                      style={{ pointerEvents: 'all', width: 'calc(100% - 20px)' }}
                    >
                      <TaskboxPropertyWrapper>
                        {event.category && (
                          <CategoryShowingWrapper
                            textColor={getCategoryTextColor(event.category.color)}
                            bgColor={getCategoryBgColor(event.category.color)}
                            onClick={(e) => {
                              setSelectedTaskbox(event.id);
                              setCategoryAnchorEl(e.currentTarget);
                            }}
                          >
                            {`# ${event.category.name}`}
                            <IconButton
                              className="category-detach-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleClickCategoryActions(null, 'SELECT');
                              }}
                              sx={{
                                'width': '12px',
                                'height': '12px',
                                'borderRadius': '4px',
                                'marginLeft': '4px',
                                'padding': '0px',
                                ':hover': {
                                  backgroundColor: hexToRGBA(getCategoryTextColor(event.category!.color)!, 0.3),
                                },
                              }}
                              style={categoryAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                            >
                              <Icons.Close width={8} height={8} fill={getCategoryTextColor(event.category.color)} />
                            </IconButton>
                          </CategoryShowingWrapper>
                        )}
                        {event.deadline && (
                          <DeadlineShowingWrapper
                            date={event.deadline}
                            onClick={(e) => {
                              setSelectedTaskbox(event.id);
                              setDeadlineAnchorEl(e.currentTarget);
                            }}
                          >
                            <Icons.Flag
                              fill={
                                dayjs(event!.deadline).isToday() ? COLORS.brand1 : dayjs(event.deadline).isBefore(dayjs()) ? COLORS.negative1 : COLORS.gray600
                              }
                            />
                            <span style={{ marginLeft: '2px' }}>
                              {dayjs(event.deadline).isToday()
                                ? '오늘'
                                : dayjs(event.deadline).isYesterday()
                                ? '어제'
                                : dayjs(event.deadline).isTomorrow()
                                ? '내일'
                                : dayjs(event.deadline).format('M월 D일 (dd)')}
                            </span>
                            <IconButton
                              className="deadline-detach-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChangeDeadline(null);
                              }}
                              sx={{
                                'width': '12px',
                                'height': '12px',
                                'borderRadius': '4px',
                                'marginLeft': '4px',
                                'padding': '0px',
                                ':hover': {
                                  backgroundColor: hexToRGBA(
                                    dayjs(event!.deadline).isToday()
                                      ? COLORS.brand1
                                      : dayjs(event!.deadline).isBefore(dayjs())
                                      ? COLORS.negative1
                                      : COLORS.gray600,
                                    0.3,
                                  ),
                                },
                              }}
                              style={deadlineAnchorEl && selectedTaskbox === event.id && !multiSelected ? { display: 'flex' } : {}}
                            >
                              <Icons.Close
                                width={8}
                                height={8}
                                fill={
                                  dayjs(event!.deadline).isToday()
                                    ? COLORS.brand1
                                    : dayjs(event!.deadline).isBefore(dayjs())
                                    ? COLORS.negative1
                                    : COLORS.gray600
                                }
                              />
                            </IconButton>
                          </DeadlineShowingWrapper>
                        )}
                        <div
                          className="flex gap-1 items-center time-modal"
                          onClick={(e) => {
                            if (e.currentTarget === e.target && !e.ctrlKey && !e.metaKey) handleClickTaskbox(event!);
                          }}
                        >
                          <div className="time-modal-container">
                            {event.start?.datetime ? <Icons.Time stroke={COLORS.gray500} /> : <Icons.Today />}
                            <Fonts.Blockquote
                              className="text-gray500"
                              onClick={(e) => {
                                if (!e.metaKey && !e.ctrlKey) {
                                  handleOpenSimpleTimeSelectionModal(e);
                                }
                              }}
                              style={{ marginLeft: '4px' }}
                            >
                              {event.start?.datetime ? showingTime(event) : '오늘'}
                            </Fonts.Blockquote>
                            {!multiSelected ? (
                              <Tooltip title={'시간 해제하기'} disableInteractive>
                                <IconButton
                                  className="unselect-time"
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      event.isRecurrence ? handleOpenRecurrenceUpdateDialog() : handleUnSelectTime(event.id!);
                                    }
                                  }}
                                  sx={{ borderRadius: '8px', padding: '6px', marginLeft: '4px' }}
                                >
                                  <Icons.Close width={12} height={12} fill={'#C8CDDB'} />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <div style={{ width: '24px', height: '24px' }} />
                            )}
                          </div>
                        </div>
                      </TaskboxPropertyWrapper>
                      <div className="flex justify-between items-center relative timeline-header">
                        {/* 타임박스 제목 */}
                        <div
                          className="timelineEvents-title-container"
                          style={{
                            width: `calc(100% - ${document.querySelector(`[data-foldShowingId="${event.id}"]`)?.getBoundingClientRect().width}px)`,
                          }}
                        >
                          {tasks.filter((task) => task.done).length > 0 && !taskboxDone ? (
                            <Checkbox
                              checked={false}
                              icon={
                                event.focus ? (
                                  <Icons.FocusInprogressCheck />
                                ) : (
                                  <Icons.InprogressCheck fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                                )
                              }
                              sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              style={{ width: 20, height: 20, marginTop: 2, marginRight: 8 }}
                              onClick={(e) => {
                                if (!e.metaKey && !e.ctrlKey) {
                                  if (event.type === 'TERM_TASK') {
                                    handleClickTaskboxCheck();
                                  } else {
                                    setSelectedTaskbox(event.id);
                                    setInProgressPopover(e.currentTarget);
                                  }
                                }
                              }}
                            />
                          ) : (
                            <PriorityIssueCheckbox
                              width={20}
                              height={20}
                              focus={event.focus}
                              bordercolor={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                              checked={!!event.done}
                              // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                              // onClick={(e) => onChangeIssue && e.stopPropagation()}
                              onClick={(e) => {
                                if (!e.metaKey && !e.ctrlKey) handleClickTaskboxCheck();
                              }}
                              style={{ marginTop: 2, marginRight: 8 }}
                            />
                          )}
                          {isExploding && event.focus && (
                            <ConfettiExplosion
                              force={0.6}
                              duration={2500}
                              particleCount={80}
                              width={1000}
                              onComplete={() => setIsExploding(false)}
                              style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                          )}
                          <TaskboxInputWrapper isProject={!!event.project}>
                            {event.project && (
                              <ProjectIcon>
                                <Icons.Issue />
                              </ProjectIcon>
                            )}
                            {event.lockedIn && <LockInIcon event={event} />}
                            {event.project ? (
                              <Tooltip title="프로젝트 상세보기" disableInteractive placement="bottom-start">
                                <TaskboxInput
                                  className="timelineEvents-title"
                                  data-id={
                                    event.type === 'TERM_TASK'
                                      ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                      : `${event.id}`
                                  }
                                  onClick={(e) => {
                                    if (!e.metaKey && !e.ctrlKey) {
                                      handleClickTaskbox(event);
                                      handleClickInstance(event);
                                    }
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {event.title}
                                </TaskboxInput>
                              </Tooltip>
                            ) : (
                              <TaskboxInput
                                ref={refTaskboxInput}
                                className="timelineEvents-title"
                                data-id={
                                  event.type === 'TERM_TASK'
                                    ? `${event.id}-${event.matchDate?.findIndex((v) => v === dayjs(date).format(DATE_FORMAT_4))}`
                                    : `${event.id}`
                                }
                                onBlur={(e) => handleBlurTitle(e, event)}
                                onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                placeholder="Title"
                                contentEditable={multiSelected ? false : true}
                                suppressContentEditableWarning={true}
                                spellCheck={false}
                                onClick={(e) => {
                                  if (!e.metaKey && !e.ctrlKey) {
                                    handleClickTaskbox(event);
                                    setGetCaret(window.getSelection()!.anchorOffset);
                                  }
                                }}
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {event.title}
                              </TaskboxInput>
                            )}
                          </TaskboxInputWrapper>
                          {event.issueId && (
                            <Button
                              variant="outlined"
                              color="inherit"
                              onClick={() => handleClickIssueDetail(event.issueId!)}
                              sx={{
                                width: '100px',
                                height: '24px',
                                borderRadius: '8px',
                                padding: '0px',
                                color: `${COLORS.gray400}`,
                                border: `1px solid ${COLORS.gray400}`,
                              }}
                            >
                              <div className="timelineEvents-issue-detail">
                                <IssueLinkage />
                                <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                              </div>
                            </Button>
                          )}
                        </div>
                        <div data-foldShowingId={`${event.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                          {event.memo && <Icons.TaskboxMemo />}
                          {event.links!.length > 0 && (
                            <div style={{ marginLeft: '4px' }}>
                              <Icons.Link2 width={16} height={16} stroke={COLORS.gray500} />
                            </div>
                          )}
                          {event.isRecurrence && (
                            <div style={{ marginLeft: '4px' }}>
                              <Icons.Recurrence />
                            </div>
                          )}
                          {tasks.length > 0 && (
                            <div className="show-subtask">
                              <Icons.Subtask />
                              <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{tasks.length}</span>
                            </div>
                          )}
                        </div>
                        {!multiSelected && (
                          <TaskboxOptionsContainer className="taskbox-option" style={selectedTaskbox ? { visibility: 'visible' } : {}}>
                            <TaskboxOptionsContent>
                              <TaskboxOptionsIcon>
                                <Tooltip title={event.focus ? '하이라이트풀기' : '하이라이트하기'} disableInteractive>
                                  <IconButton
                                    sx={{ borderRadius: '8px', padding: '4px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) handleHighlightTaskbox();
                                    }}
                                  >
                                    {event.focus ? <Icons.UnfocusTime /> : <Icons.FocusTime />}
                                  </IconButton>
                                </Tooltip>
                              </TaskboxOptionsIcon>
                            </TaskboxOptionsContent>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{
                                margin: '4px 2px',
                              }}
                            />
                            <TaskboxOptionsContent>
                              <TaskboxOptionsIcon>
                                <Tooltip
                                  title={
                                    <div style={{ margin: '2px 4px' }}>
                                      <span>날짜 및 시간 설정</span>
                                      <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                        T
                                      </KeyboardButtonRect>
                                    </div>
                                  }
                                  disableInteractive
                                >
                                  <IconButton
                                    data-timeselectionid={event.id}
                                    sx={{ borderRadius: '8px', padding: '4px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) handleOpenTimeSelectionModal(e);
                                    }}
                                  >
                                    <Icons.SelectTime />
                                  </IconButton>
                                </Tooltip>
                              </TaskboxOptionsIcon>
                            </TaskboxOptionsContent>
                            <TaskboxOptionsContent>
                              <TaskboxOptionsIcon>
                                <Tooltip
                                  title={
                                    event.recurrence
                                      ? `반복 업무에서는 '구글 캘린더에 표시하기'를 추후 지원할 예정입니다`
                                      : event.lockedIn
                                      ? '구글 캘린더에서 해제하기'
                                      : '구글 캘린더에 표시하기'
                                  }
                                  disableInteractive
                                >
                                  <TaskboxOptionsIcon style={{ cursor: 'pointer' }}>
                                    <IconButton
                                      disabled={event.recurrence ? true : false}
                                      sx={{ borderRadius: '8px', padding: '4px' }}
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        if (!e.metaKey && !e.ctrlKey) handleLockInOutButton(event.lockedIn!);
                                      }}
                                    >
                                      {event.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                                    </IconButton>
                                  </TaskboxOptionsIcon>
                                </Tooltip>
                              </TaskboxOptionsIcon>
                            </TaskboxOptionsContent>
                            <TaskboxOptionsContent>
                              <TaskboxOptionsIcon>
                                <Tooltip
                                  title={
                                    <div style={{ margin: '2px 4px' }}>
                                      <span>포커스모드</span>
                                      {/* <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Shift
                                                  </KeyboardButtonRect>
                                                  +
                                                  <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                                                    Option
                                                  </KeyboardButtonRect>
                                                  + <KeyboardButtonRect small>F</KeyboardButtonRect> */}
                                      <KeyboardButtonRect small style={{ marginLeft: 8 }}>
                                        F
                                      </KeyboardButtonRect>
                                    </div>
                                  }
                                  disableInteractive
                                >
                                  <IconButton
                                    sx={{ borderRadius: '8px', padding: '4px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) handleClickFocusMode();
                                    }}
                                  >
                                    <Icons.FocusMode />
                                  </IconButton>
                                </Tooltip>
                              </TaskboxOptionsIcon>
                            </TaskboxOptionsContent>
                            <TaskboxOptionsContent>
                              <TaskboxOptionsIcon>
                                <Tooltip title="더보기" disableInteractive>
                                  <IconButton
                                    sx={{ borderRadius: '8px', padding: '4px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                      if (!e.metaKey && !e.ctrlKey) {
                                        setSelectedTaskbox(event.id!);
                                        setTaskboxDetail(e.currentTarget);
                                      }
                                    }}
                                  >
                                    <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                  </IconButton>
                                </Tooltip>
                              </TaskboxOptionsIcon>
                            </TaskboxOptionsContent>
                          </TaskboxOptionsContainer>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </TaskDragContainer>
            )}

            {/** 간편 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(simpleTimeSelection)}
                anchorEl={simpleTimeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseSimpleTimeSelectionModal}
                disableEscapeKeyDown
              >
                <SimpleTimeSelectionModal event={event} onClose={handleCloseSimpleTimeSelectionModal} onChangeTime={handleChangeTime} />
              </Popover>
            )}
            {/* 날짜 및 시간 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(timeSelection)}
                anchorEl={timeSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{ marginTop: '4px' }}
                onClose={handleCloseTimeSelectionModal}
              >
                <TimeDateSelectionModal
                  event={event}
                  onClose={handleCloseTimeSelectionModal}
                  onChangeTime={handleChangeTime}
                  onChangeDate={handleChangeTaskboxDate}
                />
              </Popover>
            )}
            {/* 태스크박스 상세 설정 팝오버 */}
            {event!.id === selectedTaskbox && (
              <TimelineTaskboxDetail
                event={event}
                detail={taskboxDetail}
                suppressDivider={!!event.project}
                onCloseDetail={handleCloseTimelineDetail}
                onClickMenu={handleClickTaskboxContextMenu}
              />
            )}
            {/**이어서 완료하기 팝오버 */}
            {event!.id === selectedTaskbox && (
              <Popover
                open={Boolean(inProgressPopover)}
                anchorEl={inProgressPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handleCloseInProgressPopover}
                sx={{ marginTop: '4px' }}
              >
                <InProgressTaskbox>
                  <InProgressTaskboxContent
                    onClick={() => {
                      handleClickTaskboxCheck();
                      handleCloseInProgressPopover();
                    }}
                  >
                    {event!.focus ? (
                      <Icons.FocusCheck width={16} height={16} />
                    ) : (
                      <Icons.TaskboxCheck width={16} height={16} fill={event.project ? COLORS.issue2 : event!.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                    )}
                    <span style={{ marginLeft: '8px' }}>모두 완료하기</span>
                  </InProgressTaskboxContent>
                  {event.project ? (
                    <Tooltip
                      title={
                        <span>
                          인스턴스를 완료하고 미완료 하위 업무를
                          <br />
                          다음 인스턴스나 프로젝트로 보냅니다.
                        </span>
                      }
                      placement="right"
                    >
                      <InProgressTaskboxContent onClick={handleClickInstanceContinue}>
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                        <span style={{ marginLeft: '8px' }}>일부 완료하기</span>
                        <span style={{ marginLeft: '4px' }}>
                          <Icons.QuestionInfo />
                        </span>
                      </InProgressTaskboxContent>
                    </Tooltip>
                  ) : (
                    <InProgressTaskboxContent
                      onClick={(e) => {
                        setContinueSettingPopover(e.currentTarget);
                      }}
                    >
                      {event.focus ? (
                        <Icons.ContinueFocusTaskbox />
                      ) : (
                        <Icons.ContinueTaskbox fill={event.project ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                      )}
                      <span style={{ marginLeft: '8px' }}>일부 완료하고 이어서하기</span>
                    </InProgressTaskboxContent>
                  )}
                </InProgressTaskbox>
              </Popover>
            )}
            {/** 태스크박스 이어서하기 설정창 */}
            <TaskboxContinueDetail
              anchorEl={continueSettingPopover}
              event={event}
              onCloseContinue={handleCloseContinueSettingPopover}
              onChangeDate={handleChangeInprogress}
            />
            {/* 태스크박스 카테고리 */}
            {
              <Popover
                open={Boolean(categoryAnchorEl)}
                anchorEl={categoryAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setSelectedTaskbox(undefined);
                  setCategoryAnchorEl(null);
                  if (isQuickCategory) {
                    setFocusElement(focusedTaskbox!.id!);
                    setIsQuickCategory(false);
                  }
                }}
                sx={{ marginTop: '4px' }}
              >
                <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategoryActions} />
              </Popover>
            }
            {/* 태스크박스 기한 */}
            {
              <Popover
                open={Boolean(deadlineAnchorEl)}
                anchorEl={deadlineAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={() => {
                  setSelectedTaskbox(undefined);
                  setDeadlineAnchorEl(null);
                }}
                sx={{ marginTop: '4px' }}
              >
                <DeadlinePopover date={date} onChangeDeadline={handleChangeDeadline} />
              </Popover>
            }
            {multiSelectIds[multiSelectIds.length - 1] === event!.id && (
              <Popper open={Boolean(multiSelectAnchorEl)} anchorEl={multiSelectAnchorEl} placement={'right-start'} ref={refPopperMenu} sx={{ zIndex: 1000 }}>
                <TaskboxMultiSelectPopover
                  date={dayjs(
                    events?.find((v) => v.id === multiSelectIds[0])?.start?.date || events?.find((v) => v.id === multiSelectIds[0])?.start?.datetime,
                  ).toDate()}
                  multiSelectCount={multiSelectIds.length}
                  openDeleteDialog={isOpenMultiSelectDeleteDialog}
                  onOpenDeleteDialog={handleOpenMultiSelectDeleteDialog}
                  onCloseDeleteDialog={handleCloseMultiSelectDeleteDialog}
                  onClickMultiSelectActions={onClickMultiSelectActions}
                />
              </Popper>
            )}

            {/** 포커스 모드 dialog */}
            {focusModeTaskbox && event!.id === focusModeTaskbox!.id && (
              <FocusModeDialog
                open={Boolean(focusModeTaskbox) && !hideFocusMode}
                taskbox={focusModeTaskbox}
                templateList={templateList}
                hide={hideFocusMode}
                categoryList={categoryList}
                onFetch={handleFetchFocusMode}
                onClose={handleCloseFocusMode}
                onHide={handleHideFocusMode}
                onDelete={handleDeleteFocusMode}
                onCreateTemplate={handleCreateTemplate}
                onPutTemplate={handlePutTemplate}
                onDeleteTemplate={handleDeleteTemplate}
                onChangeInprogress={handleChangeFocusModeInprogress}
                onChangeTimer={onChangeTimer}
                onClickCategoryActions={handleClickCategoryActions}
                onChangeDeadline={handleChangeDeadline}
                onClickInstanceContinue={handleClickInstanceContinue}
                onPushProjectRecommendTask={handlePushProjectRecommendTask}
                onDeleteProject={handleDeleteProject}
              />
            )}
            {/** 프로젝트 상세보기 */}
            <ProjectDetailView
              selectedProject={selectedProject || undefined}
              onClose={handleCloseProjectDetail}
              onDelete={handleDeleteProject}
              onFetch={fetchProjects}
            />
            {event!.id === focusedTaskbox?.id && (
              <ConfirmDeleteDialog open={deleteWithKeydown} onClose={() => setDeleteWithKeydown(false)} onDelete={handleDeleteWithKeydown} />
            )}

            {(event!.id === focusedTaskbox?.id || event!.id === selectedTaskbox) && (
              <UpdateRecurrenceDialog open={openRecurrenceUpdate} onClose={handleCloseRecurrenceUpdateDialog} onUpdateRecurrence={handleRemoveRecurrenceTime} />
            )}

            {(event!.id === focusedTaskbox?.id || event!.id === selectedTaskbox) && deleteRecurrenceWithKeydown && (
              <RemoveRecurrenceDialog
                open={deleteRecurrenceWithKeydown}
                onClose={() => setDeleteRecurrenceWithKeydown(false)}
                onRemoveRecurrence={handleDeleteRecurrenceWithKeydown}
              />
            )}

            {!!clickedIssue && plan && (
              <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                <IssueDetailView
                  issueId={event!.issueId!}
                  selectable={true}
                  priority={plan.length > 0 ? plan[0].priority : undefined}
                  // sectionTitle={sectionTitle ? sectionTitle : '섹션 없음'}
                  // timelineView={timelineView}
                  // tasks={selectedTasks}
                  onClickCancel={handleClickCancelIssue}
                  onClickSave={handleClickSaveIssue}
                />
              </Dialog>
            )}

            {/** 자주 하는 업무 다이어로그 */}
            {event!.id! === selectedTaskbox && (
              <TemplateDialog
                open={openTemplateDialog}
                templateList={templateList}
                categoryList={categoryList}
                onClose={handleCloseTemplateDialog}
                onPut={handlePutTemplate}
                onDelete={handleDeleteTemplate}
                onAdd={handleTemplateToTaskbox}
                onClickCategory={handleClickCategoryActions}
              />
            )}
            <TaskDropContainer height={taskboxHeight} onDrop={() => handleDragDropToTaskbox(event!.id!)} hidden={!draggingToTaskbox} />
          </div>
        )}
      </>
    );
  },
);

const TaskboxDragContainer = styled.div`
  position: relative;
  :hover {
    .taskbox-drag-handle {
      opacity: 0.4;
    }
  }
`;

const TaskDragContainer = styled.div<{
  event?: OutTaskboxDetailResponse;
  taskboxDone?: boolean;
  focusedTaskbox?: OutTaskboxDetailResponse;
  plan?: OutPlanWeekly[];
  selectedTaskbox?: string;
  multiSelected?: boolean;
}>`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${COLORS.white};
  pointer-events: all;
  margin-bottom: 8px;

  &:hover {
    ${(props) =>
      !(props.focusedTaskbox?.id === props.event?.id) &&
      css`
        border: 1px solid ${COLORS.gray300};
        box-shadow: 1px 1px 20px 1px ${COLORS.gray200};
      `}

    .taskbox-option {
      visibility: visible;
    }
  }

  ${(props) =>
    props.taskboxDone &&
    css`
      border: 1px solid ${COLORS.gray200};
      background-color: ${COLORS.white};
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    props.event?.done &&
    css`
      border: 1px solid ${COLORS.gray300};
      border-radius: 8px;
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}

  ${(props) =>
    props.focusedTaskbox?.id === props.event?.id &&
    props.event?.focus &&
    css`
      border: 1px solid transparent;
      background-image: linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
      border-radius: 8px;
      background-origin: border-box;
      background-clip: padding-box, border-box;
    `}

    ${(props) =>
    props.event?.isRecurrence &&
    !props.event?.focus &&
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.sub4};
    `}

    ${(props) =>
    props.event?.project &&
    !props.event?.focus &&
    props.focusedTaskbox?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.issue2};
    `}

  ${(props) =>
    props.multiSelected &&
    css`
      cursor: pointer;
      background-color: ${COLORS.sub3};
      border: 1px solid ${COLORS.gray300};
    `}

  .timeline-border {
    ${(props) =>
      props.taskboxDone &&
      css`
        opacity: 0.4;
      `}
  }

  .line {
    width: 4px;
    background-color: ${COLORS.brand1};
    border-radius: 4px;

    ${(props) =>
      props.event?.isRecurrence &&
      css`
        background-color: ${COLORS.sub4};
      `}

    ${(props) =>
      props.event?.project &&
      css`
        background-color: ${COLORS.issue2};
      `}

    ${(props) =>
      props.event?.focus &&
      css`
        background: var(--Gradation, linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%));
      `}
  }

  .show-subtask {
    margin-left: 4px;
    display: flex;
    align-items: center;
  }

  .timelineEvents-background {
    padding-right: 20px;
  }

  .time-modal-container {
    height: 24px;
    display: flex;
    align-items: center;

    cursor: pointer;
    &:hover {
      .unselect-time {
        display: block;
      }
    }
  }

  .unselect-time {
    display: none;
  }

  .timeline-header {
    padding: 4px 2px;
  }

  .timelineEvents-title-container {
    display: flex;
    width: 100%;
  }

  .timelineEvents-issue-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timelineEvents-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .timelineEvents-title {
    width: 100%;
    /* ${(props) =>
      props.taskboxDone === true &&
      css`
        color: ${COLORS.gray500};
      `} */

    ${(props) =>
      props.taskboxDone === true &&
      css`
        text-decoration: line-through;
      `}
  }

  .remove-timelineEvents {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }

  .add-task {
    margin-top: 2px;
    margin-left: 4px;
    color: ${COLORS.gray500};
    /* ${(props) =>
      props.taskboxDone === true &&
      css`
        color: ${COLORS.gray400};
      `} */
  }
`;

const TaskboxInputWrapper = styled.div<{ isProject?: boolean }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  word-break: break-all;

  :hover {
    ${(props) => props.isProject && `color: #68387F;`}
  }
`;

const LockInIcon = styled.div<{ event?: OutTaskboxDetailResponse }>`
  margin-right: 4px;
  margin-top: 4px;
  width: 16px;
  height: 16px;

  ${(props) =>
    !props.event?.done &&
    css`
      background-image: url('${require('assets/images/Star.png')}');
      background-position: center;
      background-size: cover;
    `}

  ${(props) =>
    props.event?.done &&
    css`
      background-image: url('${require('assets/images/LockInDone.png')}');
      background-position: center;
      background-size: cover;
      opacity: 50%;
    `}
`;

const ProjectIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 3px;
`;

const TaskboxInput = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-right: 8px;

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }
`;

const SubtaskView = styled.div`
  width: 100%;
  margin-top: 2px;

  .event-background {
    padding-right: 20px;
  }

  .detail {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }

  .event-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .remove-event {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }
`;

const SubTaskField = styled.div<{ taskboxDone?: boolean }>`
  max-width: 480px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray900};

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }

  ${(props) =>
    props.taskboxDone &&
    css`
      color: ${COLORS.gray500};
      text-decoration: line-through;
    `}
`;

const EditableTextFieldWrapper = styled.div<{ selectedTaskId?: boolean; hover?: boolean }>`
  width: 100%;
  position: relative;
  word-break: break-all;
  border-radius: 8px;
  padding: 3px;
  border: 1px solid transparent;

  ${(props) =>
    props.selectedTaskId &&
    css`
      border: 1px solid ${COLORS.gray200};
    `}

  .task-container {
    width: 100%;
    flex: 1;
  }

  .subtask {
    width: 100%;
  }

  :hover {
    ${(props) =>
      props.hover &&
      css`
        background-color: ${COLORS.gray100};
      `}
    .drag-handle {
      opacity: 0.4;
    }
    .subtask-addson {
      background-color: ${COLORS.white};
    }
    .detail {
      opacity: 1;
    }
  }
`;

const SubtaskAddsOnWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 2px;
  position: absolute;
  right: 2px;
`;

const TaskboxDragHandleIcon = styled.span<{ taskboxHeight?: number }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 + 1}px;
  left: -21px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const TaskboxDragToCalendarIcon = styled.span<{ taskboxHeight?: number; isDragging?: boolean }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 - 22}px;
  left: -21px;
  cursor: grab;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=arrow_back');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const DragHandleIcon = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  left: -16px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=drag_indicator');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const TaskboxOptionsContainer = styled.div`
  position: absolute;
  border: 1px solid ${COLORS.gray200};
  display: flex;
  border-radius: 8px;
  padding: 1px;
  visibility: hidden;
  z-index: 2;
  background-color: ${COLORS.white};
  right: 0px;
  top: 1px;
`;

const TaskboxOptionsContent = styled.div`
  position: relative;
`;

const TaskboxOptionsIcon = styled.div`
  :hover ~ .taskbox-tooltip {
    visibility: visible;
  }
`;

// const TaskboxOptionsTooltip = styled.div`
//   position: absolute;
//   font-size: 12px;
//   color: ${COLORS.white};
//   background-color: ${COLORS.gray900};
//   padding: 7px 12px;
//   border-radius: 4px;
//   right: -13px;
//   top: 30px;
//   text-align: center;
//   visibility: hidden;
// `;

const TaskboxAddOn = styled.div<{ multiSelected?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    .template-btn {
      display: flex;
      align-items: center;
    }

    ${(props) =>
      props.multiSelected &&
      css`
        .template-btn {
          display: none;
        }
      `}
  }
`;

const SubtaskWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TemplateWrapper = styled.div`
  display: none;
`;

const TemplateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
  }
`;

const UpdateRecurrenceWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
`;

const TaskDropContainer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${COLORS.sub5};
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  margin-bottom: 8px;
`;

const InProgressTaskbox = styled.div`
  padding: 8px;
  background-color: ${COLORS.white};
`;

const InProgressTaskboxContent = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray100};
  }
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px 3px' : '4px'}`};
`;

const TaskIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 4px;
  padding: 2px;
  border-radius: 4px;

  &:hover {
    background-color: ${COLORS.gray200};

    .later {
      filter: brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);
    }
    .delete {
      filter: invert(16%) sepia(55%) saturate(6083%) hue-rotate(336deg) brightness(93%) contrast(86%);
    }
  }
`;

const ShowingMemoWrapper = styled.div``;

const ShowingMemoTextWrapper = styled.div`
  border-radius: 8px;
  color: ${COLORS.gray700};
  cursor: pointer;
  font-size: 12px;
  padding: 2px 8px;

  :hover {
    background-color: ${COLORS.gray100};
  }
`;

const TaskboxPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .category-detach-button {
    display: none;
  }

  &:hover {
    .category-detach-button {
      display: flex;
    }
  }
`;

const DeadlineShowingWrapper = styled.div<{ date?: string }>`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative2 : dayjs(props.date).isToday() ? COLORS.sub3 : COLORS.gray200)};
  border-radius: 4px;
  color: ${(props) => (dayjs(props.date).isBefore(dayjs(), 'date') ? COLORS.negative1 : dayjs(props.date).isToday() ? COLORS.brand1 : COLORS.gray600)};
  cursor: pointer;
  font-size: 10px;
  margin-right: 4px;
  padding: 2px 6px;

  .deadline-detach-button {
    display: none;
  }

  &:hover {
    .deadline-detach-button {
      display: flex;
    }
  }
`;

const DateRangeShowingWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 12px;
`;

const RecommendTaskWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray500};
  font-size: 10px;
  font-weight: 700;
  padding: 0px 4px;
`;

const RecommendButtonRect = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  background-color: ${COLORS.gray100};
  border-radius: 2px;
  font-size: 8px;
  padding: 0px 4px;
`;
