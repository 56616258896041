import { SVGProps } from 'react';

export const Calendar = ({
  width = 32,
  height = 32,
  fill = '#1F2023',
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1748_505)">
        <path
          d="M15.7267 28.0001H6.66667C5.95942 28.0001 5.28115 27.7191 4.78105 27.219C4.28095 26.7189 4 26.0407 4 25.3334V9.33341C4 8.62617 4.28095 7.94789 4.78105 7.4478C5.28115 6.9477 5.95942 6.66675 6.66667 6.66675H22.6667C23.3739 6.66675 24.0522 6.9477 24.5523 7.4478C25.0524 7.94789 25.3333 8.62617 25.3333 9.33341V14.6667"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0001 29.3334C26.9456 29.3334 29.3334 26.9456 29.3334 24.0001C29.3334 21.0546 26.9456 18.6667 24.0001 18.6667C21.0546 18.6667 18.6667 21.0546 18.6667 24.0001C18.6667 26.9456 21.0546 29.3334 24.0001 29.3334Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 4V9.33333"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33325 4V9.33333"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 14.6667H25.3333"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 21.9946V24L25.3333 25.3333"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1748_505">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
