import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { useEffect, useRef, useState } from 'react';
import { EventProps, Event as RBCEvent } from 'react-big-calendar';
import dayjs from 'lib/dayjs';
import 'moment/locale/ko';
import { setFocusElement, truncate } from 'styles/utils';
import { has } from 'lodash';
import { Checkbox, Popover, TextField } from '@mui/material';
import { Icons } from 'components';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import { OutCategory, OutTaskboxDetailResponseCategory } from 'queries/model';
import { getCategoryBgColor, getCategoryTextColor } from 'utils/category';
import { GAEventTrigger } from 'lib/gtag';
import CategoryPopover, { CategoryActionType } from '../components/CategoryPopover';
import { useAtom } from 'jotai';
import { categoryAtom } from 'atoms/category';
import { setCaretToEnd } from 'utils';

const Container = styled.div<{ done?: boolean; calendarColor?: string }>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.calendarColor ? `${props.calendarColor}` : props.done ? '#F9FAFE' : 'white')};
`;

const EventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 6px 8px;
`;

const AllDayEventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  height: 28px;
`;

const TextTruncate = styled.div`
  ${truncate('auto')};
`;

const AllDayEventTitle = styled.div<{ done?: boolean; color?: string }>`
  ${truncate('100%')};
  flex: 1;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;

const EventTitle = styled.div<{ done?: boolean; color?: string }>`
  ${truncate('100%')};
  flex: 1;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
`;

const VerticalBar = styled.div<{ done?: boolean; isRecurrence?: boolean; isProject?: boolean }>`
  border-right: ${(props) => `2px solid ${props.isProject ? COLORS.issue2 : props.isRecurrence ? COLORS.sub4 : COLORS.brand1}`};
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const VerticalBarFocus = styled.div<{ done?: boolean }>`
  border: 1px solid transparent;
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
  -webkit-border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image: linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%);
  border-image-slice: 1;
  opacity: ${(props) => (props.done ? 0.5 : 1)};
`;

const EditableTextField = styled.div`
  width: 100%;
  /* height: 100%; */
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 13px;
  font-weight: bold;
  color: ${COLORS.gray900};
`;

const CategoryShowingWrapper = styled.div<{ textColor?: string; bgColor?: string }>`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${(props) => props.textColor};
  font-size: 10px;
  padding: 2px;
`;

const EventTitleSpan = styled.span`
  ${truncate('100%')};
`;

export interface TaskboxInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onChangeEditing?: () => void;
  onClickCategory?: (category: OutCategory, action: CategoryActionType) => void;
}

const TaskboxInput = ({ value, onChange, onChangeEditing, onClickCategory }: TaskboxInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryList] = useAtom(categoryAtom);

  useEffect(() => {
    // refInput?.current?.focus();
    setCaretToEnd(refInput.current as HTMLElement);
  }, []);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const content = refInput.current?.textContent || '';

    if (e.key === 'Escape') {
      return;
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      if (categoryAnchorEl) {
        e.preventDefault();
        return;
      }
      onChange?.(content);
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current);
      }, 100);
    }
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLInputElement>) => {
    if (categoryAnchorEl) return;
    const content = e.currentTarget.textContent || '';
    setTimeout(() => onChange?.(content), 100);
  };

  const handleClickCategory = (category: OutCategory, action: CategoryActionType) => {
    onClickCategory?.(category, action);
    if (refInput.current?.textContent?.includes('#')) {
      refInput.current.textContent = refInput.current.textContent.replace(/#/g, '');
    }

    setTimeout(() => {
      setCategoryAnchorEl(null);
      setCaretToEnd(refInput.current as HTMLElement);
    }, 100);
  };

  return (
    <>
      <EditableTextField
        className="today-calendar-title"
        ref={refInput}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={onChangeEditing}
        onKeyDown={handleKeydownInput}
        onBlur={handleBlurTextField}
        dangerouslySetInnerHTML={{ __html: value || '' }}
      />
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
            setCaretToEnd(refInput.current as HTMLElement);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
    </>
  );
};

const TaskboxAllDayInput = ({ value, onChange, onChangeEditing, onClickCategory }: TaskboxInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryList] = useAtom(categoryAtom);

  useEffect(() => {
    refInput?.current?.focus();
  }, []);

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      if (categoryAnchorEl) {
        e.preventDefault();
        return;
      }
      onChange?.(`${inputValue}`.trim());
    }

    if (e.key === '#') {
      setTimeout(() => {
        setCategoryAnchorEl(refInput.current as HTMLElement);
      }, 100);
    }
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLInputElement>) => {
    if (categoryAnchorEl) return;
    const content = e.currentTarget.value || '';
    setTimeout(() => onChange?.(content), 100);
  };

  const handleClickCategory = (category: OutCategory, action: CategoryActionType) => {
    onClickCategory?.(category, action);

    if (inputValue && inputValue.includes('#')) setInputValue(inputValue.replace(/#/g, ''));
    setTimeout(() => {
      setCategoryAnchorEl(null);
      setCaretToEnd(refInput.current as HTMLElement);
    }, 100);
  };

  return (
    <>
      <TextField
        ref={refInput}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChangeEditing?.();
        }}
        autoFocus={true}
        autoComplete="off"
        fullWidth
        variant="standard"
        onBlur={handleBlurTextField}
        onKeyDown={handleKeydownInput}
        InputProps={{ disableUnderline: true, style: { fontSize: 13, fontWeight: 'bold', color: COLORS.gray900 } }}
      />
      {/* 태스크박스 카테고리 */}
      {categoryAnchorEl && (
        <Popover
          open={Boolean(categoryAnchorEl)}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            setCategoryAnchorEl(null);
          }}
          sx={{ marginTop: '4px' }}
        >
          <CategoryPopover categoryList={categoryList} onClickCategoryAction={handleClickCategory} />
        </Popover>
      )}
    </>
  );
};

export interface TodayCalendarEventProps extends EventProps<RBCEvent> {
  event: RBCEvent & {
    id?: string;
    type?: string;
    done?: boolean;
    lockedIn?: boolean;
    isRecurrence?: boolean;
    isProject?: boolean;
    focus?: boolean;
    category?: OutTaskboxDetailResponseCategory;
    calendarColor?: string;
    data?: {
      id: string;
      content: string;
      done: boolean;
      simple: boolean;
    }[];
  };
  onInput?: (event: RBCEvent, title: string) => void;
  onChangeCategory?: (event: RBCEvent, category: OutTaskboxDetailResponseCategory, action: CategoryActionType) => void;
  onChangeEditing?(): void;
  onContextMenu?: (event: RBCEvent) => void;
}
const TodayCalendarEvent = (props: TodayCalendarEventProps) => {
  const { event, onInput, onChangeCategory, onChangeEditing, onContextMenu } = props;
  const [editing, setEditing] = useState(false);
  const range = `${dayjs(event.start).format('a h:mm')} ~ ${dayjs(event.end).format('a h:mm')}`;
  const isGreaterThanMinimum = Math.abs(dayjs(event?.start).diff(event?.end, 'minutes')) > 15;
  const textColor = event.done ? COLORS.gray400 : COLORS.gray900;
  const isNewEvent = event.type === 'task' && !has(event, 'data');

  useEffect(() => {
    if (event.type === 'task' && !has(event, 'data')) {
      setTimeout(() => setEditing(true), 100);
    }
  }, [event]);

  const handleCreate = (value: string) => {
    setEditing(false);
    onInput?.(event, value);
  };

  const handleClickCategory = (category: OutCategory, action: CategoryActionType) => {
    onChangeCategory?.(event, category, action);
  };

  const handleClickEdit = () => {
    if (event.type === 'task' && !event.done) {
      setEditing(true);
    }
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    onContextMenu?.(event);
  };

  return (
    <Container
      data-calendar-id={event.id}
      calendarColor={event.calendarColor}
      done={event.type === 'task' && event?.done}
      onContextMenu={(e) => {
        handleContextMenu(e);
        GAEventTrigger({ action: 'timebox_menu_right_click', category: 'timebox_menu_right_click' });
      }}
      style={{ borderRadius: event.allDay ? '8px' : '0' }}
    >
      {event.allDay ? (
        <AllDayEventWrapper>
          {event?.type === 'task' &&
            (event?.focus ? (
              <VerticalBarFocus done={event.done} style={{ marginRight: 8, height: '100%' }} />
            ) : (
              <VerticalBar done={event.done} isRecurrence={event.isRecurrence} isProject={event.isProject} style={{ marginRight: 8, height: '100%' }} />
            ))}
          {editing && !event.isProject ? (
            <>
              {event?.type === 'task' &&
                (event.data && event.data.filter((task: { done: any }) => task.done).length > 0 && !event.done ? (
                  <Checkbox
                    checked={false}
                    icon={
                      event.focus ? (
                        <Icons.FocusInprogressCheck />
                      ) : (
                        <Icons.InprogressCheck fill={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                      )
                    }
                    sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    style={{ width: 12, height: 12, marginRight: 4, opacity: event.done ? 0.5 : 1 }}
                  />
                ) : (
                  <PriorityIssueCheckbox
                    width={12}
                    height={12}
                    focus={event.focus}
                    bordercolor={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                    checked={!!event.done}
                    style={{ marginRight: 4, opacity: event.done ? 0.5 : 1 }}
                  />
                ))}
              {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
              <TaskboxAllDayInput
                value={event.title as string}
                onChange={handleCreate}
                onChangeEditing={onChangeEditing}
                onClickCategory={handleClickCategory}
              />
            </>
          ) : (
            <AllDayEventTitle done={event.done} color={textColor} onClick={handleClickEdit}>
              {event?.type === 'task' &&
                (event.data && event.data.filter((task: { done: any }) => task.done).length > 0 && !event.done ? (
                  <Checkbox
                    checked={false}
                    icon={
                      event.focus ? (
                        <Icons.FocusInprogressCheck />
                      ) : (
                        <Icons.InprogressCheck fill={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                      )
                    }
                    sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    style={{ width: 12, height: 12, marginRight: 4, opacity: event.done ? 0.5 : 1 }}
                  />
                ) : (
                  <PriorityIssueCheckbox
                    width={12}
                    height={12}
                    focus={event.focus}
                    bordercolor={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                    checked={!!event.done}
                    style={{ marginRight: 4, opacity: event.done ? 0.5 : 1 }}
                  />
                ))}
              {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
              <EventTitleSpan style={{ textDecoration: event.done ? 'line-through' : '' }}>{isNewEvent ? '' : event.title || '제목 없음'}</EventTitleSpan>
            </AllDayEventTitle>
          )}
          {event.category && (
            <CategoryShowingWrapper
              textColor={getCategoryTextColor(event.category.color)}
              bgColor={getCategoryBgColor(event.category.color)}
              style={{
                border: event.type === 'meeting' ? `1px solid ${getCategoryTextColor(event.category.color)}` : 'none',
              }}
            >
              #
            </CategoryShowingWrapper>
          )}
        </AllDayEventWrapper>
      ) : (
        <EventWrapper style={isGreaterThanMinimum ? {} : { padding: '4px 5px' }}>
          {event?.type === 'task' &&
            (event?.focus ? (
              <VerticalBarFocus done={event.done} style={{ marginRight: 8 }} />
            ) : (
              <VerticalBar done={event.done} isRecurrence={event.isRecurrence} isProject={event.isProject} style={{ marginRight: 8 }} />
            ))}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              {editing && !event.isProject ? (
                <div style={{ display: 'flex', alignItems: 'flex-start', flex: 1 }}>
                  {event?.type === 'task' &&
                    (event.data && event.data.filter((task: { done: any }) => task.done).length > 0 && !event.done ? (
                      <Checkbox
                        checked={false}
                        icon={
                          event.focus ? (
                            <Icons.FocusInprogressCheck />
                          ) : (
                            <Icons.InprogressCheck fill={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                          )
                        }
                        style={{ width: 12, height: 12, marginRight: 4, marginTop: 1, padding: 0, opacity: event.done ? 0.5 : 1 }}
                      />
                    ) : (
                      <PriorityIssueCheckbox
                        width={12}
                        height={12}
                        focus={event.focus}
                        bordercolor={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                        checked={!!event.done}
                        style={{ marginRight: 4, marginTop: 1, opacity: event.done ? 0.5 : 1 }}
                      />
                    ))}
                  {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
                  <TaskboxInput value={event.title as string} onChange={handleCreate} onChangeEditing={onChangeEditing} onClickCategory={handleClickCategory} />
                </div>
              ) : (
                <EventTitle
                  done={event.done}
                  color={textColor}
                  onClick={handleClickEdit}
                  style={{ fontSize: isGreaterThanMinimum ? 13 : 10, minHeight: isGreaterThanMinimum ? 13 : 10 }}
                >
                  {event?.type === 'task' &&
                    (event.data && event.data.filter((task: { done: any }) => task.done).length > 0 && !event.done ? (
                      <Checkbox
                        checked={false}
                        icon={
                          event.focus ? (
                            <Icons.FocusInprogressCheck />
                          ) : (
                            <Icons.InprogressCheck fill={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1} />
                          )
                        }
                        sx={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        style={{
                          width: isGreaterThanMinimum ? 12 : 10,
                          height: isGreaterThanMinimum ? 12 : 10,
                          marginRight: 4,
                          opacity: event.done ? 0.5 : 1,
                        }}
                      />
                    ) : (
                      <PriorityIssueCheckbox
                        width={isGreaterThanMinimum ? 12 : 10}
                        height={isGreaterThanMinimum ? 12 : 10}
                        focus={event.focus}
                        bordercolor={event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1}
                        checked={!!event.done}
                        style={{ marginRight: 4, opacity: event.done ? 0.5 : 1 }}
                      />
                    ))}
                  {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
                  <EventTitleSpan style={{ textDecoration: event.done ? 'line-through' : '' }}>{isNewEvent ? '' : event.title || '제목 없음'}</EventTitleSpan>
                </EventTitle>
              )}
              {event.category && (
                <CategoryShowingWrapper
                  textColor={getCategoryTextColor(event.category.color)}
                  bgColor={getCategoryBgColor(event.category.color)}
                  style={{
                    border: event.type === 'meeting' ? `1px solid ${getCategoryTextColor(event.category.color)}` : 'none',
                  }}
                >
                  #
                </CategoryShowingWrapper>
              )}
            </div>
            <TextTruncate style={{ marginTop: 6 }}>{range}</TextTruncate>
          </div>
        </EventWrapper>
      )}
    </Container>
  );
};

export default TodayCalendarEvent;
