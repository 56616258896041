import dayjs from 'lib/dayjs';

export const DATE_FORMAT_1 = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_FORMAT_2 = 'YYYY년 MM월 DD일';
export const DATE_FORMAT_3 = 'YYYY.MM.DD (ddd)';
export const DATE_FORMAT_4 = 'YYYY-MM-DD';
export const DATE_FORMAT_5 = 'YYYY년 MM월 DD일 ddd요일';
export const DATE_FORMAT_6 = 'YYYY-MM-DDT15:00:00'; // UTC 기준 00시
export const DATE_FORMAT_7 = 'YYYY-MM-DDTHH:mm:ss';
export const TIME_FORMAT_1 = 'a hh시';
export const TIME_FORMAT_2 = 'a h:mm';

/** 날짜 형식 변경 */
export const convertDatetimeFormat = (datetime: string, format: string) => {
  return dayjs(datetime, { utc: true }).format(format);
};

export const getToday = () => {
  return `${dayjs().format('YYYY-MM-DD')}T15:00:00`;
};
