import { Icons } from 'components';
import { PropsWithChildren, ReactNode } from 'react';

export const SectionTitle = ({ icon, title, children }: { icon?: ReactNode; title?: string } & PropsWithChildren) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon && <span style={{ marginRight: 4 }}>{icon}</span>}
        <div className="heading-2">{title || children}</div>
      </div>
    </div>
  );
};

export const SectionTitleButtons = ({ onClickAdd, onClickMore }: { onClickAdd?: () => void; onClickMore?: () => void }) => {
  return (
    <div className="flex gap-3">
      <span className="cursor-pointer" onClick={onClickMore}>
        <Icons.ColorMore />
      </span>
      <span className="cursor-pointer" onClick={onClickAdd}>
        <Icons.Add />
      </span>
    </div>
  );
};
