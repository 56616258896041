import TextField from '@mui/material/TextField';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Alarm, Close } from 'components/Icons';
import { useEffect, useState } from 'react';
import { listReceiversV1SlackReceiversGet } from 'queries';
import { AppKernelApplicationSchemasOutUser, CreateInvitationShare, User } from 'queries/model';

type SlackReceiverUser = {
  type: 'Channel' | 'Member';
} & Omit<User, 'email' | 'active'>;

type ReminderMinute = 'oneDay' | 'oneHour' | 'fiveMinute';

interface InvitationModalProps extends DialogProps {
  meetingNoteId?: string;
  participants?: AppKernelApplicationSchemasOutUser[];
  sendSlack?: boolean;
  sendEmail?: boolean;
  onClose?: () => void;
  onClickSend: (value: CreateInvitationShare) => void;
}

const InvitationModal = ({ participants = [], sendSlack = true, sendEmail = true, open, onClose, onClickSend, ...props }: InvitationModalProps) => {
  const [receivers, setReceivers] = useState<SlackReceiverUser[]>([]);
  const [selectedReceiver, setSelectedReceiver] = useState<string>('');
  const [reminder, setReminder] = useState(true);
  const [reminderMinutes, setReminderMinutes] = useState<Record<ReminderMinute, boolean>>({ oneDay: true, oneHour: true, fiveMinute: true });
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (open) initialize();
  }, [open]);

  const initialize = async () => {
    const [receivers] = await Promise.all([sendSlack ? listReceiversV1SlackReceiversGet() : Promise.resolve({ data: [] })]);
    setReceivers((receivers?.data || []) as SlackReceiverUser[]);
    setSelectedReceiver((receivers?.data[0] as SlackReceiverUser)?.id || '');
  };

  const handleChangeReminder = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setReminder(checked);
    setReminderMinutes({ oneDay: checked, oneHour: checked, fiveMinute: checked });
  };

  const handleChangeReminderMinutes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setReminderMinutes({
      ...reminderMinutes,
      [event.target.name]: checked,
    });

    if (!checked && Object.keys(reminderMinutes).filter((key) => reminderMinutes[key as ReminderMinute]).length === 1) {
      setReminder(false);
    }
  };

  const handleClickSend = () => {
    const minutes = Object.keys(reminderMinutes)
      .filter((key) => reminderMinutes[key as ReminderMinute])
      .map((item) => ({ oneDay: 720, oneHour: 60, fiveMinute: 5 }[item as ReminderMinute]));

    onClickSend({ slackChannelId: selectedReceiver, message, sendEmail, reminderMinutes: minutes });
  };

  return (
    <>
      <Dialog open={open} {...props}>
        <DialogTitle sx={{ mt: 1 }} variant="h5" fontWeight={'bold'}>
          인비테이션 보내기
          <IconButton aria-label="close" onClick={onClose} size="small" sx={{ position: 'absolute', right: 20, top: 24 }}>
            <Close width={24} height={24} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          {sendSlack && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                슬랙 채널 또는 사용자를 선택해주세요
              </Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={selectedReceiver}
                  onChange={(e) => setSelectedReceiver(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 372,
                      },
                    },
                  }}
                >
                  {receivers.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      <Box sx={{ display: 'flex' }}>
                        <Avatar alt={v.name} src={v.profileImage} sx={{ width: 24, height: 24, marginRight: 1 }} />
                        <span>{v.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {sendEmail && (
            <Box>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                회의 참가자들 이메일로 인비테이션을 보냅니다
              </Typography>
              <Stack spacing={1} sx={{ mt: 1 }}>
                <Stack direction="row" flexWrap="wrap" sx={{ gap: 1 }}>
                  {participants.map((v) => (
                    <Chip key={v.id} label={`${v.name} : ${v.email}`} />
                  ))}
                </Stack>
              </Stack>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <Stack direction={'row'} alignItems={'center'}>
              <span style={{ paddingBottom: 2 }}>
                <Alarm />
              </span>
              <Typography variant="subtitle2" fontWeight={'bold'} sx={{ ml: 1 }}>
                회의 전 리마인더 알림 보내기
              </Typography>
              <Switch checked={reminder} onChange={handleChangeReminder} />
            </Stack>
            <Box sx={{ background: '#F2F5FC', borderRadius: 2, padding: '4px 16px' }}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={reminderMinutes.oneDay} onChange={handleChangeReminderMinutes} name="oneDay" />}
                  label="회의 하루 전(오전 9시)"
                />
                <FormControlLabel
                  control={<Checkbox checked={reminderMinutes.oneHour} onChange={handleChangeReminderMinutes} name="oneHour" />}
                  label="회의 1시간 전"
                />
                <FormControlLabel
                  control={<Checkbox checked={reminderMinutes.fiveMinute} onChange={handleChangeReminderMinutes} name="fiveMinute" />}
                  label="회의 5분 전"
                />
              </FormGroup>
            </Box>
          </Box>
          {/* <Box sx={{ mt: 3 }}>
            <Stack direction={'row'} alignItems={'center'}>
              <span style={{ paddingBottom: 2 }}>
                <InviOutlined />
              </span>
              <Typography variant="subtitle2" fontWeight={'bold'} sx={{ ml: 1 }}>
                읽지 않은 참가자에게 자동 재발송하기
              </Typography>
              <Switch defaultChecked />
            </Stack>
            <Box sx={{ background: '#F2F5FC', borderRadius: 2, padding: '4px 16px' }}>
              <FormGroup row>
                <FormControlLabel control={<Checkbox defaultChecked />} label="1시간 이후" />
                <FormControlLabel control={<Checkbox />} label="1일 이후" />
                <FormControlLabel control={<Checkbox />} label="3일 이후" />
              </FormGroup>
            </Box>
          </Box> */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" fontWeight={'bold'}>
              첨부할 메세지가 있으신가요?
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder="메시지를 입력해주세요."
              sx={{ mt: 1 }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 2 }}>
          <Button variant="contained" disableElevation onClick={handleClickSend}>
            보내기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvitationModal;
