import { useEffect, useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { Button, IconButton, LinearProgress, MenuItem, MenuList, Popover, Select, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event, DateHeaderProps } from 'react-big-calendar';
import { useEventListener, useMountEffect, useRafCallback, useUpdateEffect } from '@react-hookz/web';
import { hideScroll } from 'styles/utils';
import MonthCalendarEvent, { MonthCalendarEventProps } from './MonthCalendarEvent';
import { useNavigate } from 'react-router-dom';
import { CalendarEventsFilter } from '.';
import { ritualAlarmAtom, ritualEntryAtom } from 'atoms/notification';
import { useAtom } from 'jotai';
import { useTimer } from 'react-timer-hook';
import { GAEventTrigger } from 'lib/gtag';
import { OutCategory, OutFeatureStorage, OutTaskboxDetailResponseCategory } from 'queries/model';
import { OnDragStartArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { dragContextAtom } from 'atoms/calendar';
import { CategoryActionType } from '../components/CategoryPopover';
import { ritualAlarmTimeAtom, totalTimeAtom } from 'atoms/timer';

const CalendarViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px 28px;
  background-color: #f2f5fc;
  position: relative;
`;

const CalendarViewControlWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .MuiSelect-icon {
    right: 1px;
  }

  .MuiSelect-select {
    font-size: 12px;
    padding: 0px 0px 0px 9px !important;
  }

  .MuiSelect-select span:nth-of-type(2) {
    display: none !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: ${COLORS.white};
    color: ${COLORS.gray900};
    font-weight: 700;
  }
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${hideScroll};
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};

  .rbc-time-header {
    border-bottom: 2px solid ${COLORS.gray300};
    margin-right: 0px !important;
  }

  .rbc-time-header > .rbc-time-header-gutter {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .rbc-time-header-cell {
    /* margin-bottom: 8px; */
    display: none;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-header {
    color: ${COLORS.gray900};
    font-size: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rbc-today {
      background-color: transparent;
      color: ${COLORS.brand1};
    }

    & > button {
      cursor: default;
    }

    /* :nth-of-type(1),
    :nth-last-of-type(1) {
      color: ${COLORS.gray500};
    } */
  }

  .rbc-today {
    /* background-color: rgba(226, 236, 255, 0.45); */
    background-color: ${COLORS.gray100};
  }

  .rbc-allday-cell {
    height: 100px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    border-top: 1px solid ${COLORS.gray300};
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
    &:nth-of-type(3n) {
      border-top: 1px dashed ${COLORS.gray200};
    }
  }

  .rbc-day-bg.rbc-selected-cell {
    /* background-color: #f2f5fc !important; */
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    & > * + * > * {
      border: none;
    }

    & > :nth-of-type(2) {
      border-right: 1px solid ${COLORS.gray200};
    }

    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-event {
    border: 1px solid transparent;
    background: ${COLORS.gray100};
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    border: 1px solid ${COLORS.gray200};
    background: white !important;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-month-header {
    display: none;
  }

  .rbc-month-row {
    overflow: visible;
    border-top: 1px solid ${COLORS.gray200};
    height: fit-content;
  }

  .rbc-row-content-scroll-container {
    min-height: 104px;
  }

  .rbc-row-segment {
    padding: 0px 10px 1px 1px;
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-date-cell {
    text-align: center;
    padding-right: 0px;
  }

  .rbc-button-link {
    width: 24px;
    height: 24px;
    font-size: 13px;
    color: ${COLORS.gray900};
    font-weight: 600;
    margin-top: 4px;
    border-radius: 50%;
    :hover {
      background-color: ${COLORS.gray200};
    }
  }

  .rbc-date-cell.rbc-now {
    .rbc-button-link {
      background-color: ${COLORS.brand1};
      color: white;
    }
  }

  .rbc-month-view div:nth-of-type(2) {
    border-top: none;
  }
`;

const CalendarHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${COLORS.gray200};
`;

const CalendarHeaderDayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 14.2857%;
  max-width: 14.2857%;
  font-weight: bold;
  font-size: 20px;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: fit-content;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

const KeyboardCommandPlus = styled.span`
  font-size: 12px;
  color: ${COLORS.white};
  margin: 0px 4px;
`;

const RitualNotification = styled.div`
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 80px;
  right: -54px;
  z-index: 100;
  background-color: ${COLORS.white};
  border-radius: 8px;
  box-shadow: 0px 10.566px 21.132px 0px rgba(26, 30, 39, 0.16);
  padding: 12px;

  ::before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 10px 12px 0px 12px;
    border-color: ${COLORS.white} transparent;
    display: block;
    width: 0;
    z-index: 100;
    bottom: -8px;
    left: 50%;
    @media screen and (max-width: 650px) {
      left: 8px;
    }
  }
`;

const SnoozeWrapper = styled.div`
  padding: 8px 0px;

  .MuiList-root {
    padding: 0px 8px;
  }

  .MuiMenuItem-root {
    border-radius: 6px;
    font-size: 12px;
    padding: 5px 8px;
    :hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
  isRecurrence?: boolean;
  isProject?: boolean;
  focus?: boolean;
  done?: boolean;
  category?: OutTaskboxDetailResponseCategory;
  lockedIn?: boolean;
} & Event;

export interface CalendarViewProps {
  newEventId?: string;
  events?: CustomEvent[];
  selectedEvent?: CustomEvent | null;
  currentDate: Date;
  calendarFilter?: CalendarEventsFilter;
  meetingFilter?: OutFeatureStorage;
  onClickRefresh?: (isRefresh?: boolean) => void;
  onClickToggleView?: () => void;
  onClickCalendarFilter?: (filter: CalendarEventsFilter) => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onUpdateEvent?: ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDeleteEvent?: (eventId: string) => void;
  onClickTimeSlot?: ({
    action,
    bounds,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: 'select' | 'click' | 'doubleClick';
    bounds:
      | {
          x: number;
          y: number;
          top: number;
          bottom: number;
          left: number;
          right: number;
        }
      | undefined;
    box:
      | {
          x: number;
          y: number;
          clientX: number;
          clientY: number;
        }
      | undefined;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onContextMenuEvent?: (eventId: string) => void;
  onClickMeetingFilter?: () => void;
  onClickCategoryActions?: (category: OutCategory | null, action: CategoryActionType) => void;
}

const MonthCalendarView = ({
  newEventId,
  events = [],
  selectedEvent,
  currentDate,
  calendarFilter,
  meetingFilter,
  onClickRefresh,
  onClickCalendarFilter,
  onSelectEvent,
  onUpdateEvent,
  onClickTimeSlot,
  onChangeCurrentDate,
  onDropFromOutside,
  onUpdateEventTitle,
  onContextMenuEvent,
  onClickMeetingFilter,
  onClickCategoryActions,
}: CalendarViewProps) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const monthDays = ['일', '월', '화', '수', '목', '금', '토'];
  const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);
  const [ritualSettingEl, setRitualSettingEl] = useState<HTMLElement | null>(null);
  const [snoozeAnchorEl, setSnoozeAnchorEl] = useState<HTMLElement | null>(null);
  const [meetingInfoPopoverAnchor, setMeetingInfoPopover] = useState<HTMLElement | null>(null);
  const [ritualAlarm, setRitualAlarm] = useAtom(ritualAlarmAtom);
  const [snoozeTime] = useState(JSON.parse(localStorage.getItem('snooze-time') || '[]'));
  const [, setRitualEntry] = useAtom(ritualEntryAtom);
  const [, setDragContext] = useAtom(dragContextAtom);
  const [, setRitualAlarmTime] = useAtom(ritualAlarmTimeAtom);

  const formats = useMemo(
    () => ({
      dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => `${localizer?.format(date, 'DD (ddd)', culture)}`,
      timeGutterFormat: 'a hh시',
      eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
        `${localizer?.format(range.start, 'a h:mm', culture)}~${localizer?.format(range.end, 'a h:mm', culture)}`,
    }),
    [],
  );

  const draggableAccessor = useCallback((event: CustomEvent) => event.type === 'task', [events]);
  const resizableAccessor = useCallback(
    (event: CustomEvent) => {
      if (event.type === 'task') {
        return event.allDay! && !event.isProject && !event.isRecurrence;
      } else if (event.type === 'meeting') {
        return event.allDay!;
      }
      return false;
    },
    [events],
  );
  const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
  const eventPropGetter = useCallback(
    (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
      if (event.type === 'meeting') return {};

      if (event.type === 'task' && event.id === newEventId) {
        return {
          style: {
            background: `rgba(0, 57, 167, 0.3)`,
          },
        };
      }

      // 종일 taskbox를 특정 시간대로 이동시 스타일
      if (event.type === 'task' && event.allDay && isSelected === undefined) {
        return {
          style: { maxHeight: '28px' },
        };
      }

      if (isSelected && event.focus) {
        return {
          style: {
            border: '1px solid transparent',
            backgroundImage: 'linear-gradient(white, white), linear-gradient(180deg, #c471ed 1.78%, #f64f59 97.94%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
          },
        };
      }

      return {
        style: {
          border: `1px solid ${
            isSelected ? (event.isProject ? COLORS.issue2 : event.isRecurrence ? COLORS.sub4 : COLORS.brand1) : event.allDay ? COLORS.gray200 : 'transparent'
          }`,
        },
      };
    },
    [events],
  );

  useMountEffect(() => {
    scrollToCurrentIndicator();
  });

  useEventListener(window, 'mouseup', () => {
    setDragContext(null);
  });

  const handleClickRitual = () => {
    navigate('/ritual');
    setRitualAlarm(false);
    localStorage.setItem('ritual-alarm', 'false');
    setRitualEntry([dayjs().format(DATE_FORMAT_4), true]);
    localStorage.setItem('ritual-entry', JSON.stringify([dayjs().format(DATE_FORMAT_4), true]));
  };

  const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
    onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
  });

  const [handleContextMenu] = useRafCallback((event: CustomEvent) => {
    onContextMenuEvent && onContextMenuEvent(event.id!);
  });

  const [updateCategory] = useRafCallback((category: OutCategory | null, action: CategoryActionType) => {
    onClickCategoryActions && onClickCategoryActions(category, action);
  });

  const scrollToCurrentIndicator = () => {
    setTimeout(() => {
      if (!ref || !ref.current) return;
      const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
      if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
    }, 30);
  };

  const handleClickCalendarPopoverItem = (value: Date | null) => {
    if (!value) return;

    setIsVisibleCalendarPopover(false);
    onChangeCurrentDate && onChangeCurrentDate(value);
  };

  const handleChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
  };

  const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(end).format(DATE_FORMAT_1),
        // endTime: endTime: dayjs(end).diff(dayjs(start), 'day') > 1 && !event.allDay ? dayjs(end).add(1, 'day').format(DATE_FORMAT_1) : dayjs(end).format(DATE_FORMAT_1),
        isAllDay: event.allDay!,
      });
  };

  const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay?: boolean }) => {
    onUpdateEvent &&
      onUpdateEvent({
        eventId: event.id!,
        startTime: dayjs(start).format(DATE_FORMAT_1),
        endTime: dayjs(end).format(DATE_FORMAT_1),
        isAllDay: Boolean(isAllDay),
      });
  };

  const handleSelectEvent = (event: CustomEvent) => {
    onSelectEvent && onSelectEvent(event.id!);
  };

  const handleSelectSlot = (slot: SlotInfo) => {
    if (calendarFilter === 'MEETING') onClickCalendarFilter && onClickCalendarFilter('ALL');

    const days = dayjs(slot.end).startOf('day').diff(dayjs(slot.start).startOf('day'), 'day');
    if (slot.action === 'select' && days > 1) return;

    const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
    const endTime =
      slot.slots.length === 2
        ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
        : dayjs(slot.end).format(DATE_FORMAT_1);

    onClickTimeSlot &&
      onClickTimeSlot({
        action: slot.action,
        bounds: slot.bounds,
        box: slot.box,
        startTime,
        endTime,
        isAllDay: slot?.slots.length === 1 || days > 1 ? true : false,
      });
  };

  const handleDropFromOutside = (args: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
    onDropFromOutside &&
      onDropFromOutside({
        startTime: dayjs(args.start).format(DATE_FORMAT_1),
        endTime: dayjs(args.end).format(DATE_FORMAT_1),
        isAllDay: args.allDay,
      });
  };

  const handleEventInput = (event: CustomEvent, title: string) => {
    updateEventTitle(event, title);
  };

  const handleEventCategory = (category: OutCategory | null, action: CategoryActionType) => {
    updateCategory(category, action);
  };

  const handleClickMonthTaskDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
    navigate('/task/today');
  };

  const EventComponentWrapper = useCallback((props: MonthCalendarEventProps) => {
    return (
      <MonthCalendarEvent
        {...props}
        onInput={(event, value) => handleEventInput(event, value)}
        onChangeCategory={(category, action) => handleEventCategory(category, action)}
        onContextMenu={handleContextMenu}
      />
    );
  }, []);

  const handleClickSnoozeMenu = (time: number) => {
    setSnoozeAnchorEl(null);
    setRitualAlarm(false);
    localStorage.setItem('ritual-alarm', 'false');
    if (time === 0) {
      setRitualEntry([dayjs().format(DATE_FORMAT_4), true]);
      localStorage.setItem('ritual-entry', JSON.stringify([dayjs().format(DATE_FORMAT_4), true]));
    } else {
      setRitualAlarmTime(time);
    }
  };

  const handleDragStart = (args: OnDragStartArgs<CustomEvent>) => {
    const { event } = args;
    if (!event) return;

    setDragContext({
      id: event.id!,
      title: `${event.title}`,
      isRecurrence: event.isRecurrence,
      isProject: event.isProject,
      done: event.done,
      lockedIn: event.lockedIn,
      focus: event.focus,
    });
  };

  const CustomDateHeader = useCallback(
    ({ label, date, events }: { label: string; date: Date; events: CustomEvent[] }) => {
      const filteredEvents = events.filter((event) => event.type === 'task' && dayjs(event.start).isSame(date, 'day'));
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button className="rbc-button-link" role="cell" onClick={() => handleClickMonthTaskDate(date)}>
            {label}
          </button>
          {filteredEvents.length > 0 ? (
            <div
              style={{
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor:
                  filteredEvents.filter((v) => !v.done).length === 0
                    ? COLORS.positive1
                    : dayjs(date).isBefore(dayjs()) && filteredEvents.filter((v) => !v.done).length > 0
                    ? COLORS.negative1
                    : COLORS.brand1,
                margin: '4px 0px',
              }}
            />
          ) : (
            <div style={{ width: '4px', height: '4px', borderRadius: '50%' }} />
          )}
        </div>
      );
    },
    [events],
  );

  return (
    <CalendarViewWrapper>
      <CalendarViewControlWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarPopover
            isOpen={isVisibleCalendarPopover}
            defaultValue={currentDate}
            onClickOutside={() => setIsVisibleCalendarPopover(false)}
            onClickItem={handleClickCalendarPopoverItem}
          >
            <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
              <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                  {dayjs(currentDate).format('M월')}
                </Typography>
              </ArrowToggleButton>
            </span>
          </CalendarPopover>
          <Select
            value={'월간'}
            sx={{ width: '53px', height: '30px', fontSize: '12px' }}
            MenuProps={{
              MenuListProps: {
                style: {
                  padding: '8px',
                },
              },
              style: { marginTop: '2px', left: '23px' },
            }}
          >
            <MenuItem
              value={'일간'}
              onClick={() => navigate('/task/today')}
              sx={{
                width: '82px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>일간</span>
              <KeyboardButtonRect style={{ width: '16px' }}>D</KeyboardButtonRect>
            </MenuItem>
            <MenuItem
              value={'주간'}
              onClick={() => navigate('/task/week')}
              sx={{
                width: '82px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>주간</span>
              <KeyboardButtonRect style={{ width: '16px' }}>W</KeyboardButtonRect>
            </MenuItem>
            <MenuItem
              value={'월간'}
              sx={{
                width: '82px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                fontSize: '12px',
                padding: '8px',
              }}
            >
              <span>월간</span>
              <KeyboardButtonRect style={{ width: '16px' }}>M</KeyboardButtonRect>
            </MenuItem>
          </Select>
          <div style={{ marginLeft: 12 }}>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>지난달로 이동</span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    ←
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton
                aria-label="previous day"
                onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(1, 'month').toDate())}
                sx={{ padding: '4px' }}
              >
                <Icons.ArrowLeftSmall />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>오늘로 이동</span>
                  <KeyboardButtonRect small style={{ width: '26px', padding: '0px 3px', marginLeft: 8 }}>
                    Shift
                  </KeyboardButtonRect>
                  <KeyboardCommandPlus>+</KeyboardCommandPlus>
                  <KeyboardButtonRect small style={{ padding: '0px 3px' }}>
                    T
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <Button
                sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
                size="small"
                style={{ minWidth: '48px', marginLeft: 2, marginRight: 2 }}
                onClick={() => handleChangeCurrentDate(dayjs().toDate())}
              >
                <b>오늘</b>
              </Button>
            </Tooltip>
            <Tooltip
              title={
                <div style={{ display: 'flex' }}>
                  <span>다음달로 이동</span>
                  <KeyboardButtonRect small style={{ padding: '0px 2px 1px 2px', marginLeft: 8 }}>
                    →
                  </KeyboardButtonRect>
                </div>
              }
              disableInteractive
            >
              <IconButton aria-label="next day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(1, 'month').toDate())} sx={{ padding: '4px' }}>
                <Icons.ArrowRightSmall />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ToggleButtonGroup
          color="primary"
          value={calendarFilter}
          exclusive
          onChange={(e, newFilter) => onClickCalendarFilter?.(newFilter)}
          sx={{ height: 32, padding: '3px', backgroundColor: COLORS.gray200, borderRadius: '8px' }}
        >
          <ToggleButton value="ALL" style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px' }}>
            <span>모두</span>
          </ToggleButton>
          <ToggleButton value="TASK" style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px' }}>
            <span>할 일</span>
          </ToggleButton>
          <ToggleButton
            value="MEETING"
            onClick={(e) => setMeetingInfoPopover(e.currentTarget)}
            style={{ width: 'fit-content', border: 0, borderRadius: '6px', fontSize: 12, padding: '4px 6px' }}
          >
            <span>일정</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </CalendarViewControlWrapper>
      <CalendarHeaderWrapper>
        {monthDays.map((day, index) => (
          <CalendarHeaderDayWrapper key={index}>{day}</CalendarHeaderDayWrapper>
        ))}
      </CalendarHeaderWrapper>
      <CalendarViewDaySchedulerWrapper>
        <CalendarContainer ref={ref}>
          <DnDCalendar
            style={{ height: '100%' }}
            date={currentDate}
            selected={selectedEvent}
            getNow={() => dayjs().toDate()}
            formats={formats}
            view="month"
            defaultView="month"
            toolbar={false}
            timeslots={4}
            step={15}
            max={dayjs(currentDate).endOf('day').toDate()}
            events={events}
            dayLayoutAlgorithm={'overlap'}
            selectable={true}
            // draggableAccessor={draggableAccessor}
            resizableAccessor={resizableAccessor}
            allDayAccessor={allDayAccessor}
            components={{
              event: EventComponentWrapper,
              month: {
                dateHeader: (props) => {
                  return <CustomDateHeader {...props} events={events} />;
                },
              },
            }}
            eventPropGetter={eventPropGetter}
            onDropFromOutside={handleDropFromOutside}
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onDragStart={handleDragStart}
            // onDoubleClickEvent={handleDoubleClickEvent}
            onView={() => 'month'}
            onNavigate={() => ({})}
            showAllEvents={true}
            showMultiDayTimes={true}
          />
        </CalendarContainer>
      </CalendarViewDaySchedulerWrapper>
      <div style={{ position: 'absolute', bottom: 24, right: 62 }}>
        <IconButton
          aria-label="sync"
          sx={{ boxShadow: '0px 8px 16px 0px rgba(26, 30, 39, 0.16)' }}
          style={{ width: '48px', height: '48px', marginLeft: 8, background: 'white' }}
          onClick={() => onClickRefresh && onClickRefresh(true)}
        >
          <Icons.Reload width={28} height={28} strokeWidth={1} />
        </IconButton>
      </div>
      <div style={{ position: 'absolute', bottom: 4, right: -14 }}>
        {ritualAlarm && (
          <RitualNotification>
            <Icons.OrganizeToday />
            <div>
              <div style={{ fontSize: 12, fontWeight: 700 }}>오늘 정리하기</div>
              <div style={{ color: COLORS.gray500, fontSize: 10, fontWeight: 700 }}>오늘 하루를 정리해보세요</div>
            </div>
            <div>
              <IconButton sx={{ width: 20, height: 20, padding: '2.5px 2px', borderRadius: '6px' }} onClick={(e) => setRitualSettingEl(e.currentTarget)}>
                <Icons.More />
              </IconButton>
              <IconButton sx={{ width: 20, height: 20, padding: '2.5px 2px', borderRadius: '6px' }} onClick={(e) => setSnoozeAnchorEl(e.currentTarget)}>
                <Icons.Close width={14} height={14} />
              </IconButton>
            </div>
          </RitualNotification>
        )}
        <Tooltip title="오늘 정리하기" disableInteractive>
          <IconButton
            onClick={() => {
              handleClickRitual();
              GAEventTrigger({ action: 'ritual_shutdown_init', category: 'ritual_shutdown_init' });
            }}
            sx={{
              'position': 'absolute',
              'bottom': 20,
              'left': -70,
              'zIndex': 10,
              'width': 48,
              'height': 48,
              'backgroundColor': ritualAlarm ? COLORS.brand1 : COLORS.white,
              'boxShadow': '0px 8px 16px 0px rgba(26, 30, 39, 0.16)',
              ':hover': ritualAlarm ? { backgroundColor: COLORS.sub2 } : { backgroundColor: COLORS.white },
            }}
          >
            <Icons.Ritual stroke={ritualAlarm ? COLORS.white : COLORS.brand1} />
          </IconButton>
        </Tooltip>
      </div>
      {ritualSettingEl && (
        <Popover
          open={Boolean(ritualSettingEl)}
          anchorEl={ritualSettingEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setRitualSettingEl(null)}
        >
          <MenuList sx={{ padding: '12px' }}>
            <MenuItem
              onClick={() => navigate('/settings?section=notification')}
              sx={{
                'borderRadius': '8px',
                'fontSize': 12,
                'padding': '8px',
                ':hover': {
                  backgroundColor: COLORS.gray100,
                },
              }}
            >
              하루 정리하기 설정
            </MenuItem>
          </MenuList>
        </Popover>
      )}
      {snoozeAnchorEl && (
        <Popover
          open={Boolean(snoozeAnchorEl)}
          anchorEl={snoozeAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setSnoozeAnchorEl(null)}
        >
          <SnoozeWrapper>
            <div style={{ fontSize: '13px', fontWeight: 700, padding: '2px 16px' }}>스누즈</div>
            <div style={{ fontSize: '10px', padding: '5px 16px' }}>나중에 알림을 받을 수 있어요</div>
            <MenuList>
              <MenuItem onClick={() => handleClickSnoozeMenu(0.5)}>30분 후</MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(1)}>1시간 후</MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(2)}>2시간 후</MenuItem>
              <MenuItem onClick={() => handleClickSnoozeMenu(0)}>오늘은 알리지 않기</MenuItem>
            </MenuList>
          </SnoozeWrapper>
        </Popover>
      )}
      {meetingInfoPopoverAnchor && meetingFilter?.data.click !== true && (
        <Popover
          open={Boolean(meetingInfoPopoverAnchor)}
          anchorEl={meetingInfoPopoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => {
            setMeetingInfoPopover(null);
            onClickMeetingFilter && onClickMeetingFilter();
          }}
          sx={{ top: '10px' }}
        >
          <div style={{ fontSize: '12px', fontWeight: 700, textAlign: 'center', padding: '12px' }}>
            🛠️ 일정 생성·수정 기능은 준비중입니다.
            <br />
            조금만 기다려주세요!
          </div>
        </Popover>
      )}
    </CalendarViewWrapper>
  );
};

export default MonthCalendarView;
