import { OutputData } from '@editorjs/editorjs';
import styled from '@emotion/styled';
import Editor from 'components/Editor';
import { useCallback, useEffect, useState } from 'react';
import RemirrorEditor from 'components/Remirror';
import { RemirrorContentType, RemirrorJSON } from 'remirror';
import { EditorState } from '@remirror/core';
import { hideScroll } from 'styles/utils';
import { TextField } from '@mui/material';
import { PlaceholderExtension, ReactComponentExtension, useRemirror, Remirror, OnChangeJSON } from '@remirror/react';
import {
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  DropCursorExtension,
  HeadingExtension,
  HorizontalRuleExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  NodeFormattingExtension,
  OrderedListExtension,
  StrikeExtension,
  TableExtension,
  TaskListExtension,
  TextColorExtension,
  TextHighlightExtension,
  TrailingNodeExtension,
  UnderlineExtension,
  wysiwygPreset,
} from 'remirror/extensions';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';
import { DragDropExtension } from 'components/Remirror/Extension/DragDrop';
import { oneDark } from '@codemirror/theme-one-dark';
import { languages } from '@codemirror/language-data';

export interface MemoProps {
  active?: boolean;
  showFilter?: boolean;
}

const TestingMemo = () => {
  const [editorInput, setEditorInput] = useState('');
  const [remirrorInput, setRemirrorInput] = useState('');
  const [editorData, setEditorData] = useState<OutputData>({
    blocks: [],
  });
  const [remirrorData, setRemirrorData] = useState<RemirrorContentType>();

  const handleChangeEditor = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditorInput(e.target.value);
  };

  useEffect(() => {
    try {
      if (editorInput === '') {
        setEditorData({ blocks: [] });
      } else {
        const parsedData = JSON.parse(`[${editorInput}]`);
        if (parsedData) {
          setEditorData({ blocks: parsedData });
        }
      }
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
  }, [editorInput]);

  const handleChangeRemirror = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRemirrorInput(e.target.value);
  };

  useEffect(() => {
    try {
      if (remirrorInput === '') {
        setRemirrorData(undefined);
      } else {
        const parsedData = JSON.parse(`[${remirrorInput}]`);
        if (parsedData) {
          setRemirrorData({ type: 'doc', content: parsedData });
        }
      }
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
  }, [remirrorInput]);

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%', marginTop: '20px' }}>
        <Wrapper>
          <div>EditorJs</div>
          <TextField
            multiline
            value={editorInput}
            onChange={handleChangeEditor}
            sx={{
              overflowY: 'scroll',
            }}
          />
          <MemoWrapper>{editorData.blocks.length > 0 ? <Editor data={editorData} /> : <div />}</MemoWrapper>
        </Wrapper>
        <Wrapper>
          <div>Remirror</div>
          <TextField
            multiline
            value={remirrorInput}
            onChange={handleChangeRemirror}
            sx={{
              overflowY: 'scroll',
            }}
          />
          <MemoWrapper>{remirrorData ? <RemirrorEditor data={remirrorData} /> : <div />}</MemoWrapper>
        </Wrapper>
      </div>
    </Container>
  );
};

export default TestingMemo;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    max-height: 40%;
  }
`;

const Wrapper = styled.div`
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MemoWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  padding-left: 16px;
  ${hideScroll()};
`;
