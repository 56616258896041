import { API, BlockTune } from '@editorjs/editorjs';
import { TunesMenuConfig } from '@editorjs/editorjs/types/tools';
import { createTaskV1WorksPost, createWorkboxV2WorkboxesPost } from 'queries';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

export default class LaterTune implements BlockTune {
  public static readonly isTune = true;
  private readonly api: API;

  constructor({ api }: { api: API }) {
    this.api = api;
  }

  public render(): TunesMenuConfig {
    return {
      icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1672 7.66667H14.0839V4.75M13.9143 12.5415C13.3707 13.3786 12.5731 14.0193 11.6386 14.3696C10.7041 14.7199 9.68178 14.7614 8.72193 14.488C7.76208 14.2146 6.91535 13.6408 6.30571 12.8506C5.69608 12.0604 5.35591 11.0955 5.33501 10.0977C5.31411 9.09989 5.61342 8.12167 6.18943 7.30664C6.76544 6.49161 7.5876 5.88283 8.53516 5.56946C9.48271 5.25608 10.5059 5.25458 11.4542 5.56547C12.4026 5.87636 13.2264 6.48305 13.8045 7.29658" stroke="#1F2023" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      title: '나중에로 보내기',
      name: 'later',
      onActivate: () => this.handleClick(),
    };
  }

  public async handleClick() {
    const index = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex());
    if (!block) return;

    const blockType = block.name;
    const outerText = `${block.holder.outerText}`.trim();
    if (!outerText) return;

    const contents = outerText.split('\n');
    if (blockType === 'paragraph') {
      await createTaskV1WorksPost({ id: uuidv4(), type: 'task', content: contents[0], workSectionType: 'someday' });
    }

    if (blockType === 'list' || blockType === 'checklist') {
      await createWorkboxV2WorkboxesPost({
        id: uuidv4(),
        title: contents[0],
        tasks: contents.slice(1).map((item) => ({ id: uuidv4(), content: item })),
      });
    }

    this.api.blocks.delete(index);
    toast.success('메모를 나중에로 옮겼습니다.');
  }
}
