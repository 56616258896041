import { SVGProps } from 'react';

export const ConvertToProject = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 7.99958C2.5 4.96225 4.96225 2.5 7.99958 2.5C11.0369 2.5 13.4992 4.96225 13.4992 7.99958C13.4992 8.09476 13.4967 8.18938 13.492 8.28337C14.0247 8.49774 14.5007 8.82346 14.8911 9.23173C14.9621 8.83173 14.9992 8.41998 14.9992 7.99958C14.9992 4.13382 11.8653 1 7.99958 1C4.13382 1 1 4.13382 1 7.99958C1 11.8653 4.13382 14.9992 7.99958 14.9992C8.42235 14.9992 8.83636 14.9617 9.23847 14.8899C8.82981 14.4999 8.50363 14.0242 8.28874 13.4917C8.19298 13.4966 8.09657 13.4992 7.99958 13.4992C4.96225 13.4992 2.5 11.0369 2.5 7.99958Z"
        fill="#1F2023"
      />
      <path
        d="M13.4905 12.2857L14.6016 11.1429M14.6016 11.1429L13.4905 10M14.6016 11.1429H10.9905C10.2234 11.1429 9.60156 11.7825 9.60156 12.5714C9.60156 13.3604 10.2234 14 10.9905 14H12.3793"
        stroke="#1F2023"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" fill="#1F2023" />
    </svg>
  );
};
