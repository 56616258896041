import Measured, { measure } from '@measured-im/browser';
import { OutUserDetail } from 'queries/model';

export const settingMeasured = (user: OutUserDetail) => {
  Measured.install('15baef61-ce2f-4119-8387-2824693bfaec');
  measure('User ID', user?.id);
  measure('User Name', user?.name);
  measure('Sign-up Date', user?.createdAt);
};

export const removeMeasured = () => {
  measure('User ID', undefined);
  measure('User Name', undefined);
  measure('Sign-up Date', undefined);
};
