import { getCaretPosition } from './moveCaret';

/** 글의 마지막에서 엔터키 눌렀을 때 후속 처리 */
export const onHandleKeyUpEnter = (event: any, callback: () => void) => {
  const { textContent, value } = event.target;
  // 문장의 마지막 위치에서 Enter를

  const content = textContent || value;
  if (content) {
    const isEndOfSentence = content.length === getCaretPosition(event.target);
    if (isEnterKey(event) && isEndOfSentence && content !== '') {
      callback();
    }
  }
};

/** 공백에서 backspace 눌렀을 때 삭제, 2번 입력 시 callback 함수 호출 */
let pressBackspace = false;
export const onHandleKeyUpBackspace = (event: any, callback: () => void) => {
  const { textContent, value } = event.target;
  const content = textContent || value;

  if (isDeleteKey(event) && !content) {
    if (pressBackspace) {
      pressBackspace = false;
      callback();
    }
    pressBackspace = true;
    setTimeout(() => {
      pressBackspace = false;
    }, 1000);
  } else {
    pressBackspace = false;
  }
};

/** 우물정(#) 눌렀을 때 후속처리 */
export const onHandleKeyUpPoundKey = (event: any, callback: () => void) => {
  if (isPoundKey(event)) {
    callback();
  }
};

export const isEnterKey = (event: any) => {
  const key = event.key || event.keyCode;
  return key === 'Enter' || key === 13;
};

export const isPoundKey = (event: any) => {
  const key = event.key || event.keyCode;
  return key === '#' || key === 51;
};

export const isDeleteKey = (event: any) => {
  const key = event.key || event.keyCode;
  return key === 'Delete' || key === 'Backspace' || key === 46;
};

// event.code 받아서 printable key인지 확인
export const isPrintableKey = (key: string): boolean => {
  const printableKeys = [
    'Backquote',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Minus',
    'Equal',
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
    'Backslash',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
    'KeyG',
    'KeyH',
    'KeyJ',
    'KeyK',
    'KeyL',
    'Semicolon',
    'Quote',
    'KeyZ',
    'KeyX',
    'KeyC',
    'KeyV',
    'KeyB',
    'KeyN',
    'KeyM',
    'Comma',
    'Period',
    'Slash',
    'Space',
  ];

  return printableKeys.includes(key);
};
